import React, { useEffect, useRef } from 'react';
import './style.scss';
import { createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const ref = useRef(null);
  const progress = props?.progress || 0;

  useEffect(() => {
    const textElementList = ref?.current?.getElementsByClassName('loading-text');
    if (!textElementList?.length) {
      return;
    }
    const textElement = textElementList[0];
    textElement.innerText = `全景看车 ${progress}%`;

    const progressBarElementList = ref?.current?.getElementsByClassName('progress-bar');
    if (!progressBarElementList?.length) {
      return;
    }
    const progressBarElement = progressBarElementList[0];
    progressBarElement.style.background = `linear-gradient(to right, #ffffff ${progress}%, rgba(255, 255, 255, 0.15) 0)`;

    const carElementList = ref?.current?.getElementsByClassName('loading-car-container');
    if (!carElementList?.length) {
      return;
    }
    const carElement = carElementList[0];
    const totalWidth = ref?.current?.getBoundingClientRect()?.width;
    const carWidth = carElement?.getBoundingClientRect()?.width;
    carElement.style.marginLeft = `${((totalWidth - carWidth) * progress) / 100}px`;
  }, [progress]);

  return (
    <section className={`progress-bar-section ${props?.className || ''}`} ref={ref}>
      <div className="loading-car-container">
        <img alt="广汽本田" className="loading-car" src={createFilePath('/common/loading-car.png')} />
        <p className="loading-text">全景看车 0%</p>
      </div>
      <div className="progress-bar-container">
        <div className="progress-bar-container-border progress-bar-container-border-left" />
        <div className="progress-bar-container-border progress-bar-container-border-right" />
        <div className="progress-bar" />
      </div>
      <img alt="广汽本田" className="loading-dot" src={createFilePath('/common/loading-dot.gif')} />
    </section>
  );
}
