import React, { useEffect, useRef, useState, forwardRef } from 'react';
import './style.scss';
import temp from '../../temp/config';
import { isEmpty, convertStylesStringToObject, sendDmp, isImage, isVideo, isMobileFun, createFilePath, GotoMall } from '../../utils/obj-utils';
import { GetGmallAppSchemeUrl } from '../../utils/api';

const HeroLandingV1 = forwardRef((props, ref) => {
  const isMobile = isMobileFun();
  const data = {
    ...props?.data,
    style: convertStylesStringToObject(props?.data?.style),
    backgroundStyle: convertStylesStringToObject(props?.data?.backgroundStyle),
    contentStyle: convertStylesStringToObject(props?.data?.contentStyle),
    titleStyle: convertStylesStringToObject(props?.data?.titleStyle),
    descStyle: convertStylesStringToObject(props?.data?.descStyle),
    textContainerStyle: convertStylesStringToObject(props?.data?.textContainerStyle),
    buttonStyle: convertStylesStringToObject(props?.data?.buttonStyle),
    buttonList: props?.data?.buttonList?.map((item) => ({ ...item, style: convertStylesStringToObject(item?.style) })),
    bannerhtml: props?.data?.bannerhtml
  };

  const buttonClick = (item) => {
    sendDmp(item?.dmp, item?.link);
    if (props?.buttonClick) {
      props?.buttonClick();
    } else {
      window.open(item?.link, item?.target);
    }
  };

  const getBackground = () => {
    if (isEmpty(data?.background)) {
      return null;
    }
    return (
      <React.Fragment>
        {isImage(data?.background) ? (
          <img className="background" src={createFilePath(data?.background)} style={data?.backgroundStyle} alt={data?.backgroundAlt} />
        ) : null}
        {isVideo(data?.background) ? (
          <video className="background" src={createFilePath(data?.background)} style={data?.backgroundStyle} autoPlay={true} muted={true} />
        ) : null}
      </React.Fragment>
    );
  };

  const getTitle = () => {
    if (isEmpty(data?.title)) {
      return null;
    }
    return (
      <React.Fragment>
        {isImage(data?.title) ? (
          <img alt="广汽本田" className="title" src={createFilePath(data?.title)} style={data?.titleStyle} />
        ) : (
          <div className="title" dangerouslySetInnerHTML={{ __html: data?.title?.replaceHtml() }} style={data?.titleStyle} />
        )}
      </React.Fragment>
    );
  };

  const getDesc = () => {
    if (isEmpty(data?.desc)) {
      return null;
    }
    return (
      <React.Fragment>
        {isImage(data?.desc) ? (
          <img alt="广汽本田" className="desc" src={createFilePath(data?.desc)} style={data?.descStyle} />
        ) : (
          <div className="desc" dangerouslySetInnerHTML={{ __html: data?.desc?.replaceHtml() }} style={data?.descStyle} />
        )}
      </React.Fragment>
    );
  };

  const getButtonContainer = () => {
    if (data?.buttonList?.length === 0) {
      return null;
    }
    return (
      <div className="button-container" style={data?.buttonStyle}>
        {data?.buttonList?.map((item, index) => (
          <button
            key={index}
            className="button"
            dangerouslySetInnerHTML={{ __html: item?.text?.replaceHtml() }}
            style={item?.style}
            onClick={() => buttonClick(item)}
          />
        ))}
      </div>
    );
  };

  const AppSchemeUrlRefClick = (e) => {
    if (e.target && e.target.dataset && e.target.dataset.appschemeurl) {
      GotoMall(e.target.dataset.appschemeurl, e.target.dataset.htmlItem1, isMobile);
    }
  };

  return (
    <div
      className={`hero-landing-v1 hero-landing-v1-${data?.position} ${props?.className || ''}`}
      style={{ ...data?.style, ...props?.style }}
      ref={ref}
    >
      {!data?.bannerhtml ? (
        <>
          {getBackground()}
          <div
            className={`content-container content-container-${data?.position} content-container-content-${data?.contentPosition}`}
            style={data?.contentStyle}
          >
            <div className="text-container" style={data?.textContainerStyle}>
              {getTitle()}
              {getDesc()}
            </div>
            {getButtonContainer()}
          </div>
        </>
      ) : (
        <>
          {data?.bannerhtml.indexOf('data-appschemeurl') !== -1 ? (
            <div dangerouslySetInnerHTML={{ __html: data?.bannerhtml?.replaceHtml() }} onClick={AppSchemeUrlRefClick} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: data?.bannerhtml?.replaceHtml() }} />
          )}
        </>
      )}
    </div>
  );
});

HeroLandingV1.displayName = 'HeroLandingV1';
export default HeroLandingV1;
