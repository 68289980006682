import React, { useState, useEffect, createRef, useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import { getPrefixField, sendDmpAction, createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields, scrollElement }) {
  const containerRef = useRef(null);
  const imageContainerRef = useRef(null);
  const contentContainerRef = useRef(null);
  const arrowContainerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = isMobileFun();
  const itemList = fields[getPrefixField('item_list', isMobile)]?.map((item) => ({
    title: item?.fields?.title?.value,
    description: item?.fields?.description?.value,
    image: createFilePath(item?.fields?.image?.value)
  }));
  const background = fields[getPrefixField('background', isMobile)]?.value;
  const leftArrowImage = createFilePath(fields[getPrefixField('left_arrow_image', isMobile)]?.value);
  const rightArrowImage = createFilePath(fields[getPrefixField('right_arrow_image', isMobile)]?.value);
  const titleNormalColor = fields[getPrefixField('title_normal_color', isMobile)]?.value;
  const titleHighlightColor = fields[getPrefixField('title_highlight_color', isMobile)]?.value;
  const descNormalColor = fields[getPrefixField('desc_normal_color', isMobile)]?.value;
  const descHighlightColor = fields[getPrefixField('desc_highlight_color', isMobile)]?.value;
  const reverse = fields[getPrefixField('reverse', isMobile)]?.value;
  const dmpActionLeft = fields[getPrefixField('dmp_action_left', isMobile)]?.value;
  const dmpPointLeftList = fields[getPrefixField('dmp_point_left', isMobile)];
  const dmpPointLeft = dmpPointLeftList?.length > 0 ? dmpPointLeftList[0].name : '';
  const dmpActionRight = fields[getPrefixField('dmp_action_right', isMobile)]?.value;
  const dmpPointRightList = fields[getPrefixField('dmp_point_right', isMobile)];
  const dmpPointRight = dmpPointRightList?.length > 0 ? dmpPointRightList[0].name : '';
  const imageRefList = itemList.map(() => createRef());
  const textRefList = itemList.map(() => createRef());
  const [data, _] = useState({
    itemList,
    imageRefList,
    textRefList,
    isMobile,
    background,
    leftArrowImage,
    rightArrowImage,
    titleNormalColor,
    titleHighlightColor,
    descNormalColor,
    descHighlightColor,
    reverse,
    dmpActionLeft,
    dmpPointLeft,
    dmpActionRight,
    dmpPointRight
  });
  const duration = 0.15;

  useEffect(() => {
    const length = data?.imageRefList?.length;
    const containerWidth = getComputedStyle(imageContainerRef.current).width.replace('px', '');
    const containerHeight = getComputedStyle(imageContainerRef.current).height.replace('px', '');
    const width = getComputedStyle(data?.imageRefList[0].current).width.replace('px', '');
    const height = getComputedStyle(data?.imageRefList[0].current).height.replace('px', '');
    const x = (containerWidth - width) / (length - 1);
    const y = (containerHeight - height) / (length - 1);
    let currentPosition = activeIndex;

    const controller = new ScrollMagic.Controller({ container: scrollElement || window });
    const scene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onLeave',
      duration: '100%'
    })
      .on('progress', function (event) {
        const progress = event.progress;
        if (progress <= duration) {
          const currentProgress = Math.min(progress * (1 / duration), 1);
          data?.imageRefList?.forEach((item, index) => {
            const currentIndex = index < currentPosition ? length - index - 1 : index - currentPosition;
            if (currentProgress === 0) {
              setTimeout(() => {
                item.current.style.transform = `translate(-${x * currentIndex * currentProgress}px, -${y * currentIndex * currentProgress}px)`;
                item.current.style.zIndex = length - currentIndex - 1;
              }, 200);
            }
            item.current.style.transform = `translate(-${x * currentIndex * currentProgress}px, -${y * currentIndex * currentProgress}px)`;
            item.current.style.zIndex = length - currentIndex - 1;
          });
          if (!data?.isMobile) {
            contentContainerRef.current.style.opacity = currentProgress;
          }
          arrowContainerRef.current.style.opacity = currentProgress;
        } else {
          const average = (1 - duration) / length;
          const position = Math.min(Math.floor((progress - duration) / average), length - 1);
          if (position !== currentPosition) {
            if (!data?.isMobile) {
              contentContainerRef.current.style.opacity = 1;
            }
            arrowContainerRef.current.style.opacity = 1;
            imageAnimation(position, x, y);
            textAnimation(currentPosition, position);
            currentPosition = position;
            setActiveIndex(position);
          }
        }
      })
      .setPin(containerRef.current)
      .addTo(controller);

    return () => {
      scene.remove();
      scene.destroy();

      controller.destroy();
    };
  }, []);

  const leftArrowClick = () => {
    const length = data?.imageRefList?.length;
    let prevIndex = activeIndex - 1;
    if (activeIndex === 0) {
      prevIndex = length - 1;
    }
    changePage(prevIndex, data?.dmpActionLeft, data?.dmpPointLeft);
  };

  const rightArrowClick = () => {
    const length = data?.imageRefList?.length;
    let nextIndex = activeIndex + 1;
    if (activeIndex === length - 1) {
      nextIndex = 0;
    }
    changePage(nextIndex, data?.dmpActionRight, data?.dmpPointRight);
  };

  const changePage = (pageIndex, action, point) => {
    const length = data?.imageRefList?.length;
    const containerWidth = getComputedStyle(imageContainerRef.current).width.replace('px', '');
    const containerHeight = getComputedStyle(imageContainerRef.current).height.replace('px', '');
    const width = getComputedStyle(data?.imageRefList[0].current).width.replace('px', '');
    const height = getComputedStyle(data?.imageRefList[0].current).height.replace('px', '');
    const x = (containerWidth - width) / (length - 1);
    const y = (containerHeight - height) / (length - 1);
    imageAnimation(pageIndex, x, y);
    textAnimation(activeIndex, pageIndex);
    setActiveIndex(pageIndex);
    sendDmpAction(action, point, '');
  };

  const imageAnimation = (position, x, y) => {
    const length = data?.imageRefList?.length;
    data?.imageRefList?.forEach((item, index) => {
      const currentIndex = index < position ? length - index - 1 : index - position;
      gsap.to(item.current, { x: -x * currentIndex * 1, y: -y * currentIndex * 1, zIndex: length - currentIndex - 1, duration: 0.2 });
    });
  };

  const textAnimation = (fromIndex, toIndex) => {
    if (data?.isMobile) {
      gsap.to(data?.textRefList[fromIndex].current, { opacity: 0, duration: 0.4 });
      gsap.to(data?.textRefList[toIndex].current, { opacity: 1, duration: 0.4 });
    }
  };

  const getLeftContainer = () => {
    return (
      <div className="left-container">
        <div className="image-container" ref={imageContainerRef}>
          {data?.itemList?.map((item, index) => {
            const length = data?.itemList?.length;
            const currentIndex = index < activeIndex ? length - index - 1 : index - activeIndex;
            return (
              <img
                alt="广汽本田"
                key={index}
                className="image"
                style={{ zIndex: length - currentIndex - 1 }}
                src={item.image}
                ref={data?.imageRefList[index]}
              />
            );
          })}
        </div>
        <div className="arrow-container" ref={arrowContainerRef}>
          <img alt="广汽本田" className="left-arrow-image" src={data?.leftArrowImage} onClick={leftArrowClick} />
          <img alt="广汽本田" className="right-arrow-image" src={data?.rightArrowImage} onClick={rightArrowClick} />
        </div>
      </div>
    );
  };

  const getContentContainer = () => {
    return (
      <div className="content-container" ref={contentContainerRef}>
        {data?.itemList?.map((item, index) => (
          <div
            key={index}
            className={`text-container ${index !== 0 && !data?.isMobile ? 'text-container-margin' : ''}`}
            ref={data?.textRefList[index]}
          >
            <div
              className="title"
              style={{ color: activeIndex === index ? data?.titleHighlightColor : data?.titleNormalColor }}
              dangerouslySetInnerHTML={{ __html: item.title?.replaceHtml() }}
            />
            <div
              className="description"
              style={{ color: activeIndex === index ? data?.descHighlightColor : data?.descNormalColor }}
              dangerouslySetInnerHTML={{ __html: item.description?.replaceHtml() }}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <section className="overlay-image-section" style={{ background: data?.background }} ref={containerRef}>
      {data ? (
        <div className={`overlay-image ${data?.reverse ? 'overlay-image-reverse' : ''}`}>
          {data?.reverse && !data?.isMobile ? (
            <React.Fragment>
              {getContentContainer()}
              {getLeftContainer()}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getLeftContainer()}
              {getContentContainer()}
            </React.Fragment>
          )}
        </div>
      ) : null}
    </section>
  );
}
