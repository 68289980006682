import React from 'react';
import './style.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.min.css';
import { Pagination, Navigation } from 'swiper';
import temp from '../../temp/config';
import { getPrefixField, sendDmp, convertStylesStringToObject, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { fields, rootEl, isMobile, windowHeight, setNum, num, openComparison, openDetail } = props;

  return (
    <Controller>
      <Scene triggerHook={1} duration={windowHeight}>
        {(progress, event) => {
          return (
            <div style={convertStylesStringToObject(fields[`${getPrefixField('container_style', isMobile)}`]?.value)}>
              <Timeline totalProgress={progress}>
                <Tween>
                  <div
                    className="ParameterConfiguration"
                    ref={rootEl}
                    style={{
                      // paddingBottom: fields?.is_submodule?.value ? (isMobile ? '13vh' : '10vh') : '',
                      ...convertStylesStringToObject(fields[`${getPrefixField('container_style', isMobile)}`]?.value)
                    }}
                  >
                    <div className="ContrastCenter">
                      {fields[`${getPrefixField('show_tab_flag', isMobile)}`]?.value ? (
                        <>
                          <div
                            className="ContrastHeader"
                            style={convertStylesStringToObject(fields[`${getPrefixField('tabList_style', isMobile)}`]?.value)}
                          >
                            <div>
                              <div className="ContrastHeaderLeft">
                                {fields[`${getPrefixField('version_list', isMobile)}`].map((item, index) => {
                                  return (
                                    <p
                                      style={convertStylesStringToObject(fields[`${getPrefixField('tab_style', isMobile)}`]?.value)}
                                      key={item.id}
                                      className={num === index ? 'ContrastActive' : 'Contrast'}
                                      onClick={() => {
                                        setNum(index);
                                        sendDmp(item?.fields?.tab_dmp);
                                      }}
                                      dangerouslySetInnerHTML={{ __html: item.fields.title?.value?.replaceHtml() }}
                                    ></p>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="ContrastHeader_virtual" />
                        </>
                      )}
                      {fields[`${getPrefixField('version_list', isMobile)}`].length &&
                        fields[`${getPrefixField('version_list', isMobile)}`].map((ele, index) => {
                          return (
                            index === num && (
                              <Swiper
                                key={index}
                                modules={[Navigation, Pagination]}
                                className={
                                  isMobile
                                    ? 'ContrastBottom mySwiper ismb'
                                    : `ContrastBottom mySwiper ispc ${progress > 0 ? 'positionLeft' : ''} ${
                                        ele.fields.car_list.length < 3 ? 'ContrastBottomLess3' : ''
                                      }`
                                }
                                slidesPerView={isMobile ? 1 : 3}
                                navigation={fields[`${getPrefixField('swiper_navigation', isMobile)}`]?.value}
                                pagination={
                                  fields[`${getPrefixField('swiper_pagination', isMobile)}`]?.value && {
                                    // bulletClass bulletActiveClass 最后不能有空格 & 里面不能有连续多个空格
                                    bulletClass: `swiper-pagination-bullet ${ele.fields.slide_pagination_class.value}`.trim(),
                                    bulletActiveClass: `swiper-pagination-bullet-active ${ele.fields.slide_pagination_active_class.value}`.trim(),
                                    clickable: true
                                  }
                                }
                                onSlidePrevTransitionStart={() => sendDmp(ele.fields.slide_prev_dmp)}
                                onSlideNextTransitionStart={() => sendDmp(ele.fields.slide_next_dmp)}
                              >
                                {ele.fields.car_list.map((item, i) => {
                                  return (
                                    <SwiperSlide
                                      key={i}
                                      className={index === 2 ? 'NoneBoder' : ''}
                                      style={convertStylesStringToObject(item.fields.silder_container_style?.value)}
                                    >
                                      <div
                                        className="sliderContainer"
                                        style={convertStylesStringToObject(item.fields.silder_content_container_style?.value)}
                                      >
                                        <p dangerouslySetInnerHTML={{ __html: item.fields.title?.value?.replaceHtml() }}></p>
                                        <img
                                          alt="广汽本田"
                                          className={isMobile ? '' : 'img'}
                                          src={`${createFilePath(item.fields.image.value)}`}
                                          style={convertStylesStringToObject(item.fields.back_img_style?.value)}
                                        />
                                        <div style={convertStylesStringToObject(item.fields.detail_style?.value)}>
                                          <div
                                            className="ContrastBottomPrice"
                                            style={convertStylesStringToObject(item.fields.contrast_bottom_price_style?.value)}
                                          >
                                            <div style={{ display: 'grid', textAlign: 'start' }}>
                                              <div dangerouslySetInnerHTML={{ __html: item.fields.price_text?.value?.replaceHtml() }}></div>
                                              <p
                                                style={convertStylesStringToObject(item.fields.contrast_bottom_price_remark_style?.value)}
                                                dangerouslySetInnerHTML={{ __html: item.fields.price_remark?.value?.replaceHtml() }}
                                              ></p>
                                            </div>
                                            <div style={{ display: 'grid', textAlign: 'start' }}>
                                              <div dangerouslySetInnerHTML={{ __html: item.fields.oil_cost_text?.value?.replaceHtml() }}></div>
                                              <p
                                                style={convertStylesStringToObject(item.fields.contrast_bottom_price_remark_style?.value)}
                                                dangerouslySetInnerHTML={{ __html: item.fields.oil_cost_remark?.value?.replaceHtml() }}
                                              ></p>
                                            </div>
                                            {item.fields.two_oils_cost?.value && (
                                              <div style={{ display: 'grid', textAlign: 'start' }}>
                                                <div dangerouslySetInnerHTML={{ __html: item.fields.oil_cost_text1?.value?.replaceHtml() }}></div>
                                                <p
                                                  style={convertStylesStringToObject(item.fields.contrast_bottom_price_remark_style?.value)}
                                                  dangerouslySetInnerHTML={{ __html: item.fields.oil_cost_remark1?.value?.replaceHtml() }}
                                                ></p>
                                              </div>
                                            )}
                                          </div>
                                          <div
                                            className="ContrastBottomS"
                                            style={convertStylesStringToObject(item.fields.contrast_bottoms_style?.value)}
                                          >
                                            {item.fields.detail_list.map((element, ii) => {
                                              return (
                                                <div key={ii} style={convertStylesStringToObject(item.fields.contrast_bottoms_content_style?.value)}>
                                                  <img
                                                    alt="广汽本田"
                                                    style={convertStylesStringToObject(item.fields.contrast_bottoms_content_img_style?.value)}
                                                    src={`${createFilePath(element.fields.icon.value)}`}
                                                  />
                                                  <p dangerouslySetInnerHTML={{ __html: element.fields.text.value?.replaceHtml() }}></p>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  );
                                })}
                              </Swiper>
                            )
                          );
                        })}

                      {!fields?.is_submodule?.value && (
                        <div className="ContrastBtn">
                          {!isMobile && (
                            <div
                              onClick={() => openDetail()}
                              dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('finance_btn_text', isMobile)}`].value?.replaceHtml() }}
                            />
                          )}
                          <div
                            onClick={() => openComparison()}
                            dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('detail_btn_text', isMobile)}`].value?.replaceHtml() }}
                          />
                          {isMobile && (
                            <div
                              onClick={() => openDetail()}
                              dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('finance_btn_text', isMobile)}`].value?.replaceHtml() }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Tween>
              </Timeline>
            </div>
          );
        }}
      </Scene>
    </Controller>
  );
}
