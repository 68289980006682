import React, { useState, useEffect } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import temp from '../../temp/config';
import { createFilePath, isMobileFun } from '../../utils/obj-utils';
import './style.scss';

function index(props) {
  const isMobile = isMobileFun();
  const [flag, setFlag] = useState(true);
  const [num, setNum] = useState(0);
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    const myScript = document.createElement('script');
    myScript.src = createFilePath('/common/lib/common/js/live800.js?t=' + Date.now());
    myScript.async = false;
    document.body.appendChild(myScript);
    return () => {
      document.body.removeChild(myScript);
    };
  }, []);

  return !isMobile ? (
    <div className="Suspension-homePage">
      <div className={`inner ${num === 1 ? 'innerRemove' : ''}`} style={{ display: flag ? 'none' : 'block' }}>
        <div className="innerText">
          {props.fields.list.map((item, index) => {
            return index !== 0 ? (
              <a
                href={item.fields.pc_link.value}
                data-action={item.fields.iconDmp[0]?.fields?.func?.value}
                data-item={item.fields.iconDmp[0]?.displayName}
                data-url={item.fields.pc_link?.value}
                key={index}
                onClick={() => {
                  window.Dmp.clickAction(item.fields.iconDmp[0]?.displayName, item.fields.pc_link?.value);
                }}
              >
                <img alt="广汽本田" src={`${createFilePath(item.fields.icon.value)}`} />
                <p>{item.fields.text.value}</p>
              </a>
            ) : (
              <div
                key={index}
                onClick={() => {
                  window.Dmp.decideAction('home-side-consult', !expand);
                  setExpand(!expand);
                }}
              >
                <img alt="广汽本田" src={`${createFilePath(item.fields.icon.value)}`} />
                <div>
                  {item.fields.text.value}
                  {expand && (
                    <div className="image-container">
                      <div>
                        <img
                          alt="广汽本田"
                          className="pc"
                          src={createFilePath('/homePage/v1/pc/suspension/pc.png')}
                          onClick={(e) => {
                            e.stopPropagation();
                            PopLive800();
                            window.Dmp.clickAction('home-side-customer', item.fields.pc_link.value);
                          }}
                        />
                        网页版
                      </div>
                      <div>
                        <img alt="广汽本田" className="qrcode" src={createFilePath('/homePage/v1/pc/suspension/qrcode.png')} />
                        微信版
                      </div>
                    </div>
                  )}
                </div>
                <img alt="广汽本田" className="arrow" src={createFilePath(`/homePage/v1/pc/suspension/arrow-${expand ? 'up' : 'down'}.png`)} />
              </div>
            );
          })}
        </div>
      </div>
      <div
        onClick={() => {
          setNum(1);
          setTimeout(() => {
            setFlag(true);
            window.Dmp.clickAction(props.fields.hoverDmp[0]?.displayName, '', '');
          }, 200);
        }}
        onMouseOver={() => {
          setNum(2);
          setTimeout(() => {
            if (flag) {
              window.Dmp.hoverAction(props.fields.hoverDmp[0]?.displayName);
            }
            setFlag(false);
          }, 200);
        }}
        className="dg"
      >
        <img
          alt="广汽本田"
          className={`${num === 2 ? 'pcBtn1' : 'pcBtn2'}`}
          src={`${createFilePath(props.fields.plus.value)}`}
          onClick={(e) => {
            let target = e.currentTarget;
            const { left, top } = target.getBoundingClientRect();
            target.style.setProperty('--x', e.clientX - left + 'px');
            target.style.setProperty('--y', e.clientY - top + 'px');
          }}
        />
      </div>
    </div>
  ) : (
    <div className="btnList" style={{ background: '#343434' }}>
      {props.fields.list.map((item, index) => {
        return index !== 0 ? (
          <a
            href={item.fields.link.value}
            key={index}
            data-action={item.fields.iconDmp[0]?.fields?.func?.value}
            data-item={item.fields.iconDmp[0]?.displayName}
            data-url={item.fields.link?.value}
            onClick={() => {
              window.Dmp.clickAction(item.fields.iconDmp[0]?.displayName, item.fields.link?.value);
            }}
          >
            <img alt="广汽本田" src={`${createFilePath(item.fields.icon_m.value)}`} />
            <p style={{ color: 'white' }}>{item.fields.text.value}</p>
          </a>
        ) : (
          <div
            key={index}
            onClick={() => {
              PopLive800();
              window.Dmp.clickAction(item.fields.iconDmp[0]?.displayName, '', item.fields.link?.value);
            }}
          >
            <img alt="广汽本田" src={`${createFilePath(item.fields.icon_m.value)}`} />
            <p style={{ color: 'white' }}>{item.fields.text.value}</p>
          </div>
        );
      })}
    </div>
  );
}
export default index;
