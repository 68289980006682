import React from 'react';
import './style.scss';
import temp from '../../../temp/config';
import FullModal from './FullModal';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMobileFun, createFilePath } from '../../../utils/obj-utils';
export default function index(props) {
  const isMobile = isMobileFun();
  const { closeImg, setFlag, videoSrc, videoIndex } = props;
  return (
    <FullModal>
      <div className="detailsVideo">
        <div>
          <img
            alt="广汽本田"
            src={createFilePath(closeImg)}
            onClick={() => {
              setFlag(false);
              window.Dmp.clickAction(videoIndex, '', '');
            }}
          />
        </div>
        <video src={createFilePath(videoSrc)} controls preload="auto" height="100%" width="100%" autoPlay="autoPlay"></video>
      </div>
    </FullModal>
  );
}
