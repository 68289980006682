/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, useEffect, useRef, useState, useImperativeHandle } from 'react';
import './style.scss';
import temp from '../../temp/config';
import { realiboxInit } from '../../utils/realiboxCaller';
import Realibox from '../../elements/Realibox';
import Script from 'react-load-script';
import { getPrefixField, sendDmpAction, scrollToAnchor, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { fields } = props;
  const timelineInfoList = [
    { timeLine: '2350236989390848076', delay: 2000, color: '61,127,182', title: '英气凌厉侧颜' },
    { timeLine: '2350236724118945868', delay: 2000, color: '154,25,33', title: '都市锐雅前脸' },
    { timeLine: '2350236746032087116', delay: 0, color: '54,82,159', title: '超大容量后备厢' },
    { timeLine: '2350236752276357280', delay: 2000, color: '227,226,227', title: '宽敞静谧空间' }
  ];
  const sceneId = '2349968673414840480';

  const videoRef = useRef(null);
  const [Index, setIndex] = useState(0);
  const [windowHeight, setWindowHeight] = useState(400);
  const [windowWidth, setWindowWidth] = useState(375);
  const [thisMobile, setThisMobile] = useState(true);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [sceneLoadedProgress, setSceneLoadedProgress] = useState(0);
  const rootEl = useRef(null);
  const canvasRef = useRef(null);
  const timestamp = fields?.timestamp?.value;
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
    setThisMobile(document.documentElement.clientWidth > 1024);
    setWindowWidth(document.documentElement.clientWidth);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
      setThisMobile(document.documentElement.clientWidth > 1024);
      setWindowWidth(document.documentElement.clientWidth);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    window.Dmp.sectionAction('show360');
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : 'show360';
  };

  const [flag, setFlag] = useState(true);

  var app;
  const handleScriptLoad = (res) => {
    if (!app) {
      if (canvasRef.current) {
        app = realiboxInit(
          // `${temp.app.deployUrlS3}/breeze/v1/pc/show360/scene-360.rbs`,
          createFilePath('/breeze/v1/pc/show360/scene-360.rbs'),
          canvasRef.current,
          {
            sceneId: sceneId,
            startIndex: 0,
            timelineInfoList: timelineInfoList,
            loop: true,
            callback: interactionEndedCallback,
            onloadCallback: () => {
              setFirstLoaded(true);
            },
            progressCallback: (progress) => {
              setSceneLoadedProgress(Math.trunc(progress * 100));
            }
          },
          timestamp
        );
      }
    }
  };
  const interactionEndedCallback = (nextIndex) => {
    setIndex(nextIndex);
  };
  // 转换移动端视频格式
  useEffect(() => {
    return () => {
      if (app) {
        app.destroy();
      }
    };
  }, []);

  return (
    <Fragment>
      {/* <Script url={`${temp.app.deployUrlS3}/common/lib/realibox/v2.0.0/realibox.min.js`} onLoad={handleScriptLoad} /> */}
      <Script url={createFilePath('/common/lib/realibox/v2.0.0/realibox.min.js')} onLoad={handleScriptLoad} />
      <div className="ExhibitionHall" id="show360" ref={rootEl}>
        <div className="ExhibitionHallCenter">
          {flag ? (
            <div className="ExhibitionHallCenterTop">
              <div className="SideFace">
                <div style={{ color: 'rgb(255,255,255)' }}>
                  <p>{timelineInfoList[Index].title}</p>
                  <div
                    className="strip"
                    style={{
                      background: `-webkit-linear-gradient(left, rgba(${timelineInfoList[Index].color},1)0%,rgba(${timelineInfoList[Index].color},0.5)66%, rgba(${timelineInfoList[Index].color},0)99%) `
                    }}
                  />
                </div>
                <div>
                  <button
                    onClick={(e) => {
                      sendDmpAction('click', 'show360-drive');
                      scrollToAnchor('drive', 'start');
                    }}
                    style={{ background: `rgba(${timelineInfoList[Index].color},0)` }}
                  >
                    <span className="btnText">预约试驾</span>
                  </button>
                </div>
              </div>
              <div className="Cloud">
                <div>
                  <div style={{ background: `rgba(${timelineInfoList[Index].color},0.8)` }} />
                  <div style={{ background: `rgba(${timelineInfoList[Index].color},0.5)` }} />
                  <div style={{ background: `rgba(${timelineInfoList[Index].color},0.1)` }} />
                </div>

                <div>
                  <img
                    data-action="clickAction"
                    data-item="show360-show"
                    data-url="https://breeze360.ghac.cn/"
                    src={createFilePath('/breeze/v1/pc/other/Frame.png')}
                    className="rightIcon"
                    style={{ background: `rgb(${timelineInfoList[Index].color})` }}
                    alt="全景看车"
                  />
                  <p data-action="clickAction" data-item="show360-show" data-url="https://breeze360.ghac.cn/">
                    全景看车
                  </p>
                  <a
                    target="_blank"
                    data-action="clickAction"
                    data-item="show360-show"
                    data-url="https://breeze360.ghac.cn/"
                    href="https://breeze360.ghac.cn/"
                  />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="ExhibitionCar">
            <Realibox canvasRef={canvasRef} width={windowWidth} height={windowHeight} loading={!firstLoaded} progress={sceneLoadedProgress} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
