import React, { useState, useEffect } from 'react';
import './style.scss';
import temp from '../../temp/config';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMobileFun, createFilePath } from '../../utils/obj-utils';
export default function index(props) {
  const isMobile = isMobileFun();

  const { backColor, img, list, selectIconUP, selectIconDN } = props.fields;
  const [flag, setFlag] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [listIndex, setListIndex] = useState([0]);
  const [secondLightColor, setSecondLightColor] = useState([]);
  useEffect(() => {
    if (isMobile) {
      document.querySelectorAll('.listSecond')[0].style.display = 'grid';
    } else {
      document.querySelectorAll('.listSecond')[0].style.display = 'block';
    }
  }, []);
  return (
    <div className={`Operation_footer_v5${isMobile ? '_m' : ''}`}>
      <div>
        <div>
          {!flag ? (
            <img alt="广汽本田" src={createFilePath(img.value)} />
          ) : (
            <video src={createFilePath(videoUrl)} controls preload="auto" height="100%" width="100%" autoPlay="autoPlay"></video>
          )}
        </div>
        <div>
          {list.map((item, index) => {
            return (
              <div key={index}>
                <div
                  onClick={() => {
                    if (listIndex.indexOf(index) !== -1) {
                      var arr = listIndex;
                      listIndex.splice(listIndex.indexOf(index));
                      setListIndex(arr);
                      document.querySelectorAll('.imgFlag')[index].setAttribute('src', createFilePath(selectIconDN.value));
                      document.querySelectorAll('.listSecond')[index].style.display = 'none';
                    } else {
                      var arr = listIndex;
                      listIndex.push(index);
                      setListIndex(arr);
                      document.querySelectorAll('.imgFlag')[index].setAttribute('src', createFilePath(selectIconUP.value));
                      if (isMobile) {
                        document.querySelectorAll('.listSecond')[index].style.display = 'grid';
                      } else {
                        document.querySelectorAll('.listSecond')[index].style.display = 'block';
                      }
                    }
                  }}
                >
                  <p>{item.fields.title.value}</p>
                  <img
                    alt="广汽本田"
                    src={createFilePath(listIndex.indexOf(index) === -1 ? selectIconDN.value : selectIconUP.value)}
                    className="imgFlag"
                  />
                </div>
                <div
                  style={{ display: listIndex === index && !isMobile ? 'block' : listIndex === index && isMobile ? 'grid' : 'none' }}
                  className="listSecond"
                >
                  {item.fields.childrenList.map((ite, idx) => {
                    return (
                      <p
                        key={idx}
                        onClick={(e) => {
                          if (!flag) {
                            setFlag(true);
                          }
                          setSecondLightColor(index + '' + idx);
                          setVideoUrl(ite.fields.video.value);
                        }}
                        className={index + '' + idx === secondLightColor ? 'list_s_Active' : ''}
                      >
                        {ite.fields.title.value}
                      </p>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
