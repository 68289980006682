/* eslint-disable */
Number.prototype.round0 = function () {
  let s = this + '';
  if (s.indexOf('.') !== -1) {
    let numArr = s.split('.');
    let dotStr = numArr[1];
    let intStr = numArr[0];
    let intNum = parseInt(intStr);
    let firstDotNum = parseInt(dotStr.charAt(0));
    // 五舍六入
    // if (firstDotNum > 5) {
    //   intNum += 1;
    // }
    // 四舍五入
    if (firstDotNum > 4) {
      intNum += 1;
    }
    return intNum + '';
  }
  return this + '';
};

String.prototype.format = function (args) {
  let result = this;
  if (arguments.length > 0) {
    if (arguments.length === 1 && typeof args === 'object') {
      for (let key in args) {
        if (args[key] !== undefined) {
          let reg = new RegExp('({' + key + '})', 'g');
          result = result.replace(reg, args[key]);
        }
      }
    } else {
      for (let i = 0; i < arguments.length; i++) {
        if (arguments[i] !== undefined) {
          let reg = new RegExp('({)' + i + '(})', 'g');
          result = result.replace(reg, arguments[i]);
        }
      }
    }
  }
  return result;
};

export function number_format(number, decimals = 0, dec_point = '.', thousands_sep = ',') {
  /*
   * 参数说明：
   * number：要格式化的数字
   * decimals：保留几位小数
   * dec_point：小数点符号
   * thousands_sep：千分位符号
   * */
  if (number === undefined || number === null || number === '') {
    return number;
  }
  number = number.round0();
  number = (number + '').replace(/[^0-9+-Ee.]/g, '');
  let n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
      let k = Math.pow(10, prec);
      return '' + Math.floor(n * k) / k;
    };
  s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
  let re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2');
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

function PMT(yearRate, cycle, proportionPrice) {
  if (yearRate === 0) {
    return proportionPrice / cycle;
  } else {
    let v = 1 + yearRate / 12;
    let t = -(cycle / 12) * 12;
    return (proportionPrice * (yearRate / 12)) / (1 - Math.pow(v, t));
  }
}

let calcDataV2 = {
  loans: [
    // 贷款配置
    {
      calcType: 1,
      name: '广汽汇理汽金-等额本息',
      acceptCycle: [12, 18, 24, 36, 48, 60],
      rate: [0.0999, 0.0999, 0.0999, 0.0899, 0.0899, 0.0899], // 客户手续费率 12，18，24，36，48，60
      baseRate: [0, 0, 0, 0, 0, 0],
      extraText: '※ 金融方案仅供参考，详情请咨询各地特约店',
      desc: {
        text: '总共支付{total}元，首付{firstPay}元，贷款{totalYear}年，月供{monthPay}元，总利息{interest}元（分月支付）'
      },
      index: 1
    },
    {
      calcType: 3,
      name: '广汽汇理汽金-5050轻松贷',
      acceptCycle: [12], // 可接受的贷款期限
      rate: [0.1129],
      baseRate: [0, 0, 0, 0, 0, 0],
      desc: {
        text: '总共支付{total}元，首付{firstPay}元，贷款{totalYear}年，第12个月支付尾款{tail}元，总利息{interest}元（分月支付）'
      },
      index: 11
    },
    {
      calcType: 10,
      name: '招商银行-信用卡分期',
      acceptCycle: [12, 18, 24, 36], // 可接受的贷款期限
      rate: [0.05, 0.08, 0.105, 0.145, 0, 0], // 客户手续费率 12，18，24，36，48，60
      desc: {
        text: '总共支付{total}元，首付{firstPay}元，贷款{totalYear}年，月供{monthPay}元，分期手续费{interest}元（分月支付）'
      },
      index: 2
    },
    {
      calcType: 12,
      name: '建设银行-信用卡分期',
      acceptCycle: [12, 18, 24, 36],
      rate: [0.04, 0.06, 0.08, 0.12, 0, 0],
      desc: {
        text: '总共支付{total}元，首付{firstPay}元，贷款{totalYear}年，月供{monthPay}元，分期手续费{interest}元（一次性收取）'
      },
      index: 3
    },
    {
      calcType: 12,
      name: '工商银行-信用卡分期',
      acceptCycle: [12, 18, 24, 36],
      rate: [0.04, 0.06, 0.08, 0.12, 0, 0],
      desc: {
        text: '总共支付{total}元，首付{firstPay}元，贷款{totalYear}年，月供{monthPay}元，分期手续费{interest}元（一次性收取）'
      },
      index: 4
    },
    {
      calcType: 12,
      name: '农业银行-信用卡分期',
      acceptCycle: [12, 24, 36],
      rate: [0.04, 0, 0.08, 0.12, 0, 0],
      desc: {
        text: '总共支付{total}元，首付{firstPay}元，贷款{totalYear}年，月供{monthPay}元，分期手续费{interest}元（一次性收取）'
      },
      index: 5
    },
    {
      calcType: 12,
      name: '中国银行-信用卡分期',
      acceptCycle: [12, 18, 24, 36],
      rate: [0.0375, 0.06, 0.075, 0.1125, 0, 0],
      desc: {
        text: '总共支付{total}元，首付{firstPay}元，贷款{totalYear}年，月供{monthPay}元，分期手续费{interest}元（一次性收取）'
      },
      index: 6
    },
    {
      calcType: 13,
      name: '平安银行-等额本息',
      acceptCycle: [12, 18, 24, 36, 48, 60],
      rate: [0.095, 0.095, 0.095, 0.095, 0.09, 0.09],
      desc: {
        text: '总共支付{total}元，首付{firstPay}元，贷款{totalYear}年，月供{monthPay}元，总利息{interest}元（分月支付）'
      },
      index: 7
    },
    {
      calcType: 50501,
      name: '平安银行-5050贷款',
      acceptCycle: [12], // 可接受的贷款期限
      rate: [0.095], // 客户手续费率 12，18，24，36，48，60
      desc: {
        text: '总共支付{total}元，首付{firstPay}元，贷款{totalYear}年，第12个月支付尾款{tail}元，总利息{interest}元（分月支付）'
      },
      index: 8
    },
    {
      calcType: 13,
      name: '中信银行-等额本息',
      acceptCycle: [24, 36, 48, 60],
      rate: [0, 0, 0.089, 0.089, 0.09, 0.09],
      desc: {
        text: '总共支付{total}元，首付{firstPay}元，贷款{totalYear}年，月供{monthPay}元，分期手续费{interest}元（一次性收取）'
      },
      index: 9
    },
    {
      calcType: 50502,
      name: '中信银行-5050贷款',
      acceptCycle: [12], // 可接受的贷款期限
      rate: [0.088], // 客户手续费率 12，18，24，36，48，60
      desc: {
        text: '总共支付{total}元，首付{firstPay}元，贷款{totalYear}年，第12个月支付尾款{tail}元，总利息{interest}元（分月支付）'
      },
      index: 10
    }
  ]
};
/**
 * 计算银行数据
 * @param {Number} carPrice 车价格 例 200000
 * @param {Number} proportionProc 首付比例 例 20
 * @param {Number} cycle  贷款期数 例 12
 */
export function calcLoanSchemeV2(carPrice, proportionProc, cycle, carType, calcData) {
  let loans = (calcData || calcDataV2).loans;
  const list = [];
  if (carType.indexOf('enp1') !== -1) {
    (calcData || calcDataV2).loans.forEach((ele) => {
      if ([1].indexOf(ele.index) !== -1) {
        list.push(ele);
      }
    });
    loans = list;
  }
  // 首付比例 例0.2
  proportionProc = proportionProc / 100;
  // 指导价
  let totalPrice = carPrice;
  // 贷款金额
  let proportionPrice = parseInt(((1 - proportionProc) * totalPrice).round0());
  // console.log("指导价 " + totalPrice + " 贷款金额 " + proportionPrice);
  // 符合条件的贷款项 数组
  let schemes = [];
  for (let i = 0; i < loans.length; i++) {
    let loan = loans[i];
    let acceptCycle = loan.acceptCycle;
    if (acceptCycle.indexOf(cycle) >= 0) {
      if (loan.calcType === 3 || loan.calcType === 50501 || loan.calcType === 50502) {
        if (proportionProc === 0.5) {
          schemes.push(loan);
        }
      } else {
        schemes.push(loan);
      }
    }
  }

  let cycleList = [12, 18, 24, 36, 48, 60];
  let procList = [20, 30, 40, 50, 60, 70, 80, 90];

  function jieCheng(value, jieShuo) {
    let sum = 1.0;
    for (let i = 1; i <= jieShuo; i++) {
      sum = sum * value;
    }
    return sum;
  }
  let result = [];
  for (let schemIndex = 0; schemIndex < schemes.length; schemIndex++) {
    let item = schemes[schemIndex];
    // 总支付
    let total = 0;
    // 首付款
    let firstPay = parseInt(proportionProc * totalPrice);
    // 贷款年限
    let totalYear = parseFloat((cycle / 12).toFixed(1));
    // 月供
    let monthPay = 0;
    // 总利息
    let interest = 0;
    // 尾款
    let tail = 0;
    // 中段款
    let middlePay = 0;
    // 年利率
    let yearRate = item.rate[cycleList.indexOf(cycle)];
    // 建设银行 雅阁 雅阁混动 凌派 锋范 奥德赛 12期免息
    if (item.calcType === 1) {
      // 广汽汇理汽金-等额本息
      monthPay = PMT(yearRate, cycle, proportionPrice);
      // 总利息 =月供*贷款期数-首付款
      // 总支付 =首付款+月供*期数
      interest = monthPay * cycle - proportionPrice;
      total = firstPay + monthPay * cycle;
    } else if (item.calcType === 3) {
      // 广汽汇理汽金-5050轻松贷
      // 总利息=贷款金额-贷款金额/jieCheng((1+年利率/12)，12)
      // 总支付=首付款+贷款金额+总利息
      // 第12个月支付尾款=贷款金额
      interest = proportionPrice - proportionPrice / jieCheng(1 + yearRate / 12, 12);
      total = firstPay + proportionPrice + interest;
      tail = proportionPrice;
    } else if (item.calcType === 50501) {
      // 平安银行的5050贷款
      interest = (proportionPrice * yearRate * 365) / 360;
      total = firstPay + proportionPrice + interest;
      tail = totalPrice - firstPay;
    } else if (item.calcType === 50502) {
      // 中信银行的5050贷款
      interest = proportionPrice * yearRate;
      total = firstPay + proportionPrice + interest;
      tail = totalPrice - firstPay;
    } else if (item.calcType === 10) {
      // 招商银行贷款计算
      interest = proportionPrice * yearRate;
      monthPay = (proportionPrice + interest) / cycle; // 利息分期付
      total = firstPay + proportionPrice + interest;
    } else if (item.calcType === 12) {
      // 建设、工商、农业、中国银行
      interest = proportionPrice * yearRate;
      monthPay = proportionPrice / cycle; // 利息一次付
      total = firstPay + proportionPrice + interest;
    } else if (item.calcType === 13) {
      // 中信银行 平安银行
      monthPay = PMT(yearRate, cycle, proportionPrice);
      // 总利息 =月供*贷款期数-首付款
      // 总支付 =首付款+月供*期数
      interest = monthPay * cycle - proportionPrice;
      total = firstPay + monthPay * cycle;
    }
    let text = item.desc.text.format({
      total: number_format(total, 0, '.', ','),
      firstPay: number_format(firstPay, 0, '.', ','),
      totalYear: totalYear + '',
      monthPay: number_format(monthPay, 0, '.', ','),
      interest: number_format(interest, 0, '.', ','),
      tail: number_format(tail, 0, '.', ','),
      middlePay: number_format(middlePay, 0, '.', ',')
    });
    result.push({
      loan: item,
      desc: text
    });
  }
  return result;
}
