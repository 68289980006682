import React, { useState, useEffect } from 'react';
import './style.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ManualList from '../../elements/ManualList';
import { dataFetcher } from '../../dataFetcher';
import $ from 'jquery';
import { isMobileFun } from '../../utils/obj-utils';

let jsApiListArr = ['updateAppMessageShareData', 'updateTimelineShareData'];
export default function index(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const carList = fields.car_list;
  const [data, _] = useState({ isMobile, carList });
  const [wxLink, setWxLink] = useState('');
  const [isWx, setIsWx] = useState(false);
  const [isWxReady, setIsWxReady] = useState(false);

  useEffect(() => {
    const isWx = window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) === 'micromessenger';
    setIsWx(isWx);

    if (isWx) {
      dataFetcher('/api/honda/v1/DWP/GetDWPWeChatShareData?shareUrl=' + encodeURIComponent(location.href), null)
        .then((res) => {
          const { data } = res;
          wx.config({
            debug: false,
            appId: data.Data.appId,
            timestamp: data.Data.timestamp,
            nonceStr: data.Data.noncestr,
            signature: data.Data.signature,
            jsApiList: jsApiListArr,
            openTagList: ['wx-open-launch-weapp']
          });
          wx.ready(() => setIsWxReady(true));
        })
        .catch((err) => {});
    } else {
      // $.ajax({
      //   url: 'https://www.ghac.cn/api/v1/WeChatShare/GetDwpWeChatUrlLink',
      //   type: 'GET',
      //   async: false,
      //   dataType: 'json',
      //   success: function success(data) {
      //     setWxLink(data.info.openlink);
      //   },
      //   error: function error(xhr, type, exception) {}
      // });
      dataFetcher('/api/honda/v1/DWP/GetDwpWeChatUrlLink')
        .then(({ data }) => {
          if (data.Status) {
            setWxLink(data.Data.info.openlink);
          } else {
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return () => {};
  }, []);

  return (
    <section>
      <div className="manual_v1">
        <div className="manual_main">
          {!data.isMobile && (
            <div className="staticHead">
              <h3>车辆操作指南</h3>
              <p className="breadcrumb">
                <a href="/">首页</a>
                <em>&gt;</em>
                <a href="/service/service-item">客户服务</a>
                <em>&gt;</em>
                <span>车辆操作指南</span>
              </p>
            </div>
          )}
          <ManualList carList={data.carList} isMobile={data.isMobile} isWxReady={isWxReady} isWx={isWx} wxLink={wxLink} />
        </div>
      </div>
    </section>
  );
}
