import React, { useState, useEffect, useRef } from 'react';
import { createFilePath, checkMobile, getPrefixField, isEmpty, getQueryString, sendDmpAction, sendDmp, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { dataFetcher } from '../../dataFetcher';
import { getQueryVariable } from '../../utils/site-utils';
import { useHistory } from 'react-router-dom';
import FindDealer from '../../elements/FindDealer';
import FullModalDetail from '../../elements/FullModalDetail_v1';
import temp from '../../temp/config';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const history = useHistory();
  const isMobile = isMobileFun();
  const method = fields.method.value || 2;
  const carList = fields.carData.value ? JSON.parse(fields.carData.value.replaceHtml()) : '';
  const typeList = fields.typeData.value ? JSON.parse(fields.typeData.value.replaceHtml()) : '';
  const dmpList = fields.dmp.value ? JSON.parse(fields[getPrefixField('dmp', isMobile)]?.value.replaceHtml()) : '';
  const car = getQueryVariable('car_id') ? carList.find((item) => item.modelName.toLowerCase() === getQueryVariable('car_id').toLowerCase()) : '';
  const carIndex = carList.indexOf(car);
  const [selectVehicleType, setSelectVehicleType] = useState(carList[carIndex]);
  const [agree, setAgree] = useState(false);
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [verificationImage, setVerificationImage] = useState();
  const [messageInfo, setMessageInfo] = useState({ sendType: 1, validWhiteList: false, whiteListType: 0 });
  const [policyVersion, setPolicyVersion] = useState(null);
  const [mapShow, setMapShow] = useState(false);
  // 预约参数模板
  const [person, setPerson] = useState({
    userName: '',
    mobile: '',
    provinceId: '',
    cityId: '',
    dealerId: '',
    type: car ? car.type : '',
    modelName: car ? car.modelName : '',
    mediafrom: method,
    graphic: {
      code: '', // 用户录入的图形验证码
      id: '', // 图形验证码id
      preMod: false
    },
    smsValidId: '', // 短信验证码id
    smsValidCode: '', // 用户录入的短信验证码
    series_code: '',
    device_type: isMobile ? 2 : 1,
    areaCode: '',
    adcode: '',
    contactid: useSitecoreContext()?.sitecoreContext?.contactId
  });
  const verificationCodeRef = useRef(null);
  const messageCodeRef = useRef(null);
  const lastPerson = useRef(null);
  // 版本号
  const getPolicyVersion = () => {
    dataFetcher('/api/honda/v1/Policy/GetPolicyVersion')
      .then(({ data }) => {
        if (data.Status) {
          setPolicyVersion(data?.Data?.policy_code);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getPolicyVersion();
  }, []);
  // 选择类型
  const selecttype = (e) => {
    let val = e.target.childNodes[e.target.selectedIndex].getAttribute('value');
    if (val) {
      person.type = val;
      person.modelName = '';
      let newPerson = { ...person };
      setPerson(newPerson);
      setSelectVehicleType('');
      if (dmpList) {
        window.Dmp.msgAction(dmpList.type, val);
      }
    }
  };
  // 请选择车型
  const selectcar = (e) => {
    let val = e.target.childNodes[e.target.selectedIndex].getAttribute('value');
    if (val) {
      person.modelName = val;
      let car = carList.find((item) => item.modelName === val);
      let newPerson = { ...person };
      setPerson(newPerson);
      setSelectVehicleType(car);
      if (dmpList) {
        window.Dmp.msgAction(dmpList.car, val);
      }
    }
  };

  const verificationCodeChange = (e) => {
    messageInfo.validCode = e.target.value;
  };

  const messageCodeChange = (e) => {
    person.smsValidCode = e.target.value;
  };

  const addLeads = () => {
    delete person.type;
    dataFetcher('/api/honda/v1/Leads/AddLeads', person)
      .then(({ data }) => {
        if (data?.Status) {
          const personData = {
            n: person.userName,
            tel: person.mobile,
            prv: person.provinceId,
            city: person.cityId,
            dl: person.dealerId,
            ver: policyVersion
          };
          alert('提交成功！');
          sendDmpAction('submit', 'drive', personData);
          lastPerson.current = { ...person };
          close();
          history.push('/buy/tools/result');
        } else {
          const errorCode = parseInt(data?.ErrorResult?.ErrCode);
          if (errorCode === 26 || errorCode === 27 || errorCode === 28) {
            if (showVerificationCode) {
              // return Promise.reject(new Error('短信验证码输入错误！'));
              alert('短信验证码输入错误！');
            } else {
              setShowVerificationCode(true);
              getVerificationImage();
            }
          } else {
            // return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
            alert(data?.ErrorResult?.ErrMsg);
          }
        }
      })
      .catch((error) => {
        alert(error?.message);
        console.log(error);
      });
  };

  const close = () => {
    setPerson({
      ...person,
      graphic: {
        code: '',
        id: '',
        preMod: false
      },
      smsValidId: '',
      smsValidCode: ''
    });
    setMessageInfo({ ...messageInfo, validId: '', validCode: '', phone: '' });
    verificationCodeRef?.current && (verificationCodeRef.current.value = '');
    messageCodeRef?.current && (messageCodeRef.current.value = '');
    setShowVerificationCode(false);
  };

  const getVerificationImage = () => {
    dataFetcher('/api/honda/v1/Graphic/CreateCode')
      .then(({ data }) => {
        if (data?.Status) {
          person.graphic.id = data?.Data?.GraphicValidId;
          messageInfo.validId = data?.Data?.GraphicValidId;
          messageInfo.validCode = '';
          verificationCodeRef.current.value = '';
          setVerificationImage(data?.Data?.Base64);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const personSubmit = () => {
    if (!person.type) {
      alert('请选择类型');
      return;
    }
    if (!selectVehicleType) {
      alert('请选择车型');
      return;
    }
    if (isEmpty(person.userName)) {
      alert('请填写您的真实姓名');
      return;
    }
    if (!checkMobile(person.mobile)) {
      alert('请填写正确的联系方式');
      return;
    }
    if (!person.provinceId) {
      alert('请选择省份');
      return;
    }
    if (!person.cityId) {
      alert('请选择城市');
      return;
    }
    if (!person.dealerId) {
      alert('请选择特约店');
      return;
    }
    if (!agree) {
      alert('请确认并阅读声明');
      return;
    }
    if (showVerificationCode && isEmpty(person.smsValidCode)) {
      alert('短信验证码不能为空');
      return;
    }
    person.series_code = selectVehicleType?.seriesId;
    person.adcode = getQueryString('adcode');
    person.graphic.code = messageInfo?.validCode;
    if (lastPerson?.current) {
      let exist = false;
      for (const key in lastPerson?.current) {
        if (!['graphic', 'smsValidId', 'smsValidCode'].includes(key) && lastPerson?.current[key] !== person[key]) {
          exist = true;
          break;
        }
      }
      if (!exist) {
        alert('重复提交！');
        return;
      }
    }
    addLeads();
  };

  const sendMessage = () => {
    if (isEmpty(messageInfo?.validCode)) {
      alert('请输入图形验证码！');
      return;
    }
    messageInfo.phone = person.mobile;
    dataFetcher('/api/honda/v1/SMS/SendSMS', messageInfo)
      .then(({ data }) => {
        if (data.Status) {
          alert('已发送短信,请注意查收');
          person.smsValidId = data?.Data?.smsValidId;
        } else {
          getVerificationImage();
          const errorCode = parseInt(data?.ErrorResult?.ErrCode);
          let errorMessage;
          switch (errorCode) {
            case -1:
              errorMessage = '短信发送失败！';
              break;
            case 5:
              errorMessage = '图形验证码错误！';
              break;
            case 11:
              errorMessage = '手机号当天发送次数超限！';
              break;
            case 12:
              errorMessage = '手机号发送次数超限，请1小时候再试！';
              break;
            case 13:
              errorMessage = '手机号发送频率过快，请稍后再试！';
              break;
            case 21:
              errorMessage = '非常抱歉，发送验证码时出现异常，请稍后再试！';
              break;
            default:
              errorMessage = data?.ErrorResult?.ErrMsg;
              break;
          }
          return Promise.reject(new Error(errorMessage));
        }
      })
      .catch((error) => {
        alert(error?.message);
        console.log(error);
      });
  };
  const setPersonchild = (e) => {
    let newPerson = { ...person };
    newPerson.provinceId = e?.provinceId;
    newPerson.cityId = e?.cityId;
    newPerson.dealerId = e?.dealerId;
    newPerson.areaCode = e?.areaCode;
    setPerson(newPerson);
  };
  return (
    <section>
      <div className="testDrive_v4">
        <div className="main">
          <div className="title-section">
            <h3 className="title">
              <p>预约试驾</p>
            </h3>
          </div>
          <div className="content">
            <div className="banner">
              <img src={`${createFilePath(fields[getPrefixField('banner', isMobile)]?.value)}`} alt="预约试驾" />
            </div>
            <div className="content_in">
              <div className="formBox">
                <div className="tip">
                  <span className="red">*</span>为必填项。您的信息我们将会完全保密，请您放心填写。
                </div>

                <div className="formitem">
                  <div className="n">
                    <span className="red">*</span>选择意向车型：
                  </div>
                  <div className="val">
                    <div className="item">
                      <i className="icon type_icon">
                        <img src={createFilePath('/test-drive/type_icon.png')} alt="type" />
                      </i>
                      <select
                        className="select"
                        onChange={(e) => {
                          selecttype(e);
                        }}
                        value={person.type ? person.type : 0}
                      >
                        <option value={0}>选择类型</option>
                        {typeList &&
                          typeList.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="item fr">
                      <i className="icon car_icon">
                        <img src={createFilePath('/test-drive/car_icon.png')} alt="car" />
                      </i>
                      <select
                        className="select"
                        onChange={(e) => {
                          selectcar(e);
                        }}
                        value={person.modelName ? person.modelName : 0}
                      >
                        <option value={0}>请选择车型</option>
                        {carList &&
                          carList.map((item, index) => {
                            if (item.type === person.type) {
                              return (
                                <option key={index} value={item.modelName}>
                                  {item.name}
                                </option>
                              );
                            }
                          })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="formitem">
                  <div className="n">
                    <span className="red">*</span>基本信息：
                  </div>
                  <div className="val">
                    <div className="item">
                      <i className="icon name_icon">
                        <img src={createFilePath('/test-drive/name_icon.png')} alt="name" />
                      </i>
                      <input
                        type="text"
                        className="text"
                        maxLength="10"
                        placeholder="您的真实姓名"
                        onInput={(e) => {
                          person.userName = e.target.value;
                          setPerson(person);
                        }}
                        onBlur={(e) => {
                          if (dmpList) {
                            window.Dmp.msgAction(dmpList.name, null);
                          }
                        }}
                      />
                    </div>
                    <div className="item fr">
                      <i className="icon tel_icon">
                        <img src={createFilePath('/test-drive/tel_icon.png')} alt="tel" />
                      </i>
                      <input
                        type="text"
                        className="text"
                        maxLength="12"
                        placeholder="您的手机号码"
                        onInput={(e) => {
                          if (checkMobile(e.target.value)) {
                            person.mobile = e.target.value;
                            setPerson(person);
                          }
                        }}
                        onBlur={(e) => {
                          const dmpPointList = fields.tel_dmp_point;
                          if (dmpList) {
                            window.Dmp.msgAction(dmpList.tel, null);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <FindDealer
                  props={props}
                  istestdrive={true}
                  car={car}
                  selectVehicleType={selectVehicleType}
                  mapShow={true}
                  setPersonparent={setPersonchild}
                />

                {isMobile && (
                  <>
                    <div className="formagree">
                      <label>
                        <input
                          type="checkbox"
                          checked={agree}
                          onChange={() => {
                            setAgree((agree) => !agree);
                            if (dmpList) {
                              window.Dmp.decideAction(dmpList.btn1, !agree);
                            }
                          }}
                        />
                        本人同意
                        <a
                          href="/policy/privacy-policy"
                          target="_blank"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (dmpList) {
                              window.Dmp.clickAction(dmpList.btn2, '/policy/privacy-policy');
                            }
                          }}
                        >
                          《广汽本田隐私政策》
                        </a>
                        ；广汽本田将对您提交的个人信息保密，不对外公开。
                      </label>
                    </div>

                    <div className="formsubmit">
                      <button
                        type="button"
                        onClick={() => {
                          personSubmit();
                          if (dmpList) {
                            window.Dmp.clickAction(dmpList.btn4);
                          }
                        }}
                      >
                        确认提交
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="formmapbox"></div>
            </div>
            {!isMobile && (
              <>
                <div className="formagree">
                  <label>
                    <input
                      type="checkbox"
                      checked={agree}
                      onChange={() => {
                        setAgree((agree) => !agree);
                        if (dmpList) {
                          window.Dmp.decideAction(dmpList.btn1, !agree);
                        }
                      }}
                    />
                    本人同意
                    <a
                      href="/policy/privacy-policy"
                      target="_blank"
                      onClick={(event) => {
                        event.stopPropagation();
                        if (dmpList) {
                          window.Dmp.clickAction(dmpList.btn2, '/policy/privacy-policy');
                        }
                      }}
                    >
                      《广汽本田隐私政策》
                    </a>
                    ；广汽本田将对您提交的个人信息保密，不对外公开。
                  </label>
                </div>

                <div className="formsubmit">
                  <button
                    type="button"
                    onClick={() => {
                      personSubmit();
                      if (dmpList) {
                        window.Dmp.clickAction(dmpList.btn4);
                      }
                    }}
                  >
                    确认提交
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        {showVerificationCode && (
          <FullModalDetail style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="financial-calculator-v1-verification-code">
              <div className="verification-code-form">
                <img
                  alt="广汽本田"
                  className="verification-code-close"
                  src={createFilePath('/financial/calculator/close.png')}
                  onClick={() => close()}
                />
                <div className="verification-code-item">
                  <div className="verification-code-item-name">手机号：</div>
                  <div className="verification-code-item-right">{person?.mobile}</div>
                </div>
                <div className="verification-code-item">
                  <div className="verification-code-item-name">图形验证码：</div>
                  <img alt="广汽本田" className="verification-code-item-image" src={verificationImage} onClick={getVerificationImage} />
                  <div className="verification-code-item-right">
                    <input
                      className="verification-code-item-input"
                      ref={verificationCodeRef}
                      type="text"
                      placeholder="请输入图形验证码"
                      onChange={verificationCodeChange}
                    />
                  </div>
                </div>
                <div className="verification-code-item">
                  <div className="verification-code-item-name">短信验证码：</div>
                  <button className="verification-code-item-button" onClick={sendMessage}>
                    发送
                  </button>
                  <div className="verification-code-item-right">
                    <input
                      className="verification-code-item-input"
                      ref={messageCodeRef}
                      type="text"
                      placeholder="请输入短信验证码"
                      onChange={messageCodeChange}
                    />
                  </div>
                </div>
                <button className="verification-code-button" onClick={() => personSubmit()}>
                  确定
                </button>
              </div>
            </div>
          </FullModalDetail>
        )}
      </div>
    </section>
  );
}
