import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, getQueryString, convertStylesStringToObject, getPrefixField, isMobileFun, createFilePath, isVideo } from '../../utils/obj-utils';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Navigation } from 'swiper';
import FullVideo from '../../elements/FullVideo';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const [windowHeight, setWindowHeight] = useState(400);
  const swiperRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const [flagindex, setFlagindex] = useState(0);
  const [flag1, setFlag1] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const isMobile = isMobileFun();
  const [showFooter, setShowFooter] = useState(true);
  const id = props?.params?.pagename;
  const data = {
    id
  };
  var val = fields?.data?.value.replace(/k:/gi, '"k":').replace(/v:/gi, '"v":').replace(/'/g, '"');
  var datalist = JSON.parse(val.replaceHtml());
  var videodata = {
    detail_video: createFilePath(datalist?.lists[flagindex].video),
    close_icon_dmp: {
      type: 'click',
      point: datalist?.lists[flagindex].closedmp,
      value1: createFilePath(datalist?.lists[flagindex].video)
    },
    play_video_dmp: datalist?.lists[flagindex]?.playdmp
      ? {
          type: 'click',
          point: datalist?.lists[flagindex].playdmp,
          value1: createFilePath(datalist?.lists[flagindex].video)
        }
      : '',
    stop_video_dmp: datalist?.lists[flagindex]?.stopdmp
      ? {
          type: 'click',
          point: datalist?.lists[flagindex].stopdmp,
          value1: createFilePath(datalist?.lists[flagindex].video)
        }
      : ''
  };
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
  };
  useEffect(() => {
    if (
      window.location.href.indexOf('accord-show') !== -1 ||
      getQueryString('source') === 'app' ||
      window.location.href.indexOf('app/vehicles/accord') !== -1
    ) {
      setShowFooter(false);
    }
  }, []);

  return (
    <>
      <div className={!showFooter ? 'ipfilm noheader' : 'ipfilm'} id={data?.id}>
        <div className="triggerstart" id="ipfilm_gird"></div>
        {datalist?.is_disabled_animate ? (
          <div className="acitved">
            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              className="modules-swiper"
              spaceBetween={0}
              initialSlide={0}
              observer={true}
              resizeObserver={true}
              updateOnWindowResize={true}
              modules={[Navigation]}
              navigation={{
                prevEl: '.ipfilm .prev',
                nextEl: '.ipfilm .next',
                disabledClass: 'disabled'
              }}
              onTransitionStart={(e) => {}}
              onSlideChangeTransitionEnd={(e) => {}}
            >
              {datalist?.lists?.map((item, index) => {
                return (
                  <SwiperSlide className="item" key={index} style={convertStylesStringToObject(item[`${getPrefixField('itemstyle', isMobile)}`])}>
                    {!isMobile ? (
                      <div
                        className="video"
                        data-html-item={item?.opendmp}
                        data-url={createFilePath(item?.video)}
                        onClick={() => {
                          setFlagindex(index);
                          setFlag(true);
                        }}
                      >
                        {isVideo(item?.poster) ? (
                          <video src={createFilePath(item?.poster)} autoPlay="autoplay" muted={true} loop />
                        ) : (
                          <img alt="广汽本田" src={createFilePath(item?.poster)} />
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="video">
                          <img alt="广汽本田" src={createFilePath(item?.m_poster)} />
                        </div>
                      </>
                    )}
                    <div className="text" style={convertStylesStringToObject(item[`${getPrefixField('textstyle', isMobile)}`])}>
                      <div dangerouslySetInnerHTML={{ __html: item[`${getPrefixField('text', isMobile)}`]?.replaceHtml() }}></div>
                      {isMobile && (
                        <div
                          className="btn"
                          data-html-item={item?.opendmp}
                          data-url={createFilePath(item?.video)}
                          onClick={() => {
                            setFlagindex(index);
                            setFlag(true);
                          }}
                          dangerouslySetInnerHTML={{ __html: item?.btn?.replaceHtml() }}
                        ></div>
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="next" data-html-item={datalist?.nextdmp}></div>
            <div className="prev" data-html-item={datalist?.prevdmp}></div>
          </div>
        ) : (
          <Controller>
            <Scene triggerHook={'onLeave'} duration={windowHeight} pin={true} reverse={true} triggerElement={'#ipfilm_gird'}>
              {(progress, event) => {
                return (
                  <div>
                    <Timeline totalProgress={progress} paused duration={1}>
                      <Tween from={{ scale: 0.9 }} to={{ scale: 1 }} duration={0.5}>
                        <div className={progress > 0.45 ? 'acitved' : ''}>
                          <Swiper
                            ref={swiperRef}
                            slidesPerView={1}
                            className="modules-swiper"
                            spaceBetween={0}
                            initialSlide={0}
                            observer={true}
                            resizeObserver={true}
                            updateOnWindowResize={true}
                            modules={[Navigation]}
                            navigation={{
                              prevEl: '.ipfilm .prev',
                              nextEl: '.ipfilm .next',
                              disabledClass: 'disabled'
                            }}
                            onTransitionStart={(e) => {}}
                            onSlideChangeTransitionEnd={(e) => {}}
                          >
                            {datalist?.lists?.map((item, index) => {
                              return (
                                <SwiperSlide
                                  className="item"
                                  key={index}
                                  style={convertStylesStringToObject(item[`${getPrefixField('itemstyle', isMobile)}`])}
                                >
                                  {!isMobile ? (
                                    <div
                                      className="video"
                                      data-html-item={item?.opendmp}
                                      data-url={createFilePath(item?.video)}
                                      onClick={() => {
                                        setFlagindex(index);
                                        setFlag(true);
                                      }}
                                    >
                                      {isVideo(item?.poster) ? (
                                        <video src={createFilePath(item?.poster)} autoPlay="autoplay" muted={true} loop />
                                      ) : (
                                        <img alt="广汽本田" src={createFilePath(item?.poster)} />
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      <div className="video">
                                        <img alt="广汽本田" src={createFilePath(item?.m_poster)} />
                                      </div>
                                    </>
                                  )}
                                  <div className="text" style={convertStylesStringToObject(item[`${getPrefixField('textstyle', isMobile)}`])}>
                                    <div dangerouslySetInnerHTML={{ __html: item[`${getPrefixField('text', isMobile)}`]?.replaceHtml() }}></div>
                                    {isMobile && (
                                      <div
                                        className="btn"
                                        data-html-item={item?.opendmp}
                                        data-url={createFilePath(item?.video)}
                                        onClick={() => {
                                          setFlagindex(index);
                                          setFlag(true);
                                        }}
                                        dangerouslySetInnerHTML={{ __html: item?.btn?.replaceHtml() }}
                                      ></div>
                                    )}
                                  </div>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                          <div className="next" data-html-item={datalist?.nextdmp}></div>
                          <div className="prev" data-html-item={datalist?.prevdmp}></div>
                        </div>
                      </Tween>
                    </Timeline>
                  </div>
                );
              }}
            </Scene>
          </Controller>
        )}
      </div>
      {flag && <FullVideo fields={videodata} close={() => setFlag(false)} />}
    </>
  );
}
