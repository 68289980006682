/* eslint-disable no-bitwise */
import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';
import { createFilePath } from '../../utils/obj-utils';
import temp from '../../temp/config';

export default function index() {
  const pageSize = 6;
  const [pageIndex, setPageIndex] = useState(1);
  const [data, setData] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    getData();
  }, [pageIndex]);

  const getData = () => {
    setIsLoading(true);
    dataFetcher(`/api/honda/v1/Article/GetArticleList?articleType=1&pageIndex=${pageIndex}&pageSize=${pageSize}&client=0`)
      .then(({ data: response }) => {
        setIsLoading(false);
        if (response.Status) {
          if (response?.Data[0]?.News?.articles?.length === 0) {
            setShowLoadMore(false);
          } else {
            setData([...data, ...response?.Data[0]?.News?.articles]);
          }
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const loadMore = () => {
    setPageIndex(pageIndex + 1);
  };

  const click = (item) => {
    if (item.a_title.indexOf('二十周年') > -1) {
      window.open(createFilePath('/service/environment/pdf/change_pdf/CSR报告官网版0627.pdf'), '_blank');
    } else if (item.a_title.indexOf('广汽本田公益二十年') > -1) {
      window.open(createFilePath('/service/environment/pdf/change_pdf/公益二十年印刷版.pdf'), '_blank');
    } else {
      window.open(`/news/detail?itempath=${item?.item_path?.replace(/{(.*?)}/g, '$1')}`, '_blank');
    }
  };

  return (
    <section className="news-v1" ref={containerRef}>
      <div className="title">新闻资讯</div>
      <ul className="list">
        {data.map((item, index) => {
          return (
            <li className="item" key={index} onClick={() => click(item)}>
              <div className="item-container">
                <div className="picture-container">
                  <img className="picture" src={createFilePath(item.a_thumb.replaceHtml())} alt={item.a_title} />
                </div>
                <div className="detail">
                  <div className="list-title-container">
                    <div className="list-title" dangerouslySetInnerHTML={{ __html: item.a_title }} />
                  </div>
                  <div className="bottom-container">
                    <div className="pubilsh-date">{moment(item.publish_date).format('YYYY-MM-DD')}</div>
                    <img alt="广汽本田" className="more-image" src={createFilePath('/common/icon/more.png')} />
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      {data.length > 0 && showLoadMore && !isLoading && (
        <div className="load-more-container" onClick={loadMore}>
          加载更多
        </div>
      )}
    </section>
  );
}
