import React, { useState, useImperativeHandle } from 'react';
import TextScrollytelling from '../../elements/TextScrollytelling';
import Detailss from '../../elements/InnerPageDetail';
import { sendDmpAction, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import temp from '../../temp/config';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const { fields } = props;
  const [flag, setFlag] = useState(false);
  // const deployUrlS3 = temp.app.deployUrlS3;
  // const video_value = `${deployUrlS3}${fields.dialog_video?.value}`;
  const video_value = createFilePath(fields.dialog_video?.value);
  const isMobile = isMobileFun();
  const openVideo = () => {
    setFlag(true);
    sendDmpAction(
      fields.video_btn_dmp?.length > 0 ? fields.video_btn_dmp[0]?.fields?.func?.value : '',
      fields.video_btn_dmp?.length > 0 ? fields.video_btn_dmp[0].name : '',
      fields.dialog_video?.value ? video_value : ''
    );
  };

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    window.Dmp.sectionAction(fields.id.value);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : '';
  };

  return (
    <div>
      <div
        className="htmlhidden"
        dangerouslySetInnerHTML={{
          __html: fields[`${getPrefixField('sethtml', isMobile)}`].value?.replaceHtml()
        }}
      ></div>
      <TextScrollytelling {...fields} flag={flag} open={() => openVideo()} />
      {flag && (
        <Detailss
          routePath={props.fields.detail_btn_href.value}
          {...fields}
          close={() => setFlag(false)}
          isModal={document.documentElement.clientWidth <= 1024}
        />
      )}
    </div>
  );
}
