import React, { useState, useRef, useImperativeHandle, useEffect, createRef } from 'react';
import Details from '../PriceSpace_v3/index';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { getPrefixField, sendDmp, isMobileFun } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';
export default function index(props) {
  const { fields, eventRef, params } = props;
  const isMobile = isMobileFun();
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const rootEl = useRef(null);
  const [tabNum, setTabNum] = useState(0);
  const secondPageRef = useRef(null);
  const tabs = fields?.tabs;
  const sectionDmp = fields?.sectionDmp;
  const placeHolderRoute = fields?.placeHolderRoute.value;
  const [data, _] = useState({
    tabs,
    sectionDmp,
    placeHolderRoute
  });

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(data?.sectionDmp);
    // 二级导航高亮设置
    window.navigationIndex = fields?.navigationIndex?.value;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'price_space';
  };

  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = 0;
      data.tabs.map((item, index) => {
        if (item.fields?.tab_name?.value === value) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    setTimeout(() => {
      switch (getQueryVariable('tab')) {
        case 'hyrid':
          setTabNum(queryTabDefault('hyrid'));
          break;
        case 'plugin':
          setTabNum(queryTabDefault('plugin'));
          break;
        case 'oil':
          setTabNum(queryTabDefault('oil'));
          break;
        case 'control':
          setTabNum(queryTabDefault('control'));
          break;
        default:
          setTabNum(queryTabDefault(fields?.default_tab_name?.value));
      }
    }, 1000);
    return () => {};
  }, []);

  useEffect(() => {
    if (tabNum === null) return;
    let c = secondPageRef.current.children;
    secondPageRef.current.style.width = `${c.length * 100}vw`;
    for (let i = 0; i < c.length; i++) {
      c[i].style.display = 'inline-block';
    }
    secondPageRef.current.style.transform = `translate3d(-${tabNum * 100}vw, 0px, 0px)`;

    return () => {};
  }, [tabNum]);

  return (
    <div className="PriceSpace_v5" id={params?.pagename !== undefined ? params?.pagename : 'price_space'} ref={rootEl}>
      <div className="tab_list">
        {data.tabs &&
          data.tabs.length > 0 &&
          data.tabs.map((item, index) => {
            return (
              <>
                {index !== 0 && <div className="split_line" dangerouslySetInnerHTML={{ __html: fields?.split_line.value?.replaceHtml() }} />}
                <div
                  key={index}
                  className={`tab_item ${index === tabNum ? 'tab_disabled' : ''}`}
                  onClick={() => {
                    setTabNum(index);
                    sendDmp(item?.fields?.tabDmp);
                  }}
                >
                  <div
                    className={index === tabNum ? 'tabContainer_active' : 'tabContainer'}
                    dangerouslySetInnerHTML={{ __html: item.fields?.tab?.value?.replaceHtml() }}
                  />
                </div>
              </>
            );
          })}
      </div>
      <div ref={secondPageRef}>
        <Placeholder
          name={data?.placeHolderRoute}
          updateDmpList={props.updateDmpList}
          updateRefList={props.updateRefList}
          rendering={route}
          page={route}
        />
      </div>
    </div>
  );
}
