import React, { useState } from 'react';
import './style.scss';
import { createFilePath, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const staticMenu = fields.staticMenu;
  const [data, _] = useState({ isMobile, staticMenu });

  return (
    <section>
      <div className="result_v1">
        <div className="result_main">
          <div className="staticHead">
            <h3>预约试驾</h3>
          </div>
          <div
            className="staticContent"
            style={{
              background: data.isMobile ? '' : `url(${createFilePath('/service/staticMenu.gif')}) repeat-y right top`
            }}
          >
            <div className="staticDetail">
              <div className="success clearFix">
                <p
                  className="successImg floatL"
                  style={{
                    background: `url(${createFilePath('/service/staticIcon.gif')}) no-repeat`,
                    backgroundPositionX: '0px',
                    backgroundPositionY: data.isMobile ? '-268px' : '-606px',
                    backgroundSize: data.isMobile ? '100px auto' : 'auto'
                  }}
                />
                <p className="successTip1 floatL">预约试驾提交成功！感谢您的参与。</p>
                <p className="successTip2 floatL">我们将会在24小时内安排客服人员与您联系。</p>
              </div>
            </div>
            {!data.isMobile && (
              <div className="staticMenu">
                <h2>
                  购车指南<span>BUYERS GUIDE</span>
                </h2>
                <ul>
                  {data.staticMenu.length > 0 &&
                    data.staticMenu.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={item.fields?.url.value} dangerouslySetInnerHTML={{ __html: item.fields?.title?.value?.replaceHtml() }} />
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
