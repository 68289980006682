/* eslint-disable no-eval */
import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { getPrefixField, getQueryString, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import temp from '../../temp/config';
import { dataFetcher } from '../../dataFetcher';

export default function index({ fields, eventRef, params }) {
  const containerRef = useRef(null);
  const isMobile = isMobileFun();
  const [type, setType] = useState(true);
  const [serviceDynamics, setServiceDynamics] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  const pageSize = 6;

  useEffect(() => {}, []);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields?.sectionDmp);
    // 二级导航高亮设置
    window.navigationIndex = fields?.navigationIndex?.value;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'price_power';
  };

  const getServiceDynamics = () => {
    let pi = pageIndex + 1;
    setPageIndex(pi);
    dataFetcher(`/api/honda/v1/Article/GetArticleList?articleType=3&client=0&pageIndex=${pi}&pageSize=${pageSize}`, null)
      .then(({ data }) => {
        if (data.Status) {
          let sd = [];
          if (data.Data && data.Data[0].ServiceDynamics) {
            data.Data[0].ServiceDynamics?.articles?.forEach((item) => {
              sd.push(item);
            });
            if (data.Data[0].ServiceDynamics?.articles?.length < pageSize) setType(false);
          }
          setServiceDynamics([...serviceDynamics, ...sd]);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formatDate = (item_date) => {
    let date = new Date(item_date);
    let YY = date.getFullYear() + '-';
    let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return YY + MM + DD;
  };

  useEffect(() => {
    getServiceDynamics();
    return () => {};
  }, []);

  return (
    <div className="ServiceDynamics_v1" id={params?.pagename !== undefined ? params?.pagename : 'service-dynamics'} ref={containerRef}>
      <div className="title">服务动态</div>
      <div className="lists">
        <ul>
          {serviceDynamics &&
            serviceDynamics.length > 0 &&
            serviceDynamics.map((item, index) => {
              return (
                <li key={index}>
                  <a
                    href={'/service/service-activities/details?itempath=' + item?.item_path?.replace(/{(.*?)}/g, '$1')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="name">
                      {item.a_title}
                      <span
                        className="nameafter"
                        style={{
                          background: `url("${createFilePath('/common/icon/servicemore.png')}") center center no-repeat`,
                          backgroundSize: '100%'
                        }}
                      />
                    </div>
                    <div className="time">{formatDate(item.publish_date)}</div>
                    <div className="detail">{item.a_subtitle}</div>
                  </a>
                  <span
                    className="liafter"
                    style={{
                      background: `url("${createFilePath('/common/icon/servicemore.png')}") center center no-repeat`,
                      backgroundSize: '100%'
                    }}
                  />
                </li>
              );
            })}
        </ul>
      </div>
      {!serviceDynamics || (serviceDynamics.length < 1 && <div className="empty">暂无相关数据</div>)}
      {serviceDynamics && serviceDynamics.length > 0 && type && (
        <a
          onClick={() => {
            getServiceDynamics();
          }}
          className="more"
        >
          加载更多
        </a>
      )}
    </div>
  );
}
