import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { isMobileFun, createFilePath, getPrefixField, isInView } from '../../utils/obj-utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import './style.scss';
import gsap from 'gsap';

export default function index(props) {
  const { fields } = props;
  const rootEl = useRef(null);
  const tabsswiperRef = useRef(null);
  const swiperRef = useRef(null);
  const once = useRef(true);
  const isMobile = isMobileFun();
  const [tabIndex, setTabIndex] = useState(0);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [paused, setPaused] = useState(true);
  const id = props?.params?.pagename;
  const data = {
    id
  };
  useEffect(() => {
    const scroll = () => {
      if (rootEl.current) {
        setPaused(!isInView(rootEl));
      }
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  useEffect(() => {
    if (isMobile) {
      return;
    }
    const timeline = gsap.timeline({
      duration: 1.5,
      onComplete: () => {
        timeline.kill();
      }
    });
    timeline.fromTo(tabsswiperRef?.current, { y: 0 }, { y: '60%' }, 0);
    timeline.fromTo(swiperRef?.current, { y: 0 }, { y: '8%' }, '<');
    if (paused) {
      return;
    }
    timeline.fromTo(tabsswiperRef?.current, { y: '60%' }, { y: 0 }, 0);
    timeline.fromTo(swiperRef?.current, { y: '8%' }, { y: 0 }, '<');
    return () => {
      timeline.kill();
    };
  }, [paused]);
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
  };

  const swiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
  };
  const swiperslidetabsTo = (index) => {
    tabsswiperRef.current.swiper.slideTo(index);
  };
  return (
    <div className="PriceControl_v1" id={id} ref={rootEl}>
      <Swiper
        ref={tabsswiperRef}
        slidesPerView={isMobile ? 3.5 : 5}
        className="tabs"
        initialSlide={0}
        observer={true}
        resizeObserver={true}
        updateOnWindowResize={true}
        navigation={false}
        onSlideChangeTransitionEnd={(e) => {}}
      >
        {fields?.lists.length > 0 &&
          fields?.lists?.map((item, index) => {
            return (
              item?.fields[getPrefixField('name', isMobile)]?.value && (
                <SwiperSlide
                  key={index}
                  className={`item ${index === tabIndex ? 'on' : ''}`}
                  onClick={() => {
                    setTabIndex(index);
                    setSwiperIndex(0);
                    swiperslideTo(0);
                    window.Dmp.clickAction(item?.fields?.tabsdmp?.value);
                    if (index) {
                      swiperslidetabsTo(index);
                    }
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: item?.fields[getPrefixField('name', isMobile)]?.value.replaceHtml() }}></div>
                </SwiperSlide>
              )
            );
          })}
      </Swiper>
      <div className="con">
        {fields?.lists[tabIndex]?.fields[getPrefixField('lists_in', isMobile)].length > 0 && (
          <>
            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              className="modules-swiper"
              spaceBetween={0}
              initialSlide={0}
              observer={true}
              resizeObserver={true}
              updateOnWindowResize={true}
              modules={[Navigation]}
              navigation={{
                prevEl: '.PriceControl_v1 .prev',
                nextEl: '.PriceControl_v1 .next',
                disabledClass: 'disabled'
              }}
              onSlideChangeTransitionEnd={(e) => {
                setSwiperIndex(e.activeIndex);
              }}
            >
              {fields?.lists[tabIndex]?.fields[getPrefixField('lists_in', isMobile)]?.map((item, index) => {
                return (
                  <SwiperSlide key={index} className="item">
                    {item?.fields?.pic?.value && (
                      <div className="img">
                        <img alt="广汽本田" src={`${createFilePath(item?.fields?.pic?.value)}`} />
                      </div>
                    )}
                    {item?.fields?.text?.value && (
                      <div className="text" dangerouslySetInnerHTML={{ __html: item?.fields?.text?.value.replaceHtml() }}></div>
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="next" data-html-item={fields?.lists[tabIndex]?.fields?.nextdmp?.value}>
              {fields?.lists[tabIndex]?.fields?.nexticon?.value && (
                <img alt="广汽本田" className="img" src={`${createFilePath(fields?.lists[tabIndex]?.fields?.nexticon?.value)}`} />
              )}
            </div>
            <div className="prev" data-html-item={fields?.lists[tabIndex]?.fields?.prevdmp?.value}>
              {fields?.lists[tabIndex]?.fields?.previcon?.value && (
                <img alt="广汽本田" className="img" src={`${createFilePath(fields?.lists[tabIndex]?.fields?.previcon?.value)}`} />
              )}
            </div>
            <div className="pagination">
              {fields?.lists[tabIndex]?.fields[getPrefixField('lists_in', isMobile)]?.map((item, index) => {
                return (
                  <span
                    key={index}
                    className={`pagination-bullets ${index === swiperIndex ? 'pagination-bullets-active' : ''}`}
                    onClick={() => {
                      swiperslideTo(index);
                      window.Dmp.clickAction(fields?.lists[tabIndex]?.fields?.paginationdmp?.value + (Number(index) + 1));
                    }}
                  ></span>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
