import React, { useEffect, useRef, useState } from 'react';
import gsap, { Linear } from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import temp from '../../../temp/config';
import { createFilePath } from '../../../utils/obj-utils';

function index(props) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const backgroundRef = useRef(null);
  const images = props?.swiperImages?.split(', ').map((item) => `${createFilePath(item)}`);
  const obj = { currentIndex: 0 };
  useEffect(() => {
    loadImage();

    const controller = new ScrollMagic.Controller();
    const timeline = gsap
      .timeline({ paused: true })
      .fromTo(
        obj,
        { ...obj },
        {
          currentIndex: images.length - 1,
          roundProps: 'currentIndex',
          immediateRender: true,
          ease: Linear.easeNone,
          onUpdate: function () {
            imageRef?.current?.setAttribute('src', images[obj?.currentIndex]);
          }
        }
      )
      .to(backgroundRef.current, { opacity: 1, duration: 0.25 })
      .to(containerRef.current, { opacity: 0, duration: 0.25 });

    const scene = new ScrollMagic.Scene({
      triggerElement: props?.rootEl?.current,
      triggerHook: 'onLeave',
      duration: props.swiperDuration ? props.swiperDuration : document.documentElement.clientHeight * 3
    })
      .setTween(timeline)
      .setPin(props?.rootEl?.current)
      .addTo(controller);

    return () => {
      timeline.kill();

      scene.remove();
      scene.destroy();

      controller.destroy();
    };
  }, []);

  const loadImage = () => {
    const length = images?.length;
    if (length > 0) {
      let index = 0;
      const node = document.createElement('img');
      node.src = images[index];
      node.onload = () => {
        if (index < length - 1) {
          node.src = images[++index];
        } else {
          setImageLoaded(true);
        }
      };
    }
  };

  return (
    <section className="price-sapce-v3-image-container" ref={containerRef}>
      <div className="price-sapce-v3-background" ref={backgroundRef} />
      <img alt="广汽本田" className="price-sapce-v3-image" ref={imageRef} src={images[obj?.currentIndex]} />
    </section>
  );
}

export default index;
