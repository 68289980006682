import React from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';
import './style.scss';
import { getPrefixField, convertStylesStringToObject, createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields }) {
  const history = useHistory();
  const isMobile = isMobileFun();
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const leftButton = fields[getPrefixField('leftButton', isMobile)]?.value;
  const rightButton = fields[getPrefixField('rightButton', isMobile)]?.value;
  const background = createFilePath(fields[getPrefixField('background', isMobile)]?.value);
  const backgroundStyle = convertStylesStringToObject(fields[getPrefixField('backgroundStyle', isMobile)]?.value);
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);

  const back = () => {
    history.goBack();
  };

  const backHome = () => {
    history.replace('/');
  };

  return (
    <section className="not-found-v1" style={style}>
      <img alt="广汽本田" className="background" src={background} style={backgroundStyle} />
      <div className="title" dangerouslySetInnerHTML={{ __html: title }} />
      <div className="button-container">
        <button className="back-button" dangerouslySetInnerHTML={{ __html: leftButton }} onClick={back} />
        <button className="back-button" dangerouslySetInnerHTML={{ __html: rightButton }} onClick={backHome} />
      </div>
    </section>
  );
}
