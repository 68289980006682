import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { isMobileFun, getPrefixField, convertStylesStringToObject, createFilePath } from '../../utils/obj-utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import Detailss from '../../elements/InnerPageDetail';
import 'swiper/swiper.min.css';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const swiperRef = useRef(null);
  const paginationswiperRef = useRef(null);
  const isMobile = isMobileFun();
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [flag, setFlag] = useState(false);
  const [videoflag, setVideoflag] = useState(false);
  const id = props?.params?.pagename;
  const count = 6;
  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span data-html-data="' +
        (fields?.paginationdmp?.value ? fields?.paginationdmp?.value + (index + 1) : '') +
        '" class="' +
        className +
        '"></span>'
      );
    }
  };
  const windowhidden = () => {
    document.documentElement.style.overflow = 'hidden';
  };
  const closewindowhidden = () => {
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.overflowX = 'hidden';
  };
  const chunkArray = (array, chunkSize) => {
    let chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };
  const swiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
  };
  return (
    <div className="Picture_v1" id={id}>
      <div className="hash"></div>
      <div className="con" style={convertStylesStringToObject(fields[`${getPrefixField('constyle', isMobile)}`]?.value)}>
        {fields[`${getPrefixField('title', isMobile)}`]?.value && isMobile && (
          <div
            className="title"
            style={convertStylesStringToObject(fields[`${getPrefixField('titlestyle', isMobile)}`]?.value)}
            dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}
          ></div>
        )}
        {fields?.lists.length > 0 && (
          <div className="lists">
            <>
              <Swiper
                ref={swiperRef}
                slidesPerView={1}
                className="swiper"
                spaceBetween={0}
                initialSlide={0}
                observer={true}
                resizeObserver={true}
                updateOnWindowResize={true}
                pagination={''}
                modules={[Pagination, Navigation]}
                navigation={{
                  prevEl: '.Picture_v1 .prev',
                  nextEl: '.Picture_v1 .next',
                  disabledClass: 'disabled'
                }}
                onInit={(e) => {
                  if (swiperRef?.current?.swiper?.pagination?.bullets && isMobile) {
                    for (var i = 0; i < swiperRef.current.swiper.pagination.bullets.length; i++) {
                      swiperRef.current.swiper.pagination.bullets[i].onclick = function (item) {
                        if (item.currentTarget.dataset.htmlData) {
                          window.Dmp.clickAction(item.currentTarget.dataset.htmlData);
                        }
                      };
                    }
                  }
                }}
                onSlideChangeTransitionEnd={(e) => {
                  setSwiperIndex(e.activeIndex);
                  if (paginationswiperRef.current) {
                    paginationswiperRef.current.swiper.slideTo(e.activeIndex);
                  }
                }}
                onSlidePrevTransitionEnd={(e) => {
                  if (!isMobile && fields?.prevdmp?.value && !fields[`${getPrefixField('previcon', isMobile)}`]?.value) {
                    window.Dmp.clickAction(fields?.prevdmp?.value);
                  }
                }}
                onSlideNextTransitionEnd={(e) => {
                  if (!isMobile && fields?.nextdmp?.value && !fields[`${getPrefixField('nexticon', isMobile)}`]?.value) {
                    window.Dmp.clickAction(fields?.nextdmp?.value);
                  }
                }}
              >
                {isMobile
                  ? fields?.lists.map((item, index) => {
                      return (
                        item?.fields[`${getPrefixField('img', isMobile)}`]?.value && (
                          <SwiperSlide
                            className="item"
                            key={index}
                            onClick={() => {
                              if (item?.fields[`${getPrefixField('detailurl', isMobile)}`]?.value) {
                                setVideoflag(true);
                                setSwiperIndex(index);
                                window.Dmp.clickAction(
                                  item?.fields[`${getPrefixField('opendmp', isMobile)}`]?.value
                                    ? item?.fields[`${getPrefixField('opendmp', isMobile)}`]?.value
                                    : item?.fields?.opendmp?.value,
                                  item?.fields?.bigimg?.value
                                );
                              } else {
                                setFlag(true);
                                setSwiperIndex(index);
                                windowhidden();
                                window.Dmp.clickAction(
                                  item?.fields[`${getPrefixField('opendmp', isMobile)}`]?.value
                                    ? item?.fields[`${getPrefixField('opendmp', isMobile)}`]?.value
                                    : item?.fields?.opendmp?.value
                                );
                              }
                            }}
                          >
                            {item?.fields[`${getPrefixField('img', isMobile)}`]?.value && (
                              <img
                                className="img"
                                style={convertStylesStringToObject(item?.fields[`${getPrefixField('imgstyle', isMobile)}`]?.value)}
                                src={createFilePath(item?.fields[`${getPrefixField('img', isMobile)}`]?.value)}
                                alt="广汽本田"
                              />
                            )}
                            {item?.fields[`${getPrefixField('icon', isMobile)}`]?.value && (
                              <img
                                className="icon"
                                style={convertStylesStringToObject(item?.fields[`${getPrefixField('iconstyle', isMobile)}`]?.value)}
                                src={createFilePath(item?.fields[`${getPrefixField('icon', isMobile)}`]?.value)}
                                alt="广汽本田"
                              />
                            )}
                          </SwiperSlide>
                        )
                      );
                    })
                  : chunkArray(fields?.lists, count).map((item, index) => {
                      return (
                        <SwiperSlide className="item" key={index}>
                          {item.map((subitem, subindex) => {
                            return (
                              <div
                                className={`img img${subindex}`}
                                key={subindex}
                                onClick={() => {
                                  if (subitem?.fields[`${getPrefixField('detailurl', isMobile)}`]?.value) {
                                    setVideoflag(true);
                                    setSwiperIndex(index * count + subindex);
                                    window.Dmp.clickAction(
                                      subitem?.fields[`${getPrefixField('opendmp', isMobile)}`]?.value
                                        ? subitem?.fields[`${getPrefixField('opendmp', isMobile)}`]?.value
                                        : subitem?.fields?.opendmp?.value,
                                      subitem?.fields?.bigimg?.value
                                    );
                                  } else {
                                    setFlag(true);
                                    setSwiperIndex(index * count + subindex);
                                    windowhidden();
                                    window.Dmp.clickAction(
                                      subitem?.fields[`${getPrefixField('opendmp', isMobile)}`]?.value
                                        ? subitem?.fields[`${getPrefixField('opendmp', isMobile)}`]?.value
                                        : subitem?.fields?.opendmp?.value
                                    );
                                  }
                                }}
                                style={convertStylesStringToObject(subitem?.fields[`${getPrefixField('imgstyle', isMobile)}`]?.value)}
                              >
                                {subitem?.fields[`${getPrefixField('img', isMobile)}`]?.value && (
                                  <img src={createFilePath(subitem?.fields[`${getPrefixField('img', isMobile)}`]?.value)} alt="广汽本田" />
                                )}
                                {subitem?.fields[`${getPrefixField('icon', isMobile)}`]?.value && (
                                  <img
                                    className="icon"
                                    style={convertStylesStringToObject(subitem?.fields[`${getPrefixField('iconstyle', isMobile)}`]?.value)}
                                    src={createFilePath(subitem?.fields[`${getPrefixField('icon', isMobile)}`]?.value)}
                                    alt="广汽本田"
                                  />
                                )}
                              </div>
                            );
                          })}
                        </SwiperSlide>
                      );
                    })}
              </Swiper>
              {fields[`${getPrefixField('nexticon', isMobile)}`]?.value && (
                <div className="next" data-html-item={fields?.prevdmp?.value}>
                  <img className="icon" src={createFilePath(fields[`${getPrefixField('nexticon', isMobile)}`]?.value)} alt="广汽本田" />
                </div>
              )}
              {fields[`${getPrefixField('previcon', isMobile)}`]?.value && (
                <div className="prev" data-html-item={fields?.nextdmp?.value}>
                  <img className="icon" src={createFilePath(fields[`${getPrefixField('previcon', isMobile)}`]?.value)} alt="广汽本田" />
                </div>
              )}
              {fields[`${getPrefixField('dragicon', isMobile)}`]?.value && (
                <img alt="广汽本田" src={createFilePath(fields[`${getPrefixField('dragicon', isMobile)}`]?.value)} className="dragPictrue"></img>
              )}
              {isMobile && (
                <Swiper
                  ref={paginationswiperRef}
                  slidesPerView={'auto'}
                  className="pagination"
                  spaceBetween={0}
                  initialSlide={0}
                  observer={true}
                  resizeObserver={true}
                  updateOnWindowResize={true}
                  navigation={false}
                >
                  {fields?.lists.map((item, index) => {
                    return (
                      item?.fields[`${getPrefixField('img', isMobile)}`]?.value && (
                        <SwiperSlide
                          className={swiperIndex === index ? 'pagination-bullet pagination-bullet-active' : 'pagination-bullet'}
                          key={index}
                          onClick={() => {
                            swiperslideTo(index);
                            if (fields?.paginationdmp?.value) {
                              window.Dmp.clickAction(fields?.paginationdmp?.value + (index + 1));
                            }
                          }}
                        >
                          <span></span>
                        </SwiperSlide>
                      )
                    );
                  })}
                </Swiper>
              )}
            </>
          </div>
        )}
      </div>
      {flag && (
        <div className="prop_detail">
          {fields?.lists[swiperIndex]?.fields[`${getPrefixField('img', isMobile)}`]?.value && (
            <img
              className="bg"
              style={convertStylesStringToObject(fields?.lists[swiperIndex]?.fields[`${getPrefixField('bigimgstyle', isMobile)}`]?.value)}
              src={createFilePath(fields?.lists[swiperIndex]?.fields[`${getPrefixField('bigimg', isMobile)}`]?.value)}
              alt="广汽本田"
            />
          )}
          <div className="bottom">
            <div
              className={'btn_v1 btn_v1_1'}
              onClick={() => {
                setFlag(false);
                closewindowhidden();
                window.Dmp.clickAction(
                  fields?.lists[swiperIndex]?.fields[`${getPrefixField('closedmp', isMobile)}`]?.value
                    ? fields?.lists[swiperIndex]?.fields[`${getPrefixField('closedmp', isMobile)}`]?.value
                    : fields?.lists[swiperIndex]?.fields?.closedmp?.value
                );
              }}
            >
              关闭
            </div>
            <a
              className={'btn_v1 btn_v1_2'}
              href={
                isMobile
                  ? createFilePath(fields?.lists[swiperIndex]?.fields[`${getPrefixField('img', isMobile)}`]?.value)
                  : createFilePath(fields?.lists[swiperIndex]?.fields[`${getPrefixField('bigimg', isMobile)}`]?.value)
              }
              download
              onClick={() => {
                window.Dmp.clickAction(
                  fields?.lists[swiperIndex]?.fields[`${getPrefixField('savedmp', isMobile)}`]?.value
                    ? fields?.lists[swiperIndex]?.fields[`${getPrefixField('savedmp', isMobile)}`]?.value
                    : fields?.lists[swiperIndex]?.fields?.savedmp?.value
                );
              }}
            >
              下载
            </a>
          </div>
        </div>
      )}
      {videoflag && (
        <Detailss
          routePath={fields?.lists[swiperIndex]?.fields[`${getPrefixField('detailurl', isMobile)}`]?.value}
          close={() => setVideoflag(false)}
        />
      )}
    </div>
  );
}
