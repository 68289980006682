import React, { createRef, useState, useRef } from 'react';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import { getWindow } from 'ssr-window';
import deepEqual from 'deep-equal';
import Helmet from 'react-helmet';
import { getQueryVariable } from './utils/site-utils';
import { scrollToAnchor, debounce, loadFont, getPathnameByPosition, createFilePath, getQueryString, setlocationhistory } from './utils/obj-utils';
import useIsomorphicLayoutEffect from './utils/useIsomorphicLayoutEffect';
import dmpListener from './utils/dmpListener';
import 'swiper/swiper.scss';
import './styles/app.scss';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/
// 方法尽量不要写着外面build会报错
const Layout = ({ route, personalTrack, isMobile, contactId }) => {
  const dmpList = useRef([]);
  const [mainRefList, setMainRefList] = useState([]);
  const [init, setInit] = useState({ advLoad: false });
  const [showFooter, setShowFooter] = useState(true);
  // const [ischangeimg, setIschangeimg] = useState(false);
  useIsomorphicLayoutEffect(() => {
    if (getQueryString('adcode')) {
      sessionStorage.setItem('adcode', getQueryString('adcode'));
      window.adcode = getQueryString('adcode');
    } else {
      if (sessionStorage.getItem('adcode')) {
        window.adcode = sessionStorage.getItem('adcode');
      }
    }
    if (getQueryString('clkid')) {
      sessionStorage.setItem('clkid', getQueryString('clkid'));
    }
    // 保客获取手机号码复制到url(session)
    if (sessionStorage.getItem('urlMembermobileValue')) {
      setlocationhistory(sessionStorage.getItem('urlMembermobileValue'), route?.fields?.personalized_enable?.value, 'tel');
    }
    // window.ImgAlt = '广汽本田';
  });
  useIsomorphicLayoutEffect(() => {
    if (!!global) {
      setShowFooter(getQueryString('source') !== 'app');
      loadFonts();
      addFullScreenGray();
      setMainRefList(new Array(route?.placeholders['jss-main']?.length).fill().map(() => createRef()));
      dmpList.current = [];
      setInit({ advLoad: false });
      getWindow().history.scrollRestoration = 'manual';
      if (getWindow()?.Dmp) {
        getWindow().Dmp.isMobile = isMobile;
        getWindow().Dmp.trackEnable = route?.fields?.track_enable?.value;
        getWindow().Dmp.advDisabled = route?.fields?.adv_disabled?.value;
        getWindow().Dmp.personalizedEnable = route?.fields?.personalized_enable?.value;
        getWindow().Dmp.mappingEnable = route?.fields?.mapping_enable?.value;
        getWindow().Dmp.act = route?.fields?.act?.value;
        getWindow().Dmp.drive = route?.fields?.drive?.value;
        getWindow().Dmp.advAction(() => {
          setInit({ advLoad: true });
          getWindow().Dmp.cookieAction(contactId, () => {
            getWindow().Dmp.ruleAction({ route, personalTrack });
          });
        });
        getWindow().Dmp.subscribeAction();
      }
    }
  }, [route, global]);

  useIsomorphicLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [route]);

  useIsomorphicLayoutEffect(() => {
    if (!init.advLoad || mainRefList.length === 0) {
      return;
    }
    document.documentElement.style.overflowY = 'auto';

    const dmpListenerObj = new dmpListener(dmpList?.current);
    let isUser;

    const scroll = () => {
      isUser = true;
      mainRefList?.forEach((item) => {
        const scrollEvent = item?.current?.scrollEvent;
        scrollEvent && scrollEvent();
      });
      try {
        if (!window.top.Dmp.isAnchor) {
          dmpListenerObj.checkIsInView(mainRefList);
        }
      } catch (e) {
        if (!Dmp.isAnchor) {
          dmpListenerObj.checkIsInView(mainRefList);
        }
      }
    };
    window.addEventListener('scroll', scroll);

    const debounceScroll = debounce(() => {
      isUser = true;
      try {
        if (window.top.Dmp.isAnchor) {
          window.top.Dmp.isAnchor = false;
          dmpListenerObj.checkIsInView(mainRefList);
        }
      } catch (e) {
        if (Dmp.isAnchor) {
          Dmp.isAnchor = false;
          dmpListenerObj.checkIsInView(mainRefList);
        }
      }
    }, 300);
    window.addEventListener('scroll', debounceScroll);

    const load = () => {
      setTimeout(() => {
        const activePage = getQueryVariable('activePage');
        const pagename = route?.placeholders['jss-main']?.map((item) => item.params?.pagename)[0];
        if (activePage && activePage !== pagename) {
          scrollToAnchor(activePage, null, 'auto');
        } else {
          if (!isUser) {
            let exist;
            const data = sessionStorage.getItem('data') && JSON.parse(sessionStorage.getItem('data'));
            if (data) {
              const scrollY = parseInt(data.scrollY);
              if (data.pathname === location.pathname && scrollY !== 0) {
                exist = true;
                window.scrollTo(0, scrollY + 1);
                setTimeout(() => {
                  window.scrollTo(0, scrollY);
                }, 10);
              } else if (data.pathname !== location.pathname) {
                sessionStorage.removeItem('data');
              }
            }
            if (!exist) {
              window.scrollTo(0, 1);
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 10);
            }
          }
        }
      }, 0);
    };
    window.addEventListener('load', load);
    load();
    window.onbeforeunload = () => {
      sessionStorage.setItem('data', JSON.stringify({ pathname: location.pathname, scrollY: window.scrollY }));
    };

    return () => {
      if (!init.advLoad || mainRefList.length === 0) {
        return;
      }

      debounceScroll.cancel();
      window.removeEventListener('scroll', scroll);
      window.removeEventListener('scroll', debounceScroll);
      window.removeEventListener('load', load);
    };
  }, [init, mainRefList, dmpList?.current]);

  const updateRefList = (list) => {
    if (list?.length > 0) {
      setMainRefList((val) => [...list, ...val]);
    }
  };

  const updateDmpList = (list) => {
    if (list?.length > 0) {
      dmpList.current = [...list, ...dmpList?.current];
    }
  };

  const addFullScreenGray = () => {
    const fullScreenGray = route?.fields?.router_setting?.fields?.fullscreen_gray?.value;
    if (fullScreenGray) {
      getWindow()?.document?.documentElement?.classList?.add('full-screen-gray');
    }
  };

  const loadFonts = () => {
    if (
      getPathnameByPosition().toLocaleLowerCase() === 'breeze-pet' ||
      getPathnameByPosition().toLocaleLowerCase() === '' ||
      getPathnameByPosition().toLocaleLowerCase() === 'breeze-pet-show' ||
      getPathnameByPosition().toLocaleLowerCase() === 'home' ||
      getPathnameByPosition().toLocaleLowerCase() === 'accord' ||
      getPathnameByPosition().toLocaleLowerCase() === 'avancier'
    ) {
      loadFont([
        { fontUrl: createFilePath('/common/font/字魂独角兽体.ttf'), fontFamily: '字魂独角兽体' },
        { fontUrl: createFilePath('/common/font/KozGoPr6N-Regular.otf'), fontFamily: 'KozGoPr6N-Regular' },
        { fontUrl: createFilePath('/common/font/FZSSJW.TTF'), fontFamily: 'FZShuSong-Z01S' },
        { fontUrl: createFilePath('/common/font/FZLTZHJW.ttf'), fontFamily: 'FZLTZHJW' },
        { fontUrl: createFilePath('/common/font/FZLTHJW.ttf'), fontFamily: 'FZLTHJW' }
      ]);
    }
    if (getPathnameByPosition().toLocaleLowerCase() === 'enp2') {
      loadFont([{ fontUrl: createFilePath('/common/font/FZChaoLTJW.TTF'), fontFamily: 'FZChaoLTJW' }]);
    }
  };

  const render = (components) => {
    const list = mainRefList.filter((item) => typeof item !== 'function');
    return components.map((item, index) =>
      React.cloneElement(item, {
        eventRef: list[index]
      })
    );
  };

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>{route?.fields?.pageTitle?.value || route?.fields?.title?.value || '广汽本田官方网站-广汽本田让梦走得更远'}</title>
        <meta name="keywords" content={route?.fields?.keywords?.value} />
        <meta name="description" content={route?.fields?.description?.value} />
      </Helmet>
      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />
      {/* root placeholder for the app, which we add components to using route data */}
      <div className="container" style={{ background: route?.fields?.theme?.fields?.v?.value || '#FFFFFF' }}>
        <Placeholder name="jss-header" rendering={route} page={route} showFooter={showFooter} />
        <Placeholder name="jss-main" rendering={route} page={route} render={render} updateRefList={updateRefList} updateDmpList={updateDmpList} />
        <Placeholder name="jss-footer" rendering={route} page={route} showFooter={showFooter} />
      </div>
    </React.Fragment>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
