import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { convertStylesStringToObject, sendDmp, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';
import temp from '../../temp/config';

export default function inlet(props) {
  const isMobile = isMobileFun();
  const inletList = isMobile ? props.fields.m_inletList : props.fields.inletList;
  const [defaultTab, setDefaultTab] = useState(-1);
  const id = props?.params?.pagename;
  // const isApp = window.location.href.indexOf('https://www.ghac.cn/app/') !== -1;
  const [isApp, setIsapp] = useState(0);
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
  };
  useEffect(() => {
    if (window.location.href.indexOf('https://www.ghac.cn/app/') !== -1) {
      setIsapp(1);
    }
  }, []);
  useEffect(() => {
    const inletTimer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      if (tab) {
        var tabState = 0;
        inletList.map((item, index) => {
          let tabArr = item.fields?.tabValue?.name;
          if (tabArr.includes(tab)) {
            setDefaultTab(index);
            tabState++;
          }
        });
        if (tabState === 0) {
          setDefaultTab(1);
        }
      } else {
        setDefaultTab(1);
      }
    }, 0);
    return () => {
      clearTimeout(inletTimer);
    };
  }, []);
  return (
    <section className={['inlet-model-v1', isApp === 1 ? 'appUse' : ''].join(' ')}>
      <div className="inletList">
        {inletList.map((item, index) => {
          return (
            <div
              key={index}
              className={`inletItem ${defaultTab === index ? 'active' : ''}`}
              style={convertStylesStringToObject(item.fields.style.value)}
              onClick={() => {
                setDefaultTab(index);
                sendDmp(item?.fields?.btn_dmp, item?.fields?.item_href?.value);
                if (item?.fields?.item_href?.value) {
                  window.open(item?.fields?.item_href?.value, '_self');
                }
              }}
            >
              {/* <img alt="广汽本田" className="clickIcon" src={`${temp.app.deployUrlS3}/breeze/v1/pc/inlet/click.png`} />
              <img alt="广汽本田" className="inletItem-image" src={`${temp.app.deployUrlS3}${item.fields.bannerImg.value}`} /> */}
              <img alt="广汽本田" className="clickIcon" src={createFilePath('/breeze/v1/pc/inlet/click.png')} />
              <img alt="广汽本田" className="inletItem-image" src={createFilePath(item.fields.bannerImg.value)} />
              <div dangerouslySetInnerHTML={{ __html: item.fields.logoHtml?.value?.replaceHtml() }} />
            </div>
          );
        })}
      </div>
    </section>
  );
}
