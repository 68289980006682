import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, getQueryString, isInView, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { useSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import temp from '../../temp/config';
import './style.scss';
import gsap from 'gsap';

export default function index(props) {
  const { fields } = props;
  const jsonData = JSON.parse(fields?.jsonData?.value);
  // const jsonData = {
  //   "text": "<div class=\"t\">高昂气势外观</div><div class=\"sub\">高阶品味，型动现代新美学</div>",
  //   "car1": "/avancier/v2/pc/exterior/car1.png",
  //   "car2": "/avancier/v2/pc/exterior/car2.png",
  //   "more": "<p>了解更多</p><img alt="广汽本田" class=\"icon\" src=\"${dm-media}/avancier/v2/pc/exterior/more_icon.png\" />",
  //   "bg": "/avancier/v2/pc/exterior/exterior1.jpg",
  //   "m_text": "<div class=\"t\">高昂气势外观</div><div class=\"sub\">高阶品味，型动现代新美学</div>",
  //   "m_car1": "",
  //   "m_car2": "",
  //   "m_more": "<p>了解更多</p><img alt="广汽本田" class=\"icon\" src=\"${dm-media}/avancier/v2/mobile/exterior/more_icon.png\" />",
  //   "m_bg": "/avancier/v2/mobile/exterior/exterior1.jpg",
  //   "placeHolderRoute": "jss-exterior"
  // }
  const [flaganimate, setFlaganimate] = useState(false);
  const isMobile = isMobileFun();
  const [tabswiperIndex, setTabswiperIndex] = useState(0);
  const id = props?.params?.pagename ? props?.params?.pagename : 'price_exterior';
  const [paused, setPaused] = useState(true);
  const rootEl = useRef(null);
  const titleEl = useRef(null);
  const car1El = useRef(null);
  const car2El = useRef(null);
  const moreEl = useRef(null);
  const secondPageRef = useRef(null);
  const secondPageEventRef = useRef(null);
  const route = useSitecoreContext()?.sitecoreContext?.route;
  useEffect(() => {
    const scroll = () => {
      if (rootEl.current) {
        setPaused(!isInView(rootEl));
      }
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  useEffect(() => {
    const timeline = gsap.timeline({
      duration: 15,
      onComplete: () => {
        timeline.kill();
      }
    });
    if (paused) {
      if (isMobile) {
        timeline.to(moreEl?.current, { opacity: 0 }, '<').to(titleEl?.current, { opacity: 0 });
      } else {
        timeline
          .to(moreEl?.current, { opacity: 0 }, '<')
          .to(titleEl?.current, { opacity: 0 })
          .to(car2El?.current, { opacity: 0 })
          .to(car1El?.current, { opacity: 0 });
      }
    } else {
      if (isMobile) {
        timeline
          .fromTo(titleEl?.current, 0.8, { y: '-200%', opacity: 0 }, { y: 0, opacity: 1 }, '<')
          .fromTo(moreEl?.current, 0.8, { opacity: 0 }, { opacity: 1 });
      } else {
        timeline
          .fromTo(car1El?.current, 0.5, { y: '-10%', x: '-200%' }, { x: 0, y: 0, opacity: 1 }, '<')
          .fromTo(car2El?.current, 0.5, { y: '-10%', x: '200%' }, { x: 0, y: 0, opacity: 1 })
          .fromTo(titleEl?.current, 0.8, { y: '-200%', opacity: 0 }, { y: 0, opacity: 1 })
          .fromTo(moreEl?.current, 0.8, { opacity: 0 }, { opacity: 1 });
      }
    }
    return () => {
      timeline.kill();
    };
  }, [paused]);

  useEffect(() => {
    if ('jss-exterior') {
      props.updateRefList([sendFirstDmp, sendSecondDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current]);
    } else {
      props.updateRefList([sendFirstDmp]);
      props.updateDmpList([rootEl?.current]);
    }
  }, []);
  const sendFirstDmp = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id;
  };

  const sendSecondDmp = () => {
    secondPageEventRef?.current?.sendEvent();
  };
  return (
    <div>
      <div className="price-exterior-v10" id={id} ref={rootEl}>
        <div className="text" ref={titleEl} dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('text', isMobile)].replaceHtml() }}></div>
        {!isMobile && (
          <>
            <div className="car1" ref={car1El}>
              <img alt="广汽本田" src={`${createFilePath(jsonData[getPrefixField('car1', isMobile)])}`} />
            </div>
            <div className="car2" ref={car2El}>
              <img alt="广汽本田" src={`${createFilePath(jsonData[getPrefixField('car2', isMobile)])}`} />
            </div>
          </>
        )}
        <div className="more" ref={moreEl} dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('more', isMobile)].replaceHtml() }}></div>
        <div className="bg">
          <img alt="广汽本田" src={`${createFilePath(jsonData[getPrefixField('bg', isMobile)])}`} />
        </div>
      </div>
      {jsonData?.placeHolderRoute && (
        <div ref={secondPageRef}>
          <Placeholder name={jsonData?.placeHolderRoute} rendering={route} page={route} eventRef={secondPageEventRef} />
        </div>
      )}
    </div>
  );
}
