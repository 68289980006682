import $, { error } from 'jquery';
import { isMobile } from './dom-utils';
import { getPathnameByPosition } from './obj-utils';

function preventScroll(id) {
  var _this = document.getElementsByClassName(id)[0];
  if (navigator.userAgent.indexOf('Firefox') > 0) {
    _this.addEventListener('DOMMouseScroll', function (e) {
      _this.scrollTop += e.detail > 0 ? 60 : -60;
      e.preventDefault();
    });
  } else {
    _this.onmousewheel = function (e) {
      e = e || window.event;
      _this.scrollTop += e.wheelDelta > 0 ? -60 : 60;
      return false;
    };
  }
  return this;
}

export function realiboxInit(url, canvas, data, timestamp) {
  const sceneID = data.sceneId;
  let startIndex = data.startIndex;
  const timelineInfoList = data.timelineInfoList;
  const callback = data.callback;
  const onloadCallback = data.onloadCallback;
  const progressCallback = data.progressCallback;
  const loop = data.loop;
  var u = navigator.userAgent;
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  var timestampA = '';
  // 判断SDK是否定义
  // eslint-disable-next-line no-undef
  if (!window.realibox || !window.realibox.studio) {
    // alert("3")
    console.info('SDK initialization failed!');
    return;
  }

  // 初始化场景
  // eslint-disable-next-line no-undef
  if (timestamp) {
    timestampA = `-${timestamp}`;
  }
  console.log('storeName:' + `gw-${getPathnameByPosition()?.toLowerCase()}-resources${timestampA}`);
  var app = new realibox.studio(canvas, {
    mode: 'viewer',
    indexedDBConfig: {
      enabled: true,
      name: 'realibox',
      version: 1,
      storeName: `gw-${getPathnameByPosition()?.toLowerCase()}-resources-${timestamp}`
    },
    dracoDecoderConfig: {
      decoderFilePath: 'https://asset.realibox.com/open/js/draco/draco_decoder.js',
      wasmWrapperFilePath: 'https://asset.realibox.com/open/js/draco/draco_wasm_wrapper.js',
      wasmFilePath: 'https://asset.realibox.com/open/js/draco/draco_decoder.wasm'
    },
    preferWebGl2: !isIOS
  });
  if (app) {
    const runInteraction = (interactionId, delay) => {
      if (delay) {
        setTimeout(() => {
          app.events.call('edk:scene:interaction:run', sceneID, interactionId);
        }, delay);
      } else {
        app.events.call('edk:scene:interaction:run', sceneID, interactionId);
      }
    };
    // 资源加载完毕
    app.events.on('edk:scene:resource:preload:end', function (sceneID) {
      console.log('场景ID ' + sceneID + ' 资源加载完成');
      if (onloadCallback && typeof onloadCallback === 'function') {
        onloadCallback();
      }
      runInteraction(timelineInfoList[startIndex].timeLine);

      if (!isMobile()) {
        // preventScroll('canvas3d');
      }
    });
    // 资源加载进度
    app.events.on('edk:scene:resource:preload:progress', function (sceneID, progress) {
      if (progressCallback && typeof progressCallback === 'function') {
        progressCallback(progress.progress);
      }
    });
    app.events.on('edk:scene:interaction:timeline:stop', function (sceneID, interactionID) {
      if (typeof callback === 'function') {
        let preIndex = startIndex;
        if (startIndex < timelineInfoList.length - 1) {
          startIndex++;
          runInteraction(timelineInfoList[startIndex].timeLine, timelineInfoList[preIndex].delay);
        } else {
          if (loop) {
            startIndex = 0;
            runInteraction(timelineInfoList[startIndex].timeLine, timelineInfoList[preIndex].delay);
          }
        }
        if (timelineInfoList[preIndex].delay) {
          setTimeout(() => {
            callback(startIndex);
          }, timelineInfoList[preIndex].delay);
        } else {
          callback(startIndex);
        }
      } else {
        console.log('callback not a valid function. please check it.');
      }
    });
    // app.events.on('edk:scene:resource:preload:progress', function (sceneID, progress) {
    //   // console.log('场景ID ' + sceneID + ' 已加载: ' + progress.progress);
    // });
    // const localToken = localStorage.getItem('realibox-token');
    // const localExpiresIn = localStorage.getItem('realibox-expires_in');
    // const localTokenTime = localStorage.getItem('realibox-token-time');
    // let needRefreshToken = false;
    // // token的存储，快到期前一分钟之内的话还是重新获取
    // if (localToken && localExpiresIn && localTokenTime) {
    //   const now = Date.now();
    //   if (now + 1 * 60 * 1000 < localExpiresIn * 1000 + localTokenTime) {
    //     needRefreshToken = false;
    //   } else {
    //     needRefreshToken = true;
    //   }
    // } else {
    //   needRefreshToken = true;
    // }
    // if (needRefreshToken) {
    //   $.ajax({
    //     data: JSON.stringify({
    //       app_key: 'C28a9Ab4d5c165e462FfeDD4DDaE80C7',
    //       app_secret: 'Dcf221F048d18C1fC96a9aFEccfadBbA0a46fC2e0690bfd4cC3FEED193d36fb6',
    //       grant_type: 'client_credentials'
    //     }),
    //     contentType: 'application/json',
    //     type: 'post',
    //     url: 'https://open.realibox.com/api/auth/v1/token',
    //     dataType: 'json'
    //   })
    //     .then((res) => {
    //       let token = '';
    //       let expires_in = '';
    //       if (res.code === 200000) {
    //         token = res.info.token;
    //         expires_in = res.info.expires_in;
    //         localStorage.setItem('realibox-token', token);
    //         localStorage.setItem('realibox-expires_in', expires_in);
    //         localStorage.setItem('realibox-token-time', Date.now());
    //         loadData(token);
    //       } else {
    //         console.log('调用获取token接口失败');
    //       }
    //     })
    //     .fail((err) => {
    //       console.log(err);
    //     });
    // } else {
    //   loadData(localToken);
    // }
    app.loadData(url, {
      id: sceneID,
      dataProcessingHook: (data) => {
        data.resources
          .filter((r) => r.type === 'texture')
          .forEach((item) => {
            const bool = item.data.url.includes('?');
            item.data.url = item.data.url += `${bool ? '&' : '?'}x-oss-process=image/format,webp`;
            return item;
          });
        return data;
      }
    });
    //
  }
  return app;
}
function loadData(token) {
  $.ajax({
    headers: {
      Authorization: token
    },
    crossDomain: true,
    type: 'GET',
    url: 'https://open.realibox.com/api/open/api/hub/v1/studio/scenes/publish/data/' + sceneID,
    dataType: 'JSONP',
    success: (res) => {
      if (res.code === 200000) {
        console.log('场景数据获取成功');
        // 加载主场景
        app.loadData(res.info, {
          id: sceneID,
          postRendering: true
        });
      } else {
        console.log('场景数据获取失败', res);
      }
    },
    error: (err) => console.error(err)
  });
}
// realiboxInit()
/* initExhibitData(exhibitJsonData, function (parameter) {
    realiboxInit(parameter)
}) */
