import React from 'react';
import classNames from 'classnames';
import './style.scss';

const Toggle = ({ expanded, onClick = () => {} }) => {
  const onRootClick = (e) => {
    onClick && onClick(e);
  };
  const rootClasses = classNames('e-toggle', { expanded });
  return <div className={rootClasses} onClick={onRootClick} />;
};

export default Toggle;
