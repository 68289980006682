import React from 'react';
import SvgIcons from '../../svg-icons';
import classNames from 'classnames';
import './style.scss';

const Icon = ({ name, className, onClick }) => {
  const NamedIcon = SvgIcons[name];
  const rootClasses = classNames('e-icon', className);
  const onIconClick = (e) => {
    onClick && onClick(e);
  };
  return (
    <span className={rootClasses} onClick={(e) => onIconClick(e)}>
      <NamedIcon />
    </span>
  );
};

export default Icon;
