import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, pcTorem, mbTorem, getQueryString, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import { swiperAnimateCache, swiperAnimate, clearSwiperAnimate } from '../../utils/swiper.animate1.0.3.min';
import FullVideo from '../../elements/FullVideo';
import FullModalDetail from '../../elements/FullModalDetail_v1';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const [windowHeight, setWindowHeight] = useState(400);
  const [swipertypeflag, setSwipertypeflag] = useState(true);
  const swiperRef = useRef(null);
  const tabsswiperRef = useRef(null);
  const [showcountnum, setShowcountnum] = useState(false);
  const [flag, setFlag] = useState(false);
  const [swiperflag, setSwiperflag] = useState(false);
  const [technologytab, setTechnologytab] = useState(1);
  const isMobile = isMobileFun();
  // const hosturl = temp.app.deployUrlS3;
  const [showDetail2, setShowDetail2] = useState(false);
  const [tabswiperIndex, setTabswiperIndex] = useState(0);
  const [loadingvideo, setLoadingvideo] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [showFooter, setShowFooter] = useState(true);
  // if (isMobile) {
  //   var accordhosturl = hosturl + '/accord/v2/mobile';
  // } else {
  //   var accordhosturl = hosturl + '/accord/v2/pc';
  // }
  const id = props?.params?.pagename;
  const videodata = {
    detail_video: createFilePath('/accord/v2/pc/technology/video.mp4?20240701'),
    close_icon_dmp: {
      type: 'click',
      point: 'price_space1-video-close',
      value1: ''
    }
  };
  const videodata2 = {
    detail_video: createFilePath('/accord/v2/pc/technology/video2.mp4?20240701'),
    close_icon_dmp: {
      type: 'click',
      point: 'price_tech-help-video-close',
      value1: ''
    }
  };
  const data = {
    id
  };
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
  };

  const openVideo = (data) => {
    setLoadingvideo(data);
    setFlag(true);
  };

  const swiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
    setTabswiperIndex(index);
  };
  const opendetail2 = (index) => {
    setShowDetail2(true);
    window.Dmp.clickAction('price_space1-btn1-detail');
  };
  useEffect(() => {
    if (
      window.location.href.indexOf('accord-show') !== -1 ||
      getQueryString('source') === 'app' ||
      window.location.href.indexOf('app/vehicles/accord') !== -1
    ) {
      setShowFooter(false);
    }
  }, []);
  return (
    <>
      <div className={!showFooter ? 'modules-grid technology noheader' : 'modules-grid technology'}>
        <div className="triggerstart" id="interior2"></div>
        <div id="interior2a"></div>
        <Controller>
          <Scene
            triggerHook={'onLeave'}
            duration={!isMobile ? windowHeight * 2 : windowHeight * 2}
            pin={true}
            reverse={true}
            triggerElement={'#interior2'}
          >
            {(progress, event) => {
              return (
                <div>
                  <Timeline totalProgress={progress} paused>
                    <div className="technologya" data-index={progress}>
                      <div
                        className={`griditem griditem0 enter ${
                          progress > 0 && progress < 0.4 ? 'enter' : progress > 0.4 && progress <= 1 ? 'leave' : ''
                        }`}
                      >
                        {!isMobile ? (
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/technology/technology0.gif')
                                : createFilePath('/accord/v2/pc/technology/technology0.gif')
                            }
                          />
                        ) : (
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/technology/technology0.jpg')
                                : createFilePath('/accord/v2/pc/technology/technology0.jpg')
                            }
                          />
                        )}
                        <img
                          alt="广汽本田"
                          className="car"
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/technology/technology0_car.png')
                              : createFilePath('/accord/v2/pc/technology/technology0_car.png')
                          }
                        />
                        <img
                          alt="广汽本田"
                          className="bottom"
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/technology/technologya1_bottom.jpg')
                              : createFilePath('/accord/v2/pc/technology/technologya1_bottom.jpg')
                          }
                        />
                        <img
                          alt="广汽本田"
                          className="t-img"
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/technology/technology0_t.png')
                              : createFilePath('/accord/v2/pc/technology/technology0_t.png')
                          }
                        />
                        <div className="text">
                          <div
                            className="t ani"
                            swiper-animate-effect="animate__fadeInDownBig"
                            swiper-animate-duration="1.5s"
                            swiper-animate-delay="0s"
                          >
                            <span>智座</span>智能座舱科技
                          </div>
                          <div className="sub">
                            <div className="en">INTUITIVE AND CARING TECHNOLOGY</div>
                            <div className="cn">一种全程感知心意的读心科技 </div>
                          </div>
                          <div className="control">
                            {isMobile && (
                              <div
                                className="btn"
                                onClick={() => {
                                  openVideo(videodata);
                                  window.Dmp.clickAction('price_space1-video-open');
                                }}
                              >
                                查看视频
                              </div>
                            )}
                            <div
                              className="immdbtn"
                              data-html-item="price_space1-immd"
                              data-url="https://i-mmd-lab.ghac.cn/"
                              onClick={() => {
                                window.open('https://i-mmd-lab.ghac.cn/');
                              }}
                            >
                              探索先进技术
                            </div>
                          </div>
                        </div>
                        {!isMobile ? (
                          <div
                            className="videomin1"
                            onClick={() => {
                              openVideo(videodata);
                              window.Dmp.clickAction('price_space1-video-open');
                            }}
                          >
                            <img alt="广汽本田" className="video" src={createFilePath('/accord/v2/pc/technology/video.gif')} />
                          </div>
                        ) : (
                          ' '
                        )}
                      </div>
                      <div className="modules-swiper-out1">
                        <Swiper
                          ref={swiperRef}
                          slidesPerView={1}
                          className="modules-swiper"
                          spaceBetween={0}
                          initialSlide={0}
                          observer={true}
                          resizeObserver={true}
                          updateOnWindowResize={true}
                          modules={[Navigation]}
                          navigation={{
                            prevEl: '.modules-swiper-out1 .prev',
                            nextEl: '.modules-swiper-out1 .next',
                            disabledClass: 'disabled'
                          }}
                          onInit={(e) => {
                            clearSwiperAnimate(e);
                            swiperAnimateCache(e);
                          }}
                          onSlideChangeTransitionEnd={(e) => {
                            swiperAnimate(e);
                            if (tabsswiperRef.current) {
                              tabsswiperRef.current.swiper.slideTo(e.activeIndex);
                              var allBoxes = tabsswiperRef.current.swiper.slides;
                              for (var i = 0; i < allBoxes.length; i++) {
                                allBoxes[i].className = allBoxes[i].className.replace('on', ' ');
                                allBoxes[e.activeIndex].classList.add('on');
                              }
                            }
                            setSwiperIndex(e.activeIndex);
                          }}
                        >
                          <SwiperSlide className={`griditem griditem1 ${progress > 0.4 ? 'enter' : ''}`}>
                            {/* <img alt="广汽本田" className="bg" src={accordhosturl + '/technology/technology1.jpg'} /> */}
                            <img
                              alt="广汽本田"
                              className="bg"
                              src={
                                isMobile
                                  ? createFilePath('/accord/v2/mobile/technology/technology1.jpg')
                                  : createFilePath('/accord/v2/pc/technology/technology1.jpg')
                              }
                            />
                            {!isMobile ? (
                              <>
                                {/* <img alt="广汽本田" className="bottom" src={accordhosturl + '/technology/technologya1_bottom.jpg'} /> */}
                                <img
                                  alt="广汽本田"
                                  className="bottom"
                                  src={
                                    isMobile
                                      ? createFilePath('/accord/v2/mobile/technology/technologya1_bottom.jpg')
                                      : createFilePath('/accord/v2/pc/technology/technologya1_bottom.jpg')
                                  }
                                />
                                <div className="text1">
                                  <div className="sub ">
                                    <div className="en">ONE GLANCE FOR CONSTANT STATUS UPDATES</div>
                                    <div className="cn">只需一眼 状态时刻在线 </div>
                                  </div>
                                  <div className="sub2 ">
                                    <div className="t1">DMC驾驶员状态感知系统</div>
                                    <div className="p1">只需一眼，状态时刻感知</div>
                                  </div>
                                  {/* <img alt="广汽本田" className="img" src={accordhosturl + '/technology/technologya1_text1.png'} /> */}
                                  <img
                                    alt="广汽本田"
                                    className="img"
                                    src={
                                      isMobile
                                        ? createFilePath('/accord/v2/mobile/technology/technologya1_text1.png')
                                        : createFilePath('/accord/v2/pc/technology/technologya1_text1.png')
                                    }
                                  />
                                </div>
                                <div className="text2">
                                  <div className="sub2 ">
                                    <div className="t1">快捷（刷脸）线上支付</div>
                                    <div className="p1">使用百度FACE ID Payment技术，即刻刷脸快速支付，节约时间。</div>
                                  </div>
                                  <div className="sub2 ">
                                    <div className="t1">全新个性化车辆设定</div>
                                    <div className="p1">重新定义舒适驾驶环境</div>
                                  </div>
                                  <img
                                    alt="广汽本田"
                                    className="img"
                                    src={
                                      isMobile
                                        ? createFilePath('/accord/v2/mobile/technology/technologya1_text2.png')
                                        : createFilePath('/accord/v2/pc/technology/technologya1_text2.png')
                                    }
                                  />
                                </div>
                                <div className="middle">
                                  <img
                                    alt="广汽本田"
                                    className="cir cir1"
                                    src={
                                      isMobile
                                        ? createFilePath('/accord/v2/mobile/technology/technologya1_cir1.png')
                                        : createFilePath('/accord/v2/pc/technology/technologya1_cir1.png')
                                    }
                                  />
                                  <img
                                    alt="广汽本田"
                                    className="cir cir2"
                                    src={
                                      isMobile
                                        ? createFilePath('/accord/v2/mobile/technology/technologya1_cir2.png')
                                        : createFilePath('/accord/v2/pc/technology/technologya1_cir2.png')
                                    }
                                  />
                                  <img
                                    alt="广汽本田"
                                    className="cir cir3"
                                    src={
                                      isMobile
                                        ? createFilePath('/accord/v2/mobile/technology/technologya1_cir3.jpg')
                                        : createFilePath('/accord/v2/pc/technology/technologya1_cir3.jpg')
                                    }
                                  />
                                  <img
                                    alt="广汽本田"
                                    className="cir cir4"
                                    src={
                                      isMobile
                                        ? createFilePath('/accord/v2/mobile/technology/technologya1_cir4.png')
                                        : createFilePath('/accord/v2/pc/technology/technologya1_cir4.png')
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="text">
                                  <div className="sub ">
                                    <div className="en">ONE GLANCE FOR CONSTANT STATUS UPDATES</div>
                                    <div className="cn">只需一眼 状态时刻在线 </div>
                                  </div>
                                  <div
                                    className="btn"
                                    onClick={() => {
                                      opendetail2();
                                    }}
                                  >
                                    查看详情
                                  </div>
                                </div>
                                <div className="list">
                                  <div className="li">灵动前脸机能造型</div>
                                  <div className="li">天蓝色车标</div>
                                  <div className="li">天蓝色车标</div>
                                </div>
                              </>
                            )}
                          </SwiperSlide>
                          <SwiperSlide className={'griditem griditem2'}>
                            {/* <img alt="广汽本田" className="bg" src={accordhosturl + '/technology/technologya2.jpg'} /> */}
                            <img
                              alt="广汽本田"
                              className="bg"
                              src={
                                isMobile
                                  ? createFilePath('/accord/v2/mobile/technology/technologya2.jpg')
                                  : createFilePath('/accord/v2/pc/technology/technologya2.jpg')
                              }
                            />
                            <div className="sub ">
                              <div className="en">INTELLIGENT THAT CONNECTS WHETHER FAR OR NEAR</div>
                              <div className="cn">全能联动 远近尽情掌控 </div>
                              <div className="li">智能手机&智能手表互联 </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide className={'griditem griditem3'}>
                            {/* <img alt="广汽本田" className="bg" src={accordhosturl + '/technology/technologya3.jpg'} /> */}
                            <img
                              alt="广汽本田"
                              className="bg"
                              src={
                                isMobile
                                  ? createFilePath('/accord/v2/mobile/technology/technologya3.jpg')
                                  : createFilePath('/accord/v2/pc/technology/technologya3.jpg')
                              }
                            />
                            {!isMobile ? (
                              <div className="sub ">
                                <div className="en">UNLOCK YOUR BOUNDLESS VIEW</div>
                                <div className="cn">解锁视界 一切尽收眼底 </div>
                                <div className="li">流媒体内后视镜</div>
                                <div className="li">数字后视镜</div>
                                <div className="li">行车记录仪</div>
                                <div className="li">实景导航</div>
                              </div>
                            ) : (
                              <>
                                <div className="sub ">
                                  <div className="en">UNLOCK YOUR BOUNDLESS VIEW</div>
                                  <div className="cn">解锁视界 一切尽收眼底 </div>
                                </div>
                                <div className="lists ">
                                  <div className="li">流媒体内后视镜</div>
                                  <div className="li">数字后视镜</div>
                                  <div className="li">行车记录仪</div>
                                  <div className="li">实景导航</div>
                                </div>
                              </>
                            )}
                          </SwiperSlide>
                          <SwiperSlide className={'griditem griditem4'}>
                            {/* <img alt="广汽本田" className="bg" src={accordhosturl + '/technology/technologya4.jpg'} /> */}
                            <img
                              alt="广汽本田"
                              className="bg"
                              src={
                                isMobile
                                  ? createFilePath('/accord/v2/mobile/technology/technologya4.jpg')
                                  : createFilePath('/accord/v2/pc/technology/technologya4.jpg')
                              }
                            />
                            <div className="sub ">
                              <div className="en">COMPLETE PROTECTION, ALWAYS AT EASE</div>
                              <div className="cn">全维守护 安心全时在线</div>
                            </div>
                            <div className="list ">
                              <div className="li">实时座舱监测</div>
                              {/* <img alt="广汽本田" className="img" src={accordhosturl + '/technology/technologya4_img.png'} /> */}
                              <img
                                alt="广汽本田"
                                className="img"
                                src={
                                  isMobile
                                    ? createFilePath('/accord/v2/mobile/technology/technologya4_img.png')
                                    : createFilePath('/accord/v2/pc/technology/technologya4_img.png')
                                }
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide className={'griditem griditem5'}>
                            {/* <img alt="广汽本田" className="bg" src={accordhosturl + '/technology/technologya5.jpg'} /> */}
                            <img
                              alt="广汽本田"
                              className="bg"
                              src={
                                isMobile
                                  ? createFilePath('/accord/v2/mobile/technology/technologya5.jpg')
                                  : createFilePath('/accord/v2/pc/technology/technologya5.jpg')
                              }
                            />
                            <div className="sub ">
                              <div className="en">CONNECT</div>
                              <div className="cn">安全 </div>
                            </div>
                            <div className="list ">
                              <div className="li">事故·危险预测*</div>
                              <div className="p">
                                *用户有固定的出行路线，并至少驾驶3个月以上；
                                <br />
                                用户本次驾驶是在固定出行路线上， 并且已经驾驶10分钟以上；
                                <br />
                                固定出行路线前方有异常天气路况（积水、积雪等）。*
                              </div>
                              <div className="li">可视化安全感</div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide className={'griditem griditem6'}>
                            {/* <img alt="广汽本田" className="bg" src={accordhosturl + '/technology/technologya6.jpg'} /> */}
                            <img
                              alt="广汽本田"
                              className="bg"
                              src={
                                isMobile
                                  ? createFilePath('/accord/v2/mobile/technology/technologya6.jpg')
                                  : createFilePath('/accord/v2/pc/technology/technologya6.jpg')
                              }
                            />
                            <div className="sub ">
                              <div className="en">INTELLIGENT COMMUNICATION THAT TOUCHES THE HEART</div>
                              <div className="cn">双商在线 让对话更有情感</div>
                            </div>
                            <div className="list">
                              <div className="li">AI智能推荐</div>
                              <div className="li">海量娱乐APP</div>
                              <div className="li">精准实景导航</div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide className={'griditem griditem7'}>
                            {/* <img alt="广汽本田" className="bg" src={accordhosturl + '/technology/technologya7.jpg'} /> */}
                            <img
                              alt="广汽本田"
                              className="bg"
                              src={
                                isMobile
                                  ? createFilePath('/accord/v2/mobile/technology/technologya7.jpg')
                                  : createFilePath('/accord/v2/pc/technology/technologya7.jpg')
                              }
                            />
                            <div className="sub ">
                              <div className="en">TWO-WAY LINK FOR SEAMLESS HOME-CAR TRANSITION</div>
                              <div className="cn">双向链接 无缝切换主场</div>
                              <div className="li">汽车与智能家居双向互联</div>
                            </div>
                          </SwiperSlide>
                        </Swiper>
                        <div className="next" data-html-item="price_space1-btnr"></div>
                        <div className="prev" data-html-item="price_space1-btnl"></div>
                      </div>
                      <Swiper
                        ref={tabsswiperRef}
                        slidesPerView={!isMobile ? 4 : 3}
                        className="technologytabs"
                        spaceBetween={0}
                        initialSlide={0}
                        observer={true}
                        resizeObserver={true}
                        updateOnWindowResize={true}
                        navigation={false}
                        centeredSlides={isMobile && tabswiperIndex !== 0 ? true : false}
                        onSlideChangeTransitionEnd={(e) => {}}
                      >
                        <SwiperSlide
                          className={tabswiperIndex === 0 ? 'item on' : 'item'}
                          onClick={() => {
                            swiperslideTo(0);
                            window.Dmp.clickAction('price_space1-btn1');
                          }}
                        >
                          {!isMobile ? (
                            <>DMC驾驶员 状态感知系统</>
                          ) : (
                            <>
                              DMC驾驶员
                              <br />
                              状态感知系统
                            </>
                          )}
                        </SwiperSlide>
                        <SwiperSlide
                          className={tabswiperIndex === 1 ? 'item on' : 'item'}
                          onClick={() => {
                            swiperslideTo(1);
                            window.Dmp.clickAction('price_space1-btn2');
                          }}
                        >
                          {!isMobile ? (
                            <>智能手机/手表互联</>
                          ) : (
                            <>
                              智能手机/
                              <br />
                              手表互联
                            </>
                          )}
                        </SwiperSlide>
                        <SwiperSlide
                          className={tabswiperIndex === 2 ? 'item on' : 'item'}
                          onClick={() => {
                            swiperslideTo(2);
                            window.Dmp.clickAction('price_space1-btn3');
                          }}
                        >
                          {!isMobile ? (
                            <>C-CMS流媒体内后视镜</>
                          ) : (
                            <>
                              C-CMS流媒体
                              <br />
                              内后视镜
                            </>
                          )}
                        </SwiperSlide>
                        <SwiperSlide
                          className={tabswiperIndex === 3 ? 'item on' : 'item'}
                          onClick={() => {
                            swiperslideTo(3);
                            window.Dmp.clickAction('price_space1-btn4');
                          }}
                        >
                          {!isMobile ? (
                            <>Cabin Watch 后排守护</>
                          ) : (
                            <>
                              Cabin Watch
                              <br />
                              后排守护
                            </>
                          )}
                        </SwiperSlide>
                        <SwiperSlide
                          className={tabswiperIndex === 4 ? 'item on' : 'item'}
                          onClick={() => {
                            swiperslideTo(4);
                            window.Dmp.clickAction('price_space1-btn5');
                          }}
                        >
                          安全CONNECT
                        </SwiperSlide>
                        <SwiperSlide
                          className={tabswiperIndex === 5 ? 'item on' : 'item'}
                          onClick={() => {
                            swiperslideTo(5);
                            window.Dmp.clickAction('price_space1-btn6');
                          }}
                        >
                          {!isMobile ? (
                            <>AI智能推荐 Mini Program</>
                          ) : (
                            <>
                              AI智能推荐
                              <br />
                              Mini Program
                            </>
                          )}
                        </SwiperSlide>
                        <SwiperSlide
                          className={tabswiperIndex === 6 ? 'item on' : 'item'}
                          onClick={() => {
                            swiperslideTo(6);
                            window.Dmp.clickAction('price_space1-btn7');
                          }}
                        >
                          车家互联
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </Timeline>
                </div>
              );
            }}
          </Scene>
        </Controller>
      </div>
      {showDetail2 ? (
        <FullModalDetail style={{ background: 'transparent' }}>
          <div className="detailfull">
            <div
              className="close1"
              onClick={() => {
                setShowDetail2(false);
                window.Dmp.clickAction('price_space1-btn1-close');
              }}
            ></div>
            <div className="text1">
              <div className="sub2 ">
                <div className="t1">DMC驾驶员状态感知系统</div>
                <div className="p1">只需一眼，状态时刻感知</div>
              </div>
              {/* <img alt="广汽本田" className="img" src={accordhosturl + '/technology/technologya1_text1.png'} /> */}
              <img
                alt="广汽本田"
                className="img"
                src={createFilePath(
                  isMobile ? '/accord/v2/mobile/technology/technologya1_text1.png' : '/accord/v2/pc/technology/technologya1_text1.png'
                )}
              />
            </div>
            <div className="text1">
              <div className="sub2 ">
                <div className="t1">快捷（刷脸）线上支付</div>
                <div className="p1">使用百度FACE ID Payment技术，即刻刷脸快速支付，节约时间。</div>
              </div>
            </div>
            <div className="text1">
              <div className="sub2 ">
                <div className="t1">全新个性化车辆设定</div>
                <div className="p1">重新定义舒适驾驶环境</div>
              </div>
              {/* <img alt="广汽本田" className="img2" src={accordhosturl + '/technology/technologya1_text2.png'} /> */}
              <img
                alt="广汽本田"
                className="img2"
                src={
                  isMobile
                    ? createFilePath('/accord/v2/mobile/technology/technologya1_text2.png')
                    : createFilePath('/accord/v2/pc/technology/technologya1_text1.png')
                }
              />
            </div>
          </div>
        </FullModalDetail>
      ) : (
        ''
      )}
      {flag && <FullVideo fields={loadingvideo} close={() => setFlag(false)} />}
    </>
  );
}
