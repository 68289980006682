import { isInView } from './obj-utils';

export default class dmpListener {
  constructor(dmpList) {
    this.scrollY = window.scrollY;
    this.dmpList = this.getDmpList(dmpList);
    this.lastList = [];
  }

  getDmpList(list) {
    const children = document.getElementById('root').getElementsByClassName('container')[0].children;
    const dmpList = (list || [])
      .concat(
        Array.from(children)
          .slice(0, children.length - 1)
          .filter((item) => {
            const className = item.className.toLowerCase();
            return (
              (className.indexOf('header') === -1 &&
                className.indexOf('navigationside') === -1 &&
                className.indexOf('return_top') === -1 &&
                className.indexOf('suspension-homepage') === -1 &&
                className.indexOf('btnlist') === -1) ||
              className.indexOf('banner') !== -1
            );
          })
      )
      .map((element, index) => ({ element, overview: this.dmpList ? this.dmpList[index].overview : true }));
    return dmpList;
  }

  refreshDmpList(list) {
    this.dmpList = this.getDmpList(list);
  }

  checkIsInView(refList) {
    if (this.dmpList.length === 0) {
      return;
    }
    const inViewList = [];
    this.dmpList.forEach((item) => {
      if (isInView(item.element)) {
        inViewList.push(item);
      } else {
        item.overview = true;
      }
    });
    if (inViewList.length > 1) {
      if (window.scrollY >= this.scrollY) {
        if (!this.scrollDown) {
          this.scrollDown = true;
          inViewList[inViewList.length - 1].overview = true;
        }
      } else {
        if (this.scrollDown) {
          this.scrollDown = false;
          inViewList[0].overview = true;
        }
      }
    } else {
      if (window.scrollY >= this.scrollY) {
        if (!this.scrollDown) {
          this.scrollDown = true;
        }
      } else {
        if (this.scrollDown) {
          this.scrollDown = false;
        }
      }
    }
    const filterList = [];
    const endElement = this.dmpList[this.dmpList.length - 1].element;
    const rect = endElement.getBoundingClientRect();
    inViewList.forEach((item) => {
      const sendEvent = refList[this.dmpList.indexOf(item)]?.current?.sendEvent || refList[this.dmpList.indexOf(item)];
      if (
        item.overview &&
        // 最后一个埋点特殊处理（从footer上划回去和上次一样也需要触发埋点）
        ((item.element === endElement && rect.top <= 0 && !this.scrollDown) ||
          // 和上次的不一致的才触发埋点
          this.lastList.indexOf(item.element) === -1) &&
        sendEvent &&
        typeof sendEvent === 'function'
      ) {
        filterList.push(item?.element);
        sendEvent(item?.element);
        item.overview = false;
      }
    });
    if (filterList?.length > 0) {
      this.lastList = filterList;
    }
    this.scrollY = window.scrollY;
  }
}
