import temp from '../../../temp/config';
import { createFilePath } from '../../../utils/obj-utils';
export default [
  {
    title: 'e:NP纯电', // 大分类名称
    id: 'catalog-hcar-first-ect', // 大分类点击埋码
    children: [
      {
        name: '极湃1 e:NP1', // 标题
        littleT: '', // 副标题
        price: '17.50', // 价钱
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/enp1.jpg`, // 车型图片
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/enp1.jpg'), // 车型图片
        lookLink: 'https://www.ghac.cn/vehicles/enp1', // 移动端点击图片跳转地址
        car_id: '/buy/tools/test-drive?car_ID=0C616788-8FE4-43DC-96F1-4D5BFF58A908',
        threeD: 'https://enp1.ghac.cn', // 3D看车地址
        imgID: 'catalog-hcar-link-enp1', // 图片埋码
        carID: 'catalog-hcar-drive-enp1', // 预约试驾埋码
        lookID: 'catalog-hcar-detail-enp1', // 了解车型埋码
        threeDID: 'catalog-hcar-360-enp1', // 3D看车埋码
        pc_lookLink: 'https://www.ghac.cn/vehicles/enp1', // pc端点击图片跳转地址
        pc_car_id: '/buy/tools/test-drive?car_ID=0C616788-8FE4-43DC-96F1-4D5BFF58A908' // pc端预约试驾地址
      }
    ]
  },
  {
    title: '轿车',
    id: 'catalog-hcar-first-car',
    children: [
      {
        name: '雅阁ACCORD',
        price: '16.98',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/Accord.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/Accord.png'),
        lookLink: 'https://www.ghac.cn/vehicles/accord',
        car_id: '/buy/tools/test-drive?car_ID=A71A2B15-DFC8-41B6-AF34-935E2A87DE60',
        threeD: 'https://accord360.ghac.cn',
        imgID: 'catalog-hcar-link-accord', // 图片id
        carID: 'catalog-hcar-drive-accord', // 预约试驾id
        lookID: 'catalog-hcar-detail-accord', // 了解车型id
        threeDID: 'catalog-hcar-360-accord', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/accord',
        pc_car_id: '/buy/tools/test-drive?car_ID=A71A2B15-DFC8-41B6-AF34-935E2A87DE60'
      },
      {
        name: '型格INTEGRA',
        price: '12.99',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/INTEGRA.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/INTEGRA.png'),
        lookLink: 'https://www.ghac.cn/vehicles/integra',
        car_id: '/buy/tools/test-drive?car_ID=CA7D4B4D-8CDB-41E1-8A0B-A6D7B4090789',
        threeD: 'https://integra360.ghac.cn',
        imgID: 'catalog-hcar-link-integra', // 图片id
        carID: 'catalog-hcar-drive-integra', // 预约试驾id
        lookID: 'catalog-hcar-detail-integra', // 了解车型id
        threeDID: 'catalog-hcar-360-integra', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/integra',
        pc_car_id: '/buy/tools/test-drive?car_ID=CA7D4B4D-8CDB-41E1-8A0B-A6D7B4090789'
      },
      {
        name: '凌派CRIDER',
        price: '10.98',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/crider.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/crider.png'),
        lookLink: 'https://www.ghac.cn/vehicles/crider',
        car_id: '/buy/tools/test-drive?car_ID=390AEEE9-41DC-4D12-840F-1137693F0B0A',
        threeD: 'https://crider360.ghac.cn',
        imgID: 'catalog-hcar-link-crider', // 图片id
        carID: 'catalog-hcar-drive-crider', // 预约试驾id
        lookID: 'catalog-hcar-detail-crider', // 了解车型id
        threeDID: 'catalog-hcar-360-crider', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/crider',
        pc_car_id: '/buy/tools/test-drive?car_ID=390AEEE9-41DC-4D12-840F-1137693F0B0A'
      },
      {
        name: '飞度FIT',
        price: '8.18',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/fit.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/fit.png'),
        lookLink: 'https://www.ghac.cn/vehicles/fit',
        car_id: '/buy/tools/test-drive?car_ID=DFD5D5F0-B03B-4707-9AC6-FB734D04508E',
        threeD: 'https://fit360.ghac.cn',
        imgID: 'catalog-hcar-link-fit', // 图片id
        carID: 'catalog-hcar-drive-fit', // 预约试驾id
        lookID: 'catalog-hcar-detail-fit', // 了解车型id
        threeDID: 'catalog-hcar-360-fit', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/fit',
        pc_car_id: '/buy/tools/test-drive?car_ID=DFD5D5F0-B03B-4707-9AC6-FB734D04508E'
      },
      {
        name: '绎乐EA6',
        price: '16.78',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/ea6.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/ea6.png'),
        lookLink: 'https://www.ghac.cn/vehicles/EA6',
        car_id: '/buy/tools/test-drive?car_ID=F2EEDC6F-344F-49E6-8982-481A028E185A',
        threeD: 'https://ea6.ghac.cn',
        imgID: 'catalog-hcar-link-ea6', // 图片id
        carID: 'catalog-hcar-drive-ea6', // 预约试驾id
        lookID: 'catalog-hcar-detail-ea6', // 了解车型id
        threeDID: 'catalog-hcar-360-ea6', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/EA6/p',
        pc_car_id: '/buy/tools/test-drive?car_ID=F2EEDC6F-344F-49E6-8982-481A028E185A'
      }
    ]
  },
  {
    title: 'SUV/MPV',
    id: 'catalog-hcar-first-suv',
    children: [
      {
        name: '极湃1 e:NP1',
        littleT: '',
        price: '17.50',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/enp1.jpg`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/enp1.jpg'),
        lookLink: 'https://www.ghac.cn/vehicles/enp1',
        car_id: '/buy/tools/test-drive?car_ID=0C616788-8FE4-43DC-96F1-4D5BFF58A908',
        threeD: 'https://enp1.ghac.cn',
        imgID: 'catalog-hcar-link-enp1', // 图片id
        carID: 'catalog-hcar-drive-enp1', // 预约试驾id
        lookID: 'catalog-hcar-detail-enp1', // 了解车型id
        threeDID: 'catalog-hcar-360-enp1', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/enp1',
        pc_car_id: '/buy/tools/test-drive?car_ID=0C616788-8FE4-43DC-96F1-4D5BFF58A908'
      },
      {
        name: '冠道AVANCIER',
        price: '22.48',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/Crown.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/Crown.png'),
        lookLink: 'https://www.ghac.cn/vehicles/avancier',
        car_id: '/buy/tools/test-drive?car_ID=C50C3996-49A4-4FC7-AFEF-4BC98539AF90',
        threeD: 'https://avancier360.ghac.cn',
        imgID: 'catalog-hcar-link-avancier', // 图片id
        carID: 'catalog-hcar-drive-avancier', // 预约试驾id
        lookID: 'catalog-hcar-detail-avancier', // 了解车型id
        threeDID: 'catalog-hcar-360-avancier', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/avancier',
        pc_car_id: '/buy/tools/test-drive?car_ID=C50C3996-49A4-4FC7-AFEF-4BC98539AF90'
      },
      {
        name: '皓影BREEZE',
        price: '16.98',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/brezze.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/brezze.png'),
        lookLink: 'https://www.ghac.cn/vehicles/breeze?tab=oil',
        car_id: '/buy/tools/test-drive?car_ID=27701A1D-EB48-4DB8-A930-99FD9B3A3F85',
        threeD: 'https://breeze360.ghac.cn',
        imgID: 'catalog-hcar-link-breeze', // 图片id
        carID: 'catalog-hcar-drive-breeze', // 预约试驾id
        lookID: 'catalog-hcar-detail-breeze', // 了解车型id
        threeDID: 'catalog-hcar-360-breeze', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/breeze?tab=oil',
        pc_car_id: '/buy/tools/test-drive?car_ID=27701A1D-EB48-4DB8-A930-99FD9B3A3F85'
      },
      {
        name: '皓影 锐·混动e+',
        littleT: 'BREEZE HYBRID e+',
        price: '24.78',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/brezzeHybrid.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/brezzeHybrid.png'),
        lookLink: 'https://www.ghac.cn/vehicles/breeze?tab=plugin',
        car_id: '/buy/tools/test-drive?car_ID=9694a180-07da-4a05-876b-915e7f9cc7b8',
        threeD: 'https://breeze360.ghac.cn',
        imgID: 'catalog-hcar-link-breezephev', // 图片id
        carID: 'catalog-hcar-drive-breezephev', // 预约试驾id
        lookID: 'catalog-hcar-detail-breezephev', // 了解车型id
        threeDID: 'catalog-hcar-360-breezephev', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/breeze?tab=plugin',
        pc_car_id: '/buy/tools/test-drive?car_ID=9694a180-07da-4a05-876b-915e7f9cc7b8'
      },
      {
        name: '致在ZR-V',
        littleT: '',
        price: '15.99',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/zrv.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/zrv.png'),
        lookLink: 'https://www.ghac.cn/vehicles/Z',
        car_id: '/buy/tools/test-drive?car_ID=47cf6985-2756-4990-88b1-7a61eae2424b',
        threeD: 'https://z.ghac.cn',
        imgID: 'catalog-hcar-link-zrv', // 图片id
        carID: 'catalog-hcar-drive-zrv', // 预约试驾id
        lookID: 'catalog-hcar-detail-zrv', // 了解车型id
        threeDID: 'catalog-hcar-360-zrv', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/Z',
        pc_car_id: '/buy/tools/test-drive?car_ID=47cf6985-2756-4990-88b1-7a61eae2424b'
      },
      {
        name: '缤智VEZEL',
        price: '13.29',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/VEZEL.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/VEZEL.png'),
        lookLink: 'https://www.ghac.cn/vehicles/vezel',
        car_id: '/buy/tools/test-drive?car_ID=BCE5664C-92D2-4D49-A398-966B342FFBF8',
        threeD: 'https://vezel360.ghac.cn',
        imgID: 'catalog-hcar-link-vezel', // 图片id
        carID: 'catalog-hcar-drive-vezel', // 预约试驾id
        lookID: 'catalog-hcar-detail-vezel', // 了解车型id
        threeDID: 'catalog-hcar-360-vezel', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/vezel',
        pc_car_id: '/buy/tools/test-drive?car_ID=BCE5664C-92D2-4D49-A398-966B342FFBF8'
      },
      {
        name: '奥德赛 锐·混动',
        littleT: 'ODYSSEY HYBRID',
        price: '23.58',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/odysseyHybrid.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/odysseyHybrid.png'),
        lookLink: 'https://www.ghac.cn/vehicles/odyssey',
        car_id: '/buy/tools/test-drive?car_ID=AFCF9B1C-A14A-4380-8706-170CC16CB819',
        threeD: 'https://odyssey360.ghac.cn',
        imgID: 'catalog-hcar-link-odyssey', // 图片id
        carID: 'catalog-hcar-drive-odyssey', // 预约试驾id
        lookID: 'catalog-hcar-detail-odyssey', // 了解车型id
        threeDID: 'catalog-hcar-360-odyssey', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/odyssey',
        pc_car_id: '/buy/tools/test-drive?car_ID=AFCF9B1C-A14A-4380-8706-170CC16CB819'
      },
      {
        name: 'VE-1',
        price: '15.98',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/ve1.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/ve1.png'),
        lookLink: 'https://www.ghac.cn/vehicles/VE-1',
        car_id: '/buy/tools/test-drive?car_ID=1CFE8580-0E18-4E4D-AAE2-D6BA843258AD',
        threeD: 'https://ve-1.ghac.cn',
        imgID: 'catalog-hcar-link-ve1', // 图片id
        carID: 'catalog-hcar-drive-ve1', // 预约试驾id
        lookID: 'catalog-hcar-detail-ve1', // 了解车型id
        threeDID: 'catalog-hcar-360-ve1', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/VE-1/p',
        pc_car_id: '/buy/tools/test-drive?car_ID=1CFE8580-0E18-4E4D-AAE2-D6BA843258AD'
      }
    ]
  },
  {
    title: '新能源',
    id: 'catalog-hcar-first-new',
    children: [
      {
        name: '极湃1 e:NP1',
        littleT: '',
        price: '17.50',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/enp1.jpg`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/enp1.jpg'),
        lookLink: 'https://www.ghac.cn/vehicles/enp1',
        car_id: '/buy/tools/test-drive?car_ID=0C616788-8FE4-43DC-96F1-4D5BFF58A908',
        threeD: 'https://enp1.ghac.cn',
        imgID: 'catalog-hcar-link-enp1', // 图片id
        carID: 'catalog-hcar-drive-enp1', // 预约试驾id
        lookID: 'catalog-hcar-detail-enp1', // 了解车型id
        threeDID: 'catalog-hcar-360-enp1', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/enp1',
        pc_car_id: '/buy/tools/test-drive?car_ID=0C616788-8FE4-43DC-96F1-4D5BFF58A908'
      },
      {
        name: '皓影 锐·混动e+',
        littleT: 'BREEZE HYBRID e+',
        price: '24.78',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/brezzeHybrid.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/brezzeHybrid.png'),
        lookLink: 'https://www.ghac.cn/vehicles/breeze?tab=plugin',
        car_id: '/buy/tools/test-drive?car_ID=9694a180-07da-4a05-876b-915e7f9cc7b8',
        threeD: 'https://breeze360.ghac.cn',
        imgID: 'catalog-hcar-link-breezephev', // 图片id
        carID: 'catalog-hcar-drive-breezephev', // 预约试驾id
        lookID: 'catalog-hcar-detail-breezephev', // 了解车型id
        threeDID: 'catalog-hcar-360-breezephev', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/breeze?tab=plugin',
        pc_car_id: '/buy/tools/test-drive?car_ID=9694a180-07da-4a05-876b-915e7f9cc7b8'
      },
      {
        name: 'VE-1',
        price: '15.98',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/ve1.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/ve1.png'),
        lookLink: 'https://www.ghac.cn/vehicles/VE-1',
        car_id: '/buy/tools/test-drive?car_ID=1CFE8580-0E18-4E4D-AAE2-D6BA843258AD',
        threeD: 'https://ve-1.ghac.cn',
        imgID: 'catalog-hcar-link-ve1', // 图片id
        carID: 'catalog-hcar-drive-ve1', // 预约试驾id
        lookID: 'catalog-hcar-detail-ve1', // 了解车型id
        threeDID: 'catalog-hcar-360-ve1', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/VE-1/p',
        pc_car_id: '/buy/tools/test-drive?car_ID=1CFE8580-0E18-4E4D-AAE2-D6BA843258AD'
      },
      {
        name: '绎乐EA6',
        price: '16.78',
        // img: `${temp.app.deployUrlS3}/common/header/v1/whiteStytle/carUltility/ea6.png`,
        img: createFilePath('/common/header/v1/whiteStytle/carUltility/ea6.png'),
        lookLink: 'https://www.ghac.cn/vehicles/EA6',
        car_id: '/buy/tools/test-drive?car_ID=F2EEDC6F-344F-49E6-8982-481A028E185A',
        threeD: 'https://ea6.ghac.cn',
        imgID: 'catalog-hcar-link-ea6', // 图片id
        carID: 'catalog-hcar-drive-ea6', // 预约试驾id
        lookID: 'catalog-hcar-detail-ea6', // 了解车型id
        threeDID: 'catalog-hcar-360-ea6', // 3D看车id
        pc_lookLink: 'https://www.ghac.cn/vehicles/EA6/p',
        pc_car_id: '/buy/tools/test-drive?car_ID=F2EEDC6F-344F-49E6-8982-481A028E185A'
      }
    ]
  }
];
