import React, { useEffect, useRef, useState, forwardRef } from 'react';
import './style.scss';
import temp from '../../temp/config';
import { isEmpty, convertStylesStringToObject, sendDmp, createFilePath, GotoMall } from '../../utils/obj-utils';
import { isIOS, isAndroid } from 'react-device-detect';

const HeroLanding = forwardRef((data, ref) => {
  const contentRef = useRef(null);
  const [iscarshow, setIscarshow] = useState(true);
  if (isEmpty(data.data.fields?.m_subtitle?.value)) {
    data.data.fields.m_subtitle = data.data.fields.subtitle;
  }
  const {
    position,
    title,
    subtitle,
    m_subtitle,
    pngLinks,
    describe,
    btn,
    pc_image_video,
    pc_image_alt,
    m_image_video,
    m_image_alt,
    btnPosition,
    erCode,
    m_title,
    className,
    m_erCode,
    btnFlag,
    m_btnFlag,
    m_position,
    m_describe,
    m_erCodeImg,
    erCodeImg,
    styleBannerImg,
    m_bottom,
    pc_price,
    m_price,
    titleLogo,
    textalign,
    m_styleBannerImg,
    m_textalign,
    btnPosition_m,
    bannerhtml,
    m_bannerhtml,
    m_drivebtnstyle,
    is_drivebtn,
    m_drivebtnhtml,
    m_drivebtndmp
  } = data.data.fields;
  const [isMobile, setIsMobile] = useState(data?.isMobile);
  const isPng = (value) => {
    if (value !== '') {
      return value.indexOf('png') !== -1 || value.indexOf('jpg') !== -1 || value.indexOf('gif') !== -1;
    }
  };
  const positionAuto = (value) => {
    if (value === undefined) return;
    if (value.indexOf('left') === 0) {
      return 'left';
    }
    if (value.indexOf('center') === 0) {
      return 'center';
    }
    if (value.indexOf('right') === 0) {
      return 'right';
    }
  };
  let scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        try {
          window.top.Dmp.isAnchor = true;
        } catch (e) {
          Dmp.isAnchor = true;
        }
        anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  };
  useEffect(() => {
    if (!ref) {
      contentRef?.current?.classList.add('animate__fadeInDown');
    }
  }, []);

  useEffect(() => {
    if (isIOS && isMobile) {
      document.addEventListener('WeixinJSBridgeReady', function () {
        document.getElementById('videoID').play();
      });
    } else if (isAndroid && isMobile) {
      document.addEventListener('touchstart', function () {
        document.getElementById('videoID')?.play();
      });
    }
  }, []);

  useEffect(() => {
    if (window.location.href.indexOf('odyssey-show') !== -1 || window.location.href.indexOf('/show/odyssey') !== -1) {
      setIscarshow(false);
    }
  }, []);
  const AppSchemeUrlRefClick = (e) => {
    if (e.target && e.target.dataset && e.target.dataset.appschemeurl) {
      GotoMall(e.target.dataset.appschemeurl, e.target.dataset.htmlItem1, isMobile);
    }
  };
  return (
    <div
      className={`heroLandingBox heroLandingBox_${positionAuto(position?.fields?.k?.value || position?.value)} ${data.className || ''} ${
        className?.value || ''
      }`}
    >
      {isMobile && is_drivebtn?.value && iscarshow && (
        <>
          {m_drivebtndmp?.value ? (
            <div
              style={convertStylesStringToObject(m_drivebtnstyle?.value)}
              className="drivebtn"
              onClick={() => scrollToAnchor('drive')}
              data-html-item={m_drivebtndmp?.value}
              dangerouslySetInnerHTML={{ __html: m_drivebtnhtml?.value?.replaceHtml() }}
            ></div>
          ) : (
            <div
              style={convertStylesStringToObject(m_drivebtnstyle?.value)}
              className="drivebtn"
              onClick={() => scrollToAnchor('drive')}
              dangerouslySetInnerHTML={{ __html: m_drivebtnhtml?.value?.replaceHtml() }}
            ></div>
          )}
        </>
      )}
      {!isMobile ? (
        isPng(pc_image_video?.value) ? (
          <img
            className="firstScreen"
            src={`${createFilePath(pc_image_video?.value)}`}
            style={convertStylesStringToObject(styleBannerImg?.value)}
            alt={pc_image_alt?.value}
          />
        ) : pc_image_video?.value ? (
          <video
            src={`${createFilePath(pc_image_video?.value)}`}
            autoPlay={true}
            muted={true}
            loop={true}
            style={convertStylesStringToObject(styleBannerImg?.value)}
          />
        ) : (
          ''
        )
      ) : isPng(m_image_video?.value) ? (
        <img
          className="firstScreen"
          src={`${createFilePath(m_image_video?.value)}`}
          style={convertStylesStringToObject(m_styleBannerImg?.value)}
          alt={m_image_alt?.value}
        />
      ) : m_image_video?.value ? (
        <video
          id="videoID"
          src={`${createFilePath(m_image_video?.value)}`}
          autoPlay={true}
          muted={true}
          loop={true}
          style={convertStylesStringToObject(m_styleBannerImg?.value)}
          webkit-playsinline="true"
          playsInline={true}
        />
      ) : (
        ''
      )}
      {!bannerhtml?.value && !m_bannerhtml?.value ? (
        <div
          className={`heroLanding_${!isMobile ? position?.fields?.k?.value || position?.value : m_position?.fields?.k?.value || position?.value}`}
          style={{
            top: m_bottom?.value !== '' ? m_bottom?.value : '',
            textAlign: !isMobile ? textalign?.value : m_textalign?.value,
            width: !isMobile && className?.value === 'breezeBannerWidth' ? '50%' : '',
            opacity: 0
          }}
          ref={ref || contentRef}
        >
          {isPng(title?.value) ? (
            <img className="title" src={`${createFilePath(title?.value)}`} alt="title" />
          ) : (
            <div className="title" dangerouslySetInnerHTML={{ __html: (!isMobile ? title?.value : m_title?.value)?.replaceHtml() }} />
          )}
          {titleLogo?.value !== '' ? (
            <div dangerouslySetInnerHTML={{ __html: (!isMobile ? titleLogo?.value : titleLogo?.value)?.replaceHtml() }}></div>
          ) : (
            ''
          )}
          {isPng(!isMobile ? subtitle?.value : m_subtitle?.value) ? (
            <img src={`${createFilePath(!isMobile ? subtitle?.value : m_subtitle?.value)}`} alt="SubTitle" />
          ) : (
            <div
              className="subTitle specialSunTitle"
              dangerouslySetInnerHTML={{ __html: (!isMobile ? subtitle?.value : m_subtitle?.value)?.replaceHtml() }}
            />
          )}
          {describe?.value !== '' || m_describe?.value !== '' ? (
            <div className="describe" dangerouslySetInnerHTML={{ __html: (!isMobile ? describe?.value : m_describe?.value)?.replaceHtml() }} />
          ) : (
            ''
          )}
          {pngLinks?.fields?.k?.value === 'true' ? (
            <div className="pngLinks">
              <img src={createFilePath('/breeze/v1/pc/banner/red_line.png')} alt="img" />
            </div>
          ) : (
            ''
          )}
          {pc_price?.value !== '' || m_price?.value !== '' ? (
            <div dangerouslySetInnerHTML={{ __html: (!isMobile ? pc_price?.value : m_price?.value)?.replaceHtml() }}></div>
          ) : (
            ''
          )}
          {/* <div dangerouslySetInnerHTML={{ __html: !isMobile ? erCode?.value : m_erCode?.value }}></div> */}
          {erCodeImg?.value !== '' && erCodeImg?.value !== undefined && !isMobile ? (
            <img alt="广汽本田" src={`${createFilePath(erCodeImg?.value)}`} style={{ marginTop: '2vh' }} />
          ) : m_erCodeImg?.value !== '' && m_erCodeImg?.value !== undefined && isMobile ? (
            <img alt="广汽本田" src={`${createFilePath(m_erCodeImg?.value)}`} style={{ width: '40%' }} />
          ) : (
            ''
          )}
          {(btnFlag?.value === 'true' && !isMobile) || (m_btnFlag?.value === 'true' && isMobile) ? (
            <div className={`btnArr btnArr_${!isMobile ? btnPosition?.value : btnPosition_m?.value}`}>
              {btn &&
                btn.map((item) => {
                  if (data?.checkDetail) {
                    return (
                      <button
                        className={'btn'}
                        key={item.id}
                        onClick={(e) => {
                          sendDmp(item?.fields['data-item']);
                          data?.checkDetail(true);
                        }}
                        style={{ backgroundColor: item?.fields?.m_backColor?.value }}
                      >
                        {item?.fields?.title.value}
                      </button>
                    );
                  } else {
                    return (
                      <button
                        className={`btn btn-${item?.fields?.class?.displayName} btn-${item?.fields?.type.displayName}`}
                        key={item.id}
                        data-action={item?.fields['data-item'][0].fields.func.value}
                        data-item={item?.fields['data-item'][0].displayName}
                        data-url={!isMobile ? item?.fields?.url.value : item?.fields?.m_url.value}
                        onClick={() => {
                          !isMobile
                            ? item?.fields?.url.value && window.open(item?.fields?.url.value)
                            : item?.fields?.m_url.value && window.open(item?.fields?.m_url.value);
                        }}
                        style={{
                          ...convertStylesStringToObject(!isMobile ? item?.fields.btn_style?.value : item?.fields?.m_btn_style?.value),
                          marginTop: !isMobile ? item?.fields?.pc_bottom?.value : item?.fields?.m_bottom?.value,
                          marginLeft: !isMobile ? item?.fields?.pc_left?.value : item?.fields?.m_left?.value,
                          backgroundColor: !isMobile ? item?.fields?.backColor?.value : item?.fields?.m_backColor?.value
                        }}
                      >
                        {item?.fields?.title.value}
                      </button>
                    );
                  }
                })}
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <>
          {(!isMobile ? bannerhtml?.value : m_bannerhtml?.value).indexOf('data-appschemeurl') !== -1 ? (
            <div
              dangerouslySetInnerHTML={{ __html: (!isMobile ? bannerhtml?.value : m_bannerhtml?.value)?.replaceHtml() }}
              onClick={AppSchemeUrlRefClick}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: (!isMobile ? bannerhtml?.value : m_bannerhtml?.value)?.replaceHtml() }} />
          )}
        </>
      )}
    </div>
  );
});
HeroLanding.displayName = 'HeroLanding';
export default HeroLanding;
