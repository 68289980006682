/* eslint-disable no-eval */
import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import gsap, { Linear } from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import temp from '../../temp/config';
import { getPrefixField, sendDmp, debounce, countUp, scrollFinish, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';

export default function index(props) {
  const { fields, eventRef, params } = props;
  const containerRef = useRef(null);
  const scene1Ref = useRef(null);
  const scene1TextRef = useRef(null);
  const scene2Ref = useRef(null);
  const scene2TextRef = useRef(null);
  const scene3Ref = useRef(null);
  const scene3TextRef = useRef(null);
  const line3Ref = useRef(null);
  const dotImage3Ref = useRef(null);
  const scene4Ref = useRef(null);
  const scene4TextRef = useRef(null);
  const line4Ref = useRef(null);
  const dotImage4Ref = useRef(null);
  const debounceCallAnimateRef = useRef(null);
  // const deployUrlS3 = temp.app.deployUrlS3;
  const [tabNum, setTabNum] = useState(0);
  const isMobile = isMobileFun();
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const secondPageRef = useRef(null);
  const placeHolderRoute = fields.placeHolderRoute.value;
  const sectionDmp = fields?.sectionDmp;
  const tabs = fields[getPrefixField('tabs', isMobile)];
  const defaultTab = fields.default_tab_name.value;
  const [imgLoaded, setImgLoaded] = useState(false);
  const [animateIndex, setAnimateIndex] = useState(0);
  const [data, _] = useState({
    isMobile,
    tabs,
    sectionDmp,
    placeHolderRoute,
    defaultTab
  });
  const animateList = [];
  const length = 4;
  const dynamicNumberList = Array(length);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    require('swiped-events');
    sendDmp(data?.sectionDmp);
    window.navigationIndex = fields?.navigationIndex?.value;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'price_power';
  };

  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      data.tabs.map((item, index) => {
        let tabArr = item.fields?.tab_name?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(data.defaultTab.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabNum(tabInd < 0 ? (defaultTabInd < 0 ? tabNum : defaultTabInd) : tabInd);
      } else {
        setTabNum(defaultTabInd < 0 ? tabNum : defaultTabInd);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (!data || !imgLoaded || !tabNum) {
      return;
    }
    initDynamicNumberList();
    initLine();

    debounceCallAnimateRef.current = debounce(callAnimate, 50);
    debounceCallAnimateRef?.current(animateIndex);

    const controller = new ScrollMagic.Controller();
    const timeline = gsap.timeline({});
    timeline.fromTo(
      scene1Ref?.current,
      {},
      {
        duration: 0.25,
        onStart: () => {
          dynamicNumberAnimate(0);
        }
      }
    );
    const scene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 0.5
    })
      .on('progress', function (event) {
        timeline.progress(event.progress);
      })
      .setTween(timeline)
      .addTo(controller);

    return () => {
      debounceCallAnimateRef?.current?.cancel();
      scene.remove();
      scene.destroy();

      controller.destroy();
    };
  }, [imgLoaded, tabNum]);

  useEffect(() => {
    if (!imgLoaded || !isMobile || !tabNum) {
      return;
    }

    containerRef?.current?.addEventListener('swiped-left', rightArrowClick);
    containerRef?.current?.addEventListener('swiped-right', leftArrowClick);

    return () => {
      if (!imgLoaded || !isMobile || !tabNum) {
        return;
      }

      containerRef?.current?.removeEventListener('swiped-left', rightArrowClick);
      containerRef?.current?.removeEventListener('swiped-right', leftArrowClick);
    };
  }, [imgLoaded, animateIndex, tabNum]);

  const initDynamicNumberList = () => {
    for (let index = 0; index < length; index++) {
      dynamicNumberList[index] = Array.from(eval(`scene${index + 1}TextRef`)?.current?.getElementsByClassName('dynamic-number'))?.map((item) => ({
        element: item,
        content: item?.innerText
      }));
    }
  };

  const initLine = () => {
    const windowHeight = document.documentElement.clientHeight;
    const polyline3 = line3Ref?.current?.getElementsByClassName('polyline')[0];
    polyline3.setAttribute('points', data.isMobile ? `0,0 0,${windowHeight * 0.26}` : '299,0 299,100 0,100');
    polyline3.style.strokeDashoffset = polyline3.getTotalLength();
    polyline3.style.strokeDasharray = polyline3.getTotalLength();
    const polyline4 = line4Ref?.current?.getElementsByClassName('polyline')[0];
    polyline4.setAttribute('points', data.isMobile ? `0,0 0,${windowHeight * 0.22}` : '299,0 299,100 0,100');
    polyline4.style.strokeDashoffset = polyline4.getTotalLength();
    polyline4.style.strokeDasharray = polyline4.getTotalLength();
  };

  const callAnimate = (index, reverse = false) => {
    animateList.push(eval(`animate${index + 1}`)(reverse, index));
  };

  const animate1 = (reverse = false, index) => {
    const timeline = gsap.timeline({
      onComplete: () => {
        timeline.kill();
      },
      onReverseComplete: () => {
        timeline.kill();
      }
    });
    reverse && timeline.reverse(0);
    return timeline;
  };

  const animate2 = (reverse = false, index) => {
    const timeline = gsap
      .timeline({
        onComplete: () => {
          timeline.kill();
        },
        onReverseComplete: () => {
          timeline.kill();
        }
      })
      .fromTo(
        scene1Ref?.current,
        { opacity: 1 },
        {
          opacity: 0,
          duration: 0.25
        },
        '<'
      )
      .fromTo(
        scene2Ref?.current,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.25,
          onStart: () => {
            dynamicNumberAnimate(index);
          }
        },
        '<'
      );
    reverse && timeline.reverse(0);
    return timeline;
  };

  const animate3 = (reverse = false, index) => {
    const timeline = gsap
      .timeline({
        onComplete: () => {
          timeline.kill();
        },
        onReverseComplete: () => {
          timeline.kill();
        }
      })
      .fromTo(
        scene3Ref?.current,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.25,
          onStart: () => {
            dynamicNumberAnimate(index);
          }
        },
        '<'
      )
      .fromTo(dotImage3Ref?.current, { opacity: 0 }, { opacity: 1, duration: 0.25 });
    const polyline3 = line3Ref?.current?.getElementsByClassName('polyline')[0];
    lineAnimate(polyline3, timeline);
    reverse && timeline.reverse(0);
    return timeline;
  };

  const animate4 = (reverse = false, index) => {
    const timeline = gsap
      .timeline({
        onComplete: () => {
          timeline.kill();
        },
        onReverseComplete: () => {
          timeline.kill();
        }
      })
      .fromTo(line3Ref?.current, { opacity: 1 }, { opacity: 0, duration: 0.1 })
      .fromTo(dotImage3Ref?.current, { opacity: 1 }, { opacity: 0, duration: 0.1 }, '<')
      .fromTo(
        scene4Ref?.current,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.25,
          onStart: () => {
            dynamicNumberAnimate(index);
          }
        },
        '<'
      )
      .fromTo(dotImage4Ref?.current, { opacity: 0 }, { opacity: 1, duration: 0.25 });
    const polyline4 = line4Ref?.current?.getElementsByClassName('polyline')[0];
    lineAnimate(polyline4, timeline);
    reverse && timeline.reverse(0);
    return timeline;
  };

  const lineAnimate = (line, timeline, position = '>', reverse = false, duration = 0.1) => {
    const pathLength = line.getTotalLength();
    const pathObj = { offset: reverse ? 0 : pathLength };
    timeline.fromTo(
      pathObj,
      { ...pathObj },
      { offset: reverse ? pathLength : 0, onUpdate: drawLine, onUpdateParams: [line, pathObj], duration, ease: Linear.easeNone },
      position
    );
  };

  const dynamicNumberAnimate = (index) => {
    dynamicNumberList[index].forEach((item) => countUp(item?.element, item?.content));
  };

  const drawLine = (line, obj) => {
    line.style.strokeDashoffset = obj.offset;
  };

  const leftArrowClick = () => {
    if (animateIndex > 0) {
      sendDmp(data.tabs[tabNum]?.fields?.leftArrowDmp);
      debounceCallAnimateRef?.current(animateIndex, true);
      setAnimateIndex(animateIndex - 1);
    }
  };

  const rightArrowClick = () => {
    if (animateIndex < length - 1) {
      sendDmp(data.tabs[tabNum]?.fields?.rightArrowDmp);
      debounceCallAnimateRef?.current(animateIndex + 1);
      setAnimateIndex(animateIndex + 1);
    }
  };

  useEffect(() => {
    // if (!imgLoaded) {
    //   return;
    // }
    setAnimateIndex(0);
    const windowHeight = document.documentElement.clientHeight;
    const tabList = containerRef?.current?.getElementsByClassName('tab_list')[0];
    const scroll = () => {
      if (tabNum) {
        tabList.style.position = 'absolute';
      } else {
        const rect = containerRef?.current?.getBoundingClientRect();
        if (rect.top < 0 && rect.bottom > windowHeight) {
          tabList.style.position = 'fixed';
        } else {
          tabList.style.position = 'absolute';
        }
      }
    };

    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, [tabNum, imgLoaded]);

  return (
    <section>
      <div className="power_v4" ref={containerRef} id={params?.pagename !== undefined ? params?.pagename : 'price_power'}>
        <div>
          <div className="tab_list">
            {data.tabs &&
              data.tabs.length > 0 &&
              data.tabs.map((item, index) => {
                return (
                  <>
                    {index !== 0 && <div className="split_line" dangerouslySetInnerHTML={{ __html: fields?.split_line.value?.replaceHtml() }} />}
                    <div
                      key={index}
                      className={`tab_item ${index === tabNum ? 'tab_disabled' : ''}`}
                      onClick={() => {
                        setTabNum(index);
                        sendDmp(item?.fields?.tabDmp);
                      }}
                    >
                      <div
                        className={index === tabNum ? 'tabContainer_active' : 'tabContainer'}
                        dangerouslySetInnerHTML={{ __html: item.fields?.tab?.value?.replaceHtml() }}
                      />
                    </div>
                  </>
                );
              })}
          </div>
          {tabNum ? (
            <div>
              <div className="scene1" ref={scene1Ref}>
                <img
                  alt="广汽本田"
                  className="scene1_image"
                  src={`${createFilePath(data.tabs[tabNum].fields.image1.value)}`}
                  onLoad={() => setImgLoaded(true)}
                />
                <div
                  className="scene1_text"
                  ref={scene1TextRef}
                  dangerouslySetInnerHTML={{ __html: data.tabs[tabNum].fields.content1.value?.replaceHtml() }}
                />
              </div>
              <div className="scene2" ref={scene2Ref}>
                <img alt="广汽本田" className="scene2_image" src={`${createFilePath(data.tabs[tabNum].fields.image2.value)}`} />
                <div
                  className="scene2_text"
                  ref={scene2TextRef}
                  dangerouslySetInnerHTML={{ __html: data.tabs[tabNum].fields.content2.value?.replaceHtml() }}
                />
              </div>
              <div className="scene3" ref={scene3Ref}>
                <img alt="广汽本田" className="scene3_image" src={`${createFilePath(data.tabs[tabNum].fields.image3.value)}`} />
                <div
                  className="scene3_text"
                  ref={scene3TextRef}
                  dangerouslySetInnerHTML={{ __html: data.tabs[tabNum].fields.content3.value?.replaceHtml() }}
                />
                <div className="scene3_line">
                  <svg className="line" ref={line3Ref}>
                    <polyline className="polyline" />
                  </svg>
                  <img
                    alt="广汽本田"
                    className="scene3_line_dot"
                    ref={dotImage3Ref}
                    src={`${createFilePath(data.tabs[tabNum].fields.dotImage.value)}`}
                  />
                </div>
              </div>
              <div className="scene4" ref={scene4Ref}>
                <img alt="广汽本田" className="scene4_image" src={`${createFilePath(data.tabs[tabNum].fields.image4.value)}`} />
                <div
                  className="scene4_text"
                  ref={scene4TextRef}
                  dangerouslySetInnerHTML={{ __html: data.tabs[tabNum].fields.content4.value?.replaceHtml() }}
                />
                <div className="scene4_line">
                  <svg className="line" ref={line4Ref}>
                    <polyline className="polyline" />
                  </svg>
                  <img
                    alt="广汽本田"
                    className="scene4_line_dot"
                    ref={dotImage4Ref}
                    src={`${createFilePath(data.tabs[tabNum].fields.dotImage.value)}`}
                  />
                </div>
              </div>
              <div className="btn">
                <button
                  className="btnDetails"
                  onClick={() => {
                    sendDmp(data.tabs[tabNum].fields.btnDmp, data.tabs[tabNum].fields.btnHref?.value);
                    window.open(data.tabs[tabNum].fields.btnHref?.value);
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: data.tabs[tabNum].fields.btn?.value?.replaceHtml() }} />
                </button>
              </div>
              {animateIndex > 0 && (
                <img
                  alt="广汽本田"
                  className="left"
                  onClick={() => {
                    leftArrowClick();
                    setAnimateIndex(animateIndex - 1);
                  }}
                  src={createFilePath('/common/icon/VectorLeftWhiteGrey.png')}
                />
              )}
              {animateIndex < length - 1 && (
                <img
                  alt="广汽本田"
                  className="right"
                  onClick={() => {
                    rightArrowClick();
                    setAnimateIndex(animateIndex + 1);
                  }}
                  src={createFilePath('/common/icon/VectorRightWhiteGrey.png')}
                />
              )}
            </div>
          ) : (
            <div ref={secondPageRef} key={+new Date()}>
              <Placeholder name={data?.placeHolderRoute} rendering={route} page={route} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
