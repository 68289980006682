/* eslint-disable no-bitwise */
import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import './style.scss';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import { dataFetcher } from '../../dataFetcher';
import Dialog from '../../elements/Dialog/index';
import temp from '../../temp/config';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import $ from 'jquery';
import { getQueryVariable } from '../../utils/site-utils';
import { getPrefixField, sendDmpAction, convertStylesStringToObject, sendDmp, isEmpty, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { rest } from 'underscore';

// 计算经纬度之间的距离
function getDistance(lat1, lng1, lat2, lng2) {
  var radLat1 = (lat1 * Math.PI) / 180.0;
  var radLat2 = (lat2 * Math.PI) / 180.0;
  var a = radLat1 - radLat2;
  var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
  var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
  s = s * 6378.137;
  s = Math.round(s * 10000) / 10000;
  return s;
}

export default function index(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const driveDataList = fields[getPrefixField('driveList', isMobile)];
  const showTabFrontCar = fields[getPrefixField('showTabFrontCar', isMobile)]?.value;
  const [vedioEnded, setVedioEnded] = useState(false);
  const [agree, setAgree] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [semImg, setSemImg] = useState('');
  const [dealerProvince, setDealerProvince] = useState(null);
  const [dealerProCitys, setDealerProCitys] = useState(null);
  const [dealerDealers, setDealerDealers] = useState(null);
  const [imgId, setImgId] = useState(''); // 设置短信和留资公用的图形编码
  const [customerPhone, setCustomerPhone] = useState('');
  const rootEl = useRef(null);
  const [specialDi, setSpecialD] = useState('');
  const checkboxRef = useRef(null);
  const chooseTab = () => {
    let value = getQueryVariable('tab');
    if (!fields[`${getPrefixField('driveList', isMobile)}`].find((item) => item.fields.tabValue?.fields.v.value === value)) {
      value = fields[`${getPrefixField('defaultValue', isMobile)}`]?.fields?.v?.value;
    }
    let number = 0;
    let items = fields[`${getPrefixField('driveList', isMobile)}`];
    for (let i = 0; i < items.length; i++) {
      if (items[i].fields.tabValue?.fields.v.value === value) {
        number = i;
        break;
      }
    }
    return number;
  };
  // 预约参数模板
  const [person, setPerson] = useState({
    userName: '',
    mobile: '',
    provinceId: '', // 省份Id Provinces.province_name
    cityId: '', // 城市id Citys.city_id
    dealerId: '', // 经销商id Dealers.dealer_id
    mediafrom: fields[`${getPrefixField('mediafrom', isMobile)}`]?.value || 2, // 留资方式
    graphic: {
      code: '', // 用户录入的图形验证码
      id: '', // 图形验证码id
      preMod: false
    },
    smsValidId: '', // 短信验证码id
    smsValidCode: '', // 用户录入的短信验证码
    series_code: driveDataList[chooseTab()].fields[getPrefixField('series_code', isMobile)].value,
    market_code: driveDataList[chooseTab()].fields[getPrefixField('market_code', isMobile)].value,
    model_code: driveDataList[chooseTab()].fields.modelCode.value,
    device_type: isMobile ? 2 : 1,
    areaCode: '',
    adcode: '',
    contactid: useSitecoreContext()?.sitecoreContext?.contactId
  });
  const [policyVersion, setPolicyVersion] = useState(null);
  const [ownAddress, setOwnAddress] = useState([]);
  const [load, setLoad] = useState(false);
  // 短信参数模板
  const customer = {
    phone: '',
    sendType: 1,
    validId: '',
    validCode: '',
    validWhiteList: false,
    whiteListType: 0
  };
  const [tabIndex, setTabIndex] = useState(chooseTab());
  const originalData = useRef(null);
  const province = useRef(null);
  const city = useRef(null);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields.load_dmp_point);
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : 'drive';
  };

  // 数据
  useEffect(() => {
    const handleScroll = () => {
      const rect = rootEl.current.getBoundingClientRect();
      if (fields[getPrefixField('animate_disabled', isMobile)].value) {
        setVedioEnded(true);
      } else if (rect.top <= window.innerHeight / 2) {
        setVedioEnded(true);
      }
    };
    var urls = window.location.href;
    const obj = {};
    var arr = urls.split('?').pop().split('#').shift().split('&');
    for (var i = arr.length - 1; i >= 0; i--) {
      var k = arr[i].split('=')[0];
      var val = arr[i].split('=')[1];
      obj[k] = val;
    }
    person.adcode = obj.adcode;
    setPerson(person);
    window.addEventListener('scroll', handleScroll);

    if (!load) {
      const getJsonp = (url) => {
        return new Promise((resolve, reject) => {
          $.ajax({
            url: url,
            type: 'GET',
            dataType: 'JSONP',
            cache: false,
            success: function (res) {
              resolve(res);
            },
            error: function (err, res, res2) {
              reject(res2);
            }
          });
        });
      };

      // 根据IP获取地理位置
      const mapCityApiPromise =
        sessionStorage.getItem('ownAddressProvince') && sessionStorage.getItem('ownAddressCity')
          ? { province: sessionStorage.getItem('ownAddressProvince'), city: sessionStorage.getItem('ownAddressCity') }
          : getJsonp('https://restapi.amap.com/v3/ip?key=d4db88c544bf23a3a363f7ff8bcbf783&output=json')
              .then((res) => {
                if (res.status === '1') {
                  sessionStorage.setItem('ownAddressProvince', res.province);
                  sessionStorage.setItem('ownAddressCity', res.city);
                  return {
                    province: res.province,
                    city: res.city
                  };
                } else {
                  return {
                    province: '广东省',
                    city: '广州市'
                  };
                }
              })
              .catch((err) => {
                return {
                  province: '广东省',
                  city: '广州市'
                };
              });

      const AMapLoader = require('@amap/amap-jsapi-loader');
      window._AMapsecuritvonfig = { securityJsCode: 'df2d1e69d62971d0a7ebb43C882b678a' };
      // 有地址时不需要加载高德
      const mapApiPromise =
        sessionStorage.getItem('ownAddressLat') && sessionStorage.getItem('ownAddressLng')
          ? { lat: sessionStorage.getItem('ownAddressLat'), lng: sessionStorage.getItem('ownAddressLng') }
          : AMapLoader.load({
              key: '975d8e63e9648540ee56a840bbce9884',
              version: '2.0',
              plugins: ['AMap.Geolocation']
            });
      const allDealderInfo = dataFetcher('/api/honda/v1/Leads/GetAllDealerProCitys', null);

      // 三级联动数据初次渲染
      Promise.all([mapApiPromise, allDealderInfo, mapCityApiPromise]).then((res) => {
        // sessionStorage是否含有城市
        if (sessionStorage.getItem('ownAddressProvince') && sessionStorage.getItem('ownAddressCity')) {
          province.current = sessionStorage.getItem('ownAddressProvince');
          city.current = sessionStorage.getItem('ownAddressCity');
        } else {
          province.current = res[2].province;
          city.current = res[2].city;
        }

        // sessionStorage是否含有个人经纬度
        if (sessionStorage.getItem('ownAddressLat') && sessionStorage.getItem('ownAddressLng')) {
          setLoad(true);
        } else {
          if (res[0]) {
            let geolocationLatLng = new res[0].Geolocation();
            geolocationLatLng.getCurrentPosition(function (status, result) {
              if (status === 'complete') {
                sessionStorage.setItem('ownAddressLat', result.position.lat);
                sessionStorage.setItem('ownAddressLng', result.position.lng);
                setOwnAddress([result.position.lat, result.position.lng]);
              } else {
                console.log('errorerrorerrorerror', result);
              }
              setLoad(true);
            });
          }
        }

        if (res[1].status === 200) {
          originalData.current = { ...res[1]?.data?.Data };
          fetchData();
        }
      });

      getPolicyVersion();
    } else {
      if (originalData.current) {
        fetchData();
      }
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [load]);

  const filterOriginalData = () => {
    const data = { Citys: [], Dealers: [...originalData.current?.Dealers], Provinces: [] };
    originalData.current?.Citys?.forEach((item) => {
      const dealers = getDealersData(item.city_id, originalData.current?.Dealers);
      if (dealers.length !== 0) {
        data.Citys.push(item);
      }
    });

    originalData.current?.Provinces?.forEach((item) => {
      const result = data?.Citys?.find((item1) => item1.province_id === item.province_id);
      if (result) {
        data.Provinces.push(item);
      }
    });
    return data;
  };

  const getPolicyVersion = () => {
    dataFetcher('/api/honda/v1/Policy/GetPolicyVersion')
      .then(({ data }) => {
        if (data.Status) {
          setPolicyVersion(data?.Data?.policy_code);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchData = () => {
    const data = filterOriginalData();
    let init_province = person?.provinceId || '';
    let init_zoneId = person?.zone_id || '';
    let result = data.Provinces.find((item) => item.province_id === init_province);
    // if (!result) {
    result = data.Provinces.find((item) => province.current?.indexOf(item.province_name) !== -1);
    if (!result) {
      result = data.Provinces.find((item) => '广东省'.indexOf(item.province_name) !== -1);
      if (!result) {
        result = data.Provinces[0];
      }
    }
    init_province = result.province_id;
    init_zoneId = result.zone_id;
    // }
    const newProvinceList = data.Provinces.map((item) => ({ ...item, default: init_province === item.province_id }));
    setDealerProvince({ ...data, Provinces: newProvinceList });

    let init_city = person?.cityId || '';
    const citys = data.Citys.filter((item) => item.province_id === (init_province || data.Provinces[0].province_id));
    result = citys.find((item) => item.city_id === init_city);
    // if (!result) {
    result = citys.find((item) => city.current?.indexOf(item.city_name) !== -1);
    if (!result) {
      result = citys.find((item) => '广州市'.indexOf(item.city_name) !== -1);
      if (!result) {
        result = citys[0];
      }
    }
    init_city = result.city_id;
    // }
    const newCitys = citys.map((item) => ({ ...item, default: init_city === item.city_id }));
    setDealerProCitys(newCitys);

    let dealers = getDealersData(init_city || citys[0].city_id, data.Dealers);
    dealers[0].default = true;
    let ownLatLng = ownAddress;
    if (sessionStorage.getItem('ownAddressLat') && sessionStorage.getItem('ownAddressLng')) {
      ownLatLng = [Number(sessionStorage.getItem('ownAddressLat')), Number(sessionStorage.getItem('ownAddressLng'))];
    }
    if (ownLatLng[0] && ownLatLng[1]) {
      let distance = Number.MAX_VALUE;
      for (let i = 0; i < dealers.length; i++) {
        if (getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealers[i].latitude), Number(dealers[i].longitude)) < distance) {
          distance = getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealers[i].latitude), Number(dealers[i].longitude));
        }
      }
      dealers = dealers.map((item) => ({
        ...item,
        default: getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(item.latitude), Number(item.longitude)) === distance
      }));
    }
    setDealerDealers(dealers);
    person.provinceId = init_province ? init_province : data.Provinces[0].province_id;
    person.cityId = init_city ? init_city : citys[0]?.city_id;
    for (let i = 0; i < dealers.length; i++) {
      if (dealers[i].default) {
        person.dealerId = dealers[i]?.dealer_id;
        setSpecialD(dealers[i].address);
      }
    }
    person.areaCode = init_zoneId ? init_zoneId : data.Provinces[0].zone_id;
    setPerson(person);
  };

  useEffect(() => {
    if (originalData?.current) {
      fetchData();
    }
  }, [tabIndex]);

  const getDealersData = (cityId, data) => {
    const vehicleWeightKey = driveDataList[tabIndex]?.fields?.vehicleWeight?.fields?.k?.value;
    let vehicleWeightValue = driveDataList[tabIndex]?.fields?.vehicleWeight?.fields?.v?.value;
    let dealers = [];
    if (!isEmpty(vehicleWeightValue)) {
      vehicleWeightValue = parseInt(vehicleWeightValue);
      dealers = data.filter((item) => item.city_id === cityId && (vehicleWeightValue & item.dealer_right) > 0);
      // if (vehicleWeightKey === 'enp1') {
      //   if (dealers.length === 0) {
      //     dealers = data.filter((item) => item.city_id === cityId && item.dealer_level === 1);
      //   } else {
      //     const exist = dealers.find((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     if (exist) {
      //       dealers = dealers.filter((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     }
      //   }
      // }
    }
    const newEnergy = dealers.filter((item) => item.dealer_right === 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    const gas = dealers.filter((item) => item.dealer_right !== 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    return [...newEnergy, ...gas];
  };

  // 点击切换渲染数据
  const selectProvince = (e) => {
    let p_value = e.target.childNodes[e.target.selectedIndex].getAttribute('value');
    let z_value = e.target.childNodes[e.target.selectedIndex].getAttribute('zoneid');

    const dmpPointList = fields.province_dmp_point;
    if (dmpPointList?.length > 0) {
      const dmpAction = dmpPointList[0]?.fields?.func?.value;
      const dmpPoint = dmpPointList[0].name;
      sendDmpAction(dmpAction, dmpPoint, p_value);
    }

    const aou = dealerProvince.Citys.filter((item) => {
      return item.province_id === p_value;
    });
    person.areaCode = z_value;
    setPerson(person);
    setDealerProCitys(aou);

    const dealers = getDealersData(aou[0].city_id, dealerProvince.Dealers);
    setDealerDealers(dealers);
    person.provinceId = p_value;
    person.cityId = aou[0].city_id;
    person.dealerId = dealers.length > 0 && dealers[0].dealer_id;
    setPerson(person);
    setSpecialD(dealers.length > 0 && dealers[0].address);
  };
  const selectCity = (e) => {
    let c_value = e.target.childNodes[e.target.selectedIndex].getAttribute('value');

    const dmpPointList = fields.city_dmp_point;
    if (dmpPointList?.length > 0) {
      const dmpAction = dmpPointList[0]?.fields?.func?.value;
      const dmpPoint = dmpPointList[0].name;
      sendDmpAction(dmpAction, dmpPoint, c_value);
    }

    const dealers = getDealersData(c_value, dealerProvince.Dealers);
    setDealerDealers(dealers);
    person.cityId = c_value;
    person.dealerId = dealers.length > 0 && dealers[0].dealer_id;
    setPerson(person);
    setSpecialD(dealers.length > 0 && dealers[0].address);
  };
  const selectDealer = (e) => {
    const dmpPointList = fields.shop_dmp_point;
    if (dmpPointList?.length > 0) {
      const dmpAction = dmpPointList[0]?.fields?.func?.value;
      const dmpPoint = dmpPointList[0].name;
      sendDmpAction(dmpAction, dmpPoint, e.target.childNodes[e.target.selectedIndex].getAttribute('value'));
    }

    person.dealerId = e.target.childNodes[e.target.selectedIndex].getAttribute('provinceid');
    setPerson(person);
    setSpecialD(dealerDealers[e.target.selectedIndex].address);
  };
  // 图形验证码

  return (
    <>
      <div
        className="TestDrive_v1"
        id="drive"
        ref={rootEl}
        style={convertStylesStringToObject(fields[getPrefixField('background_style', isMobile)]?.value)}
      >
        {vedioEnded ? (
          <div
            className="TestDriveCenter"
            style={
              fields[getPrefixField('animate_disabled', isMobile)].value
                ? { animation: 'unset', WebkitAnimation: 'unset', background: 'none' }
                : convertStylesStringToObject(fields[getPrefixField('background_color', isMobile)]?.value)
            }
          >
            <div>
              <p dangerouslySetInnerHTML={{ __html: fields[getPrefixField('title', isMobile)].value?.replaceHtml() }}></p>
              <div className="CarType" style={{ gridTemplateColumns: `repeat(${driveDataList.length}, 1fr)` }}>
                {driveDataList.map((item, index) => {
                  return (
                    <p
                      key={index}
                      className={
                        tabIndex === index
                          ? driveDataList.length === 4
                            ? 'carTypeActive4'
                            : 'carTypeActive'
                          : driveDataList.length === 4
                          ? 'carType4'
                          : 'carType'
                      }
                      onClick={() => {
                        sendDmp(item.fields.dmp_point);
                        setTabIndex(index);
                        person.series_code = item.fields[getPrefixField('series_code', isMobile)].value;
                        person.market_code = item.fields[getPrefixField('market_code', isMobile)].value;
                        person.model_code = item.fields.modelCode.value;
                        setPerson(person);
                      }}
                      dangerouslySetInnerHTML={{ __html: item.fields[getPrefixField('title', isMobile)].value?.replaceHtml() }}
                    >
                      {/* {item.fields[getPrefixField('title', isMobile)].value} */}
                    </p>
                  );
                })}
              </div>
              <div className="inputList">
                <div>
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: fields[getPrefixField('name_title', isMobile)].value?.replaceHtml() }}></p>
                    <input
                      type="text"
                      onInput={(e) => {
                        person.userName = e.target.value;
                      }}
                      onBlur={(e) => {
                        const dmpPointList = fields.name_dmp_point;
                        if (dmpPointList?.length > 0) {
                          const dmpAction = dmpPointList[0]?.fields?.func?.value;
                          const dmpPoint = dmpPointList[0].name;
                          sendDmpAction(dmpAction, dmpPoint, null);
                        }
                      }}
                      style={convertStylesStringToObject(fields[getPrefixField('input_style', isMobile)].value)}
                    />
                  </div>
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: fields[getPrefixField('tel_title', isMobile)].value?.replaceHtml() }}></p>
                    <input
                      type="text"
                      onInput={(e) => {
                        person.mobile = e.target.value;
                        setPerson(person);
                      }}
                      onBlur={(e) => {
                        const dmpPointList = fields.tel_dmp_point;
                        if (dmpPointList?.length > 0) {
                          const dmpAction = dmpPointList[0]?.fields?.func?.value;
                          const dmpPoint = dmpPointList[0].name;
                          sendDmpAction(dmpAction, dmpPoint, null);
                        }
                      }}
                      style={convertStylesStringToObject(fields[getPrefixField('input_style', isMobile)].value)}
                    />
                  </div>
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: fields[getPrefixField('province_title', isMobile)].value?.replaceHtml() }}></p>
                    <select
                      onChange={(e) => {
                        selectProvince(e);
                      }}
                      style={
                        !isMobile
                          ? convertStylesStringToObject(fields[getPrefixField('input_style', isMobile)].value)
                          : {
                              ...convertStylesStringToObject(fields[getPrefixField('input_style', isMobile)].value),
                              backgroundImage: `url(${fields[getPrefixField('vector_url', isMobile)].value}?${createFilePath(
                                fields[getPrefixField('vector_url', isMobile)].value
                              )}:')`,
                              backgroundPosition: '90%',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: '16px'
                            }
                      }
                      value={person.provinceId}
                    >
                      {dealerProvince &&
                        dealerProvince.Provinces.map((item, index) => {
                          return (
                            <option
                              key={index}
                              // selected={item.default ? 'selected' : ''}
                              provinceid={item.province_id}
                              zoneid={item.zone_id}
                              value={item.province_id}
                              style={convertStylesStringToObject(fields[getPrefixField('option_style', isMobile)].value)}
                            >
                              {item.province_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: fields[getPrefixField('city_title', isMobile)].value?.replaceHtml() }}></p>
                    <select
                      onChange={(e) => {
                        selectCity(e);
                      }}
                      style={
                        !isMobile
                          ? convertStylesStringToObject(fields[getPrefixField('input_style', isMobile)].value)
                          : {
                              ...convertStylesStringToObject(fields[getPrefixField('input_style', isMobile)].value),
                              backgroundImage: `url(${fields[getPrefixField('vector_url', isMobile)].value}?${createFilePath(
                                fields[getPrefixField('vector_url', isMobile)].value
                              )}:')`,
                              backgroundPosition: '90%',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: '16px'
                            }
                      }
                      value={person.cityId}
                    >
                      {dealerProCitys &&
                        dealerProCitys.map((item, index) => {
                          return (
                            <option
                              style={convertStylesStringToObject(fields[getPrefixField('option_style', isMobile)].value)}
                              key={index}
                              // selected={item.default ? 'selected' : ''}
                              provinceid={item.city_id}
                              value={item.city_id}
                            >
                              {item.city_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        driveDataList[tabIndex].fields[getPrefixField('shopTitle', isMobile)].value?.replaceHtml() ||
                        fields[getPrefixField('shop_title', isMobile)].value?.replaceHtml()
                    }}
                  ></p>
                  <select
                    onChange={(e) => {
                      selectDealer(e);
                    }}
                    style={
                      !isMobile
                        ? convertStylesStringToObject(fields[getPrefixField('input_style', isMobile)].value)
                        : {
                            ...convertStylesStringToObject(fields[getPrefixField('input_style', isMobile)].value),
                            backgroundImage: `url(${fields[getPrefixField('vector_url', isMobile)].value}?${createFilePath(
                              fields[getPrefixField('vector_url', isMobile)].value
                            )}:'')`,
                            backgroundPosition: '90%',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '16px'
                          }
                    }
                    value={person.dealerId}
                  >
                    {dealerDealers &&
                      dealerDealers.map((item, index) => {
                        return (
                          <option
                            style={convertStylesStringToObject(fields[getPrefixField('option_style', isMobile)].value)}
                            key={index}
                            provinceid={item.dealer_id}
                            value={item.dealer_id}
                            // selected={item.default ? 'selected' : ''}
                          >
                            {item.dealer_name}
                          </option>
                        );
                      })}
                  </select>
                  <div className="specialAddress" style={convertStylesStringToObject(fields[getPrefixField('text_address_style', isMobile)].value)}>
                    地址:{specialDi}
                  </div>
                </div>
              </div>
              <div className="read">
                <div style={convertStylesStringToObject(fields[getPrefixField('text_style', isMobile)].value)}>
                  <input
                    type="checkbox"
                    ref={checkboxRef}
                    onClick={() => {
                      setAgree(!agree);
                      const dmpPointList = fields.btn1_dmp_point;
                      if (dmpPointList?.length > 0) {
                        const dmpAction = dmpPointList[0]?.fields?.func?.value;
                        const dmpPoint = dmpPointList[0].name;
                        sendDmpAction(dmpAction, dmpPoint, !agree);
                      }
                    }}
                  />
                  <p
                    onClick={() => {
                      checkboxRef.current.click();
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: fields[getPrefixField('text_detail_1', isMobile)].value?.replaceHtml() }}></span>
                    <Link
                      target="_blank"
                      href={fields[getPrefixField('information_linkUrl', isMobile)].value}
                      field={fields[getPrefixField('information_linkText', isMobile)]}
                      onClick={(event) => {
                        event.stopPropagation();
                        const dmpPointList = fields.btn2_dmp_point;
                        if (dmpPointList?.length > 0) {
                          const dmpAction = dmpPointList[0]?.fields?.func?.value;
                          const dmpPoint = dmpPointList[0].name;
                          sendDmpAction(dmpAction, dmpPoint, fields[getPrefixField('information_linkUrl', isMobile)].value);
                        }
                      }}
                      style={convertStylesStringToObject(fields[getPrefixField('information_linkText_style', isMobile)].value)}
                    >
                      {fields[getPrefixField('information_linkText', isMobile)].value}
                    </Link>
                    <span dangerouslySetInnerHTML={{ __html: fields[getPrefixField('text_detail_2', isMobile)].value?.replaceHtml() }}></span>
                  </p>
                </div>
                <div>
                  <button
                    data-action={fields.btn3_dmp_point?.length > 0 ? fields.btn3_dmp_point[0]?.fields?.func?.value : ''}
                    data-item={fields.btn3_dmp_point?.length > 0 ? fields.btn3_dmp_point[0].name : ''}
                    onClick={() => {
                      const data = {
                        n: person.userName,
                        tel: person.mobile,
                        prv: person.provinceId,
                        city: person.cityId,
                        dl: person.dealerId,
                        ver: policyVersion
                      };

                      if (person.userName !== '' && person.mobile !== '') {
                        if (agree) {
                          if (!/^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/.test(person.mobile)) {
                            alert('手机号码格式不正确');
                          } else {
                            if (localStorage.getItem('beforePerson')) {
                              const beforePerson = JSON.parse(localStorage.getItem('beforePerson'));
                              let personFlag = false;
                              for (const key in beforePerson) {
                                if (beforePerson[key] !== person[key]) {
                                  personFlag = true;
                                }
                              }
                              if (personFlag) {
                                dataFetcher('/api/honda/v1/Leads/AddLeads', person).then((res) => {
                                  if (res.data.Status === true) {
                                    alert('提交成功');
                                    sendDmpAction('submit', 'drive', data);
                                    localStorage.setItem(
                                      'beforePerson',
                                      JSON.stringify({
                                        userName: person.userName,
                                        mobile: person.mobile,
                                        provinceId: person.provinceId, // 省份Id Provinces.province_name
                                        cityId: person.cityId, // 城市id Citys.city_id
                                        dealerId: person.dealerId, // 经销商id Dealers.dealer_id
                                        mediafrom: fields[`${getPrefixField('mediafrom', isMobile)}`]?.value || 2, // 留资方
                                        series_code: person.series_code,
                                        market_code: person.market_code,
                                        model_code: person.model_code,
                                        areaCode: person.areaCode,
                                        adcode: person.adcode,
                                        contactid: person.contactid,
                                        device_type: isMobile ? 2 : 1
                                      })
                                    );
                                  } else {
                                    if (
                                      res.data.ErrorResult.ErrCode === '26' ||
                                      res.data.ErrorResult.ErrCode === '27' ||
                                      res.data.ErrorResult.ErrCode === '28'
                                    ) {
                                      setSubmited(true);
                                      customer.phone = person.mobile;
                                      setCustomerPhone(person.mobile);
                                      dataFetcher('/api/honda/v1/Graphic/CreateCode', null).then((res) => {
                                        if (res.status) {
                                          person.graphic.id = res.data.Data.GraphicValidId;
                                          setSemImg(res.data.Data.Base64);
                                          setImgId(res.data.Data.GraphicValidId);
                                          setPerson(person);
                                        }
                                      });
                                    } else {
                                      alert(res.data.ErrorResult.ErrMsg);
                                    }
                                  }
                                });
                              } else {
                                alert('重复提交');
                              }
                            } else {
                              dataFetcher('/api/honda/v1/Leads/AddLeads', person).then((res) => {
                                if (res.data.Status === true) {
                                  alert('提交成功');
                                  sendDmpAction('submit', 'drive', data);
                                  localStorage.setItem(
                                    'beforePerson',
                                    JSON.stringify({
                                      userName: person.userName,
                                      mobile: person.mobile,
                                      provinceId: person.provinceId, // 省份Id Provinces.province_name
                                      cityId: person.cityId, // 城市id Citys.city_id
                                      dealerId: person.dealerId, // 经销商id Dealers.dealer_id
                                      mediafrom: fields[`${getPrefixField('mediafrom', isMobile)}`]?.value || 2, // 留资方
                                      series_code: person.series_code,
                                      market_code: person.market_code,
                                      model_code: person.model_code,
                                      areaCode: person.areaCode,
                                      adcode: person.adcode,
                                      contactid: person.contactid,
                                      device_type: isMobile ? 2 : 1
                                    })
                                  );
                                } else {
                                  if (
                                    res.data.ErrorResult.ErrCode === '26' ||
                                    res.data.ErrorResult.ErrCode === '27' ||
                                    res.data.ErrorResult.ErrCode === '28'
                                  ) {
                                    setSubmited(true);
                                    customer.phone = person.mobile;
                                    setCustomerPhone(person.mobile);
                                    dataFetcher('/api/honda/v1/Graphic/CreateCode', null).then((res) => {
                                      if (res.status) {
                                        person.graphic.id = res.data.Data.GraphicValidId;
                                        setSemImg(res.data.Data.Base64);
                                        setImgId(res.data.Data.GraphicValidId);
                                        setPerson(person);
                                      }
                                    });
                                  } else {
                                    alert(res.data.ErrorResult.ErrMsg);
                                  }
                                }
                              });
                            }
                          }
                        } else {
                          alert('请确认同意条款');
                        }
                      } else {
                        alert('名字手机号码不能为空');
                      }
                    }}
                    style={convertStylesStringToObject(fields[getPrefixField('submit_btn_style', isMobile)].value)}
                  >
                    {fields[getPrefixField('submit_btn', isMobile)].value}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {fields[getPrefixField('qr_code_url', isMobile)].value && !fields?.is_hide_qr_code?.value && (
          <div className="qrCode">
            <img
              alt="广汽本田"
              src={`${createFilePath(fields[getPrefixField('qr_code_url', isMobile)].value)}`}
              style={convertStylesStringToObject(!isMobile ? fields?.qr_code_style?.value : fields?.m_qr_code_style?.value)}
            />
            <div className="qrCode-right">
              <div dangerouslySetInnerHTML={{ __html: fields[getPrefixField('qr_text', isMobile)].value?.replaceHtml() }}></div>
            </div>
          </div>
        )}
        {vedioEnded
          ? showTabFrontCar
            ? driveDataList.map((item, index) => {
                return (
                  <img
                    alt="广汽本田"
                    key={index}
                    src={`${createFilePath(item?.fields[getPrefixField('frontCar', isMobile)].value)}`}
                    className={`carImg showTabFrontCar ${index === tabIndex ? 'showTabFrontCarActive' : ''}`}
                    style={convertStylesStringToObject(item?.fields[getPrefixField('car_img_style', isMobile)].value)}
                  />
                );
              })
            : fields[getPrefixField('frontCar', isMobile)].value && (
                <img
                  alt="广汽本田"
                  src={`${createFilePath(fields[getPrefixField('frontCar', isMobile)].value)}`}
                  className="carImg"
                  style={convertStylesStringToObject(fields[getPrefixField('car_img_style', isMobile)].value)}
                />
              )
          : ''}
        {vedioEnded && !isMobile ? (
          <div
            className="carBottom"
            style={
              fields[getPrefixField('animate_disabled', isMobile)].value
                ? { animation: 'unset', WebkitAnimation: 'unset', opacity: 0, display: 'none' }
                : convertStylesStringToObject(fields[getPrefixField('carBottom_style', isMobile)]?.value)
            }
          ></div>
        ) : (
          ''
        )}
        {!isMobile && !fields[getPrefixField('isImg', isMobile)]?.value ? (
          <>
            {driveDataList.length > 0 && driveDataList[tabIndex]?.fields[getPrefixField('video', isMobile)]?.value ? (
              <video
                src={`${createFilePath(driveDataList[tabIndex]?.fields[getPrefixField('video', isMobile)]?.value)}`}
                autoPlay="autoplay"
                muted={true}
                loop={true}
                onEnded={() => {
                  setVedioEnded(true);
                }}
              />
            ) : (
              <video
                src={`${createFilePath(fields[getPrefixField('video', isMobile)].value)}`}
                autoPlay="autoplay"
                muted={true}
                loop={true}
                onEnded={() => {
                  setVedioEnded(true);
                }}
              />
            )}
          </>
        ) : (
          // !fields[getPrefixField('qr_code_url', isMobile)].value &&
          (fields[getPrefixField('video', isMobile)].value ||
            (driveDataList.length > 0 && driveDataList[tabIndex]?.fields[getPrefixField('video', isMobile)]?.value)) && (
            <>
              {driveDataList.length > 0 && driveDataList[tabIndex]?.fields[getPrefixField('video', isMobile)]?.value ? (
                <img
                  alt="广汽本田"
                  className="background"
                  src={`${createFilePath(driveDataList[tabIndex]?.fields[getPrefixField('video', isMobile)]?.value)}`}
                  style={
                    fields[getPrefixField('animate_disabled', isMobile)].value
                      ? {
                          ...convertStylesStringToObject(fields[getPrefixField('carBackground_style', isMobile)].value),
                          animation: 'unset',
                          WebkitAnimation: 'unset'
                        }
                      : {}
                  }
                ></img>
              ) : (
                <img
                  alt="广汽本田"
                  className="background"
                  src={`${createFilePath(fields[getPrefixField('video', isMobile)].value)}`}
                  style={
                    fields[getPrefixField('animate_disabled', isMobile)].value
                      ? {
                          ...convertStylesStringToObject(fields[getPrefixField('carBackground_style', isMobile)].value),
                          animation: 'unset',
                          WebkitAnimation: 'unset'
                        }
                      : {}
                  }
                ></img>
              )}
            </>
          )
        )}
        {submited ? (
          <Dialog key={Math.random()} width="100%">
            <div className="formPhone">
              <p>手机号*</p>
              <input type="text" value={person.mobile} readOnly="readOnly" />
            </div>
            <div className="graphicCode">
              <div>
                <p>图形验证码*</p>
                <input
                  type="text"
                  onChange={(e) => {
                    // 设置留资链接和短信公用的图形编码
                    person.graphic.code = e.target.value;
                    customer.validCode = e.target.value;
                    setPerson(person);
                  }}
                />
              </div>
              <img
                alt="广汽本田"
                src={semImg}
                onClick={() => {
                  dataFetcher('/api/honda/v1/Graphic/CreateCode', null).then((res) => {
                    if (res.status) {
                      const graphicID = res.data.Data.GraphicValidId;
                      person.graphic.id = graphicID;
                      setSemImg(res.data.Data.Base64);
                      setImgId(res.data.Data.GraphicValidId);
                      setPerson(person);
                    }
                  });
                }}
              />
            </div>
            <div className="messageCode">
              <div>
                <p>短信验证码*</p>
                <input
                  type="text"
                  onInput={(e) => {
                    // 设置留资链接的短信code
                    person.smsValidCode = e.target.value;
                    setPerson(person);
                  }}
                />
              </div>
              <button
                onClick={() => {
                  if (customer.validCode !== '') {
                    // 完善customer
                    customer.validId = imgId;
                    customer.phone = customerPhone;
                    dataFetcher('/api/honda/v1/SMS/SendSMS', customer).then((res) => {
                      // 设置留资链接的短信id
                      if (res.data.Status) {
                        alert('已发送短信,请注意查收');
                        person.smsValidId = res.data.Data.smsValidId;
                        setPerson(person);
                      } else {
                        if (res.data.ErrorResult.ErrCode === '-1') {
                          alert('短信发送失败');
                        } else if (res.data.ErrorResult.ErrCode === '1' || '2' || '3' || '4' || '6') {
                          alert(res.data.ErrorResult.ErrMsg);
                        } else {
                          if (res.data.ErrorResult.ErrCode === '5') {
                            alert('图形验证码错误');
                            dataFetcher('/api/honda/v1/Graphic/CreateCode', null).then((res) => {
                              if (res.status) {
                                const graphicID = res.data.Data.GraphicValidId;
                                person.graphic.id = graphicID;
                                setSemImg(res.data.Data.Base64);
                                setImgId(res.data.Data.GraphicValidId);
                                setPerson(person);
                              }
                            });
                          } else if (res.data.ErrorResult.ErrCode === '11') {
                            alert('手机号当天发送次数超限');
                          } else if (res.data.ErrorResult.ErrCode === '12') {
                            alert('手机号发送次数超限，请1小时候再试');
                          } else if (res.data.ErrorResult.ErrCode === '13') {
                            alert('手机号发送频率过快，请稍后再试');
                          } else if (res.data.ErrorResult.ErrCode === '21') {
                            alert('非常抱歉，发送验证码时出现异常，请稍后再试');
                          }
                        }
                      }
                    });
                  } else {
                    alert('请输入图形验证码');
                  }
                }}
              >
                发送
              </button>
            </div>
            <button
              onClick={() => {
                customer.validId = imgId;
                customer.phone = customerPhone;
                const data = {
                  n: person.userName,
                  tel: person.mobile,
                  prv: person.provinceId,
                  city: person.cityId,
                  dl: person.dealerId,
                  ver: policyVersion
                };
                if (person.smsValidCode !== '') {
                  dataFetcher('/api/honda/v1/Leads/AddLeads', person).then((res) => {
                    if (res.data.Status) {
                      setSubmited(false);
                      alert('提交成功');
                      sendDmpAction('submit', 'drive', data);
                      localStorage.setItem(
                        'beforePerson',
                        JSON.stringify({
                          userName: person.userName,
                          mobile: person.mobile,
                          provinceId: person.provinceId, // 省份Id Provinces.province_name
                          cityId: person.cityId, // 城市id Citys.city_id
                          dealerId: person.dealerId, // 经销商id Dealers.dealer_id
                          mediafrom: fields[`${getPrefixField('mediafrom', isMobile)}`]?.value || 2, // 留资方
                          series_code: person.series_code,
                          market_code: person.market_code,
                          model_code: person.model_code,
                          areaCode: person.areaCode,
                          adcode: person.adcode,
                          contactid: person.contactid,
                          device_type: isMobile ? 2 : 1
                        })
                      );
                    } else {
                      if (res.data.ErrorResult.ErrCode === '28') {
                        alert('短信验证码输入错误');
                      }
                    }
                  });
                } else {
                  alert('短信验证码不能为空');
                }
              }}
            >
              确定
            </button>
            <img
              className="closeIcon"
              src={createFilePath('/breeze/v1/pc/price-control/deployed/close.png')}
              alt="close"
              onClick={() => {
                setSubmited(false);
              }}
            />
          </Dialog>
        ) : (
          ''
        )}
      </div>
    </>
  );
}
