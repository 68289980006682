import React, { useEffect, useRef, useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, SplitWords, Timeline } from 'react-gsap';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import temp from '../../temp/config';
import './style.scss';
import { isImage, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { pc_video, m_video, dialog_video, cursor, flag, open, id, disableAnimate } = props;
  const isMobile = isMobileFun();
  const thisMobile = !isMobileFun();
  const textArr = props[`${getPrefixField('textScroll', !thisMobile)}`]?.value.split('----');
  const rootEl = useRef(null);

  return (
    <section>
      <Controller>
        <Scene triggerHook={'onLeave'} duration={'100%'} pin={!disableAnimate?.value}>
          {(progress, event) => {
            return (
              <div>
                <Timeline totalProgress={disableAnimate?.value ? 1 : progress} paused>
                  <Tween from={{ scale: 0.9 }} to={{ scale: 1 }} position={1}>
                    <div
                      className={'text-scroll-y-telling'}
                      style={{ cursor: `url(${createFilePath(cursor.value)}), pointer` }}
                      ref={rootEl}
                      onClick={() => {
                        thisMobile && open();
                      }}
                    >
                      <Tween from={{ scale: 1 }} to={{ scale: 1 }} position={2} stagger={0.2}>
                        {thisMobile ? (
                          isImage(pc_video?.value) ? (
                            <img alt="广汽本田" className="gifSource" src={`${createFilePath(pc_video?.value)}`} />
                          ) : (
                            <video
                              x5-playsinline="true"
                              webkit-playsinline="true"
                              playsInline={true}
                              src={`${createFilePath(pc_video?.value)}`}
                              autoPlay="autoplay"
                              muted={true}
                              loop
                            />
                          )
                        ) : (
                          <img alt="广汽本田" className="gifSource" src={`${createFilePath(m_video?.value)}`} />
                        )}
                      </Tween>
                      <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={2} stagger={0.2}>
                        <div className="text-container">
                          <div
                            className={`split-text ${disableAnimate?.value ? '' : `animate__animated ${progress > 0.4 ? 'animate__fadeInUp' : ''}`}`}
                            style={{
                              display: disableAnimate?.value ? 'block' : '',
                              fontSize: props[`${getPrefixField('fontStyle', !thisMobile)}`].value
                            }}
                          >
                            {textArr.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Tween from={{ opacity: 0.2 }} to={{ opacity: 1 }} position={5 + index} stagger={0.2} ease="elastic.out(0.1, 0.1)">
                                    <div
                                      style={{ display: 'inline-block', minWidth: thisMobile ? '1.9rem' : '0.25rem' }}
                                      dangerouslySetInnerHTML={{ __html: item?.replaceHtml() }}
                                    ></div>
                                  </Tween>
                                  <p></p>
                                </React.Fragment>
                              );
                            })}
                            {!thisMobile && (
                              <button
                                className="MobileInsertBtn"
                                onClick={() => {
                                  open();
                                }}
                              >
                                完整视频
                              </button>
                            )}
                          </div>
                        </div>
                      </Tween>
                    </div>
                  </Tween>
                </Timeline>
              </div>
            );
          }}
        </Scene>
      </Controller>
      <div id={id} />
    </section>
  );
}
