/* eslint-disable no-bitwise */
import _ from 'underscore';
import { getWindow } from 'ssr-window';
import { isMobile as isDeviceMobile } from './dom-utils';
import { getQueryVariable } from '../utils/site-utils';
import temp from '../temp/config';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMobile } from 'react-device-detect';
import { GetGmallAppSchemeUrl } from '../utils/api';

export const getType = (data) => {
  return Object.prototype.toString.call(data);
};
export const toLowCamel = (data, separator = '_') => {
  const type = getType(data);
  if (type === '[object Array]') {
    let result = [];
    for (let item of data) {
      result.push(toLowCamel(item));
    }
    return result;
  } else if (type === '[object Object]') {
    let result = {};
    for (let key of Object.keys(data)) {
      const newKey = key
        .replace(/ID/g, `${separator}id`)
        .replace(/\B[A-Z]/g, (match) => `${separator}${match.toLowerCase()}`)
        .toLowerCase();
      result[newKey] = toLowCamel(data[key]);
    }
    return result;
  } else {
    return data;
  }
};
const devicePrefix = 'm_';

export const getDevicePrefix = () => {
  return devicePrefix;
};

// 获取当前返回字段前缀
export const getPrefixField = (name = '', isMobile = isDeviceMobile()) => {
  return `${isMobile ? devicePrefix : ''}${name}`;
};

// 过滤html
export const filterHtmlTag = (string) => {
  return string.replace(/<.*?>/g, '');
};

// 判断是否为空
export const isEmpty = (obj) => {
  if (typeof obj === 'undefined' || obj === null) {
    return true;
  }
  if (typeof obj === 'string' && trim(obj) === '') {
    return true;
  }
  return false;
};

// 删除字符串两侧空格
export const trim = (str) => {
  return str.replace(/(^\s*)|(\s*$)/g, '');
};

// dmp
export const sendDmpAction = (type, point, value1 = '', value2 = '') => {
  if (isEmpty(type)) {
    return;
  }
  window.Dmp[`${type}Action`](point, value1, value2);
};

export const sendDmp = (dmpList, value1 = '', value2 = '', action) => {
  if (dmpList?.length > 0) {
    const dmpAction = action || dmpList[0]?.fields?.func?.value;
    const dmpPoint = dmpList[0].name;
    sendDmpAction(dmpAction, dmpPoint, value1, value2);
  }
};

// path name
export const getPathnameByPosition = (position = -1) => {
  const pathArray = getWindow()
    .location.pathname.split('/')
    .filter((path) => path);
  if (pathArray.length > 0) {
    return pathArray[position >= 0 ? position : pathArray.length + position];
  }
  return '';
};

// Parse html style string for a react component
export const convertStylesStringToObject = (stringStyles, from = '${dm-media}', to = temp.app.deployUrlS3) =>
  stringStyles
    ? typeof stringStyles === 'string'
      ? stringStyles
          .replace(/[${]+dm-media}[$]/g, to)
          .replace(/[${]+dm-media}/g, to)
          .split(';')
          .reduce((acc, style) => {
            const colonPosition = style.indexOf(':');

            if (colonPosition === -1) {
              return acc;
            }

            const camelCaseProperty = style
                .substr(0, colonPosition)
                .trim()
                .replace(/^-ms-/, 'ms-')
                .replace(/-./g, (c) => c.substr(1).toUpperCase()),
              value = style.substr(colonPosition + 1).trim();

            return value ? { ...acc, [camelCaseProperty]: value } : acc;
          }, {})
      : stringStyles
    : {};

// is in view
export const isInView = (rootEl, offset = 0) => {
  const rect = rootEl?.current?.getBoundingClientRect() || rootEl?.getBoundingClientRect();
  const topOffset = /* getHeaderHeight() + */ offset + window.innerHeight / (isDeviceMobile() ? 2 : 2);
  const bottomOffset = /* getFooterHeight() + */ offset + window.innerHeight / (isDeviceMobile() ? 2 : 2);
  return rect.top <= window.innerHeight - bottomOffset && rect.bottom >= topOffset;
};

// is scroll to bottom
export const isScrollToBottom = (offset = 0, element = window) => {
  const scrollTop = element === window ? document.documentElement.scrollTop || document.body.scrollTop : element.scrollTop;
  const windowHeight = element === window ? document.documentElement.clientHeight || document.body.clientHeight : element.clientHeight;
  const scrollHeight = element === window ? document.documentElement.scrollHeight || document.body.scrollHeight : element.scrollHeight;

  return scrollHeight <= scrollTop + windowHeight + offset;
};

// scroll to anchor
export const scrollToAnchor = (anchorName, block, behavior = 'smooth') => {
  if (anchorName) {
    try {
      window.top.Dmp.isAnchor = true;
      const anchorElement = window.top.document.getElementById(anchorName);
      if (anchorElement) {
        if (anchorElement === window.top.document.querySelector('.NavigationMachine')) {
          anchorElement.scrollIntoView({ block: block || 'end', behavior });
        } else if (anchorName === 'price_power' && getPathnameByPosition()?.toLowerCase() === 'integra') {
          if (anchorElement.getBoundingClientRect().bottom <= 0) {
            anchorElement.scrollIntoView({ block: block || 'end', behavior });
          } else {
            anchorElement.scrollIntoView({ block: block || 'start', behavior });
          }
        } else {
          if (anchorElement.offsetHeight > window.top.document.documentElement.clientHeight - 60) {
            anchorElement.scrollIntoView({ block: block || 'start', behavior });
          } else {
            anchorElement.scrollIntoView({ block: block || 'end', behavior });
          }
        }
      }
      if (anchorName === 'price_power' && getPathnameByPosition()?.toLowerCase() === 'integra') {
        scrollFinish(() => {
          window.scrollTo(0, window.scrollY + 1);
        });
      }
    } catch (e) {
      Dmp.isAnchor = true;
      const anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        if (anchorElement === document.querySelector('.NavigationMachine')) {
          anchorElement.scrollIntoView({ block: block || 'end', behavior });
        } else if (anchorName === 'price_power' && getPathnameByPosition()?.toLowerCase() === 'integra') {
          if (anchorElement.getBoundingClientRect().bottom <= 0) {
            anchorElement.scrollIntoView({ block: block || 'end', behavior });
          } else {
            anchorElement.scrollIntoView({ block: block || 'start', behavior });
          }
        } else {
          if (anchorElement.offsetHeight > document.documentElement.clientHeight - 60) {
            anchorElement.scrollIntoView({ block: block || 'start', behavior });
          } else {
            anchorElement.scrollIntoView({ block: block || 'end', behavior });
          }
        }
      }
      if (anchorName === 'price_power' && getPathnameByPosition()?.toLowerCase() === 'integra') {
        scrollFinish(() => {
          window.scrollTo(0, window.scrollY + 1);
        });
      }
    }
  }
};

// scroll finish
export const scrollFinish = (callback) => {
  let scrollTimeout;
  const scroll = () => {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(function () {
      removeEventListener('scroll', scroll);
      clearTimeout(scrollTimeout);
      callback && callback();
    }, 100);
  };

  addEventListener('scroll', scroll);
};

// get header height
export const getHeaderHeight = () => {
  let header = document.getElementsByClassName('ViceHeadernavBox');
  header = header?.length > 0 ? header : document.getElementsByClassName('headerBox-homePage');
  const headerHeight = parseFloat(header?.length > 0 ? getComputedStyle(header[0]).height.replace('px', '') : 0);
  return headerHeight;
};

// get footer height
export const getFooterHeight = () => {
  const footer = document.getElementsByClassName('btnList');
  const footerHeight = parseFloat(footer?.length > 0 ? getComputedStyle(footer[0])?.height?.replace('px', '') : 0);
  return footerHeight;
};
export const getFooterCarHeight = () => {
  const footer = document.getElementsByClassName('Fotter-homePage-mobile');
  const footerHeight = parseFloat(footer?.length > 0 ? getComputedStyle(footer[0])?.height?.replace('px', '') : 0);
  return footerHeight;
};

// check name is image
export const isImage = (name) => {
  return /\.(jpg|jpeg|png|gif|svgz|ico|avif|tiff|svg).*$/i.test(name);
};
export const isImage2 = (name) => {
  return /\.(jpg|jpeg|png).*$/i.test(name);
};

// check name is video
export const isVideo = (name) => {
  return /\.(m4v|avi|mpg|mp4|webm).*$/i.test(name);
};
export const isPngImage = (name) => {
  return /\.(png).*$/i.test(name);
};

export const addParamToImages = (isPng) => {
  // var q = getQueryVariable('q') ? 'q=' + getQueryVariable('q') : '';s
  // var Q = getQueryVariable('Q') ? 'Q=' + getQueryVariable('Q') : '';
  // var q = getWindow().ImgQuality_q ? 'q=' + getWindow().ImgQuality_q : '';
  // var Q = getWindow().ImgQuality_Q ? 'Q=' + getWindow().ImgQuality_Q : '';
  var q = '';
  var Q = 'Q=70';

  if (q || Q) {
    if (q && !Q) {
      return q + (isPng ? '&c=1' : '');
    } else if (!q && Q) {
      return Q + (isPng ? '&c=1' : '');
    } else if (q && Q) {
      return q + '&' + Q + (isPng ? '&c=1' : '');
    } else {
      return '';
    }
  } else {
    return '';
  }
};
// create file path
export const createFilePath = (path, prefix = temp.app.deployUrlS3) => {
  var pathnew = path;
  if (isEmpty(pathnew)) {
    return;
  }
  // if (isImage2(pathnew)) {
  //   pathnew = pathnew.trim();
  //   if (pathnew.indexOf('?') !== -1) {
  //     if (addParamToImages()) {
  //       pathnew = pathnew + '&' + addParamToImages(isPngImage(pathnew));
  //     } else {
  //       pathnew = pathnew;
  //     }
  //   } else {
  //     if (addParamToImages()) {
  //       pathnew = pathnew + '?' + addParamToImages(isPngImage(pathnew));
  //     } else {
  //       pathnew = pathnew;
  //     }
  //   }
  // }
  if (pathnew.indexOf('http') !== 0) {
    return `${prefix}${pathnew}`;
  }
  return pathnew;
};
String.prototype.replaceHtml = function (from = '${dm-media}', to = temp.app.deployUrlS3) {
  var param = /[${]+dm-param}/g;
  var pngparam = /[${]+dm-pngparam}/g;
  if (isEmpty(this)) {
    return this;
  }
  if (param.test(this) || pngparam.test(this)) {
    // if (addParamToImages()) {
    //   return this.replace(/[${]+dm-media}[$]/g, to)
    //     .replace(/[${]+dm-media}/g, to)
    //     .replace(/[${]+dm-param}/g, addParamToImages(false))
    //     .replace(/[${]+dm-pngparam}/g, addParamToImages(true));
    // } else {
    return this.replace(/[${]+dm-media}[$]/g, to)
      .replace(/[${]+dm-media}/g, to)
      .replace(/[$?{]+dm-param}/g, '')
      .replace(/[$?{]+dm-pngparam}/g, '')
      .replace(/[$&{]+dm-param}/g, '')
      .replace(/[$&{]+dm-pngparam}/g, '');
    // }
  } else {
    return this.replace(/[${]+dm-media}[$]/g, to).replace(/[${]+dm-media}/g, to);
  }
};

// create file path
// export const createFilePath = (path, prefix = temp.app.deployUrlS3) => {
//   if (isEmpty(path)) {
//     return;
//   }
//   if (path.indexOf('http') !== 0) {
//     return `${prefix}${path}`;
//   }
//   return path;
// };

// replace ${dm-media} to path
// String.prototype.replaceHtml = function (from = '${dm-media}', to = temp.app.deployUrlS3) {
//   if (isEmpty(this)) {
//     return this;
//   }
//   return this.replace(/[${]+dm-media}[$]/g, to).replace(/[${]+dm-media}/g, to);
// };

// create guid
export const guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// num count up
export const countUp = (dom, numStr = '0', time = 1000) => {
  let showNum = 0;
  let flag = 'int';
  const _numStr = `${numStr}`.trim();
  if (_numStr.indexOf('.') === -1) {
    showNum = parseInt(_numStr);
  } else {
    showNum = parseInt((parseFloat(_numStr) * 10).toString());
    flag = 'double';
  }
  for (let a = 0; a <= showNum; a++) {
    (function (t) {
      setTimeout(() => {
        dom.innerText = flag === 'int' ? t : t / 10;
      }, (time / showNum) * t);
    })(a);
  }
};

// debounce
export const debounce = (func, wait = 300) => {
  return _.debounce(func, wait);
};

// open link
export const openLink = (href, target = '_blank', referrerpolicy = 'no-referrer-when-downgrade') => {
  if (isEmpty(href)) {
    return;
  }
  const a = document.createElement('a');
  a.setAttribute('href', href);
  a.setAttribute('target', target);
  a.setAttribute('referrerpolicy', referrerpolicy);
  a.click();
};

export const imageLoaded = (image, callback) => {
  const timer = setInterval(function () {
    if ((image?.current || image).complete) {
      callback && callback();
      clearInterval(timer);
    }
  }, 50);
};

// 获取Url参数
export const getQueryString = (name) => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = getWindow().location.search.substring(1).match(reg);
  if (r !== null) return decodeURIComponent(r[2]);
  return null;
};

export const updateQueryStringParameter = (url, key, value) => {
  if (!value) {
    return url;
  }
  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  var separator = url.indexOf('?') !== -1 ? '&' : '?';
  if (url.match(re)) {
    return url.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return url + separator + key + '=' + value;
  }
};

// 加载字体
export const loadFont = (fontList) => {
  const code = fontList.reduce((accumulator, currentValue) => {
    return accumulator + `@font-face { font-family: ${currentValue.fontFamily};src: url('${currentValue.fontUrl}'); }`;
  }, '');
  const style = getWindow()?.document?.createElement('style');
  style.rel = 'stylesheet';
  style.appendChild(getWindow()?.document?.createTextNode(code));
  const head = getWindow()?.document?.getElementsByTagName('head')[0];
  head.appendChild(style);
};

// 校验手机
export const checkMobile = (text) => {
  return /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/.test(text);
};

// set url Parameter
export const setUrlParameter = (data = {}) => {
  const url = `${Object.keys(data)
    .map((item) => `${item}=${data[item]}`)
    .reduce((previous, current, index) => `${previous}${index !== 0 ? '&' : ''}${current}`, `${location.origin}${location.pathname}?`)}`;
  history.replaceState(history.state, '', url);
};

// check is json
export const isJSON = (str) => {
  if (typeof str !== 'string') {
    return false;
  }

  try {
    const obj = JSON.parse(str);
    if (typeof obj === 'object' && obj) {
      return obj;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const pcTorem = ($cout) => {
  const $devwidth = 1920;
  const $coutwidth = 120;
  const $rem = 16;
  return ($cout * $coutwidth) / $devwidth + 'rem';
};

export const mbTorem = ($cout) => {
  const $mdevwidth = 375;
  const $mcoutwidth = 750;
  const $rem = 100;
  return ($cout * $mcoutwidth) / $mdevwidth / $rem + 'rem';
};

export const setlocationhistory = (mobile, bool = false, config) => {
  if (bool) {
    var newurl = updateQueryStringParameter(window.location.href, config, mobile);
    window.history.replaceState({ path: newurl }, '', newurl);
  }
};

export const requestFullScreen = () => {
  var de = document.documentElement;
  if (de.requestFullscreen) {
    de.requestFullscreen();
  } else if (de.mozRequestFullScreen) {
    de.mozRequestFullScreen();
  } else if (de.webkitRequestFullScreen) {
    de.webkitRequestFullScreen();
  }
};
export const exitFullscreen = () => {
  if (document.fullscreenElement !== null) {
    var de = document;
    if (de.exitFullscreen) {
      de.exitFullscreen();
    } else if (de.mozCancelFullScreen) {
      de.mozCancelFullScreen();
    } else if (de.webkitCancelFullScreen) {
      de.webkitCancelFullScreen();
    }
  }
};

export const isMobileFun = () => {
  // var ismobileflag = navigator.userAgent.match(
  //   /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  // );
  // console.log(useSitecoreContext()?.sitecoreContext?.isMobile + '=1');
  // console.log(isDeviceMobile() + '=2');
  // console.log((ismobileflag ? true : false) + '=3');
  // console.log(isMobile + '=4');
  return useSitecoreContext()?.sitecoreContext?.isMobile;
};

export const GotoMall = (url, dmp, isMobile) => {
  var adcode = sessionStorage.getItem('adcode') || '';
  var clkid = sessionStorage.getItem('clkid') || '';
  var mallurl = url;
  if (mallurl) {
    if (isMobile) {
      GetGmallAppSchemeUrl(
        mallurl,
        (link) => {
          if (dmp) {
            window.Dmp.clickAction(dmp, link);
          }
        },
        adcode,
        clkid
      );
    } else {
      if (adcode && adcode !== 'undefined') {
        if (mallurl.indexOf('?') !== -1) {
          window.open(mallurl + '&adcode=' + adcode + '&clkid=' + clkid);
          mallurl = mallurl + '&adcode=' + adcode + '&clkid=' + clkid;
        } else {
          window.open(mallurl + '?adcode=' + adcode + '&clkid=' + clkid);
          mallurl = mallurl + '?adcode=' + adcode + '&clkid=' + clkid;
        }
      } else {
        window.open(mallurl);
      }
      if (dmp) {
        window.Dmp.clickAction(dmp, mallurl);
      }
    }
  }
};

export const GlobalCookie = {
  set: function (key, val, expiresDays, Path) {
    // 判断是否设置expiresDays
    if (expiresDays) {
      var date = new Date();
      date.setTime(date.getTime() + expiresDays * 24 * 3600 * 1000); // 格式化时间
      var expiresStr = 'expires=' + date.toGMTString() + ';';
    } else {
      var expiresStr = '';
    }
    if (Path) {
      var path = Path + ';';
    } else {
      var path = '';
    }
    document.cookie = key + '=' + escape(val) + ';' + path + expiresStr;
  },
  get: function (key) {
    var getCookie = document.cookie.replace(/[ ]/g, '');
    var resArr = getCookie.split(';');
    var res;
    for (var i = 0, len = resArr.length; i < len; i++) {
      var arr = resArr[i].split('=');
      if (arr[0] === key) {
        res = arr[1];
        break;
      }
    }
    return unescape(res);
  }
};
