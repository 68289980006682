import $ from 'jquery';

window.DmpCar = 'vgPre';
window.themeEn = ''; // szt vogue tech

// Piwik
// var _paq = _paq || [];
// /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
// _paq.push(['trackPageView']);
// _paq.push(['enableLinkTracking']);
// (function () {
//   var u = 'https://piwik.ghac.cn/piwik/';
//   _paq.push(['setTrackerUrl', u + 'piwik.php']);
//   _paq.push(['setSiteId', '1']);
//   var d = document,
//     g = d.createElement('script'),
//     s = d.getElementsByTagName('script')[0];
//   g.type = 'text/javascript';
//   g.async = true;
//   g.defer = true;
//   g.src = u + 'piwik.js';
//   s.parentNode.insertBefore(g, s);
// })();
// window._paq = _paq;

window.Dmp = {
  context: '',
  runningEvent: {},
  addTask: function (itemId) {
    if (!this.trackEnable) {
      return;
    }
    this.runningEvent[itemId] = (this.runningEvent[itemId] || 0) + 1;
  },
  advAction: function (callback) {
    if (this.advDisabled) {
      return;
    }
    const params =
      'u=' + encodeURIComponent(location.href) + '&coretype=' + (this.isMobile ? 'm' : 'pc') + '&r=' + encodeURIComponent(document.referrer);
    const image = new Image();
    image.src = '//stats.dmp.ghac.cn/adv.gif?' + params;
    image.onerror = callback;
    image.onload = callback;
    console.log('进入', params);
  },
  cookieAction: function (contactId, callback) {
    if (!this.trackEnable || !this.mappingEnable) {
      return;
    }
    const params = `id=${contactId}&ver=2.0`;
    const image = new Image();
    image.src = `//stats.dmp.ghac.cn/mapping?${params}`;
    image.onerror = callback;
    image.onload = callback;
    console.log('cookie mapping', params);
  },
  subscribeAction: function () {
    document.body.addEventListener('click', function (e) {
      e.stopPropagation();
      if (e.target.attributes['data-item'] && e.target.attributes['data-item'].nodeValue) {
        const item = e.target.attributes['data-item'].nodeValue;
        const itemUrl = e.target.attributes['data-url']?.nodeValue || '';
        const itemContext = e.target.attributes['data-context']?.nodeValue || '';
        window.Dmp.clickAction(item, itemUrl, itemContext);
      }
    });
    document.body.addEventListener('mouseover', function (e) {
      if (e.target.attributes['data-action'] && e.target.attributes['data-action'].nodeValue) {
        const action = e.target.attributes['data-action'].nodeValue.split('-');
        if (e.target.attributes['data-item'] && e.target.attributes['data-item'].nodeValue) {
          const item = e.target.attributes['data-item'].nodeValue;
          const itemContext = e.target.attributes['data-context']?.nodeValue || '';
          if (action.indexOf('hoverAction') === 1) {
            window.Dmp.hoverAction(item, itemContext);
          }
        }
      }
    });
  },
  ruleAction: function (sitecoreContext) {
    if (!this.trackEnable || !this.personalizedEnable) {
      return;
    }
    const mainPlaceholderList = sitecoreContext?.route?.placeholders['jss-main'];
    const personalTrack = JSON.parse(sitecoreContext?.personalTrack || '{}');
    const routerString = mainPlaceholderList.map((item) => item.params?.pagename).join('>');
    const _url_ = encodeURIComponent(location);
    const __refer__ = encodeURIComponent(document.referrer);
    const __d__ = new Date().getTime();
    personalTrack.f = routerString;
    const mapData = `u=${_url_}&r=${__refer__}&rd=${__d__}&e=${this.act}&act=scroll&id=v-rule-order&val=${JSON.stringify(personalTrack)}`;
    new Image().src = `//stats.dmp.ghac.cn/cvt.gif?${mapData}`;
    console.log('千人千面', mapData);
  },
  hoverAction: function (itemId, _context = '') {
    if (!this.trackEnable) {
      return;
    }
    var isRunning = this.runningEvent[itemId];
    if (isRunning > 0) {
      this.runningEvent[itemId] -= 1;
    }
    var canProcess = this.context === _context || isRunning;
    if (canProcess) {
      let w = window,
        d = document,
        e = encodeURIComponent;
      let b = location.href,
        c = d.referrer;
      let u =
        '//stats.dmp.ghac.cn/cvt.gif?u=' +
        e(b) +
        '&r=' +
        e(c) +
        '&rd=' +
        new Date().getTime() +
        '&e=' +
        this.act +
        '&act=' +
        e('hover') +
        '&id=' +
        e(itemId) +
        '&coretype=' +
        (this.isMobile ? 'm' : 'pc');
      new Image().src = u;
      console.log('hoverAction', DmpCar, itemId, _context);
    }
  },
  clickAction: function (itemId, url, val, _context = '') {
    if (!this.trackEnable) {
      return;
    }
    var isRunning = this.runningEvent[itemId];
    if (isRunning > 0) {
      this.runningEvent[itemId] -= 1;
    }
    var canProcess = this.context === _context || isRunning;
    if (canProcess) {
      let w = window,
        d = document,
        e = encodeURIComponent;
      let b = location.href,
        c = d.referrer,
        f;
      let u =
        '//stats.dmp.ghac.cn/cvt.gif?u=' +
        e(b) +
        '&r=' +
        e(c) +
        '&rd=' +
        new Date().getTime() +
        '&e=' +
        this.act +
        '&act=' +
        e('click') +
        '&id=' +
        e(itemId) +
        '&coretype=' +
        (this.isMobile ? 'm' : 'pc');
      if (url) u += '&to=' + e(url);
      if (val) u += '&val=' + e(val?.val || val);
      new Image().src = u;
      console.log('clickAction', DmpCar, itemId, url, val?.val || val || '');
    }
  },
  sectionAction: function (itemId, value, _context = '') {
    if (!this.trackEnable) {
      return;
    }
    var isRunning = this.runningEvent[itemId];
    if (isRunning > 0) {
      this.runningEvent[itemId] -= 1;
    }
    var canProcess = this.context === _context || isRunning;
    if (canProcess) {
      let w = window,
        d = document,
        e = encodeURIComponent;
      let b = location.href,
        c = d.referrer,
        f;
      let u =
        '//stats.dmp.ghac.cn/cvt.gif?u=' +
        e(b) +
        '&r=' +
        e(c) +
        '&rd=' +
        new Date().getTime() +
        '&e=' +
        this.act +
        '&act=scroll&id=' +
        e(itemId) +
        '&coretype=' +
        (this.isMobile ? 'm' : 'pc');
      if (value) {
        u = u + '&val=' + value;
      }
      new Image().src = u;
      console.log('sectionAction', itemId);
    }
  },
  decideAction: function (itemId, action) {
    if (!this.trackEnable) {
      return;
    }
    let w = window,
      d = document,
      e = encodeURIComponent;
    let b = location.href,
      c = d.referrer,
      f;
    let u =
      '//stats.dmp.ghac.cn/cvt.gif?u=' +
      e(b) +
      '&r=' +
      e(c) +
      '&rd=' +
      new Date().getTime() +
      '&e=' +
      this.act +
      '&act=' +
      e(action) +
      '&id=' +
      e(itemId) +
      '&coretype=' +
      (this.isMobile ? 'm' : 'pc');
    new Image().src = u;
    console.log('decideAction', DmpCar, itemId, action);
  },
  msgAction: function (itemId, value) {
    if (!this.trackEnable) {
      return;
    }
    let w = window,
      d = document,
      e = encodeURIComponent;
    let b = location.href,
      c = d.referrer,
      f;
    let u =
      '//stats.dmp.ghac.cn/cvt.gif?u=' +
      e(b) +
      '&r=' +
      e(c) +
      '&rd=' +
      new Date().getTime() +
      '&e=' +
      this.act +
      '&act=input&id=' +
      e(itemId) +
      '&val=' +
      e(value) +
      '&coretype=' +
      (this.isMobile ? 'm' : 'pc');
    new Image().src = u;
    console.log('msgAction', DmpCar, itemId, value);
  },
  submitAction: function (type, req) {
    if (!this.trackEnable) {
      return;
    }
    let e = encodeURIComponent;
    let data = [
      'u=' + e(location.href),
      'r=' + e(document.referrer),
      'rd=' + new Date().getTime(),
      'e=' + this[type],
      'coretype=' + (this.isMobile ? 'm' : 'pc')
    ];
    for (let p in req) {
      if (p === 'extd') {
        data.push(p + '=' + e(JSON.stringify(req[p])));
      } else {
        data.push(p + '=' + e(req[p]));
      }
    }
    data = data.join('&');
    $.ajax({
      type: 'GET',
      async: false,
      url: `${document.location.protocol}//stats.dmp.ghac.cn/clue`,
      data,
      success: function (res) {
        new Image().src = res;
        console.log('submitAction', type, req);
      },
      error: function (e) {
        console.log('submitAction', e);
      }
    });

    // new Image().src = `//stats.dmp.ghac.cn/cvt.gif?${data}`;
    // console.log('submitAction', type, req);
  }
};

window.themeEn = ''; // sport vogue tech
$(function () {
  $('body').on(this.isMobile ? 'click touchstart' : 'click mouseenter', '[data-html-item]', function (e) {
    if ($(this).attr('blean') === 'false') {
      $(this).attr('blean', true);
      return;
    }
    var item = $(this).attr('data-html-item');
    var action = [''];
    if (typeof $(this).attr('data-action') !== 'undefined') {
      var action = $(this).attr('data-action').split('-');
    }

    var type = action[0],
      action = action.length > 1 ? '-' + action[1].split('theme').join(window.themeEn) : '';

    if (e.type === 'mouseenter') {
      if (type.indexOf('hover') !== -1) {
        action = 'hover' + action;
        window.Dmp.hoverAction(item);
      }
    } else if (type.indexOf('decide') !== -1) {
      window.Dmp.decideAction(item);
    } else if (e.type === 'click') {
      action = 'click' + action;
      var url = $(this).attr('data-url');
      var name = $(this).attr('data-name');
      if (url) {
        if (url.indexOf('javascript:') !== -1) {
          window.Dmp.clickAction(item);
        } else {
          window.Dmp.clickAction(item, url);
        }
      } else if (name) {
        window.Dmp.clickAction(item, name);
      } else {
        window.Dmp.clickAction(item);
      }
    }
  });
});
