import React, { useState, useRef, useEffect } from 'react';
import './style.scss';
import { createFilePath, getQueryString, isEmpty, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper } from 'swiper';
import { Navigation } from 'swiper';
import Icon from '../../elements/icon';

// 点击元素外边的事件
function useClickOutside(callback, refObject) {
  const handleClickOutside = (e) => {
    if (!refObject?.current?.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
}

// 检查链接
function isHttps(val) {
  if (val.toLocaleLowerCase().indexOf('https') === -1) {
    return false;
  } else {
    return true;
  }
}

export default function index(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const sanbao_icon = fields.sanbao_icon?.value;
  const hege_icon = fields.hege_icon?.value;
  const sanbao_list = fields.sanbao_list;
  const list = fields.list;
  const hege_href = fields.hege_href?.value;
  const [data, _] = useState({ isMobile, list, sanbao_list, sanbao_icon, hege_icon, hege_href });
  const [sanbaoShow, setSanbaoShow] = useState(false);
  const containerRef = useRef(null);
  const sanbaoRef = useRef(null);
  const swiperRef = useRef(null);
  const carSort = !isEmpty(getQueryString('car_sort')) && getQueryString('car_sort')?.split(',');
  const [carList, setCarList] = useState(data.list);

  useClickOutside(() => {
    setSanbaoShow(false);
  }, sanbaoRef);

  useEffect(() => {
    if (!data) {
      return;
    }

    const swiper = new Swiper(swiperRef.current, {
      grabCursor: true,
      modules: [Navigation],
      slidesPerView: 2,
      grid: {
        fill: 'column',
        rows: 1
      },
      navigation: {
        prevEl: containerRef.current.querySelector('.navigation-prev'),
        nextEl: containerRef.current.querySelector('.navigation-next'),
        disabledClass: 'navigation-disabled'
      }
    });

    return () => {
      swiper.destroy(true, true);
    };
  }, [data]);

  const getData = () => {
    if (carSort.length > 0) {
      let car_list = [];
      let count = 0;
      carSort.forEach((item) => {
        const findIndex = data.list.findIndex((val) => val.fields?.key?.value.toLocaleLowerCase() === item.toLocaleLowerCase());
        if (findIndex === -1) {
          count++;
        } else {
          car_list.push(data.list[findIndex]);
          data.list.splice(findIndex, 1);
        }
      });
      car_list = [...car_list, ...data.list.slice(0, count)];
      setCarList([...car_list]);
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    getData();
  }, [data]);

  return (
    <section>
      <div className="repairGuide_v1" ref={containerRef}>
        <div className="repairGuide_main">
          <div className="staticHead">
            <h3>三包政策及维修保养手册信息查询</h3>
          </div>
          <div className="sanbao_content">
            <div className="sanbao_certificate" ref={sanbaoRef}>
              <p>三包凭证式样</p>
              <img alt="广汽本田" src={`${createFilePath(data.sanbao_icon)}`} />
              <a className="default" onClick={() => setSanbaoShow(true)}>
                点击查看
              </a>
              {sanbaoShow && (
                <div className="sbextend">
                  {data.sanbao_list &&
                    data.sanbao_list.length > 0 &&
                    data.sanbao_list.map((sanbaoItem, sanbaoIndex) => {
                      return (
                        <a
                          className="sbextend_name"
                          href={
                            isHttps(sanbaoItem.fields?.guide_href?.value)
                              ? sanbaoItem.fields?.guide_href?.value
                              : `${createFilePath(sanbaoItem.fields?.guide_href?.value)}`
                          }
                          target="_blank"
                          key={sanbaoIndex}
                          rel="noreferrer"
                        >
                          {sanbaoItem.fields?.name?.value}
                        </a>
                      );
                    })}
                </div>
              )}
            </div>
            {!data.isMobile && (
              <ul className="divBrand">
                {carList &&
                  carList.length > 0 &&
                  carList.map((item, index) => {
                    return (
                      <li key={index} className="car_info">
                        <div className="tot">
                          <h3 className="brand_name">{item.fields?.car_name?.value}</h3>
                          <img alt={item.fields?.car_name?.value} src={`${createFilePath(item.fields?.car_img?.value)}`} className="car_img" />
                        </div>
                        <div className="select_box">
                          <div className="sel_guid">
                            <span className="chooseCarModel">
                              <span
                                className={item.fields?.type_list.length > 0 ? 'scar_name' : 'car_name'}
                                onClick={() => {
                                  item.fields?.type_list.length === 0 &&
                                    window.open(
                                      isHttps(item.fields?.btn_href?.value)
                                        ? item.fields?.btn_href?.value
                                        : `${createFilePath(item.fields?.btn_href?.value)}`,
                                      '_blank'
                                    );
                                }}
                              >
                                维修保养手册
                              </span>
                            </span>
                          </div>
                          {item.fields?.type_list.length > 0 && (
                            <ul className="car_model_hide">
                              {item.fields?.type_list.map((typeItem, typeIndex) => {
                                return (
                                  <li className="choosed" key={typeIndex}>
                                    <span
                                      className="choosed_name"
                                      onClick={() =>
                                        window.open(
                                          isHttps(typeItem.fields?.guide_href?.value)
                                            ? typeItem.fields?.guide_href?.value
                                            : `${createFilePath(typeItem.fields?.guide_href?.value)}`,
                                          '_blank'
                                        )
                                      }
                                    >
                                      {typeItem.fields?.name?.value}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      </li>
                    );
                  })}
              </ul>
            )}
            {!data.isMobile && <p className="sanbao_tips">式样仅为示意，实际以左侧各车型维修保养手册里的内容为准</p>}
            <div className="sanbao_certificate">
              <p>合格证式样</p>
              <img alt="广汽本田" src={`${createFilePath(data.hege_icon)}`} />
              <a
                href={isHttps(data.hege_href) ? data.hege_href : `${createFilePath(data.hege_href)}`}
                target="_blank"
                rel="noreferrer"
                className="default"
              >
                点击查看
              </a>
            </div>
            {data.isMobile && <p className="sanbao_tips">式样仅为示意，实际以下方各车型维修保养手册里的内容为准</p>}
            {data.isMobile && (
              <div className="sanbao_swiper">
                <div className="swiper-container">
                  <div className="swiper-support" ref={swiperRef}>
                    <div className="swiper-wrapper">
                      <React.Fragment>
                        {carList.length > 0 &&
                          carList.map((item, index) => {
                            return (
                              <div className="swiper-slide" key={index}>
                                <ul className="divBrand">
                                  <li className="car_info">
                                    <div className="tot">
                                      <h3 className="brand_name">{item.fields?.car_name?.value}</h3>
                                      <img
                                        alt={item.fields?.car_name?.value}
                                        src={`${createFilePath(item.fields?.car_img?.value)}`}
                                        className="car_img"
                                      />
                                    </div>
                                    <div className="select_box">
                                      <div className="sel_guid">
                                        <span className="chooseCarModel">
                                          <span
                                            className={item.fields?.type_list.length > 0 ? 'scar_name' : 'car_name'}
                                            onClick={() => {
                                              item.fields?.type_list.length === 0 &&
                                                window.open(
                                                  isHttps(item.fields?.btn_href?.value)
                                                    ? item.fields?.btn_href?.value
                                                    : `${createFilePath(item.fields?.btn_href?.value)}`,
                                                  '_blank'
                                                );
                                            }}
                                          >
                                            维修保养手册
                                          </span>
                                        </span>
                                      </div>
                                      {item.fields?.type_list.length > 0 && (
                                        <ul className="car_model_hide">
                                          {item.fields?.type_list.map((typeItem, typeIndex) => {
                                            return (
                                              <li className="choosed" key={typeIndex}>
                                                <span
                                                  className="choosed_name"
                                                  onClick={() =>
                                                    window.open(
                                                      isHttps(typeItem.fields?.guide_href?.value)
                                                        ? typeItem.fields?.guide_href?.value
                                                        : `${createFilePath(typeItem.fields?.guide_href?.value)}`,
                                                      '_blank'
                                                    )
                                                  }
                                                >
                                                  {typeItem.fields?.name?.value}
                                                </span>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            );
                          })}
                      </React.Fragment>
                    </div>
                  </div>
                  <div className="bottomIcon">
                    <div className="navigation-prev">
                      <Icon name="ArrowLeft" />
                    </div>
                    <div className="navigation-next">
                      <Icon name="ArrowRight" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
