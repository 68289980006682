import React, { useState, useEffect, useRef, createRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import { getPrefixField, convertStylesStringToObject, createFilePath, isMobileFun } from '../../utils/obj-utils';
import { gridSpanNumber } from '../../utils/site-utils';

export default function index({ fields, scrollElement }) {
  const containerRef = useRef(null);
  const bottomContainerRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const isMobile = isMobileFun();
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const description = fields[getPrefixField('description', isMobile)]?.value;
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const imageList = fields[getPrefixField('image_list', isMobile)]?.map((item) => ({ image: createFilePath(item?.fields?.image?.value) }));
  const imageRefList = imageList.map(() => createRef());
  const [data, _] = useState({ title, description, style, imageList, imageRefList, isMobile });

  useEffect(() => {
    const gridWidth = gridSpanNumber(2);
    const duration1 = 0.4;
    const duration2 = 0.6;
    const widthOffset = 30;
    const xOffset = 30;
    let width;
    bottomContainerRef.current.style.transform = `translateX(${100 + gridWidth}vw)`;

    const textTimeline = gsap.timeline({ paused: true }).to(titleRef.current, { opacity: 1 }).to(descriptionRef.current, { opacity: 1 });
    const controller = new ScrollMagic.Controller({ container: scrollElement || window });
    const textScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onCenter',
      duration: '50%'
    })
      .on('progress', function (event) {
        textTimeline.progress(event.progress);
      })
      .setTween(textTimeline)
      .addTo(controller);

    const bottomScene = new ScrollMagic.Scene({
      triggerElement: bottomContainerRef.current,
      triggerHook: 'onCenter',
      duration: data?.isMobile ? '100%' : '150%'
    })
      .on('progress', function (event) {
        const progress = event.progress;
        if (progress <= duration2) {
          const currentProgress = Math.min(progress * (1 / duration2), 1);
          width = 100 - widthOffset * currentProgress;
          data?.imageRefList.forEach((item) => {
            item.current.style.width = `${width}vw`;
          });
        }
        if (progress <= duration1) {
          bottomContainerRef.current.style.transform = `translateX(${width + gridWidth}vw)`;
        } else if (progress > duration1) {
          const currentProgress = Math.min((progress - duration1) * (1 / (1 - duration1)), 1);
          bottomContainerRef.current.style.transform = `translateX(${width + gridWidth - xOffset * currentProgress}vw)`;
        }
      })
      .addTo(controller);

    return () => {
      textTimeline.kill();
      textScene.remove();
      textScene.destroy();

      bottomScene.remove();
      bottomScene.destroy();

      controller.destroy();
    };
  }, []);

  return (
    <section>
      {data ? (
        <div className="image-horizontal-scoll" style={data?.style} ref={containerRef}>
          <div className="title" dangerouslySetInnerHTML={{ __html: data?.title?.replaceHtml() }} ref={titleRef} />
          <div className="description" dangerouslySetInnerHTML={{ __html: data?.description?.replaceHtml() }} ref={descriptionRef} />
          <div className="bottom-container" ref={bottomContainerRef}>
            {data?.imageList?.map((item, index) => (
              <div className="image-container" key={index} ref={data?.imageRefList[index]}>
                <img alt="广汽本田" src={createFilePath(item.image)} className="image" />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </section>
  );
}
