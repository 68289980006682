import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import LargeImageContent_v1 from '../../elements/LargeImageContent_v1';
import Details from '../../elements/InnerPageDetail';
import { getPrefixField, sendDmp, isInView, isMobileFun } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const { fields } = props;
  const [num, setNum] = useState(0);
  const [flag, setFlag] = useState(false);
  const [windowHeight, setWindowHeight] = useState(400);
  const rootEl = useRef(null);
  const [isWX, setIsWX] = useState(false);
  const [isIphone, setIsIhone] = useState(false);
  const [paused, setPaused] = useState(true);
  const thisMobile = isMobileFun();
  const isMobile = isMobileFun();
  const defaultTab = fields.default_tab_value?.value;
  const tabs = fields[getPrefixField('car_type_list', isMobile)];
  const [showcode, setShowcode] = useState(false);
  useEffect(() => {
    setIsWX(window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) === 'micromessenger');
    setIsIhone(navigator.userAgent.indexOf('Android') === -1 && navigator.userAgent.indexOf('Linux') === -1);
    setWindowHeight(document.documentElement.clientHeight);
  }, [windowHeight]);
  useEffect(() => {
    const scroll = () => {
      if (rootEl.current) {
        setPaused(!isInView(rootEl));
      }
    };
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('resize', resizeEvent);
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(props.fields.window_dmp);
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : '';
    setShowcode(true);
  };
  useEffect(() => {
    const scroll = () => {
      if (window.navigationPagenameb === props?.params?.pagename) {
        setShowcode(true);
      } else {
        setShowcode(false);
      }
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      tabs.map((item, index) => {
        let tabArr = item.fields?.tab_value?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(defaultTab.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setNum(tabInd < 0 ? (defaultTabInd < 0 ? num : defaultTabInd) : tabInd);
      } else {
        setNum(defaultTabInd < 0 ? num : defaultTabInd);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <LargeImageContent_v1
        {...props}
        isMobile={isMobile}
        isWX={isWX}
        isIphone={isIphone}
        num={num}
        setNum={setNum}
        flag={flag}
        setFlag={setFlag}
        windowHeight={windowHeight}
        thisMobile={thisMobile}
        rootEl={rootEl}
        paused={paused}
        showcode={showcode}
      />
      {flag && (
        <Details id="activity" routePath={fields[`${getPrefixField('car_type_list')}`][num].fields.detail_href.value} close={() => setFlag(false)} />
      )}
    </div>
  );
}
