import React from 'react';
import './style.scss';
import ProgressBar from '../../elements/ProgressBar';

const Realibox = (props) => {
  return (
    <div className={`realibox-container ${props.className || ''}`} style={props.style}>
      {props.loading && (
        <div className="loading">
          <ProgressBar progress={props?.progress} />
        </div>
      )}
      <canvas className="canvas" ref={props.canvasRef} />
      {props?.children || ''}
    </div>
  );
};
export default Realibox;
