import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { array, bool, func, object, oneOfType, string } from 'prop-types';
import { MODAL_ANI_TYPES, modalAnimations } from '../../utils/modal-animations';
import { DomPortal } from '../';
import './style.scss';
const Modal = ({ open = false, onMaskClick, animation = MODAL_ANI_TYPES.bottomFadeIn, className, children }) => {
  const modalProps = useMemo(() => modalAnimations[animation], [animation]);
  const modalMaskAnimations = {
    start: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.3 } },
    exit: { opacity: 0, transition: { duration: 0.3 } }
  };
  const rootClasses = classNames('e-modal', className);
  useEffect(() => {
    if (open) {
      document.documentElement.classList.add('modal-open');
    } else {
      document.documentElement.classList.remove('modal-open');
    }
  }, [open]);
  return (
    <DomPortal>
      {animation !== MODAL_ANI_TYPES.none && (
        <AnimatePresence>
          {open && (
            <motion.div className={rootClasses} variants={modalMaskAnimations} initial="start" animate="visible" exit="exit">
              <div className="e-modal__mask" onClick={onMaskClick} />
              <motion.div {...modalProps} className="e-modal__content" initial="start" animate="visible" exit="exit">
                {children}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      )}
      {animation === MODAL_ANI_TYPES.none && open && (
        <div className={rootClasses}>
          <div className="e-modal__mask" onClick={onMaskClick} />
          <div {...modalProps} className="e-modal__content">
            {children}
          </div>
        </div>
      )}
    </DomPortal>
  );
};
export default Modal;
Modal.propTypes = {
  open: bool,
  onMaskClick: func,
  className: string,
  children: oneOfType([object, string, array])
};
