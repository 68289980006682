import temp from '../temp/config';
import { dataFetcher } from '../dataFetcher';

export const openCloudCar = (resourceId, callback) => {
  const query = `scene=${encodeURIComponent(`resource_id=${resourceId}&referer=web`)}`;
  const path = '/components/pages/scanVehicleCode/index';
  dataFetcher(`${temp?.h5?.url}?path=${encodeURIComponent(path)}&query=${encodeURIComponent(query)}&type=0`, null, false).then((response) => {
    const data = response?.data;
    if (data?.code === 10000) {
      const {
        info: { openlink }
      } = data;
      callback && callback(openlink);
      try {
        window.top.location.href = openlink;
      } catch (e) {
        location.href = openlink;
      }
    }
  });
};

export const openHome = (callback) => {
  const query = `r=${location.href}`;
  const path = '/components/pages/index/index';
  dataFetcher(`${temp?.h5?.url}?path=${encodeURIComponent(path)}&query=${encodeURIComponent(query)}&type=0`, null, false).then((response) => {
    const data = response?.data;
    if (data?.code === 10000) {
      const {
        info: { openlink }
      } = data;
      callback && callback(openlink);
      location.href = openlink;
    }
  });
};
export const GetGmallAppSchemeUrl = (path, callback, adcode, clkid) => {
  var mall = 'https://mall.ghac.cn';
  var mall1 = 'https://mall.ghac.cn/';
  var mall2 = 'https://mall.ghac.cn/?channel=GW';
  var mall3 = 'https://mall.ghac.cn/o2o/activity-detail/';
  var oldpath = path;
  var newpath = '';
  var activityId = '';
  if (oldpath.indexOf('?') !== -1) {
    oldpath = path.split('?')[0];
  }
  if (path === mall || path === mall1 || path === mall2) {
    newpath = 'pages/index';
  } else {
    if (path.indexOf(mall3) !== -1) {
      newpath = 'pageO2o/o2o/activity-detail/_activityId';
    } else {
      newpath = 'pageO2o/onlinesaling/activity-detail/_activityId';
    }
    if (oldpath.indexOf(mall3 !== -1)) {
      activityId = oldpath.split(mall3)[1];
    }
  }
  if (!activityId) {
    newpath = 'pages/index';
  }
  dataFetcher(
    '/api/honda/v1/DWP/GetGmallAppSchemeUrl?path=' +
      newpath +
      '&activityId=' +
      activityId +
      (adcode ? '&adcode=' + adcode : '') +
      (clkid ? '&clkid=' + clkid : '') +
      '&channel=GW',
    null
  )
    .then(({ data }) => {
      if (data.Status) {
        try {
          window.top.location.href = data.Data.data;
          callback && callback(data.Data.data);
        } catch (e) {
          location.href = data.Data.data;
          callback && callback(data.Data.data);
        }
      } else {
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
