import React, { useState } from 'react';
import './style.scss';
import { getPrefixField, createFilePath, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const img_kv = fields[getPrefixField('img_kv', isMobile)]?.value;
  const img1 = fields[getPrefixField('img1', isMobile)]?.value;
  const img2 = fields[getPrefixField('img2', isMobile)]?.value;
  const img3 = fields[getPrefixField('img3', isMobile)]?.value;
  const [data, _] = useState({ isMobile, img_kv, img1, img2, img3 });

  return (
    <section>
      <div className="insurance_v1">
        <div className="insurance_main">
          <div className="staticHead">
            <h3>喜悦延保</h3>
          </div>
          <div className="service_insurance">
            <div className="banner">
              <img alt="广汽本田" src={`${createFilePath(data.img_kv)}`} />
            </div>
            <div className="insurance_con">
              <div className="grid grid1">
                <div className="t">
                  <div className="wid1200n">喜悦延保简介</div>
                </div>
                <div className="wid1200n">
                  <div className="con">
                    <div className="p1">
                      “喜悦延保”是广汽本田为车主精心设计的汽车延长保修服务，
                      <b>最长可获得5年/16万公里的保障；</b>
                    </div>
                    <div className="p1">“喜悦延保”适用于广本全系车型。</div>
                  </div>
                </div>
              </div>
              <div className="grid grid2">
                <div className="t">
                  <div className="wid1200n">喜悦延保保障范围</div>
                </div>
                <div className="wid1200n">
                  <div className="con">
                    <div className="t2">
                      <span>保修延长期限（可选）</span>
                    </div>
                    <div className="pic">
                      <img alt="广汽本田" src={`${createFilePath(data.img1)}`} />
                    </div>
                    <div className="t2">
                      <span>延长保修范围（可选）</span>
                    </div>
                    <div className="pic">
                      <img alt="广汽本田" src={`${createFilePath(data.img2)}`} />
                    </div>
                    <div className="tips">※具体的服务范围请以《汽车延长保修服务合同》以及《广汽本田汽车延保服务手册》为准</div>
                    <div className="t2">
                      <span>套餐组合</span>
                    </div>
                    <div className="pic">
                      <img alt="广汽本田" src={`${createFilePath(data.img3)}`} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid3">
                <div className="t">
                  <div className="wid1200n">购买喜悦延保的方式</div>
                </div>
                <div className="wid1200n">
                  <div className="con">
                    <div className="p1">关于喜悦延保的更详细内容或购买喜悦延保，敬请咨询您的广汽本田特约销售服务店或致电</div>
                    <div className="tel">
                      <div className="n">广汽本田客服热线</div>
                      <div className="p">
                        <span>800-830-8999</span>（固话）
                      </div>
                      <div className="p">
                        <span>400-830-8999</span>（手机）
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
