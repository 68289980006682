import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { scrollFinish, getPathnameByPosition, sendDmp, convertStylesStringToObject, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const { fields } = props;
  const { list } = fields;
  const [navbool, setnavbool] = useState(false);
  const [isMobile, setIsMobile] = useState(isMobileFun());
  const [pagename, setPagename] = useState(null);
  const rootEl = useRef(null);
  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      try {
        window.top.Dmp.isAnchor = true;
      } catch (e) {
        Dmp.isAnchor = true;
      }
      const anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        if (anchorElement === document.querySelector('.NavigationMachine')) {
          anchorElement.scrollIntoView({ block: 'end', behavior: 'smooth' });
        } else if (anchorName === 'price_power' && getPathnameByPosition()?.toLowerCase() === 'integra') {
          if (anchorElement.getBoundingClientRect().bottom <= 0) {
            anchorElement.scrollIntoView({ block: 'end', behavior: 'smooth' });
          } else {
            anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }
        } else {
          if (anchorElement.offsetHeight > document.documentElement.clientHeight - 60) {
            anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
          } else {
            anchorElement.scrollIntoView({ block: 'end', behavior: 'smooth' });
          }
        }
      }
      if (anchorName === 'price_power' && getPathnameByPosition()?.toLowerCase() === 'integra') {
        if (anchorElement.getBoundingClientRect().bottom <= 0) {
          scrollFinish(() => {
            window.scrollTo(0, window.scrollY - 10);
          });
        } else {
          scrollFinish(() => {
            window.scrollTo(0, window.scrollY + 1);
          });
        }
      }
    }
  };

  useEffect(() => {
    Object.defineProperty(window, 'navigationPagename', {
      set(pagename) {
        setPagename(pagename);
      },
      configurable: true
    });
    if (window.navigationPagename === undefined) {
      window.navigationPagename = window.navigationPagenameb = 'home';
    }
    const navscroll = () => {
      var height = document.documentElement.clientHeight;
      var scrolltop = document.documentElement.scrollTop;
      if (scrolltop >= height) {
        setnavbool(true);
      } else {
        setnavbool(false);
      }
    };
    window.addEventListener('scroll', navscroll);
    return () => {
      window.removeEventListener('scroll', navscroll);
    };
  }, []);

  return (
    <div
      className="NavigationSide_v1"
      style={{ ...convertStylesStringToObject(fields?.navigationStyle?.value), display: isMobile || !navbool ? 'none' : '' }}
      ref={rootEl}
      onMouseEnter={() => {
        sendDmp(fields?.hoverDmp);
      }}
    >
      {!isMobile && (
        <>
          <div className="bg"> </div>
          <ul>
            {list?.map((item, index) => (
              <li
                key={index}
                className={`${item.fields?.anchorId?.value === pagename ? 'action' : ''}`}
                onClick={() => {
                  scrollToAnchor(item.fields?.anchorId?.value);
                  setPagename(item.fields?.anchorId?.value);
                  sendDmp(item.fields?.itemDmp, item.fields?.sendTo?.value ? item.fields?.anchorId?.value : '');
                }}
                onMouseOver={() => {
                  sendDmp(item.fields?.itemHoverDmp);
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: item.fields?.name?.value?.replaceHtml() }}></span>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
