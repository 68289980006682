import { createPortal } from 'react-dom';
import { useEffect, useState } from 'react';

const DomPortal = ({ children }) => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  if (isClient) {
    return createPortal(children, document.body);
  }
  return children;
};
export default DomPortal;
