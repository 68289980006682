import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import './style.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { EffectFade, Navigation, Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import '../PriceSpace_v3/style_breezePet.scss';
import Details from '../../elements/InnerPageDetail';
import SwiperDetails from '../PriceSpace_v3/SwiperDetails/index';
import Breath from '../PriceSpace_v3/breath';
import temp from '../../temp/config';
import { getPrefixField, sendDmp, convertStylesStringToObject, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const { fields } = props;
  const [windowHeight, setWindowHeight] = useState(400);
  const [flag, setFlag] = useState(false);
  const isMobile = isMobileFun();
  const [num, setNum] = useState(0);
  const [breathIndex, setBreathIndex] = useState(-1);
  const [num2, setNum2] = useState(1);
  const rootEl = useRef(null);

  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);

  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields.window_dmp);
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : '';
  };

  return (
    <div>
      <Controller>
        <Scene triggerHook={'onEnter'} duration={windowHeight}>
          {(progress, event) => {
            return (
              <div>
                <Timeline totalProgress={progress} paused>
                  <div className="NavigationMachine_v1" id={!fields?.noHeaderId?.value ? props?.params?.pagename : ''} ref={rootEl}>
                    <div className="black" style={{ display: fields[`${getPrefixField('hide_black', isMobile)}`]?.value ? 'none' : '' }}>
                      <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={1} stagger={0.2}>
                        <div
                          dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('first_page_content', isMobile)}`]?.value?.replaceHtml() }}
                        />
                      </Tween>
                    </div>
                    <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={2} stagger={0.2}>
                      <Swiper
                        id={fields?.noHeaderId?.value ? props?.params?.pagename : ''}
                        className="contentSwiper"
                        initialSlide={0}
                        modules={[Pagination, EffectFade]}
                        effect={fields[`${getPrefixField('swiper_effect', isMobile)}`]?.value || 'slide'}
                        pagination={
                          !fields?.ishidetab?.value
                            ? {
                                clickable: true,
                                bulletClass: 'tabContainer',
                                bulletActiveClass: 'tabContainer_active',
                                renderBullet: (index, className) => {
                                  return isMobile
                                    ? '<span />'
                                    : `<span key=${index} class=${className}>${
                                        fields[`${getPrefixField('content_list', isMobile)}`][index].fields.tab.value
                                      }</span>`;
                                }
                              }
                            : false
                        }
                        onSlideChange={(swiper) => {
                          setNum(swiper.activeIndex);
                          sendDmp(fields[`${getPrefixField('content_list', isMobile)}`][swiper.activeIndex].fields.tab_dmp);
                          isMobile && document.querySelector('.tabSwiper').swiper.slideTo(swiper.activeIndex);
                        }}
                      >
                        {fields[`${getPrefixField('content_list', isMobile)}`].length &&
                          fields[`${getPrefixField('content_list', isMobile)}`].map((ele, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <div className="sildeContainer">
                                  {ele.fields.back_type?.fields.v.value === 'video' ? (
                                    <video
                                      src={createFilePath(ele.fields.back_source?.value)}
                                      autoPlay="autoplay"
                                      muted={true}
                                      loop={true}
                                      style={convertStylesStringToObject(ele.fields.back_source_style?.value)}
                                    />
                                  ) : (
                                    <img
                                      alt="广汽本田"
                                      src={createFilePath(ele.fields.back_source?.value)}
                                      style={convertStylesStringToObject(ele.fields.back_source_style?.value)}
                                    />
                                  )}
                                  <div
                                    className="NavigationMachineText"
                                    style={convertStylesStringToObject(ele.fields.NavigationMachineText_style?.value)}
                                  >
                                    <div>
                                      {ele.fields.title_img?.value && (
                                        <div className="titleImg">
                                          <img
                                            alt="广汽本田"
                                            src={createFilePath(ele.fields.title_img?.value)}
                                            style={convertStylesStringToObject(ele.fields.title_img_style?.value)}
                                          />
                                        </div>
                                      )}
                                      {ele.fields.title_text?.value && (
                                        <div dangerouslySetInnerHTML={{ __html: ele.fields.title_text.value?.replaceHtml() }} />
                                      )}
                                      {ele.fields.content?.value && (
                                        <div dangerouslySetInnerHTML={{ __html: ele.fields.content.value?.replaceHtml() }} />
                                      )}
                                      {ele.fields.detail_btn?.value && (
                                        <div
                                          style={{ display: 'inline-block' }}
                                          onClick={() => {
                                            sendDmp(ele.fields.btn_dmp);
                                            setFlag(true);
                                          }}
                                          dangerouslySetInnerHTML={{ __html: ele.fields.detail_btn.value?.replaceHtml() }}
                                        />
                                      )}
                                      {ele.fields.immd_btn?.value && (
                                        <div
                                          style={{ display: 'inline-block' }}
                                          dangerouslySetInnerHTML={{ __html: ele.fields.immd_btn.value?.replaceHtml() }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {ele.fields.specific &&
                                    ele.fields.specific.length > 0 &&
                                    ele.fields.specific.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          onClick={() => {
                                            localStorage.setItem('kk', index);
                                            setBreathIndex(index);
                                            window.Dmp.clickAction(item.fields.breathDmp[0]?.displayName, '', { val: item.fields.name.value });
                                          }}
                                        >
                                          <Breath className={`breathing  breathingFlag${index + 1}${ele.fields.styleBox.value}`}></Breath>
                                        </div>
                                      );
                                    })}
                                  {breathIndex !== -1 && ele.fields.specific && ele.fields.specific.length > 0 && (
                                    <SwiperDetails
                                      fields={{
                                        num: breathIndex,
                                        setNum: setBreathIndex,
                                        specific: ele.fields.specific,
                                        close: ele.fields.close.value,
                                        closeDmp: ele.fields.specific[breathIndex].fields.closeDmp[0]?.displayName,
                                        val: ele.fields.specific[breathIndex].fields.name.value,
                                        styleBox: ele.fields.styleBox.value,
                                        num2,
                                        dmpSwiperName: ele.fields.dmpSwiperName.value
                                      }}
                                    ></SwiperDetails>
                                  )}
                                </div>
                              </SwiperSlide>
                            );
                          })}
                      </Swiper>
                    </Tween>
                    {isMobile && (
                      <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={3} stagger={0.2}>
                        <div>
                          <Swiper
                            className="tabSwiper"
                            centeredSlides={
                              fields[`${getPrefixField('content_list', isMobile)}`].length > 3 &&
                              num > 0 &&
                              num < fields[`${getPrefixField('content_list', isMobile)}`].length - 1
                            }
                            slidesPerView={
                              fields[`${getPrefixField('content_list', isMobile)}`].length >= 3
                                ? 3
                                : fields[`${getPrefixField('content_list', isMobile)}`].length
                            }
                            modules={[Navigation]}
                            navigation={false}
                          >
                            {fields[`${getPrefixField('content_list', isMobile)}`].length &&
                              fields[`${getPrefixField('content_list', isMobile)}`].map((ele, index) => {
                                return (
                                  <SwiperSlide
                                    key={index}
                                    onClick={() => {
                                      setNum(index);
                                      rootEl.current.querySelector('.contentSwiper').swiper.slideTo(index);
                                    }}
                                  >
                                    <div
                                      className={index === num ? 'tabContainer tabContainer_active' : 'tabContainer'}
                                      dangerouslySetInnerHTML={{ __html: ele.fields.tab.value?.replaceHtml() }}
                                    ></div>
                                  </SwiperSlide>
                                );
                              })}
                          </Swiper>
                        </div>
                      </Tween>
                    )}
                  </div>
                </Timeline>
              </div>
            );
          }}
        </Scene>
      </Controller>
      {flag && (
        <Details routePath={fields[`${getPrefixField('content_list', isMobile)}`][num]?.fields?.detail_href?.value} close={() => setFlag(false)} />
      )}
    </div>
  );
}
