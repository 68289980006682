import React, { useEffect, useRef, useState, createRef } from 'react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import temp from '../../temp/config';
import SwiperDetails from './SwiperDetails/index';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { useSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';

export default function index({ fields, updateRefList, updateDmpList }) {
  const containerRef = useRef(null);
  const firstPageRef = useRef(null);
  const secondPageRef = useRef(null);
  const thirdPageRef = useRef(null);
  const thirdPageEventRef = useRef(null);
  const descContainerRef = useRef(null);
  const isMobile = isMobileFun();
  const route = useSitecoreContext()?.sitecoreContext?.route;
  // const { deployUrlS3 } = temp.app;
  const secondItemContainerRefList = fields[`${getPrefixField('secondPage_list')}`].map(() => createRef());
  const [num, setNum] = useState(-1);

  useEffect(() => {
    updateRefList([sendFirstDmp, sendSecondDmp, sendThirdDmp]);
    updateDmpList([firstPageRef?.current, secondPageRef?.current, thirdPageRef?.current]);
  }, []);

  useEffect(() => {
    const showDecsTimeline = gsap.timeline({ paused: true });
    showDecsTimeline.fromTo(descContainerRef.current, { opacity: 0 }, { opacity: 1 });
    const showDecsController = new ScrollMagic.Controller();
    const showDecsScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 0.3,
      duration: document.documentElement.clientHeight * 0.3
    })
      .on('progress', function (event) {
        showDecsTimeline.progress(event.progress);
      })
      .setTween(showDecsTimeline)
      .addTo(showDecsController);

    return () => {
      showDecsTimeline.kill();
      showDecsScene.remove();
      showDecsScene.destroy();
      showDecsController.destroy();
    };
  }, []);

  const sendFirstDmp = () => {
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = 'price_exterior';
    sendDmp(fields.window_dmp);
  };

  const sendSecondDmp = () => {
    sendDmp(fields.window_2screen_dmp);
  };

  const sendThirdDmp = () => {
    thirdPageEventRef?.current?.sendEvent();
  };

  const closeModal = (dmp, value) => {
    setNum(-1);
    sendDmp(dmp, '', value);
  };
  const btnlModal = () => {
    sendDmp(fields.btnl_dmp);
  };
  const btnrModal = () => {
    sendDmp(fields.btnr_dmp);
  };

  const renderSecondPageItem = (item, num) => {
    return (
      <div className="imgContent">
        <img
          alt="广汽本田"
          className="imgContentImg"
          src={`${createFilePath(item.fields.back_img?.value)}`}
          style={convertStylesStringToObject(item.fields.back_img_style?.value)}
        />
        {item.fields.content?.value && (
          <div className="contentContainer" dangerouslySetInnerHTML={{ __html: item.fields.content?.value?.replaceHtml() }} />
        )}
        <img
          alt="广汽本田"
          className="open_the_plus_icon"
          src={createFilePath('/common/icon/openThePushIcon.png')}
          onClick={() => {
            sendDmp(item.fields.open_dmp, '', item.fields.name.value);
            setNum(num);
          }}
        />
      </div>
    );
  };

  return (
    <div
      className="priceExterior-v4"
      id="price_exterior"
      ref={containerRef}
      style={convertStylesStringToObject(fields[`${getPrefixField('container_style', isMobile)}`]?.value)}
    >
      <div className="firstPageContianer" ref={firstPageRef}>
        <div className="imgContainer">
          <img alt="广汽本田" src={`${createFilePath(fields[getPrefixField('firstPage_backImg', isMobile)].value)}`} />
        </div>
        <div
          className="descContainer"
          ref={descContainerRef}
          dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('firstPage_desc', isMobile)}`]?.value?.replaceHtml() }}
        />
      </div>
      <div className="secondPageContainer" ref={secondPageRef}>
        {fields[getPrefixField('secondPage_list', isMobile)] &&
          fields[getPrefixField('secondPage_list', isMobile)].map((item, index) => {
            return (
              <div className={'item'} key={index}>
                <div className={`item_${index}`} ref={secondItemContainerRefList[index]}>
                  {renderSecondPageItem(item, index)}
                </div>
              </div>
            );
          })}
      </div>
      {fields[getPrefixField('third_page_route', isMobile)]?.value && (
        <div ref={thirdPageRef}>
          <Placeholder
            name={`${fields[getPrefixField('third_page_route', isMobile)]?.value}`}
            eventRef={thirdPageEventRef}
            rendering={route}
            page={route}
          />
        </div>
      )}
      {num !== -1 && (
        <SwiperDetails
          num={num}
          sourceList={fields[getPrefixField('secondPage_list', isMobile)]}
          closeImg={fields[getPrefixField('close_img', isMobile)].value}
          styleBox={fields[getPrefixField('style_box', isMobile)].value}
          close={(dmp, value) => {
            closeModal(dmp, value);
          }}
          btnl={() => {
            btnlModal();
          }}
          btnr={() => {
            btnrModal();
          }}
        />
      )}
    </div>
  );
}
