import React from 'react';
import temp from '../../temp/config';
import './style.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const isMobile = isMobileFun();
  const { img, linkUrl, m_img, returnIcon, pc_flag, m_flag } = props.fields;
  return (
    <div
      className={`${!isMobile ? 'operation_header' : 'operation_header_m'}`}
      style={{ display: !isMobile && pc_flag.value ? 'block' : isMobile && m_flag.value ? 'grid' : 'none' }}
    >
      <img
        alt="广汽本田"
        src={createFilePath(!isMobile ? img.value : m_img.value)}
        // onClick={() => {
        //   window.open(linkUrl.value, '_self');
        //   window.Dmp.clickAction('manual-back', linkUrl.value, '');
        // }}
      />
      {/* {isMobile ? (
        <div
          onClick={() => {
            history.go(-1);
            window.Dmp.clickAction('manual-return', '', '');
          }}
        >
          <img alt="广汽本田" src={createFilePath(returnIcon.value)} />
          <p>返回</p>
        </div>
      ) : (
        ''
      )} */}
    </div>
  );
}
