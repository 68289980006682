import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { getPrefixField, isMobileFun, createFilePath, convertStylesStringToObject } from '../../utils/obj-utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.min.css';
import './style.scss';

export default function index({ fields }) {
  const isMobile = isMobileFun();
  const swiperRef = useRef(null);
  const [tabsIndex, setTabsIndex] = useState(0);
  const [tabsflag, setTabsflag] = useState(true);
  return (
    <div className="InnerPageSensingLists_v1">
      <div className="tabs">
        {fields?.lists.length > 0 &&
          fields?.lists.map((item, index) => {
            return (
              <div
                key={index}
                className={tabsIndex === index ? 'item on' : 'item'}
                onClick={() => {
                  window.Dmp.clickAction(item?.fields?.tabsdmp?.value);
                  setTabsIndex(index);
                  setTabsflag(false);
                  setTimeout(() => {
                    setTabsflag(true);
                  }, 10);
                }}
              >
                {item?.fields[`${getPrefixField('title', isMobile)}`]?.value && (
                  <div dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}></div>
                )}
              </div>
            );
          })}
      </div>
      <div className="con">
        {fields?.lists[tabsIndex]?.fields?.sublists.length > 0 &&
          tabsflag &&
          fields?.lists[tabsIndex]?.fields?.sublists.map((subitem, subindex) => {
            return (
              <div
                key={subindex}
                className={`subitem ${subitem?.fields?.direction?.value ? 'subitem' + subitem?.fields?.direction?.value : ''} ${
                  subitem?.fields?.reserve?.value ? 'reserve' : ''
                }`}
              >
                {subitem?.fields[getPrefixField('img', isMobile)]?.value && (
                  <img
                    alt="广汽本田"
                    className="img"
                    style={convertStylesStringToObject(subitem?.fields[`${getPrefixField('imgstyle', isMobile)}`]?.value)}
                    src={`${createFilePath(subitem?.fields[getPrefixField('img', isMobile)]?.value)}`}
                  />
                )}
                {subitem?.fields[`${getPrefixField('text', isMobile)}`]?.value && (
                  <div dangerouslySetInnerHTML={{ __html: subitem?.fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}></div>
                )}
                {subitem?.fields?.thirdlists.length > 0 && (
                  <div className="sublists">
                    <Swiper
                      ref={swiperRef}
                      slidesPerView={isMobile ? 1 : 3}
                      className="swiper"
                      spaceBetween={isMobile ? 0 : 60}
                      initialSlide={0}
                      observer={true}
                      resizeObserver={true}
                      updateOnWindowResize={true}
                      modules={[Pagination, Navigation]}
                      navigation={{
                        prevEl: '.InnerPageSensingLists_v1 .sublists .prev',
                        nextEl: '.InnerPageSensingLists_v1 .sublists .next',
                        disabledClass: 'disabled'
                      }}
                      onInit={(e) => {}}
                      onSlideChangeTransitionEnd={(e) => {}}
                    >
                      {subitem?.fields?.thirdlists.map((thirditem, thirdindex) => {
                        return (
                          <SwiperSlide className="thirditem" key={thirdindex}>
                            {thirditem?.fields[`${getPrefixField('text', isMobile)}`]?.value && (
                              <div
                                dangerouslySetInnerHTML={{ __html: thirditem?.fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}
                              ></div>
                            )}
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                    <div className="next" data-html-item={fields?.nextdmp?.value}>
                      <img className="icon" src={createFilePath(fields[`${getPrefixField('nexticon', isMobile)}`]?.value)} alt="广汽本田" />
                    </div>
                    <div className="prev" data-html-item={fields?.prevdmp?.value}>
                      <img className="icon" src={createFilePath(fields[`${getPrefixField('previcon', isMobile)}`]?.value)} alt="广汽本田" />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
