import React, { useEffect, useState } from 'react';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';

export default function index({ fields }) {
  const [data, setData] = useState({});

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    dataFetcher('/api/honda/v1/Policy/GetPolicyContent')
      .then(({ data: response }) => {
        if (response.Status) {
          data.content = response?.Data;
          setData({ ...data });
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <section>
      {data ? (
        <div className="policy">
          <div className="content" dangerouslySetInnerHTML={{ __html: data?.content?.replaceHtml() }} />
        </div>
      ) : null}
    </section>
  );
}
