import React, { useEffect, useState } from 'react';
import './style.scss';

export default function index({ fields }) {
  return (
    <div className="centerBox">
      <iframe id="centerIframe" className="centerIframe" src="" width="100%" height="100%" frameBorder="0" scrolling="no" />
    </div>
  );
}
