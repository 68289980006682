/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import temp from '../../temp/config';
import { realiboxInit } from '../../utils/realiboxCaller';
import Realibox from '../../elements/Realibox';
import { getPrefixField, getDevicePrefix, sendDmp } from '../../utils/obj-utils';
import Script from 'react-load-script';
import { sendDmpAction, scrollToAnchor, isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { fields } = props;

  const isMobie = isMobileFun();
  const timelineInfoList = fields[getPrefixField('stepData')].map((item) => {
    const returnMap = {};
    // 以下代码是根据终端获取仅需要的属性，比如M端只获取m_的属性，PC的除外
    Object.keys(item.fields)
      .filter((key) => (isMobie ? key.startsWith(getDevicePrefix()) : !key.startsWith(getDevicePrefix())))
      .map((key) => (isMobie ? key.replace(getDevicePrefix(), '') : key))
      .forEach((key) => {
        returnMap[key] = item.fields[key].value;
      });
    return returnMap;
  });
  const sceneId = fields[getPrefixField('sceneId')].value;
  const timestamp = fields?.timestamp?.value;

  const [Index, setIndex] = useState(0);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [sceneLoadedProgress, setSceneLoadedProgress] = useState(0);
  const rootEl = useRef(null);
  const canvasRef = useRef(null);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields[getPrefixField('show360-dmp')]);
    // 二级导航高亮所属变量
    window.navigationIndex = 2;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : 'show360';
  };

  var app;
  const handleScriptLoad = (res) => {
    if (!app) {
      if (canvasRef.current) {
        app = realiboxInit(
          // `${temp.app.deployUrlS3}${fields[getPrefixField('sceneRbsPath')].value}`,
          createFilePath(fields[getPrefixField('sceneRbsPath')].value),
          canvasRef.current,
          {
            sceneId: sceneId,
            startIndex: 0,
            timelineInfoList: timelineInfoList,
            loop: fields[getPrefixField('loop')].value,
            callback: interactionEndedCallback,
            onloadCallback: () => {
              setFirstLoaded(true);
            },
            progressCallback: (progress) => {
              setSceneLoadedProgress(Math.trunc(progress * 100));
            }
          },
          timestamp
        );
      }
    }
  };
  const interactionEndedCallback = (nextIndex) => {
    setIndex(nextIndex);
  };
  // 转换移动端视频格式
  useEffect(() => {
    return () => {
      if (app) {
        app.destroy();
      }
    };
  }, []);
  return (
    <Fragment>
      {/* <Script url={`${temp.app.deployUrlS3}${fields.realiboxLibPath.value}`} onLoad={handleScriptLoad} /> */}
      <Script url={createFilePath(fields.realiboxLibPath.value)} onLoad={handleScriptLoad} />
      <div className="ExhibitionHall_v1" id="show360" ref={rootEl}>
        <div className="ExhibitionHallCenter">
          <div className="ExhibitionHallCenterTop">
            <div className="SideFace">
              <div>
                <p style={{ color: `rgb(${timelineInfoList[Index].fontColor ? timelineInfoList[Index].fontColor : '255,255,255'})` }}>
                  {timelineInfoList[Index].title}
                </p>
                <div
                  className="strip"
                  style={{
                    background: `-webkit-linear-gradient(left, rgba(${timelineInfoList[Index].color},1)0%,rgba(${timelineInfoList[Index].color},0.5)66%, rgba(${timelineInfoList[Index].color},0)99%) `
                  }}
                ></div>
              </div>
              <div>
                <button
                  className="exhibitionHallBox"
                  onClick={(e) => {
                    sendDmpAction(
                      fields['show360-drive-dmp']?.length > 0 ? fields['show360-drive-dmp'][0]?.fields?.func?.value : '',
                      fields['show360-drive-dmp']?.length > 0 ? fields['show360-drive-dmp'][0].name : ''
                    );
                    scrollToAnchor('drive', 'start');
                  }}
                  style={{
                    background: `rgba(${timelineInfoList[Index].color},0)`,
                    borderColor: `rgb(${timelineInfoList[Index].fontColor ? timelineInfoList[Index].fontColor : '255,255,255'})`
                  }}
                >
                  <span style={{ color: `rgb(${timelineInfoList[Index].fontColor ? timelineInfoList[Index].fontColor : '255,255,255'})` }}>
                    {fields[getPrefixField('testDriveTitle')].value}
                  </span>
                </button>
              </div>
            </div>
            <div className="Cloud">
              <div>
                <div style={{ background: `rgba(${timelineInfoList[Index].color},0.8)` }} />
                <div style={{ background: `rgba(${timelineInfoList[Index].color},0.5)` }} />
                <div style={{ background: `rgba(${timelineInfoList[Index].color},0.1)` }} />
              </div>

              <div>
                <img
                  data-action={fields['show360-show-dmp']?.length > 0 ? fields['show360-show-dmp'][0]?.fields?.func?.value : ''}
                  data-item={fields['show360-show-dmp']?.length > 0 ? fields['show360-show-dmp'][0].name : ''}
                  data-url={fields[getPrefixField('showFullPageUrl')].value}
                  // src={`${temp.app.deployUrlS3}${fields[getPrefixField('showFullIcon')].value}`}
                  src={createFilePath(fields[getPrefixField('showFullIcon')].value)}
                  className="rightIcon"
                  style={{ background: `rgb(${timelineInfoList[Index].color})` }}
                  alt="全景看车"
                />
                <p
                  data-action={fields['show360-show-dmp']?.length > 0 ? fields['show360-show-dmp'][0]?.fields?.func?.value : ''}
                  data-item={fields['show360-show-dmp']?.length > 0 ? fields['show360-show-dmp'][0].name : ''}
                  data-url={fields[getPrefixField('showFullPageUrl')].value}
                >
                  {fields[getPrefixField('showFullPageTitle')].value}
                </p>
                <a
                  target="_blank"
                  data-action={fields['show360-show-dmp']?.length > 0 ? fields['show360-show-dmp'][0]?.fields?.func?.value : ''}
                  data-item={fields['show360-show-dmp']?.length > 0 ? fields['show360-show-dmp'][0].name : ''}
                  data-url={fields[getPrefixField('showFullPageUrl')].value}
                  href={fields[getPrefixField('showFullPageUrl')].value}
                />
              </div>
            </div>
          </div>
          <div className="ExhibitionCar">
            <Realibox canvasRef={canvasRef} loading={!firstLoaded} progress={sceneLoadedProgress} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
