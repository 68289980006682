import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import EnlargeHeroScrollytelling from '../../elements/EnlargeHeroScrollytelling_v3';
import Details from '../../elements/InnerPageDetail';
import { getPrefixField, sendDmp, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const [num, setNum] = useState(0);
  const [windowHeight, setWindowHeight] = useState(400);
  const isMobile = isMobileFun();
  const [flag, setFlag] = useState(false);
  const rootEl = useRef(null);
  const secondPageRef = useRef(null);
  const secondPageEventRef = useRef(null);
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const id = props?.params?.pagename ? props?.params?.pagename : '';
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);
  useEffect(() => {
    if (props?.fields?.placeHolderRoute?.value) {
      props.updateRefList([sendFirstDmp, sendSecondDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current]);
    } else {
      props.updateRefList([sendFirstDmp]);
      props.updateDmpList([rootEl?.current]);
    }
  }, []);
  const sendFirstDmp = () => {
    sendDmp(props.fields.window_dmp);
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id;
  };

  const sendSecondDmp = () => {
    secondPageEventRef?.current?.sendEvent();
  };

  return (
    <div>
      <div ref={rootEl}>
        <EnlargeHeroScrollytelling
          {...props}
          num={num}
          setNum={setNum}
          windowHeight={windowHeight}
          isMobile={isMobile}
          rootEl={rootEl}
          checkDetail={setFlag}
          anchorId={props?.params?.pagename}
        />
        {flag && <Details routePath={props.fields[`${getPrefixField('tab_list')}`][num].fields.button_herf.value} close={() => setFlag(false)} />}
      </div>
      {props?.fields?.placeHolderRoute?.value && (
        <div ref={secondPageRef}>
          <Placeholder name={props?.fields?.placeHolderRoute?.value} rendering={route} page={route} eventRef={secondPageEventRef} />
        </div>
      )}
    </div>
  );
}
