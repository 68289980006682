import React, { useEffect, useRef, createRef, useState } from 'react';
import './style.scss';
import temp from '../../temp/config';
import { isMobile as isDeviceMobile } from '../../utils/dom-utils';
import { convertStylesStringToObject, getPrefixField, sendDmp, sendDmpAction, createFilePath } from '../../utils/obj-utils';
import Detail from '../../elements/InnerPageDetail';

export default function index({ fields }) {
  const [data, setData] = useState(null);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    // const faceSource = `${temp.app.deployUrlS3}${fields[getPrefixField('face_source')]?.value}`;
    const faceSource = createFilePath(fields[getPrefixField('face_source')]?.value);
    const detailBtnHref = fields[getPrefixField('detail_btn_href')]?.value;
    const openVideoDmp = fields[`${getPrefixField('open_video_dmp')}`];
    // const videoName = `${temp.app.deployUrlS3}${fields[getPrefixField('video_name')]?.value}`;
    const videoName = createFilePath(fields[getPrefixField('video_name')]?.value);
    const isMobile = isDeviceMobile();
    const text = fields[getPrefixField('text', isMobile)]?.value;
    const style = fields[getPrefixField('style', isMobile)]?.value;
    setData({ faceSource, detailBtnHref, openVideoDmp, videoName, isMobile, text, style });
  }, []);

  return (
    <React.Fragment>
      {data ? (
        <div className="inner-page-face-container" id={fields?.id.value}>
          <img
            alt="广汽本田"
            style={convertStylesStringToObject(data.style)}
            src={createFilePath(data?.faceSource)}
            onClick={() => {
              sendDmp(data?.openVideoDmp, data?.videoName);
              setFlag(true);
            }}
          />
          {data.text && (
            <div
              onClick={() => {
                sendDmp(data?.openVideoDmp, data?.videoName);
                setFlag(true);
              }}
              dangerouslySetInnerHTML={{ __html: data.text.replaceHtml() }}
            ></div>
          )}
          {flag && <Detail routePath={data?.detailBtnHref} close={() => setFlag(false)} />}
        </div>
      ) : null}
    </React.Fragment>
  );
}
