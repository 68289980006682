import React, { useEffect, useRef, createRef, useState } from 'react';
import './style.scss';
import temp from '../../temp/config';
import { isMobile as isDeviceMobile } from '../../utils/dom-utils';
import { getPrefixField, sendDmp, sendDmpAction, createFilePath } from '../../utils/obj-utils';
import Detailss from '../../elements/InnerPageDetail';

export default function index({ fields }) {
  return (
    <React.Fragment>
      <div className="innerPageSourceList">
        {fields.source_list.map((item, index) => {
          return (
            <div key={index} className="listOnly">
              {/* <img alt="广汽本田" src={`${temp.app.deployUrlS3}${item.fields.source.value}`} className="imgListOne" /> */}
              <img alt="广汽本田" src={createFilePath(item.fields.source.value)} className="imgListOne" />
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}
