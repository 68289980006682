import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmpAction, sendDmp } from '../../utils/obj-utils';
import Campaign from '../../elements/Campaign';
import Detailss from '../../elements/InnerPageDetail';

export default function index(props) {
  const { fields } = props;
  const [windowHeight, setWindowHeight] = useState(400);
  const [thisMobile, setThisMobile] = useState(true);
  const [flag, setFlag] = useState(false);
  let rootEl = useRef(null);
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
    setThisMobile(document.documentElement.clientWidth > 1024);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
      setThisMobile(document.documentElement.clientWidth > 1024);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(props.fields.window_dmp);
  };

  const checkCar = () => {
    let xhr = new XMLHttpRequest();
    const baseUrl = 'https://ghac.realibox.com/api/customer/api/v1/applet/generatescheme';
    const query = `r=${location.href}`;
    const path = '/components/pages/index/index';
    xhr.onreadystatechange = () => {
      const res = JSON.parse(xhr.response);
      if (res && res.code === 10000) {
        const {
          info: { openlink }
        } = res;
        location.href = openlink;
      }
      return;
    };
    xhr.open('get', `${baseUrl}?$path=${encodeURIComponent(path)}&query=${encodeURIComponent(query)}&type=0`, true);
    xhr.send();
  };

  const openDetail = () => {
    setFlag(true);
    sendDmpAction(
      fields.video_btn_dmp?.length > 0 ? fields.video_btn_dmp[0]?.fields?.func?.value : '',
      fields.video_btn_dmp?.length > 0 ? fields.video_btn_dmp[0].name : '',
      ''
    );
  };

  return (
    <div>
      <Campaign {...props} windowHeight={windowHeight} thisMobile={thisMobile} rootEl={rootEl} openDetail={openDetail} checkCar={checkCar} />
      {flag && <Detailss routePath={props.fields.detail_btn_href.value} close={() => setFlag(false)} />}
    </div>
  );
}
