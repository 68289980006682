/* eslint-disable no-unused-expressions */
// 本插件由www.swiper.com.cn提供
// 版本1.03
export const swiperAnimateCache = (a) => {
  for (var j = 0; j < a.slides.length; j++) {
    var allBoxes = a.slides[j].querySelectorAll('.ani');
    for (let i = 0; i < allBoxes.length; i++) {
      allBoxes[i].attributes.style
        ? allBoxes[i].setAttribute('swiper-animate-style-cache', allBoxes[i].attributes.style.value)
        : allBoxes[i].setAttribute('swiper-animate-style-cache', ' '),
        (allBoxes[i].style.visibility = 'hidden');
    }
  }
};

export const swiperAnimate = (a) => {
  var allBoxes, effect, style, duration, delay;
  clearSwiperAnimate(a);
  var b = a.slides[a.activeIndex].querySelectorAll('.ani');
  for (var i = 0; i < b.length; i++) {
    (b[i].style.visibility = 'visible'),
      (effect = b[i].attributes['swiper-animate-effect'] ? b[i].attributes['swiper-animate-effect'].value : ''),
      (b[i].className = b[i].className + '  ' + effect + ' ' + 'animated'),
      (style = b[i].attributes.style.value),
      (duration = b[i].attributes['swiper-animate-duration'] ? b[i].attributes['swiper-animate-duration'].value : ''),
      duration && (style = style + 'animation-duration:' + duration + ';-webkit-animation-duration:' + duration + ';'),
      (delay = b[i].attributes['swiper-animate-delay'] ? b[i].attributes['swiper-animate-delay'].value : ''),
      delay && (style = style + 'animation-delay:' + delay + ';-webkit-animation-delay:' + delay + ';'),
      b[i].setAttribute('style', style);
  }
};

export const clearSwiperAnimate = (a) => {
  for (var j = 0; j < a.slides.length; j++) {
    var allBoxes = a.slides[j].querySelectorAll('.ani');
    var effect;
    for (var i = 0; i < allBoxes.length; i++) {
      allBoxes[i].attributes['swiper-animate-style-cache'] &&
        allBoxes[i].setAttribute('style', allBoxes[i].attributes['swiper-animate-style-cache'].value),
        (allBoxes[i].style.visibility = 'hidden'),
        (allBoxes[i].className = allBoxes[i].className.replace('animated', ' ')),
        allBoxes[i].attributes['swiper-animate-effect'] &&
          ((effect = allBoxes[i].attributes['swiper-animate-effect'].value), (allBoxes[i].className = allBoxes[i].className.replace(effect, ' ')));
    }
  }
};

export const eleAnimateCache = (a) => {
  var allBoxes = a.querySelectorAll('.ani');
  for (let i = 0; i < allBoxes.length; i++) {
    allBoxes[i].attributes.style
      ? allBoxes[i].setAttribute('swiper-animate-style-cache', allBoxes[i].attributes.style.value)
      : allBoxes[i].setAttribute('swiper-animate-style-cache', ' '),
      (allBoxes[i].style.visibility = 'hidden');
  }
};

export const eleAnimate = (a) => {
  var allBoxes, effect, style, duration, delay;
  cleareleAnimate(a);
  var b = a.querySelectorAll('.ani');
  for (var i = 0; i < b.length; i++) {
    (b[i].style.visibility = 'visible'),
      (effect = b[i].attributes['swiper-animate-effect'] ? b[i].attributes['swiper-animate-effect'].value : ''),
      (b[i].className = b[i].className + '  ' + effect + ' ' + 'animated'),
      (style = b[i].attributes.style.value),
      (duration = b[i].attributes['swiper-animate-duration'] ? b[i].attributes['swiper-animate-duration'].value : ''),
      duration && (style = style + 'animation-duration:' + duration + ';-webkit-animation-duration:' + duration + ';'),
      (delay = b[i].attributes['swiper-animate-delay'] ? b[i].attributes['swiper-animate-delay'].value : ''),
      delay && (style = style + 'animation-delay:' + delay + ';-webkit-animation-delay:' + delay + ';'),
      b[i].setAttribute('style', style);
  }
};

export const cleareleAnimate = (a) => {
  var allBoxes = a.querySelectorAll('.ani');
  var effect;
  for (var i = 0; i < allBoxes.length; i++) {
    allBoxes[i].attributes['swiper-animate-style-cache'] &&
      allBoxes[i].setAttribute('style', allBoxes[i].attributes['swiper-animate-style-cache'].value),
      (allBoxes[i].style.visibility = 'hidden'),
      (allBoxes[i].className = allBoxes[i].className.replace('animated', ' ')),
      allBoxes[i].attributes['swiper-animate-effect'] &&
        ((effect = allBoxes[i].attributes['swiper-animate-effect'].value), (allBoxes[i].className = allBoxes[i].className.replace(effect, ' ')));
  }
};
