import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import { Swiper } from 'swiper';
import { Pagination, Navigation } from 'swiper';
import temp from '../../temp/config';
import './style.scss';
import Icon from '../../elements/icon';
import { isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index({ fields, eventRef }) {
  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  // const deployUrlS3 = temp.app.deployUrlS3;
  const isMobile = isMobileFun();
  const title = isMobile ? fields.m_title?.value : fields.pc_title?.value;
  const background = isMobile ? fields.backColor?.value : fields.backColor?.value;
  const imgList = fields.list;
  const data = {
    imgList,
    isMobile,
    title,
    background
  };

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    window.Dmp.sectionAction(fields.windowDmp[0]?.displayName);
  };

  useEffect(() => {
    const swiper = new Swiper(swiperRef.current, {
      grabCursor: true,
      modules: [Pagination, Navigation],
      slidesPerView: data?.isMobile ? 1 : 3,
      grid: {
        fill: 'column',
        rows: 1
      },
      pagination: data?.isMobile
        ? {
            el: containerRef.current.querySelector('.swiper-pagination'),
            clickable: true
          }
        : false,
      navigation: {
        prevEl: containerRef.current.querySelector('.navigation-prev'),
        nextEl: containerRef.current.querySelector('.navigation-next'),
        disabledClass: 'navigation-disabled'
      }
    });
    for (let i = 0; i < swiper.pagination.bullets.length; i++) {
      swiper.pagination.bullets[i].onclick = function () {
        // sendDmp([data?.dmpPointMore[i]]);
      };
    }
    swiper.navigation.prevEl.onclick = function () {
      // sendDmp(data?.dmpPointLeft);
    };
    swiper.navigation.nextEl.onclick = function () {
      // sendDmp(data?.dmpPointRight);
    };

    return () => {
      swiper.destroy(true, true);
    };
  }, []);

  useEffect(() => {
    const publicPraiseTimeline = gsap.timeline({ pause: true });
    if (!data.isMobile) {
      publicPraiseTimeline
        .fromTo(titleRef.current, { opacity: 0 }, { opacity: 1 })
        .fromTo(swiperRef.current, { x: '100%', opacity: 0 }, { x: '0', opacity: 1 });
    } else {
      publicPraiseTimeline.fromTo(titleRef.current, { opacity: 0 }, { opacity: 1 }).fromTo(swiperRef.current, { x: '100%' }, { x: '0' });
    }
    const controller = new ScrollMagic.Controller();
    const publicPraiseScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onEnter',
      duration: data.isMobile ? document.documentElement.clientHeight : '80%'
    })
      .on('progress', function (event) {
        publicPraiseTimeline.progress(event.progress);
      })
      .setTween(publicPraiseTimeline)
      .addTo(controller);

    return () => {
      publicPraiseTimeline.kill();
      publicPraiseScene.remove();
      publicPraiseScene.destroy();
      controller.destroy();
    };
  }, []);

  return (
    <section className={`PriceExterior_v5 ${fields.class.value}`} ref={containerRef} style={{ background: background }}>
      <div className="publicPraiseV2">
        <div className="content-container">
          <div className="titleV2" ref={titleRef} dangerouslySetInnerHTML={{ __html: data.title?.replaceHtml() }} />
          <div className="swiper-container">
            <div className="swiper-support" ref={swiperRef}>
              <div className="swiper-wrapper">
                <React.Fragment>
                  {data?.imgList?.map((item, index) => {
                    return (
                      <div className="swiper-slide" key={index}>
                        {item.fields.reserve.value ? (
                          <div className="cardDetail" style={{ background: fields.textImgbackColor.value }}>
                            <img
                              alt="广汽本田"
                              className="img"
                              src={`${createFilePath(isMobile ? item.fields.m_img.value : item.fields.pc_img.value)}`}
                            />
                            <div
                              className="title"
                              dangerouslySetInnerHTML={{ __html: (isMobile ? item.fields.m_text.value : item.fields.pc_text.value)?.replaceHtml() }}
                            ></div>
                          </div>
                        ) : (
                          <div className="cardDetail" style={{ background: fields.textImgbackColor.value }}>
                            <div
                              className="title"
                              dangerouslySetInnerHTML={{ __html: (isMobile ? item.fields.m_text.value : item.fields.pc_text.value)?.replaceHtml() }}
                            ></div>

                            <img
                              alt="广汽本田"
                              className="img"
                              src={`${createFilePath(isMobile ? item.fields.m_img.value : item.fields.pc_img.value)}`}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </React.Fragment>
              </div>
            </div>
            <div className="bottomIcon">
              <div
                className="navigation-prev"
                onClick={() => {
                  window.Dmp.clickAction(fields.BtnL[0].displayName, '', '');
                }}
              >
                <Icon name="ArrowLeft" />
              </div>
              <div
                className="navigation-next"
                onClick={() => {
                  window.Dmp.clickAction(fields.BtnR[0].displayName, '', '');
                }}
              >
                <Icon name="ArrowRight" />
              </div>
              {data?.isMobile && (
                <div
                  className="swiper-pagination"
                  style={{ bottom: '-5.5vh' }}
                  onClick={(e) => {
                    window.Dmp.clickAction(`${fields.dot.value + (swiperRef.current.swiper.activeIndex + 1)}`, '', '');
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
