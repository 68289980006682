/* eslint-disable no-bitwise */
import React, { useEffect, useState, useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, Navigation } from 'swiper';
import gsap from 'gsap';
import './style.scss';
import { isEmpty, createFilePath, getPrefixField, checkMobile, getQueryString, isMobileFun } from '../../utils/obj-utils';
import useIsomorphicLayoutEffect from '../../utils/useIsomorphicLayoutEffect';
import Icon from '../../elements/icon';
import { number_format, calcLoanSchemeV2 } from '../Contrast_v1/details/loan-calc';
import { dataFetcher, getJsonp } from '../../dataFetcher';
import FullModalDetail from '../../elements/FullModalDetail_v1';

export default function index({ fields }) {
  const isMobile = isMobileFun();
  const containerRef = useRef(null);
  const usernameRef = useRef(null);
  const mobileRef = useRef(null);
  const verificationCodeRef = useRef(null);
  const messageCodeRef = useRef(null);
  const swiperRef = useRef(null);
  const vehiclesData = useRef(null);
  const proportionData = useRef(null);
  const ruleData = useRef(null);
  const typeData = useRef(null);
  const periodData = useRef(null);
  const numData = useRef(null);
  const timeData = useRef(null);
  const lastDriveInfo = useRef(null);
  const [selectIndex, setSelectIndex] = useState(0);
  const [selectVersionIndex, setSelectVersionIndex] = useState(0);
  const [versionHide, setVersionHide] = useState(true);
  const [selectProportionIndex, setSelectProportionIndex] = useState(0);
  const [proportionHide, setProportionHide] = useState(true);
  const [selectPeriodIndex, setSelectPeriodIndex] = useState(0);
  const [periodHide, setPeriodHide] = useState(true);
  const [programmeList, setProgrammeList] = useState([]);
  const [agree, setAgree] = useState(false);
  const [location, setLocation] = useState({ province: '广东省', city: '广州市' });
  const [originalDealerData, setOriginalDealerData] = useState();
  const [provinceData, setProvinceData] = useState();
  const [policyVersion, setPolicyVersion] = useState();
  const [selectVehicleType, setSelectVehicleType] = useState();
  const [selectProvince, setSelectProvince] = useState();
  const [selectCity, setSelectCity] = useState();
  const [selectDealer, setSelectDealer] = useState();
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [verificationImage, setVerificationImage] = useState();
  const [questionData, setQuestionData] = useState(JSON.parse(fields?.questionData?.value?.replaceHtml()));
  const method = fields[getPrefixField('method', isMobile)]?.value || 2;
  const [driveInfo, setDriveInfo] = useState({
    mediafrom: method,
    device_type: isMobile ? 2 : 1,
    contactid: useSitecoreContext()?.sitecoreContext?.contactId,
    graphic: {
      code: '',
      id: '',
      preMod: false
    }
  });
  const [messageInfo, setMessageInfo] = useState({ sendType: 1, validWhiteList: false, whiteListType: 0 });
  const desc = fields[getPrefixField('desc', isMobile)]?.value;
  const remarks = fields[getPrefixField('remarks', isMobile)]?.value?.replaceHtml();

  if (!vehiclesData?.current) {
    vehiclesData.current = JSON.parse(fields?.vehiclesData?.value?.replaceHtml()).map((item, _) => ({
      ...item,
      versionList: [{ name: '请选择配置' }, ...item.versionList]
    }));
    ruleData.current = JSON.parse(fields?.ruleData?.value?.replaceHtml());
    typeData.current = JSON.parse(fields?.typeData?.value?.replaceHtml());
    proportionData.current = [20, 30, 40, 50, 60, 70, 80, 90];
    periodData.current = [12, 18, 24, 36, 48, 60];
    numData.current = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五'];
    timeData.current = [
      { name: '7天', value: '1' },
      { name: '一个月内', value: '2' },
      { name: '1个月以上', value: '3' }
    ];
  }

  const [time, setTime] = useState(timeData?.current[0].value);
  const modelName = vehiclesData?.current[selectIndex]?.modelName;
  const selectVersion = vehiclesData?.current[selectIndex]?.versionList[selectVersionIndex];
  const selectProportion = proportionData?.current[selectProportionIndex];
  const selectPeriod = periodData?.current[selectPeriodIndex];
  const loadAmount = selectVersion?.price ? number_format((selectVersion?.price * (100 - selectProportion)) / 100) : '';
  const questionSize = isMobile ? questionData?.length : 4;

  useEffect(() => {
    document.body.addEventListener('click', hideSelect);
    return () => {
      document.body.removeEventListener('click', hideSelect);
    };
  }, []);

  useEffect(() => {
    const carId = getQueryString('carid');
    let initialSlide = 0;
    if (!isEmpty(carId)) {
      initialSlide = vehiclesData?.current?.findIndex((item) => item.modelName.toLocaleLowerCase() === carId.toLocaleLowerCase()) || 0;
      setSelectIndex(initialSlide);
    }

    const swiper = new Swiper(swiperRef.current, {
      grabCursor: true,
      modules: [Navigation],
      slidesPerView: isMobile ? 3 : 5,
      initialSlide,
      navigation: {
        prevEl: containerRef.current.querySelector('.navigation-prev'),
        nextEl: containerRef.current.querySelector('.navigation-next'),
        disabledClass: 'navigation-disabled'
      }
    });

    return () => {
      swiper.destroy(true, true);
    };
  }, []);

  useIsomorphicLayoutEffect(() => {
    getLocation();
    getOriginalDealer();
    getPolicyVersion();
  }, []);

  useEffect(() => {
    if (originalDealerData) {
      setProvinceData(filterDealerData());
    }
  }, [selectVehicleType, originalDealerData]);

  useEffect(() => {
    if (provinceData && location) {
      const province = provinceData?.find((item) => location.province.includes(item.province_name));
      const city = province?.Citys?.find((item) => location.city.includes(item.city_name));
      setSelectProvince(province);
      setSelectCity(city);
    }
  }, [provinceData, location]);

  useEffect(() => {
    setSelectDealer();
  }, [selectCity, selectProvince, selectVehicleType]);

  const getLocation = () => {
    getJsonp('https://restapi.amap.com/v3/ip?key=d4db88c544bf23a3a363f7ff8bcbf783&output=json')
      .then((response) => {
        if (response.status === '1') {
          setLocation({ province: response.province, city: response.city });
        } else {
          return Promise.reject();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getOriginalDealer = () => {
    dataFetcher('/api/honda/v1/Leads/GetAllDealerProCitys')
      .then(({ data }) => {
        if (data.Status) {
          setOriginalDealerData({ ...data?.Data });
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getPolicyVersion = () => {
    dataFetcher('/api/honda/v1/Policy/GetPolicyVersion')
      .then(({ data }) => {
        if (data.Status) {
          setPolicyVersion(data?.Data?.policy_code);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addLeads = () => {
    dataFetcher('/api/honda/v1/Leads/AddLeads', driveInfo)
      .then(({ data }) => {
        if (data?.Status) {
          alert('提交成功！');
          lastDriveInfo.current = { ...driveInfo };
          driveInfo.userName = '';
          driveInfo.mobile = '';
          usernameRef.current.value = '';
          mobileRef.current.value = '';
          close();
        } else {
          const errorCode = parseInt(data?.ErrorResult?.ErrCode);
          if (errorCode === 26 || errorCode === 27 || errorCode === 28) {
            if (errorCode === 28 && showVerificationCode) {
              return Promise.reject(new Error('短信验证码输入错误！'));
            } else {
              setShowVerificationCode(true);
              getVerificationImage();
            }
          } else {
            return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
          }
        }
      })
      .catch((error) => {
        alert(error?.message);
        console.error(error);
      });
  };

  const getVerificationImage = () => {
    dataFetcher('/api/honda/v1/Graphic/CreateCode')
      .then(({ data }) => {
        if (data?.Status) {
          driveInfo.graphic.id = data?.Data?.GraphicValidId;
          messageInfo.validId = data?.Data?.GraphicValidId;
          messageInfo.validCode = '';
          verificationCodeRef.current.value = '';
          setVerificationImage(data?.Data?.Base64);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filterDealerData = () => {
    const originalData = JSON.parse(JSON.stringify(originalDealerData));
    if (!selectVehicleType) {
      return reduceDealerData(originalData);
    }
    const data = { Provinces: [], Citys: [], Dealers: [...originalData?.Dealers] };
    originalData?.Citys?.forEach((item) => {
      const dealers = getDealersData(item.city_id, originalData?.Dealers);
      if (dealers.length !== 0) {
        item.Dealers = dealers;
        data.Citys.push(item);
      }
    });
    originalData?.Provinces?.forEach((item) => {
      const citys = data?.Citys?.filter((item1) => item1.province_id === item.province_id);
      if (citys.length !== 0) {
        item.Citys = citys;
        data.Provinces.push(item);
      }
    });
    return data?.Provinces;
  };

  const reduceDealerData = (data) => {
    data?.Provinces?.forEach((item1) => {
      data?.Citys?.forEach((item2) => {
        item2.Dealers = data?.Dealers?.filter((item3) => item3.city_id === item2.city_id);
      });
      item1.Citys = data?.Citys?.filter((item2) => item2.province_id === item1.province_id);
    });
    return [...data?.Provinces];
  };

  const getDealersData = (cityId, data) => {
    const modelName = selectVehicleType?.modelName;
    const weight = selectVehicleType?.weight;
    let dealers = [];
    if (!isEmpty(weight)) {
      dealers = data.filter((item) => item.city_id === cityId && (weight & item.dealer_right) > 0);
      // if (modelName === 'enp1') {
      //   if (dealers.length === 0) {
      //     dealers = data.filter((item) => item.city_id === cityId && item.dealer_level === 1);
      //   } else {
      //     const exist = dealers.find((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     if (exist) {
      //       dealers = dealers.filter((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     }
      //   }
      // }
    }
    const newEnergy = dealers.filter((item) => item.dealer_right === 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    const gas = dealers.filter((item) => item.dealer_right !== 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    return [...newEnergy, ...gas];
  };

  const hideSelect = () => {
    setVersionHide(true);
    setProportionHide(true);
    setPeriodHide(true);
  };

  const swiperClick = (index) => {
    setSelectIndex(index);
    setSelectVersionIndex(0);
    setSelectProportionIndex(0);
  };

  const versionClick = (index) => {
    setSelectVersionIndex(index);
    setVersionHide(true);
  };

  const versionChange = (e) => {
    setSelectVersionIndex(vehiclesData?.current[selectIndex]?.versionList?.findIndex((item) => item?.name === e.target.value));
  };

  const proportionClick = (index) => {
    setSelectProportionIndex(index);
    setProportionHide(true);
  };

  const proportionChange = (e) => {
    setSelectProportionIndex(proportionData?.current?.findIndex((item) => item === parseInt(e.target.value)));
  };

  const periodClick = (index) => {
    setSelectPeriodIndex(index);
    setPeriodHide(true);
  };

  const periodChange = (e) => {
    setSelectPeriodIndex(periodData?.current?.findIndex((item) => item === parseInt(e.target.value)));
  };

  const toggleVersion = (e) => {
    e.stopPropagation();
    setVersionHide(!versionHide);
    setProportionHide(true);
    setPeriodHide(true);
  };

  const toggleProportion = (e) => {
    e.stopPropagation();
    setProportionHide(!proportionHide);
    setVersionHide(true);
    setPeriodHide(true);
  };

  const togglePeriod = (e) => {
    e.stopPropagation();
    setPeriodHide(!periodHide);
    setVersionHide(true);
    setProportionHide(true);
  };

  const submit = () => {
    if (selectVersionIndex === 0) {
      alert('请选择配置');
      return;
    }
    setProgrammeList(calcLoanSchemeV2(selectVersion.price, selectProportion, selectPeriod, modelName, ruleData?.current));
  };

  const usernameChange = (e) => {
    driveInfo.userName = e.target.value;
  };

  const mobileChange = (e) => {
    driveInfo.mobile = e.target.value;
  };

  const vehicleTypeChange = (e) => {
    setSelectVehicleType(typeData?.current?.find((item) => item.seriesId === e.target.value));
  };

  const provinceChange = (e) => {
    setSelectProvince(provinceData?.find((item) => item.province_id === e.target.value));
    setSelectCity();
  };

  const cityChange = (e) => {
    setSelectCity(selectProvince?.Citys?.find((item) => item.city_id === e.target.value));
  };

  const dealerChange = (e) => {
    setSelectDealer(selectCity?.Dealers?.find((item) => item.dealer_id === e.target.value));
  };

  const timeChange = (e) => {
    setTime(e.target.value);
  };

  const agreeChange = () => {
    setAgree(!agree);
  };

  const driverSubmit = () => {
    if (isEmpty(driveInfo?.userName)) {
      alert('请填写真实姓名');
      return;
    }
    if (!checkMobile(driveInfo?.mobile)) {
      alert('请填写正确的联系方式');
      return;
    }
    if (!selectVehicleType) {
      alert('请选择意向车型');
      return;
    }
    if (!selectProvince) {
      alert('请选择省份');
      return;
    }
    if (!selectCity) {
      alert('请选择城市');
      return;
    }
    if (!selectDealer) {
      alert('请选择特约店');
      return;
    }
    if (!agree) {
      alert('请确认并阅读声明');
      return;
    }
    if (showVerificationCode && isEmpty(driveInfo?.smsValidCode)) {
      alert('短信验证码不能为空');
      return;
    }
    driveInfo.provinceId = selectProvince?.province_id;
    driveInfo.cityId = selectCity?.city_id;
    driveInfo.dealerId = selectDealer?.dealer_id;
    driveInfo.series_code = selectVehicleType?.seriesId;
    driveInfo.areaCode = selectProvince?.zone_id;
    driveInfo.adcode = getQueryString('adcode');
    driveInfo.graphic.code = messageInfo?.validCode;
    driveInfo.plan_buy_date = time;
    if (lastDriveInfo?.current) {
      let exist = false;
      for (const key in lastDriveInfo?.current) {
        if (!['graphic', 'smsValidId', 'smsValidCode'].includes(key) && lastDriveInfo?.current[key] !== driveInfo[key]) {
          exist = true;
          break;
        }
      }
      if (!exist) {
        alert('重复提交！');
        return;
      }
    }
    addLeads();
  };

  const verificationCodeChange = (e) => {
    messageInfo.validCode = e.target.value;
  };

  const messageCodeChange = (e) => {
    driveInfo.smsValidCode = e.target.value;
  };

  const sendMessage = () => {
    if (isEmpty(messageInfo?.validCode)) {
      alert('请输入图形验证码！');
      return;
    }
    messageInfo.phone = driveInfo?.mobile;
    dataFetcher('/api/honda/v1/SMS/SendSMS', messageInfo)
      .then(({ data }) => {
        if (data.Status) {
          alert('已发送短信,请注意查收');
          driveInfo.smsValidId = data?.Data?.smsValidId;
        } else {
          getVerificationImage();
          const errorCode = parseInt(data?.ErrorResult?.ErrCode);
          let errorMessage;
          switch (errorCode) {
            case -1:
              errorMessage = '短信发送失败！';
              break;
            case 5:
              errorMessage = '图形验证码错误！';
              break;
            case 11:
              errorMessage = '手机号当天发送次数超限！';
              break;
            case 12:
              errorMessage = '手机号发送次数超限，请1小时候再试！';
              break;
            case 13:
              errorMessage = '手机号发送频率过快，请稍后再试！';
              break;
            case 21:
              errorMessage = '非常抱歉，发送验证码时出现异常，请稍后再试！';
              break;
            default:
              errorMessage = data?.ErrorResult?.ErrMsg;
              break;
          }
          return Promise.reject(new Error(errorMessage));
        }
      })
      .catch((error) => {
        alert(error?.message);
        console.error(error);
      });
  };

  const close = () => {
    setDriveInfo({
      ...driveInfo,
      graphic: {
        code: '',
        id: '',
        preMod: false
      },
      smsValidId: '',
      smsValidCode: ''
    });
    setMessageInfo({ ...messageInfo, validId: '', validCode: '', phone: '' });
    verificationCodeRef?.current && (verificationCodeRef.current.value = '');
    messageCodeRef?.current && (messageCodeRef.current.value = '');
    setShowVerificationCode(false);
  };

  const questionClick = (item, e) => {
    item.active = !item?.active;
    const dom = e.currentTarget.nextElementSibling;
    if (item?.active) {
      setQuestionData([...questionData]);
      setTimeout(() => {
        const height = `${dom.getBoundingClientRect().height}px`;
        gsap.fromTo(dom, { height: 0 }, { height, duration: 0.3 });
      }, 0);
    } else {
      const height = `${dom.getBoundingClientRect().height}px`;
      gsap.fromTo(
        dom,
        { height },
        {
          height: 0,
          duration: 0.3,
          onComplete: () => {
            dom.style.height = height;
            setQuestionData([...questionData]);
          }
        }
      );
    }
  };

  return (
    <section className="financial-calculator-v1" ref={containerRef}>
      <div className="title">金融服务</div>
      <div className="tab">
        <a href="/financial/service">喜悦金融服务</a>
        <a href="/financial/calculator" className="active">
          贷款计算器
        </a>
      </div>
      <p className="desc" dangerouslySetInnerHTML={{ __html: desc }} />
      <div className="swiper-container">
        <div className="swiper" ref={swiperRef}>
          <div className="swiper-wrapper">
            {vehiclesData?.current?.map((item, index) => {
              return (
                <div key={index} className={`swiper-slide ${index === selectIndex ? 'active' : ''}`} onClick={() => swiperClick(index)}>
                  <img src={createFilePath(item.img)} alt={item.name} />
                  <p>{item.name}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="navigation-prev">
          <Icon name="ArrowLeft1" />
        </div>
        <div className="navigation-next">
          <Icon name="ArrowRight1" />
        </div>
      </div>
      <ul className="version-container">
        <li>
          <p className="version-title">配置</p>
          <div className="version-select">
            {isMobile ? (
              <>
                <input type="text" value={selectVersion?.name} readOnly />
                <select value={selectVersion?.name} onChange={(e) => versionChange(e)}>
                  {vehiclesData?.current[selectIndex]?.versionList?.map((item, index) => (
                    <option key={index} value={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <>
                <p className="select" onClick={(e) => toggleVersion(e)}>
                  <span>{selectVersion?.name}</span>
                  <em></em>
                </p>
                <div className={`select-list${versionHide ? ' hide' : ''}`}>
                  {vehiclesData?.current[selectIndex]?.versionList?.map((item, index) => (
                    <p key={index} onClick={() => versionClick(index)}>
                      {item?.name}
                    </p>
                  ))}
                </div>
              </>
            )}
          </div>
        </li>
        <li>
          <p className="version-title">指导价（元）</p>
          <div className="version-select price">{number_format(selectVersion?.price)}</div>
        </li>
      </ul>
      {isMobile ? (
        <ul className="version-container option">
          <li>
            <p className="version-title">首付比例（%）</p>
            <div className="version-select">
              <input type="text" value={`${selectProportion}%`} readOnly />
              <select value={selectProportion} onChange={(e) => proportionChange(e)}>
                {proportionData?.current?.map((item, index) => (
                  <option key={index} value={item}>
                    {`${item}%`}
                  </option>
                ))}
              </select>
            </div>
          </li>
          <li>
            <p className="version-title">贷款金额（元）</p>
            <div className="version-select price">{loadAmount}</div>
          </li>
          <li>
            <p className="version-title">贷款期数</p>
            <div className="version-select">
              <input type="text" value={selectPeriod} readOnly />
              <select value={selectPeriod} onChange={(e) => periodChange(e)}>
                {periodData?.current?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </li>
        </ul>
      ) : (
        <ul className="option-container">
          <li>
            <p className="option-title">首付比例（%）</p>
            <div className="option-content">
              <div className="option-select">
                <p className="select" onClick={(e) => toggleProportion(e)}>
                  <span>{`${selectProportion}%`}</span>
                  <em></em>
                </p>
                <div className={`select-list${proportionHide ? ' hide' : ''}`}>
                  {proportionData?.current?.map((item, index) => (
                    <p key={index} onClick={() => proportionClick(index)}>
                      {`${item}%`}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </li>
          <li>
            <p className="option-title">贷款金额（元）</p>
            <div className="option-content price">{loadAmount}</div>
          </li>
          <li>
            <p className="option-title">贷款期数</p>
            <div className="option-content">
              <div className="option-select">
                <p className="select" onClick={(e) => togglePeriod(e)}>
                  <span>{selectPeriod}</span>
                  <em></em>
                </p>
                <div className={`select-list${periodHide ? ' hide' : ''}`}>
                  {periodData?.current?.map((item, index) => (
                    <p key={index} onClick={() => periodClick(index)}>
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </li>
        </ul>
      )}
      <button className="submit" onClick={submit}>
        确认提交
      </button>
      {programmeList.length > 0 && (
        <div className="programme-list">
          {programmeList.map((item, index) => (
            <div key={index} className="item">
              <div className="item-title">{`方案${numData?.current[index]}`}</div>
              <div className="item-content">
                <div className="left">
                  <strong>{item?.loan?.name}</strong>
                  <p className="text">{item?.desc}</p>
                </div>
                <div className="right">
                  <img className="image" src={createFilePath(item?.loan?.image)} alt={item?.loan?.name} />
                </div>
              </div>
            </div>
          ))}
          <p className="remarks" dangerouslySetInnerHTML={{ __html: remarks }} />
        </div>
      )}
      <p className="service-title">金融服务</p>
      <p>*为必填项。您的信息我们将会完全保密，请您放心填写。</p>
      <ul className="drive-info-list">
        <li>
          <span className="drive-info-txt">*基本信息：</span>
          <div className="input-container">
            <em className="drive-info-icon" style={{ backgroundImage: `url(${createFilePath('/financial/calculator/icon-name.png')})` }} />
            <input className="drive-info-input" ref={usernameRef} type="text" placeholder="您的真实姓名" onChange={usernameChange} />
          </div>
          <div className="input-container margin">
            <em className="drive-info-icon" style={{ backgroundImage: `url(${createFilePath('/financial/calculator/icon-tel.png')}) ` }} />
            <input className="drive-info-input" ref={mobileRef} type="text" placeholder="您的手机号码" onChange={mobileChange} />
          </div>
          <div className="input-container">
            <em className="drive-info-icon" style={{ backgroundImage: `url(${createFilePath('/financial/calculator/icon-car.png')}) ` }} />
            <select className="drive-info-select" value={selectVehicleType?.seriesId} onChange={vehicleTypeChange}>
              <option>请选择车型</option>
              {typeData?.current?.map((item, index) => (
                <option key={index} value={item?.seriesId} dangerouslySetInnerHTML={{ __html: item?.name }} />
              ))}
            </select>
          </div>
        </li>
        <li>
          <span className="drive-info-txt">*特约店查询：</span>
          <div className="input-container">
            <em className="drive-info-icon" style={{ backgroundImage: `url(${createFilePath('/financial/calculator/icon-province.png')}) ` }} />
            <select className="drive-info-select" value={selectProvince?.province_id || 0} onChange={provinceChange}>
              <option value={0}>请选择省份</option>
              {provinceData?.map((item, index) => (
                <option key={index} value={item?.province_id}>
                  {item?.province_name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-container margin">
            <em className="drive-info-icon" style={{ backgroundImage: `url(${createFilePath('/financial/calculator/icon-city.png')}) ` }} />
            <select className="drive-info-select" value={selectCity?.city_id || 0} onChange={cityChange}>
              <option value={0}>请选择城市</option>
              {selectProvince?.Citys?.map((item, index) => (
                <option key={index} value={item?.city_id}>
                  {item?.city_name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-container">
            <em className="drive-info-icon" style={{ backgroundImage: `url(${createFilePath('/financial/calculator/icon-dealer.png')}) ` }} />
            <select className="drive-info-select" value={selectDealer?.dealer_id || 0} onChange={dealerChange}>
              <option value={0}>请选择特约店</option>
              {selectCity?.Dealers?.map((item, index) => (
                <option key={index} value={item?.dealer_id}>
                  {item?.dealer_name}
                </option>
              ))}
            </select>
          </div>
        </li>
        <li className="purchase-time">
          <span className="drive-info-txt">计划购车时间：</span>
          {timeData?.current?.map((item, index) => (
            <label key={index} className="drive-info-radio">
              <input type="radio" value={item.value} checked={time === item.value} onChange={timeChange} />
              <span>{item.name}</span>
            </label>
          ))}
        </li>
      </ul>
      <p className="policy-container">
        <input type="checkbox" checked={agree} onChange={agreeChange} />
        <label>
          本人同意
          <a href="/policy/privacy-policy" target="_blank">
            《广汽本田隐私政策》
          </a>
          ；广汽本田将对您提交的个人信息保密，不对外公开。
        </label>
      </p>
      <button className="drive-info-button" onClick={driverSubmit}>
        确认提交
      </button>
      <p className="service-title">常见问题</p>
      <div className="question-container">
        {new Array(Math.ceil(questionData?.length / questionSize)).fill().map((_, index) => (
          <div key={index} className="question-list">
            {questionData?.slice(index * questionSize, index * questionSize + questionSize)?.map((item1, index1) => (
              <React.Fragment key={index1}>
                <div className={`question${item1?.active ? ' active' : ''}`} onClick={(e) => questionClick(item1, e)}>
                  <em>{item1?.active ? '﹣' : '﹢'}</em>
                  <span dangerouslySetInnerHTML={{ __html: item1?.question }} />
                </div>
                <div className={`answer${item1?.active ? ' active' : ''}`} dangerouslySetInnerHTML={{ __html: item1?.answer }} />
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
      {showVerificationCode && (
        <FullModalDetail style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="financial-calculator-v1-verification-code">
            <div className="verification-code-form">
              <img className="verification-code-close" src={createFilePath('/financial/calculator/close.png')} onClick={close} alt="close" />
              <div className="verification-code-item">
                <div className="verification-code-item-name">手机号：</div>
                <div className="verification-code-item-right">{driveInfo?.mobile}</div>
              </div>
              <div className="verification-code-item">
                <div className="verification-code-item-name">图形验证码：</div>
                <img className="verification-code-item-image" src={verificationImage} onClick={getVerificationImage} alt="图形验证码" />
                <div className="verification-code-item-right">
                  <input
                    className="verification-code-item-input"
                    ref={verificationCodeRef}
                    type="text"
                    placeholder="请输入图形验证码"
                    onChange={verificationCodeChange}
                  />
                </div>
              </div>
              <div className="verification-code-item">
                <div className="verification-code-item-name">短信验证码：</div>
                <button className="verification-code-item-button" onClick={sendMessage}>
                  发送
                </button>
                <div className="verification-code-item-right">
                  <input
                    className="verification-code-item-input"
                    ref={messageCodeRef}
                    type="text"
                    placeholder="请输入短信验证码"
                    onChange={messageCodeChange}
                  />
                </div>
              </div>
              <button className="verification-code-button" onClick={driverSubmit}>
                确定
              </button>
            </div>
          </div>
        </FullModalDetail>
      )}
    </section>
  );
}
