/* eslint-disable no-bitwise */
import React, { useEffect, useState, useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';
import { createFilePath, getPrefixField, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields }) {
  const isMobile = isMobileFun();
  const containerRef = useRef(null);
  // const vehiclesData = useRef(null);
  // const tabData = useRef(null);
  // const explain = useRef(null);
  const [sliderOn, setSliderOn] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [showCarList, setShowCarList] = useState(null);
  const [selectTabIndex, setSelectTabIndex] = useState(0);
  const [fixed, setFixed] = useState(false);
  const [filterComparisonList, setFilterComparisonList] = useState([]);
  const [exist, setExist] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [vehiclesData, setVehiclesData] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [explain, seExplain] = useState(null);
  const setCarData = (data) => {
    if (!vehiclesData?.current) {
      // const comparisonData = JSON.parse(fields?.comparisonData?.value);
      const comparisonData = data;
      const vehiclesDataA = JSON.parse(fields?.vehiclesData?.value?.replaceHtml());
      vehiclesDataA.forEach((item) => {
        item.comparisonData = comparisonData.filter((item1) => item.modelName.includes(item1.ModelName.Value));
      });
      setVehiclesData([...vehiclesData, ...vehiclesDataA]);
      const tabDataA = comparisonData[0]?.CatalogueList?.map((item) => ({ ...item, KeyValueList: item.KeyValueList.map((item1) => item1.Key) }));
      setTabData([...tabData, ...tabDataA]);
      const explainA = fields[getPrefixField('explain', isMobile)].value.replaceHtml();
      seExplain(explainA);
    }
  };
  const getData = () => {
    setIsLoading(true);
    dataFetcher('/api/honda/v1/ESB/GetNewGhacModelContrastList')
      .then(({ data: response }) => {
        setIsLoading(false);
        if (response.Status) {
          setCarData(response?.Data);
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    document.body.style.overflowY = 'auto';
    document.body.style.height = '100vh';
    document.body.addEventListener('click', hideCar);
    document.body.addEventListener('scroll', scroll);

    return () => {
      document.body.style.overflowY = 'hidden';
      document.body.style.height = 'auto';
      document.body.removeEventListener('click', hideCar);
      document.body.removeEventListener('scroll', scroll);
    };
  }, []);

  useEffect(() => {
    scroll();
    versionCheck();
    comparisonCheck();
    setTimeout(() => {
      const element1 = containerRef?.current?.querySelector('.top-container');
      element1.style.minHeight = selectedList.length > 0 ? getComputedStyle(element1).height : 'auto';
    }, 500);
  }, [selectedList]);

  useEffect(() => {
    setTimeout(() => {
      if (fixed) {
        const element2 = containerRef?.current?.querySelector('.added-car-container');
        const headerHeight = document.querySelector('.headerBoxWhite').getBoundingClientRect().height;
        const offset = headerHeight + element2.getBoundingClientRect().height;
        const element3 = containerRef?.current?.querySelector('.comparison-table thead');
        const element4 = containerRef?.current?.querySelector('.tab-container');
        if (element3) {
          element3.style.top = `${offset + element4.getBoundingClientRect().height}px`;
        }
        element4.style.top = `${offset}px`;
      } else {
        const element3 = containerRef?.current?.querySelector('.comparison-table thead');
        const element4 = containerRef?.current?.querySelector('.tab-container');
        if (element3) {
          element3.style.top = 'unset';
        }
        element4.style.top = 'unset';
      }
    }, 500);
  }, [fixed, exist]);

  const scroll = () => {
    const element1 = containerRef?.current?.querySelector('.added-car-position');
    if (element1) {
      const headerHeight = document.querySelector('.headerBoxWhite').getBoundingClientRect().height;
      const element2 = containerRef?.current?.querySelector('.added-car-container');
      const element3 = containerRef?.current?.querySelector('.top-container');
      const top =
        element1.getBoundingClientRect().top -
        headerHeight +
        (element3.getBoundingClientRect().height - parseFloat(getComputedStyle(element2).marginTop.replace('px', ''))) * 0.6;
      element2.style.top = `${top <= 0 ? headerHeight : 0}px`;
      setFixed(top <= 0);
    } else {
      setFixed(false);
    }
  };

  const selectCar = (e, item) => {
    e.stopPropagation();
    setSelectedList([...selectedList, JSON.parse(JSON.stringify(item))]);
    hideCar();
  };

  const addCar = (e) => {
    e.stopPropagation();
    showCar();
  };

  const showCar = () => {
    if (containerRef?.current?.querySelector('.car-list')?.classList?.value?.indexOf('show') === -1) {
      setShowCarList(true);
    }
    scrollTo();
  };

  const hideCar = () => {
    if (containerRef?.current?.querySelector('.car-list')?.classList?.value?.indexOf('show') !== -1) {
      setShowCarList(false);
      scrollTo();
    }
  };

  const scrollTo = (top = 0, left = 0, behavior = 'smooth') => {
    document.body.scrollTo({ top, left, behavior });
  };

  const versionChange = (e, item) => {
    item.version = e.target.value === '0' ? null : e.target.value;
    setSelectedList([...selectedList]);
  };

  const versionCheck = () => {
    selectedList.forEach((item) => {
      const filterList = selectedList.filter((item1) => item1.name === item.name && item1.version).map((item1) => item1.version);
      item.comparisonData.forEach((item1) => (item1.versionSelect = filterList.includes(item1.DeriveId.Value)));
    });
  };

  const removeCar = (item) => {
    selectedList.splice(selectedList.indexOf(item), 1);
    if (selectedList.length === 0) {
      scrollTo();
    }
    setSelectedList([...selectedList]);
  };

  const comparisonCheck = () => {
    const filterComparisonList = selectedList
      .filter((item) => item.version)
      .reduce((prev, cur) => [...prev, { ...cur.comparisonData.find((item) => item.DeriveId.Value === cur.version), name: cur.name }], []);
    setFilterComparisonList(filterComparisonList);
    setExist(filterComparisonList.length > 0);
  };

  return (
    <section className="model-comparison-v1" ref={containerRef}>
      <div className="title">车型对比</div>
      <div className="top-container">
        <ul className={`car-list${showCarList === null ? '' : showCarList ? ' show' : ' hide'}`}>
          {vehiclesData.length > 0 &&
            vehiclesData.map((item, index) => (
              <li className="car-list-item" key={index} onClick={(e) => selectCar(e, item)}>
                <div className="car-image-container">
                  <img alt={item.name} className="car-image" src={createFilePath(item.img)} />
                  <img alt={item.name} className="car-add-image" src={createFilePath('/model-comparison/car_add_on.png')} />
                </div>
                <div>
                  {item.name}
                  {isMobile && <img alt={item.name} className="car-arrow-image" src={createFilePath('/model-comparison/more.png')} />}
                </div>
                {!isMobile && <div className="car-price">{`指导价:${item.price}${item.priceonly ? '万元' : '万元起'}`}</div>}
              </li>
            ))}
        </ul>
        {selectedList?.length === 0 ? (
          <div
            className="add-car-container"
            style={{ background: `url(${createFilePath('/model-comparison/car_bg.png')}) center center no-repeat` }}
            onClick={addCar}
          >
            <img alt="广汽本田" src={createFilePath('/model-comparison/car_add.png')} />
            <div>添加对比车型</div>
          </div>
        ) : (
          <>
            <div className="added-car-position" />
            <div className={`added-car-container${fixed ? ' fixed' : ''}`}>
              <div className="added-car-container-in">
                {fixed ? (
                  <div className="added-car-title">车型对比</div>
                ) : (
                  !isMobile && (
                    <div className="setting-container">
                      <p className="setting-title">配置状况</p>
                      <p className="setting-item">
                        <b>●</b>表示装备
                      </p>
                      <p className="setting-item">
                        <b>-</b>表示未装备
                      </p>
                      <p className="setting-item">
                        <b>○</b>表示选装
                      </p>
                      <p className="right-checkout-box">
                        只显示不同
                        <span className={`slider${sliderOn ? ' on' : ''}`} onClick={() => setSliderOn(!sliderOn)}>
                          <input type="checkbox" />
                        </span>
                      </p>
                    </div>
                  )
                )}
                <ul className="added-car-list">
                  {selectedList.map((item, index) => {
                    const price = `指导价 : ${
                      item.version
                        ? item.comparisonData
                            .find((item1) => item1.DeriveId.Value === item.version)
                            .DerivePrice.Value.replace(/\uff08[^\）]*\uff09/g, '')
                        : '--元'
                    }`;
                    return (
                      <li className="added-car-list-item" key={index}>
                        {!fixed && <div className="added-car-name">{item.name}</div>}
                        {!fixed && <div className="added-car-price">{price}</div>}
                        <img alt="广汽本田" className="added-car-image" src={createFilePath(item.img)} />
                        <div className="added-car-select-container">
                          {fixed && <span className="added-car-select-name">{item.name}</span>}
                          <select
                            className="added-car-select"
                            style={{
                              background: isMobile
                                ? `url(${createFilePath('/model-comparison/down.png')}) no-repeat scroll right 0.1rem center transparent`
                                : 'none'
                            }}
                            value={item.version}
                            onChange={(e) => versionChange(e, item)}
                          >
                            <option value={0}>请选择版本</option>
                            {item?.comparisonData?.map((item1, index1) => (
                              <option
                                key={`${index}-${index1}`}
                                dangerouslySetInnerHTML={{ __html: item1.DeriveName.Value }}
                                value={item1.DeriveId.Value}
                                disabled={item1.versionSelect}
                              />
                            ))}
                          </select>
                        </div>
                        <img
                          alt="广汽本田"
                          className="added-car-close"
                          src={createFilePath(`/model-comparison/${fixed ? 'car-close4.png' : 'car-close2.png'}`)}
                          onClick={() => removeCar(item)}
                        />
                        {!fixed && (
                          <div className="added-car-bottom">
                            <a href={item.link}>了解详情</a>
                            <a href={item.driveLink}>预约试驾</a>
                          </div>
                        )}
                      </li>
                    );
                  })}
                  {selectedList.length < 3 && (
                    <div
                      className="added-car-list-item added-car-list-add-container"
                      style={{ background: `url(${createFilePath('/model-comparison/car_bg.png')}) center center no-repeat` }}
                    >
                      <img alt="广汽本田" src={createFilePath('/model-comparison/car_add.png')} onClick={addCar} />
                      <div>添加对比车型</div>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
      {isMobile && (
        <div className="setting-container">
          <p className="setting-title">配置状况</p>
          <p className="setting-item">
            <b>●</b>表示装备
          </p>
          <p className="setting-item">
            <b>-</b>表示未装备
          </p>
          <p className="setting-item">
            <b>○</b>表示选装
          </p>
          <p className="right-checkout-box">
            只显示不同
            <span className={`slider${sliderOn ? ' on' : ''}`} onClick={() => setSliderOn(!sliderOn)}>
              <input type="checkbox" />
            </span>
          </p>
        </div>
      )}
      <div className="content-container">
        <div
          className="tab-container"
          style={{
            background: isMobile ? `url(${createFilePath('/model-comparison/more2.png')}) no-repeat scroll right 0.1rem center #fff` : '#fff'
          }}
        >
          <ul className="tab-list">
            {tabData.length > 0 &&
              tabData.map((item, index) => (
                <li className={`tab-item${index === selectTabIndex ? ' on' : ''}`} key={index} onClick={() => setSelectTabIndex(index)}>
                  {item.Name}
                </li>
              ))}
          </ul>
        </div>
        <table className="comparison-table">
          {exist && (
            <thead>
              <tr>
                <th className="comparison-table-name">车型</th>
                {filterComparisonList.map((item, index) => (
                  <th key={index} dangerouslySetInnerHTML={{ __html: `${item.name}<br/>${item.DeriveName.Value}` }} />
                ))}
              </tr>
              <tr>
                <th className="comparison-table-name">指导价</th>
                {filterComparisonList.map((item, index) => (
                  <th key={index} dangerouslySetInnerHTML={{ __html: item.DerivePrice.Value }} />
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {tabData.length > 0 &&
              tabData[!exist ? 0 : selectTabIndex]?.KeyValueList?.map((item, index) => {
                if (exist && filterComparisonList.length > 1) {
                  filterComparisonList.map((item1, index1) => {
                    const value = item1?.CatalogueList[selectTabIndex]?.KeyValueList[index]?.Value;
                    let colSpan = 1;
                    for (let i = index1 + 1; i < filterComparisonList.length; i++) {
                      if (
                        value === filterComparisonList[i].CatalogueList[selectTabIndex]?.KeyValueList[index]?.Value &&
                        (selectTabIndex < 3 || value === '-' || sliderOn)
                      ) {
                        colSpan++;
                        continue;
                      }
                      break;
                    }
                    item1.colSpan = colSpan;
                  });
                  if (
                    filterComparisonList.length === filterComparisonList[0].colSpan &&
                    (sliderOn || filterComparisonList[0].CatalogueList[selectTabIndex]?.KeyValueList[index]?.Value === '-')
                  ) {
                    return null;
                  }
                }
                return (
                  <tr key={index}>
                    <td className="comparison-table-name" dangerouslySetInnerHTML={{ __html: item }} />
                    {!exist && index === 0 ? (
                      <td>
                        <img
                          alt="广汽本田"
                          className="comparison-table-add"
                          src={createFilePath('/model-comparison/car_add_on.png')}
                          onClick={addCar}
                        />
                      </td>
                    ) : exist ? (
                      filterComparisonList.map((item1, index1) => {
                        const value = item1?.CatalogueList[selectTabIndex]?.KeyValueList[index]?.Value;
                        if (
                          (index1 > 0 && filterComparisonList[index1 - 1].colSpan > 1) ||
                          (filterComparisonList.length === item1.colSpan && (sliderOn || value === '-'))
                        ) {
                          return null;
                        }
                        return <td colSpan={item1.colSpan} key={`${index}-${index1}`} dangerouslySetInnerHTML={{ __html: value }} />;
                      })
                    ) : (
                      <td />
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <p className="explain" dangerouslySetInnerHTML={{ __html: explain }} />
    </section>
  );
}
