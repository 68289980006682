import React, { useImperativeHandle, useEffect } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function index(props) {
  const isMobile = isMobileFun();
  const vechilesList = props?.fields?.vechilesList?.map((item) => ({ ...item.fields }));
  const id = props?.params?.pagename;
  const itemClick = (item) => {
    if (isMobile) {
      window.open(item?.vehicleDetailLink?.value, '_self');
      window.Dmp.clickAction(item?.vehicleCarDmp?.value, item?.vehicleDetailLink?.value);
    }
  };
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    console.log(props.eventRef);
    window.Dmp.sectionAction(id);
  };

  return (
    <section className="vehicles-list-v1">
      <div className="title">车型总览</div>
      <ul className="list">
        {vechilesList.map((item, index) => (
          <li className="item" key={index}>
            <p className="name">{item?.displayName?.value}</p>
            <div className="card" onClick={() => itemClick(item)}>
              <img alt="广汽本田" className="image" src={createFilePath(item?.indexImage?.value)} />
              <div className="detail">
                <p className="price">
                  {isMobile ? '' : item?.priceText?.value}
                  <span>{item?.price?.value}</span> {item?.priceonly?.value ? '万元' : '万元起'}
                </p>
                {!isMobile && (
                  <p className="link">
                    <a href={item?.vehicleDetailLink?.value} data-html-item={item?.vehicleCarDmp?.value} data-url={item?.vehicleDetailLink?.value}>
                      查看车型<span>&gt;</span>
                    </a>
                    <a href={item?.vehicleDriveLink?.value} data-html-item={item?.vehicleDriveDmp?.value} data-url={item?.vehicleDriveLink?.value}>
                      预约试驾<span>&gt;</span>
                    </a>
                  </p>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}
