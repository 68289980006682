import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import './style.scss';
import temp from '../../temp/config';
import { getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';
import { isMobile as isDeviceMobile } from '../../utils/dom-utils';

export default function Power(props) {
  const { fields } = props;
  const [num, setNum] = useState(0);
  const [num2, setNum2] = useState(0);
  const powerEl = useRef(null);
  const [thisMobile, setThisMobile] = useState(!isMobileFun());
  const [windowHeight, setWindowHeight] = useState(400);

  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
    const queryTabDefault = (value) => {
      let defaultIndex = 0;
      fields.tabsItem.map((item, index) => {
        if (item.fields.defaultValue.value === value) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };

    setTimeout(() => {
      switch (getQueryVariable('tab')) {
        case 'hyrid':
          setNum(queryTabDefault('hyrid'));
          break;
        case 'plugin':
          setNum(queryTabDefault('plugin'));
          break;
        case 'oil':
          setNum(queryTabDefault('oil'));
          break;
        case 'control':
          setNum(queryTabDefault('control'));
          break;
        default:
          setNum(queryTabDefault(fields?.defaultValue?.value));
      }
    }, 2000);
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
      setThisMobile(!isDeviceMobile());
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    window.Dmp.sectionAction('price_power');
  };

  return (
    <Controller>
      <Scene triggerHook={1} duration={windowHeight}>
        {(progress, event) => {
          return (
            <div>
              <Timeline totalProgress={progress}>
                <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={1}>
                  <div className="powerBox" id="price_power" ref={powerEl}>
                    <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={thisMobile ? 2 : 3}>
                      <div className="powerTab">
                        {fields.tabsItem.map((item, index) => {
                          return (
                            <div
                              key={item.id}
                              data-action={item?.fields?.dataAction?.value}
                              data-item={item?.fields?.dataItem?.value}
                              className={`${num === index ? 'active' : ''}`}
                              onClick={() => {
                                setNum(index);
                                setNum2(0);
                              }}
                            >
                              {item.fields.title.value}
                            </div>
                          );
                        })}
                      </div>
                    </Tween>
                    {fields.tabsItem.map((ele, i) => {
                      return (
                        i === num && (
                          <div className="powerBoxContent" key={i}>
                            <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={thisMobile ? 3 : 4}>
                              <div className="powerContent">
                                {ele.fields.children2.map((item, index) => {
                                  return (
                                    index === num2 && (
                                      <div className="powerTabsContent" key={item.id}>
                                        {thisMobile ? (
                                          <React.Fragment>
                                            <div className="powerContent_image">
                                              <img src={`${createFilePath(item.fields.img.value)}`} alt="power" />
                                            </div>
                                            <div
                                              className="powerContent_font"
                                              dangerouslySetInnerHTML={{ __html: item.fields.value.value?.replaceHtml() }}
                                            />
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <div
                                              className="powerContent_font"
                                              dangerouslySetInnerHTML={{ __html: item.fields.value.value?.replaceHtml() }}
                                            />
                                            <div className="powerContent_image">
                                              <img src={`${createFilePath(item.fields.img.value)}`} alt="power" />
                                            </div>
                                          </React.Fragment>
                                        )}
                                      </div>
                                    )
                                  );
                                })}
                              </div>
                            </Tween>
                            <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={thisMobile ? 2 : 3}>
                              <div className="powerFooterTab">
                                {fields.tabsItem[num].fields[getPrefixField('children')].map((item, index) => {
                                  if (item.fields.value.value !== '') {
                                    return (
                                      <div
                                        key={item.id}
                                        data-action={item?.fields?.dataAction?.value}
                                        data-item={item?.fields?.dataItem?.value}
                                        className={`${num2 === index ? 'active' : ''}`}
                                        onClick={(e) => {
                                          if (!thisMobile) {
                                            e.target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
                                          }
                                          setNum2(index);
                                        }}
                                        dangerouslySetInnerHTML={{ __html: item.fields.value.value?.replaceHtml() }}
                                      />
                                    );
                                  }
                                })}
                              </div>
                            </Tween>
                            {fields.tabsItem[num].fields.iMMDButton.value ? (
                              <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={thisMobile ? 2 : 3}>
                                <div className="powerBoxButton">
                                  <button data-action="clickAction" data-item="price_power-plugin-iMMD" data-url="https://i-mmd-lab.ghac.cn/">
                                    探索先进技术
                                    <a
                                      data-action="clickAction"
                                      data-item="price_power-plugin-iMMD"
                                      data-url={fields?.iMMDURL?.value}
                                      onClick={() => {
                                        window.open(fields?.iMMDURL?.value);
                                      }}
                                    />
                                  </button>
                                </div>
                              </Tween>
                            ) : null}
                          </div>
                        )
                      );
                    })}
                  </div>
                </Tween>
              </Timeline>
            </div>
          );
        }}
      </Scene>
    </Controller>
  );
}
