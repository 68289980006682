import React from 'react';
import classNames from 'classnames';
import './style.scss';

// <Button disabled>hello</Button>
// <Button autoFocus size={ButtonSize.Large}>Large</Button>
// <Button className="custom" onClick={()=>{console.log(123);
// }} size={ButtonSize.Small}>Small</Button>
// <Button target="_blank" btnType={ButtonType.Link} href="http://www.baidu.com">baidu link</Button>
// <Button btnType={ButtonType.Danger} >Danger</Button>
// <Button btnType={ButtonType.Default} >Danger</Button>
// <Button btnType={ButtonType.Primary} >Primary</Button>

const Button = (props) => {
  const { className, btnType, disabled, size, children, href, ...restProps } = props;
  const classes = classNames('btn', className, {
    [`btn-${btnType}`]: btnType,
    [`btn-${size}`]: size,
    disabled: btnType && disabled
  });
  if (btnType && href) {
    return (
      <a className={classes} href={href} {...restProps}>
        {children}
      </a>
    );
  } else {
    return (
      <button className={classes} disabled={disabled}>
        {children}
      </button>
    );
  }
};
Button.defaultProps = {
  disabled: false,
  btnType: 'Default'
};
export default Button;
