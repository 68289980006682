import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import Detailss from '../../elements/InnerPageDetail';
import { isMobileFun, getPrefixField, convertStylesStringToObject, createFilePath } from '../../utils/obj-utils';
import 'swiper/swiper.min.css';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const [flag, setFlag] = useState(false);
  const [btnindex, setBtnindex] = useState(0);
  const swiperRef = useRef(null);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename;

  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };

  return (
    <>
      <div className="Price_space_v2" id={id}>
        <div className="hash"></div>
        <div className="con">
          {fields[`${getPrefixField('bg', isMobile)}`]?.value && (
            <img
              className="bg"
              style={convertStylesStringToObject(fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
              src={createFilePath(fields[`${getPrefixField('bg', isMobile)}`]?.value)}
              alt="广汽本田"
            />
          )}
          {fields[`${getPrefixField('bghtml', isMobile)}`]?.value && (
            <div
              style={convertStylesStringToObject(fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
              dangerouslySetInnerHTML={{
                __html: fields[`${getPrefixField('bghtml', isMobile)}`]?.value?.replaceHtml()
              }}
            ></div>
          )}
          <div className="in">
            {fields[`${getPrefixField('text', isMobile)}`]?.value && (
              <div dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}></div>
            )}
            <div className="bottom" style={convertStylesStringToObject(fields[`${getPrefixField('bottomstyle', isMobile)}`]?.value)}>
              {fields?.btnlists.length > 0 &&
                fields?.btnlists.map((item, index) => {
                  return (
                    <div
                      className={`btn_v1 btn_v1_${index + 1}`}
                      key={index}
                      style={convertStylesStringToObject(item?.fields[`${getPrefixField('btnstyle', isMobile)}`]?.value)}
                      dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('btn', isMobile)}`]?.value?.replaceHtml() }}
                      onClick={() => {
                        setFlag(true);
                        setBtnindex(index);
                        window.Dmp.clickAction(item?.fields?.dmp?.value, createFilePath(item?.fields?.videourl?.value));
                      }}
                    ></div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {flag && (
        <Detailss
          routePath={fields?.btnlists[btnindex]?.fields?.detailurl?.value}
          {...fields?.btnlists[btnindex]?.fields}
          close={() => setFlag(false)}
        />
      )}
    </>
  );
}
