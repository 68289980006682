import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, getQueryString, scrollToAnchor, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import CountUp from 'react-countup';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import temp from '../../temp/config';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const jsonData = JSON.parse(fields?.jsonData?.value);
  // const jsonData = {
  //   "title": "旗舰燃油动力",
  //   "car": "/avancier/v2/pc/power/car.png",
  //   "wheelbefore": "/avancier/v2/pc/power/wheelbefore.png",
  //   "wheelafter": "/avancier/v2/pc/power/wheelafter.png",
  //   "wheelbefore1": "/avancier/v2/pc/power/wheelbefore1.png",
  //   "wheelafter1": "/avancier/v2/pc/power/wheelafter1.png",
  //   "tab1": {
  //     "icon": "/avancier/v2/pc/power/370.png",
  //     "iconblack": "/avancier/v2/pc/power/370_black.png",
  //     "text": "<b>殿堂级动力总成</b><br /><span>燃动旗舰风范</span>",
  //     "t": "370 TURBO",
  //     "itemdmp":"price_power1-btn1"
  //   },
  //   "tab2": {
  //     "icon": "/avancier/v2/pc/power/240.png",
  //     "iconblack": "/avancier/v2/pc/power/240_black.png",
  //     "text": "<b>傲领同级的强大动力</b><br /><span>燃擎澎湃驾趣</span>",
  //     "t": "240 TURBO",
  //     "itemdmp":"price_power1-btn2"
  //   },
  //   "power1": {
  //     "icon": "/avancier/v2/pc/power/power1.png",
  //     "n1": "最大功率",
  //     "num1": "192",
  //     "kw": "kW /",
  //     "num2": "6500",
  //     "rpm": "rpm",
  //     "n2": "最大扭矩",
  //     "num3": "370",
  //     "nm": "N·m /",
  //     "num4": "2250",
  //     "line": "-",
  //     "num5": "3500",
  //     "p": "<b>2.0T 直喷i-VETC涡轮增压发动机 +9速自动变速器</b><br />高能动力与高效油耗，让每一次加速皆是愉悦驾享。"
  //   },
  //   "power2": {
  //     "icon": "/avancier/v2/pc/power/power2.png",
  //     "n1": "最大功率",
  //     "num1": "138",
  //     "kw": "kW /",
  //     "num2": "5600",
  //     "rpm": "rpm",
  //     "n2": "最大扭矩",
  //     "num3": "243",
  //     "nm": "N·m /",
  //     "num4": "2000",
  //     "line": "-",
  //     "num5": "4500",
  //     "p": "<b>1.5T直喷i-VETC涡轮增压发动机+CVT无级变速器</b><br>流畅的线性操控，动静间爆发澎湃驾趣。"
  //   },
  //   "m_title": "旗舰燃油动力",
  //   "m_car": "/avancier/v2/pc/power/car.png",
  //   "m_wheelbefore": "/avancier/v2/pc/power/wheelbefore.png",
  //   "m_wheelafter": "/avancier/v2/pc/power/wheelafter.png",
  //   "m_wheelbefore1": "/avancier/v2/pc/power/wheelbefore1.png",
  //   "m_wheelafter1": "/avancier/v2/pc/power/wheelafter1.png",
  //   "m_tab1": {
  //     "icon": "/avancier/v2/mobile/power/370.png",
  //     "iconblack": "/avancier/v2/mobile/power/370_black.png",
  //     "text": "<b>殿堂级动力总成</b><br /><span>燃动旗舰风范</span>",
  //     "t": "370 TURBO",
  //     "itemdmp":"price_power1-btn1"
  //   },
  //   "m_tab2": {
  //     "icon": "/avancier/v2/mobile/power/240.png",
  //     "iconblack": "/avancier/v2/mobile/power/240_black.png",
  //     "text": "<b>傲领同级的强大动力</b><br /><span>燃擎澎湃驾趣</span>",
  //     "t": "240 TURBO",
  //     "itemdmp":"price_power1-btn2"
  //   },
  //   "m_power1": {
  //     "icon": "/avancier/v2/pc/power/power1.png",
  //     "n1": "最大功率",
  //     "num1": "192",
  //     "kw": "kW /",
  //     "num2": "6500",
  //     "rpm": "rpm",
  //     "n2": "最大扭矩",
  //     "num3": "370",
  //     "nm": "N·m /",
  //     "num4": "2250",
  //     "line": "-",
  //     "num5": "3500",
  //     "p": "<b>2.0T 直喷i-VETC涡轮增压发动机 +9速自动变速器</b><br />高能动力与高效油耗，让每一次加速皆是愉悦驾享。"
  //   },
  //   "m_power2": {
  //     "icon": "/avancier/v2/pc/power/power2.png",
  //     "n1": "最大功率",
  //     "num1": "138",
  //     "kw": "kW /",
  //     "num2": "5600",
  //     "rpm": "rpm",
  //     "n2": "最大扭矩",
  //     "num3": "243",
  //     "nm": "N·m /",
  //     "num4": "2000",
  //     "line": "-",
  //     "num5": "4500",
  //     "p": "<b>1.5T直喷i-VETC涡轮增压发动机+CVT无级变速器</b><br>流畅的线性操控，动静间爆发澎湃驾趣。"
  //   }
  // }
  const [windowHeight, setWindowHeight] = useState(400);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename ? props?.params?.pagename : 'price_power1';
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id;
  };

  return (
    <div className="price-power-v6" id={id}>
      <div className="price-power-item1" id="price-power-item1"></div>
      <div className="price-power-item2" id="price-power-item2"></div>
      <Controller>
        <Scene triggerHook={'onLeave'} duration={isMobile ? windowHeight * 1 : windowHeight * 5} pin={true} reverse={true} triggerElement={'#' + id}>
          {(progress, event) => {
            return (
              <div className="in">
                <Timeline
                  totalProgress={progress}
                  paused
                  target={
                    <>
                      <div className="title" dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('title', isMobile)]?.replaceHtml() }}></div>
                      <div className="sub">
                        {isMobile && (
                          <>
                            <div className={`item ${progress < 0.5 ? 'on' : ''}`}>
                              <img alt="广汽本田" className="icon" src={`${createFilePath(jsonData[getPrefixField('tab1', isMobile)]?.iconblack)}`} />
                              <div
                                className="p"
                                dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('tab1', isMobile)]?.text?.replaceHtml() }}
                              ></div>
                            </div>
                            <div className={`item ${progress >= 0.5 ? 'on' : ''}`}>
                              <img alt="广汽本田" className="icon" src={`${createFilePath(jsonData[getPrefixField('tab2', isMobile)]?.iconblack)}`} />
                              <div
                                className="p"
                                dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('tab2', isMobile)]?.text?.replaceHtml() }}
                              ></div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className={`con con1 ${isMobile && progress < 0.5 ? 'show' : ''}`}>
                        <img
                          alt="广汽本田"
                          className={`power1 animate__not ${
                            !isMobile
                              ? progress >= 0.15 && progress <= 0.59
                                ? 'animate__fadeInDownBig animate__animated'
                                : progress > 0.59 && progress <= 1
                                ? 'animate__fadeOutUp animate__animated'
                                : ''
                              : 'animate__animated'
                          } `}
                          src={`${createFilePath(jsonData[getPrefixField('power1', isMobile)]?.icon)}`}
                        />
                        <div
                          className={`text animate__not ${
                            !isMobile
                              ? progress >= 0.15 && progress <= 0.59
                                ? 'animate__fadeInDown animate__animated'
                                : progress > 0.59 && progress <= 1
                                ? 'animate__fadeOutUpBig animate__animated'
                                : ''
                              : 'animate__animated'
                          } `}
                        >
                          <div className="item">
                            <div className="n">{jsonData[getPrefixField('power1', isMobile)]?.n1}</div>
                            <div className="val">
                              {(!isMobile ? progress >= 0.15 && progress <= 0.59 : progress >= 0.56 && progress <= 1) ? (
                                <CountUp duration={1.6} useGrouping={false} start={0} end={jsonData[getPrefixField('power1', isMobile)]?.num1} />
                              ) : (
                                jsonData[getPrefixField('power1', isMobile)]?.num1
                              )}
                              {jsonData[getPrefixField('power1', isMobile)]?.kw}
                              {(!isMobile ? progress >= 0.15 && progress <= 0.59 : progress >= 0.56 && progress <= 1) ? (
                                <CountUp duration={1.6} useGrouping={false} start={0} end={jsonData[getPrefixField('power1', isMobile)]?.num2} />
                              ) : (
                                jsonData[getPrefixField('power1', isMobile)]?.num2
                              )}
                              {jsonData[getPrefixField('power1', isMobile)]?.rpm}
                            </div>
                          </div>
                          <div className="item">
                            <div className="n">{jsonData[getPrefixField('power1', isMobile)]?.n2}</div>
                            <div className="val">
                              {(!isMobile ? progress >= 0.15 && progress <= 0.59 : progress >= 0.56 && progress <= 1) ? (
                                <CountUp duration={1.6} useGrouping={false} start={0} end={jsonData[getPrefixField('power1', isMobile)]?.num3} />
                              ) : (
                                jsonData[getPrefixField('power1', isMobile)]?.num3
                              )}
                              {jsonData[getPrefixField('power1', isMobile)]?.nm}
                              {(!isMobile ? progress >= 0.15 && progress <= 0.59 : progress >= 0.56 && progress <= 1) ? (
                                <CountUp duration={1.6} useGrouping={false} start={0} end={jsonData[getPrefixField('power1', isMobile)]?.num4} />
                              ) : (
                                jsonData[getPrefixField('power1', isMobile)]?.num4
                              )}
                              {jsonData[getPrefixField('power1', isMobile)]?.line}
                              {(!isMobile ? progress >= 0.15 && progress <= 0.59 : progress >= 0.56 && progress <= 1) ? (
                                <CountUp duration={1.6} useGrouping={false} start={0} end={jsonData[getPrefixField('power1', isMobile)]?.num5} />
                              ) : (
                                jsonData[getPrefixField('power1', isMobile)]?.num5
                              )}
                              {jsonData[getPrefixField('power1', isMobile)]?.rpm}
                            </div>
                          </div>
                          <div
                            className="p"
                            dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('power1', isMobile)]?.p?.replaceHtml() }}
                          ></div>
                        </div>
                      </div>
                      <div className={`con con2 ${isMobile && progress >= 0.5 ? 'show' : ''}`}>
                        <img
                          alt="广汽本田"
                          className={`power2 animate__not  ${
                            !isMobile ? (progress > 0.8 ? 'animate__animated animate__fadeInDown' : '') : 'animate__animated'
                          } `}
                          src={`${createFilePath(jsonData[getPrefixField('power2', isMobile)]?.icon)}`}
                        />

                        <div
                          className={`text animate__not ${
                            !isMobile ? (progress > 0.85 ? 'animate__animated animate__fadeInDownBig' : '') : 'animate__animated'
                          } `}
                        >
                          <div className="item">
                            <div className="n">{jsonData[getPrefixField('power2', isMobile)]?.n1}</div>
                            <div className="val">
                              {(!isMobile ? progress > 0.85 : progress > 0.05) ? (
                                <CountUp duration={1.6} useGrouping={false} start={0} end={jsonData[getPrefixField('power2', isMobile)]?.num1} />
                              ) : (
                                jsonData[getPrefixField('power2', isMobile)]?.num1
                              )}
                              {jsonData[getPrefixField('power2', isMobile)]?.kw}
                              {(!isMobile ? progress > 0.85 : progress > 0.05) ? (
                                <CountUp duration={1.6} useGrouping={false} start={0} end={jsonData[getPrefixField('power2', isMobile)]?.num2} />
                              ) : (
                                jsonData[getPrefixField('power2', isMobile)]?.num2
                              )}
                              {jsonData[getPrefixField('power2', isMobile)]?.rpm}
                            </div>
                          </div>
                          <div className="item">
                            <div className="n">{jsonData[getPrefixField('power2', isMobile)]?.n2}</div>
                            <div className="val">
                              {(!isMobile ? progress > 0.85 : progress > 0.05) ? (
                                <CountUp duration={1.6} useGrouping={false} start={0} end={jsonData[getPrefixField('power2', isMobile)]?.num3} />
                              ) : (
                                jsonData[getPrefixField('power2', isMobile)]?.num3
                              )}
                              {jsonData[getPrefixField('power2', isMobile)]?.nm}
                              {(!isMobile ? progress > 0.85 : progress > 0.05) ? (
                                <CountUp duration={1.6} useGrouping={false} start={0} end={jsonData[getPrefixField('power2', isMobile)]?.num4} />
                              ) : (
                                jsonData[getPrefixField('power2', isMobile)]?.num4
                              )}
                              {jsonData[getPrefixField('power2', isMobile)]?.line}
                              {(!isMobile ? progress > 0.85 : progress > 0.05) ? (
                                <CountUp duration={1.6} useGrouping={false} start={0} end={jsonData[getPrefixField('power2', isMobile)]?.num5} />
                              ) : (
                                jsonData[getPrefixField('power2', isMobile)]?.num5
                              )}
                              {jsonData[getPrefixField('power2', isMobile)]?.rpm}
                            </div>
                          </div>
                          <div
                            className="p"
                            dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('power2', isMobile)]?.p?.replaceHtml() }}
                          ></div>
                        </div>
                      </div>
                      <div className="car">
                        <img alt="广汽本田" className="carimg" src={`${createFilePath(jsonData[getPrefixField('car', isMobile)])}`} />
                        {(
                          !isMobile ? (progress >= 0.1 && progress < 0.3) || (progress >= 0.6 && progress < 0.95) : progress >= 0.1 && progress < 0.9
                        ) ? (
                          <>
                            <img
                              alt="广汽本田"
                              className={`wheelafter ${isMobile && progress >= 0.1 && progress < 0.9 ? 'acivite' : ''} ${
                                !isMobile && progress >= 0.1 && progress < 0.3 ? 'acivite' : ''
                              } ${!isMobile && progress >= 0.6 && progress < 0.95 ? 'acivite1' : ''}`}
                              src={`${createFilePath(jsonData[getPrefixField('wheelafter1', isMobile)])}`}
                            />
                            <img
                              alt="广汽本田"
                              className={`wheelbefore ${isMobile && progress >= 0.1 && progress < 0.9 ? 'acivite' : ''} ${
                                !isMobile && progress >= 0.1 && progress < 0.3 ? 'acivite' : ''
                              } ${!isMobile && progress >= 0.6 && progress < 0.95 ? 'acivite1' : ''}`}
                              src={`${createFilePath(jsonData[getPrefixField('wheelbefore1', isMobile)])}`}
                            />
                          </>
                        ) : (
                          <>
                            <img alt="广汽本田" className="wheelafter" src={`${createFilePath(jsonData[getPrefixField('wheelafter', isMobile)])}`} />
                            <img
                              alt="广汽本田"
                              className="wheelbefore"
                              src={`${createFilePath(jsonData[getPrefixField('wheelbefore', isMobile)])}`}
                            />
                          </>
                        )}
                      </div>
                      <div className={progress < 0.25 ? 'tabs' : 'tabs black'}>
                        <div
                          className={(isMobile ? progress < 0.5 : progress <= 0.45) ? 'item on' : 'item'}
                          data-html-item={jsonData[getPrefixField('tab1', isMobile)]?.itemdmp}
                          onClick={() => scrollToAnchor('price-power-item1', 'end')}
                          data={progress}
                        >
                          {isMobile ? (
                            <div
                              className="p"
                              dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('tab1', isMobile)]?.t?.replaceHtml() }}
                            ></div>
                          ) : (
                            <>
                              {progress < 0.3 ? (
                                <img alt="广汽本田" className="icon" src={`${createFilePath(jsonData[getPrefixField('tab1', isMobile)]?.icon)}`} />
                              ) : (
                                <img
                                  alt="广汽本田"
                                  className="icon"
                                  src={`${createFilePath(jsonData[getPrefixField('tab1', isMobile)]?.iconblack)}`}
                                />
                              )}
                              <div
                                className="p"
                                dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('tab1', isMobile)]?.text?.replaceHtml() }}
                              ></div>
                            </>
                          )}
                        </div>
                        <div
                          className={(isMobile ? progress >= 0.5 : progress > 0.8) ? 'item on' : 'item'}
                          data-html-item={jsonData[getPrefixField('tab2', isMobile)]?.itemdmp}
                          onClick={() => scrollToAnchor('price-power-item2', 'end')}
                        >
                          {isMobile ? (
                            <div
                              className="p"
                              dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('tab2', isMobile)]?.t?.replaceHtml() }}
                            ></div>
                          ) : (
                            <>
                              {progress < 0.3 ? (
                                <img alt="广汽本田" className="icon" src={`${createFilePath(jsonData[getPrefixField('tab2', isMobile)]?.icon)}`} />
                              ) : (
                                <img
                                  alt="广汽本田"
                                  className="icon"
                                  src={`${createFilePath(jsonData[getPrefixField('tab2', isMobile)]?.iconblack)}`}
                                />
                              )}
                              <div
                                className="p"
                                dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('tab2', isMobile)]?.text?.replaceHtml() }}
                              ></div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="bg"></div>
                    </>
                  }
                >
                  {!isMobile ? (
                    <>
                      <Tween position={0.1} to={{ color: '#000' }} duration={0.2} target={0} />
                      <Tween position={0.15} from={{ opacity: 0 }} to={{ display: 'block', opacity: 1 }} duration={0.1} target={2} />
                      <Tween position={0.24} to={{ opacity: 0 }} duration={0.1} target={2} />
                      <Tween position={0.35} to={{ display: 'none' }} duration={0.05} target={2} />
                      <Tween position={0.4} from={{ opacity: 0 }} to={{ display: 'block', opacity: 1 }} duration={0.1} target={3} />
                      <Tween position={0.05} to={{ x: '-102%' }} duration={0.1} target={4} />
                      <Tween position={0.3} to={{ x: '98%' }} duration={0.2} target={4} />
                      <Tween position={0.15} from={{ color: '#fff' }} to={{ color: '#000' }} duration={0.1} target={5} />
                      <Tween position={0.1} to={{ y: '100%' }} duration={0.1} target={6} />
                    </>
                  ) : (
                    <>
                      <Tween position={0} from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.1} target={0} />
                      <Tween position={0} from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.1} target={1} />
                      <Tween position={0} from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.1} target={2} />
                      <Tween position={0} from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.1} target={3} />
                      <Tween position={0} from={{ x: '40%' }} to={{ x: '-70%' }} duration={1} target={4} />
                      <Tween position={0.6} to={{}} duration={0.3} target={3} />
                    </>
                  )}
                </Timeline>
              </div>
            );
          }}
        </Scene>
      </Controller>
    </div>
  );
}
