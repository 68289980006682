/* eslint-disable no-bitwise */
import React, { useEffect, useState, useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { isEmpty, createFilePath, getPrefixField, checkMobile, getQueryString, isMobileFun } from '../../utils/obj-utils';
import useIsomorphicLayoutEffect from '../../utils/useIsomorphicLayoutEffect';
import { dataFetcher, getJsonp } from '../../dataFetcher';
import FullModalDetail from '../../elements/FullModalDetail_v1';

export default function index({ fields }) {
  const isMobile = isMobileFun();
  const containerRef = useRef(null);
  const usernameRef = useRef(null);
  const ageRef = useRef(null);
  const mobileRef = useRef(null);
  const emailRef = useRef(null);
  const contentRef = useRef(null);
  const sexData = useRef([
    { name: '先生', value: '男' },
    { name: '女士', value: '女' }
  ]);
  const ownData = useRef([
    { name: '是', value: '0' },
    { name: '否', value: '1' }
  ]);
  const [agree, setAgree] = useState(false);
  const [location, setLocation] = useState({ province: '广东省', city: '广州市' });
  const [originalDealerData, setOriginalDealerData] = useState();
  const [provinceData, setProvinceData] = useState();
  const [selectProvince, setSelectProvince] = useState();
  const [selectCity, setSelectCity] = useState();
  const [selectDealer, setSelectDealer] = useState();
  const [showForm, setShowForm] = useState(false);
  const [userInfo, setUserInfo] = useState({ sex: sexData?.current[0]?.value, ownyes: ownData?.current[0]?.value, rootin: '0' });
  const [fileList, setFileList] = useState([]);
  const [showAdd, setShowAdd] = useState(true);

  useIsomorphicLayoutEffect(() => {
    getLocation();
    getOriginalDealer();
  }, []);

  useEffect(() => {
    if (originalDealerData) {
      setProvinceData(filterDealerData());
    }
  }, [originalDealerData]);

  useEffect(() => {
    if (provinceData && location) {
      const province = provinceData?.find((item) => location.province.includes(item.province_name));
      const city = province?.Citys?.find((item) => location.city.includes(item.city_name));
      setSelectProvince(province);
      setSelectCity(city);
    }
  }, [provinceData, location]);

  useEffect(() => {
    setSelectDealer();
  }, [selectCity, selectProvince]);

  const getLocation = () => {
    getJsonp('https://restapi.amap.com/v3/ip?key=d4db88c544bf23a3a363f7ff8bcbf783&output=json')
      .then((response) => {
        if (response.status === '1') {
          if (response?.province?.length > 0 && response?.city?.length > 0) {
            setLocation({ province: response.province, city: response.city });
          }
        } else {
          return Promise.reject();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getOriginalDealer = () => {
    dataFetcher('/api/honda/v1/Leads/GetAllDealerProCitys')
      .then(({ data }) => {
        if (data.Status) {
          setOriginalDealerData({ ...data?.Data });
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addUserInfo = () => {
    dataFetcher('/api/honda/v1/Activity/AddClupUserInfo', userInfo)
      .then(({ data }) => {
        if (data?.Status) {
          alert('提交成功！');
          userInfo.userName = '';
          userInfo.sex = sexData?.current[0]?.value;
          userInfo.age = '';
          userInfo.mobile = '';
          userInfo.email = '';
          userInfo.ownyes = ownData?.current[0]?.value;
          userInfo.content = '';
          userInfo.provinceId = '';
          userInfo.cityId = '';
          userInfo.dealerId = '';
          userInfo.files = '';
          setSelectProvince();
          setSelectCity();
          setSelectDealer();
          setFileList([]);
          setAgree(false);
          setUserInfo({ ...userInfo });
          usernameRef.current.value = '';
          ageRef.current.value = '';
          mobileRef.current.value = '';
          emailRef.current.value = '';
          contentRef.current.value = '';
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        alert('提交失败！');
        console.error(error);
      });
  };

  const uploadFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const formData = new FormData();
      formData.append('myfile', file);
      dataFetcher('/api/honda/v1/Activity/UploadSingleFile', formData)
        .then(({ data }) => {
          if (data?.Status) {
            if (fileList.length < 5) {
              fileList.push({ id: data?.Data, image: e.target.result });
              setFileList([...fileList]);
            }
            setShowAdd(fileList.length < 5);
          } else {
            return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
          }
        })
        .catch((error) => {
          alert('上传失败！');
          console.error(error);
        });
    };
  };

  const filterDealerData = () => {
    const originalData = JSON.parse(JSON.stringify(originalDealerData));
    return reduceDealerData(originalData);
  };

  const reduceDealerData = (data) => {
    data?.Provinces?.forEach((item1) => {
      data?.Citys?.forEach((item2) => {
        item2.Dealers = data?.Dealers?.filter((item3) => item3.city_id === item2.city_id);
      });
      item1.Citys = data?.Citys?.filter((item2) => item2.province_id === item1.province_id);
    });
    return [...data?.Provinces];
  };

  const usernameChange = (e) => {
    userInfo.userName = e.target.value;
  };

  const ageChange = (e) => {
    userInfo.age = e.target.value;
  };

  const sexChange = (item) => {
    setUserInfo({ ...userInfo, sex: item.value });
  };

  const mobileChange = (e) => {
    userInfo.mobile = e.target.value;
  };

  const emailChange = (e) => {
    userInfo.email = e.target.value;
  };

  const provinceChange = (e) => {
    setSelectProvince(provinceData?.find((item) => item.province_id === e.target.value));
    setSelectCity();
  };

  const cityChange = (e) => {
    setSelectCity(selectProvince?.Citys?.find((item) => item.city_id === e.target.value));
  };

  const ownChange = (item) => {
    setUserInfo({ ...userInfo, ownyes: item.value });
  };

  const dealerChange = (e) => {
    setSelectDealer(selectCity?.Dealers?.find((item) => item.dealer_id === e.target.value));
  };

  const contentChange = (e) => {
    userInfo.content = e.target.value;
  };

  const addImage = (e) => {
    uploadFile(e.target.files[0]);
  };

  const agreeChange = () => {
    setAgree(!agree);
  };

  const submit = () => {
    if (isEmpty(userInfo?.userName)) {
      alert('请输入姓名');
      usernameRef?.current?.focus();
      return;
    }
    if (!checkMobile(userInfo?.mobile)) {
      alert('不是完整的11位手机号');
      mobileRef?.current?.focus();
      return;
    }
    if (!/^([\.a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/.test(userInfo?.email)) {
      alert('请输入正确邮箱地址');
      emailRef?.current?.focus();
      return;
    }
    if (!selectProvince) {
      alert('请选择省份');
      return;
    }
    if (!selectCity) {
      alert('请选择城市');
      return;
    }
    if (!selectDealer) {
      alert('请选择特约店');
      return;
    }
    if (isEmpty(userInfo?.content) || userInfo?.content?.length < 30 || userInfo?.content?.length > 500) {
      alert('经历或者故事不少于30个字或者大于500个字');
      contentRef?.current?.focus();
      return;
    }
    if (!agree) {
      alert('请确认广汽本田隐私政策');
      return;
    }
    userInfo.provinceId = selectProvince?.province_id;
    userInfo.cityId = selectCity?.city_id;
    userInfo.dealerId = selectDealer?.dealer_id;
    userInfo.files = fileList?.map((item) => item.id).join(',');
    addUserInfo();
  };

  const showModal = () => {
    setShowForm(true);
  };

  const close = () => {
    setShowForm(false);
  };

  return (
    <section className="share-v1" ref={containerRef}>
      <button className="share-button" onClick={showModal}>
        <img alt="广汽本田" src={createFilePath('/car-owners-story/jiahao.png')} />
        分享故事，&nbsp;&nbsp;参与活动
        <br />
        加入车主故事圈
      </button>
      {showForm && (
        <FullModalDetail style={{ background: 'rgba(0, 0, 0, 0.6)' }}>
          <div className="share-v1-modal">
            <div className="form-container">
              <img alt="广汽本田" className="close" src={createFilePath('/car-owners-story/pc_close.png')} onClick={close} />
              <h2 className="title">活动报名</h2>
              <div className="input-container">
                <div className="left-container">
                  <input ref={usernameRef} type="text" placeholder="姓名" onChange={usernameChange} />
                </div>
                <div className="right-container">
                  {sexData?.current?.map((item, index) => (
                    <span key={index} className="radio-container" onClick={() => sexChange(item)}>
                      <img
                        alt="广汽本田"
                        src={createFilePath(`/car-owners-story/${userInfo?.sex === item?.value ? 'pc_hongkuan' : 'pc_kuang'}.png`)}
                      />
                      <label>{item?.name}</label>
                    </span>
                  ))}
                </div>
              </div>
              <div className="input-container">
                <div className="left-container">
                  <input ref={ageRef} type="text" placeholder="年龄" onChange={ageChange} />
                </div>
                <div className="right-container">
                  <input ref={mobileRef} type="text" placeholder="手机号码" onChange={mobileChange} />
                </div>
              </div>
              {isMobile ? (
                <>
                  <div className="input-container">
                    <div className="left-container">
                      <div className="select-container">
                        <select value={selectProvince?.province_id || 0} onChange={provinceChange}>
                          <option value={0}>请选择省份</option>
                          {provinceData?.map((item, index) => (
                            <option key={index} value={item?.province_id}>
                              {item?.province_name}
                            </option>
                          ))}
                        </select>
                        <img alt="广汽本田" src={createFilePath('/car-owners-story/pc_kuang3.jpg')} />
                      </div>
                    </div>
                    <div className="right-container">
                      <div className="select-container">
                        <select value={selectCity?.city_id || 0} onChange={cityChange}>
                          <option value={0}>请选择城市</option>
                          {selectProvince?.Citys?.map((item, index) => (
                            <option key={index} value={item?.city_id}>
                              {item?.city_name}
                            </option>
                          ))}
                        </select>
                        <img alt="广汽本田" src={createFilePath('/car-owners-story/pc_kuang3.jpg')} />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="left-container full-width">
                      <div className="select-container">
                        <select value={selectDealer?.dealer_id || 0} onChange={dealerChange}>
                          <option value={0}>请选择特约店</option>
                          {selectCity?.Dealers?.map((item, index) => (
                            <option key={index} value={item?.dealer_id}>
                              {item?.dealer_name}
                            </option>
                          ))}
                        </select>
                        <img alt="广汽本田" src={createFilePath('/car-owners-story/pc_kuang3.jpg')} />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="left-container full-width">
                      <input ref={emailRef} type="text" placeholder="电子邮箱" onChange={emailChange} />
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="left-container full-width">
                      <span className="own-title">是否拥有广汽本田座驾</span>
                      {ownData?.current?.map((item, index) => (
                        <span key={index} className="radio-container" onClick={() => ownChange(item)}>
                          <img
                            alt="广汽本田"
                            src={createFilePath(`/car-owners-story/${userInfo?.ownyes === item?.value ? 'pc_hongkuan' : 'pc_kuang'}.png`)}
                          />
                          <label className="gray">{item?.name}</label>
                        </span>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="input-container">
                    <div className="left-container">
                      <input ref={emailRef} type="text" placeholder="电子邮箱" onChange={emailChange} />
                    </div>
                    <div className="right-container">
                      <div className="select-container">
                        <select value={selectProvince?.province_id || 0} onChange={provinceChange}>
                          <option value={0}>请选择省份</option>
                          {provinceData?.map((item, index) => (
                            <option key={index} value={item?.province_id}>
                              {item?.province_name}
                            </option>
                          ))}
                        </select>
                        <img alt="广汽本田" src={createFilePath('/car-owners-story/pc_kuang3.jpg')} />
                      </div>
                      <div className="select-container margin">
                        <select value={selectCity?.city_id || 0} onChange={cityChange}>
                          <option value={0}>请选择城市</option>
                          {selectProvince?.Citys?.map((item, index) => (
                            <option key={index} value={item?.city_id}>
                              {item?.city_name}
                            </option>
                          ))}
                        </select>
                        <img alt="广汽本田" src={createFilePath('/car-owners-story/pc_kuang3.jpg')} />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="left-container">
                      <span className="own-title">是否拥有广汽本田座驾</span>
                      {ownData?.current?.map((item, index) => (
                        <span key={index} className="radio-container" onClick={() => ownChange(item)}>
                          <img
                            alt="广汽本田"
                            src={createFilePath(`/car-owners-story/${userInfo?.ownyes === item?.value ? 'pc_hongkuan' : 'pc_kuang'}.png`)}
                          />
                          <label className="gray">{item?.name}</label>
                        </span>
                      ))}
                    </div>
                    <div className="right-container">
                      <div className="select-container dealer">
                        <select value={selectDealer?.dealer_id || 0} onChange={dealerChange}>
                          <option value={0}>请选择特约店</option>
                          {selectCity?.Dealers?.map((item, index) => (
                            <option key={index} value={item?.dealer_id}>
                              {item?.dealer_name}
                            </option>
                          ))}
                        </select>
                        <img alt="广汽本田" src={createFilePath('/car-owners-story/pc_kuang3.jpg')} />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="sub-title">分享坚持自我的梦想故事(经历或者故事)</div>
              <textarea className="content-text" ref={contentRef} placeholder="最少输入30个字,最多输入500个字" onChange={contentChange}></textarea>
              <div className="sub-title">相关图片(最多上传五张5MB以内的照片)</div>
              <div className="upload-container">
                {showAdd && (
                  <div className="upload-plus">
                    <img alt="广汽本田" src={createFilePath('/car-owners-story/pc_kuangjia.png')} />
                    <input type="file" accept="image/png,image/jpeg" onChange={addImage} />
                  </div>
                )}
                {fileList?.map((item, index) => (
                  <div key={index} className="upload-image">
                    <img alt="广汽本田" src={item.image} />
                  </div>
                ))}
              </div>
              <p className="policy-agree">
                <img alt="广汽本田" src={createFilePath(`/car-owners-story/${agree ? 'pc_hongkuan' : 'pc_kuang'}.png`)} onClick={agreeChange} />
                本人同意
                <a href="/policy/privacy-policy" target="_blank">
                  《广汽本田隐私政策》
                </a>
                ；广汽本田将对您提交的个人信息保密，不对外公开。
              </p>
              <button className="submit-button" onClick={submit}>
                提交报名表
              </button>
            </div>
          </div>
        </FullModalDetail>
      )}
    </section>
  );
}
