import React, { useEffect, forwardRef, useState } from 'react';
import { createPortal } from 'react-dom';
import './style.scss';

const FullModalDetail = forwardRef((props, ref) => {
  const [container, setContainer] = useState(null);

  useEffect(() => {
    const personalizedEnable = window.Dmp.personalizedEnable;
    document.documentElement.style.overflow = 'hidden';
    const node = document.createElement('div');
    if (props.classname) {
      node.className = 'full-modal-detail ' + props.classname;
    } else {
      node.className = 'full-modal-detail';
    }
    node.style.background = props?.style?.background;
    node.onclick = props?.onClick;
    document.body.appendChild(node);
    setContainer(node);

    return () => {
      window.Dmp.personalizedEnable = personalizedEnable;
      document.body.removeChild(node);
      if (Array.from(document.getElementsByClassName('full-modal-detail')).length === 0) {
        document.documentElement.style.overflow = 'auto';
        document.documentElement.style.overflowX = 'hidden';
      }
    };
  }, []);

  return (
    <>
      {container &&
        createPortal(
          <div className="full-modal-detail-content" ref={ref}>
            {props.children}
          </div>,
          container
        )}
    </>
  );
});

FullModalDetail.displayName = 'FullModalDetail';

export default FullModalDetail;
