import temp from '../../../temp/config';
import { createFilePath } from '../../../utils/obj-utils';
export default [
  [],
  [
    {
      title: '购车流程',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Support/buyCars.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Support/buyCars.jpg'),
      link: 'https://www.ghac.cn/buy/tools/process'
    },
    {
      title: '广汽本田商城',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Support/store.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Support/store.jpg'),
      link: 'https://mall.ghac.cn/?channel=GW'
    },
    ,
    {
      title: '广汽本田云展厅',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Support/CloudExhibition+Hall.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Support/CloudExhibition+Hall.jpg'),
      link: 'https://vehicles.ghac.cn'
    },
    {
      title: '车型对比',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Support/contrast.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Support/contrast.jpg'),
      link: 'https://www.ghac.cn/ModelContrast/p'
    },
    {
      title: '贷款计算器',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Support/calculator.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Support/calculator.jpg'),
      link: 'https://www.ghac.cn/buy/tools/financial/components/financeCalculator'
    },
    {
      title: '特约店查询',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Support/query.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Support/query.jpg'),
      link: '/buy/tools/find-dealer'
    },
    {
      title: '喜悦二手车官网',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Support/xiyue.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Support/xiyue.jpg'),
      link: 'https://usedcar.ghac.cn'
    }
  ],
  [
    {
      title: '服务品牌',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Customer/brand.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Customer/brand.jpg'),
      link: 'https://www.ghac.cn/service/funlink/p'
    },
    {
      title: '服务动态',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Customer/serviceDynamic.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Customer/serviceDynamic.jpg'),
      link: 'https://www.ghac.cn/service/service-activities'
    },
    {
      title: '车辆操作指南',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Customer/vehicleOperation.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Customer/vehicleOperation.jpg'),
      link: 'https://www.ghac.cn/Layouts/Activity/2016/0317/manual'
    },
    {
      title: '用车知识',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Customer/carKnowledge.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Customer/carKnowledge.jpg'),
      link: 'https://www.ghac.cn/service/knowledge'
    },
    {
      title: '零部件价格',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Customer/partsPrice.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Customer/partsPrice.jpg'),
      link: 'https://www.ghac.cn/service/part-price'
    },
    {
      title: '纯正用品',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Customer/sureSupplies.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Customer/sureSupplies.jpg'),
      link: 'https://www.ghac.cn/service/appliance'
    },
    {
      title: '三包及保养信息',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Customer/maintain.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Customer/maintain.jpg'),
      link: 'https://www.ghac.cn/service/repair-guide'
    },
    {
      title: '喜悦延保',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Customer/xiyue.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Customer/xiyue.jpg'),
      link: 'https://www.ghac.cn/service/service-insurance'
    },
    {
      title: '环保信息公开',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Customer/environmental.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Customer/environmental.jpg'),
      link: 'https://www.ghac.cn/service/environment'
    },
    {
      title: '新能源车服务',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Customer/newEnergy.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Customer/newEnergy.jpg'),
      link: 'https://www.ghac.cn/service/NEV'
    }
  ],
  [
    {
      title: '新闻资讯',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Brand/journalism.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Brand/journalism.jpg'),
      link: 'https://www.ghac.cn/news'
    },
    {
      title: '精彩活动',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Brand/activity.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Brand/activity.jpg'),
      link: 'https://www.ghac.cn/activity'
    },
    {
      title: '互动中心',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Brand/interaction.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Brand/interaction.jpg'),
      link: 'https://www.ghac.cn/interaction'
    },
    {
      title: '先进技术',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Brand/technology.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Brand/technology.jpg'),
      link: 'https://www.ghac.cn/technology'
    },
    {
      title: '儿童道路安全教育',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/Brand/childrenSecurity.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/Brand/childrenSecurity.jpg'),
      link: 'https://www.ghac.cn/Layouts/Activity/2019/0808/child_safety'
    }
  ],
  [
    {
      title: '公司介绍',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/AboutUs/introduce.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/AboutUs/introduce.jpg'),
      link: 'https://www.ghac.cn/about/introducing'
    },
    {
      title: '企业社会责任',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/AboutUs/society.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/AboutUs/society.jpg'),
      link: 'https://www.ghac.cn/about/Social-Responsibility'
    },
    {
      title: '企业参观预约',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/AboutUs/club.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/AboutUs/club.jpg'),
      link: 'https://reception.ghac.cn'
    },
    {
      title: '诚聘英才',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/AboutUs/recruitment.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/AboutUs/recruitment.jpg'),
      link: 'https://job.ghac.cn'
    },
    {
      title: '梦想同行club',
      // img: `${temp.app.deployUrlS3}/breeze/v1/pc/header/navigation/AboutUs/club.jpg`,
      img: createFilePath('/breeze/v1/pc/header/navigation/AboutUs/club.jpg'),
      link: 'https://www.ghac.cn/car-owners-club',
      m_link: 'https://www.ghac.cn/mcar-owners-club'
    }
  ],
  []
];
