import React, { useEffect } from 'react';
import temp from '../../temp/config';
import './style.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const isMobile = isMobileFun();
  const { backImg, backImgAlt, linkUrl, lookImg, positionClass, m_backImg, m_backImgAlt, m_marginFlag, dmpUrl, dmpFlag, initialDmp, loopDmp } =
    props.fields;
  useEffect(() => {
    if (!initialDmp.value) {
      setTimeout(() => {
        window.Dmp.sectionAction('manual');
      }, 1500);
    }
  }, []);
  return (
    <div
      className={`operation_main${isMobile ? '_m' : ''}`}
      style={{
        marginTop: m_marginFlag.value && isMobile ? '47px' : '0',
        padding: isMobile ? props.fields.padding.value : '',
        background: isMobile ? props.fields.backColor.value : ''
      }}
    >
      <img src={`${createFilePath(!isMobile ? backImg.value : m_backImg.value)}`} alt={!isMobile ? backImgAlt.value : m_backImgAlt.value} />
      {lookImg.value !== '' ? (
        <img
          alt="广汽本田"
          style={convertStylesStringToObject(isMobile ? props.fields.m_position.value : props.fields.position.value)}
          src={`${createFilePath(lookImg.value)}`}
          onClick={() => {
            window.open(linkUrl.value, '_self');
            {
              !dmpFlag.value ? window.Dmp.clickAction(loopDmp[0].displayName, linkUrl.value, '') : '';
            }
          }}
          className={positionClass.value}
        />
      ) : (
        ''
      )}
      {props.fields.lookImg_s.value !== '' ? (
        <img
          alt="广汽本田"
          style={convertStylesStringToObject(isMobile ? props.fields.m_position_s.value : props.fields.position_s.value)}
          src={`${createFilePath(props.fields.lookImg_s.value)}`}
          // onClick={() => {
          //   window.open(props.fields.linkUrl_s.value, '_self');
          //   {
          //     !dmpFlag.value ? window.Dmp.clickAction('manual-back', props.fields.linkUrl_s.value, '') : '';
          //   }
          // }}
          className={`${positionClass.value} toplogo`}
        />
      ) : (
        ''
      )}
    </div>
  );
}
