import React, { useState, useRef, useEffect } from 'react';
import './style.scss';
import { createFilePath, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import GatelogDownload_v1 from '../../elements/GatelogDownload_v1';
import WallpaperDownload_v1 from '../../elements/WallpaperDownload_v1';
import { dataFetcher } from '../../dataFetcher';

export default function index(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const gatelog_list = fields.gatelog_list;
  const wallpaper_list = fields.wallpaper_list;
  const [data, _] = useState({ isMobile, gatelog_list, wallpaper_list });
  const [showNum, setShowNum] = useState(4);
  const [videoLargeType, setVideoLargeType] = useState(0);
  const [videoLargeTypeShow, setVideoLargeTypeShow] = useState(false);
  const videoLargeTypeList = [
    { name: '全部视频', value: '0' },
    { name: '品牌视频', value: '{7AF2E2DE-FF87-4EB7-A13B-A69EB7653B53}' },
    { name: '车型视频', value: '{6FAEE4D5-58E7-4177-8116-01670E35B286}' }
  ];
  const [videoSmallTypeList, setVideoSmallTypeList] = useState([{ Key: '', Value: '全部视频' }]);
  const [videoSmallTypeShow, setVideoSmallTypeShow] = useState(false);
  const [videoSmallType, setVideoSmallType] = useState(0);
  const [videoList, setVideoList] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [search, setSearch] = useState(false);
  const [pageNum, setPageNum] = useState(3);
  const [videoShow, setVideoShow] = useState(false);
  const [videoNum, setVideoNum] = useState(0);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    let list = [{ Key: '', Value: '全部视频' }];
    dataFetcher(`/api/honda/v1/Article/GetVideoSubType?videoType=${videoLargeType}`)
      .then(({ data }) => {
        if (data.length > 0) {
          data.forEach((item) => {
            list.push(item);
          });
        }
        setVideoSmallType(0);
        setVideoSmallTypeList([...list]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [videoLargeType]);

  useEffect(() => {
    let list = [];
    dataFetcher(
      `/api/honda/v1/Article/GetInteractionList?pageIndex=${pageIndex}&pageSize=${isMobile ? 4 : 8}&videoType=${videoLargeType}&videoSubTypeKey=${
        videoSmallTypeList[videoSmallType].Key
      }`
    )
      .then(({ data }) => {
        list = data.articles;
        setPageNum(Math.ceil(data.TotalCount / (isMobile ? 4 : 8)));
        setVideoList([...list]);
        setLoad((load) => !load);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [search, pageIndex]);

  useEffect(() => {
    const prev = document.querySelector('.prev');
    const next = document.querySelector('.next');
    if (prev) {
      if (pageIndex === 1) {
        prev.style.display = 'none';
      } else {
        prev.style.display = 'inline-block';
      }
    }
    if (next) {
      if (pageIndex === pageNum) {
        next.style.display = 'none';
      } else {
        next.style.display = 'inline-block';
      }
    }
  }, [load]);

  const getPage = () => {
    let gp = [];
    for (let i = 1; i <= pageNum; i++) {
      gp.push(
        <span style={{ backgroundColor: pageIndex === i ? '#e10825' : 'white', color: pageIndex === i ? 'white' : '#d21f1f' }}>
          <a page={i} onClick={() => setPageIndex(i)} key={i}>
            {i}
          </a>
        </span>
      );
    }
    return gp;
  };

  return (
    <section>
      <div className="interaction_v1">
        <div className="interaction_main">
          <div className="staticHead">
            <h3>互动中心</h3>
          </div>
          <div className="video_bg" style={{ marginTop: data.isMobile ? '0' : '-2rem' }}>
            <div className="content">
              <div className="download">
                <h2 className="video_title video_title02">视频专区</h2>
                <div className="video_classify">
                  <label className="video_classify_title">视频分类：</label>
                  <div
                    className="video_k"
                    onClick={() => {
                      setVideoLargeTypeShow((videoLargeTypeShow) => !videoLargeTypeShow);
                      setVideoSmallTypeShow(false);
                    }}
                  >
                    <span className="video_k_top">{videoLargeTypeList[videoLargeType].name}</span>
                    <i
                      className="video_k_icon"
                      style={{
                        background: `url(${createFilePath('/interaction/video_icon.png')})`,
                        backgroundPositionX: isMobile ? '-118px' : '-74px',
                        backgroundPositionY: '0'
                      }}
                    />
                    {videoLargeTypeShow && (
                      <div className="video_option">
                        {videoLargeTypeList.map((item, index) => {
                          return (
                            <p
                              onClick={() => setVideoLargeType(index)}
                              className={videoLargeType === index ? 'video_option_p_active' : 'video_option_p'}
                              value={item.value}
                              key={index}
                            >
                              {item.name}
                            </p>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div
                    className="video_k"
                    onClick={() => {
                      setVideoSmallTypeShow((videoSmallTypeShow) => !videoSmallTypeShow);
                      setVideoLargeTypeShow(false);
                    }}
                  >
                    <span className="video_k_top">{videoSmallTypeList[videoSmallType].Value}</span>
                    <i
                      className="video_k_icon"
                      style={{
                        background: `url(${createFilePath('/interaction/video_icon.png')})`,
                        backgroundPositionX: isMobile ? '-118px' : '-74px',
                        backgroundPositionY: '0'
                      }}
                    />
                    {videoSmallTypeShow && (
                      <div className="video_option">
                        {videoSmallTypeList.map((item, index) => {
                          return (
                            <p
                              onClick={() => setVideoSmallType(index)}
                              className={videoSmallType === index ? 'video_option_p_active' : 'video_option_p'}
                              value={item.Value}
                              key={index}
                            >
                              {item.Value}
                            </p>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <input
                    type="button"
                    className="pic_download_search"
                    value="搜索"
                    onClick={() => {
                      setSearch((search) => !search);
                      setPageIndex(1);
                    }}
                  />
                </div>
                <div className="listVideo">
                  {videoList.length > 0 &&
                    videoList.map((item, index) => {
                      return (
                        <div
                          className="video_content_co"
                          key={index}
                          onClick={() => {
                            setVideoNum(index);
                            setVideoShow(true);
                          }}
                        >
                          <img alt={item.a_title} src={createFilePath(item.a_thumb.replaceHtml())} />
                          <p className="video_content_title">{item.a_title}</p>
                          <i
                            className="video_content_icon"
                            style={{
                              background: `url(${createFilePath('/interaction/video_icon.png')})`,
                              backgroundPositionX: '0',
                              backgroundPositionY: '0'
                            }}
                          />
                          <input type="hidden" value={item.out_link_url.replaceHtml()} />
                        </div>
                      );
                    })}
                </div>
                {pageNum !== 1 && (
                  <div className="video_page">
                    <span className="prev" onClick={() => setPageIndex((pageIndex) => pageIndex - 1)}>
                      <em></em>&lt;
                    </span>
                    {getPage()}
                    <span className="next" onClick={() => setPageIndex((pageIndex) => pageIndex + 1)}>
                      &gt;<em></em>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {videoShow && (
            <div className="video_ckplayer">
              <div className="video_ckplayer_content">
                <video controls={true} src={videoList[videoNum].out_link_url.replaceHtml()} />
              </div>
              <div className="video_ckplayer_btn" onClick={() => setVideoShow(false)} />
            </div>
          )}
          <div className="video_bg">
            <div className="content">
              <div className="download">
                <h2 className="video_title">型录下载</h2>
                <div className="video_content">
                  {data.gatelog_list &&
                    data.gatelog_list.length > 0 &&
                    data.gatelog_list.map((gatelogItem, gatelogIndex) => {
                      if (gatelogIndex >= showNum && data.isMobile) {
                        return '';
                      } else {
                        return <GatelogDownload_v1 key={gatelogIndex} gatelog_car={gatelogItem} isMobile={data.isMobile} />;
                      }
                    })}
                </div>
                {data.isMobile && showNum < data.gatelog_list.length && (
                  <div className="interactDown_btn" onClick={() => setShowNum(data.gatelog_list.length)}>
                    <img alt="广汽本田" src={createFilePath('/interaction/down.png')} className="interactDown_more" />
                    查看更多
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="video_bg">
            <div className="content">
              <div className="download">
                <h2 className="video_title">壁纸下载</h2>
                <WallpaperDownload_v1 wallpaper_list={data.wallpaper_list} isMobile={data.isMobile} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
