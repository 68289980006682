import React, { useEffect, useRef, createRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import gsap, { Linear } from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import HeroLanding from '../../elements/HeroLanding_v1';
import { convertStylesStringToObject, getPrefixField, sendDmp, isInView, isMobileFun, GotoMall } from '../../utils/obj-utils';

export default function index({ fields, eventRef }) {
  const containerRef = useRef(null);
  const isMobile = isMobileFun();
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const sectionDmp = fields?.sectionDmp;
  const anchor = fields?.anchor?.value;
  // const bannerList = fields[getPrefixField('bannerList', isMobile)]?.map((item) => ({
  //   sectionDmp: item?.fields?.sectionDmp,
  //   title: item?.fields?.title?.value,
  //   titleStyle: item?.fields?.titleStyle?.value,
  //   desc: item?.fields?.desc?.value,
  //   descStyle: item?.fields?.descStyle?.value,
  //   background: item?.fields?.background?.value,
  //   backgroundAlt: item?.fields?.backgroundAlt?.value,
  //   backgroundStyle: item?.fields?.backgroundStyle?.value,
  //   buttonList: item?.fields?.buttonList?.map((item1) => ({
  //     dmp: item1?.fields?.dmp,
  //     style: item1?.fields?.style?.value,
  //     text: item1?.fields?.text?.value,
  //     link: item1?.fields?.link?.value,
  //     target: item1?.fields?.target?.fields?.v?.value
  //   })),
  //   buttonStyle: item?.fields?.buttonStyle?.value,
  //   textContainerStyle: item?.fields?.textContainerStyle?.value,
  //   position: item?.fields?.position?.value,
  //   contentPosition: item?.fields?.contentPosition?.value,
  //   style: item?.fields?.style?.value,
  //   contentStyle: item?.fields?.contentStyle?.value,
  //   bannerhtml: item?.fields?.bannerhtml?.value
  // }));

  const bannerList = fields?.List?.map((item) => ({
    sectionDmp: item?.fields?.sectionDmp,
    style: item?.fields[getPrefixField('style', isMobile)]?.value,
    bannerhtml: item?.fields[getPrefixField('bannerhtml', isMobile)]?.value
  }));
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const bannerRefList = bannerList.map(() => createRef());
  const [data, _] = useState({
    sectionDmp,
    anchor,
    bannerList,
    style,
    bannerRefList,
    isMobile
  });
  const onceList = bannerList.map(() => true);
  const overviewList = bannerList.map(() => true);
  const length = bannerRefList?.length;

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent,
      scrollEvent
    };
  });

  let scrollY = 0;
  let scrollDown = false;
  let lastScrollDown = false;
  let lastSectionDmp;
  const sendEvent = () => {
    if (!lastScrollDown) {
      placeholderDmp();
    }
  };

  const placeholderDmp = (callback) => {
    const placeholder = containerRef?.current?.getElementsByClassName('home-banner-placeholder')[0];
    const rect = data?.bannerRefList[data?.bannerRefList?.length - 1]?.current?.getBoundingClientRect();
    const bottomOffset = window.innerHeight - window.innerHeight / (data?.isMobile ? 3 : 2);
    if (rect.bottom <= bottomOffset && isInView(placeholder)) {
      callback && callback();
      const sectionDmp = data?.sectionDmp;
      if (lastSectionDmp !== sectionDmp || !callback) {
        lastSectionDmp = sectionDmp;
        sendDmp(sectionDmp);
      }
    }
  };

  const scrollEvent = () => {
    setTimeout(() => {
      const list = [];
      data?.bannerRefList?.forEach((item, index) => {
        if (index < data?.bannerRefList?.length - 1) {
          const rect = data?.bannerRefList[index + 1]?.current?.getBoundingClientRect();
          const topOffset = window.innerHeight / (data?.isMobile ? 3 : 2);
          if (isInView(item) && rect.top >= topOffset) {
            list.push(index);
          } else {
            overviewList[index] = true;
          }
        } else {
          if (isInView(item)) {
            list.push(index);
          } else {
            overviewList[index] = true;
          }
        }
      });
      if (list?.length > 1) {
        if (window.scrollY >= scrollY) {
          if (!scrollDown) {
            scrollDown = true;
            overviewList[list[list.length - 1]] = true;
          }
        } else {
          if (scrollDown) {
            scrollDown = false;
            overviewList[list[0]] = true;
          }
        }
      }
      if ((list?.length === 1 && list[0] === data?.bannerRefList?.length - 1) || list?.length === 0) {
        if (window.scrollY >= scrollY) {
          if (!lastScrollDown) {
            placeholderDmp(() => {
              lastScrollDown = true;
            });
          }
        } else {
          if (lastScrollDown) {
            lastScrollDown = false;
            overviewList[overviewList.length - 1] = true;
          }
        }
      }

      list.forEach((item) => {
        sectionChange(item);
      });
      scrollY = window.scrollY;
    }, 0);
  };

  useEffect(() => {
    reset();
    const timelineList = [];
    const controller = new ScrollMagic.Controller();
    const size = length * 2 - 1;
    // const duration = `${size * 100}%`;
    const duration = length * document.documentElement.clientHeight;
    const timeline = gsap.timeline({
      paused: true,
      onStart: () => {
        data?.bannerRefList?.forEach((item, index) => {
          if (index > 0) {
            item.current.style.transform = 'translate(0, 100%)';
          }
        });
      }
    });
    timelineList.push(timeline);
    for (let index = 0; index < size; index++) {
      // if (index === 0) {
      //   const timeline1 = gsap.timeline({
      //     duration: 0.5,
      //     onUpdate: () => {
      //       if (timeline1.progress() <= 0.5 && onceList[0]) {
      //         onceList[0] = false;
      //         // contentAnimate(data?.bannerRefList[0]);
      //       }
      //     }
      //   });
      //   timeline.add(timeline1);
      //   timelineList.push(timeline1);
      // }
      if (index % 2 === 0) {
        const i = index / 2;
        timeline.to(
          data?.bannerRefList[i]?.current,
          {
            y: i < length - 1 ? '-50%' : '-100%',
            ease: Linear.easeNone,
            onStart: () => {
              data?.bannerRefList?.forEach((item1, index1) => {
                if (index1 < i) {
                  item1.current.style.display = 'none';
                }
              });
            },
            onComplete: () => {
              setTimeout(() => {
                resetByIndex(i);
              }, 500);
            },
            onReverseComplete: () => {
              if (i < length - 1) {
                setTimeout(() => {
                  resetByIndex(i + 1);
                }, 500);
              }
              if (i > 0) {
                data.bannerRefList[i - 1].current.style.display = '';
              }
            }
          },
          i === 0 ? '<' : '>'
        );
        if (i < length - 1) {
          timeline.to(
            data?.bannerRefList[i + 1]?.current,
            {
              y: 0,
              ease: Linear.easeNone
            },
            '<'
          );
        }
        // if (i === length - 1) {
        //   const timeline1 = gsap.timeline({
        //     duration: 0.5
        //   });
        //   timeline.add(timeline1);
        //   timelineList.push(timeline1);
        // }
      } else {
        // const i = (index - 1) / 2 + 1;
        // const timeline1 = gsap.timeline({
        //   duration: 1,
        //   onUpdate: () => {
        //     if (timeline1.progress() >= 0.5 && onceList[i]) {
        //       onceList[i] = false;
        //       // contentAnimate(data?.bannerRefList[i]);
        //     }
        //   }
        // });
        // timeline.add(timeline1);
        // timelineList.push(timeline1);
      }
    }

    const scene = new ScrollMagic.Scene({
      triggerElement: containerRef?.current,
      triggerHook: 'onLeave',
      duration
    })
      .setTween(timeline)
      .setPin(containerRef?.current)
      .addTo(controller);

    return () => {
      timelineList.forEach((item) => item.kill());

      scene.remove();
      scene.destroy();

      controller.destroy();
    };
  }, []);

  const sectionChange = (index) => {
    const sectionDmp = data?.bannerList[index]?.sectionDmp;
    if (overviewList[index] && lastSectionDmp !== sectionDmp) {
      lastSectionDmp = sectionDmp;
      overviewList[index] = false;
      sendDmp(sectionDmp);
    }
  };

  const reset = () => {
    data?.bannerRefList?.forEach((_, index) => {
      resetByIndex(index);
    });
  };

  const resetByIndex = (index) => {
    // resetAnimateByIndex(index);
  };

  const resetAnimateByIndex = (index) => {
    const contentElementList = data?.bannerRefList[index]?.current?.getElementsByClassName('content-container');
    if (!contentElementList?.length) {
      return;
    }
    const contentElement = contentElementList[0];
    if (data?.isMobile) {
      const textElementList = contentElement?.getElementsByClassName('text-container');
      if (textElementList.length > 0) {
        textElementList[0].style.opacity = 0;
      }
      const buttonElementList = contentElement?.getElementsByClassName('button-container');
      if (buttonElementList.length > 0) {
        buttonElementList[0].style.opacity = 0;
      }
    } else {
      contentElement.style.opacity = 0;
    }
    onceList[index] = true;
  };

  const contentAnimate = (ref, callback) => {
    const duration = 1.25;
    const contentElementList = ref?.current?.getElementsByClassName('content-container');
    if (!contentElementList?.length) {
      return;
    }
    const contentElement = contentElementList[0];
    if (data?.isMobile) {
      const textElementList = contentElement?.getElementsByClassName('text-container');
      const timeline = gsap.timeline({
        onComplete: () => {
          timeline.kill();
          callback && callback();
        }
      });
      if (textElementList.length > 0) {
        timeline.fromTo(textElementList[0], { opacity: 0, y: '-100%' }, { opacity: 1, y: 0, duration });
      }
      const buttonElementList = contentElement?.getElementsByClassName('button-container');
      if (buttonElementList.length > 0) {
        timeline.fromTo(buttonElementList[0], { opacity: 0, y: '-100%' }, { opacity: 1, y: 0, duration }, '<40%');
      }
    } else {
      gsap.fromTo(contentElement, { opacity: 0, y: '-100%' }, { opacity: 1, y: 0, duration, onComplete: callback });
    }
  };
  const AppSchemeUrlRefClick = (e) => {
    if (e.target && e.target.dataset && e.target.dataset.appschemeurl) {
      GotoMall(e.target.dataset.appschemeurl, e.target.dataset.htmlItem1, isMobile);
    }
  };
  return (
    <section>
      <div className="home-banner" id={data?.anchor} ref={containerRef} style={data?.style}>
        {data?.bannerList?.map((item, index) => (
          <div
            className={'hero-landing-v1 home-banner-hero-landing'}
            style={{ ...convertStylesStringToObject(item?.style), transform: `translate(0, ${index * 100}%)` }}
            ref={data?.bannerRefList[index]}
            key={index}
          >
            {item?.bannerhtml.indexOf('data-appschemeurl') !== -1 ? (
              <div dangerouslySetInnerHTML={{ __html: item?.bannerhtml?.replaceHtml() }} onClick={AppSchemeUrlRefClick} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: item?.bannerhtml?.replaceHtml() }} />
            )}
          </div>
        ))}
        <div className="home-banner-placeholder">
          <Placeholder name="jss-banner" rendering={route} page={route} />
        </div>
      </div>
    </section>
  );
}
