// noinspection NonAsciiCharacters
import React, { useEffect, useState, useRef } from 'react';
import 'swiper/swiper.scss';
import './style.scss';
import Fuls from '../../../elements/FullModalss/index';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import { calcLoanSchemeV2 } from './loan-calc';
import Icon from '../../../elements/icon';
import temp from '../../../temp/config';
import { sendDmpAction, createFilePath } from '../../../utils/obj-utils';

let activeIndex = 0;
export default React.memo(function TextContent(props) {
  const { carList, carType } = props;
  const [carIndex, setCarIndex] = useState(0); // 选择车系
  const [downPayments, setDownPayments] = useState(20); // 首付比例
  const [lounNum, setLounNum] = useState(12); // 贷款期数
  const [lounIndex, setLounIndex] = useState(0); // 贷款期数映射
  const [bankList, setBankList] = useState([]); // 顶部银行Swiper
  const [bankActiveIndex, setBankActiveIndex] = useState(0);
  const [thisMobile, setThisMobile] = useState(false);
  const swiperRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  useEffect(() => {
    setThisMobile(document.documentElement.clientWidth > 1024);
    setBankList(new calcLoanSchemeV2(carList[carIndex].GuidancePrice, downPayments * 1, lounNum * 1, carType));
    const resizeEvent = () => {
      setThisMobile(document.documentElement.clientWidth > 1024);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);
  // 设置首付比例
  const changeDownPayments = (value) => {
    setDownPayments(value);
  };
  // 设置贷款期数
  const changeLounIndex = (value) => {
    setLounIndex(value);
    setLounNum(getLounNum(value));
  };
  // 设置选择车系
  const setPage = (value) => {
    setCarIndex(value);
    setBankActiveIndex(0);
    activeIndex = 0;
    setBankList(calcLoanSchemeV2(carList[value].GuidancePrice, downPayments * 1, lounNum * 1, carType));
  };
  // 设置银行
  const selectBank = (index) => {
    setBankActiveIndex(index);
    window.Dmp.clickAction('detail-final-loan', bankList[index].loan.name);
  };
  // 提交
  const submit = () => {
    setBankActiveIndex(0);
    activeIndex = 0;
    setBankList(calcLoanSchemeV2(carList[carIndex].GuidancePrice, downPayments * 1, lounNum * 1, carType));
  };
  const getLounNum = (value) => {
    switch (value) {
      case '0':
        return 12;
      case '1':
        return 18;
      case '2':
        return 24;
      case '3':
        return 36;
      case '4':
        return 48;
      default:
        return 60;
    }
  };
  return (
    <Fuls key={Math.random()}>
      <div className="moneyComputer">
        <div>
          <p>贷款计算器</p>
          <img
            className="close"
            // src={`${temp.app.deployUrlS3}/common/icon/closeLoanIcon.png`}
            src={createFilePath('/common/icon/closeLoanIcon.png')}
            onClick={() => {
              sendDmpAction('click', 'detail-final-close');
              props.close(false);
            }}
            alt="关闭"
          />
        </div>
        <div className="contrastSwiperContainer">
          <Swiper
            ref={swiperRef}
            modules={[Pagination, Navigation]}
            slidesPerView={thisMobile ? 3 : 1}
            className="contrastSwiper"
            spaceBetween={20}
            initialSlide={activeIndex}
            navigation={{
              prevEl: '.navigation-prev',
              nextEl: '.navigation-next',
              disabledClass: 'navigation-disabled'
            }}
            onSlidePrevTransitionEnd={(swiper) => {
              if (activeIndex !== swiper.activeIndex) {
                activeIndex = swiper.activeIndex;
              }
            }}
            onSlideNextTransitionEnd={(swiper) => {
              if (activeIndex !== swiper.activeIndex) {
                activeIndex = swiper.activeIndex;
              }
            }}
          >
            {bankList &&
              bankList.map((item, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className={bankActiveIndex === index ? 'contrastSlide contrastSlideActive' : 'contrastSlide'}
                    onClick={() => {
                      selectBank(index);
                    }}
                  >
                    <p>{item.loan.name}</p>
                    <p>{item.desc}</p>
                    <p>{item.loan.extraText ? item.loan.extraText : ''}</p>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <div
            ref={navigationPrevRef}
            className="navigation-prev"
            onClick={() => {
              activeIndex = swiperRef.current.swiper.activeIndex;
              window.Dmp.clickAction('detail-final-btnl', bankList[swiperRef.current.swiper.activeIndex].loan.name);
            }}
          >
            <Icon name="ArrowLeft" />
          </div>
          <div
            ref={navigationNextRef}
            className="navigation-next"
            onClick={() => {
              activeIndex = swiperRef.current.swiper.activeIndex;
              window.Dmp.clickAction('detail-final-btnr', bankList[swiperRef.current.swiper.activeIndex].loan.name);
            }}
          >
            <Icon name="ArrowRight" />
          </div>
        </div>
        <div>
          <div className="toConfigure">
            <p>配置</p>
            <select
              onChange={(e) => {
                setPage(e.target.value);
                window.Dmp.msgAction('detail-final-select', carList[e.target.value].carName);
              }}
              defaultValue={carIndex}
            >
              {carList &&
                carList.map((itme, index) => {
                  return (
                    <option key={index} value={index}>
                      {itme.carName}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="guidance">
            <p>指导价(元)</p>
            <div>
              <p>{parseInt(carList[carIndex].GuidancePrice).toLocaleString()}</p>
            </div>
          </div>
          <div className="loan">
            <p>贷款金额(元)</p>
            <div>
              <p>{parseInt((carList[carIndex].GuidancePrice * (100 - downPayments)) / 100).toLocaleString()}</p>
            </div>
          </div>
        </div>
        <div>
          <div>
            <p>
              首付比例（%）<span>{downPayments + '%'}</span>
            </p>
            <div>
              <p>20%</p>
              <input
                type="range"
                max="90"
                min="20"
                step="10"
                value={downPayments}
                onInput={(e) => {
                  changeDownPayments(e.target.value);
                  window.Dmp.msgAction('detail-final-ratio', e.target.value);
                }}
              />
              <p>90%</p>
            </div>
          </div>
          <div>
            <p>
              贷款期数（期）<span>{lounNum + '期'}</span>
            </p>
            <div>
              <p>12</p>
              <input
                type="range"
                max="5"
                min="0"
                step="1"
                value={lounIndex}
                onInput={(e) => {
                  changeLounIndex(e.target.value);
                  window.Dmp.msgAction('detail-final-num', getLounNum(e.target.value));
                }}
              />
              <p>60</p>
            </div>
          </div>
        </div>
        <div>
          <button data-action="clickAction" data-item="detail-final-btn1" className="computerBtn2" onClick={submit}>
            确认提交
          </button>
        </div>
      </div>
    </Fuls>
  );
});
