/* eslint-disable quotes */
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { EffectFade, Pagination, Navigation, Autoplay } from 'swiper';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import './style.scss';
import HeroLanding from '../../elements/HeroLanding';
import { convertStylesStringToObject, getPrefixField, createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function Banner(props) {
  let autoplay = true;
  const rootEl = useRef(null);
  const isMobile = isMobileFun();
  const bannerItem = props?.fields?.bannerItem;
  const className = props?.fields?.className?.value;
  const countDown = props?.fields.countDown?.value;
  const styleHeight = convertStylesStringToObject(isMobile ? props?.fields?.mStyleHeight?.value : props?.fields?.styleHeight?.value);
  const navigation = props?.fields?.navigation?.fields?.k?.value === 'true';
  const maskImage = createFilePath(props?.fields[getPrefixField('maskImage', isMobile)]?.value);
  const showMask = props?.fields[getPrefixField('showMask', isMobile)]?.value;
  const dmp = props?.fields?.dmp?.value;
  const pagename = props?.page?.placeholders['jss-main'][0]?.params?.pagename;
  const nextdmp = props?.fields?.nextdmp?.value || '';
  const prevdmp = props?.fields?.prevdmp?.value || '';
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    window.Dmp.sectionAction(dmp || 'home');
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : 'headerNav';
    maskAnimation();
  };

  const maskAnimation = () => {
    const classList = rootEl?.current?.getElementsByClassName('mask-cover')[0]?.classList;
    if (showMask && Array(classList)?.indexOf('mask-cover-animation') === -1) {
      classList?.add('mask-cover-animation');
      rootEl?.current?.getElementsByClassName('mask-image')[0]?.classList?.add('mask-image-animation');
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', function () {
      if (countDown && document.visibilityState === 'visible') {
        let circleBoxList = document.querySelectorAll('.swiper-circle-box-active');
        for (let i = 0; i < circleBoxList.length; i++) {
          circleBoxList[i].classList.remove('swiper-circle-box-active');
          void circleBoxList[i].offsetWidth;
          circleBoxList[i].classList.add('swiper-circle-box-active');
        }
      }
    });
  }, []);

  return (
    <div className={`HeaderBanner ${className}`} id={props?.params?.pagename || 'headerNav'} ref={rootEl} style={styleHeight}>
      <Swiper
        className={!isMobile ? 'bannerList_pc' : 'bannerList_mobile'}
        effect={'fade'}
        fadeEffect={{ crossFade: true }}
        autoplay={{ delay: 3000, autoPlay: true, disableOnInteraction: false }}
        navigation={navigation}
        slidesPerView={1}
        slidesPerGroup={1}
        modules={[EffectFade, Pagination, Navigation, Autoplay]}
        onSlideChangeTransitionStart={() => {
          if (autoplay) {
            autoplay = false;
          }
        }}
        onInit={(e) => {
          let circleBoxList = document.querySelectorAll('.swiper-circle-box-active');
          if (circleBoxList && circleBoxList.length > 0) {
            circleBoxList[0].classList.remove('swiper-circle-box-active');
            e.autoplay.stop();
            setTimeout(() => {
              void circleBoxList[0].offsetWidth;
              circleBoxList[0].classList.add('swiper-circle-box-active');
              e.autoplay.start();
            }, 3000);
          }
          e?.pagination?.bullets?.forEach((item, index) => {
            item.onclick = function () {
              if (e.realIndex !== index) {
                global.Dmp.clickAction(dmp ? `${dmp}-btn${index + 1}` : `home-btn${index + 1}`, '');
              }
            };
          });
          if (navigation) {
            e.navigation.nextEl.onclick = function () {
              if (props?.fields?.isNextPrevDmp?.value) {
                global.Dmp.clickAction(dmp ? `${dmp}-btnr` : `home-btnr`, '');
              } else {
                if (nextdmp) {
                  global.Dmp.clickAction(nextdmp, '');
                } else {
                  global.Dmp.clickAction(dmp ? `${dmp}-btn${e.realIndex + 1}` : `home-btn${e.realIndex + 1}`, '');
                }
              }
            };
            e.navigation.prevEl.onclick = function () {
              if (props?.fields?.isNextPrevDmp?.value) {
                global.Dmp.clickAction(dmp ? `${dmp}-btnl` : `home-btnl`, '');
              } else {
                if (prevdmp) {
                  global.Dmp.clickAction(prevdmp, '');
                } else {
                  global.Dmp.clickAction(dmp ? `${dmp}-btn${e.realIndex + 1}` : `home-btn${e.realIndex + 1}`, '');
                }
              }
            };
          }
        }}
        onSlidePrevTransitionEnd={(e) => {
          if (autoplay) {
            return;
          }
        }}
        onSlideNextTransitionEnd={(e) => {
          if (autoplay) {
            autoplay = false;
            return;
          }
        }}
        onAutoplay={(e) => {
          autoplay = true;
        }}
        pagination={
          countDown
            ? {
                bulletClass: 'swiper-circle-box',
                bulletActiveClass: 'swiper-circle-box-active',
                clickable: true,
                renderBullet: function (index, className) {
                  let radius = 12;
                  let currentProgress = 100;
                  let dash1 = (((radius - 2) * 2 * Math.PI) / 100) * currentProgress;
                  let dash2 = (radius - 2) * 2 * Math.PI + 'px';
                  return (
                    '<span class=' +
                    className +
                    '><svg width=' +
                    radius * 2 +
                    ' height=' +
                    radius * 2 +
                    '><circle cx=' +
                    radius +
                    ' cy=' +
                    radius +
                    ' r=' +
                    (radius - 2) +
                    " stroke='white' stroke-dasharray=" +
                    dash1 +
                    ',' +
                    dash2 +
                    " fill='rgba(0,0,0,0.5)'/>" +
                    '<circle cx=' +
                    radius +
                    ' cy=' +
                    radius +
                    ' r=' +
                    (radius - 9) +
                    " fill='white' " +
                    " class='swiper-circle-text'" +
                    ' /> ' +
                    '</svg></span>'
                  );
                }
              }
            : {
                bulletClass: 'swiper-pagination-bullet',
                bulletActiveClass: 'swiper-pagination-bullet-active',
                clickable: true
              }
        }
      >
        {bannerItem?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item?.fields?.onlymb?.value && !item?.fields?.onlypc?.value && isMobile && (
                <SwiperSlide key={index}>
                  <HeroLanding data={item} isMobile={isMobile} className="HeaderBanner-hero" />
                </SwiperSlide>
              )}
              {item?.fields?.onlypc?.value && !item?.fields?.onlymb?.value && !isMobile && (
                <SwiperSlide key={index}>
                  <HeroLanding data={item} isMobile={isMobile} className="HeaderBanner-hero" />
                </SwiperSlide>
              )}
              {((!item?.fields?.onlymb?.value && !item?.fields?.onlypc?.value) || (item?.fields?.onlymb?.value && item?.fields?.onlypc?.value)) && (
                <SwiperSlide key={index}>
                  <HeroLanding data={item} isMobile={isMobile} className="HeaderBanner-hero" />
                </SwiperSlide>
              )}
            </React.Fragment>
          );
        })}
      </Swiper>
      {showMask && <div className={`mask-cover ${pagename === 'home' && 'mask-cover-animation'}`} />}
      {showMask && <img alt="广汽本田" className={`mask-image ${pagename === 'home' && 'mask-image-animation'}`} src={maskImage} />}
    </div>
  );
}
