import React, { useState } from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';
import temp from '../../temp/config';
import state from './state.js';
import { createFilePath, isMobileFun, GotoMall } from '../../utils/obj-utils';
export default function index(props) {
  if (!props?.showFooter) {
    return null;
  }
  const isMobile = isMobileFun();
  const { top_left_data, top_right_data, bottom_other_link_title } = state;
  const fotterBtmList = [7, 6, 5, 9, 4, 3, 8];
  const fotterTopLeftHeadList = [
    'bottom-button-car',
    'bottom-button-buy',
    'bottom-button-service',
    'bottom-button-center',
    'bottom-button-about',
    'bottom-button-link'
  ];
  const carList = [
    'bottom-button-accord',
    'bottom-button-ea6',
    'bottom-button-crider',
    'bottom-button-fit',
    'bottom-button-odysseySport',
    'bottom-button-avancier',
    'bottom-button-breeze',
    'bottom-button-breezephev',
    'bottom-button-zrv',
    'bottom-button-vezel',
    'bottom-button-integra',
    'bottom-button-ve1'
  ];
  const [pictrueList] = useState([
    '/breeze/v1/pc/footer/Tmall.png',
    '/breeze/v1/pc/footer/Microblog.png',
    '/breeze/v1/pc/footer/WeChat.png',
    '/breeze/v1/pc/footer/QrCode.png',
    '/breeze/v1/pc/footer/Phone.png'
  ]);
  return (
    <div style={{ color: 'black', backgroundColor: 'white' }}>
      <div className="FotterAll">
        <div className="FotterTop">
          <div className="FotterTopLeft">
            <div className="FotterTopLeftHead">
              {top_left_data.map((item, index) => {
                return item.linkUrl !== '' ? (
                  <Link
                    data-action="clickAction"
                    data-item={`${item.dmp ? item.dmp : fotterTopLeftHeadList[index]}`}
                    data-url={item.linkUrl}
                    href={item.linkUrl}
                    key={index}
                    field={{ value: item.title }}
                    style={{ color: 'black' }}
                    target={item.target ? '_blank ' : ''}
                    rel={item.nofollow ? 'nofollow' : ''}
                  >
                    {item.title}
                  </Link>
                ) : (
                  <Link
                    data-action="clickAction"
                    data-item={`${item.dmp ? item.dmp : fotterTopLeftHeadList[index]}`}
                    data-url={item.linkUrl}
                    key={index}
                    field={{ value: item.title }}
                    style={{ color: 'black', pointerEvents: item.title === '友情链接' ? 'none' : 'all' }}
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
            <div className="FotterTopLeftBottom">
              {top_left_data.map((item, index) => {
                return index === 0 ? (
                  <div key={index}>
                    {item.listItem.map((ite, id) => {
                      return (
                        <Link
                          field={{ value: ite.title }}
                          key={id}
                          rel={ite.nofollow ? 'nofollow' : ''}
                          onClick={(e) => {
                            var url = ite.linkUrl;
                            if (ite.title === '广汽本田商城') {
                              GotoMall(ite.linkUrl, ite.dmp ? ite.dmp : carList[id], isMobile);
                            } else {
                              if (ite.target) {
                                window.open(ite.linkUrl);
                              } else {
                                location.href = ite.linkUrl;
                              }
                              window.Dmp.clickAction(ite.dmp ? ite.dmp : carList[id], url);
                            }
                          }}
                        >
                          {ite.title}
                        </Link>
                      );
                    })}
                  </div>
                ) : index === 1 ? (
                  <div key={index}>
                    {item.listItem.map((ite, idx) => {
                      return (
                        <Link
                          key={idx === 2 ? 7 : idx < 2 ? idx + 1 : idx}
                          field={{ value: ite.title }}
                          rel={ite.nofollow ? 'nofollow' : ''}
                          onClick={(e) => {
                            var url = ite.linkUrl;
                            if (ite.title === '广汽本田商城') {
                              GotoMall(
                                ite.linkUrl,
                                ite.dmp ? ite.dmp : fotterTopLeftHeadList[index] + (idx === 2 ? 7 : idx < 2 ? idx + 1 : idx),
                                isMobile
                              );
                            } else {
                              if (ite.target) {
                                window.open(ite.linkUrl);
                              } else {
                                location.href = ite.linkUrl;
                              }
                              window.Dmp.clickAction(
                                ite.dmp ? ite.dmp : fotterTopLeftHeadList[index] + (idx === 2 ? 7 : idx < 2 ? idx + 1 : idx),
                                url
                              );
                            }
                          }}
                        >
                          {ite.title}
                        </Link>
                      );
                    })}
                  </div>
                ) : (
                  <div key={index}>
                    {item.listItem.map((ite, idx) => {
                      return (
                        <Link
                          key={idx}
                          field={{ value: ite.title }}
                          rel={ite.nofollow ? 'nofollow' : ''}
                          onClick={(e) => {
                            var url = ite.linkUrl;
                            if (ite.title === '广汽本田商城') {
                              GotoMall(ite.linkUrl, ite.dmp ? ite.dmp : fotterTopLeftHeadList[index] + (idx + 1), isMobile);
                            } else {
                              if (ite.target) {
                                window.open(ite.linkUrl);
                              } else {
                                location.href = ite.linkUrl;
                              }
                              window.Dmp.clickAction(ite.dmp ? ite.dmp : fotterTopLeftHeadList[index] + (idx + 1), url);
                            }
                          }}
                        >
                          {ite.title}
                        </Link>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="FotterTopRight">
            {pictrueList.map((item, index) => {
              return (
                <div key={index}>
                  <img src={createFilePath(item)} className={top_right_data[index].title !== '' ? '' : 'weChat'} alt="广汽本田" />
                  {index === 2 || index === 4 ? (
                    <Link
                      data-action="clickAction"
                      data-item={`${top_right_data[index].dmp ? top_right_data[index].dmp : 'bottom-button-btn' + (index + 1)}`}
                      field={{ value: top_right_data[index].title }}
                      data-url={top_right_data[index].linkUrl}
                      target={top_right_data[index].target ? '_blank ' : ''}
                      rel={top_right_data[index].nofollow ? 'nofollow' : ''}
                    >
                      {top_right_data[index].title !== '' ? (
                        <p
                          data-action="clickAction"
                          data-item={`${top_right_data[index].dmp ? top_right_data[index].dmp : 'bottom-button-btn' + (index + 1)}`}
                          data-url={top_right_data[index].linkUrl}
                          dangerouslySetInnerHTML={{ __html: top_right_data[index].title?.replaceHtml() }}
                          style={{ color: 'black' }}
                        ></p>
                      ) : (
                        ''
                      )}
                    </Link>
                  ) : (
                    <Link
                      data-action="clickAction"
                      data-item={`${top_right_data[index].dmp ? top_right_data[index].dmp : 'bottom-button-btn' + (index + 1)}`}
                      data-url={top_right_data[index].linkUrl}
                      href={top_right_data[index].linkUrl}
                      field={{ value: top_right_data[index].title }}
                      key={index}
                      target={top_right_data[index].target ? '_blank ' : ''}
                      rel={top_right_data[index].nofollow ? 'nofollow' : ''}
                    >
                      {top_right_data[index].title !== '' ? (
                        <div
                          style={{ color: 'black' }}
                          data-action="clickAction"
                          data-item={`${top_right_data[index].dmp ? top_right_data[index].dmp : 'bottom-button-btn' + (index + 1)}`}
                          data-url={top_right_data[index].linkUrl}
                          dangerouslySetInnerHTML={{ __html: top_right_data[index].title?.replaceHtml() }}
                        ></div>
                      ) : (
                        ''
                      )}
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Fotter">
          <div className="FotterBtm">
            {bottom_other_link_title.map((item, index) => {
              return (
                <Link
                  data-action="clickAction"
                  data-item={`${item.dmp ? item.dmp : 'bottom-button-btn' + fotterBtmList[index]}`}
                  data-url={item.linkUrl}
                  href={item.linkUrl}
                  key={index}
                  field={{ value: item.title }}
                  style={{ color: 'black' }}
                  target={item.target ? '_blank ' : ''}
                  rel={item.nofollow ? 'nofollow' : ''}
                >
                  {item.title}
                </Link>
              );
            })}
            <div className="FotterBottom" style={{ color: 'black' }}>
              <p style={{ color: 'black' }}>
                ©2024 广汽本田汽车有限公司版权所有{' '}
                <a style={{ color: 'black' }} href="https://beian.miit.gov.cn" target="_blank" rel="nofollow noreferrer">
                  粤ICP备 13029665号
                </a>
              </p>
              <a
                style={{ color: 'black' }}
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202000012"
                target="_blank"
                rel="nofollow noreferrer"
              >
                粤公网安备 44011202000012号
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
