import React, { useEffect, useState } from 'react';
import temp from '../../temp/config';
import { getQueryString, createFilePath } from '../../utils/obj-utils';
import './style.scss';

export default function index({ fields }) {
  const atlas = fields;
  useEffect(() => {
    const car_sort = getQueryString('car_sort');
    const emptyitem = {
      id: '',
      url: '',
      name: 'empty-item',
      displayName: 'components-item',
      fields: {
        atlas: {
          value: ''
        },
        exhibition: {
          value: ''
        },
        flag: {
          value: ''
        },
        img: {
          value: '/service/supplies-atlas/v1/pc/empty.png'
        },
        links: {
          value: ''
        }
      }
    };
    if (car_sort && fields.data.length > 0) {
      const car_sort_attry = car_sort.split(',');
      const car_item = fields.data;
      const new_item = [];
      for (let j = 0; j < car_sort_attry.length; j++) {
        const item2 = car_sort_attry[j];
        for (let i = 0; i < car_item.length; i++) {
          const item1 = car_item[i];
          if (item1?.fields?.flag?.value === item2) {
            new_item.push(item1);
            car_item.splice(i, 1);
            break;
          }
        }
      }
      atlas?.data.unshift.apply(atlas?.data, new_item);
    }
    if (atlas?.data.length % 2 !== 0) {
      atlas?.data.push(emptyitem);
    }
  }, []);
  return atlas?.data.length > 0 ? (
    <div className="supplies-atlas">
      {atlas?.data?.map((item, index) => {
        return (
          <div key={index} className="item" flag={item?.fields?.flag?.value}>
            <div className="in">
              {item?.fields?.links?.value ? (
                <a
                  className={item?.name === 'empty-item' ? 'img img-empty' : 'img'}
                  href={item?.fields?.links?.value}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="广汽本田" src={`${createFilePath(item?.fields?.img?.value)}`} />
                </a>
              ) : (
                <div className={item?.name === 'empty-item' ? 'img img-empty' : 'img'}>
                  <img alt="广汽本田" src={`${createFilePath(item?.fields?.img?.value)}`} />
                </div>
              )}
              {item?.fields?.atlas?.value || item?.fields?.exhibition?.value ? (
                <div className="meau">
                  {item?.fields?.atlas?.value ? (
                    <a href={item?.fields?.atlas?.value} target="_blank" rel="noreferrer">
                      用品图册
                    </a>
                  ) : (
                    ''
                  )}
                  {item?.fields?.exhibition?.value ? (
                    <a href={item?.fields?.exhibition?.value} target="_blank" rel="noreferrer">
                      用品云展厅
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <div className="empty">暂无数据</div>
  );
}
