import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import './style.scss';
export default function index(props) {
  const node = document.createElement('div');
  node.className = 'FullModelBoxs';
  document.body.appendChild(node);
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.body.removeChild(node);
      document.documentElement.style.overflow = 'auto';
      document.documentElement.style.overflowX = 'hidden';
    };
  }, []);
  function changeProps(props) {
    return props;
  }
  useMemo(() => {
    changeProps(props);
  }, [props]);
  return createPortal(
    <div className="FullModels">
      <div className="FullModelContents">{props.children}</div>
    </div>,
    node
  );
}
