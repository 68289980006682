import React, { useState, useImperativeHandle, useRef, useEffect } from 'react';
import TextScrollytelling from '../../elements/TextScrollytelling_v3';
import Detailss from '../../elements/InnerPageDetail';
import { sendDmpAction, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import temp from '../../temp/config';
import { useSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const { fields } = props;
  const rootEl = useRef(null);
  const secondPageRef = useRef(null);
  const secondPageEventRef = useRef(null);
  const thirdPageRef = useRef(null);
  const thirdPageEventRef = useRef(null);
  const [flag, setFlag] = useState(false);
  // const deployUrlS3 = temp.app.deployUrlS3;
  // const video_value = `${deployUrlS3}${fields.dialog_video?.value}`;
  const video_value = createFilePath(fields.dialog_video?.value);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename ? props?.params?.pagename : '';
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const openVideo = () => {
    setFlag(true);
    sendDmpAction(
      fields.video_btn_dmp?.length > 0 ? fields.video_btn_dmp[0]?.fields?.func?.value : '',
      fields.video_btn_dmp?.length > 0 ? fields.video_btn_dmp[0].name : '',
      fields.dialog_video?.value ? video_value : ''
    );
  };
  useEffect(() => {
    if (fields?.placeHolderRoute1?.value && fields?.placeHolderRoute2?.value) {
      props.updateRefList([sendFirstDmp, sendSecondDmp, sendThirdDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current, thirdPageRef?.current]);
    } else if (fields?.placeHolderRoute1?.value && !fields?.placeHolderRoute2?.value) {
      props.updateRefList([sendFirstDmp, sendSecondDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current]);
    } else if (!fields?.placeHolderRoute1?.value && fields?.placeHolderRoute2?.value) {
      props.updateRefList([sendFirstDmp, sendThirdDmp]);
      props.updateDmpList([rootEl?.current, thirdPageRef?.current]);
    } else {
      props.updateRefList([sendFirstDmp]);
      props.updateDmpList([rootEl?.current]);
    }
  }, []);
  const sendFirstDmp = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename || '';
  };

  const sendSecondDmp = () => {
    secondPageEventRef?.current?.sendEvent();
  };

  const sendThirdDmp = () => {
    thirdPageEventRef?.current?.sendEvent();
  };

  const sendEvent = () => {
    window.Dmp.sectionAction(fields.id.value);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename || '';
  };

  return (
    <div>
      <div
        className="htmlhidden"
        dangerouslySetInnerHTML={{
          __html: fields[`${getPrefixField('sethtml', isMobile)}`].value?.replaceHtml()
        }}
      ></div>
      <div ref={rootEl}>
        <TextScrollytelling {...fields} flag={flag} id={id} open={() => openVideo()} />
      </div>
      {flag && (
        <Detailss
          routePath={props.fields.detail_btn_href.value}
          {...fields}
          close={() => setFlag(false)}
          isModal={document.documentElement.clientWidth <= 1024}
        />
      )}
      {fields?.placeHolderRoute1?.value && (
        <div ref={secondPageRef}>
          <Placeholder name={fields.placeHolderRoute1.value} rendering={route} page={route} eventRef={secondPageEventRef} />
        </div>
      )}
      {fields?.placeHolderRoute2?.value && (
        <div ref={thirdPageRef}>
          <Placeholder name={fields.placeHolderRoute2.value} rendering={route} page={route} eventRef={thirdPageEventRef} />
        </div>
      )}
    </div>
  );
}
