import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import './style.scss';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import {
  sendDmp,
  getPrefixField,
  sendDmpAction,
  convertStylesStringToObject,
  isMobileFun,
  createFilePath,
  getQueryString,
  GotoMall
} from '../../utils/obj-utils';
import { GetGmallAppSchemeUrl } from '../../utils/api';

export default function index(props) {
  const { fields, num, setNum, setFlag, thisMobile, rootEl, isWX, isIphone, paused, showcode } = props;
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const enterpriserootEl = useRef(null);
  const once = useRef(true);
  const isMobile = isMobileFun();
  useEffect(() => {
    if (paused || (thisMobile && !once?.current)) {
      return;
    }

    const timeline = gsap.timeline({
      duration: 1.5,
      onComplete: () => {
        timeline.kill();
      }
    });
    if (thisMobile) {
      once.current = false;
      timeline.fromTo(rightRef?.current, { x: '-100%' }, { x: 0 }, '<');
      if (enterpriserootEl?.current) {
        timeline.fromTo(enterpriserootEl?.current, { opacity: 0 }, { opacity: 1 }, '<');
      }
    } else {
      timeline.fromTo(rightRef?.current, { x: '-100%' }, { x: 0 }, '<').fromTo(leftRef?.current, { height: 0 }, { height: '100%' });
      if (enterpriserootEl?.current) {
        timeline.fromTo(enterpriserootEl?.current, { opacity: 0 }, { opacity: 1 }, '<');
      }
    }

    return () => {
      timeline.kill();
    };
  }, [paused]);

  useEffect(() => {
    if (!fields[`${getPrefixField('show_tab_img', thisMobile)}`].value || num === null) {
      return;
    }
    const timeline = gsap.timeline({
      onComplete: () => {
        timeline.kill();
      }
    });
    timeline.fromTo(rightRef?.current, { opacity: 0 }, { opacity: 1, duration: 0.5 }, '<');
    return () => {
      timeline.kill();
    };
  }, [num]);

  const renderLeft = () => {
    return (
      <div
        className={`buyCarLeftCenter ${
          fields[getPrefixField('car_type_list', thisMobile)][num]?.fields?.show_text?.value ? 'buyCarLeftCenterText' : ''
        }`}
        ref={leftRef}
        style={convertStylesStringToObject(fields[`${getPrefixField('info_container_style', thisMobile)}`]?.value)}
      >
        <div className="tab">
          {fields[`${getPrefixField('car_type_list', thisMobile)}`].map((item, index) => {
            return (
              <p
                key={index}
                className={index === num ? 'buyCarActive' : ''}
                onClick={() => {
                  setNum(index);
                  sendDmp(item.fields.tab_dmp);
                }}
                dangerouslySetInnerHTML={{ __html: item.fields.tab.value?.replaceHtml() }}
              ></p>
            );
          })}
        </div>
        {fields[`${getPrefixField('car_type_list', thisMobile)}`].map((ele, index) => {
          return (
            index === num && (
              <React.Fragment key={`t${index}`}>
                <div className="top">
                  <div className="t" style={{ position: 'relative' }}>
                    <div className="h1" dangerouslySetInnerHTML={{ __html: ele.fields.title.value?.replaceHtml() }} />
                    <div className="time" dangerouslySetInnerHTML={{ __html: ele.fields.active.value?.replaceHtml() }} />
                  </div>
                </div>
                {ele?.fields?.show_text?.value && (
                  <div className="con1" dangerouslySetInnerHTML={{ __html: ele?.fields?.text?.value?.replaceHtml() }}></div>
                )}
                {!ele?.fields?.show_text?.value && (
                  <div className="lists" key={`list${index}`} style={convertStylesStringToObject(ele.fields.info_list_container_style?.value)}>
                    {ele.fields.info_list.map((item, index) => {
                      return (
                        <div key={index} className="item" style={convertStylesStringToObject(ele.fields.info_list_container_item_style?.value)}>
                          <img alt="广汽本田" src={`${createFilePath(item.fields.icon.value)}`} />
                          <div
                            style={convertStylesStringToObject(ele.fields.info_list_container_item_title_style?.value)}
                            dangerouslySetInnerHTML={{ __html: item.fields.title.value?.replaceHtml() }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </React.Fragment>
            )
          );
        })}
        <div className="button_container">
          <div
            onClick={() => {
              var val = fields[`${getPrefixField('car_type_list', thisMobile)}`][num].fields.mall_url.value;
              const dmpPointList = fields[`${getPrefixField('car_type_list', thisMobile)}`][num].fields.mall_btn_dmp;
              const dmpAction = dmpPointList?.length > 0 ? dmpPointList[0]?.fields?.func?.value : '';
              const dmpPoint = dmpPointList?.length > 0 ? dmpPointList[0].name : '';
              GotoMall(val, dmpPoint, isMobile);
            }}
            dangerouslySetInnerHTML={{
              __html: fields[`${getPrefixField('car_type_list', thisMobile)}`][num].fields.mall_btn_text.value?.replaceHtml()
            }}
          />
          <div
            onClick={() => {
              setFlag(true);
              const dmpPointList = fields[`${getPrefixField('car_type_list', thisMobile)}`][num].fields.detail_btn_dmp;
              const dmpAction = dmpPointList?.length > 0 ? dmpPointList[0]?.fields?.func?.value : '';
              const dmpPoint = dmpPointList?.length > 0 ? dmpPointList[0].name : '';
              sendDmpAction(dmpAction, dmpPoint);
            }}
            dangerouslySetInnerHTML={{
              __html: fields[`${getPrefixField('car_type_list', thisMobile)}`][num].fields.detail_btn_text.value?.replaceHtml()
            }}
          />
        </div>
        {!isMobile && (
          <div
            ref={enterpriserootEl}
            dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('enterprise', isMobile)}`].value?.replaceHtml() }}
          ></div>
        )}
      </div>
    );
  };

  const renderRight = () => {
    let wx = '30vh';
    let iphone = '20vh';
    let noIphone = '5vh';
    let i = 0;
    if (num !== null) i = num;
    if (fields[`${getPrefixField('car_type_list', thisMobile)}`][i]?.fields?.custom_top?.value) {
      if (fields[`${getPrefixField('car_type_list', thisMobile)}`][i].fields.wx_top?.value) {
        wx = fields[`${getPrefixField('car_type_list', thisMobile)}`][i].fields.wx_top?.value;
      }
      if (fields[`${getPrefixField('car_type_list', thisMobile)}`][i].fields.iphone_top?.value) {
        iphone = fields[`${getPrefixField('car_type_list', thisMobile)}`][i].fields.iphone_top?.value;
      }
      if (fields[`${getPrefixField('car_type_list', thisMobile)}`][i].fields.no_iphone_top?.value) {
        noIphone = fields[`${getPrefixField('car_type_list', thisMobile)}`][i].fields.no_iphone_top?.value;
      }
    }
    return (
      <div
        className="buyCarRight"
        style={{
          marginTop: thisMobile && isWX ? wx : thisMobile && !isWX && !isIphone ? noIphone : thisMobile && !isWX && isIphone ? iphone : ''
        }}
        ref={rightRef}
      >
        {!fields[`${getPrefixField('show_tab_img', thisMobile)}`].value && (
          <img alt="广汽本田" src={`${createFilePath(fields[`${getPrefixField('half_car_bg', thisMobile)}`].value)}`} />
        )}
        {fields[`${getPrefixField('show_tab_img', thisMobile)}`].value &&
          fields[`${getPrefixField('car_type_list', thisMobile)}`].map((item, index) => {
            return (
              <img
                alt="广汽本田"
                key={index}
                style={{ display: index !== num ? 'none' : '' }}
                src={`${createFilePath(item.fields.half_car_bg.value)}`}
              />
            );
          })}
      </div>
    );
  };

  return (
    <>
      <div
        className="LargeImageContent"
        id="activity"
        ref={rootEl}
        style={convertStylesStringToObject(fields[`${getPrefixField('container_style', thisMobile)}`]?.value)}
      >
        <div>
          {fields[`${getPrefixField('isImgLeft', thisMobile)}`]?.value && renderRight()}
          <div className="buyCarLeft">{renderLeft()}</div>
          {!fields[`${getPrefixField('isImgLeft', thisMobile)}`]?.value && renderRight()}
        </div>
      </div>
      {/* {isMobile && (
        <>
          {showcode && (
            <div
              ref={enterpriserootEl}
              dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('enterprise', isMobile)}`].value?.replaceHtml() }}
            ></div>
          )}
        </>
      )} */}
    </>
  );
}
