import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import './style.scss';
import temp from '../../temp/config';
import { Timeline, Tween } from 'react-gsap';
import { isMobile as isDeviceMobile } from '../../utils/dom-utils';

function index(props) {
  const [thisMobile, setThisMobile] = useState(isDeviceMobile());
  const { img, text, btn, m_img, m_text, m_btn, btnLink, pageDmp, btnDmp } = props.fields;
  const rootEl = useRef(null);
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    setThisMobile(document.documentElement.clientWidth > 1024);
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (rootEl?.current?.offsetTop - document.documentElement.clientHeight <= document.documentElement.scrollTop) {
        setFlag(true);
      }
    });
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(pageDmp[0]?.displayName);
    setFlag(true);
    // 二级导航高亮设置
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props.fields.pageId.value;
  };
  return thisMobile ? (
    flag ? (
      <div
        className="BuyCar_integra_pc"
        ref={rootEl}
        id={props.fields.pageId.value}
        style={{ background: props.fields.backColor.value, gridTemplateColumns: !props.fields.reserve.value ? '3fr 2fr' : '3fr 4fr' }}
      >
        {!props.fields.reserve.value ? (
          <Timeline duration={1.5}>
            <Tween from={{ x: '100%' }} to={{ x: 0 }}>
              {/* <img alt="广汽本田" src={`${temp.app.deployUrlS3}${img.value}`} /> */}
              <img alt="广汽本田" src={createFilePath(img.value)} />
            </Tween>
            <div>
              <div dangerouslySetInnerHTML={{ __html: text.value?.replaceHtml() }}></div>
              <div
                onClick={() => {
                  window.Dmp.clickAction(btnDmp[0]?.displayName, btnLink?.value, '');
                  window.open(btnLink?.value);
                }}
                dangerouslySetInnerHTML={{ __html: btn.value?.replaceHtml() }}
              ></div>
              <div style={{ background: props.fields.backColor.value }}></div>
            </div>
          </Timeline>
        ) : (
          <Timeline duration={1.5}>
            <div>
              <div dangerouslySetInnerHTML={{ __html: text.value?.replaceHtml() }}></div>
              <div
                onClick={() => {
                  window.Dmp.clickAction(btnDmp[0]?.displayName, btnLink?.value, '');
                  window.open(btnLink?.value);
                }}
                dangerouslySetInnerHTML={{ __html: btn.value?.replaceHtml() }}
              ></div>
              <div style={{ background: props.fields.backColor.value }}></div>
            </div>
            <Tween from={{ x: '100%' }} to={{ x: 0 }}>
              {/* <img alt="广汽本田" src={`${temp.app.deployUrlS3}${img.value}`} /> */}
              <img alt="广汽本田" src={createFilePath(img.value)} />
            </Tween>
          </Timeline>
        )}
      </div>
    ) : (
      <div className="BuyCar_integra_pc" ref={rootEl} id={props.fields.pageId.value} style={{ background: props.fields.backColor.value }}></div>
    )
  ) : flag ? (
    <div className="BuyCar_integra_mobile" ref={rootEl} id={props.fields.pageId.value} style={{ background: props.fields.backColor.value }}>
      <Timeline duration={1.5}>
        <div>
          <div dangerouslySetInnerHTML={{ __html: m_text.value?.replaceHtml() }}></div>
          <div
            onClick={() => {
              window.Dmp.clickAction(btnDmp[0]?.displayName, btnLink?.value, '');
              window.open(btnLink?.value);
            }}
            dangerouslySetInnerHTML={{ __html: m_btn.value?.replaceHtml() }}
          ></div>
          <div style={{ background: props.fields.backColor.value }}></div>
        </div>
        <Tween from={{ x: '-100%' }} to={{ x: 0 }}>
          {/* <img alt="广汽本田" src={`${temp.app.deployUrlS3}${m_img.value}`} /> */}
          <img alt="广汽本田" src={createFilePath(m_img.value)} />
        </Tween>
      </Timeline>
    </div>
  ) : (
    <div className="BuyCar_integra_mobile" ref={rootEl} id={props.fields.pageId.value} style={{ background: props.fields.backColor.value }}></div>
  );
}

export default index;
