export const MODAL_ANI_TYPES = {
  none: 'none',
  flipDown: 'flip-down',
  FadeIn: 'fade-in',
  bottomFadeIn: 'bottom-fade-in',
  leftFadeIn: 'left-fade-in'
};
export const modalAnimations = {
  [MODAL_ANI_TYPES.flipDown]: {
    style: {
      originX: 0,
      originY: '100%'
    },
    variants: {
      start: { scale: 2, x: -100, rotate: -30, opacity: 0 },
      visible: { scale: 1, x: 0, rotate: 0, opacity: 1, transition: { duration: 0.3 } },
      exit: { scale: 2, x: -100, rotate: -30, opacity: 0, transition: { duration: 0.15 } }
    }
  },
  [MODAL_ANI_TYPES.FadeIn]: {
    variants: {
      start: { opacity: 0 },
      visible: { opacity: 1, transition: { duration: 0.3 } },
      exit: { opacity: 0, transition: { duration: 0.15 } }
    }
  },
  [MODAL_ANI_TYPES.bottomFadeIn]: {
    style: {
      originX: 0,
      originY: '100%'
    },
    variants: {
      start: { y: 100, rotate: 0, opacity: 0 },
      visible: { y: 0, rotate: 0, opacity: 1, transition: { duration: 0.3 } },
      exit: { y: 100, rotate: 0, opacity: 0, transition: { duration: 0.15 } }
    }
  },
  [MODAL_ANI_TYPES.leftFadeIn]: {
    style: {
      originX: '-100%',
      originY: 0
    },
    variants: {
      start: { x: -100, rotate: 0, opacity: 0 },
      visible: { x: 0, rotate: 0, opacity: 1, transition: { duration: 0.3 } },
      exit: { x: -100, rotate: 0, opacity: 0, transition: { duration: 0.15 } }
    }
  }
};
