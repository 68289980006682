/* eslint-disable no-bitwise */
import React, { useEffect, useState, useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';
import { createFilePath, getPrefixField, isEmpty, setUrlParameter, getQueryString, isMobileFun } from '../../utils/obj-utils';
import FullModalDetail from '../../elements/FullModalDetail_v1';

export default function index({ fields }) {
  const pageSize = 6;
  const history = useHistory();
  const isMobile = isMobileFun();
  const containerRef = useRef(null);
  const modalRef = useRef(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [data, setData] = useState({ knowledges: [], vehicles: [] });
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [vehicleIndex, setVehicleIndex] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [keyword, setKeyword] = useState();
  const [detail, setDetail] = useState(null);
  const desc = fields[getPrefixField('desc', isMobile)]?.value?.replaceHtml();
  const qrcode = createFilePath(fields?.qrcode?.value);
  const qrcodeText = fields?.qrcodeText?.value?.replaceHtml();
  const noResultTips = fields[getPrefixField('noResultTips', isMobile)]?.value?.replaceHtml();

  useEffect(() => {
    if (getQueryString('type') === 'video') {
      tabClick(true);
    }
  }, []);

  useEffect(() => {
    getData(pageIndex);
  }, [pageIndex]);

  const getData = (pageIndex = 1) => {
    setIsLoading(true);
    dataFetcher(`/api/honda/v1/Article/GetArticleList?articleType=5&pageIndex=${pageIndex}&pageSize=${pageSize}&client=0`)
      .then(({ data: response }) => {
        setIsLoading(false);
        if (response.Status) {
          const knowledges = response?.Data[0]?.Knowledges?.text?.articles;
          const vehicles = response?.Data[0]?.Knowledges?.video?.article_vehicles;
          setShowLoadMore(knowledges?.length === pageSize);
          if (knowledges?.length !== 0) {
            setData({ knowledges: pageIndex === 1 ? [...knowledges] : [...data?.knowledges, ...knowledges], vehicles });
          }
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const getSearchData = () => {
    dataFetcher(`/api/honda/v1/Article/SearchKnowledgeText?key=${keyword}`)
      .then(({ data: response }) => {
        if (response.Status) {
          setData({ knowledges: [...response?.Data?.Data], vehicles: [...data?.vehicles] });
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const tabClick = (video = false) => {
    setUrlParameter({ type: video ? 'video' : '' });
    setIsVideo(video);
    setVehicleIndex(null);
    setKeyword();
    setIsSearch(false);
    updateSearchText();
    if (!video && isVideo !== video) {
      reloadKnowledges();
    }
  };

  const keywordInput = (e) => {
    setKeyword(e.target.value);
  };

  const search = () => {
    if (isVideo && vehicleIndex === null) {
      alert('请选择车型');
      return;
    }
    if (isEmpty(keyword)) {
      alert('请输入关键字');
      return;
    }
    setIsSearch(true);
    if (!isVideo) {
      getSearchData();
    }
  };

  const back = () => {
    setIsSearch(false);
    updateSearchText(keyword);
    if (!isVideo) {
      reloadKnowledges();
    }
  };

  const itemClick = (item, index) => {
    if (isVideo) {
      if (vehicleIndex === null) {
        setVehicleIndex(index);
      } else {
        setDetail(item);
      }
    } else {
      if (item?.click_event === 'blank') {
        history.push(`/service/knowledge/detail?itempath=${item?.item_path?.replace(/{(.*?)}/g, '$1')}`);
      } else {
        setDetail(item);
      }
    }
  };

  const loadMore = () => {
    setPageIndex(pageIndex + 1);
  };

  const modalClick = (e) => {
    const content = modalRef?.current?.querySelector('.knowledge-v1-modal');
    if (!content.contains(e.target)) {
      modalClose();
    }
  };

  const modalClose = () => {
    setDetail();
  };

  const reloadKnowledges = () => {
    if (pageIndex === 1) {
      getData();
    } else {
      setPageIndex(1);
    }
  };

  const updateSearchText = (text = '') => {
    setTimeout(() => {
      const input = containerRef?.current?.querySelector('.search > input');
      input && (input.value = text);
    }, 0);
  };

  const getSearch = () => {
    return (
      <div className="search">
        <input placeholder="请输入关键词" type="text" maxLength="20" onInput={keywordInput} onKeyDown={(e) => e.key === 'Enter' && search()} />
        <button style={{ backgroundImage: `url(${createFilePath('/knowledge/search_icon.png')})` }} onClick={search} />
      </div>
    );
  };

  const getTips = () => {
    return (
      <div className="tips">
        为您找到<b>{list?.length}</b>条记录
        <button onClick={back}>
          返回
          <img alt="广汽本田" src={createFilePath('/knowledge/return_icon.png')} />
        </button>
      </div>
    );
  };

  const isVehicle = isVideo && vehicleIndex === null;
  let list = isVideo ? (vehicleIndex === null ? data?.vehicles : data?.vehicles[vehicleIndex]?.articles) : data?.knowledges;
  if (isVideo && vehicleIndex !== null && !isEmpty(keyword) && isSearch) {
    list = list?.filter((item) => item?.a_title?.indexOf(keyword) !== -1);
  }

  return (
    <section className="knowledge-v1" ref={containerRef}>
      <div className="title">用车知识</div>
      <div className="top-container">
        {!isMobile && (
          <div className="qrcode-container" style={{ backgroundImage: `url(${createFilePath('/knowledge/cir.png')})` }}>
            <img alt="广汽本田" src={qrcode} />
            <p dangerouslySetInnerHTML={{ __html: qrcodeText }} />
          </div>
        )}
        <p className="desc" dangerouslySetInnerHTML={{ __html: desc }} />
      </div>
      <div className="search-container">
        {isMobile ? (
          <>
            {getSearch()}
            {isSearch && getTips()}
          </>
        ) : !isSearch ? (
          getSearch()
        ) : (
          getTips()
        )}
        <div className="tab-container">
          <button className={`tab-item${isVideo ? '' : ' on'}`} onClick={() => tabClick()}>
            文章讲解
          </button>
          <button className={`tab-item${isVideo ? ' on' : ''}`} onClick={() => tabClick(true)}>
            视频讲解
          </button>
        </div>
      </div>
      {list?.length > 0 && (
        <ul className="list">
          {list?.map((item, index) => {
            return (
              <li className={`item${isVehicle ? ' vehicle' : ''}`} key={index} onClick={() => itemClick(item, index)}>
                {isVehicle ? (
                  <img alt={item.a_title} className="picture" src={createFilePath(item?.thumb?.replaceHtml())} />
                ) : (
                  <div className="item-container">
                    <div className="picture-container">
                      <img className="picture" src={createFilePath(item?.a_thumb?.replaceHtml())} alt={item.a_title} />
                      {isVideo && vehicleIndex !== null && (
                        <img alt={item.a_title} className="play" src={createFilePath('/knowledge/play_icon.png')} />
                      )}
                    </div>
                    <div className="detail">
                      {item.a_title}
                      <img alt={item.a_title} className="more-image" src={createFilePath('/common/icon/more.png')} />
                    </div>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      )}
      {isSearch && list?.length === 0 && <div className="no-result" dangerouslySetInnerHTML={{ __html: noResultTips }} />}
      {data?.knowledges?.length > 0 && showLoadMore && !isLoading && !isVideo && !isSearch && (
        <div className="load-more-container" onClick={loadMore}>
          加载更多
        </div>
      )}
      {detail?.click_event?.indexOf('pop') >= 0 && (
        <FullModalDetail style={{ background: isMobile ? 'rgba(0, 0, 0, 0.95)' : 'rgba(0, 0, 0, 0.8)' }} onClick={modalClick} ref={modalRef}>
          <div className={`knowledge-v1-modal${isVideo ? ' video' : ''}`}>
            {isVideo ? (
              <video
                controls={true}
                src={createFilePath(detail[isMobile ? 'event_m_val' : 'event_pc_val']?.replaceHtml())}
                width="100%"
                height="100%"
                autoPlay={!isMobile}
              />
            ) : (
              <>
                <div className="title">{detail?.a_title}</div>
                <div className="desc" dangerouslySetInnerHTML={{ __html: detail?.a_intro?.replaceHtml() }}></div>
              </>
            )}
            <img alt="广汽本田" className="close" src={createFilePath('/knowledge/close.jpg')} onClick={modalClose} />
          </div>
        </FullModalDetail>
      )}
    </section>
  );
}
