import React, { useState, useEffect } from 'react';
import './style.scss';
import { getPrefixField, createFilePath } from '../../utils/obj-utils';
import Script from 'react-load-script';

export default function index(props) {
  const { car, isMobile, isWx, isWxReady, wxLink } = props;
  const name = car.fields[getPrefixField('name', isMobile)]?.value;
  const img = car.fields[getPrefixField('img', isMobile)]?.value;
  const btn_href = car.fields[getPrefixField('btn_href', isMobile)]?.value;
  const btn_name = car.fields[getPrefixField('btn_name', isMobile)]?.value;
  const manual_list = car.fields[getPrefixField('manual_list', isMobile)];
  const userManual_list = car.fields[getPrefixField('userManual_list', isMobile)];
  const btn_list = car.fields[getPrefixField('btn_list', isMobile)]?.value;
  const [selectUserValue, setSelectUserValue] = useState(userManual_list[0]?.fields?.manual_href?.value);
  const [selectValue, setSelectValue] = useState(manual_list[0]?.fields?.manual_href?.value);
  const [scriptLoad, setScriptLoad] = useState(false);
  const userListShow = (event) => {
    /* let screen = document.querySelector('.kv_v2');
    if (screen) {
      screen.classList.remove('kv-v2-show');
      void screen.offsetWidth;
      screen.classList.add('kv-v2-show');
    } */
    // const elementId = event.target;
    $('.click_on').hide();
    $('.user_manual_list').hide();
    $(event.target).siblings('.click_on').toggle();
    $(event.target).siblings('.user_manual_list').toggle();
  };
  return (
    <>
      <Script url={createFilePath('/common/lib/common/js/jquery.min.js')} onLoad={() => setScriptLoad(true)} />
      {isMobile ? (
        <div className="m_img_kuan">
          <div className="kuan_operat_manual">
            <img alt="广汽本田" src={`${createFilePath('/service/manual/mobile/product_bj.png')}`} className="bg" />
            <img alt="广汽本田" src={`${createFilePath(img)}`} className="product_img" />
            {/* <p className="click_on">
              <span className="actives">
                <a href={btn_href}>{btn_name}</a>
              </span>
              {manual_list &&
                manual_list.length > 0 &&
                manual_list.map((item, index) => {
                  return (
                    <span key={index} className="item">
                      {isWx && isWxReady ? (
                        item.fields.manual_href?.value ? (
                          <a href={item.fields.manual_href?.value}>{item.fields.manual_name?.value}</a>
                        ) : (
                          <wx-open-launch-weapp id="launch-btn" username="gh_27242c630198" path="pages/index/index">
                            <template>{item.fields.manual_name?.value}</template>
                          </wx-open-launch-weapp>
                        )
                      ) : (
                        <a href={item.fields.manual_href?.value ? item.fields.manual_href?.value : wxLink}>{item.fields.manual_name?.value}</a>
                      )}
                    </span>
                  );
                })}
            </p> */}
            <div className="operateLists">
              {(manual_list && manual_list.length > 0) || btn_href ? (
                <div className="kuan_operate_manual">
                  <span className="user_manual_btn" onClick={userListShow}>
                    操作视频<i className="downIcon"></i>
                  </span>
                  <div className="click_on">
                    <span className="actives">
                      <a href={btn_href}>{btn_name}</a>
                    </span>
                    {manual_list &&
                      manual_list.length > 0 &&
                      manual_list.map((item, index) => {
                        return (
                          <span key={index} className="item">
                            {isWx && isWxReady ? (
                              item.fields.manual_href?.value ? (
                                <a href={item.fields.manual_href?.value}>{item.fields.manual_name?.value}</a>
                              ) : (
                                <wx-open-launch-weapp id="launch-btn" username="gh_27242c630198" path="pages/index/index">
                                  <template>{item.fields.manual_name?.value}</template>
                                </wx-open-launch-weapp>
                              )
                            ) : (
                              <a href={item.fields.manual_href?.value ? item.fields.manual_href?.value : wxLink}>{item.fields.manual_name?.value}</a>
                            )}
                          </span>
                        );
                      })}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {userManual_list && userManual_list.length > 0 ? (
                <div className="kuan_user_manual">
                  <span
                    className="user_manual_btn"
                    onClick={userListShow}
                    /* onClick={() => {
                      userListShow();
                    }} */
                  >
                    用户手册<i className="downIcon"></i>
                  </span>
                  <div className="user_manual_list">
                    {userManual_list &&
                      userManual_list.length > 0 &&
                      userManual_list.map((item, index) => {
                        return (
                          <span key={index} className="item">
                            {isWx && isWxReady ? (
                              item.fields.manual_href?.value ? (
                                <a href={`${createFilePath(item.fields.manual_href?.value)}`}>{item.fields.manual_name?.value}</a>
                              ) : (
                                <wx-open-launch-weapp id="launch-btn" username="gh_27242c630198" path="pages/index/index">
                                  <template>{item.fields.manual_name?.value}</template>
                                </wx-open-launch-weapp>
                              )
                            ) : (
                              <a href={item.fields.manual_href?.value ? `${createFilePath(item.fields.manual_href?.value)}` : wxLink}>
                                {item.fields.manual_name?.value}
                              </a>
                            )}
                          </span>
                        );
                      })}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          {/* {userManual_list && userManual_list.length > 0 ? (
            <div className="kuan_user_manual">
              <span className="user_manual_btn">
                用户手册<i className="downIcon"></i>
              </span>
              <div className="user_manual_list">
                {userManual_list &&
                  userManual_list.length > 0 &&
                  userManual_list.map((item, index) => {
                    return (
                      <span key={index} className="item">
                        {isWx && isWxReady ? (
                          item.fields.manual_href?.value ? (
                            <a href={`${createFilePath(item.fields.manual_href?.value)}`}>{item.fields.manual_name?.value}</a>
                          ) : (
                            <wx-open-launch-weapp id="launch-btn" username="gh_27242c630198" path="pages/index/index">
                              <template>{item.fields.manual_name?.value}</template>
                            </wx-open-launch-weapp>
                          )
                        ) : (
                          <a href={item.fields.manual_href?.value ? `${createFilePath(item.fields.manual_href?.value)}` : wxLink}>
                            {item.fields.manual_name?.value}
                          </a>
                        )}
                      </span>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div className="kuan_user_manual"></div>
          )} */}
        </div>
      ) : (
        (manual_list.length > 0 || userManual_list.length > 0 || btn_href) && (
          <div className="img_kuan">
            <div className="pic">
              <img alt={name} src={`${createFilePath(img)}`} />
            </div>
            <p className="name" dangerouslySetInnerHTML={{ __html: name.replaceHtml() }} />
            {userManual_list && userManual_list.length > 0 ? (
              <p className="select">
                <select onChange={(e) => setSelectUserValue(e.target.value)} value={selectUserValue}>
                  {userManual_list.map((item, index) => {
                    return (
                      <option key={index} value={item.fields.manual_href?.value} selected={index === 0 ? true : false}>
                        {item.fields.manual_name?.value}
                      </option>
                    );
                  })}
                </select>
                <span
                  className="btn_a"
                  onClick={() => {
                    window.open(createFilePath(selectUserValue), '_blank');
                  }}
                >
                  点击查看
                </span>
              </p>
            ) : (
              <div className="no_select" />
            )}
            {manual_list && manual_list.length > 0 ? (
              <p className="select">
                <select onChange={(e) => setSelectValue(e.target.value)} value={selectValue}>
                  {manual_list.map((item, index) => {
                    return (
                      <option key={index} value={item.fields.manual_href?.value} selected={index === 0 ? true : false}>
                        {item.fields.manual_name?.value}
                      </option>
                    );
                  })}
                </select>
                <span
                  className="btn_a"
                  onClick={() => {
                    window.open(selectValue, '_blank');
                  }}
                >
                  点击查看
                </span>
              </p>
            ) : (
              <div className="no_select" />
            )}
            <div className="video_a">
              {btn_href ? (
                <a href={btn_href} target="_blank" rel="noreferrer">
                  {btn_name}
                </a>
              ) : (
                <a onClick={(e) => e.preventDefault()} rel="noreferrer">
                  {btn_name}
                </a>
              )}
              {/* <a href={btn_href ? btn_href : 'javascript:;'} target={btn_href ? '_blank' : ''} rel="noreferrer">
              {btn_name}
            </a> */}
              <div className="videoList" dangerouslySetInnerHTML={{ __html: btn_list.replaceHtml() }} />
            </div>
          </div>
        )
      )}
    </>
  );
}
