import React, { useState, forwardRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import temp from '../../temp/config';
import { convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';

const HeroLandingV2 = forwardRef((data, ref) => {
  const {
    position,
    btn,
    pc_image_video,
    btnPosition,
    className,
    btnFlag,
    m_btnFlag,
    m_position,
    styleBannerImg,
    m_bottom,
    textalign,
    m_styleBannerImg,
    m_textalign,
    spaceList,
    btn_style,
    view_img_url,
    view_text,
    view_url,
    view_style,
    titleLogo,
    pngLinks
  } = data.data.fields;
  // const thisMobile = !useSitecoreContext()?.sitecoreContext?.isMobile;
  const isMobile = isMobileFun();
  const thisMobile = !isMobileFun();

  const [selectBtn, setSelectBtn] = useState(0);
  const isPng = (value) => {
    if (value !== '') {
      return value.indexOf('png') !== -1 || value.indexOf('jpg') !== -1;
    }
  };
  const positionAuto = (value) => {
    if (value === undefined) return;
    if (value.indexOf('left') === 0) {
      return 'left';
    }
    if (value.indexOf('center') === 0) {
      return 'center';
    }
    if (value.indexOf('right') === 0) {
      return 'right';
    }
  };

  return (
    <div
      className={`heroLandingBoxV2 heroLandingBoxV2_${positionAuto(position?.fields?.k?.value || position?.value)} ${data.className || ''} ${
        className?.value || ''
      }`}
      ref={ref}
    >
      {thisMobile ? (
        isPng(spaceList[selectBtn].fields.pc_img?.value) ? (
          <img
            className="firstScreen"
            src={`${createFilePath(spaceList[selectBtn].fields.pc_img?.value)}`}
            style={convertStylesStringToObject(styleBannerImg?.value)}
            alt="广汽本田"
          />
        ) : (
          <video
            src={`${createFilePath(pc_image_video?.value)}`}
            autoPlay={true}
            muted={true}
            style={convertStylesStringToObject(styleBannerImg?.value)}
          />
        )
      ) : (
        <img
          className="firstScreen"
          src={`${createFilePath(spaceList[selectBtn].fields.m_image_video?.value)}`}
          style={convertStylesStringToObject(m_styleBannerImg?.value)}
          alt="广汽本田"
        />
      )}
      <div className="SwitchBtn" style={convertStylesStringToObject(btn_style?.value)}>
        {spaceList.length > 1 &&
          spaceList.map((item, index) => {
            return (
              <img
                alt="广汽本田"
                key={index}
                src={selectBtn === index ? `${createFilePath(item.fields.selectBtnImg?.value)}` : `${createFilePath(item.fields.btnImg?.value)}`}
                onClick={() => {
                  sendDmp(item?.fields?.colorDmp);
                  setSelectBtn(index);
                }}
              />
            );
          })}
      </div>
      <div
        className={`${ref ? '' : 'animate__fadeInDown'} text-container heroLanding_${
          thisMobile ? position?.fields?.k?.value || position?.value : m_position?.fields?.k?.value || position?.value
        }`}
        style={{
          top: m_bottom?.value !== '' ? m_bottom?.value : '',
          textAlign: thisMobile ? textalign?.value : m_textalign?.value,
          width: thisMobile && className?.value === 'breezeBannerWidth' ? '50%' : ''
        }}
      >
        {isPng(spaceList[selectBtn].fields.title?.value) ? (
          <img className="title" src={`${createFilePath(spaceList[selectBtn].fields.title?.value)}`} alt="title" />
        ) : (
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: (thisMobile ? spaceList[selectBtn].fields.title?.value : spaceList[selectBtn].fields.m_title?.value)?.replaceHtml()
            }}
          />
        )}
        {titleLogo?.value !== '' ? (
          <div dangerouslySetInnerHTML={{ __html: (thisMobile ? titleLogo?.value : titleLogo?.value)?.replaceHtml() }}></div>
        ) : (
          ''
        )}
        {isPng(thisMobile ? spaceList[selectBtn].fields.subtitle?.value : spaceList[selectBtn].fields.m_subtitle?.value) ? (
          <img
            src={`${createFilePath(thisMobile ? spaceList[selectBtn].fields.subtitle?.value : spaceList[selectBtn].fields.m_subtitle?.value)}`}
            alt="SubTitle"
          />
        ) : (
          <div
            className="subTitle specialSunTitle"
            dangerouslySetInnerHTML={{
              __html: (thisMobile ? spaceList[selectBtn].fields.subtitle?.value : spaceList[selectBtn].fields.m_subtitle?.value)?.replaceHtml()
            }}
          />
        )}
        {spaceList[selectBtn].fields.describe?.value !== '' || spaceList[selectBtn].fields.m_describe?.value !== '' ? (
          <div
            className="describe"
            dangerouslySetInnerHTML={{
              __html: (thisMobile ? spaceList[selectBtn].fields.describe?.value : spaceList[selectBtn].fields.m_describe?.value)?.replaceHtml()
            }}
          />
        ) : (
          ''
        )}
        {pngLinks?.fields?.k?.value === 'true' ? (
          <div className="pngLinks">
            <img src={createFilePath('/breeze/v1/pc/banner/red_line.png')} alt="img" />
          </div>
        ) : (
          ''
        )}
        {(btnFlag?.value === 'true' && thisMobile) || (m_btnFlag?.value === 'true' && !thisMobile) ? (
          <div className={`btnArr btnArr_${btnPosition?.value}`}>
            {btn &&
              btn.map((item) => {
                if (data?.checkDetail) {
                  return (
                    <button
                      className="particularsBtn"
                      key={item.id}
                      onClick={(e) => {
                        sendDmp(item?.fields['data-item']);
                        data?.checkDetail(true);
                      }}
                    >
                      {item?.fields?.title.value}
                    </button>
                  );
                } else {
                  return (
                    <button
                      className={`btn btn-${item?.fields?.class?.displayName} btn-${item?.fields?.type.displayName}`}
                      key={item.id}
                      onClick={() => {
                        sendDmp(item?.fields['data-item'], item?.fields?.url.value);
                        item?.fields?.url.value ? window.open(item?.fields?.url.value) : null;
                      }}
                      style={{
                        marginTop: thisMobile ? item?.fields?.pc_bottom?.value : item?.fields?.m_bottom?.value,
                        marginLeft: thisMobile ? item?.fields?.pc_left?.value : item?.fields?.m_left?.value
                      }}
                    >
                      {item?.fields?.title.value}
                    </button>
                  );
                }
              })}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="panorama" style={convertStylesStringToObject(view_style?.value)}>
        <a
          onClick={() => {
            window.Dmp.clickAction('price_space-inside', view_url?.value, '');
            window.open(view_url?.value);
          }}
        >
          <div>
            <img alt="广汽本田" src={`${createFilePath(view_img_url?.value)}`} />
            <p>{view_text?.value}</p>
          </div>
        </a>
      </div>
    </div>
  );
});
HeroLandingV2.displayName = 'HeroLandingV2';
export default HeroLandingV2;
