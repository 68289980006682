/* eslint-disable no-eval */
import React, { useEffect, useRef, createRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap, { Linear } from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import {
  convertStylesStringToObject,
  getPrefixField,
  sendDmp,
  createFilePath,
  isImage,
  getHeaderHeight,
  isEmpty,
  isMobileFun
} from '../../utils/obj-utils';
import Detail from '../../elements/InnerPageDetail';

export default function index({ fields, eventRef }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const containerRef = useRef(null);
  const screen2Ref = useRef(null);
  const screen2TitleRef = useRef(null);
  const screen2ModeRef = useRef(null);
  const screen3Ref = useRef(null);
  const screen3ImageRef = useRef(null);
  const screen3Image1Ref = useRef(null);
  const screen3BottomRef = useRef(null);
  const screen3MaskRef = useRef(null);
  const screen3MaskContentRef = useRef(null);
  const screen3MaskContent1Ref = useRef(null);
  const isMobile = isMobileFun();
  const sectionDmp = fields?.sectionDmp;
  const anchor = fields?.anchor?.value;
  const navigationIndex = fields?.navigationIndex?.value;
  const title1 = fields[getPrefixField('title1', isMobile)]?.value;
  const style1 = convertStylesStringToObject(fields[getPrefixField('style1', isMobile)]?.value);
  const title2 = fields[getPrefixField('title2', isMobile)]?.value;
  const background2 = fields[getPrefixField('background2', isMobile)]?.value;
  const modeList = fields[getPrefixField('modeList', isMobile)].map((item) => ({ ...item.fields }));
  const style2 = convertStylesStringToObject(fields[getPrefixField('style2', isMobile)]?.value);
  const title3 = fields[getPrefixField('title3', isMobile)]?.value;
  const desc3 = fields[getPrefixField('desc3', isMobile)]?.value;
  const image3 = fields[getPrefixField('image3', isMobile)]?.value?.split(',').map((item) => createFilePath(item));
  const [buttonList, setButtonList] = useState(fields[getPrefixField('buttonList', isMobile)].map((item) => ({ ...item.fields })));
  const maskStyle = convertStylesStringToObject(fields[getPrefixField('maskStyle', isMobile)]?.value);
  const style3 = convertStylesStringToObject(fields[getPrefixField('style3', isMobile)]?.value);
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const title4 = fields[getPrefixField('title4', isMobile)]?.value;
  const image4 = createFilePath(fields[getPrefixField('image4', isMobile)]?.value);
  const modeImageRefList = new Array(modeList.length - 1).fill().map(() => createRef());
  const image3Obj = { currentIndex: 0 };

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(sectionDmp);
    window.navigationIndex = navigationIndex;
    window.navigationPagename = window.navigationPagenameb = anchor;
  };

  useEffect(() => {
    loadImage();

    const controller = new ScrollMagic.Controller();
    const timeline1 = gsap.timeline({ paused: true }).fromTo(screen2TitleRef.current, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
    const scene1 = new ScrollMagic.Scene({
      triggerElement: screen2Ref.current,
      triggerHook: '0.8',
      duration: isMobile ? document.documentElement.clientHeight * 0.7 : '70%'
    })
      .setTween(timeline1)
      .addTo(controller);

    const size = modeImageRefList.length + 0.5 + 1 + 0.5 + 0.2 + 0.5 + 0.5;
    const timeline2 = gsap.timeline({ paused: true });
    const onceList = new Array(modeImageRefList.length).fill().map(() => ({ forward: true }));
    modeImageRefList.forEach((item, index) => {
      timeline2.to(item.current, {
        '--clip-right': '0',
        '--clip-top': '0',
        ease: Linear.easeNone,
        duration: 1,
        onUpdate: function () {
          const scrollDirection = controller.info('scrollDirection');
          const progress = this.progress();
          const once = onceList[index];
          if (scrollDirection === 'FORWARD') {
            if (progress > 0.5 && once.forward) {
              once.forward = false;
              textAnimate(index, index + 1);
            }
          } else {
            if (progress <= 0.5 && !once.forward) {
              once.forward = true;
              textAnimate(index + 1, index);
            }
          }
        }
      });
    });
    timeline2
      .fromTo(screen2ModeRef.current, { opacity: 1 }, { opacity: 0, duration: 0.5 })
      .fromTo(
        screen3Ref.current,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.5,
          onStart: () => {
            screen3MaskRef.current.style.opacity = 0;
          }
        },
        '<'
      )
      .fromTo(
        image3Obj,
        { ...image3Obj },
        {
          currentIndex: image3.length - 1,
          roundProps: 'currentIndex',
          immediateRender: true,
          ease: Linear.easeNone,
          duration: 1,
          onUpdate: function () {
            screen3ImageRef?.current?.setAttribute('src', image3[image3Obj?.currentIndex]);
          }
        }
      )
      .fromTo(screen3MaskRef.current, { opacity: 0 }, { opacity: 1, duration: 0.5 }, '<50%')
      .fromTo(screen3MaskContentRef.current, { y: '50%' }, { y: 0, duration: 0.5 }, '<')
      .to(_, { duration: 0.5 })
      .fromTo(screen3MaskContentRef.current, { opacity: 1 }, { opacity: 0, duration: 0.2 })
      .fromTo(screen3MaskContent1Ref.current, { opacity: 0 }, { opacity: 1, duration: 0.5 })
      .fromTo(screen3Image1Ref.current, { opacity: 0 }, { opacity: 1, duration: 0.5 }, '<')
      .fromTo(screen3BottomRef.current, { opacity: 0 }, { opacity: 1, duration: 0.5 })
      .fromTo(screen3MaskRef.current, { opacity: 1 }, { opacity: 0, duration: 0.5 }, '<');
    const duration = getHeaderHeight() / document.documentElement.clientHeight;
    const scene2 = new ScrollMagic.Scene({
      triggerElement: screen2Ref.current,
      triggerHook: duration,
      duration: isMobile ? document.documentElement.clientHeight * size * (1 - duration) : `${size * 100 * (1 - duration)}%`
    })
      .setPin(screen2Ref.current)
      .setTween(timeline2)
      .addTo(controller);

    return () => {
      scene1.remove();
      scene1.destroy(true);

      scene2.remove();
      scene2.destroy(true);

      controller.destroy();
    };
  }, []);

  const textAnimate = (index1, index2) => {
    gsap
      .timeline()
      .fromTo(screen2ModeRef.current.getElementsByClassName('title')[index1], { opacity: 1 }, { opacity: 0, duration: 0.6 })
      .fromTo(screen2ModeRef.current.getElementsByClassName('title')[index2], { opacity: 0 }, { opacity: 1, duration: 0.6 }, '<10%');
  };

  const loadImage = () => {
    const length = image3?.length;
    if (length > 0) {
      let index = 0;
      const node = document.createElement('img');
      node.src = image3[index];
      node.onload = () => {
        if (index < length - 1) {
          node.src = image3[++index];
        } else {
          setImageLoaded(true);
        }
      };
    }
  };

  const getScreen1Container = () => {
    return (
      <div className="screen1-container" style={style1}>
        {isImage(title1) ? (
          <img alt="广汽本田" className="title1" src={createFilePath(title1)} />
        ) : (
          <div className="title1" dangerouslySetInnerHTML={{ __html: title1?.replaceHtml() }} />
        )}
      </div>
    );
  };

  const getScreen2Container = () => {
    return (
      <div className="screen2-container" style={style2} ref={screen2Ref}>
        {isImage(background2) ? (
          <img alt="广汽本田" className="background2" src={createFilePath(background2)} />
        ) : (
          <div className="background2" dangerouslySetInnerHTML={{ __html: background2?.replaceHtml() }} />
        )}
        <div className="title2" dangerouslySetInnerHTML={{ __html: title2?.replaceHtml() }} ref={screen2TitleRef} />
        {getModeContainer()}
        {getScreen3Container()}
      </div>
    );
  };

  const getModeContainer = () => {
    return (
      <div className="mode-container" ref={screen2ModeRef}>
        {modeList?.map((item, index) => (
          <React.Fragment key={index}>
            <img alt="广汽本田" className="image" src={createFilePath(item?.image?.value)} ref={index > 0 ? modeImageRefList[index - 1] : null} />
            <div className="title" dangerouslySetInnerHTML={{ __html: item?.title?.value?.replaceHtml() }} />
          </React.Fragment>
        ))}
      </div>
    );
  };

  const getScreen3Container = () => {
    return (
      <div className="screen3-container" style={style3} ref={screen3Ref}>
        <img alt="广汽本田" className="image3" ref={screen3ImageRef} src={image3[image3Obj?.currentIndex]} />
        <img alt="广汽本田" className="image4" ref={screen3Image1Ref} src={image4} />
        <div className="bottom" ref={screen3BottomRef}>
          <div className="desc3" dangerouslySetInnerHTML={{ __html: desc3?.replaceHtml() }} />
          <div className="button-container">
            {buttonList?.map((item, index) => (
              <React.Fragment key={index}>
                <button
                  className="button"
                  style={convertStylesStringToObject(item?.style?.value)}
                  dangerouslySetInnerHTML={{ __html: item?.title?.value?.replaceHtml() }}
                  onClick={() => buttonClick(item)}
                />
                {item?.internalLink?.value && !isEmpty(item?.link?.value) && item?.show && (
                  <Detail routePath={item?.link?.value} close={() => close(item)} />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        {getMaskContainer()}
      </div>
    );
  };

  const getMaskContainer = () => {
    return (
      <div className="mask-container" style={maskStyle} ref={screen3MaskRef}>
        <div className="content" ref={screen3MaskContentRef}>
          {isImage(title3) ? (
            <img alt="广汽本田" className="title3" src={createFilePath(title3)} />
          ) : (
            <div className="title3" dangerouslySetInnerHTML={{ __html: title3?.replaceHtml() }} />
          )}
        </div>
        <div className="content1" ref={screen3MaskContent1Ref}>
          {isImage(title4) ? (
            <img alt="广汽本田" className="title4" src={createFilePath(title4)} />
          ) : (
            <div className="title4" dangerouslySetInnerHTML={{ __html: title4?.replaceHtml() }} />
          )}
        </div>
      </div>
    );
  };

  const buttonClick = (item) => {
    const link = item?.link?.value;
    if (!isEmpty(link)) {
      if (item?.internalLink?.value) {
        item.show = true;
        setButtonList([...buttonList]);
        sendDmp(item?.dmp);
      } else {
        window.open(link);
        sendDmp(item?.dmp, link);
      }
    }
  };

  const close = (item) => {
    item.show = false;
    setButtonList([...buttonList]);
  };

  return (
    <div className="price-space-v7" id={anchor} style={style} ref={containerRef}>
      {getScreen1Container()}
      {getScreen2Container()}
    </div>
  );
}
