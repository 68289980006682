import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper } from 'swiper';
import './style.scss';
import { getPrefixField, convertStylesStringToObject, sendDmp, createFilePath, filterHtmlTag, isMobileFun } from '../../utils/obj-utils';
import Icon from '../../elements/icon';

export default function index({ fields, eventRef }) {
  const containerRef = useRef(null);
  const imageSwiperRef = useRef(null);
  const thumbnailSwiperRef = useRef(null);
  const menuSwiperRef = useRef(null);
  const isTouchRef = useRef({ image: false, thumbnail: false, menu: false });
  const [selectTabIndex, setSelectTabIndex] = useState(0);
  const [selectIndex, setSelectIndex] = useState(0);
  const isMobile = isMobileFun();
  const sectionDmp = fields.sectionDmp;
  const menuPrevDmp = fields.menuPrevDmp;
  const menuNextDmp = fields.menuNextDmp;
  const thumbnailPrevDmp = fields.thumbnailPrevDmp;
  const thumbnailNextDmp = fields.thumbnailNextDmp;
  const anchor = fields?.anchor?.value;
  const navigationIndex = fields.navigationIndex.value;
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const tabList = fields[getPrefixField('tabList', isMobile)].map((item, index) => ({
    ...item.fields,
    imageList: item.fields.imageList.map((item1, index1) => ({ ...item1.fields, tabIndex: index, index: index1 }))
  }));
  const imageList = tabList.reduce((item1, item2) => item1.concat(item2.imageList), []);
  const tabStyle = convertStylesStringToObject(fields[getPrefixField('tabStyle', isMobile)]?.value);
  const tabSelectStyle = convertStylesStringToObject(fields[getPrefixField('tabSelectStyle', isMobile)]?.value);
  const imageTitleStyle = convertStylesStringToObject(fields[getPrefixField('imageTitleStyle', isMobile)]?.value);
  const imageTitleSelectStyle = convertStylesStringToObject(fields[getPrefixField('imageTitleSelectStyle', isMobile)]?.value);
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const tabImage = fields[getPrefixField('tabImage', isMobile)]?.value && createFilePath(fields[getPrefixField('tabImage', isMobile)]?.value);
  const hideTabImage = fields[getPrefixField('hideTabImage', isMobile)]?.value;
  const hideTab = fields[getPrefixField('hideTab', isMobile)]?.value;
  const hideMenu = fields[getPrefixField('hideMenu', isMobile)]?.value;
  const hideThumbnail = fields[getPrefixField('hideThumbnail', isMobile)]?.value;

  useEffect(() => {
    const swiper1 = new Swiper(imageSwiperRef.current, {
      grabCursor: true,
      slidesPerView: 1,
      on: {
        slideChange: ({ activeIndex }) => {
          if (isTouchRef.current.image) {
            isTouchRef.current.image = false;
            return;
          }
          const tabIndex = imageList[activeIndex].tabIndex;
          const index = imageList[activeIndex].index;
          setSelectTabIndex(tabIndex);
          setSelectIndex(index);
          thumbnailSlideTo(tabIndex, index);
          menuSlideTo(tabIndex, index);
        }
      }
    });

    const swiper2 = new Swiper(thumbnailSwiperRef.current, {
      grabCursor: true,
      slidesPerView: isMobile ? 3 : 5,
      on: {
        slideChange: ({ activeIndex }) => {
          if (isTouchRef.current.thumbnail) {
            isTouchRef.current.thumbnail = false;
            return;
          }
          const tabIndex = imageList[activeIndex].tabIndex;
          const index = imageList[activeIndex].index;
          setSelectTabIndex(tabIndex);
          setSelectIndex(index);
          imageSlideTo(tabIndex, index);
          menuSlideTo(tabIndex, index);
        }
      }
    });

    addListener();

    return () => {
      swiper1.destroy(true, true);
      swiper2.destroy(true, true);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    const swiper1 = new Swiper(menuSwiperRef.current, {
      grabCursor: true,
      slidesPerView: 3,
      on: {
        slideChange: ({ activeIndex }) => {
          if (isTouchRef.current.menu) {
            isTouchRef.current.menu = false;
            return;
          }
          const tabIndex = imageList[activeIndex].tabIndex;
          const index = imageList[activeIndex].index;
          setSelectTabIndex(tabIndex);
          setSelectIndex(index);
          thumbnailSlideTo(tabIndex, index);
          imageSlideTo(tabIndex, index);
        }
      }
    });

    return () => {
      swiper1.destroy(true, true);
    };
  }, []);

  useEffect(() => {
    const slideIndex = imageList.filter((item) => item.tabIndex < selectTabIndex).length + selectIndex;
    const thumbnailNavigationPrev = containerRef.current.querySelector('.thumbnail-container .navigation-prev');
    const thumbnailNavigationNext = containerRef.current.querySelector('.thumbnail-container .navigation-next');
    const menuNavigationPrev = containerRef.current.querySelector('.menu-container .navigation-prev');
    const menuNavigationNext = containerRef.current.querySelector('.menu-container .navigation-next');
    thumbnailNavigationPrev && (thumbnailNavigationPrev.style.opacity = slideIndex === 0 ? 0 : 1);
    thumbnailNavigationNext && (thumbnailNavigationNext.style.opacity = slideIndex === imageList.length - 1 ? 0 : 1);
    menuNavigationPrev && (menuNavigationPrev.style.opacity = slideIndex === 0 ? 0 : 1);
    menuNavigationNext && (menuNavigationNext.style.opacity = slideIndex === imageList.length - 1 ? 0 : 1);
  }, [selectTabIndex, selectIndex]);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(sectionDmp);
    window.navigationIndex = navigationIndex;
    window.navigationPagename = window.navigationPagenameb = anchor;
  };

  const addListener = () => {
    Array.from(containerRef.current.getElementsByClassName('price-exterior-v9-inline')).forEach((item1) => {
      Array.from(item1.getElementsByClassName('btn')).forEach((item2) => {
        item2.onclick = (e) => {
          Array.from(item1.getElementsByClassName('img-in')).forEach((item3, index3) => {
            item3.style.display = index3 === parseInt(e.target.attributes.index.nodeValue) ? 'block' : 'none';
          });
        };
      });
    });
  };

  const tabClick = (index) => {
    isTouchRef.current = { image: true, thumbnail: true, menu: true };
    setSelectTabIndex(index);
    setSelectIndex(0);
    slideTo(index, 0);
    sendDmp(tabList[index].dmp);
  };

  const menuClick = (index, value) => {
    isTouchRef.current = { image: true, thumbnail: true, menu: true };
    setSelectIndex(index);
    slideTo(selectTabIndex, index);
    sendDmp(tabList[selectTabIndex].imageList[index].dmp, '', filterHtmlTag(value));
  };

  const thumbnailSwiperClick = ({ tabIndex, index, imageDmp }) => {
    swiperClick(tabIndex, index);
    sendDmp(imageDmp);
  };

  const menuSwiperClick = ({ tabIndex, index }, value) => {
    swiperClick(tabIndex, index);
    sendDmp(tabList[tabIndex].imageList[index].dmp, filterHtmlTag(value));
  };

  const swiperClick = (tabIndex, index) => {
    isTouchRef.current = { image: true, thumbnail: true, menu: true };
    setSelectTabIndex(tabIndex);
    setSelectIndex(index);
    slideTo(tabIndex, index);
  };

  const thumbnailPrevClick = () => {
    navigationClick(false, () => {
      sendDmp(thumbnailPrevDmp);
    });
  };

  const thumbnailNextClick = () => {
    navigationClick(true, () => {
      sendDmp(thumbnailNextDmp);
    });
  };

  const menuPrevClick = () => {
    navigationClick(false, () => {
      sendDmp(menuPrevDmp);
    });
  };

  const menuNextClick = () => {
    navigationClick(true, () => {
      sendDmp(menuNextDmp);
    });
  };

  const navigationClick = (isNext = false, callback) => {
    const slideIndex = imageList.filter((item) => item.tabIndex < selectTabIndex).length + selectIndex + (isNext ? 1 : -1);
    if (slideIndex < 0 || slideIndex >= imageList.length) {
      return;
    }
    isTouchRef.current = { image: true, thumbnail: true, menu: true };
    const tabIndex = imageList[slideIndex].tabIndex;
    const index = imageList[slideIndex].index;
    setSelectTabIndex(tabIndex);
    setSelectIndex(index);
    slideTo(tabIndex, index);
    callback && callback();
  };

  const slideTo = (tabIndex, index) => {
    imageSlideTo(tabIndex, index);
    thumbnailSlideTo(tabIndex, index);
    menuSlideTo(tabIndex, index);
  };

  const thumbnailSlideTo = (tabIndex, index) => {
    if (thumbnailSwiperRef.current) {
      const slideIndex = imageList.filter((item) => item.tabIndex < tabIndex).length + index;
      thumbnailSwiperRef.current.swiper.slideTo(slideIndex);
    }
  };

  const imageSlideTo = (tabIndex, index) => {
    if (imageSwiperRef.current) {
      const slideIndex = imageList.filter((item) => item.tabIndex < tabIndex).length + index;
      imageSwiperRef.current.swiper.slideTo(slideIndex);
    }
  };

  const menuSlideTo = (tabIndex, index) => {
    if (menuSwiperRef.current) {
      const slideIndex = imageList.filter((item) => item.tabIndex < tabIndex).length + index;
      menuSwiperRef.current.swiper.slideTo(slideIndex);
    }
  };

  const getMenuSwiper = () => {
    return (
      <>
        {isMobile && !hideMenu && (
          <div className="menu-container">
            <div className="menu-swiper" ref={menuSwiperRef}>
              <div className="swiper-wrapper">
                {imageList.map((item, index) => {
                  const selected = imageList.filter((item) => item.tabIndex < selectTabIndex).length + selectIndex === index;
                  const title = (selected ? (item.selectTitle.value ? item.selectTitle : item.title) : item.title)?.value;
                  return (
                    <div
                      key={index}
                      className={`swiper-slide ${selected ? 'swiper-slide-selected' : ''}`}
                      style={selected ? imageTitleSelectStyle : imageTitleStyle}
                      dangerouslySetInnerHTML={{ __html: title?.replaceHtml() }}
                      onClick={() => menuSwiperClick(item, title?.replaceHtml())}
                    />
                  );
                })}
              </div>
            </div>
            <div className="navigation-prev" onClick={menuPrevClick}>
              <Icon name="ArrowLeftTriangle" />
            </div>
            <div className="navigation-next" onClick={menuNextClick}>
              <Icon name="ArrowRightTriangle" />
            </div>
          </div>
        )}
      </>
    );
  };

  const getTabImage = () => {
    return (
      <>
        {!hideTabImage && (
          <div className="tab-image-container">
            <img alt="广汽本田" className="tab-image" src={tabImage} />
          </div>
        )}
      </>
    );
  };

  const getTab = () => {
    return (
      <>
        {!hideTab && (
          <div className="tab-container">
            {tabList.map((item, index) => {
              const selected = selectTabIndex === index;
              const title = (selected ? (item.selectTitle.value ? item.selectTitle : item.title) : item.title)?.value;
              return (
                <div
                  key={index}
                  className={`tab-item ${selected ? 'tab-item-active' : ''}`}
                  style={selected ? tabSelectStyle : tabStyle}
                  dangerouslySetInnerHTML={{ __html: title?.replaceHtml() }}
                  onClick={() => tabClick(index)}
                />
              );
            })}
          </div>
        )}
      </>
    );
  };

  const getMenuList = () => {
    return (
      <>
        {!hideMenu && (
          <div className="list-container">
            {tabList[selectTabIndex].imageList.map((item, index) => {
              const selected = selectIndex === index;
              const title = (selected ? (item.selectTitle.value ? item.selectTitle : item.title) : item.title)?.value;
              return (
                <div key={index} className="list-item">
                  <span
                    className={`list-item-text ${selected ? 'list-item-text-active' : ''}`}
                    style={selected ? imageTitleSelectStyle : imageTitleStyle}
                    dangerouslySetInnerHTML={{ __html: title?.replaceHtml() }}
                    onClick={() => menuClick(index, title?.replaceHtml())}
                  />
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  const getImageSwiper = () => {
    return (
      <div className="image-swiper" ref={imageSwiperRef}>
        <div className="swiper-wrapper">
          {imageList.map((item, index) => (
            <div key={index} className="swiper-slide" dangerouslySetInnerHTML={{ __html: item.image.value.replaceHtml() }} />
          ))}
        </div>
      </div>
    );
  };

  const getThumbnailSwiper = () => {
    return (
      <>
        {!hideThumbnail && (
          <div className="thumbnail-container">
            {isMobile && <div className="thumbnail-background" />}
            <div className="thumbnail-swiper" ref={thumbnailSwiperRef}>
              <div className="swiper-wrapper">
                {imageList.map((item, index) => {
                  const slideIndex = imageList.filter((item) => item.tabIndex < selectTabIndex).length + selectIndex;
                  return (
                    <div key={index} className="swiper-slide">
                      <div
                        className={`swiper-slide-item ${index === slideIndex ? 'swiper-slide-item-active' : ''}`}
                        dangerouslySetInnerHTML={{ __html: item.thumbnail.value.replaceHtml() }}
                        onClick={() => thumbnailSwiperClick(item)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="navigation-prev" onClick={thumbnailPrevClick}>
              <Icon name="ArrowLeft" />
            </div>
            <div className="navigation-next" onClick={thumbnailNextClick}>
              <Icon name="ArrowRight" />
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <section>
      <div className="price-exterior-v9" ref={containerRef} id={anchor} style={style}>
        <div className="section-title" dangerouslySetInnerHTML={{ __html: title.replaceHtml() }} />
        <div className="content-container">
          {getMenuSwiper()}
          {isMobile && getImageSwiper()}
          {isMobile && (!hideTabImage || !hideTab) && (
            <div className="tab-menu-conatiner">
              {getTabImage()}
              {getTab()}
            </div>
          )}
          {!isMobile && (
            <div className="top-container">
              {getTabImage()}
              {(!hideTab || !hideMenu) && (
                <div className="menu-container">
                  {getTab()}
                  {getMenuList()}
                </div>
              )}
              {getImageSwiper()}
            </div>
          )}
          {getThumbnailSwiper()}
          {isMobile && (
            <div className="bottom-arrow">
              <Icon name="ArrowRight" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
