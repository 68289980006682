import React, { useEffect, useState, useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import './style.scss';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import temp from '../../temp/config';
import { getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index({ fields, scrollElement }) {
  const imgRef = useRef(null);
  const containerRef = useRef(null);
  const textRef = useRef(null);
  // const deployUrlS3 = temp.app.deployUrlS3;
  const [imgLoaded, setImgLoaded] = useState(false);
  const isMobile = isMobileFun();
  // const img_url = `${deployUrlS3}${fields[getPrefixField('img_url', isMobile)]?.value}`;
  const img_url = createFilePath(fields[getPrefixField('img_url', isMobile)]?.value);
  const disableSlide = fields[getPrefixField('disableSlide', isMobile)]?.value;
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const subTitle = fields[getPrefixField('subTitle', isMobile)]?.value;
  const [data, _] = useState({
    img_url,
    isMobile,
    disableSlide,
    title,
    subTitle
  });

  useEffect(() => {
    if (!data || !imgLoaded) {
      return;
    }

    // const windowHeight = document.documentElement.clientHeight;
    const windowHeight = imgRef.current.clientHeight;
    const windowWidth = document.documentElement.clientWidth;
    const imgHeight = imgRef.current.naturalHeight;
    const imgWidth = imgRef.current.naturalWidth;
    // Pc端图片与窗口比例对比
    const pcSlideWidth = windowWidth / imgWidth;
    const pcSlideHeight = windowHeight / imgHeight;
    const pcSlide = pcSlideHeight > pcSlideWidth;
    const slideWidth = Math.abs(Math.ceil((imgWidth * windowHeight) / imgHeight - windowWidth));

    // 图片处理
    if (!data.isMobile && data.disableSlide) {
      if (pcSlide) {
        imgRef.current.style.objectFit = 'contain';
      }
    }
    if (!data.isMobile && !data.disableSlide && !pcSlide) {
      imgRef.current.style.objectFit = 'cover';
    }

    const imgSlideTimeline = gsap.timeline({ pause: true });
    if (data.isMobile || (!data.isMobile && !data.disableSlide && pcSlide)) {
      imgSlideTimeline
        .fromTo(textRef.current, { opacity: 1 }, { opacity: 0 })
        .fromTo(
          imgRef.current,
          { opacity: 0, clipPath: 'inset(5% 20% 10% 20%)', transform: 'scale(0.8)' },
          { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', transform: 'scale(1)' },
          '<'
        )
        .fromTo(imgRef.current, { objectPosition: '0' }, { objectPosition: -slideWidth + 'px' });
    } else {
      imgSlideTimeline
        .fromTo(textRef.current, { opacity: 1 }, { opacity: 0 })
        .fromTo(
          imgRef.current,
          { opacity: 0, clipPath: 'inset(5% 20% 10% 20%)', transform: 'scale(0.8)' },
          { opacity: 1, clipPath: 'inset(0% 0% 0% 0%)', transform: 'scale(1)' },
          '<'
        );
    }
    const controller = new ScrollMagic.Controller({ container: scrollElement || window });
    const imgSlideScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onLeave',
      duration: '150%'
    })
      .on('progress', function (event) {
        imgSlideTimeline.progress(event.progress);
      })
      .setPin(containerRef.current)
      .setTween(imgSlideTimeline)
      .addTo(controller);

    return () => {
      imgSlideTimeline.kill();
      imgSlideScene.remove();
      imgSlideScene.destroy();

      controller.destroy();
    };
  }, [imgLoaded]);

  return (
    <section className="imageSlide_v1" ref={containerRef}>
      {data && (
        <>
          <div className="textContainer">
            <div className="text" ref={textRef}>
              <div className="title" dangerouslySetInnerHTML={{ __html: data.title?.replaceHtml() }} />
              <div className="subTitle" dangerouslySetInnerHTML={{ __html: data.subTitle?.replaceHtml() }} />
            </div>
          </div>
          <div className="imgContainer">
            <img alt="广汽本田" className="imgSlide" ref={imgRef} src={createFilePath(data.img_url)} onLoad={() => setImgLoaded(true)} />
          </div>
        </>
      )}
    </section>
  );
}
