import React, { useEffect, useState } from 'react';
import './style.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMobileFun } from '../../../utils/obj-utils';

function index(props) {
  const [thisMobile, setThisMobile] = useState(!isMobileFun());
  return thisMobile ? (
    <div className={props.className} style={props.style}>
      <div className="breath_a_pc"></div>
      <div className="breath_b_pc"></div>
      <div className="breath_c_pc"></div>
    </div>
  ) : (
    <div className={props.className} style={props.style}>
      <div className="breath_a_mobile"></div>
      <div className="breath_b_mobile"></div>
      <div className="breath_c_mobile"></div>
    </div>
  );
}

export default index;
