import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import './style.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.min.css';
import { Navigation } from 'swiper';
import Detail from './Detail';
import temp from '../../temp/config';
import { getPrefixField, convertStylesStringToObject, sendDmp, isEmpty, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const { fields } = props;
  const [tabNum, setTabNum] = useState(0);
  const [num, setNum] = useState(0);
  const [windowHeight, setWindowHeight] = useState(400);
  const [flag, setFlag] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [imgGroupNum, setImgGroupNum] = useState(0);
  const [swiperindex, setSwiperindex] = useState(0);
  const [groupList, setGroupList] = useState([]);
  const isMobile = isMobileFun();
  const rootEl = useRef(null);
  const swiperRef = useRef(null);
  const propOpendmp = fields?.propopendmp?.value || '';
  const propClosedmp = fields?.propclosedmp?.value || '';
  const propNextdmp = fields?.propnextdmp?.value || '';
  const propPrevdmp = fields?.propprevdmp?.value || '';
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields.load_dmp_point);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : 'picture';
  };

  useEffect(() => {
    const list = [];
    let max_index = 2;
    let single_page_max_index = 6;
    if (fields[`${getPrefixField('max_index')}`].value && fields[`${getPrefixField('max_index', isMobile)}`].value.length !== 0) {
      max_index = parseInt(fields[`${getPrefixField('max_index', isMobile)}`].value);
    }
    if (
      fields[`${getPrefixField('single_page_max_source_num', isMobile)}`].value &&
      fields[`${getPrefixField('single_page_max_source_num', isMobile)}`].value.length !== 0
    ) {
      single_page_max_index = parseInt(fields[`${getPrefixField('single_page_max_source_num', isMobile)}`].value);
    }
    if (!isEmpty(fields[`${getPrefixField('type_list', isMobile)}`][tabNum].fields.single_page_max_source_num.value)) {
      single_page_max_index = parseInt(fields[`${getPrefixField('type_list', isMobile)}`][tabNum].fields.single_page_max_source_num.value);
    }
    for (let i = 0; i <= max_index; i++) {
      if (
        fields[`${getPrefixField('type_list', isMobile)}`][tabNum].fields[`source_list_${i}`] &&
        fields[`${getPrefixField('type_list', isMobile)}`][tabNum].fields[`source_list_${i}`].length
      ) {
        const addList = [];
        const imgListInSinglePage = fields[`${getPrefixField('type_list', isMobile)}`][tabNum].fields[`source_list_${i}`];
        const len = imgListInSinglePage.length;
        if (len < single_page_max_index) {
          const imgObj = imgListInSinglePage[len - 1];
          for (i = 0; i < single_page_max_index - len; i++) {
            addList.push(imgObj);
          }
        }
        const obj = { len, list: [...imgListInSinglePage, ...addList] };
        list.push(obj);
      }
    }
    setGroupList(list);
  }, [tabNum]);

  const renderSingleFaceSource = (sourceObj, index, len, showPvVideo = false) => {
    const {
      fields: { source, face_source, face_source_type, type, open_dmp_point, face_source_style, play_icon }
    } = sourceObj;
    return (
      <>
        {face_source_type?.fields.v.value === 'img' && (
          <>
            {type?.fields.v.value === 'video' && play_icon.value && (
              <img
                alt="广汽本田"
                src={`${createFilePath(play_icon.value)}`}
                className="playVideo"
                onClick={() => {
                  if (propOpendmp) {
                    window.Dmp.clickAction(propOpendmp + (index + imgGroupNum * 6 + 1), type.fields.v.value === 'img' ? '' : source.value);
                  } else {
                    sendDmp(open_dmp_point, type.fields.v.value === 'img' ? '' : source.value);
                  }
                  setFlag(true);
                  setNum(index >= len ? len - 1 : index);
                }}
              />
            )}
            <img
              alt="广汽本田"
              style={convertStylesStringToObject(face_source_style?.value)}
              src={`${createFilePath(face_source.value)}`}
              onClick={() => {
                if (propOpendmp) {
                  window.Dmp.clickAction(propOpendmp + (index + imgGroupNum * 6 + 1), type.fields.v.value === 'img' ? '' : source.value);
                } else {
                  sendDmp(open_dmp_point, type.fields.v.value === 'img' ? '' : source.value);
                }
                setFlag(true);
                setNum(index >= len ? len - 1 : index);
              }}
            />
          </>
        )}
        {face_source_type?.fields.v.value === 'video' && (
          <>
            <img alt="广汽本田" src={`${createFilePath(play_icon.value)}`} className="playVideo" />
            <video
              style={convertStylesStringToObject(face_source_style?.value)}
              src={`${createFilePath(face_source.value)}`}
              autoPlay="autoplay"
              muted
              onClick={() => {
                if (propOpendmp) {
                  window.Dmp.clickAction(propOpendmp + (index + imgGroupNum * 6 + 1), type.fields.v.value === 'img' ? '' : source.value);
                } else {
                  sendDmp(open_dmp_point, type.fields.v.value === 'img' ? '' : source.value);
                }
                setFlag(true);
                setNum(index >= len ? len - 1 : index);
                if (showPvVideo) {
                  setIsVideo(true);
                }
              }}
            ></video>
          </>
        )}
      </>
    );
  };

  const renderSinglePage = (progress, obj, firstSourceTransFlag, page) => {
    const isSub = !fields[`${getPrefixField('tab_show_flag')}`].value && !isMobile;
    return (
      <Timeline totalProgress={progress} paused>
        <div
          className={`imgListContainer ${firstSourceTransFlag ? 'imgList_FirstSourceTrans_' : 'imgList_'}${obj.len}`}
          style={convertStylesStringToObject(fields[getPrefixField('source_list_container_style', isMobile)]?.value)}
        >
          {obj &&
            obj.list &&
            obj.list.length > 0 &&
            obj.list.map((item, index) => {
              return fields[`${getPrefixField('first_pic_trans_flag', isMobile)}`].value ? (
                index === 0 ? (
                  <Tween
                    key={index}
                    from={{ width: '80%', height: '80%', margin: '0 auto' }}
                    to={{ width: '30%', height: '41%' }}
                    duration={500}
                    delay={500}
                    ease="back.in(5)"
                    position="0"
                  >
                    <div key={index} className={`item item${index}`} style={{ zIndex: 1 }}>
                      {renderSingleFaceSource(item, index, obj.len)}
                      {isSub && (
                        <Tween
                          from={{ opacity: 1, zIndex: 1 }}
                          to={{ opacity: 0, zIndex: -1 }}
                          duration={500}
                          delay={500}
                          ease="back.in(5)"
                          position="0"
                        >
                          <div className="sub-item">{renderSingleFaceSource(groupList[0].list[0], index, groupList[0].len, true)}</div>
                        </Tween>
                      )}
                    </div>
                  </Tween>
                ) : (
                  <Tween key={index} from={{ opacity: 0 }} to={{ opacity: 1 }} stagger={0.2} duration={50}>
                    <div key={index} className={`item item${index}`} style={{ zIndex: 0 }}>
                      {renderSingleFaceSource(item, index, obj.len)}
                    </div>
                  </Tween>
                )
              ) : (
                <Tween key={index} from={{ opacity: 0 }} to={{ opacity: 1 }} stagger={0.2} duration={50}>
                  <div key={index} className={`item item${index}`} style={{ zIndex: 0 }}>
                    {renderSingleFaceSource(item, index, obj.len)}
                  </div>
                </Tween>
              );
            })}
        </div>
      </Timeline>
    );
  };

  const renderSinglePageaWithoutAnimate = (obj) => {
    return (
      <div
        className={`imgListContainer imgList_${obj.len}`}
        style={convertStylesStringToObject(fields[getPrefixField('source_list_container_style', isMobile)]?.value)}
      >
        {obj &&
          obj.list &&
          obj.list.length > 0 &&
          obj.list.map((item, index) => {
            return renderSingleFaceSource(item, index, obj.len);
          })}
      </div>
    );
  };

  const closeModal = (dmp, value) => {
    setIsVideo(false);
    setFlag(false);
    if (dmp) {
      sendDmp(dmp, value);
    }
  };

  return (
    <div className="container" ref={rootEl}>
      <Controller key={tabNum}>
        <Scene
          triggerHook={fields[`${getPrefixField('first_pic_trans_flag', isMobile)}`].value ? 0.07 : 0.8}
          duration={windowHeight * 0.75}
          pin={fields[`${getPrefixField('first_pic_trans_flag', isMobile)}`].value ? '#picture' : false}
        >
          {(progress, event) => {
            if (swiperRef?.current?.swiper) {
              swiperRef.current.swiper.enabled = progress >= 0.9;
            }
            return (
              <div
                className="WallpaperVideo"
                id={fields[`${getPrefixField('tab_show_flag', isMobile)}`].value ? '' : 'picture'}
                style={convertStylesStringToObject(fields[`${getPrefixField('container_style', isMobile)}`]?.value)}
              >
                {fields[`${getPrefixField('tab_show_flag')}`].value && (
                  <div
                    className="tabContainer"
                    style={convertStylesStringToObject(fields[`${getPrefixField('tab_container_style', isMobile)}`]?.value)}
                  >
                    {fields[`${getPrefixField('type_list')}`].map((ele, index) => {
                      const dmpPointList = ele.fields?.tab_dmp;
                      return (
                        <div
                          key={index}
                          className={fields[`${getPrefixField('type_list', isMobile)}`].length > 1 && index === tabNum ? 'tabSelect' : ''}
                        >
                          <div
                            key={index}
                            onClick={() => {
                              setTabNum(index);
                              setImgGroupNum(0);
                              sendDmp(dmpPointList);
                            }}
                            dangerouslySetInnerHTML={{ __html: ele.fields.tab_text.value?.replaceHtml() }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="sourceListContainer" id={fields[`${getPrefixField('tab_show_flag', isMobile)}`].value ? 'picture' : ''}>
                  <Swiper
                    ref={swiperRef}
                    className={isMobile ? 'mobile_swiper' : ''}
                    modules={[Navigation]}
                    onSlidePrevTransitionStart={() => {
                      setImgGroupNum(imgGroupNum - 1);
                      sendDmp(fields.btnl_dmp);
                    }}
                    onSlideNextTransitionStart={() => {
                      setImgGroupNum(imgGroupNum + 1);
                      sendDmp(fields.btnr_dmp);
                    }}
                    onSlideChangeTransitionEnd={(e) => {
                      setSwiperindex(e.activeIndex + 1);
                    }}
                    navigation={isMobile}
                  >
                    {groupList &&
                      groupList.length > 0 &&
                      groupList.map((ele, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div>
                              {fields[`${getPrefixField('animate_disabled', isMobile)}`]?.value
                                ? renderSinglePageaWithoutAnimate(ele)
                                : renderSinglePage(progress, ele, fields[`${getPrefixField('first_pic_trans_flag', isMobile)}`].value, index)}
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </div>
                {!isMobile && groupList.length > 1 && progress >= 0.9 && (
                  <img alt="广汽本田" src={`${createFilePath(fields.drag_icon.value)}`} className="dragPictrue" />
                )}
              </div>
            );
          }}
        </Scene>
      </Controller>
      {flag && (
        <Detail
          single={isVideo}
          close={(dmp, value) => closeModal(dmp, value)}
          num={num}
          isMobile={isMobile}
          sourceList={groupList[isVideo ? 0 : imgGroupNum].list.slice(0, groupList[isVideo ? 0 : imgGroupNum].len)}
          checkPrev={(dmp) => sendDmp(dmp)}
          checkNext={(dmp) => sendDmp(dmp)}
          propClosedmp={propClosedmp}
          propNextdmp={propNextdmp}
          propPrevdmp={propPrevdmp}
          imgGroupNum={imgGroupNum}
        />
      )}
    </div>
  );
}
