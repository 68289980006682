/* eslint-disable no-eval */
import React, { useEffect, useRef, useState, useImperativeHandle, createRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import Swiper from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import './style.scss';
import { convertStylesStringToObject, getPrefixField, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import temp from '../../temp/config';

export default function index({ fields, eventRef, params }) {
  const containerRef = useRef(null);
  const isMobile = isMobileFun();
  const [tabNum, setTabNum] = useState(0);
  const tabSwiperEl = useRef(null);
  const tabSwiper = useRef(null);
  const contentSwiperEl = useRef(null);
  const contentSwiper = useRef(null);
  const tabs = fields[getPrefixField('tabs', isMobile)];
  const sectionDmp = fields?.sectionDmp;
  const [data, _] = useState({
    tabs,
    sectionDmp
  });
  const tabsRefList = tabs && tabs.length > 0 ? tabs.map(() => createRef()) : null;

  useEffect(() => {}, []);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(data?.sectionDmp);
    // 二级导航高亮设置
    window.navigationIndex = fields?.navigationIndex?.value;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'price_power';
  };

  // Swiper 初始化
  useEffect(() => {
    tabSwiper.current = new Swiper(tabSwiperEl.current, {
      observer: true,
      slidesPerView: 'auto',
      freeMode: true
    });

    contentSwiper.current = new Swiper(contentSwiperEl.current, {
      observer: true,
      slidesPerView: 1,
      freeMode: true,
      modules: [],
      on: {
        slidePrevTransitionStart(e) {
          setTabNum(e.activeIndex);
          sendDmp(data?.tabs[e.activeIndex]?.fields?.tabDmp);
        },
        slideNextTransitionStart(e) {
          setTabNum(e.activeIndex);
          sendDmp(data?.tabs[e.activeIndex]?.fields?.tabDmp);
        }
      }
    });
    return () => {
      tabSwiper.current.destroy(true, true);
      contentSwiper.current.destroy(true, true);
    };
  }, []);

  useEffect(() => {
    const showTabController = new ScrollMagic.Controller();
    const showTabContentTimeline = gsap.timeline({ paused: true }).fromTo(tabSwiperEl.current, { opacity: 0 }, { opacity: 1, duration: 1 });
    const showTabTimeline = gsap.timeline({
      duration: 0.1,
      onComplete: () => {
        showTabContentTimeline.play();
      },
      onReverseComplete: () => {
        showTabContentTimeline.reverse();
      }
    });
    const showTabScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 0.05
    })
      .on('progress', function (event) {
        showTabTimeline.progress(event.progress);
      })
      .setTween(showTabTimeline)
      .addTo(showTabController);
    return () => {
      showTabContentTimeline.kill();
      showTabTimeline.kill();
      showTabScene.remove();
      showTabScene.destroy();
      showTabController.destroy();
    };
  }, []);

  useEffect(() => {
    const showController = new ScrollMagic.Controller();
    const showContentTimelineList = [];
    for (let i = 0; i < data.tabs.length; i++) {
      const showContentTimelineTemp = gsap
        .timeline({ paused: true })
        .fromTo(tabsRefList[i].current, { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 0.5 });
      showContentTimelineList.push(showContentTimelineTemp);
    }
    const showTimeline = gsap.timeline({
      duration: 0.1,
      onComplete: () => {
        setTimeout(() => {
          showContentTimelineList[tabNum].play();
        }, 290);
      },
      onReverseComplete: () => {
        setTimeout(() => {
          showContentTimelineList[tabNum].reverse();
        }, 290);
      }
    });

    const showScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 0.1
    })
      .on('progress', function (event) {
        showTimeline.progress(event.progress);
      })
      .setTween(showTimeline)
      .addTo(showController);
    return () => {
      showContentTimelineList.forEach((item) => {
        item.kill();
      });
      showTimeline.kill();
      showScene.remove();
      showScene.destroy();
      showController.destroy();
    };
  }, [tabNum]);

  return (
    <div className="price-power-v5" id={params?.pagename !== undefined ? params?.pagename : 'price_power'} ref={containerRef}>
      <div className="content_list">
        <div className="swiper content_list_swiper" ref={contentSwiperEl}>
          <div className="swiper-wrapper">
            {data.tabs &&
              data.tabs.length > 0 &&
              data.tabs.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={'swiper-slide content_list_swiper_slide'}
                    style={{
                      background: `${
                        item.fields?.is_background?.value ? 'url(' + createFilePath(item.fields?.background_img?.value) + ') no-repeat' : 'unset'
                      }`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center'
                    }}
                  >
                    {!item.fields?.is_background?.value && (
                      <div
                        className="content_container_back"
                        dangerouslySetInnerHTML={{
                          __html: item.fields?.content_container_back?.value?.replaceHtml()
                        }}
                      ></div>
                    )}
                    <div className={'content_container'}>
                      <div className={`content_container_mould ${item.fields?.reverse?.value ? 'content_container_mould_reverse' : ''}`}>
                        {!item.fields?.reverse?.value && !isMobile && (
                          <div
                            ref={tabsRefList[index]}
                            className="content_container_text"
                            style={convertStylesStringToObject(item.fields?.content_container_text_style?.value)}
                            dangerouslySetInnerHTML={{
                              __html: item.fields?.content_container_text?.value?.replaceHtml()
                            }}
                          ></div>
                        )}

                        <div className="content_container_img">
                          {!item.fields?.is_background?.value && (
                            <>
                              <img
                                src={`${createFilePath(item.fields?.title_img?.value)}`}
                                alt="广汽本田"
                                className="container_img"
                                style={convertStylesStringToObject(item.fields?.title_img_style?.value)}
                              />
                              {item.fields?.subtitle_img?.value && (
                                <img
                                  className="container_subtitle_img"
                                  style={convertStylesStringToObject(item.fields?.subtitle_img_style?.value)}
                                  src={`${createFilePath(item.fields?.subtitle_img?.value)}`}
                                  alt="广汽本田"
                                />
                              )}
                            </>
                          )}
                        </div>

                        {(item.fields?.reverse?.value || isMobile) && (
                          <div
                            ref={tabsRefList[index]}
                            className="content_container_text"
                            style={convertStylesStringToObject(item.fields?.content_container_text_style?.value)}
                            dangerouslySetInnerHTML={{
                              __html: item.fields?.content_container_text?.value?.replaceHtml()
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className={'tab_bottom_list'}>
        <div className="swiper tab_list_swiper" ref={tabSwiperEl}>
          <div className="swiper-wrapper">
            {data.tabs &&
              data.tabs.length > 0 &&
              data.tabs.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`swiper-slide tab_list_swiper_slide ${index === tabNum ? 'tab_disabled' : ''}`}
                    onClick={() => {
                      setTabNum(index);
                      sendDmp(item?.fields?.tabDmp);
                      contentSwiper.current.slideTo(index, 300, false);
                    }}
                  >
                    <div
                      className={`tabContainer ${index === tabNum ? 'tabContainer_active' : ''}`}
                      dangerouslySetInnerHTML={{ __html: item.fields?.tab?.value?.replaceHtml() }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
