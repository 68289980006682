import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { convertStylesStringToObject, sendDmp, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';
import './style.scss';

export default function inlet(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const [tabIndex, setTabIndex] = useState(0);
  const [defaultTab, setDefaultTab] = useState(-1);
  const [isApp, setIsapp] = useState(0);
  const [isShow, setIsShow] = useState(0);
  const id = props?.params ? props?.params?.pagename : '';
  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    if (props.eventRef) {
      return {
        sendEvent
      };
    }
  });
  const sendEvent = () => {
    if (id) {
      window.Dmp.sectionAction(id);
      window.navigationIndex = props.fields.navigationIndex.value;
      window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
    }
  };

  useEffect(() => {
    if (window.location.href.indexOf('app/vehicles/') !== -1) {
      setIsapp(1);
    }
    if (window.location.href.indexOf('vehicles/show/') !== -1 || window.location.href.indexOf('-show') !== -1) {
      setIsShow(1);
    }
  }, []);
  // tabs
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      fields?.tabs.map((item, index) => {
        let tabArr = item.fields?.tabname?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(fields.tabsdefault?.value?.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabIndex(tabInd < 0 ? (defaultTabInd < 0 ? tabIndex : defaultTabInd) : tabInd);
      } else {
        setTabIndex(defaultTabInd < 0 ? tabIndex : defaultTabInd);
      }
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <section className={['Choose_v1', isApp === 1 ? 'appUse' : '', isShow === 1 ? 'showUse' : ''].join(' ')}>
      <div className="lists">
        {fields?.tabs.length > 0 &&
          fields?.tabs.map((item, index) => {
            return (
              <div
                key={index}
                className={`item ${tabIndex === index ? 'active' : ''}`}
                style={convertStylesStringToObject(item?.fields[`${getPrefixField('itemstyle', isMobile)}`]?.value)}
                onClick={() => {
                  setTabIndex(index);
                  window.Dmp.clickAction(item?.fields?.dmp?.value, item?.fields?.href?.value);
                  if (item?.fields?.href?.value) {
                    window.open(item?.fields?.href?.value, '_self');
                  }
                }}
              >
                <img alt="广汽本田" className="icon" src={createFilePath(item?.fields[`${getPrefixField('icon', isMobile)}`]?.value)} />
                <img
                  alt="广汽本田"
                  className="logoimg"
                  style={convertStylesStringToObject(item?.fields[`${getPrefixField('logoimgstyle', isMobile)}`]?.value)}
                  src={createFilePath(item?.fields[`${getPrefixField('logoimg', isMobile)}`]?.value)}
                />
                <img alt="广汽本田" className="bg" src={createFilePath(item?.fields[`${getPrefixField('bg', isMobile)}`]?.value)} />
              </div>
            );
          })}
      </div>
    </section>
  );
}
