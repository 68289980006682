/* eslint-disable no-eval */
import React, { useEffect, useRef, createRef, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap, { from } from 'gsap';
import './style.scss';
import { convertStylesStringToObject, getPrefixField, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import temp from '../../temp/config';

export default function index({ fields, params, updateRefList, updateDmpList }) {
  const isMobile = isMobileFun();
  const sectionDmp1 = fields?.sectionDmp1;
  const sectionDmp2 = fields?.sectionDmp2;
  const navigationIndex = fields?.navigationIndex?.value;
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const homeRef = useRef(null);
  const homeContentRef = useRef(null);
  const homeButtonRef = useRef(null);
  const title1Ref = useRef(null);
  const title2Ref = useRef(null);
  const contentImgRef = useRef(null);
  const [timelines, setTimelines] = useState(null);
  const [bgimgStyles, setBgimgStyles] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const lists = fields[getPrefixField('lists', isMobile)];
  const highlightsRefList = lists.map((item) => item?.fields?.text_list?.map(() => createRef()));

  const sendFirstDmp = () => {
    window.navigationIndex = navigationIndex;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'price_space';
    sendDmp(sectionDmp1);
  };

  const sendSecondDmp = () => {
    sendDmp(sectionDmp2);
  };

  useEffect(() => {
    updateRefList([sendFirstDmp, sendSecondDmp]);
    updateDmpList([homeRef?.current, contentRef?.current]);
    require('swiped-events');
  }, []);

  useEffect(() => {
    if (isMobile && bgimgStyles?.length > 0 && lists?.length > 0) {
      containerRef.current.addEventListener('swiped-left', right);
      containerRef.current.addEventListener('swiped-right', left);
    }
    return () => {
      if (isMobile && bgimgStyles?.length > 0 && lists?.length > 0) {
        containerRef.current.removeEventListener('swiped-left', right);
        containerRef.current.removeEventListener('swiped-right', left);
      }
    };
  }, [bgimgStyles, lists, tabIndex]);

  useEffect(() => {
    let timelinesTemp = [];
    let bgimgStylesTemp = [];
    timelinesTemp.push(gsap.timeline({ paused: true }).to(title1Ref.current, { opacity: 0 }).to(title2Ref.current, { opacity: 0 }, '<'));
    bgimgStylesTemp.push(fields[getPrefixField('bgimg_position', isMobile)]?.value);
    lists.forEach((item, index) => {
      let ref = highlightsRefList[index];
      const timeline = gsap.timeline({ paused: true });
      ref.forEach((refitem) => {
        timeline.to(refitem.current, { opacity: 1 });
      });
      timelinesTemp.push(timeline);
      bgimgStylesTemp.push(item?.fields?.bgimg_position?.value);
    });
    setTimelines(timelinesTemp);
    setBgimgStyles(bgimgStylesTemp);
    return () => {
      timelinesTemp.forEach((item) => item.kill());
    };
  }, []);

  const imgEffect = (value, i) => {
    setTabIndex(i);
    const timeline = gsap.timeline({ paused: false }).to(contentImgRef.current, { transform: value });
    timelines.forEach((item, index) => {
      if (index !== i || i === 0) {
        if (i !== 0 && index === 0) item.play();
        else item.progress(0).pause();
      } else {
        item.play();
      }
    });
  };

  // 首屏动效
  useEffect(() => {
    const controller = new ScrollMagic.Controller();
    const timeline = gsap
      .timeline({ paused: true })
      .fromTo(homeContentRef.current, { y: 200, opacity: 0 }, { y: 0, opacity: 1 })
      .fromTo(homeButtonRef.current, { y: 200, opacity: 0 }, { y: 0, opacity: 1 }, '<');
    const scene = new ScrollMagic.Scene({
      triggerElement: homeRef.current,
      triggerHook: 0.1
    })
      .setTween(timeline)
      .addTo(controller);
    return () => {
      timeline.kill();
      scene.remove();
      scene.destroy();
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (tabIndex !== 0) return;
    const controller = new ScrollMagic.Controller();
    const timeline = gsap
      .timeline({ paused: true })
      .fromTo(title1Ref.current, { opacity: 0 }, { opacity: 1 })
      .fromTo(title2Ref.current, { opacity: 0 }, { opacity: 1 });
    const scene = new ScrollMagic.Scene({
      triggerElement: contentRef.current,
      triggerHook: 0.5,
      duration: '45%'
    })
      .on('progress', function (event) {
        timeline.progress(event.progress);
      })
      .setTween(timeline)
      .addTo(controller);
    return () => {
      timeline.kill();
      scene.remove();
      scene.destroy();
      controller.destroy();
    };
  }, [tabIndex]);

  const left = () => {
    if (tabIndex !== 0) {
      imgEffect(bgimgStyles[tabIndex - 1], tabIndex - 1);
      sendDmp(fields?.left_dmp);
    }
  };

  const right = () => {
    if (tabIndex !== lists.length) {
      imgEffect(bgimgStyles[tabIndex + 1], tabIndex + 1);
      sendDmp(fields?.right_dmp);
    }
  };

  return (
    <div className="PriceSpace_v9" id={params?.pagename !== undefined ? params?.pagename : 'price_space'} ref={containerRef}>
      {!fields[getPrefixField('alone_img_reverse', isMobile)]?.value && (
        <div className="home" ref={homeRef}>
          <img className="bgimg" src={`${createFilePath(fields[getPrefixField('alone_img', isMobile)]?.value)}`} alt="广汽本田" />
          <div
            className="home_content"
            style={convertStylesStringToObject(fields[getPrefixField('text_style', isMobile)]?.value)}
            ref={homeContentRef}
            dangerouslySetInnerHTML={{
              __html: fields[getPrefixField('text', isMobile)]?.value?.replaceHtml()
            }}
          ></div>
          <div className="home_button" style={convertStylesStringToObject(fields[getPrefixField('button_style', isMobile)]?.value)}>
            <div
              className="button360"
              ref={homeButtonRef}
              onClick={() => {
                sendDmp(fields?.button360Dmp, fields?.button360link?.value);
                window.open(fields?.button360link?.value);
              }}
              dangerouslySetInnerHTML={{
                __html: fields[getPrefixField('button360', isMobile)]?.value?.replaceHtml()
              }}
            ></div>
          </div>
        </div>
      )}
      <div className="content" ref={contentRef}>
        <img
          className="bgimg"
          style={convertStylesStringToObject(fields[getPrefixField('bgimg_style', isMobile)]?.value)}
          src={`${createFilePath(fields[getPrefixField('bgimg', isMobile)]?.value)}`}
          alt="广汽本田"
          ref={contentImgRef}
        />
        {tabIndex !== 0 && (
          <div
            className="left"
            onClick={() => {
              left();
            }}
          >
            <img src={createFilePath('/common/icon/VectorLeftWhiteGrey.png')} alt="广汽本田" />
          </div>
        )}
        {tabIndex !== lists.length && (
          <div
            className="right"
            onClick={() => {
              right();
            }}
          >
            <img src={createFilePath('/common/icon/VectorRightWhiteGrey.png')} alt="广汽本田" />
          </div>
        )}
        <div className="title" style={convertStylesStringToObject(fields[getPrefixField('title_style', isMobile)]?.value)}>
          <div
            ref={title1Ref}
            dangerouslySetInnerHTML={{
              __html: fields[getPrefixField('title1', isMobile)]?.value?.replaceHtml()
            }}
          ></div>
          <div
            ref={title2Ref}
            dangerouslySetInnerHTML={{
              __html: fields[getPrefixField('title2', isMobile)]?.value?.replaceHtml()
            }}
          ></div>
        </div>
        {lists?.map((item, index) => {
          return item.fields.text_list.map((t, i) => {
            return (
              <div
                key={`${index}-${i}`}
                ref={highlightsRefList[index][i]}
                className="highlights"
                style={convertStylesStringToObject(t?.fields?.style?.value)}
                dangerouslySetInnerHTML={{
                  __html: t?.fields?.text?.value?.replaceHtml()
                }}
              ></div>
            );
          });
        })}
      </div>

      {fields[getPrefixField('alone_img_reverse', isMobile)]?.value && (
        <div className="home" ref={homeRef}>
          <img className="bgimg" src={`${createFilePath(fields[getPrefixField('alone_img', isMobile)]?.value)}`} alt="广汽本田" />
          <div
            className="home_content"
            style={convertStylesStringToObject(fields[getPrefixField('text_style', isMobile)]?.value)}
            ref={homeContentRef}
            dangerouslySetInnerHTML={{
              __html: fields[getPrefixField('text', isMobile)]?.value?.replaceHtml()
            }}
          ></div>
          <div className="home_button" style={convertStylesStringToObject(fields[getPrefixField('button_style', isMobile)]?.value)}>
            <div
              className="button360"
              ref={homeButtonRef}
              onClick={() => {
                sendDmp(fields?.button360Dmp, fields?.button360link?.value);
                window.open(fields?.button360link?.value);
              }}
              dangerouslySetInnerHTML={{
                __html: fields[getPrefixField('button360', isMobile)]?.value?.replaceHtml()
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}
