import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import './style.scss';
const FullModal = (props) => {
  const node = document.createElement('div');
  node.className = 'FullModelSourceDetail';
  document.body.appendChild(node);
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.body.removeChild(node);
      Array.from(document.getElementsByClassName('FullModelSourceDetail')).forEach((element) => element.remove());
      document.documentElement.style.overflow = 'auto';
      document.documentElement.style.overflowX = 'hidden';
    };
  }, []);
  return createPortal(<div className="FullModel">{props.children}</div>, node);
};
export default FullModal;
