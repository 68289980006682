import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { isMobileFun, getPrefixField, convertStylesStringToObject, createFilePath, GotoMall } from '../../utils/obj-utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.min.css';
import './style.scss';

export default function Banner(props) {
  const { fields } = props;
  const swiperRef = useRef(null);
  const isMobile = isMobileFun();
  const id = props?.params ? props?.params?.pagename : '';
  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    if (props.eventRef) {
      return {
        sendEvent
      };
    }
  });
  const sendEvent = () => {
    if (id) {
      window.Dmp.sectionAction(id);
      window.navigationIndex = props.fields.navigationIndex.value;
      window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
    }
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span data-html-data="' +
        (fields?.paginationdmp?.value ? fields?.paginationdmp?.value + (index + 1) : '') +
        '" class="' +
        className +
        '"></span>'
      );
    }
  };
  const AppSchemeUrlRefClick = (e) => {
    if (e.target && e.target.dataset && e.target.dataset.appschemeurl) {
      GotoMall(e.target.dataset.appschemeurl, e.target.dataset.htmlItem1, isMobile);
    }
  };
  return (
    <div className="Home_v1" id={id}>
      <div className="hash"></div>
      <Swiper
        ref={swiperRef}
        slidesPerView={1}
        className="swiper"
        spaceBetween={0}
        initialSlide={0}
        observer={true}
        resizeObserver={true}
        updateOnWindowResize={true}
        pagination={pagination}
        modules={[EffectFade, Pagination, Navigation, Autoplay]}
        navigation={{
          prevEl: '.Home_v1 .prev',
          nextEl: '.Home_v1 .next',
          disabledClass: 'disabled'
        }}
        effect={'fade'}
        fadeEffect={{ crossFade: true }}
        autoplay={{ delay: 3000, autoPlay: true, disableOnInteraction: false }}
        onInit={(e) => {
          if (swiperRef?.current?.swiper?.pagination?.bullets) {
            for (var i = 0; i < swiperRef.current.swiper.pagination.bullets.length; i++) {
              swiperRef.current.swiper.pagination.bullets[i].onclick = function (item) {
                if (item.currentTarget.dataset.htmlData) {
                  window.Dmp.clickAction(item.currentTarget.dataset.htmlData);
                }
              };
            }
          }
        }}
        onSlideChangeTransitionEnd={(e) => {}}
      >
        {fields?.lists.map((item, index) => {
          return (
            <SwiperSlide className="item" key={index}>
              {item?.fields[`${getPrefixField('bannerhtml', isMobile)}`]?.value.indexOf('data-appschemeurl') !== -1 ? (
                <div
                  dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('bannerhtml', isMobile)}`]?.value?.replaceHtml() }}
                  onClick={AppSchemeUrlRefClick}
                />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('bannerhtml', isMobile)}`]?.value?.replaceHtml() }} />
              )}
            </SwiperSlide>
          );
        })}
        {fields[`${getPrefixField('nexticon', isMobile)}`]?.value && (
          <div className="next" data-html-item={fields?.nextdmp?.value}>
            <img className="icon" src={createFilePath(fields[`${getPrefixField('nexticon', isMobile)}`]?.value)} alt="广汽本田" />
          </div>
        )}
        {fields[`${getPrefixField('nexticon', isMobile)}`]?.value && (
          <div className="prev" data-html-item={fields?.prevdmp?.value}>
            <img className="icon" src={createFilePath(fields[`${getPrefixField('previcon', isMobile)}`]?.value)} alt="广汽本田" />
          </div>
        )}
      </Swiper>
    </div>
  );
}
