import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import Detailss from '../../elements/InnerPageDetail';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMobileFun, getPrefixField, convertStylesStringToObject, createFilePath } from '../../utils/obj-utils';
import 'swiper/swiper.min.css';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const [flag, setFlag] = useState(false);
  const [btnindex, setBtnindex] = useState(0);
  const swiperRef = useRef(null);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename;
  const rootEl = useRef(null);
  const secondPageRef = useRef(null);
  const secondPageEventRef = useRef(null);
  const thirdPageRef = useRef(null);
  const thirdPageEventRef = useRef(null);
  const route = useSitecoreContext()?.sitecoreContext?.route;

  // sectionAction
  useEffect(() => {
    if (
      (fields?.placeHolderRoute?.value && !fields?.placeHolderRoute1?.value) ||
      (!fields?.placeHolderRoute?.value && fields?.placeHolderRoute1?.value)
    ) {
      props.updateRefList([sendFirstDmp, sendSecondDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current]);
    } else if (fields?.placeHolderRoute?.value && fields?.placeHolderRoute1?.value) {
      props.updateRefList([sendFirstDmp, sendSecondDmp, sendThirdDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current, thirdPageRef?.current]);
    } else {
      props.updateRefList([sendFirstDmp]);
      props.updateDmpList([rootEl?.current]);
    }
  }, []);
  const sendFirstDmp = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };

  const sendSecondDmp = () => {
    secondPageEventRef?.current?.sendEvent();
  };
  const sendThirdDmp = () => {
    thirdPageEventRef?.current?.sendEvent();
  };

  return (
    <div id={id}>
      <div className="price_exterior_v1" ref={rootEl}>
        <div className="hash"></div>
        <div className="con">
          {fields[`${getPrefixField('bg', isMobile)}`]?.value && (
            <img
              className="bg"
              style={convertStylesStringToObject(fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
              src={createFilePath(fields[`${getPrefixField('bg', isMobile)}`]?.value)}
              alt="广汽本田"
            />
          )}
          {fields[`${getPrefixField('bghtml', isMobile)}`]?.value && (
            <div
              style={convertStylesStringToObject(fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
              dangerouslySetInnerHTML={{
                __html: fields[`${getPrefixField('bghtml', isMobile)}`]?.value?.replaceHtml()
              }}
            ></div>
          )}
          <div className="in">
            {fields[`${getPrefixField('text', isMobile)}`]?.value && (
              <div dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}></div>
            )}
            <div className="bottom" style={convertStylesStringToObject(fields[`${getPrefixField('bottomstyle', isMobile)}`]?.value)}>
              {fields?.btnlists.length > 0 &&
                fields?.btnlists.map((item, index) => {
                  return (
                    <div
                      className={`btn_v1 btn_v1_${index + 1}`}
                      key={index}
                      style={convertStylesStringToObject(item?.fields[`${getPrefixField('btnstyle', isMobile)}`]?.value)}
                      dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('btn', isMobile)}`]?.value?.replaceHtml() }}
                      onClick={() => {
                        setFlag(true);
                        setBtnindex(index);
                        window.Dmp.clickAction(item?.fields?.dmp?.value, createFilePath(item?.fields?.videourl?.value));
                      }}
                    ></div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {flag && (
        <Detailss
          routePath={fields?.btnlists[btnindex]?.fields?.detailurl?.value}
          {...fields?.btnlists[btnindex]?.fields}
          close={() => setFlag(false)}
        />
      )}
      {fields?.placeHolderRoute?.value && (
        <div ref={secondPageRef}>
          <Placeholder name={fields?.placeHolderRoute?.value} rendering={route} page={route} eventRef={secondPageEventRef} />
        </div>
      )}
      {fields?.placeHolderRoute1?.value && (
        <div ref={thirdPageRef}>
          <Placeholder name={fields?.placeHolderRoute1?.value} rendering={route} page={route} eventRef={thirdPageEventRef} />
        </div>
      )}
    </div>
  );
}
