/* eslint-disable no-eval */
import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { getPrefixField, getQueryString, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import temp from '../../temp/config';
import { dataFetcher } from '../../dataFetcher';

export default function index({ fields, eventRef, params }) {
  const containerRef = useRef(null);
  const isMobile = isMobileFun();
  const [carListData, setCarListData] = useState([]);
  const [isShowAll, setisShowAll] = useState(false);
  const [type, setType] = useState(null);

  const jsons = fields?.carData?.value;
  const data = JSON.parse(jsons.replaceHtml());

  useEffect(() => {}, []);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields?.sectionDmp);
    // 二级导航高亮设置
    window.navigationIndex = fields?.navigationIndex?.value;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'price_power';
  };

  useEffect(() => {
    showCarList(['']);
    // setCarListData(getList().slice(0, 4));
    if (getQueryString('MembermobileValue')) {
      getSafeUserTag(getQueryString('MembermobileValue')); // url带参
    } else {
      if (window.attachEvent) {
        window.attachEvent(
          'message',
          function (event) {
            if (event.data && event.data.landed !== undefined && event.data.landed) {
              if (event.data.landed) {
                getSafeUserTag(event.data.mobile);
              }
            }
          },
          false
        );
      } else if (window.addEventListener) {
        window.addEventListener(
          'message',
          function (event) {
            if (event.data && event.data.landed !== undefined && event.data.landed) {
              if (event.data.landed) {
                getSafeUserTag(event.data.mobile);
              }
            }
          },
          false
        );
      }
    }

    return () => {};
  }, []);

  const getSafeUserTag = (mobile) => {
    dataFetcher('/api/honda/v1/DMP/GetSafeUserTag?mobile=' + mobile, null)
      .then(({ data }) => {
        if (data.Status) {
          if (data.Data && data.Data.retCode === 200 && data.Data.result && data.Data.result.length > 0) {
            showCarList(data?.Data.result);
          }
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showCarList = (data) => {
    let t = null;
    for (let i = 0; i < data.length; i++) {
      if (data[i] === '大车') {
        t = '大车';
      } else if (data[i] === '小车') {
        t = '小车';
      } else {
        t = null;
      }
      setType(t);
    }
    setCarListData(getList(t).slice(0, 4));
  };

  const showAll = () => {
    setCarListData(getList());
    setisShowAll(true);
  };
  const showNotAll = () => {
    containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    setTimeout(() => {
      setCarListData(getList().slice(0, 4));
      setisShowAll(false);
    }, 600);
  };

  const getList = (e) => {
    if (!e) e = type;
    let list;
    if (e === '大车') {
      list = data.big;
    } else if (e === '小车') {
      list = data.small;
    } else {
      list = [...data.big, ...data.small];
    }
    return list;
  };

  return (
    <div
      className="car-recommend-v1"
      style={{ height: isShowAll ? 'auto' : '100vh', paddingBottom: isShowAll ? '0' : '' }}
      id={params?.pagename !== undefined ? params?.pagename : 'car-recommend'}
      ref={containerRef}
    >
      <div className="title">车型推荐</div>
      <div className={`${isMobile ? 'carlist_mobile' : 'carlist_pc'}`}>
        {!isMobile &&
          carListData.map((ite, idx) => {
            return (
              <div key={idx}>
                <div>
                  <p>{ite.name}</p>
                  {ite.littleT ? <p className="LittleT">{ite.littleT}</p> : ''}
                  <p className="wan">
                    {ite.price}
                    <span>{ite.priceonly ? '万元' : '万元起'}</span>
                  </p>
                </div>
                <img
                  src={createFilePath(ite.img)}
                  alt={ite.name}
                  data-action="clickAction"
                  data-item={`${ite.imgID}`}
                  data-url={`${ite.pc_lookLink}`}
                  onClick={() => {
                    window.open(`${ite.pc_lookLink}`, '_self');
                  }}
                />
                <div className="lookCcontrol">
                  <div
                    data-action="clickAction"
                    data-item={ite.threeDID}
                    data-url={`${ite.threeD}`}
                    className="lookC"
                    onMouseOver={(e) => {
                      // e.currentTarget.firstElementChild.src = `${temp.app.deployUrlS3}/breeze/v1/pc/other/Frame360White.png`;
                      e.currentTarget.firstElementChild.src = createFilePath('/breeze/v1/pc/other/Frame360White.png');
                    }}
                    onMouseLeave={(e) => {
                      // e.currentTarget.firstElementChild.src = `${temp.app.deployUrlS3}/breeze/v1/pc/other/Frame360Black.png`;
                      e.currentTarget.firstElementChild.src = createFilePath('/breeze/v1/pc/other/Frame360Black.png');
                    }}
                    onClick={() => {
                      window.open(ite.threeD);
                    }}
                  >
                    <img
                      data-action="clickAction"
                      data-item={`${ite.threeDID}`}
                      data-url={`${ite.threeD}`}
                      // src={`${temp.app.deployUrlS3}/breeze/v1/pc/other/Frame360Black.png`}
                      src={createFilePath('/breeze/v1/pc/other/Frame360Black.png')}
                      alt="3D看车"
                    />
                    <p data-action="clickAction" data-item={`${ite.threeDID}`} data-url={`${ite.threeD}`}>
                      3D看车
                    </p>
                  </div>
                  {ite.immdUrl && (
                    <div
                      data-action="clickAction"
                      data-item={ite.immdID}
                      data-url={`${ite.immdUrl}`}
                      className="immdbtn"
                      onClick={() => {
                        window.open(ite.immdUrl);
                      }}
                    >
                      {ite.immdName ? ite.immdName : 'i-MMD'}
                    </div>
                  )}
                </div>
                <div className="yu">
                  <p
                    data-action="clickAction"
                    data-item={`${ite.carID}`}
                    data-url={`${ite.pc_car_id}`}
                    onClick={() => {
                      window.open(`${ite.pc_car_id}`, '_self');
                    }}
                  >
                    预约试驾 &gt;
                  </p>
                  <p
                    data-action="clickAction"
                    data-item={`${ite.lookID}`}
                    data-url={`${ite.pc_lookLink}`}
                    onClick={() => {
                      window.open(`${ite.pc_lookLink}`, '_self');
                    }}
                  >
                    了解车型 &gt;
                  </p>
                </div>
              </div>
            );
          })}
        {isMobile &&
          carListData.map((it, iex) => {
            return (
              <div key={iex} className="">
                <div className="carLeft">
                  <div className="lookImg">
                    <img
                      src={createFilePath(it.img)}
                      data-action="clickAction"
                      data-item={`${it.imgID}`}
                      data-url={`${it.lookLink}`}
                      onClick={() => {
                        window.open(`${it.lookLink}`, '_self');
                      }}
                      className=""
                      alt={it.name}
                    />
                  </div>
                  <div className="lookCcontrol">
                    <div
                      data-action="clickAction"
                      data-item={`${it.threeDID}`}
                      data-url={`${it.threeD}`}
                      className="lookCar"
                      onClick={() => {
                        window.open(it.threeD);
                      }}
                    >
                      <img
                        data-action="clickAction"
                        data-item={`${it.threeDID}`}
                        data-url={`${it.threeD}`}
                        // src={`${temp.app.deployUrlS3}/breeze/v1/pc/other/Frame360Black.png`}
                        src={createFilePath('/breeze/v1/pc/other/Frame360Black.png')}
                        className=""
                        alt="3D看车"
                      />
                      <p data-action="clickAction" data-item={`${it.threeDID}`} data-url={`${it.threeD}`} className="">
                        3D看车
                      </p>
                    </div>
                    {it.immdUrl && (
                      <div
                        data-action="clickAction"
                        data-item={it.immdID}
                        data-url={`${it.immdUrl}`}
                        className="immdbtn"
                        onClick={() => {
                          window.open(it.immdUrl);
                        }}
                      >
                        {it.immdName ? it.immdName : 'i-MMD'}
                      </div>
                    )}
                  </div>
                </div>

                <div className="carRight">
                  <div className="">
                    <p className={it.littleT ? 'exceed' : ''}>
                      {it.name}
                      {it.littleT ? <br /> : ''}
                      {it.littleT ? it.littleT : ''}
                    </p>

                    <p>
                      {it.price}
                      {it.priceonly ? '万元' : '万元起'}
                    </p>
                  </div>
                  <div className="">
                    <p
                      data-action="clickAction"
                      data-item={`${it.carID}`}
                      data-url={`${it.car_id}`}
                      onClick={() => {
                        window.open(`${it.car_id}`, '_self');
                      }}
                      className=""
                    >
                      预约试驾 &gt;
                    </p>
                    <p
                      data-action="clickAction"
                      data-item={`${it.lookID}`}
                      data-url={`${it.lookLink}`}
                      onClick={() => {
                        window.open(`${it.lookLink}`, '_self');
                      }}
                      className=""
                    >
                      了解车型 &gt;
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="buttons">
        {!isShowAll && (
          <div
            onClick={() => {
              sendDmp(fields[`${getPrefixField('open_button_dmp', isMobile)}`]);
              showAll();
            }}
            dangerouslySetInnerHTML={{
              __html: fields[`${getPrefixField('open_button', isMobile)}`]?.value?.replaceHtml()
            }}
          ></div>
        )}
        {isShowAll && (
          <div
            onClick={() => {
              sendDmp(fields[`${getPrefixField('close_button_dmp', isMobile)}`]);
              showNotAll();
            }}
            dangerouslySetInnerHTML={{
              __html: fields[`${getPrefixField('close_button', isMobile)}`]?.value?.replaceHtml()
            }}
          ></div>
        )}
      </div>
    </div>
  );
}
