import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, getQueryString, isInView, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import FullModalDetail from '../../elements/FullModalDetail_v1';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const jsonData = JSON.parse(fields?.jsonData?.value);
  // const jsonData = {
  //   "lists": [
  //     {
  //       "name": "ADS全时自适应减震系统",
  //       "detail": "根据不同路面状态和行驶条件调整减震器的软硬度，满足你的驾驶乐趣与舒适的乘坐体验。",
  //       "fm": "/avancier/v2/pc/power/lists_videofm1.jpg",
  //       "bigfm": "/avancier/v2/pc/power/lists_video1.jpg",
  //       "video": "/avancier/v2/pc/power/power_video1.mp4",
  //       "opendmp": "price_power2-video-open1",
  //       "closedmp": "price_power2-video-close1",
  //       "btn": "完整视频"
  //     },
  //     {
  //       "name": "操控稳定和响应快速的悬架系统",
  //       "detail": "采用麦弗逊式独立前悬挂和高刚性复合式多连杆独立后悬挂，保证车辆的响应性能和操控稳定性。",
  //       "fm": "/avancier/v2/pc/power/lists_videofm2.jpg",
  //       "bigfm": "/avancier/v2/pc/power/lists_video2.jpg",
  //       "video": "/avancier/v2/pc/power/power_video2.mp4",
  //       "opendmp": "price_power2-video-open2",
  //       "closedmp": "price_power2-video-close2",
  //       "btn": "完整视频"
  //     },
  //     {
  //       "name": "VGR速比可变电动助力转向系统",
  //       "detail": "根据转向角度调整轮胎前倾角，获得优异的转向助力与平衡性。",
  //       "fm": "/avancier/v2/pc/power/lists_videofm3.jpg",
  //       "bigfm": "/avancier/v2/pc/power/lists_video3.jpg",
  //       "video": "/avancier/v2/pc/power/power_video3.mp4",
  //       "opendmp": "price_power2-video-open3",
  //       "closedmp": "price_power2-video-close3",
  //       "btn": "完整视频"
  //     }
  //   ],
  //   "m_lists": [
  //     {
  //       "name": "ADS全时自适应减震系统",
  //       "detail": "根据不同路面状态和行驶条件调整减震器的软硬度，<br>满足你的驾驶乐趣与舒适的乘坐体验。",
  //       "fm": "/avancier/v2/mobile/power/lists_videofm1.jpg",
  //       "bigfm": "/avancier/v2/mobile/power/lists_videofm1.jpg",
  //       "video": "/avancier/v2/mobile/power/power_video1.mp4",
  //       "opendmp": "price_power2-video-open1",
  //       "closedmp": "price_power2-video-close1",
  //       "btn": "完整视频"
  //     },
  //     {
  //       "name": "操控稳定和响应快速的悬架系统",
  //       "detail": "采用麦弗逊式独立前悬挂和高刚性复合式多连杆独立<br>后悬挂，保证车辆的响应性能和操控稳定性。",
  //       "fm": "/avancier/v2/mobile/power/lists_videofm2.jpg",
  //       "bigfm": "/avancier/v2/mobile/power/lists_videofm2.jpg",
  //       "video": "/avancier/v2/mobile/power/power_video2.mp4",
  //       "opendmp": "price_power2-video-open2",
  //       "closedmp": "price_power2-video-close2",
  //       "btn": "完整视频"
  //     },
  //     {
  //       "name": "VGR速比可变电动助力转向系统",
  //       "detail": "根据转向角度调整轮胎前倾角，获得优异的<br>转向助力与平衡性。",
  //       "fm": "/avancier/v2/mobile/power/lists_videofm3.jpg",
  //       "bigfm": "/avancier/v2/mobile/power/lists_videofm3.jpg",
  //       "video": "/avancier/v2/mobile/power/power_video3.mp4",
  //       "opendmp": "price_power2-video-open3",
  //       "closedmp": "price_power2-video-close3",
  //       "btn": "完整视频"
  //     }
  //   ],
  //   "prevdmp": "price_power2-btnl",
  //   "nextdmp": "price_power2-btnr",
  //   "paginationdmp": "price_power2-btn",
  //   "prodprevdmp": "price_power2-btnl",
  //   "prodnextdmp": "price_power2-btnr"
  // }
  const [showDetail, setShowDetail] = useState(false);
  const isMobile = isMobileFun();
  const [propindex, setPropindex] = useState(0);
  const id = props?.params?.pagename ? props?.params?.pagename : 'price_power1';
  const rootEl = useRef(null);
  const SwiperRef = useRef(null);
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id;
  };
  const windowhidden = () => {
    document.documentElement.style.overflow = 'hidden';
  };
  const closewindowhidden = () => {
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.overflowX = 'hidden';
  };
  return (
    <div className="price-power-v7" id={id} ref={rootEl}>
      <div className="lists">
        <Swiper
          ref={SwiperRef}
          slidesPerView={isMobile ? 1 : 3}
          className="swiper-prop"
          spaceBetween={0}
          initialSlide={propindex}
          observer={true}
          resizeObserver={true}
          updateOnWindowResize={true}
          modules={[Navigation, Pagination]}
          navigation={{
            prevEl: '.price-power-v7 .lists .prev',
            nextEl: '.price-power-v7 .lists .next',
            disabledClass: 'disabled'
          }}
          pagination={
            isMobile
              ? {
                  el: '.price-power-v7-pagination',
                  clickable: true
                }
              : false
          }
          onInit={(e) => {
            if (isMobile && jsonData?.paginationdmp) {
              e?.pagination?.bullets?.forEach((item, index) => {
                item.onclick = function () {
                  if (e.realIndex !== index) {
                    window.Dmp.clickAction(`${jsonData?.paginationdmp}${index + 1}`);
                  }
                };
              });
            }
          }}
          onSlideChangeTransitionEnd={(e) => {
            setPropindex(e.activeIndex);
          }}
        >
          {jsonData[getPrefixField('lists', isMobile)]?.map((item, index) => {
            return (
              <SwiperSlide className="item" key={index}>
                <div className="pic">
                  <img alt="广汽本田" src={`${createFilePath(item?.fm)}`} />
                  {isMobile ? (
                    <div
                      className="btn"
                      onClick={() => {
                        setShowDetail(true);
                        setPropindex(index);
                        windowhidden();
                        window.Dmp.clickAction(item?.opendmp);
                      }}
                      dangerouslySetInnerHTML={{ __html: item?.btn.replaceHtml() }}
                    ></div>
                  ) : (
                    <div
                      className="play"
                      onClick={() => {
                        setShowDetail(true);
                        setPropindex(index);
                        windowhidden();
                        window.Dmp.clickAction(item?.opendmp);
                      }}
                    >
                      <img alt="广汽本田" src={createFilePath('/avancier/v2/pc/power/play.png')} />
                    </div>
                  )}
                </div>
                <div className="name" dangerouslySetInnerHTML={{ __html: item?.name.replaceHtml() }}></div>
                {isMobile && <div className="p" dangerouslySetInnerHTML={{ __html: item?.detail.replaceHtml() }}></div>}
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="controls">
          <div className="prev" data-html-item={jsonData.prevdmp}></div>
          <div className="price-power-v7-pagination swiper-pagination" />
          <div className="next" data-html-item={jsonData.nextdmp}></div>
        </div>
      </div>
      {showDetail && (
        <FullModalDetail>
          <div className="price-power-v7-prop">
            <div
              className="close"
              onClick={() => {
                setShowDetail(false);
                closewindowhidden();
                window.Dmp.clickAction(jsonData[getPrefixField('lists', isMobile)][propindex]?.closedmp);
              }}
            >
              {isMobile ? (
                <img alt="广汽本田" src={createFilePath('/avancier/v2/mobile/close_icon.png')} />
              ) : (
                <img alt="广汽本田" src={createFilePath('/avancier/v2/pc/close_icon.png')} />
              )}
            </div>
            <Swiper
              slidesPerView={1}
              className="swiper-prop"
              spaceBetween={0}
              initialSlide={propindex}
              observer={true}
              resizeObserver={true}
              updateOnWindowResize={true}
              modules={[Navigation]}
              navigation={{
                prevEl: '.price-power-v7-prop .prev',
                nextEl: '.price-power-v7-prop .next',
                disabledClass: 'disabled'
              }}
              onSlideChangeTransitionEnd={(e) => {
                setPropindex(e.activeIndex);
              }}
            >
              {jsonData[getPrefixField('lists', isMobile)]?.map((item, index) => {
                return (
                  <SwiperSlide className="item" key={index}>
                    <div className="pic">
                      {propindex === index ? (
                        <video
                          x5-playsinline="true"
                          webkit-playsinline="true"
                          playsInline={true}
                          src={`${createFilePath(item.video)}`}
                          poster={`${createFilePath(item.bigfm)}`}
                          controls
                          loop
                          autoPlay
                          preload="true"
                        />
                      ) : (
                        <img alt="广汽本田" src={`${createFilePath(item.bigfm)}`} />
                      )}
                    </div>
                    {!isMobile && (
                      <>
                        <div className="name" dangerouslySetInnerHTML={{ __html: item?.name.replaceHtml() }}></div>
                        <div className="p" dangerouslySetInnerHTML={{ __html: item?.detail.replaceHtml() }}></div>
                      </>
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="next" data-html-item={jsonData.prodnextdmp}></div>
            <div className="prev" data-html-item={jsonData.prodprevdmp}></div>
          </div>
        </FullModalDetail>
      )}
    </div>
  );
}
