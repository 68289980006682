import React, { useEffect, useState, useRef } from 'react';
import { Swiper, Pagination, Navigation } from 'swiper';
import './style.scss';
import { isMobile as isDeviceMobile } from '../../utils/dom-utils';
import { getPrefixField, isEmpty, sendDmpAction, createFilePath } from '../../utils/obj-utils';
import { HeroSupportTypeEnum } from '../../utils/enum';
import HeroSupportItemCard from '../../elements/HeroSupport_v1/Item/Card';
import HeroSupportItemNormal from '../../elements/HeroSupport_v1/Item/Normal';
import Icon from '../../elements/icon';

export default function Index({ fields }) {
  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const title = fields[getPrefixField('title')]?.value;
    const description = fields[getPrefixField('description')]?.value;
    const type = fields[getPrefixField('type')]?.fields?.v?.value;
    const itemList = fields[getPrefixField('item_list')];
    const remarks = fields[getPrefixField('remarks')]?.value;
    const background = createFilePath(fields[getPrefixField('background')]?.value);
    const showBackground = !isEmpty(fields[getPrefixField('background')]?.value);
    const dmpActionLeft = fields[getPrefixField('dmp_action_left')]?.value;
    const dmpPointLeftList = fields[getPrefixField('dmp_point_left')];
    const dmpPointLeft = dmpPointLeftList?.length > 0 ? dmpPointLeftList[0].name : '';
    const dmpActionRight = fields[getPrefixField('dmp_action_right')]?.value;
    const dmpPointRightList = fields[getPrefixField('dmp_point_right')];
    const dmpPointRight = dmpPointRightList?.length > 0 ? dmpPointRightList[0].name : '';
    const isMobile = isDeviceMobile();
    setData({
      title,
      description,
      type,
      itemList,
      remarks,
      dmpActionLeft,
      dmpPointLeft,
      dmpActionRight,
      dmpPointRight,
      background,
      showBackground,
      isMobile
    });
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }
    const resize = () => {
      const isMobile = isDeviceMobile();
      if (data.isMobile !== isMobile) {
        setData({ ...data, isMobile });
      }
    };
    window.addEventListener('resize', resize);

    const swiper = new Swiper(swiperRef.current, {
      grabCursor: true,
      modules: [Pagination, Navigation],
      slidesPerView: data?.isMobile ? 1 : 3,
      pagination: data?.isMobile
        ? {
            el: containerRef.current.querySelector('.pagination'),
            clickable: true,
            bulletActiveClass: 'pagination-bullets-active',
            bulletClass: 'pagination-bullets'
          }
        : false,

      navigation: {
        prevEl: containerRef.current.querySelector('.navigation-prev'),
        nextEl: containerRef.current.querySelector('.navigation-next'),
        disabledClass: 'navigation-disabled'
      },
      on: {
        slidePrevTransitionStart: () => {
          sendDmpAction(data?.dmpActionLeft, data?.dmpPointLeft, '');
        },
        slideNextTransitionStart: () => {
          sendDmpAction(data?.dmpActionRight, data?.dmpPointRight, '');
        }
      }
    });

    return () => {
      window.removeEventListener('resize', resize);
      swiper.destroy(true, true);
    };
  }, [data]);

  return (
    <section className="hero-support" ref={containerRef}>
      {data ? (
        <React.Fragment>
          {data?.showBackground && <img alt="广汽本田" className="background" src={data?.background} />}
          <div className="content-container">
            {!isEmpty(data?.title) && <div className="title" dangerouslySetInnerHTML={{ __html: data?.title?.replaceHtml() }} />}
            {!isEmpty(data?.description) && <div className="description" dangerouslySetInnerHTML={{ __html: data?.description?.replaceHtml() }} />}
            <div className="swiper-container">
              <div className="hero-support-swiper" ref={swiperRef}>
                <div className="swiper-wrapper">
                  {data?.type === HeroSupportTypeEnum.card && (
                    <React.Fragment>
                      {data?.itemList?.map((item, index) => {
                        return (
                          <div className="swiper-slide" key={index}>
                            <HeroSupportItemCard data={item} />
                          </div>
                        );
                      })}
                    </React.Fragment>
                  )}
                  {data?.type === HeroSupportTypeEnum.normal && (
                    <React.Fragment>
                      {data?.itemList?.map((item, index) => {
                        return (
                          <div className="swiper-slide" key={index}>
                            <HeroSupportItemNormal data={item} />
                          </div>
                        );
                      })}
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div className="navigation-prev">
                <Icon name="ArrowLeft" />
              </div>
              <div className="navigation-next">
                <Icon name="ArrowRight" />
              </div>
            </div>
            {!isEmpty(data?.remarks) && <div className="remarks" dangerouslySetInnerHTML={{ __html: data?.remarks?.replaceHtml() }} />}
            {data?.isMobile && <div className="pagination" />}
          </div>
        </React.Fragment>
      ) : null}
    </section>
  );
}
