import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { getPrefixField, createFilePath } from '../../utils/obj-utils';
import { Swiper } from 'swiper';
import { Navigation } from 'swiper';

export default function index(props) {
  const { wallpaper_list, isMobile } = props;
  const containerRef = useRef(null);
  const swiperRef = useRef(null);
  const swiper2Ref = useRef(null);
  const mbswiperRef = useRef(null);
  const [num, setNum] = useState(0);
  const [proppic, setProppic] = useState(null);
  const [propbool, setPropbool] = useState(false);
  let pcswiper;
  let pcswiper2;
  let swipermb;

  useEffect(() => {
    if (!isMobile) {
      pcswiper = new Swiper(swiperRef.current, {
        modules: [Navigation],
        slidesPerView: 5,
        navigation: {
          prevEl: containerRef.current.querySelector('.prev1'),
          nextEl: containerRef.current.querySelector('.next1'),
          disabledClass: 'disabled'
        }
      });

      return () => {
        if (pcswiper) {
          pcswiper.destroy(true, true);
        }
      };
    } else {
      swipermb = new Swiper(mbswiperRef.current, {
        modules: [Navigation],
        slidesPerView: 'auto',
        navigation: {
          prevEl: containerRef.current.querySelector('.prev'),
          nextEl: containerRef.current.querySelector('.next'),
          disabledClass: 'disabled'
        }
      });

      return () => {
        if (swipermb) {
          swipermb.destroy(true, true);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (!isMobile) {
      pcswiper2 = new Swiper(swiper2Ref.current, {
        modules: [Navigation],
        slidesPerView: 3,
        navigation: {
          prevEl: containerRef.current.querySelector('.prev2'),
          nextEl: containerRef.current.querySelector('.next2'),
          disabledClass: 'disabled'
        }
      });
      return () => {
        if (pcswiper2) {
          pcswiper2.destroy(true, true);
        }
      };
    }
  }, [num]);

  return (
    <div className="wallpaperdownload-list" ref={containerRef}>
      {!isMobile ? (
        <>
          <div className="carList">
            <div className="list_swiperWrap">
              <div className="swiper-container">
                <div className="swiper-support" ref={swiperRef}>
                  <div className="swiper-wrapper">
                    {wallpaper_list &&
                      wallpaper_list.length > 0 &&
                      wallpaper_list.map((item, index) => {
                        return (
                          <div
                            className={`swiper-slide ${index === num ? 'active' : ''}`}
                            key={index}
                            onClick={() => {
                              setNum(index);
                              pcswiper2 && pcswiper2.slideTo(0, 500, false);
                            }}
                          >
                            <img alt={item.fields?.wcar_name?.value} src={createFilePath(item.fields?.wcar_img?.value)} />
                            <p dangerouslySetInnerHTML={{ __html: item.fields?.wcar_name?.value?.replaceHtml() }} />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="prev prev1"></div>
              <div className="next next1"></div>
            </div>
          </div>
          <div className="wallpaper">
            <div className="swiper-container">
              <div className="swiper-support" ref={swiper2Ref}>
                <div className="swiper-wrapper">
                  {wallpaper_list[num].fields?.wcar_list?.length > 0 &&
                    wallpaper_list[num].fields?.wcar_list.map((item, index) => {
                      if (item.fields?.size_590?.value && item.fields?.size_1920?.value) {
                        return (
                          <div className="swiper-slide" key={index}>
                            <img alt="广汽本田" src={createFilePath(item.fields?.size_590?.value)} className="swiper-slide-img" />
                            <a href={createFilePath(item.fields?.size_1920?.value)} download className="down">
                              点击下载
                            </a>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <div className="prev prev2"></div>
              <div className="next next2"></div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="tabs">
            <div className="swiper-container" ref={mbswiperRef}>
              <div className="swiper-wrapper">
                {wallpaper_list &&
                  wallpaper_list.length > 0 &&
                  wallpaper_list.map((item, index) => {
                    return (
                      <div
                        className="swiper-slide"
                        key={index}
                        onClick={() => {
                          setNum(index);
                        }}
                      >
                        <div className={`name ${num === index ? ' on' : ''}`}>
                          <span dangerouslySetInnerHTML={{ __html: item.fields?.m_wcar_name?.value?.replaceHtml() }}></span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="prev"></div>
            <div className="next"></div>
          </div>
          <div className="con">
            {wallpaper_list[num].fields?.wcar_list?.length > 0 &&
              wallpaper_list[num].fields?.wcar_list.map((item, index) => {
                if (item.fields?.size_750?.value) {
                  return (
                    <div
                      className="item"
                      key={index}
                      onClick={() => {
                        setProppic(createFilePath(item.fields?.size_750?.value));
                        setPropbool(true);
                      }}
                    >
                      <img alt="广汽本田" src={createFilePath(item.fields?.size_750?.value)} className="img" />
                    </div>
                  );
                }
              })}
          </div>
          {propbool && (
            <div className="prop">
              <div className="pic">
                <img alt="广汽本田" src={proppic} />
              </div>
              <div className="down">长按保存</div>
              <div
                className="graybg"
                onClick={() => {
                  setProppic(null);
                  setPropbool(false);
                }}
              ></div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
