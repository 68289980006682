import React, { useState } from 'react';
import './style.scss';
import { getPrefixField, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { gatelog_car, isMobile } = props;
  const car_name = gatelog_car.fields[getPrefixField('gcar_name', isMobile)]?.value;
  const car_img = gatelog_car.fields[getPrefixField('gcar_img', isMobile)]?.value;
  const [mouseHover, setMouseHover] = useState(false);

  return (
    <div className="video_content_co" onMouseEnter={() => setMouseHover(true)} onMouseLeave={() => setMouseHover(false)}>
      <p className="download_title" dangerouslySetInnerHTML={{ __html: car_name.replaceHtml() }} />
      <img alt="广汽本田" className="gatelog_car_img" src={createFilePath(car_img)} />
      <i>
        <a href={createFilePath(gatelog_car.fields?.gcar_download?.value)} className="interactDown">
          <img
            alt="广汽本田"
            className="download_img"
            src={createFilePath(mouseHover && !isMobile ? '/interaction/download_white.png' : '/interaction/download_red.png')}
          />
          <span>下载型录</span>
        </a>
      </i>
    </div>
  );
}
