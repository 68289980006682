import React, { useState } from 'react';
import './style.scss';
import ManualCar from '../ManualCar';
import { createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { carList, isMobile, isWx, isWxReady, wxLink } = props;
  const [data, _] = useState({ carList, isMobile });

  return (
    <div className="product_list">
      {data.isMobile
        ? data.carList.map((item, index) => {
            return index % 2 === 0 ? (
              <div
                key={index}
                className="product_list_li"
                style={{
                  background: `url(${createFilePath('/service/manual/mobile/product_bj_all.jpg')}) center center no-repeat`
                }}
              >
                <img alt="广汽本田" className="all_bg" src={`${createFilePath('/service/manual/mobile/product_bj_all.jpg')}`} />
                <ManualCar car={data.carList[index]} isMobile={data.isMobile} isWxReady={isWxReady} isWx={isWx} wxLink={wxLink} />
                {index + 1 < data.carList.length && (
                  <ManualCar car={data.carList[index + 1]} isMobile={data.isMobile} isWxReady={isWxReady} isWx={isWx} wxLink={wxLink} />
                )}
              </div>
            ) : null;
          })
        : data.carList.map((item, index) => {
            return <ManualCar car={item} isMobile={data.isMobile} key={index} isWxReady={isWxReady} isWx={isWx} wxLink={wxLink} />;
          })}
    </div>
  );
}
