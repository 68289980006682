import React, { useEffect, useState, useRef } from 'react';
import { isMobileFun, getPrefixField, convertStylesStringToObject, GotoMall, getFooterCarHeight } from '../../utils/obj-utils';
import Detailss from '../../elements/InnerPageDetail';
import { getQueryVariable } from '../../utils/site-utils';
import './style.scss';
export default function Banner(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const [flag, setFlag] = useState(false);
  const [fixflag, setFixFlag] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    if (isMobile) {
      const scroll = () => {
        if (
          document.documentElement.scrollTop > 0 &&
          document.documentElement.scrollHeight - document.documentElement.scrollTop - document.documentElement.clientHeight - getFooterCarHeight() >
            0
        ) {
          if (window.navigationPagename !== 'home' && window.navigationPagename !== 'choose') {
            setFixFlag(true);
          } else {
            setFixFlag(false);
          }
        } else {
          setFixFlag(false);
        }
      };
      window.addEventListener('scroll', scroll);
      return () => {
        window.removeEventListener('scroll', scroll);
      };
    }
  }, []);
  // tabs
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      fields?.detailurllists.length > 0 &&
        fields?.detailurllists.map((item, index) => {
          let tabArr = item.fields?.tabname?.value.split(',').map((tItem) => tItem.toLowerCase());
          if (tabArr.includes(value)) {
            defaultIndex = index;
          }
        });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(fields.tabsdefault?.value?.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabIndex(tabInd < 0 ? (defaultTabInd < 0 ? tabIndex : defaultTabInd) : tabInd);
      } else {
        setTabIndex(defaultTabInd < 0 ? tabIndex : defaultTabInd);
      }
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    fixflag && (
      <div className="FooterFixmeau_v1" style={convertStylesStringToObject(fields[`${getPrefixField('meaustyle')}`]?.value)}>
        <div
          className="configbtn"
          style={convertStylesStringToObject(fields[`${getPrefixField('configbtnstyle', isMobile)}`]?.value)}
          dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('configbtn', isMobile)}`]?.value?.replaceHtml() }}
          onClick={() => {
            setFlag(true);
            window.Dmp.clickAction(fields?.configbtndmp?.value);
          }}
        ></div>
        <div
          className="mallbtn"
          style={convertStylesStringToObject(fields[`${getPrefixField('mallbtnstyle', isMobile)}`]?.value)}
          dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('mallbtn', isMobile)}`]?.value?.replaceHtml() }}
          onClick={() => {
            if (fields?.mallurl?.value) {
              GotoMall(fields?.mallurl?.value, fields?.mallbtndmp?.value, isMobile);
            }
          }}
        ></div>
        {flag && (
          <Detailss
            routePath={fields?.detailurllists.length > 0 ? fields?.detailurllists[tabIndex]?.fields?.detailurl?.value : fields?.detailurl?.value}
            close={() => setFlag(false)}
          />
        )}
      </div>
    )
  );
}
