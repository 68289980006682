import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { createFilePath, checkMobile, getPrefixField, isEmpty, getQueryString, sendDmpAction, sendDmp, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { dataFetcher } from '../../dataFetcher';
import $ from 'jquery';
import { getQueryVariable } from '../../utils/site-utils';
import FullModalDetail from '../../elements/FullModalDetail_v1';
import { useHistory } from 'react-router-dom';
import FindDealer from '../../elements/FindDealer';

export default function index(props) {
  var map1;
  var infoWindow;
  var markers = [];
  const { fields } = props;
  const history = useHistory();
  const isMobile = isMobileFun();
  const carData = fields.carData.value;
  const staticMenu = fields.staticMenu;
  const method = fields[getPrefixField('method', isMobile)]?.value || 2;
  const [data, _] = useState({ isMobile, carData, staticMenu, method });
  const carList = JSON.parse(data.carData.replaceHtml());
  const car = getQueryVariable('car_id') ? carList.find((item) => item.modelName.toLowerCase() === getQueryVariable('car_id').toLowerCase()) : '';
  const carIndex = carList.indexOf(car);
  const [num, setNum] = useState('');
  const [mobileCheckShow, setMobileCheckShow] = useState(false);
  const [dealerProvince, setDealerProvince] = useState([]);
  const [dealerProCitys, setDealerProCitys] = useState([]);
  const [dealerDealers, setDealerDealers] = useState([]);
  const [selectVehicleType, setSelectVehicleType] = useState(carList[carIndex]);
  const [dealerAddress, setDealerAddress] = useState('');
  const [agree, setAgree] = useState(false);
  const [mapShow, setMapShow] = useState(false);
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [verificationImage, setVerificationImage] = useState();
  const [messageInfo, setMessageInfo] = useState({ sendType: 1, validWhiteList: false, whiteListType: 0 });
  const [policyVersion, setPolicyVersion] = useState(null);
  // 预约参数模板
  const [person, setPerson] = useState({
    userName: '',
    mobile: '',
    provinceId: '',
    cityId: '',
    dealerId: '',
    modelName: car ? car.modelName : '',
    mediafrom: data.method,
    graphic: {
      code: '', // 用户录入的图形验证码
      id: '', // 图形验证码id
      preMod: false
    },
    smsValidId: '', // 短信验证码id
    smsValidCode: '', // 用户录入的短信验证码
    series_code: '',
    device_type: data.isMobile ? 2 : 1,
    areaCode: '',
    adcode: '',
    contactid: useSitecoreContext()?.sitecoreContext?.contactId
  });
  const originalData = useRef(null);
  const province = useRef(null);
  const city = useRef(null);
  const shopListRef = useRef(null);
  const verificationCodeRef = useRef(null);
  const messageCodeRef = useRef(null);
  const lastPerson = useRef(null);
  const latLng = [113.264385, 23.129112];

  const getPolicyVersion = () => {
    dataFetcher('/api/honda/v1/Policy/GetPolicyVersion')
      .then(({ data }) => {
        if (data.Status) {
          setPolicyVersion(data?.Data?.policy_code);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    let carType = getQueryVariable('car_id') ? carList.find((item) => item.modelName.toLowerCase() === getQueryVariable('car_id').toLowerCase()) : '';
    let carTypeIndex = carList.indexOf(carType);
    const timer = setTimeout(() => {
      if (carTypeIndex >= 0) {
        setNum(carTypeIndex);
      }
    }, 1000);
    getPolicyVersion();
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const setMapShow1 = (e) => {
    setMapShow(e);
  };
  // mobile端点击切换渲染汽车数据
  const mobileSelectCar = (e) => {
    let car_value = e.target.childNodes[e.target.selectedIndex].getAttribute('value');
    if (car_value) {
      person.modelName = car_value;
      let car = carList.find((item) => item.modelName === car_value);
      let newPerson = { ...person };
      const dmpPointList = fields.m_car_dmp_point;
      if (dmpPointList?.length > 0) {
        sendDmp(dmpPointList, car.seriesId);
      }
      setPerson(newPerson);
      setSelectVehicleType(car);
    }
  };

  // pc端点击选择车型
  const pcSelectCar = (ind) => {
    setNum(ind);
    setSelectVehicleType(carList[ind]);
    person.modelName = carList[ind].modelName;
    let newPerson = { ...person };
    setPerson(newPerson);
  };

  const verificationCodeChange = (e) => {
    messageInfo.validCode = e.target.value;
  };

  const messageCodeChange = (e) => {
    person.smsValidCode = e.target.value;
  };

  const addLeads = () => {
    dataFetcher('/api/honda/v1/Leads/AddLeads', person)
      .then(({ data }) => {
        if (data?.Status) {
          const personData = {
            n: person.userName,
            tel: person.mobile,
            prv: person.provinceId,
            city: person.cityId,
            dl: person.dealerId,
            ver: policyVersion
          };
          alert('提交成功！');
          sendDmpAction('submit', 'drive', personData);
          lastPerson.current = { ...person };
          close();
          history.push('/buy/tools/result');
        } else {
          const errorCode = parseInt(data?.ErrorResult?.ErrCode);
          if (errorCode === 26 || errorCode === 27 || errorCode === 28) {
            if (errorCode === 28 && showVerificationCode) {
              return Promise.reject(new Error('短信验证码输入错误！'));
            } else {
              setShowVerificationCode(true);
              getVerificationImage();
            }
          } else {
            return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
          }
        }
      })
      .catch((error) => {
        alert(error?.message);
        console.error(error);
      });
  };

  const close = () => {
    setPerson({
      ...person,
      graphic: {
        code: '',
        id: '',
        preMod: false
      },
      smsValidId: '',
      smsValidCode: ''
    });
    setMessageInfo({ ...messageInfo, validId: '', validCode: '', phone: '' });
    verificationCodeRef?.current && (verificationCodeRef.current.value = '');
    messageCodeRef?.current && (messageCodeRef.current.value = '');
    setShowVerificationCode(false);
  };

  const getVerificationImage = () => {
    dataFetcher('/api/honda/v1/Graphic/CreateCode')
      .then(({ data }) => {
        if (data?.Status) {
          person.graphic.id = data?.Data?.GraphicValidId;
          messageInfo.validId = data?.Data?.GraphicValidId;
          messageInfo.validCode = '';
          verificationCodeRef.current.value = '';
          setVerificationImage(data?.Data?.Base64);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const personSubmit = () => {
    if (isEmpty(person.userName)) {
      alert('请填写真实姓名');
      return;
    }
    if (!checkMobile(person.mobile)) {
      alert('请填写正确的联系方式');
      return;
    }
    if (!selectVehicleType) {
      alert('请选择意向车型');
      return;
    }
    if (!person.provinceId) {
      alert('请选择省份');
      return;
    }
    if (!person.cityId) {
      alert('请选择城市');
      return;
    }
    if (!person.dealerId) {
      alert('请选择特约店');
      return;
    }
    if (!agree) {
      alert('请确认并阅读声明');
      return;
    }
    if (showVerificationCode && isEmpty(person.smsValidCode)) {
      alert('短信验证码不能为空');
      return;
    }
    person.series_code = selectVehicleType?.seriesId;
    person.adcode = getQueryString('adcode');
    person.graphic.code = messageInfo?.validCode;
    if (lastPerson?.current) {
      let exist = false;
      for (const key in lastPerson?.current) {
        if (!['graphic', 'smsValidId', 'smsValidCode'].includes(key) && lastPerson?.current[key] !== person[key]) {
          exist = true;
          break;
        }
      }
      if (!exist) {
        alert('重复提交！');
        return;
      }
    }
    addLeads();
  };

  const sendMessage = () => {
    if (isEmpty(messageInfo?.validCode)) {
      alert('请输入图形验证码！');
      return;
    }
    messageInfo.phone = person.mobile;
    dataFetcher('/api/honda/v1/SMS/SendSMS', messageInfo)
      .then(({ data }) => {
        if (data.Status) {
          alert('已发送短信,请注意查收');
          person.smsValidId = data?.Data?.smsValidId;
        } else {
          getVerificationImage();
          const errorCode = parseInt(data?.ErrorResult?.ErrCode);
          let errorMessage;
          switch (errorCode) {
            case -1:
              errorMessage = '短信发送失败！';
              break;
            case 5:
              errorMessage = '图形验证码错误！';
              break;
            case 11:
              errorMessage = '手机号当天发送次数超限！';
              break;
            case 12:
              errorMessage = '手机号发送次数超限，请1小时候再试！';
              break;
            case 13:
              errorMessage = '手机号发送频率过快，请稍后再试！';
              break;
            case 21:
              errorMessage = '非常抱歉，发送验证码时出现异常，请稍后再试！';
              break;
            default:
              errorMessage = data?.ErrorResult?.ErrMsg;
              break;
          }
          return Promise.reject(new Error(errorMessage));
        }
      })
      .catch((error) => {
        alert(error?.message);
        console.error(error);
      });
  };
  const setPersonchild = (e) => {
    let newPerson = { ...person };
    newPerson.provinceId = e?.provinceId;
    newPerson.cityId = e?.cityId;
    newPerson.dealerId = e?.dealerId;
    newPerson.areaCode = e?.areaCode;
    setPerson(newPerson);
  };
  return (
    <section>
      <div className="testDrive_v3">
        <div className="testDrive_main">
          <div className="staticHead">
            <h3>预约试驾</h3>
          </div>
          <div
            className="staticContent"
            style={{
              background: data.isMobile ? '' : `url(${createFilePath('/service/staticMenu.gif')}) repeat-y right top`
            }}
          >
            <div className="staticDetail">
              <h1>预约试驾</h1>
              <div className="formBox">
                <p className="formTip">
                  <span className="red pr5">*</span>为必填项。您的信息我们将会完全保密，请您放心填写。
                </p>

                <div className="controlGroup mt15 bottomLine">
                  <p className="formTip formTip2">
                    <span className="red pr5">*</span>选择意向车型：
                  </p>
                  {data.isMobile ? (
                    <div className="controls carSelect mt5">
                      <div className="selectBox1">
                        <select
                          id="optMobileCarType"
                          onChange={(e) => {
                            mobileSelectCar(e);
                          }}
                          value={person.modelName ? person.modelName : 0}
                        >
                          <option value={0}>请选择车型</option>
                          {carList &&
                            carList.map((item, index) => {
                              return (
                                <option key={index} value={item.modelName}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div className="carType clearFix">
                      {carList &&
                        carList.length > 0 &&
                        carList.map((item, index) => {
                          return (
                            <div className="carTypeList" key={index}>
                              <p>{item.name}</p>
                              <div
                                className={index === num ? 'imgClip_active' : 'imgClip'}
                                onClick={() => {
                                  pcSelectCar(index);
                                  const dmpPointList = fields.car_dmp_point;
                                  if (dmpPointList?.length > 0) {
                                    sendDmp(dmpPointList, item.seriesId);
                                  }
                                }}
                              >
                                <img alt="广汽本田" src={item.img} />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>

                <div className="controlGroup mt15">
                  <p className="controlLeft">
                    <span className="red pr5">*</span>真实姓名：
                  </p>
                  <div className="controls">
                    <p className="iptTxt">
                      <input
                        name="TestDriveName"
                        type="text"
                        className="getFocus"
                        maxLength="10"
                        placeholder="您的真实姓名"
                        onInput={(e) => {
                          person.userName = e.target.value;
                          setPerson(person);
                        }}
                        onBlur={(e) => {
                          const dmpPointList = fields.name_dmp_point;
                          if (dmpPointList?.length > 0) {
                            sendDmp(dmpPointList, null);
                          }
                        }}
                      />
                    </p>
                  </div>
                </div>

                <div className="controlGroup mt15 bottomLine">
                  <p className="controlLeft">
                    <span className="red pr5">*</span>联系方式：
                  </p>
                  <div className="controls">
                    <p className="iptTxt">
                      <input
                        name="TestDriveMobilePhone"
                        type="text"
                        id="TestDriveMobilePhone"
                        className="getFocus"
                        maxLength="12"
                        placeholder="您的手机号"
                        onInput={(e) => {
                          if (checkMobile(e.target.value)) {
                            setMobileCheckShow(false);
                            person.mobile = e.target.value;
                            setPerson(person);
                          } else {
                            setMobileCheckShow(true);
                          }
                        }}
                        onBlur={(e) => {
                          const dmpPointList = fields.tel_dmp_point;
                          if (dmpPointList?.length > 0) {
                            sendDmp(dmpPointList, null);
                          }
                        }}
                      />
                    </p>
                    {mobileCheckShow && <span className="errorTip">手机号码格式不正确</span>}
                  </div>
                </div>
                <FindDealer
                  props={props}
                  istestdrive={true}
                  car={car}
                  selectVehicleType={selectVehicleType}
                  carnum={num}
                  mapShow={true}
                  isnomap={true}
                  setMapShow={setMapShow1}
                  setPersonparent={setPersonchild}
                />
                <div className="controlGroup mt15 " id="declaration_war">
                  <div className="controls w100 floatL">
                    <p className="iptCheckBox">
                      <label id="maininfo_0_lblAutoRegister">
                        <input
                          type="checkbox"
                          id="ckbRead"
                          checked={agree}
                          onChange={() => {
                            setAgree((agree) => !agree);
                            const dmpPointList = fields.btn1_dmp_point;
                            if (dmpPointList?.length > 0) {
                              sendDmp(dmpPointList, !agree);
                            }
                          }}
                        />
                        本人同意
                        <a
                          href="/policy/privacy-policy"
                          target="_blank"
                          data-item="drive-btn2"
                          data-url="/policy/privacy-policy"
                          onClick={(event) => {
                            event.stopPropagation();
                            const dmpPointList = fields.btn2_dmp_point;
                            if (dmpPointList?.length > 0) {
                              sendDmp(dmpPointList);
                            }
                          }}
                        >
                          《广汽本田隐私政策》
                        </a>
                        ；广汽本田将对您提交的个人信息保密，不对外公开。
                      </label>
                    </p>
                  </div>
                </div>

                <div className="controlGroup mt15">
                  <button
                    type="button"
                    onClick={() => {
                      personSubmit();
                      sendDmp(fields.btn3_dmp_point);
                    }}
                  >
                    确认提交
                  </button>
                </div>
              </div>
            </div>
            {!data.isMobile && (
              <div className="staticMenu">
                <h2>
                  购车指南<span>BUYERS GUIDE</span>
                </h2>
                <ul>
                  {data.staticMenu.length > 0 &&
                    data.staticMenu.map((item, index) => {
                      return (
                        <li key={index}>
                          <a
                            href={item.fields?.url.value}
                            dangerouslySetInnerHTML={{ __html: item.fields?.title?.value?.replaceHtml() }}
                            onClick={() => sendDmp(item.fields?.dmp, item.fields?.url.value)}
                          />
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>
        </div>
        {showVerificationCode && (
          <FullModalDetail style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="financial-calculator-v1-verification-code">
              <div className="verification-code-form">
                <img
                  alt="广汽本田"
                  className="verification-code-close"
                  src={createFilePath('/financial/calculator/close.png')}
                  onClick={() => close()}
                />
                <div className="verification-code-item">
                  <div className="verification-code-item-name">手机号：</div>
                  <div className="verification-code-item-right">{person?.mobile}</div>
                </div>
                <div className="verification-code-item">
                  <div className="verification-code-item-name">图形验证码：</div>
                  <img alt="广汽本田" className="verification-code-item-image" src={verificationImage} onClick={getVerificationImage} />
                  <div className="verification-code-item-right">
                    <input
                      className="verification-code-item-input"
                      ref={verificationCodeRef}
                      type="text"
                      placeholder="请输入图形验证码"
                      onChange={verificationCodeChange}
                    />
                  </div>
                </div>
                <div className="verification-code-item">
                  <div className="verification-code-item-name">短信验证码：</div>
                  <button className="verification-code-item-button" onClick={sendMessage}>
                    发送
                  </button>
                  <div className="verification-code-item-right">
                    <input
                      className="verification-code-item-input"
                      ref={messageCodeRef}
                      type="text"
                      placeholder="请输入短信验证码"
                      onChange={messageCodeChange}
                    />
                  </div>
                </div>
                <button className="verification-code-button" onClick={() => personSubmit()}>
                  确定
                </button>
              </div>
            </div>
          </FullModalDetail>
        )}
      </div>
    </section>
  );
}
