import { isIOS } from 'react-device-detect';
import { getPathnameByPosition } from './obj-utils';

export default class realibox {
  constructor(canvas, timestamp) {
    this.timestamp = timestamp;
    this.canvas = canvas;
    this.init();
  }

  init() {
    var timestampA = '';
    // 判断SDK是否定义
    if (!window?.realibox?.studio) {
      console.error('SDK initialization failed!');
      return;
    }
    if (this.timestamp) {
      timestampA = `-${this.timestamp}`;
    }
    console.log('storeName:' + `gw-${getPathnameByPosition()?.toLowerCase()}-resources${timestampA}`);
    // 初始化场景
    this.realibox = new window.realibox.studio(this.canvas, {
      mode: 'viewer',
      indexedDBConfig: {
        enabled: true,
        name: 'realibox',
        version: 1,
        storeName: `gw-${getPathnameByPosition()?.toLowerCase()}-resources-${this.timestamp}`
      },
      dracoDecoderConfig: {
        decoderFilePath: 'https://asset.realibox.com/open/js/draco/draco_decoder.js',
        wasmWrapperFilePath: 'https://asset.realibox.com/open/js/draco/draco_wasm_wrapper.js',
        wasmFilePath: 'https://asset.realibox.com/open/js/draco/draco_decoder.wasm'
      },
      preferWebGl2: !isIOS
    });

    // 资源开始加载
    this.realibox.events.on('edk:scene:resource:preload:start', (id) => {
      console.log('场景ID ' + id + ' 开始加载');
      if (this.loadCallback && typeof this.loadCallback === 'function') {
        this.loadCallback(false);
      }
    });

    // 资源加载进度
    this.realibox.events.on('edk:scene:resource:preload:progress', (id, progress) => {
      // console.log('场景ID ' + id + ' 加载进度 ' + progress.progress);
      if (this.progressCallback && typeof this.progressCallback === 'function') {
        this.progressCallback(progress.progress);
      }
    });

    // 资源加载完毕
    this.realibox.events.on('edk:scene:resource:preload:end', (id) => {
      console.log('场景ID ' + id + ' 加载完成');
      if (this.loadCallback && typeof this.loadCallback === 'function') {
        this.loadCallback(true);
      }
    });
  }

  loadScene(url, sceneId, loadCallback, progressCallback) {
    this.loadCallback = loadCallback;
    this.progressCallback = progressCallback;
    this.realibox.loadData(url, {
      id: sceneId,
      postRendering: true,
      dataProcessingHook: (data) => {
        data.resources
          .filter((r) => r.type === 'texture')
          .forEach((item) => {
            const bool = item.data.url.includes('?');
            item.data.url = item.data.url += `${bool ? '&' : '?'}x-oss-process=image/format,webp`;
            return item;
          });
        return data;
      }
    });
  }

  sendInteraction(sceneId, timelineId, event, delay) {
    console.log('sceneId', sceneId);
    console.log('timelineId', timelineId);
    if (this.realibox && sceneId && timelineId && event) {
      if (delay) {
        setTimeout(() => {
          this.realibox.events.call(event, sceneId, timelineId);
        }, delay);
      } else {
        this.realibox.events.call(event, sceneId, timelineId);
      }
    }
  }

  // 运行时间线
  runInteraction(sceneId, timelineId, delay) {
    this.sendInteraction(sceneId, timelineId, 'edk:scene:interaction:run', delay);
  }

  // 停止时间线
  stopInteraction(sceneId, timelineId, delay) {
    this.sendInteraction(sceneId, timelineId, 'edk:scene:interaction:stop', delay);
  }

  // 销毁
  destroy() {
    if (!this.realibox) {
      return;
    }
    this.realibox.removeData(data?.sceneId);
    this.realibox.destroy();
  }
}
