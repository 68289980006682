import React from 'react';
import './style.scss';
import { isEmpty, createFilePath } from '../../../../utils/obj-utils';

export default function index({ data: { fields }, className }) {
  return (
    <div className={`hero-support-item-card ${fields?.background?.value ? 'hero-support-item-card-background' : ''} ${className || ''}`}>
      <div className="card-number">{fields?.number?.value}</div>
      <div className="card-title" dangerouslySetInnerHTML={{ __html: fields?.title?.value?.replaceHtml() }} />
      <div className="card-description" dangerouslySetInnerHTML={{ __html: fields?.description?.value?.replaceHtml() }} />
      {!isEmpty(fields?.image?.value) && <img alt="广汽本田" className="card-image" src={createFilePath(fields?.image?.value)} />}
    </div>
  );
}
