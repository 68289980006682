import React from 'react';
import './style.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import { getPrefixField, convertStylesStringToObject, sendDmp, createFilePath } from '../../utils/obj-utils';
import temp from '../../temp/config';

export default function index(props) {
  const { fields, rootEl, windowHeight, isMobile, checkDetail, num, setNum, anchorId } = props;

  return (
    <div style={convertStylesStringToObject(fields[`${getPrefixField('container_style', isMobile)}`]?.value)}>
      <Controller>
        <Scene triggerHook={'onLeave'} duration={windowHeight} pin>
          {(progress, event) => {
            return (
              <div>
                <Timeline totalProgress={progress} paused>
                  <div className="EnlargeHeroScrollytelling" id={anchorId} ref={rootEl}>
                    <div className="DynamicEffect">
                      {fields[`${getPrefixField('effect_type', isMobile)}`].fields.v.value === 'hidden' && (
                        <Tween from={{ scale: 1, opacity: 1, zIndex: 10 }} to={{ scale: 2, opacity: 0, zIndex: -1 }} ease={'power4.in'} position={1}>
                          <div
                            className="imgmask_common"
                            dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}
                          />
                        </Tween>
                      )}
                      {fields[`${getPrefixField('effect_type', isMobile)}`].fields.v.value === 'halfOpen_upDown' && (
                        <div className="transverse">
                          <Tween to={{ width: '0%' }} duration={2} position={1}>
                            <div className="maskLeft">
                              <div
                                className="show"
                                dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}
                              />
                            </div>
                          </Tween>
                          <Tween to={{ width: '0%', left: '100%' }} duration={2} position={1}>
                            <div className="maskRight">
                              <div
                                className="show"
                                dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}
                              />
                            </div>
                          </Tween>
                        </div>
                      )}
                    </div>
                    <div>
                      <Swiper
                        className="tech_contentSwiper"
                        modules={[Pagination, Navigation]}
                        pagination={{
                          clickable: true,
                          bulletClass: 'tabContainer',
                          bulletActiveClass: 'tabContainer_active',
                          renderBullet: (index, className) => {
                            return !isMobile && fields[`${getPrefixField('tab_list', isMobile)}`].length > 1
                              ? `<span key=${index} class="${className}">${
                                  fields[`${getPrefixField('tab_list', isMobile)}`][index].fields.tab.value
                                }</span>`
                              : '<span />';
                          }
                        }}
                        navigation={false}
                        onSlideChange={(swiper) => {
                          setNum(swiper.activeIndex);
                          sendDmp(fields[`${getPrefixField('tab_list', isMobile)}`][swiper.activeIndex].fields.tab_dmp);
                          isMobile && document.querySelector('.tech_tabSwiper').swiper.slideTo(swiper.activeIndex);
                        }}
                      >
                        {fields[`${getPrefixField('tab_list', isMobile)}`].length &&
                          fields[`${getPrefixField('tab_list', isMobile)}`].map((ele, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <div className="sildeContainer">
                                  {ele.fields.back_source_type?.fields.v.value === 'video' ? (
                                    <video
                                      src={`${createFilePath(ele.fields.back_source?.value)}`}
                                      autoPlay="autoplay"
                                      muted={true}
                                      loop={true}
                                      style={convertStylesStringToObject(ele.fields.back_source_style?.value)}
                                    />
                                  ) : (
                                    <>
                                      <img
                                        alt="广汽本田"
                                        src={`${createFilePath(ele.fields.back_source?.value)}`}
                                        className={ele.fields.replace_img.value ? 'replaceImg1' : ''}
                                        style={convertStylesStringToObject(ele.fields.back_source_style?.value)}
                                      />
                                      {ele.fields.replace_img.value && (
                                        <img
                                          alt="广汽本田"
                                          className="replaceImg2"
                                          src={`${createFilePath(ele.fields.replace_img?.value)}`}
                                          style={convertStylesStringToObject(ele.fields.replace_img_style?.value)}
                                        />
                                      )}
                                    </>
                                  )}
                                  <div className={'infoContainer'}>
                                    <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={2} stagger={0.2}>
                                      <div>
                                        {ele.fields.immd_btn2?.value && (
                                          <div
                                            style={{ display: 'inline' }}
                                            dangerouslySetInnerHTML={{ __html: ele.fields.immd_btn2.value?.replaceHtml() }}
                                          />
                                        )}
                                        {ele.fields.title_img?.value && (
                                          <div className="titleImg">
                                            <img
                                              alt="广汽本田"
                                              src={`${createFilePath(ele.fields.title_img?.value)}`}
                                              style={convertStylesStringToObject(ele.fields.title_img_style?.value)}
                                            />
                                          </div>
                                        )}
                                        {ele.fields.title_text?.value && (
                                          <div dangerouslySetInnerHTML={{ __html: ele.fields.title_text.value?.replaceHtml() }} />
                                        )}
                                        {ele.fields.context?.value && (
                                          <div dangerouslySetInnerHTML={{ __html: ele.fields.context.value?.replaceHtml() }} />
                                        )}
                                        {ele.fields.detail_btn?.value && (
                                          <div
                                            onClick={() => {
                                              sendDmp(ele.fields.detail_btn_dmp);
                                              checkDetail(true);
                                            }}
                                            style={{ display: 'inline-block' }}
                                            dangerouslySetInnerHTML={{ __html: ele.fields.detail_btn.value?.replaceHtml() }}
                                          />
                                        )}
                                        {ele.fields.immd_btn?.value && (
                                          <div
                                            className="immdBtn"
                                            onClick={() => {
                                              sendDmp(ele.fields.immd_btn_dmp, ele.fields.immd_btn_href?.value);
                                              window.open(ele.fields.immd_btn_href?.value);
                                            }}
                                            style={{ display: 'inline-block' }}
                                            dangerouslySetInnerHTML={{ __html: ele.fields.immd_btn.value?.replaceHtml() }}
                                          />
                                        )}
                                      </div>
                                    </Tween>
                                  </div>
                                  {ele.fields.mask_show.value && (
                                    <div className="maskContianer" dangerouslySetInnerHTML={{ __html: ele.fields.mask_html.value?.replaceHtml() }} />
                                  )}
                                </div>
                              </SwiperSlide>
                            );
                          })}
                      </Swiper>
                    </div>
                    {isMobile && fields[`${getPrefixField('tab_list', isMobile)}`].length > 1 && (
                      <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={3} stagger={0.2}>
                        <div>
                          <Swiper
                            className="tech_tabSwiper"
                            centeredSlides={
                              fields[`${getPrefixField('tab_list', isMobile)}`].length > 3 &&
                              num > 0 &&
                              num < fields[`${getPrefixField('tab_list', isMobile)}`].length - 1
                            }
                            slidesPerView={
                              fields[`${getPrefixField('tab_list', isMobile)}`].length >= 3
                                ? 3
                                : fields[`${getPrefixField('tab_list', isMobile)}`].length
                            }
                          >
                            {fields[`${getPrefixField('tab_list', isMobile)}`].length &&
                              fields[`${getPrefixField('tab_list', isMobile)}`].map((ele, index) => {
                                return (
                                  <SwiperSlide
                                    key={index}
                                    onClick={() => {
                                      setNum(index);
                                      document.querySelector('.tech_contentSwiper').swiper.slideTo(index);
                                    }}
                                  >
                                    <div
                                      className={`${index === num ? 'tabContainer_active' : 'tabContainer'}`}
                                      dangerouslySetInnerHTML={{ __html: ele.fields.tab.value?.replaceHtml() }}
                                    />
                                  </SwiperSlide>
                                );
                              })}
                          </Swiper>
                        </div>
                      </Tween>
                    )}
                  </div>
                </Timeline>
              </div>
            );
          }}
        </Scene>
      </Controller>
    </div>
  );
}
