import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { convertStylesStringToObject, getPrefixField, sendDmp, isJSON } from '../../utils/obj-utils';
import ProgressBar from '../../elements/ProgressBar';
import './style.scss';
import { getQueryVariable } from '../../utils/site-utils';

export default function index({ fields, eventRef }) {
  const containerRef = useRef(null);
  const iframeRef = useRef(null);
  const tabList = fields?.tabs;
  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields.sectionDmp);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = 'show360';
  };

  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      tabList.map((item, index) => {
        let tabArr = item.fields?.tab_name?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    let tab = getQueryVariable('tab');
    let defaultTabInd = queryTabDefault(fields.default_tab_name?.value?.toLowerCase());
    if (tab) {
      let tabInd = queryTabDefault(tab.toLowerCase());
      setTabIndex(tabInd < 0 ? (defaultTabInd < 0 ? tabIndex : defaultTabInd) : tabInd);
    } else {
      setTabIndex(defaultTabInd < 0 ? tabIndex : defaultTabInd);
    }
  }, []);

  useEffect(() => {
    const onload = () => {
      setTimeout(() => {
        const iframe = document.createElement('iframe');
        iframe.id = 'exhibitionHall_iframe';
        iframe.className = 'exhibitionHall_iframe';
        iframeRef.current = iframe;
        document.querySelector('.exhibitionHall_iframe_container').prepend(iframe);
        setInit(true);
      }, 0);
    };
    onload();
    window.addEventListener('load', onload);
    return () => {
      window.removeEventListener('load', onload);
    };
  }, []);

  useEffect(() => {
    if (init) {
      document.querySelector('#exhibitionHall_iframe').src = `${tabList?.length > 0 ? tabList[tabIndex]?.fields?.href.value : fields?.link?.value}${
        window.location.search
      }`;
      document.querySelector('#exhibitionHall_iframe').onload = () => {
        setLoading(false);
      };
    }
  }, [tabIndex, init]);

  const tabClick = (item, index) => {
    setLoading(true);
    setTabIndex(index);
    sendDmp(item?.fields?.tab_dmp);
    iframeRef.current.src = `${item?.fields?.href?.value}${window.location.search}`;
  };

  return (
    <div className="exhibitionHall_iframe_container" ref={containerRef} id="show360">
      <div className="tab_list">
        {tabList &&
          tabList.length > 1 &&
          tabList.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {index !== 0 && <div className="split_line" dangerouslySetInnerHTML={{ __html: fields.split_line?.value?.replaceHtml() }} />}
                <div className={`tab_item ${index === tabIndex ? 'tab_disabled' : ''}`} onClick={() => tabClick(item, index)}>
                  <div
                    className={index === tabIndex ? 'tabContainer_active' : 'tabContainer'}
                    dangerouslySetInnerHTML={{ __html: item.fields?.tab?.value?.replaceHtml() }}
                  />
                </div>
              </React.Fragment>
            );
          })}
      </div>
      {loading && (
        <div className="loadingMask" style={convertStylesStringToObject(fields[`${getPrefixField('loading_container_style')}`]?.value)}>
          <ProgressBar />
        </div>
      )}
    </div>
  );
}
