import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import EnlargeHeroScrollytelling from '../../elements/EnlargeHeroScrollytelling_v2';
import Details from '../../elements/InnerPageDetail';
import { getPrefixField, sendDmp, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const [num, setNum] = useState(0);
  const [windowHeight, setWindowHeight] = useState(400);
  const isMobile = isMobileFun();
  const [flag, setFlag] = useState(false);
  const rootEl = useRef(null);
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(props.fields.window_dmp);
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
  };

  return (
    <div>
      <EnlargeHeroScrollytelling
        {...props}
        num={num}
        setNum={setNum}
        windowHeight={windowHeight}
        isMobile={isMobile}
        rootEl={rootEl}
        checkDetail={setFlag}
        anchorId={props?.params?.pagename}
      />
      {flag && <Details routePath={props.fields[`${getPrefixField('tab_list')}`][num].fields.button_herf.value} close={() => setFlag(false)} />}
    </div>
  );
}
