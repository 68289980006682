import React, { useState, useEffect } from 'react';
import './style.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getPrefixField, isScrollToBottom, convertStylesStringToObject, createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields, close, scrollElement }) {
  const [hideNext, setHideNext] = useState(false);
  const isMobile = isMobileFun();
  const icon = createFilePath(fields[getPrefixField('icon', isMobile)]?.value);
  const iconStyle = convertStylesStringToObject(fields[getPrefixField('iconStyle', isMobile)]?.value);
  const showNext = fields[getPrefixField('showNext', isMobile)].value;
  const nextTitle = fields[getPrefixField('nextTitle', isMobile)].value || '向下滑动';
  const nextIcon = createFilePath(fields[getPrefixField('nextIcon', isMobile)]?.value);
  const nextIconStyle = convertStylesStringToObject(fields[getPrefixField('nextIconStyle', isMobile)]?.value);
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const dmpList = fields?.dmp;
  const dmpAction = dmpList?.length > 0 ? dmpList[0]?.fields?.func?.value : '';
  const dmpPoint = dmpList?.length > 0 ? dmpList[0].name : '';
  const [data, _] = useState({
    icon,
    iconStyle,
    dmpAction,
    dmpPoint,
    showNext,
    nextTitle,
    nextIcon,
    nextIconStyle,
    style,
    isMobile
  });

  useEffect(() => {
    const element = scrollElement || window;
    const scroll = () => {
      setHideNext(isScrollToBottom(10, element));
    };
    element.addEventListener('scroll', scroll);
    return () => {
      element.removeEventListener('scroll', scroll);
    };
  }, []);
  return (
    <section>
      {data ? (
        <div className="inner-close" style={data?.style}>
          <img
            className="inner-close-icon"
            data-action={data?.dmpAction}
            data-item={data?.dmpPoint}
            src={data?.icon}
            style={data?.iconStyle}
            alt="关闭"
            onClick={() => {
              close && close();
            }}
          />
          {data?.showNext && !hideNext && (
            <div className="inner-close-next">
              <p className="inner-close-next-title" dangerouslySetInnerHTML={{ __html: data?.nextTitle?.replaceHtml() }} />
              <img className="inner-close-next-icon" src={data?.nextIcon} style={data?.nextIconStyle} alt="icon" />
            </div>
          )}
        </div>
      ) : null}
    </section>
  );
}
