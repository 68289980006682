import React, { useState, useImperativeHandle, useEffect, useRef } from 'react';
import './style.scss';
import TextScrollytelling_v2 from '../../elements/TextScrollytelling_v2';
import Detailss from '../../elements/InnerPageDetail';
import { sendDmpAction, sendDmp, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import temp from '../../temp/config';
import { getQueryVariable } from '../../utils/site-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const tabs = fields.tabs;
  const [flag, setFlag] = useState(false);
  const [tabNum, setTabNum] = useState(0);
  // const deployUrlS3 = temp.app.deployUrlS3;
  // const video_value = `${deployUrlS3}${tabs[tabNum].fields[getPrefixField('dialog_video', isMobile)]?.value}`;
  const video_value = createFilePath(tabs[tabNum].fields[getPrefixField('dialog_video', isMobile)]?.value);
  const containerRef = useRef(null);

  const openVideo = () => {
    setFlag(true);
    sendDmp(tabs[tabNum].fields?.video_btn_dmp, video_value);
  };

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    window.Dmp.sectionAction(fields.id.value);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : '';
  };

  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      tabs.map((item, index) => {
        let tabArr = item.fields?.tab_name?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(fields.default_tab_name?.value?.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabNum(tabInd < 0 ? (defaultTabInd < 0 ? tabNum : defaultTabInd) : tabInd);
      } else {
        setTabNum(defaultTabInd < 0 ? tabNum : defaultTabInd);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const windowHeight = document.documentElement.clientHeight;
    const tabList = containerRef?.current?.getElementsByClassName('tab_list')[0];
    const scroll = () => {
      const rect = containerRef?.current?.getBoundingClientRect();
      if (rect.top < 0 && rect.bottom > windowHeight) {
        tabList.style.position = 'fixed';
      } else {
        tabList.style.position = 'absolute';
      }
    };

    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  const screenShow = () => {
    let screen = document.querySelector('.kv_v2');
    if (screen) {
      screen.classList.remove('kv-v2-show');
      void screen.offsetWidth;
      screen.classList.add('kv-v2-show');
    }
  };

  return (
    <div className="kv_v2" ref={containerRef}>
      <div className="tab_list">
        {tabs &&
          tabs.length > 1 &&
          tabs.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {index !== 0 && <div className="split_line" dangerouslySetInnerHTML={{ __html: fields.split_line?.value?.replaceHtml() }} />}
                <div
                  className={`tab_item ${index === tabNum ? 'tab_disabled' : ''}`}
                  onClick={() => {
                    setTabNum(index);
                    screenShow();
                    sendDmp(item?.fields?.tab_dmp);
                  }}
                >
                  <div
                    className={index === tabNum ? 'tabContainer_active' : 'tabContainer'}
                    dangerouslySetInnerHTML={{ __html: item.fields?.tab?.value?.replaceHtml() }}
                  />
                </div>
              </React.Fragment>
            );
          })}
      </div>
      <TextScrollytelling_v2
        {...tabs[tabNum].fields}
        flag={flag}
        open={() => openVideo()}
        cursor={fields.cursor}
        id={fields.id}
        disableAnimate={fields.disableAnimate}
      />
      {flag && (
        <Detailss
          routePath={props.fields.tabs[tabNum].fields.detail_btn_href.value}
          {...fields}
          close={() => setFlag(false)}
          isModal={document.documentElement.clientWidth <= 1024}
        />
      )}
    </div>
  );
}
