import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, pcTorem, mbTorem, getQueryString, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import { swiperAnimateCache, swiperAnimate, clearSwiperAnimate } from '../../utils/swiper.animate1.0.3.min';
import FullVideo from '../../elements/FullVideo';
import CountUp from 'react-countup';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const [windowHeight, setWindowHeight] = useState(400);
  const [swipertypeflag, setSwipertypeflag] = useState(true);
  const swiperRef = useRef(null);
  const [showcountnum, setShowcountnum] = useState(false);
  const [flag, setFlag] = useState(false);
  const [swiperflag, setSwiperflag] = useState(false);
  const [sport2detail, setSport2detail] = useState(false);
  const isMobile = isMobileFun();
  // const hosturl = temp.app.deployUrlS3;
  const [showFooter, setShowFooter] = useState(true);
  // if (isMobile) {
  //   var accordhosturl = hosturl + '/accord/v2/mobile';
  // } else {
  //   var accordhosturl = hosturl + '/accord/v2/pc';
  // }

  const id = props?.params?.pagename;
  const videodata = {
    detail_video: createFilePath('/accord/v2/pc/sport/video.mp4'),
    close_icon_dmp: {
      type: 'click',
      point: 'price_power-video-close',
      value1: ''
    }
  };
  const data = {
    id
  };
  const openVideo = () => {
    setFlag(true);
    window.Dmp.clickAction('price_power-video-open');
  };
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
  };
  useEffect(() => {
    if (
      window.location.href.indexOf('accord-show') !== -1 ||
      getQueryString('source') === 'app' ||
      window.location.href.indexOf('app/vehicles/accord') !== -1
    ) {
      setShowFooter(false);
    }
  }, []);
  const windowhidden = () => {
    document.documentElement.style.overflow = 'hidden';
  };
  const closewindowhidden = () => {
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.overflowX = 'hidden';
  };
  return (
    <>
      <div className={!showFooter ? 'modules-grid sport noheader' : 'modules-grid sport'}>
        <div className="triggerstart" id="sport"></div>
        {!isMobile ? (
          <>
            <Controller>
              <Scene triggerHook={'onEnter'} duration={windowHeight} pin={false} reverse={true} triggerElement={'#sport'}>
                {(progress, event) => {
                  if (progress > 0.8 && progress <= 1) {
                    if (swiperRef?.current?.swiper) {
                      swiperAnimate(swiperRef.current.swiper);
                    }
                  }
                  return (
                    <div id={data?.id}>
                      <Swiper
                        ref={swiperRef}
                        slidesPerView={1}
                        className="modules-swiper"
                        spaceBetween={0}
                        initialSlide={0}
                        observer={true}
                        resizeObserver={true}
                        updateOnWindowResize={true}
                        modules={[Navigation]}
                        navigation={{
                          prevEl: '.sport .prev',
                          nextEl: '.sport .next',
                          disabledClass: 'disabled'
                        }}
                        onInit={(e) => {
                          clearSwiperAnimate(e);
                          swiperAnimateCache(e);
                        }}
                        onTransitionStart={(e) => {
                          setShowcountnum(false);
                        }}
                        onSlideChangeTransitionEnd={(e) => {
                          setTimeout(
                            function () {
                              swiperAnimate(e);
                              setShowcountnum(true);
                            },
                            swipertypeflag ? 400 : 0
                          );
                        }}
                      >
                        <SwiperSlide className={'griditem griditem0'}>
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport0.jpg') : createFilePath('/accord/v2/pc/sport/sport0.jpg')}
                          />
                          <img
                            alt="广汽本田"
                            className="car ani"
                            swiper-animate-effect="animate__fadeInBottomRight45deg"
                            swiper-animate-duration="1s"
                            swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport0_car.png')
                                : createFilePath('/accord/v2/pc/sport/sport0_car.png')
                            }
                          />
                          <div
                            className="t-img ani"
                            swiper-animate-effect="animate__fadeInDownBig"
                            swiper-animate-duration="1.5s"
                            swiper-animate-delay="0s"
                          >
                            <img
                              alt="广汽本田"
                              src={
                                isMobile ? createFilePath('/accord/v2/mobile/sport/sport0_t.png') : createFilePath('/accord/v2/pc/sport/sport0_t.png')
                              }
                            />
                          </div>
                          <div
                            className="t ani"
                            swiper-animate-effect="animate__fadeInDownBig"
                            swiper-animate-duration="1.5s"
                            swiper-animate-delay="0s"
                          >
                            <span>智动</span>全新三电科技
                          </div>
                          <div
                            className="sub ani"
                            swiper-animate-effect="animate__fadeInUpBig"
                            swiper-animate-duration="1.5s"
                            swiper-animate-delay="0s"
                          >
                            <div className="en">A THRILLING WINDY JOURNEY</div>
                            <div className="cn">一趟肾上腺素飙升的竞风之旅</div>
                          </div>
                          <div
                            className="list ani"
                            swiper-animate-effect="animate__fadeInUpBig"
                            swiper-animate-duration="1.5s"
                            swiper-animate-delay="0s"
                          >
                            <div className="li">Honda能控科技大成</div>
                            <div className="p">
                              集合Honda数十年技术精研的第四代i-MMD双电机混合动力系统，配以电机驱动科技，提供Honda独有行驶品质的全新三电科技。
                            </div>
                          </div>
                          {/* <div
                            className="videomin1 ani"
                            swiper-animate-effect="animate__fadeInDownBig"
                            swiper-animate-duration="1.5s"
                            swiper-animate-delay="0s"
                            onClick={() => {
                              openVideo();
                            }}
                          >
                            <img alt="广汽本田" src={createFilePath('/accord/v2/pc/sport/video.gif')} className="video" />
                          </div> */}
                        </SwiperSlide>
                        <SwiperSlide className={'griditem griditem1'}>
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport1.jpg') : createFilePath('/accord/v2/pc/sport/sport1.jpg')}
                          />
                          <img
                            alt="广汽本田"
                            className="car "
                            // swiper-animate-effect="animate__fadeInTopRight30deg"
                            // swiper-animate-duration="1s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport1_car.png')
                                : createFilePath('/accord/v2/pc/sport/sport1_car.png')
                            }
                          />
                          <img
                            alt="广汽本田"
                            className="icon "
                            // swiper-animate-effect="animate__fadeIndelay"
                            // swiper-animate-duration="1.8s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport1_car_icon.png')
                                : createFilePath('/accord/v2/pc/sport/sport1_car_icon.png')
                            }
                          />
                          <div
                            className="t-img "
                            // swiper-animate-effect="animate__fadeInDownBig"
                            // swiper-animate-duration="1.5s"
                            // swiper-animate-delay="0s"
                          >
                            <img
                              alt="广汽本田"
                              src={
                                isMobile ? createFilePath('/accord/v2/mobile/sport/sport1_t.png') : createFilePath('/accord/v2/pc/sport/sport1_t.png')
                              }
                            />
                          </div>
                          <div
                            className="sub ani"
                            swiper-animate-effect="animate__fadeInDownBig"
                            swiper-animate-duration=".5s"
                            swiper-animate-delay="0s"
                          >
                            <div className="en">ELECTRIC DRIVE SUMMONS EVERY SECOND OF PLEASURE</div>
                            <div className="cn">电驱 召唤每一寸兴奋的快感</div>
                            <div className="li">第四代i-MMD双电机混合动力系统</div>
                            <div
                              className="btn"
                              data-html-item="price_power-plugin-iMMD"
                              data-url="https://i-mmd-lab.ghac.cn/"
                              onClick={() => {
                                window.open('https://i-mmd-lab.ghac.cn/');
                              }}
                            >
                              探索先进技术
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className={'griditem griditem2'}>
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport2.jpg') : createFilePath('/accord/v2/pc/sport/sport2.jpg')}
                          />
                          <img
                            alt="广汽本田"
                            className="car "
                            // swiper-animate-effect="animate__fadeIn"
                            // swiper-animate-duration="0.5s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport2_car.png')
                                : createFilePath('/accord/v2/pc/sport/sport2_car.png')
                            }
                          />
                          <img
                            alt="广汽本田"
                            className="icon1 "
                            // swiper-animate-effect="animate__flash"
                            // swiper-animate-duration="0.7s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport2_icon1.png')
                                : createFilePath('/accord/v2/pc/sport/sport2_icon1.png')
                            }
                          />
                          <img
                            alt="广汽本田"
                            className="icon2 "
                            // swiper-animate-effect="animate__flash"
                            // swiper-animate-duration="0.7s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport2_icon2.png')
                                : createFilePath('/accord/v2/pc/sport/sport2_icon2.png')
                            }
                          />
                          <div
                            className="sub ani"
                            swiper-animate-effect="animate__fadeInDownBig"
                            swiper-animate-duration=".5s"
                            swiper-animate-delay="0s"
                          >
                            <div className="en">EFFORTLESS POWER PERFORMANCE SMOOTH THE DRIVING EXPERIENCE</div>
                            <div className="cn">静享 抚平每一处毛孔的躁动</div>
                          </div>
                          <div
                            className="list list1 ani"
                            swiper-animate-effect="animate__bounceInRight"
                            swiper-animate-duration="1s"
                            swiper-animate-delay="0s"
                          >
                            <div className="li">2.0L直喷式阿特金森发动机</div>
                            <div className="p">
                              采用高燃压直喷与多级喷射控制发动机，空燃比范围扩大，并且通过提高筒内流动来实现极速燃烧，使得热效率高达41%，提高全档位的行驶性能与静音性，同时带来出色的燃油经济性，重释高效静谧驾趣的典范。
                            </div>
                          </div>
                          <div
                            className="list list2 ani"
                            swiper-animate-effect="animate__bounceInLeft"
                            swiper-animate-duration="1s"
                            swiper-animate-delay="0s"
                          >
                            <div className="li">全新双电机内置电气式CVT</div>
                            <div className="p">
                              提升电机最大扭矩和最高转速，带来135kW高功率输出和335N·m的最大扭矩，驾驭随心所欲。通过优化减震结构降低电机特有的高频音及怠速发电中的驱动噪音，提升起步加速和静音性能，进一步提升顺畅、高级的驾驶质感。
                            </div>
                            <div className="li">NEDC工况纯电续驶里程-106km</div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className={'griditem griditem3'}>
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport3.jpg') : createFilePath('/accord/v2/pc/sport/sport3.jpg')}
                          />
                          <div
                            className="t-img "
                            // swiper-animate-effect="animate__bounceInDown"
                            // swiper-animate-duration="1s"
                            // swiper-animate-delay="0s"
                          >
                            <img
                              alt="广汽本田"
                              src={
                                isMobile ? createFilePath('/accord/v2/mobile/sport/sport1_t.png') : createFilePath('/accord/v2/pc/sport/sport1_t.png')
                              }
                            />
                          </div>
                          <div
                            className="sub ani"
                            swiper-animate-effect="animate__bounceInDown"
                            swiper-animate-duration="0.5s"
                            swiper-animate-delay="0s"
                          >
                            <div className="en">UNLEASH YOURSELF AT ANY MOMENT WITH INSTANT ELECTRIC POWER </div>
                            <div className="cn">随时来电 让你任性释放</div>
                          </div>
                          <div
                            className="list ani"
                            swiper-animate-effect="animate__bounceInDown"
                            swiper-animate-duration=".5s"
                            swiper-animate-delay="0s"
                          >
                            <div className="li">全路况EV畅行</div>
                            <div className="li">
                              EV Switch
                              <img
                                alt="广汽本田"
                                className="icon"
                                src={
                                  isMobile
                                    ? createFilePath('/accord/v2/mobile/sport/sport3_icon1.png')
                                    : createFilePath('/accord/v2/pc/sport/sport3_icon1.png')
                                }
                              />
                            </div>
                            <div className="li">3色充电显示灯</div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className={'griditem griditem4'}>
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport4.jpg') : createFilePath('/accord/v2/pc/sport/sport4.jpg')}
                          />
                          <img
                            alt="广汽本田"
                            className="car "
                            // swiper-animate-effect="animate__fadeInTopRight"
                            // swiper-animate-duration="0.5s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport4_car.png')
                                : createFilePath('/accord/v2/pc/sport/sport4_car.png')
                            }
                          />
                          <img
                            alt="广汽本田"
                            className="icon1 "
                            // swiper-animate-effect="animate__zoomInbig"
                            // swiper-animate-duration="0.9s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport4_icon1.png')
                                : createFilePath('/accord/v2/pc/sport/sport4_icon1.png')
                            }
                          />
                          <div
                            className="icon2 "
                            // swiper-animate-effect="animate__zoomInbig"
                            // swiper-animate-duration="0.9s"
                            // swiper-animate-delay="0s"
                          >
                            <img
                              alt="广汽本田"
                              src={
                                isMobile
                                  ? createFilePath('/accord/v2/mobile/sport/sport4_icon2.png')
                                  : createFilePath('/accord/v2/pc/sport/sport4_icon2.png')
                              }
                            />
                            <p>后排乘客头部、脚部空间扩大</p>
                          </div>
                          <img
                            alt="广汽本田"
                            className="icon3 "
                            // swiper-animate-effect="animate__fadeInLeftBig"
                            // swiper-animate-duration="0.9s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport4_icon3.png')
                                : createFilePath('/accord/v2/pc/sport/sport4_icon3.png')
                            }
                          />
                          <div
                            className="sub ani"
                            swiper-animate-effect="animate__fadeInDownBig"
                            swiper-animate-duration="0.5s"
                            swiper-animate-delay="0s"
                          >
                            <div className="en">UNLEASH LIMITLESS POWER </div>
                            <div className="cn">硬核实力 持续输出</div>
                          </div>
                          <div
                            className="bottom ani"
                            swiper-animate-effect="animate__fadeInUpBig"
                            swiper-animate-duration="0.6s"
                            swiper-animate-delay="0s"
                          >
                            <div className="l">
                              <div className="info">
                                <div className="li">
                                  <div className="n">体积减少</div>
                                  <div className="val">
                                    <b>{showcountnum ? <CountUp duration={3} useGrouping={false} start={0} end={40} /> : '40'}%</b>
                                  </div>
                                  <div className="cirl">
                                    <img
                                      alt="广汽本田"
                                      src={
                                        isMobile
                                          ? createFilePath('/accord/v2/mobile/sport/cirl4.png')
                                          : createFilePath('/accord/v2/pc/sport/cirl4.png')
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="li">
                                  <div className="n">输出提高</div>
                                  <div className="val">
                                    <b>{showcountnum ? <CountUp duration={3} useGrouping={false} start={0} end={15} /> : '15'}%</b>
                                  </div>
                                  <div className="cirl">
                                    <img
                                      alt="广汽本田"
                                      src={
                                        isMobile
                                          ? createFilePath('/accord/v2/mobile/sport/cirl5.png')
                                          : createFilePath('/accord/v2/pc/sport/cirl5.png')
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="li">
                                  <div className="n">输出电流增加</div>
                                  <div className="val">
                                    <b>{showcountnum ? <CountUp duration={3} useGrouping={false} start={0} end={7} /> : '7'}</b>%
                                  </div>
                                  <div className="cirl">
                                    <img
                                      alt="广汽本田"
                                      src={
                                        isMobile
                                          ? createFilePath('/accord/v2/mobile/sport/cirl6.png')
                                          : createFilePath('/accord/v2/pc/sport/cirl6.png')
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="list">
                                <div className="li">高输出密度动力控制单元PCU</div>
                                <div className="p">内置式动力单元的体积实现了40%的小型化，输出却提高15%，实现了高输出高密度化。</div>
                              </div>
                            </div>
                            <div className="r">
                              <div className="info">
                                <div className="li">
                                  <div className="n">体积减少</div>
                                  <div className="val">
                                    <b>{showcountnum ? <CountUp duration={3} useGrouping={false} start={0} end={57} /> : '57'}%</b>
                                  </div>
                                  <div className="cirl">
                                    <img
                                      alt="广汽本田"
                                      src={
                                        isMobile
                                          ? createFilePath('/accord/v2/mobile/sport/cirl1.png')
                                          : createFilePath('/accord/v2/pc/sport/cirl1.png')
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="li">
                                  <div className="n">重量减轻</div>
                                  <div className="val">
                                    <b>{showcountnum ? <CountUp duration={3} useGrouping={false} start={0} end={51} /> : '51'}%</b>
                                  </div>
                                  <div className="cirl">
                                    <img
                                      alt="广汽本田"
                                      src={
                                        isMobile
                                          ? createFilePath('/accord/v2/mobile/sport/cirl2.png')
                                          : createFilePath('/accord/v2/pc/sport/cirl2.png')
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="li">
                                  {/* <div className="n">输出电流增加</div>
                                  <div className="val">
                                    <b>{showcountnum ? <CountUp duration={5} useGrouping={false} start={0} end={7} /> : '7'}%</b>
                                  </div>
                                  <div className="cirl">
                                    <img alt="广汽本田" src={(isMobile ? createFilePath('/accord/v2/mobile/sport/cirl3.png') : createFilePath('/accord/v2/pc/sport/cirl3.png'))} />
                                  </div> */}
                                </div>
                              </div>
                              <div className="list">
                                <div className="li">轻量型智能动力单元IPU</div>
                                <div className="p">重量与体积减少50%以上，实现小型轻量化，带来与燃油车相同的低车身和舒适的乘坐空间。</div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className={'griditem griditem5'}>
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport5.jpg') : createFilePath('/accord/v2/pc/sport/sport5.jpg')}
                          />
                          <img
                            alt="广汽本田"
                            className="car "
                            // swiper-animate-effect="animate__fadeInRightBig"
                            // swiper-animate-duration="0.8s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport5_car.png')
                                : createFilePath('/accord/v2/pc/sport/sport5_car.png')
                            }
                          />
                          <div
                            className="sub ani"
                            swiper-animate-effect="animate__fadeInLeft"
                            swiper-animate-duration=".5s"
                            swiper-animate-delay="0s"
                          >
                            <div className="en">SILENT RIDE, CONQUER THE WORLD</div>
                            <div className="cn">让世界 沉寂在你的脚下</div>
                          </div>
                          <div
                            className="list ani"
                            swiper-animate-effect="animate__fadeInLeft"
                            swiper-animate-duration=".5s"
                            swiper-animate-delay="0s"
                          >
                            <div className="li">主动降噪系统</div>
                            <div className="p">采用全新3轴振动传感器，可高精度检测出轮胎声噪，用音响发出可以抵消噪音的声音，大幅提升车内静音性。</div>
                          </div>
                          <div
                            className="wave-box ani"
                            swiper-animate-effect="animate__fadeIndelay"
                            swiper-animate-duration=".2s"
                            swiper-animate-delay="0s"
                          >
                            <div className="wave wavea wavea1">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea2">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea3">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea4">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea5">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea6">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea7">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea8">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea9">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea10">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea11">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea12">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea13">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea14">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave wavea wavea15">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>

                            <div className="wave waveb waveb1">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave waveb waveb2">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave waveb waveb3">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                            <div className="wave waveb waveb4">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className={'griditem griditem6'}>
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport6.jpg') : createFilePath('/accord/v2/pc/sport/sport6.jpg')}
                          />
                          <img
                            alt="广汽本田"
                            className="car "
                            // swiper-animate-effect="animate__fadeInTopRight30deg"
                            // swiper-animate-duration="0.6s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport6_car.png')
                                : createFilePath('/accord/v2/pc/sport/sport6_car.png')
                            }
                          />
                          <img
                            alt="广汽本田"
                            className="icon1 "
                            // swiper-animate-effect="animate__flashdelay"
                            // swiper-animate-duration="1.5s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport6_icon1.png')
                                : createFilePath('/accord/v2/pc/sport/sport6_icon1.png')
                            }
                          />
                          <img
                            alt="广汽本田"
                            className="icon2 "
                            // swiper-animate-effect="animate__flashdelay"
                            // swiper-animate-duration="1.5s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport6_icon2.png')
                                : createFilePath('/accord/v2/pc/sport/sport6_icon2.png')
                            }
                          />
                          <div
                            className="sub ani"
                            swiper-animate-effect="animate__fadeInDownBig"
                            swiper-animate-duration=".5s"
                            swiper-animate-delay="0s"
                          >
                            <div className="en">DASHING FORWARD RECKLESSLY, SMOOTHING OUT THE CRAZY ROAD</div>
                            <div className="cn">肆意前行 抹平路面的疯狂</div>
                          </div>
                          <div
                            className="list list1 ani"
                            swiper-animate-effect="animate__fadeInUpBig"
                            swiper-animate-duration=".8s"
                            swiper-animate-delay="0s"
                          >
                            <div className="li">运动管理系统</div>
                            <div className="p">车辆转弯过程中，动力系统与刹车在方向盘的配合操作下实现共同控制的技术。有效控制车辆左右晃动。</div>
                          </div>
                          <div
                            className="list list2 ani"
                            swiper-animate-effect="animate__fadeInUpBig"
                            swiper-animate-duration=".8s"
                            swiper-animate-delay="0s"
                          >
                            <div className="li">自适应减震系统</div>
                            <div className="p">通过传感器提升车辆状态的感知性能，实现更加精确的悬挂控制，能够更加准确地控制车辆颠簸程度。</div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className={'griditem griditem7'}>
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport7.jpg') : createFilePath('/accord/v2/pc/sport/sport7.jpg')}
                          />
                          <img
                            alt="广汽本田"
                            className="car "
                            // swiper-animate-effect="animate__fadeInBottomRight"
                            // swiper-animate-duration="0.6s"
                            // swiper-animate-delay="0s"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport7_car.png')
                                : createFilePath('/accord/v2/pc/sport/sport7_car.png')
                            }
                          />
                          <div
                            className="sub ani"
                            swiper-animate-effect="animate__fadeInRightBig"
                            swiper-animate-duration=".5s"
                            swiper-animate-delay="0s"
                          >
                            <div className="en">SMOOTH OUTPUT, ACCELERATING WITH YOUR HEARTBEA</div>
                            <div className="cn">畅快输出 与心跳同频加速</div>
                          </div>
                          <div
                            className="list ani"
                            swiper-animate-effect="animate__fadeInRightBig"
                            swiper-animate-duration=".5s"
                            swiper-animate-delay="0s"
                          >
                            <div className="li">1.5T直喷VTEC涡轮增压发动机</div>
                            <div className="p">采用高燃压直喷系统和多段喷射控制发动机，极大提升燃烧效率，最大功率达141kW，最大扭矩达260N·m。</div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                      <div className="next" data-html-item="price_power-btnr"></div>
                      <div className="prev" data-html-item="price_power-btnl"></div>
                    </div>
                  );
                }}
              </Scene>
            </Controller>
          </>
        ) : (
          <>
            <div className={'griditem griditem0'} id={data?.id}>
              <img
                alt="广汽本田"
                className="bg"
                src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport0.jpg') : createFilePath('/accord/v2/pc/sport/sport0.jpg')}
              />
              <div className="t-img ani" swiper-animate-effect="animate__fadeInDownBig" swiper-animate-duration="1.5s" swiper-animate-delay="0s">
                <img
                  alt="广汽本田"
                  src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport1_t.png') : createFilePath('/accord/v2/pc/sport/sport1_t.png')}
                />
              </div>
              <div className="text">
                <div className="t ani" swiper-animate-effect="animate__fadeInDownBig" swiper-animate-duration="1.5s" swiper-animate-delay="0s">
                  <span>智动</span>全新三电科技
                </div>
                <div className="sub ani" swiper-animate-effect="animate__fadeInUpBig" swiper-animate-duration="1.5s" swiper-animate-delay="0s">
                  <div className="en">A THRILLING WINDY JOURNEY</div>
                  <div className="cn">一趟肾上腺素飙升的竞风之旅</div>
                </div>
                <div className="list ani" swiper-animate-effect="animate__fadeInUpBig" swiper-animate-duration="1.5s" swiper-animate-delay="0s">
                  <div className="li">Honda能控科技大成</div>
                  <div className="p">
                    集合Honda数十年技术精研的第四代i-MMD双电机混合动力系统，配以电机驱动科技，提供Honda独有行驶品质的全新三电科技。
                  </div>
                </div>
                <div
                  className="btn"
                  data-html-item="price_power-detail"
                  onClick={() => {
                    setSwiperflag(true);
                    windowhidden();
                  }}
                >
                  展开详情
                </div>
                {/* <div
                  className="btn btn1"
                  onClick={() => {
                    openVideo();
                  }}
                >
                  查看视频
                </div> */}
              </div>
            </div>

            {swiperflag ? (
              <div className="modules-swiper-prop">
                <div
                  className="close"
                  onClick={() => {
                    window.Dmp.clickAction('price_power-detail-close');
                    setSwiperflag(false);
                    closewindowhidden();
                  }}
                ></div>
                <Swiper
                  ref={swiperRef}
                  slidesPerView={1}
                  className="modules-swiper"
                  spaceBetween={0}
                  initialSlide={0}
                  observer={true}
                  resizeObserver={true}
                  updateOnWindowResize={true}
                  modules={[Navigation]}
                  navigation={{
                    prevEl: '.sport .prev',
                    nextEl: '.sport .next',
                    disabledClass: 'disabled'
                  }}
                  onInit={(e) => {}}
                  onTransitionStart={(e) => {
                    setShowcountnum(false);
                  }}
                  onSlideChangeTransitionEnd={(e) => {
                    setTimeout(
                      function () {
                        setShowcountnum(true);
                      },
                      swipertypeflag ? 400 : 0
                    );
                  }}
                >
                  <SwiperSlide className={'griditem griditem1'}>
                    <img
                      alt="广汽本田"
                      className="bg"
                      src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport1.jpg') : createFilePath('/accord/v2/pc/sport/sport1.jpg')}
                    />

                    <div className="text">
                      <div
                        className="sub ani"
                        swiper-animate-effect="animate__fadeInDownBig"
                        swiper-animate-duration="1.5s"
                        swiper-animate-delay="0s"
                      >
                        <div className="en">ELECTRIC DRIVE SUMMONS EVERY SECOND OF PLEASURE</div>
                        <div className="cn">
                          电驱
                          <br />
                          召唤每一寸兴奋的快感
                        </div>
                      </div>
                      <div className="list">
                        <div className="li">第四代i-MMD双电机混合动力系统</div>
                      </div>
                      <div
                        className="btn"
                        data-html-item="price_power-plugin-iMMD"
                        data-url="https://i-mmd-lab.ghac.cn/"
                        onClick={() => {
                          window.open('https://i-mmd-lab.ghac.cn/');
                        }}
                      >
                        探索先进技术
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className={'griditem griditem2'}>
                    <img
                      alt="广汽本田"
                      className="bg"
                      src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport2.jpg') : createFilePath('/accord/v2/pc/sport/sport2.jpg')}
                    />
                    <div className="text">
                      <div
                        className="sub ani"
                        swiper-animate-effect="animate__fadeInDownBig"
                        swiper-animate-duration="1.5s"
                        swiper-animate-delay="0s"
                      >
                        <div className="en">EFFORTLESS POWER PERFORMANCE SMOOTH THE DRIVING EXPERIENCE</div>
                        <div className="cn">
                          静享
                          <br />
                          抚平每一处毛孔的躁动
                        </div>
                      </div>
                      <div
                        className="btn"
                        data-html-item="price_power-enjoy-detail"
                        onClick={() => {
                          setSport2detail(true);
                        }}
                      >
                        查看详情
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className={'griditem griditem3'}>
                    <img
                      alt="广汽本田"
                      className="bg"
                      src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport3.jpg') : createFilePath('/accord/v2/pc/sport/sport3.jpg')}
                    />
                    <div className="text">
                      <div className="t-img ani" swiper-animate-effect="animate__bounceInDown" swiper-animate-duration="1s" swiper-animate-delay="0s">
                        <img
                          alt="广汽本田"
                          src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport1_t.png') : createFilePath('/accord/v2/pc/sport/sport1_t.png')}
                        />
                      </div>
                      <div className="sub ani" swiper-animate-effect="animate__bounceInDown" swiper-animate-duration="1s" swiper-animate-delay="0s">
                        <div className="en">UNLEASH YOURSELF AT ANY MOMENT WITH INSTANT ELECTRIC POWER </div>
                        <div className="cn">随时来电 让你任性释放</div>
                      </div>
                      <div className="list ani" swiper-animate-effect="animate__bounceInDown" swiper-animate-duration="1s" swiper-animate-delay="0s">
                        <div className="li">全路况EV畅行</div>
                        <div className="li">
                          EV Switch
                          <img
                            alt="广汽本田"
                            className="icon"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/sport/sport3_icon1.png')
                                : createFilePath('/accord/v2/pc/sport/sport3_icon1.png')
                            }
                          />
                        </div>
                        <div className="li">3色充电显示灯</div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className={'griditem griditem4'}>
                    <img
                      alt="广汽本田"
                      className="bg"
                      src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport4.jpg') : createFilePath('/accord/v2/pc/sport/sport4.jpg')}
                    />
                    <div className="text">
                      <div
                        className="sub ani"
                        swiper-animate-effect="animate__fadeInDownBig"
                        swiper-animate-duration="1.2s"
                        swiper-animate-delay="0s"
                      >
                        <div className="en">UNLEASH LIMITLESS POWER </div>
                        <div className="cn">硬核实力 持续输出</div>
                      </div>
                      <div
                        className="bottom ani"
                        swiper-animate-effect="animate__fadeInUpBig"
                        swiper-animate-duration="1.6s"
                        swiper-animate-delay="0s"
                      >
                        <div className="l">
                          <div className="info">
                            <div className="li">
                              <div className="n">体积减少</div>
                              <div className="val">
                                <b>{showcountnum ? <CountUp duration={3} useGrouping={false} start={0} end={40} /> : '40'}%</b>
                              </div>
                              <div className="cirl">
                                <img
                                  alt="广汽本田"
                                  src={
                                    isMobile ? createFilePath('/accord/v2/mobile/sport/cirl4.png') : createFilePath('/accord/v2/pc/sport/cirl4.png')
                                  }
                                />
                              </div>
                            </div>
                            <div className="li">
                              <div className="n">输出提高</div>
                              <div className="val">
                                <b>{showcountnum ? <CountUp duration={3} useGrouping={false} start={0} end={15} /> : '15'}%</b>
                              </div>
                              <div className="cirl">
                                <img
                                  alt="广汽本田"
                                  src={
                                    isMobile ? createFilePath('/accord/v2/mobile/sport/cirl5.png') : createFilePath('/accord/v2/pc/sport/cirl5.png')
                                  }
                                />
                              </div>
                            </div>
                            <div className="li">
                              <div className="n">输出电流增加</div>
                              <div className="val">
                                <b>{showcountnum ? <CountUp duration={5} useGrouping={false} start={0} end={7} /> : '7'}%</b>
                              </div>
                              <div className="cirl">
                                <img
                                  alt="广汽本田"
                                  src={
                                    isMobile ? createFilePath('/accord/v2/mobile/sport/cirl6.png') : createFilePath('/accord/v2/pc/sport/cirl6.png')
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="list">
                            <div className="li">高输出密度动力控制单元PCU</div>
                            <div className="p">内置式动力单元的体积实现了40%的小型化，输出却提高15%，实现了高输出高密度化。</div>
                          </div>
                        </div>
                        <div className="r">
                          <div className="info">
                            <div className="li">
                              <div className="n">体积减少</div>
                              <div className="val">
                                <b>{showcountnum ? <CountUp duration={3} useGrouping={false} start={0} end={57} /> : '57'}%</b>
                              </div>
                              <div className="cirl">
                                <img
                                  alt="广汽本田"
                                  src={
                                    isMobile ? createFilePath('/accord/v2/mobile/sport/cirl1.png') : createFilePath('/accord/v2/pc/sport/cirl1.png')
                                  }
                                />
                              </div>
                            </div>
                            <div className="li">
                              <div className="n">重量减轻</div>
                              <div className="val">
                                <b>{showcountnum ? <CountUp duration={3} useGrouping={false} start={0} end={51} /> : '51'}%</b>
                              </div>
                              <div className="cirl">
                                <img
                                  alt="广汽本田"
                                  src={
                                    isMobile ? createFilePath('/accord/v2/mobile/sport/cirl2.png') : createFilePath('/accord/v2/pc/sport/cirl2.png')
                                  }
                                />
                              </div>
                            </div>
                            <div className="li">
                              <img
                                alt="广汽本田"
                                src={
                                  isMobile
                                    ? createFilePath('/accord/v2/mobile/sport/sport4_icon2.png')
                                    : createFilePath('/accord/v2/pc/sport/sport4_icon2.png')
                                }
                              />
                              <div className="n1">
                                后排乘客头部、
                                <br />
                                脚部空间扩大
                              </div>
                            </div>
                          </div>
                          <div className="list">
                            <div className="li">轻量型智能动力单元IPU</div>
                            <div className="p">重量与体积减少50%以上，实现小型轻量化，带来与燃油车相同的低车身和舒适的乘坐空间。</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className={'griditem griditem5'}>
                    <img
                      alt="广汽本田"
                      className="bg"
                      src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport5.jpg') : createFilePath('/accord/v2/pc/sport/sport5.jpg')}
                    />
                    <div className="text">
                      <div className="sub ani" swiper-animate-effect="animate__fadeInLeft" swiper-animate-duration="1.5s" swiper-animate-delay="0s">
                        <div className="en">SILENT RIDE, CONQUER THE WORLD</div>
                        <div className="cn">让世界 沉寂在你的脚下</div>
                      </div>
                      <div className="list ani" swiper-animate-effect="animate__fadeInLeft" swiper-animate-duration="1.5s" swiper-animate-delay="0s">
                        <div className="li">主动降噪系统</div>
                        <div className="p">采用全新3轴振动传感器，可高精度检测出轮胎声噪，用音响发出可以抵消噪音的声音，大幅提升车内静音性。</div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className={'griditem griditem6'}>
                    <img
                      alt="广汽本田"
                      className="bg"
                      src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport6.jpg') : createFilePath('/accord/v2/pc/sport/sport6.jpg')}
                    />
                    <div className="text">
                      <div
                        className="sub ani"
                        swiper-animate-effect="animate__fadeInDownBig"
                        swiper-animate-duration="1.5s"
                        swiper-animate-delay="0s"
                      >
                        <div className="en">DASHING FORWARD RECKLESSLY, SMOOTHING OUT THE CRAZY ROAD</div>
                        <div className="cn">肆意前行 抹平路面的疯狂</div>
                      </div>
                      <div
                        className="list list1 ani"
                        swiper-animate-effect="animate__fadeInUpBig"
                        swiper-animate-duration="1.8s"
                        swiper-animate-delay="0s"
                      >
                        <div className="li">运动管理系统</div>
                        <div className="p">车辆转弯过程中，动力系统与刹车在方向盘的配合操作下实现共同控制的技术。有效控制车辆左右晃动。</div>
                      </div>
                      <div
                        className="list list2 ani"
                        swiper-animate-effect="animate__fadeInUpBig"
                        swiper-animate-duration="1.8s"
                        swiper-animate-delay="0s"
                      >
                        <div className="li">自适应减震系统</div>
                        <div className="p">通过传感器提升车辆状态的感知性能，实现更加精确的悬挂控制，能够更加准确地控制车辆颠簸程度。</div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className={'griditem griditem7'}>
                    <img
                      alt="广汽本田"
                      className="bg"
                      src={isMobile ? createFilePath('/accord/v2/mobile/sport/sport7.jpg') : createFilePath('/accord/v2/pc/sport/sport7.jpg')}
                    />
                    <div className="text">
                      <div
                        className="sub ani"
                        swiper-animate-effect="animate__fadeInRightBig"
                        swiper-animate-duration="1.2s"
                        swiper-animate-delay="0s"
                      >
                        <div className="en">SMOOTH OUTPUT, ACCELERATING WITH YOUR HEARTBEA</div>
                        <div className="cn">畅快输出 与心跳同频加速</div>
                      </div>
                      <div
                        className="list ani"
                        swiper-animate-effect="animate__fadeInRightBig"
                        swiper-animate-duration="1.2s"
                        swiper-animate-delay="0s"
                      >
                        <div className="li">1.5T直喷VTEC涡轮增压发动机</div>
                        <div className="p">采用高燃压直喷系统和多段喷射控制发动机，极大提升燃烧效率，最大功率达141kW，最大扭矩达260N·m。</div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="next" data-html-item="price_power-btnr"></div>
                <div className="prev" data-html-item="price_power-btnl"></div>
                {sport2detail ? (
                  <div className="detailfull">
                    <div
                      className="close1"
                      onClick={() => {
                        window.Dmp.clickAction('price_power-enjoy-close');
                        setSport2detail(false);
                      }}
                    ></div>
                    <div
                      className="list list1 ani"
                      swiper-animate-effect="animate__bounceInRight"
                      swiper-animate-duration="2s"
                      swiper-animate-delay="0s"
                    >
                      <div className="li">
                        全新双电机
                        <br />
                        内置电气式CVT
                      </div>
                      <div className="p">
                        提升电机最大扭矩和最高转速，带来135kW高功率输出和335N·m的最大扭矩，驾驭随心所欲。通过优化减震结构降低电机特有的高频音及怠速发电中的驱动噪音，提升起步加速和静音性能，进一步提升顺畅、高级的驾驶质感。
                      </div>
                    </div>
                    <div
                      className="list list2 ani"
                      swiper-animate-effect="animate__bounceInLeft"
                      swiper-animate-duration="2s"
                      swiper-animate-delay="0s"
                    >
                      <div className="li">
                        2.0L直喷式
                        <br />
                        阿特金森发动机
                      </div>
                      <div className="p">
                        采用高燃压直喷与多级喷射控制发动机，空燃比范围扩大，并且通过提高筒内流动来实现极速燃烧，使得热效率高达41%，提高全档位的行驶性能与静音性，同时带来出色的燃油经济性，重释高效静谧驾趣的典范。
                      </div>
                      <div className="li">
                        NEDC工况纯电
                        <br />
                        续驶里程-106km
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
      {flag && <FullVideo fields={videodata} close={() => setFlag(false)} />}
    </>
  );
}
