import React, { useEffect, useState, useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getPrefixField, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields }) {
  const isMobile = isMobileFun();
  const html = (fields[getPrefixField('html', isMobile)]?.value || fields?.html?.value)?.replace(/&#8203;/g, '')?.replaceHtml();
  const js = (fields[getPrefixField('js', isMobile)]?.value || fields?.js?.value)?.split(',');
  const css = (fields[getPrefixField('css', isMobile)]?.value || fields?.css?.value)?.split(',');
  const hideHeader = fields[getPrefixField('hideHeader', isMobile)]?.value;
  const [show, setshow] = useState(false);
  const [iscss, setIscss] = useState(false);
  useEffect(() => {
    js?.forEach((item) => {
      const myScript = document.createElement('script');
      myScript.type = 'text/javascript';
      myScript.async = false;
      myScript.src = item?.replaceHtml();
      document.body.appendChild(myScript);
    });
    css?.forEach((item) => {
      const myLink = document.createElement('link');
      myLink.type = 'text/css';
      myLink.rel = 'stylesheet';
      myLink.href = item?.replaceHtml();
      const header = document.getElementsByTagName('head')[0];
      header.appendChild(myLink);
      setIscss(true);
    });
    if (hideHeader) {
      const header = document.querySelector('.headerBoxWhite');
      if (header) {
        header.style.display = 'none';
      }
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setshow(true);
    }, 800);
  }, [iscss]);

  return <div style={{ visibility: show ? 'visible' : 'hidden' }} dangerouslySetInnerHTML={{ __html: html }} />;
}
