import axios from 'axios';
import config from '../temp/config';
import lodash from 'lodash';
import * as querystring from 'querystring';
const service = axios.create({
  withCredentials: true
});
export const fetchLayoutService = (route, language, _params) => {
  const url = `${config.sitecoreApiHost}sitecore/api/layout/render/default`;
  const params = Object.create(null);
  if (global.location.search && global.location.search.length > 1) {
    lodash.merge(params, querystring.parse(global.location.search.substr(1)));
  }
  lodash.merge(params, _params, {
    item: route,
    sc_apikey: config.sitecoreApiKey,
    sc_site: config.jssAppName,
    sc_lang: language,
    tracking: true
  });
  return new Promise((resolve) => {
    service
      .request({
        url,
        method: 'GET',
        params
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((ex) => {
        console.error('ex', ex);
        resolve(null);
      });
  });
};
