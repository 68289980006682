/* eslint-disable no-eval */
import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { getPrefixField, getQueryString, sendDmp, isMobileFun } from '../../utils/obj-utils';
import temp from '../../temp/config';
import { dataFetcher } from '../../dataFetcher';

export default function index({ fields, eventRef, params }) {
  const containerRef = useRef(null);
  const isMobile = isMobileFun();
  const [articleList, setArticleList] = useState([]);
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const staticMenu = fields?.staticMenu;

  useEffect(() => {}, []);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields?.sectionDmp);
    // 二级导航高亮设置
    window.navigationIndex = fields?.navigationIndex?.value;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'price_power';
  };

  const getActivities = () => {
    dataFetcher(`/api/honda/v1/Article/GetArticleList?articleType=2&pageIndex=1&pageSize=4&client=${isMobile ? 2 : 1}`, null)
      .then(({ data }) => {
        if (data.Status) {
          let sd = [];
          if (data.Data && data.Data[0].Activities) {
            data.Data[0].Activities?.articles?.forEach((item) => {
              sd.push(item);
            });
          }
          setArticleList(sd);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getActivities();
    let itemId = getQueryString('itemId');
    return () => {};
  }, []);
  const getAUrl = (value) => {
    if (!value) return '';
    var obj = document.createElement('div');
    obj.innerHTML = value;
    return obj?.firstChild?.attributes?.url?.nodeValue;
  };

  return (
    <div className="ServiceDynamicsDetails_v1" id={params?.pagename !== undefined ? params?.pagename : 'service-dynamics-details'} ref={containerRef}>
      <div className="toptitle">服务动态</div>

      <div className="staticDetail">
        <Placeholder name="jss-service" rendering={route} page={route} />
      </div>

      <div className="staticMenu">
        <h2>
          购车指南<span>BUYERS GUIDE</span>
        </h2>
        <ul>
          {staticMenu &&
            staticMenu.map((item, index) => {
              return (
                <li key={index}>
                  <a
                    className="topa"
                    href={item?.fields?.url?.value}
                    dangerouslySetInnerHTML={{ __html: item?.fields?.title?.value?.replaceHtml() }}
                  />
                </li>
              );
            })}
        </ul>
      </div>

      <div className="relatedActivities">
        <h2>相关活动</h2>

        <ul>
          {articleList &&
            articleList.length > 0 &&
            articleList.map((item, index) => {
              return (
                <li key={index}>
                  <a
                    className="topa"
                    href={getAUrl(isMobile ? (item?.event_m_val ? item?.event_m_val : item?.event_pc_val) : item?.event_pc_val)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.a_title}
                  </a>
                  <div dangerouslySetInnerHTML={{ __html: item?.event_pc_val?.replaceHtml() }}></div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
