/* eslint-disable no-bitwise */
import React, { useRef, useState, useEffect } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, Navigation } from 'swiper';
import './style.scss';
import { createFilePath, getQueryString, isEmpty, debounce, isMobileFun } from '../../utils/obj-utils';
import Icon from '../../elements/icon';
import { dataFetcher } from '../../dataFetcher';
import FullModalDetail from '../../elements/FullModalDetail_v1';

export default function index({ fields }) {
  const pageSize = 6;
  const carSort = !isEmpty(getQueryString('car_sort')) && getQueryString('car_sort')?.split(',');
  const tabData = ['产品介绍', '纯正品质', '安全保障'];
  const isMobile = isMobileFun();
  const containerRef = useRef(null);
  const swiperRef = useRef(null);
  const pureSwiperRef = useRef(null);
  const modalRef = useRef(null);
  const pureData = useRef(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [vehicleIndex, setVehicleIndex] = useState(0);
  const [pureIndex, setPureIndex] = useState(0);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState();

  if (!pureData?.current) {
    pureData.current = JSON.parse(fields?.pureData?.value?.replaceHtml());
  }

  useEffect(() => {
    const swiper1 = new Swiper(swiperRef?.current, {
      grabCursor: true,
      modules: [Navigation],
      slidesPerView: isMobile ? 3 : 5,
      navigation: {
        prevEl: containerRef.current.querySelector('.navigation-prev'),
        nextEl: containerRef.current.querySelector('.navigation-next'),
        disabledClass: 'navigation-disabled'
      }
    });

    const swiper2 = new Swiper(pureSwiperRef?.current, {
      grabCursor: true,
      slidesPerView: 1,
      autoHeight: true,
      on: {
        slideChange: ({ activeIndex }) => {
          setPureIndex(activeIndex);
        }
      }
    });

    return () => {
      swiper1.destroy(true, true);
      swiper2.destroy(true, true);
    };
  }, []);

  useEffect(() => {
    getData(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    reloadVehiclesData();
  }, [vehicleIndex]);

  const getData = debounce((pageIndex = 1) => {
    setIsLoading(true);
    dataFetcher(`/api/honda/v1/Article/GetArticleList?articleType=6&pageIndex=${pageIndex}&pageSize=${pageSize}&client=0`)
      .then(({ data: response }) => {
        setIsLoading(false);
        if (response?.Status) {
          let vehicles = response?.Data[0]?.Mountings?.article_vehicles;
          if (carSort?.length > 0) {
            const temp = [...vehicles];
            vehicles = [];
            let count = 0;
            carSort.forEach((item) => {
              const findIndex = temp.findIndex((item1) => item1.Name?.toLocaleLowerCase() === item.toLocaleLowerCase());
              if (findIndex === -1) {
                count++;
              } else {
                vehicles.push(temp[findIndex]);
                temp.splice(findIndex, 1);
              }
            });
            vehicles = [...vehicles, ...temp.slice(0, count)];
          }
          const articles = vehicles[vehicleIndex]?.articles;
          setShowLoadMore(articles?.length === pageSize);
          vehicles[vehicleIndex].articles = pageIndex === 1 ? [...articles] : [...vehiclesData[vehicleIndex]?.articles, ...articles];
          setVehiclesData([...vehicles]);
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  });

  const tabClick = (index) => {
    setTabIndex(index);
  };

  const swiperClick = (index) => {
    setVehicleIndex(index);
  };

  const itemClick = (item) => {
    setDetail(item);
  };

  const loadMore = () => {
    setPageIndex(pageIndex + 1);
  };

  const modalClick = (e) => {
    const content = modalRef?.current?.querySelector('.appliance-v1-modal');
    if (!content.contains(e.target)) {
      modalClose();
    }
  };

  const modalClose = () => {
    setDetail();
  };

  const pureTabClick = (index) => {
    setPureIndex(index);
    pureSwiperRef.current.swiper.slideTo(index);
  };

  const reloadVehiclesData = () => {
    if (pageIndex === 1) {
      getData();
    } else {
      setPageIndex(1);
    }
  };

  const threeDClick = () => {
    window.open(vehiclesData[vehicleIndex]?.link);
  };

  return (
    <section className="appliance-v1" ref={containerRef}>
      <div className="title">纯正用品</div>
      <div className="tab">
        {tabData?.map((item, index) => (
          <a className={tabIndex === index ? 'active' : ''} key={index} onClick={() => tabClick(index)}>
            {item}
          </a>
        ))}
      </div>
      <div className={tabIndex !== 0 ? 'hide' : ''}>
        <div className="swiper-container">
          <div className="swiper" ref={swiperRef}>
            <div className="swiper-wrapper">
              {vehiclesData?.map((item, index) => {
                return (
                  <div key={index} className={`swiper-slide ${index === vehicleIndex ? 'active' : ''}`} onClick={() => swiperClick(index)}>
                    <img alt="广汽本田" src={createFilePath(item.thumb?.replaceHtml())} />
                    <p>{item.title}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="navigation-prev">
            <Icon name="ArrowLeft1" />
          </div>
          <div className="navigation-next">
            <Icon name="ArrowRight1" />
          </div>
        </div>
        <ul className="list">
          {vehiclesData[vehicleIndex]?.articles?.map((item, index) => {
            return (
              <li className="item" key={index} onClick={() => itemClick(item)}>
                <p className="picture-container">
                  <img src={createFilePath(item.a_thumb?.replaceHtml())} alt={item.a_title} />
                </p>
                <div className="detail-container">
                  <div className="title-container">
                    <div>{item.a_title}</div>
                    <p className="subtitle" dangerouslySetInnerHTML={{ __html: item.a_subtitle }} />
                  </div>
                  <p className="desc" dangerouslySetInnerHTML={{ __html: item.a_intro }} />
                </div>
              </li>
            );
          })}
        </ul>
        {!vehiclesData[vehicleIndex]?.articles?.length && <div>暂无相关数据</div>}
        {vehiclesData[vehicleIndex]?.articles?.length > 0 && showLoadMore && !isLoading && (
          <div className="load-more-container">
            <div className="load-more" onClick={loadMore}>
              <img alt="广汽本田" className="load-more-image" src={createFilePath('/common/icon/loadMore.png')} />
              <div className="load-more-text">点击加载更多</div>
            </div>
          </div>
        )}
      </div>
      {tabIndex === 1 && (
        <>
          <p className="section-title">
            <strong>纯正品质</strong>
            <span>与整车一体化</span>
          </p>
          <div className="quality-container">
            <img alt="广汽本田" src={createFilePath(`/appliance/${isMobile ? 'supplies01_mb' : 'supplies01'}.jpg`)} />
            <ul className="quality-list">
              <li className="quality-list-item">
                <img alt="广汽本田" src={createFilePath('/appliance/pic01.jpg')} />
                <div className="info-container">
                  <strong>个性设计</strong>
                  <p>从造车技术和人性化的设计思路等方面综合考量，Honda设计师通过更加优秀的设计，让车辆本身焕发更加卓然不群的视觉呈现。</p>
                </div>
              </li>
              <li className="quality-list-item">
                <img alt="广汽本田" src={createFilePath('/appliance/pic02.jpg')} />
                <div className="info-container">
                  <strong>卓越性能</strong>
                  <p>Honda技师通过用品与整车的一体化研发，使每个用品都为这款车的基本性能和运行技能得到更加卓越的、更进一步的激发。</p>
                </div>
              </li>
              <li className="quality-list-item">
                <img alt="广汽本田" src={createFilePath('/appliance/pic03.jpg')} />
                <div className="info-container">
                  <strong>优良品质</strong>
                  <p>
                    每个用品的品质，毋庸置疑，更在制造过程中由Honda技师将其融合于整个车身，并在实际路况下进行了耐性、钢性、轻度、气候等测试，全面保障了车辆的综合品质。
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </>
      )}
      <div className={tabIndex !== 2 ? 'hide' : ''}>
        <p className="section-title">
          <strong>纯正用品加装须知：</strong>
        </p>
        <div style={{ margin: '2% 0 0 0' }}>
          <p style={{ lineHeight: '1.8' }}>
            ①部分纯正用品加装项目可能会对车辆的部分零件造成不可逆的破坏和影响；
            <br />
            ②鉴于车辆纯正用品加装涉及车辆行政管理的可能，在加装前您需提前向车辆所在地的车辆管理所进行咨询；并自行办理车辆纯正用品加装后正常行驶所需的必要变更手续；
            <br />
            ③纯正用品加装后车辆如遇事故，保险公司可能无法按照原车辆的理赔范围进行赔付，需自费对加装部分进行维修。
          </p>
        </div>
        <p className="section-title">
          <strong>纯正品质</strong>
          <span>有什么保障？</span>
        </p>
        <ul className="quality-list guarantee-list">
          <li className="quality-list-item">
            <img alt="广汽本田" src={createFilePath('/appliance/pic04.jpg')} />
            <div className="info-container">
              <strong>设计保障</strong>
              <p>纯正用品充分发挥本田汽车的设计性能与车辆同步进行开发，设计。它的设计、材质、安装等方面与车辆保持上佳的配合。</p>
            </div>
          </li>
          <li className="quality-list-item">
            <img alt="广汽本田" src={createFilePath('/appliance/pic05.jpg')} />
            <div className="info-container">
              <strong>品质保障</strong>
              <p>纯正用品每一个产品都需要通过耐磨测试、风洞试验等一百多项测试检测，能保证与本田汽车同水准的长期品质，因此用户可放心使用。</p>
            </div>
          </li>
          <li className="quality-list-item">
            <img alt="广汽本田" src={createFilePath('/appliance/pic06.jpg')} />
            <div className="info-container">
              <strong>售后保障</strong>
              <p>纯正用品采用与本田汽车同步的售后保修承诺，沿用整车的售后服务终端网络，为车主提供高品质的售后保修服务。</p>
            </div>
          </li>
        </ul>
        <p className="section-title">
          <strong>纯正辨别</strong>
          <span>如何辨别纯正用品与非纯正用品？</span>
        </p>
        {!isMobile && (
          <ul className="pure-tab">
            {pureData?.current?.map((item, index) => (
              <li key={index} className={`pure-tab-item${index === pureIndex ? ' active' : ''}`} onClick={() => pureTabClick(index)}>
                {item?.title}
              </li>
            ))}
          </ul>
        )}
        <div className="swiper" ref={pureSwiperRef}>
          <div className="swiper-wrapper pure-swiper-wrapper">
            {pureData?.current?.map((item, index) => {
              return (
                <div key={index} className="swiper-slide">
                  {item?.image?.map((item1, index1) => (
                    <img alt="广汽本田" className="pure-swiper-image" key={index1} src={createFilePath(item1)} />
                  ))}
                  <table className="pure-table">
                    <thead>
                      <tr>
                        <th>{item?.title}</th>
                        <th>纯正用品</th>
                        <th>非纯正用品</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.data?.map((item1, index1) => (
                        <tr key={index1}>
                          {item1.map((item2, index2) => (
                            <td key={index2}>{item2}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {vehiclesData[vehicleIndex]?.link && (
        <img alt="广汽本田" className="three-D-button" src={createFilePath('/appliance/appliance_float.png')} onClick={threeDClick} />
      )}
      {detail && (
        <FullModalDetail style={{ background: 'rgba(0, 0, 0, 0.5)' }} onClick={modalClick} ref={modalRef}>
          <div className="appliance-v1-modal">
            <p className="picture-container">
              <img src={createFilePath(detail.a_thumb?.replaceHtml())} alt={detail.a_title} />
            </p>
            <div className="title-container">
              <div dangerouslySetInnerHTML={{ __html: detail.a_title }} />
              <p dangerouslySetInnerHTML={{ __html: detail.a_subtitle }} />
            </div>
            <p className="desc" dangerouslySetInnerHTML={{ __html: detail.a_intro }} />
            <img alt="广汽本田" className="close" src={createFilePath('/appliance/close.png')} onClick={modalClose} />
          </div>
        </FullModalDetail>
      )}
    </section>
  );
}
