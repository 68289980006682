import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import Details from '../../elements/InnerPageDetail';
import { getQueryVariable } from '../../utils/site-utils';
import { getPrefixField, sendDmp, convertStylesStringToObject, countUp, isInView, isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function Power(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const [inView, setInView] = useState(false);
  const [flag, setFlag] = useState(false);
  const [tabChange, setTabChange] = useState(false);
  const [num2, setNum2] = useState(0);
  const [windowHeight, setWindowHeight] = useState(400);
  const [countMap, setCountMap] = useState({});
  const rootEl = useRef(null);

  const chooseTab = () => {
    let value = getQueryVariable('tab');
    if (!fields[`${getPrefixField('tab_list', isMobile)}`].find((item) => item.fields.tabValue.fields.v.value === value)) {
      value = fields[`${getPrefixField('defaultValue', isMobile)}`]?.fields?.v?.value;
    }
    let number = 0;
    fields[`${getPrefixField('tab_list', isMobile)}`].map((item, index) => {
      if (item.fields.tabValue.fields.v.value === value) {
        number = index;
      }
    });
    return number;
  };

  const [num, setNum] = useState(chooseTab());

  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);

  useEffect(() => {
    const scroll = () => {
      setInView(isInView(rootEl));
    };
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('resize', resizeEvent);
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields.window_dmp);
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : 'price_power';
  };

  useEffect(() => {
    const key = fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.list[num2].fields.count_up_class?.value;
    if (!key) return;
    const doms = document.querySelectorAll(`.${key}`);
    if (doms && doms.length) {
      const list = [];
      doms.forEach((element, index) => {
        let numStr = element.innerText;
        list.push(numStr);
        if (countMap.hasOwnProperty(key)) {
          numStr = countMap[key][index];
        }
        countUp(element, numStr, 800);
      });
      if (!countMap.hasOwnProperty(key)) {
        setCountMap({ ...countMap, [key]: list });
      }
    }
  }, [num, num2, inView]);

  return (
    <div ref={rootEl}>
      <Controller>
        <Scene triggerHook={1} duration={windowHeight}>
          {(progress, event) => {
            return (
              <div style={convertStylesStringToObject(fields[`${getPrefixField('container_style', isMobile)}`]?.value)} id="price_power">
                <Timeline totalProgress={progress} paused>
                  <div className="Power_v1">
                    <div className="PowerCenter">
                      <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={1}>
                        <div className="PowerTop">
                          {fields[`${getPrefixField('tab_list', isMobile)}`].map((ele, index) => {
                            return (
                              <div
                                className={index === num ? 'tab_active' : 'tab_container'}
                                key={index}
                                onClick={() => {
                                  setNum(index);
                                  setNum2(0);
                                  sendDmp(ele.fields.tab_dmp);
                                  setTabChange(true);
                                  setTimeout(() => {
                                    document.querySelector('.powerContentSwiper').swiper.slideTo(0, 100, false);
                                  }, 0);
                                }}
                                dangerouslySetInnerHTML={{ __html: ele.fields.tab?.value?.replaceHtml() }}
                              />
                            );
                          })}
                        </div>
                      </Tween>
                      <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={2}>
                        <div>
                          <Swiper
                            className="powerContentSwiper"
                            onSlideChangeTransitionStart={(swiper) => {
                              setNum2(swiper.activeIndex);
                              if (!tabChange) {
                                sendDmp(fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.list[swiper.activeIndex].fields.tab_dmp);
                              } else {
                                setTabChange(false);
                                sendDmp(fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.list[swiper.activeIndex].fields.tab_dmp);
                              }
                              document.querySelector('.powerTabSwiper')?.swiper?.slideTo(swiper.activeIndex);
                            }}
                          >
                            {fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.list?.map((ele, index) => {
                              return (
                                <SwiperSlide key={index}>
                                  {!isMobile && (
                                    <div className="contentContainer">
                                      <div className="leftContainer">
                                        <img alt="广汽本田" src={`${createFilePath(ele.fields.img?.value)}`} />
                                      </div>
                                      <div
                                        className="rightContainer"
                                        dangerouslySetInnerHTML={{ __html: ele.fields.content?.value?.replaceHtml() }}
                                      />
                                    </div>
                                  )}
                                  {isMobile && (
                                    <div className="contentContainer">
                                      <div
                                        className="topContainer"
                                        style={convertStylesStringToObject(fields.m_conent_container_style?.value)}
                                        dangerouslySetInnerHTML={{ __html: ele.fields.content?.value?.replaceHtml() }}
                                      />
                                      <div className="bottomContainer">
                                        <img alt="广汽本田" src={`${createFilePath(ele.fields.img?.value)}`} />
                                      </div>
                                    </div>
                                  )}
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                      </Tween>
                      <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={3}>
                        <div className="tabsContainer">
                          {fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.list.length > 1 && (
                            <Swiper
                              className="powerTabSwiper"
                              slidesPerView={
                                !isMobile
                                  ? fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.list.length
                                  : fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.list.length > 3
                                  ? 3
                                  : fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.list.length
                              }
                              centeredSlides={
                                !isMobile
                                  ? false
                                  : fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.list.length > 3 &&
                                    num2 > 0 &&
                                    num2 < fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.list.length - 1
                              }
                            >
                              {fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.list?.map((ele, index) => {
                                return (
                                  <SwiperSlide
                                    key={index}
                                    onClick={() => {
                                      setNum2(index);
                                      document.querySelector('.powerContentSwiper').swiper.slideTo(index);
                                    }}
                                  >
                                    <div
                                      className={index === num2 ? 'tabContainer_active' : 'tabContainer'}
                                      dangerouslySetInnerHTML={{ __html: ele.fields.tab?.value?.replaceHtml() }}
                                    />
                                  </SwiperSlide>
                                );
                              })}
                            </Swiper>
                          )}
                        </div>
                      </Tween>
                      <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={3}>
                        <div className="buttonContainer">
                          <div
                            onClick={() => {
                              sendDmp(
                                fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.immd_btn_dmp,
                                fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.immd_btn_href.value
                              );
                              window.open(fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.immd_btn_href.value);
                            }}
                            dangerouslySetInnerHTML={{
                              __html: fields[`${getPrefixField('tab_list', isMobile)}`][num]?.fields?.immd_btn?.value?.replaceHtml()
                            }}
                          />
                          <div
                            onClick={() => {
                              sendDmp(fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.detail_btn_dmp);
                              setFlag(true);
                            }}
                            dangerouslySetInnerHTML={{
                              __html: fields[`${getPrefixField('tab_list', isMobile)}`][num]?.fields?.detail_btn?.value?.replaceHtml()
                            }}
                          />
                        </div>
                      </Tween>
                    </div>
                  </div>
                </Timeline>
              </div>
            );
          }}
        </Scene>
      </Controller>
      {flag && (
        <Details
          routePath={fields[`${getPrefixField('tab_list', isMobile)}`][num].fields.list[num2].fields.detail_btn_href?.value}
          close={() => setFlag(false)}
        />
      )}
    </div>
  );
}
