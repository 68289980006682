import React, { useState, useEffect } from 'react';
import temp from '../../temp/config';
import './style.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Details from './details/index';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const isMobile = isMobileFun();
  const { backImg, backImgAlt, lookImg, positionClass, m_backImg, m_backImgAlt, video, m_marginFlag, dmpUrl, initialDmp, lookDmp } = props.fields;
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    if (!initialDmp.value) {
      setTimeout(() => {
        window.Dmp.sectionAction('manual');
      }, 1500);
    }
  }, []);
  return (
    <div className={`operation_main_v2${isMobile ? '_m' : ''}`} style={{ marginTop: m_marginFlag.value && isMobile ? '47px' : '' }}>
      <img src={`${createFilePath(!isMobile ? backImg.value : m_backImg.value)}`} alt={!isMobile ? backImgAlt.value : m_backImgAlt.value} />
      {props.fields.videoFlag.value ? (
        <img
          alt="广汽本田"
          style={convertStylesStringToObject(isMobile ? props.fields.m_position.value : props.fields.position.value)}
          src={`${createFilePath(lookImg.value)}`}
          onClick={() => {
            window.Dmp.clickAction(lookDmp[0].displayName, '', '');
            setFlag(true);
          }}
          className={positionClass.value}
        />
      ) : (
        ''
      )}
      {props.fields.lookImg_s.value !== '' ? (
        <img
          alt="广汽本田"
          className="toplogo"
          style={convertStylesStringToObject(isMobile ? props.fields.m_position_s.value : props.fields.position_s.value)}
          src={`${createFilePath(props.fields.lookImg_s.value)}`}
          // onClick={() => {
          //   window.Dmp.clickAction('manual-back', props.fields.linkUrl_s.value, '');
          //   window.open(props.fields.linkUrl_s.value, '_self');
          // }}
        />
      ) : (
        ''
      )}
      {flag ? <Details setFlag={setFlag} videoSrc={video} dmpUrl={dmpUrl.value}></Details> : ''}
    </div>
  );
}
