/* eslint-disable no-bitwise */
import React, { useEffect, useState, useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import './style.scss';
import { isEmpty, createFilePath, getPrefixField, checkMobile, isMobileFun } from '../../utils/obj-utils';
import useIsomorphicLayoutEffect from '../../utils/useIsomorphicLayoutEffect';
import { dataFetcher, getJsonp } from '../../dataFetcher';

export default function index({ fields }) {
  const isMobile = isMobileFun();
  const containerRef = useRef(null);
  const usernameRef = useRef(null);
  const mobileRef = useRef(null);
  const empnoRef = useRef(null);
  const customerNameRef = useRef(null);
  const customerMobileRef = useRef(null);
  const searchMobileRef = useRef(null);
  const typeData = useRef(null);
  const companyData = useRef(null);
  const [location, setLocation] = useState({ province: '广东省', city: '广州市' });
  const [originalDealerData, setOriginalDealerData] = useState();
  const [provinceData, setProvinceData] = useState();
  const [selectProvince, setSelectProvince] = useState();
  const [selectCity, setSelectCity] = useState();
  const [selectDealer, setSelectDealer] = useState();
  const [recommendInfo, setRecommendInfo] = useState({});
  const [selectVehicleType, setSelectVehicleType] = useState();
  const [selectCompany, setSelectCompany] = useState();
  const [resultList, setResultList] = useState([]);
  const [isShowForm, setIsShowForm] = useState(false);
  const [isShowSearch, setIsShowSearch] = useState(false);

  if (!typeData?.current) {
    typeData.current = JSON.parse(fields?.typeData?.value?.replaceHtml());
    companyData.current = JSON.parse(fields?.companyData?.value?.replaceHtml());
  }

  useIsomorphicLayoutEffect(() => {
    getLocation();
    getOriginalDealer();
  }, []);

  useEffect(() => {
    if (originalDealerData) {
      setProvinceData(filterDealerData());
    }
  }, [selectVehicleType, originalDealerData]);

  useEffect(() => {
    if (provinceData && location) {
      const province = provinceData?.find((item) => location.province.includes(item.province_name));
      const city = province?.Citys?.find((item) => location.city.includes(item.city_name));
      setSelectProvince(province);
      setSelectCity(city);
    }
  }, [provinceData, location]);

  useEffect(() => {
    setSelectDealer();
  }, [selectCity, selectProvince]);

  const getLocation = () => {
    getJsonp('https://restapi.amap.com/v3/ip?key=d4db88c544bf23a3a363f7ff8bcbf783&output=json')
      .then((response) => {
        if (response.status === '1') {
          if (response?.province?.length > 0 && response?.city?.length > 0) {
            setLocation({ province: response.province, city: response.city });
          }
        } else {
          return Promise.reject();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getOriginalDealer = () => {
    dataFetcher('/api/honda/v1/Leads/GetAllDealerProCitys')
      .then(({ data }) => {
        if (data.Status) {
          setOriginalDealerData({ ...data?.Data });
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addRecommend = () => {
    dataFetcher('/api/honda/v1/Activity/SaveRecommend', recommendInfo)
      .then(({ data }) => {
        if (data?.Status) {
          alert('提交成功！');
          recommendInfo.CName = '';
          recommendInfo.CMobile = '';
          customerNameRef.current.value = '';
          customerMobileRef.current.value = '';
          hideForm();
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        alert(error?.message);
        console.error(error);
      });
  };

  const getRecommend = () => {
    dataFetcher(`/api/honda/v1/Activity/GetRecommedInfo?empMobile=${searchMobileRef?.current?.value}`)
      .then(({ data }) => {
        if (data?.Status) {
          if (data?.Data?.length === 0) {
            alert('无申请记录');
            return;
          }
          setResultList(data?.Data);
          hideSearch();
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        alert(error?.message);
        console.error(error);
      });
  };

  const filterDealerData = () => {
    const originalData = JSON.parse(JSON.stringify(originalDealerData));
    if (!selectVehicleType) {
      return reduceDealerData(originalData);
    }
    const data = { Provinces: [], Citys: [], Dealers: [...originalData?.Dealers] };
    originalData?.Citys?.forEach((item) => {
      const dealers = getDealersData(item.city_id, originalData?.Dealers);
      if (dealers.length !== 0) {
        item.Dealers = dealers;
        data.Citys.push(item);
      }
    });
    originalData?.Provinces?.forEach((item) => {
      const citys = data?.Citys?.filter((item1) => item1.province_id === item.province_id);
      if (citys.length !== 0) {
        item.Citys = citys;
        data.Provinces.push(item);
      }
    });
    return data?.Provinces;
  };

  const reduceDealerData = (data) => {
    data?.Provinces?.forEach((item1) => {
      data?.Citys?.forEach((item2) => {
        item2.Dealers = data?.Dealers?.filter((item3) => item3.city_id === item2.city_id);
      });
      item1.Citys = data?.Citys?.filter((item2) => item2.province_id === item1.province_id);
    });
    return [...data?.Provinces];
  };

  const getDealersData = (cityId, data) => {
    const modelName = selectVehicleType?.modelName;
    const weight = selectVehicleType?.weight;
    let dealers = [];
    if (!isEmpty(weight)) {
      dealers = data.filter((item) => item.city_id === cityId && (weight & item.dealer_right) > 0);
      // if (modelName === 'enp1') {
      //   if (dealers.length === 0) {
      //     dealers = data.filter((item) => item.city_id === cityId && item.dealer_level === 1);
      //   } else {
      //     const exist = dealers.find((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     if (exist) {
      //       dealers = dealers.filter((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     }
      //   }
      // }
    }
    const newEnergy = dealers.filter((item) => item.dealer_right === 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    const gas = dealers.filter((item) => item.dealer_right !== 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    return [...newEnergy, ...gas];
  };

  const companyChange = (e) => {
    setSelectCompany(companyData?.current?.find((item) => item.value === e.target.value));
  };

  const usernameChange = (e) => {
    recommendInfo.EName = e.target.value;
  };

  const mobileChange = (e) => {
    recommendInfo.EMobile = e.target.value;
  };

  const empnoChange = (e) => {
    recommendInfo.ENO = e.target.value;
  };

  const customerNameChange = (e) => {
    recommendInfo.CName = e.target.value;
  };

  const customerMobileChange = (e) => {
    recommendInfo.CMobile = e.target.value;
  };

  const vehicleTypeChange = (e) => {
    setSelectVehicleType(typeData?.current?.find((item) => item.seriesId === e.target.value));
  };

  const provinceChange = (e) => {
    setSelectProvince(provinceData?.find((item) => item.province_id === e.target.value));
    setSelectCity();
  };

  const cityChange = (e) => {
    setSelectCity(selectProvince?.Citys?.find((item) => item.city_id === e.target.value));
  };

  const dealerChange = (e) => {
    setSelectDealer(selectCity?.Dealers?.find((item) => item.dealer_id === e.target.value));
  };

  const submit = () => {
    if (!selectCompany) {
      alert('请选择所在单位');
      return;
    }
    if (isEmpty(recommendInfo?.EName)) {
      alert('请输入员工姓名');
      return;
    }
    if (isEmpty(recommendInfo?.EMobile)) {
      alert('请输入员工手机号码');
      return;
    }
    if (!checkMobile(recommendInfo?.EMobile)) {
      alert('员工手机号码格式不对，请重新输入');
      return;
    }
    if (isEmpty(recommendInfo?.ENO)) {
      alert('请输入员工招聘号');
      return;
    }
    if (recommendInfo?.ENO?.length > 6) {
      alert('员工招聘号错误');
      return;
    }
    if (isEmpty(recommendInfo?.CName)) {
      alert('请输入推荐购车客户姓名');
      return;
    }
    if (isEmpty(recommendInfo?.CMobile)) {
      alert('请输入推荐购车客户手机号码');
      return;
    }
    if (!checkMobile(recommendInfo?.CMobile)) {
      alert('客户手机号码格式不对，请重新输入');
      return;
    }
    if (recommendInfo?.EMobile === recommendInfo?.CMobile) {
      alert('客户手机号不能与员工手机号重复');
      return;
    }
    if (!selectVehicleType) {
      alert('请选择拟购车型');
      return;
    }
    if (!selectProvince) {
      alert('请选择省份');
      return;
    }
    if (!selectCity) {
      alert('请选择城市');
      return;
    }
    if (!selectDealer) {
      alert('请选择特约店');
      return;
    }
    recommendInfo.CarName = selectVehicleType?.name;
    recommendInfo.ProviceName = selectProvince?.province_name;
    recommendInfo.CityName = selectCity?.city_name;
    recommendInfo.DealerName = selectDealer?.dealer_name;
    recommendInfo.ECompany = selectCompany?.value;
    addRecommend();
  };

  const showForm = () => {
    setIsShowForm(true);
  };

  const hideForm = () => {
    setIsShowForm(false);
  };

  const showSearch = () => {
    setIsShowSearch(true);
  };

  const hideSearch = () => {
    setIsShowSearch(false);
  };

  const search = () => {
    if (isEmpty(searchMobileRef?.current?.value)) {
      alert('请输入手机号码');
      return;
    }
    if (!checkMobile(searchMobileRef?.current?.value)) {
      alert('手机号码格式不对，请重新输入');
      return;
    }
    getRecommend();
  };

  const hideResult = () => {
    setResultList([]);
  };

  return (
    <section className="recommend-v1" ref={containerRef} style={{ backgroundImage: `url(${createFilePath('/recommend-apply/bg.jpg')})` }}>
      <a className="logo" href="/">
        <img alt="广汽本田" src={createFilePath('/recommend-apply/logo_high.png')} />
      </a>
      <div className="button-container">
        <p style={{ backgroundImage: `url(${createFilePath('/recommend-apply/but_bg01.png')})` }} onClick={showForm}>
          <img alt="广汽本田" src={createFilePath('/recommend-apply/but01.png')} />
          <span>推荐购车申请</span>
        </p>
        <p style={{ backgroundImage: `url(${createFilePath('/recommend-apply/but_bg01.png')})` }} onClick={showSearch}>
          <img alt="广汽本田" src={createFilePath('/recommend-apply/but02.png')} />
          <span>申请记录查询</span>
        </p>
      </div>
      <div
        className="form-container"
        style={{ backgroundImage: `url(${createFilePath('/recommend-apply/bg02.jpg')})`, display: isShowForm ? 'block' : 'none' }}
      >
        <span className="form-title" style={{ backgroundImage: `url(${createFilePath('/recommend-apply/tit_bg.png')})` }}>
          推荐购车申请
        </span>
        <div className="content-container">
          <div className="input-container">
            <label className="recommend-info-name">员工所在单位：</label>
            <select
              className="recommend-info-select"
              style={{ backgroundImage: `url(${createFilePath('/recommend-apply/select.png')})` }}
              value={selectCompany?.value}
              onChange={companyChange}
            >
              <option>选择所在单位</option>
              {companyData?.current?.map((item, index) => (
                <option key={index} value={item?.value}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-container">
            <label className="recommend-info-name">员工姓名：</label>
            <input className="recommend-info-input" ref={usernameRef} type="text" placeholder="员工姓名" onChange={usernameChange} />
          </div>
          <div className="input-container">
            <label className="recommend-info-name">员工手机号码：</label>
            <input
              className="recommend-info-input"
              onKeyUp={(e) => (mobileRef.current.value = e.target.value.replace(/\D/g, ''))}
              maxLength={11}
              ref={mobileRef}
              type="text"
              placeholder="员工手机号码"
              onChange={mobileChange}
            />
          </div>
          <div className="input-container">
            <label className="recommend-info-name">员工号：</label>
            <input className="recommend-info-input" ref={empnoRef} type="text" placeholder="员工号" onChange={empnoChange} />
          </div>
          <div className="input-container">
            <label className="recommend-info-name">推荐购车客户姓名：</label>
            <input className="recommend-info-input" ref={customerNameRef} type="text" placeholder="客户姓名" onChange={customerNameChange} />
          </div>
          <div className="input-container">
            <label className="recommend-info-name">推荐购车客户手机号码：</label>
            <input
              className="recommend-info-input"
              onKeyUp={(e) => (customerMobileRef.current.value = e.target.value.replace(/\D/g, ''))}
              maxLength={11}
              ref={customerMobileRef}
              type="text"
              placeholder="客户手机号码"
              onChange={customerMobileChange}
            />
          </div>
          <div className="input-container">
            <label className="recommend-info-name">拟购车型：</label>
            <select
              className="recommend-info-select"
              style={{ backgroundImage: `url(${createFilePath('/recommend-apply/select.png')})` }}
              value={selectVehicleType?.seriesId}
              onChange={vehicleTypeChange}
            >
              <option>选择拟购车型</option>
              {typeData?.current?.map((item, index) => (
                <option key={index} value={item?.seriesId}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-container province">
            <label className="recommend-info-name">拟购车特约店:</label>
            <select
              className="recommend-info-select"
              style={{ backgroundImage: `url(${createFilePath('/recommend-apply/select.png')})` }}
              value={selectProvince?.province_id || 0}
              onChange={provinceChange}
            >
              <option value={0}>请选择省份</option>
              {provinceData?.map((item, index) => (
                <option key={index} value={item?.province_id}>
                  {item?.province_name}
                </option>
              ))}
            </select>
            <select
              className="recommend-info-select"
              style={{ backgroundImage: `url(${createFilePath('/recommend-apply/select.png')})` }}
              value={selectCity?.city_id || 0}
              onChange={cityChange}
            >
              <option value={0}>请选择城市</option>
              {selectProvince?.Citys?.map((item, index) => (
                <option key={index} value={item?.city_id}>
                  {item?.city_name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-container">
            <label className="recommend-info-name" />
            <select
              className="recommend-info-select"
              style={{ backgroundImage: `url(${createFilePath('/recommend-apply/select.png')})` }}
              value={selectDealer?.dealer_id || 0}
              onChange={dealerChange}
            >
              <option value={0}>请选择特约店</option>
              {selectCity?.Dealers?.map((item, index) => (
                <option key={index} value={item?.dealer_id}>
                  {item?.dealer_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <span className="remark">*申请须知：员工应至少在拟推荐购车客户首次到店前一天提交该客户信息，否则视为无效推荐</span>
        <p className="form-button">
          <em className="close" style={{ backgroundImage: `url(${createFilePath('/recommend-apply/but_bg.png')})` }} onClick={hideForm}>
            返 回
          </em>
          <em className="submit" style={{ backgroundImage: `url(${createFilePath('/recommend-apply/but_bg.png')})` }} onClick={submit}>
            提 交
          </em>
        </p>
      </div>
      <div className="search-container" style={{ display: isShowSearch ? 'block' : 'none' }}>
        <div className="content-container">
          <p>
            <img alt="广汽本田" src={createFilePath('/recommend-apply/but03.png')} />
            <input
              onKeyUp={(e) => (customerMobileRef.current.value = e.target.value.replace(/\D/g, ''))}
              maxLength={11}
              ref={searchMobileRef}
              type="text"
              placeholder="输入手机号"
            />
            <span onClick={search}>点击查询</span>
          </p>
          <em className="search-close" onClick={hideSearch} />
        </div>
      </div>
      {resultList?.length > 0 && (
        <div className="result-container" style={{ backgroundImage: `url(${createFilePath('/recommend-apply/bg02.jpg')})` }}>
          <span className="result-title" style={{ backgroundImage: `url(${createFilePath('/recommend-apply/tit_bg.png')})` }}>
            查询结果
          </span>
          <div className="content-container">
            <p>
              员工姓名：<label>{resultList[0].EmployeeName}</label>
            </p>
            <p>
              员工所在单位：<label>{resultList[0].EmployeeCompany}</label>
            </p>
            <p>
              员工手机号码：<label>{resultList[0].EmployeeMobile}</label>
            </p>
            <p>
              员工招聘号：<label>{resultList[0].EmployeeNO}</label>
            </p>
            <div className="list">
              {resultList?.map((item, index) => (
                <div key={index} className="item">
                  <p>
                    推荐购车客户姓名：<label>{item.CustomerName}</label>
                  </p>
                  <p>
                    推荐购车客户手机号码：<label>{item.CustomerMobile}</label>
                  </p>
                  <p>
                    拟购车型：<label>{item.CarName}</label>
                  </p>
                  <p>
                    拟购车特约店：<label>{item.DealerName}</label>
                  </p>
                  <p>
                    申请时间：<label>{moment(item.createdate).format('YYYY-MM-DD HH:mm:ss')}</label>
                  </p>
                </div>
              ))}
            </div>
          </div>
          <em className="close" style={{ backgroundImage: `url(${createFilePath('/recommend-apply/but_bg.png')})` }} onClick={hideResult}>
            返 回
          </em>
        </div>
      )}
    </section>
  );
}
