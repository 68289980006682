import React, { useEffect, useRef, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import { Swiper } from 'swiper';
import { Pagination, Navigation } from 'swiper';
import temp from '../../../temp/config';
import './style.scss';
import Icon from '../../../elements/icon';
import './style_odyssey.scss';
import FulModulDetail from '../../../elements/FullModal';
import { isMobileFun, createFilePath } from '../../../utils/obj-utils';

export default function index(props) {
  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const { fields, sourceList, num, closeImg, styleBox, btnr, btnl, close } = props;
  const [reserveFlag, setReserveFlag] = useState(false);
  // const deployUrlS3 = temp.app.deployUrlS3;
  const isMobile = isMobileFun();
  const imgList = sourceList;
  const [data, _] = useState({
    imgList,
    isMobile
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    const swiper = new Swiper(swiperRef.current, {
      grabCursor: true,
      modules: [Pagination, Navigation],
      slidesPerView: 1,
      grid: {
        fill: 'column',
        rows: 1
      },
      pagination: data?.isMobile ? false : false,
      navigation: {
        prevEl: containerRef.current.querySelector('.navigation-prev'),
        nextEl: containerRef.current.querySelector('.navigation-next'),
        disabledClass: 'navigation-disabled'
      },
      initialSlide: num,
      on: {
        slidePrevTransitionEnd: () => {
          // btnl();
        },
        slideNextTransitionEnd: () => {
          // btnr();
        },
        slideChange: (s) => {
          // console.log(s);
        }
      }
    });
    for (let i = 0; i < swiper.pagination.bullets.length; i++) {
      swiper.pagination.bullets[i].onclick = function () {
        // sendDmp([data?.dmpPointMore[i]]);
      };
    }
    swiper.navigation.prevEl.onclick = function () {
      // let index = swiper.activeIndex + 1;
      btnl();
    };
    swiper.navigation.nextEl.onclick = function () {
      // let index = swiper.activeIndex - 1;
      // imgList[swiper.activeIndex + 1].fields.btnr_dmp, index
      btnr();
    };

    return () => {
      // window.removeEventListener('resize', resize);
      swiper.destroy(true, true);
    };
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const publicPraiseTimeline = gsap.timeline({ pause: true });
    if (!data.isMobile) {
      publicPraiseTimeline.fromTo(swiperRef.current, { x: '100%', opacity: 0 }, { x: '0', opacity: 1 });
    } else {
      publicPraiseTimeline.fromTo(swiperRef.current, { x: '100%' }, { x: '0' });
    }
    const controller = new ScrollMagic.Controller();
    const publicPraiseScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onEnter',
      duration: data.isMobile ? document.documentElement.clientHeight : '80%'
    })
      .on('progress', function (event) {
        publicPraiseTimeline.progress(event.progress);
      })
      .setTween(publicPraiseTimeline)
      .addTo(controller);

    return () => {
      publicPraiseTimeline.kill();
      publicPraiseScene.remove();
      publicPraiseScene.destroy();
      controller.destroy();
    };
  }, [data]);
  const closeModal = () => {
    close(sourceList[swiperRef.current.swiper.activeIndex].fields.close_dmp, sourceList[swiperRef.current.swiper.activeIndex].fields?.name?.value);
  };
  const [isCloseShow, setIsCloseShow] = useState(false);
  useEffect(() => {});
  return (
    <FulModulDetail>
      <section className="swiperDetailsPriceExterior_v4" ref={containerRef}>
        {data ? (
          <div className="PriceExterior_v4">
            <div className="content-container">
              <div className="swiper-container">
                <div className="swiper-support" ref={swiperRef}>
                  <div className="swiper-wrapper">
                    <React.Fragment>
                      {data?.imgList?.map((item, index) => {
                        return (
                          <div className={`swiper-slide ${isCloseShow ? 'isCloseShow' : ''}`} key={index}>
                            <div className="cardDetail">
                              {num !== -1 ? (
                                !isMobile ? (
                                  <div className="securityDetail">
                                    <img
                                      src={`${createFilePath(item.fields.details_img.value)}`}
                                      className={`securityDetailImg securityDetailImg${styleBox}${index}`}
                                      alt="广汽本田"
                                    />
                                    <div className={`securityDetailInner${reserveFlag ? 'T' : ''}`}>
                                      <div dangerouslySetInnerHTML={{ __html: item.fields.details_inner.value?.replaceHtml() }} />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="securityDetail">
                                    <img
                                      src={`${createFilePath(item.fields.details_img.value)}`}
                                      className={`securityDetailImg_m securityDetailImg${styleBox}${index}_m`}
                                      alt="广汽本田"
                                    />
                                    <div className={`securityDetailInner${reserveFlag ? 'T' : ''}`}>
                                      <div dangerouslySetInnerHTML={{ __html: item.fields.details_inner.value?.replaceHtml() }} />
                                    </div>
                                  </div>
                                )
                              ) : (
                                ''
                              )}
                              ;
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  </div>
                </div>
                <div className={`bottomIcon bottomIcon${reserveFlag ? 'T' : ''}`}>
                  <div className="navigation-prev">
                    <Icon name="ArrowLeft" />
                  </div>
                  <div className="navigation-next">
                    <Icon name="ArrowRight" />
                  </div>
                  {data?.isMobile && <div className="swiper-pagination" style={{ bottom: '-90.5vh' }} />}
                </div>
              </div>
              <img
                src={`${createFilePath(closeImg)}`}
                className={`securityDetailClose securityDetailClose${reserveFlag ? 'T' : ''}`}
                onClick={() => {
                  setIsCloseShow(true);
                  closeModal();
                  // setReserveFlag(false);
                }}
                alt="广汽本田"
              />
            </div>
          </div>
        ) : null}
      </section>
    </FulModulDetail>
  );
}
