import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, pcTorem, mbTorem, getQueryString, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import { swiperAnimateCache, swiperAnimate, clearSwiperAnimate } from '../../utils/swiper.animate1.0.3.min';
import FullVideo from '../../elements/FullVideo';
import FullModalDetail from '../../elements/FullModalDetail_v1';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const [windowHeight, setWindowHeight] = useState(400);
  const [swipertypeflag, setSwipertypeflag] = useState(true);
  const swiperRef = useRef(null);
  const tabsswiperRef = useRef(null);
  const [showcountnum, setShowcountnum] = useState(false);
  const [flag, setFlag] = useState(false);
  const [swiperflag, setSwiperflag] = useState(false);
  const isMobile = isMobileFun();
  // const hosturl = temp.app.deployUrlS3;
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailindex, setShowDetailindex] = useState(1);
  const [tabswiperIndex, setTabswiperIndex] = useState(0);
  const [loadingvideo, setLoadingvideo] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [showFooter, setShowFooter] = useState(true);
  // if (isMobile) {
  //   var accordhosturl = hosturl + '/accord/v2/mobile';
  // } else {
  //   var accordhosturl = hosturl + '/accord/v2/pc';
  // }
  const id = props?.params?.pagename;
  const videodata = {
    detail_video: createFilePath('/accord/v2/pc/technology/video.mp4?20240701'),
    close_icon_dmp: {
      type: 'click',
      point: 'price_tech-link-video-close',
      value1: ''
    }
  };
  const videodata2 = {
    detail_video: createFilePath('/accord/v2/pc/technology/video2.mp4?20240701'),
    close_icon_dmp: {
      type: 'click',
      point: 'price_tech-video-close',
      value1: ''
    }
  };
  const data = {
    id
  };
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
  };
  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      const anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: 'start', behavior: 'auto' });
      }
    }
  };
  const openVideo = (data) => {
    setLoadingvideo(data);
    setFlag(true);
  };
  const opendetail = (index) => {
    window.Dmp.clickAction('price_tech-btn1-detail');
    setShowDetail(true);
  };
  const changedetailtab = (index) => {
    setShowDetailindex(index);
    if (index === 2) {
      window.Dmp.clickAction('price_tech-btn1-sensing');
    } else {
      window.Dmp.clickAction('price_tech-btn1-sensing360');
    }
  };
  const swiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
    setTabswiperIndex(index);
  };

  useEffect(() => {
    if (
      window.location.href.indexOf('accord-show') !== -1 ||
      getQueryString('source') === 'app' ||
      window.location.href.indexOf('app/vehicles/accord') !== -1
    ) {
      setShowFooter(false);
    }
  }, []);
  return (
    <>
      <div className={!showFooter ? 'modules-grid technology noheader' : 'modules-grid technology'}>
        <div className="triggerstart" id="technology2"></div>
        <Controller>
          <Scene
            triggerHook={'onLeave'}
            duration={!isMobile ? windowHeight * 3 : windowHeight * 2}
            pin={true}
            reverse={true}
            triggerElement={'#technology2'}
          >
            {(progress, event) => {
              return (
                <div>
                  <div className="technologyb" id={data?.id}>
                    <Timeline
                      totalProgress={progress}
                      paused
                      target={
                        <div className="griditem griditem1">
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/technology/technologyb1.jpg')
                                : createFilePath('/accord/v2/pc/technology/technologyb1.jpg')
                            }
                          />
                          {!isMobile && (
                            <img
                              alt="广汽本田"
                              className="car"
                              src={
                                isMobile
                                  ? createFilePath('/accord/v2/mobile/technology/technologyb1_car.png')
                                  : createFilePath('/accord/v2/pc/technology/technologyb1_car.png')
                              }
                            />
                          )}
                          <img
                            alt="广汽本田"
                            className="t-img"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/technology/technologyb1_t.png')
                                : createFilePath('/accord/v2/pc/technology/technologyb1_t.png')
                            }
                          />
                          <div className="text">
                            <div
                              className="t ani"
                              swiper-animate-effect="animate__fadeInDownBig"
                              swiper-animate-duration="1.5s"
                              swiper-animate-delay="0s"
                            >
                              <span>智驾</span>智慧驾辅科技
                            </div>
                            <div className="sub">
                              <div className="en">MIND-BLOWING ENJOYMENT</div>
                              <div className="cn">一次思绪任意放飞的安心享受</div>
                            </div>
                            {isMobile && (
                              <div
                                className="btn"
                                onClick={() => {
                                  openVideo(videodata2);
                                  window.Dmp.clickAction('price_tech-video-open');
                                }}
                              >
                                查看视频
                              </div>
                            )}
                          </div>
                          {!isMobile ? (
                            <div
                              className="videomin1"
                              onClick={() => {
                                openVideo(videodata2);
                                window.Dmp.clickAction('price_tech-video-open');
                              }}
                            >
                              <img alt="广汽本田" src={createFilePath('/accord/v2/pc/technology/video2.gif')} className="video" />
                            </div>
                          ) : (
                            ' '
                          )}
                        </div>
                      }
                    >
                      <Tween to={{ opacity: 0 }} position={0.1} duration={!isMobile ? 0.5 : 0.9} />
                      <Tween to={{ zIndex: 0 }} position={!isMobile ? 0.4 : 0.6} />
                    </Timeline>
                    {!isMobile && (
                      <Timeline
                        totalProgress={progress}
                        paused
                        target={
                          <div
                            className={'showvideo'}
                            onClick={() => {
                              openVideo(videodata2);
                              window.Dmp.clickAction('price_tech-video-open');
                            }}
                          >
                            <video
                              src={createFilePath('/accord/v2/pc/technology/video2.mp4?20240701')}
                              autoPlay="autoplay"
                              muted={true}
                              className="video"
                              loop
                            />
                          </div>
                        }
                      >
                        <Tween to={{ opacity: 0 }} position={0.8} duration={0.2} />
                        <Tween to={{ zIndex: 1 }} position={0.6} />
                      </Timeline>
                    )}

                    <div className="modules-swiper-out">
                      <div className="next" data-html-item="price_tech-btnr"></div>
                      <div className="prev" data-html-item="price_tech-btnl"></div>
                      <Swiper
                        ref={swiperRef}
                        slidesPerView={1}
                        className="modules-swiper"
                        spaceBetween={0}
                        initialSlide={0}
                        observer={true}
                        resizeObserver={true}
                        updateOnWindowResize={true}
                        modules={[Navigation]}
                        navigation={{
                          prevEl: '.modules-swiper-out .prev',
                          nextEl: '.modules-swiper-out .next',
                          disabledClass: 'disabled'
                        }}
                        onInit={(e) => {
                          clearSwiperAnimate(e);
                          swiperAnimateCache(e);
                        }}
                        onSlideChangeTransitionEnd={(e) => {
                          swiperAnimate(e);
                          if (tabsswiperRef.current) {
                            tabsswiperRef.current.swiper.slideTo(e.activeIndex);
                            var allBoxes = tabsswiperRef.current.swiper.slides;
                            for (var i = 0; i < allBoxes.length; i++) {
                              allBoxes[i].className = allBoxes[i].className.replace('on', ' ');
                              allBoxes[e.activeIndex].classList.add('on');
                            }
                          }
                          setSwiperIndex(e.activeIndex);
                        }}
                      >
                        <SwiperSlide className={'griditem griditem2'}>
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/technology/technologyb2.jpg')
                                : createFilePath('/accord/v2/pc/technology/technologyb2.jpg')
                            }
                          />
                          <img
                            alt="广汽本田"
                            className="icon"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/technology/technologyb1_t.png')
                                : createFilePath('/accord/v2/pc/technology/technologyb1_t.png')
                            }
                          />
                          <div className="text">
                            <div className="p">
                              采用有效水平角度宽达约100°的前视传感器摄像头，前后方合计搭载了5个毫米波雷达。通过广角雷达、摄像头的使用以及感应技术的提升，进一步扩大对周边环境的监测范围，扩大安全驾驶辅助系统使用场景，向驾驶者提供更加舒适、安心的驾驶辅助支持。
                            </div>
                            <div className="control">
                              <div
                                className="btn"
                                onClick={() => {
                                  opendetail();
                                }}
                              >
                                展开详情
                              </div>
                              <div
                                className="btn btn1"
                                data-html-item="price_tech-immd"
                                data-url="https://i-mmd-lab.ghac.cn/"
                                onClick={() => {
                                  window.open('https://i-mmd-lab.ghac.cn/');
                                }}
                              >
                                探索先进技术
                              </div>
                            </div>
                          </div>
                          {!isMobile && (
                            <img
                              alt="广汽本田"
                              className="t-img"
                              src={
                                isMobile
                                  ? createFilePath('/accord/v2/mobile/technology/technologyb1_t.png')
                                  : createFilePath('/accord/v2/pc/technology/technologyb1_t.png')
                              }
                            />
                          )}
                        </SwiperSlide>
                        <SwiperSlide className={'griditem griditem3 '}>
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/technology/technologyb3.jpg')
                                : createFilePath('/accord/v2/pc/technology/technologyb3.jpg')
                            }
                          />
                          <div className="sub ">
                            <div className="en">SAFETY IN ANY DIRECTION</div>
                            <div className="cn">安心 填满在每一个角落</div>
                          </div>
                          <div className="list ">
                            <div className="li">10安全气囊</div>
                            <div className="p">
                              全车配置10个安全气囊，其中新增前排膝部安全气囊和后排侧部安全气囊。
                              <br />
                              安全出行，让保护来的更加周全。
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className={'griditem griditem4'}>
                          <img
                            alt="广汽本田"
                            className="bg"
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/technology/technologyb4.jpg')
                                : createFilePath('/accord/v2/pc/technology/technologyb4.jpg')
                            }
                          />
                          {!isMobile && (
                            <img
                              alt="广汽本田"
                              className="car"
                              src={
                                isMobile
                                  ? createFilePath('/accord/v2/mobile/technology/technologyb4_car.png')
                                  : createFilePath('/accord/v2/pc/technology/technologyb4_car.png')
                              }
                            />
                          )}
                          <div className="sub ">
                            <div className="en">SUBSTANTIAL BODY STRUCTURE GUARDS YOUR ADVENTURE </div>
                            <div className="cn">有强度 出行更有底气</div>
                          </div>
                          <div className="list">
                            <div className="li">高刚性车身</div>
                            <div className="p">
                              在保证汽车强度与安全性的前提下，在车辆碰撞安全领域也达到了国际标准。
                              <br /> 高刚性车身结构，在全方位应对碰撞安全的基础上，为驾驶者带来更加安心的驾驶感受。
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                      <Swiper
                        ref={tabsswiperRef}
                        slidesPerView={3}
                        className="technologytabs"
                        spaceBetween={0}
                        initialSlide={0}
                        observer={true}
                        resizeObserver={true}
                        updateOnWindowResize={true}
                        navigation={false}
                        // centeredSlides={true}
                        onSlideChangeTransitionEnd={(e) => {}}
                      >
                        <SwiperSlide
                          className={tabswiperIndex === 0 ? 'item on' : 'item'}
                          onClick={() => {
                            swiperslideTo(0);
                            window.Dmp.clickAction('price_tech-btn1');
                          }}
                        >
                          {!isMobile ? (
                            <>Honda SENSING 360</>
                          ) : (
                            <>
                              Honda
                              <br />
                              SENSING 360
                            </>
                          )}
                        </SwiperSlide>
                        <SwiperSlide
                          className={tabswiperIndex === 1 ? 'item on' : 'item'}
                          onClick={() => {
                            swiperslideTo(1);
                            window.Dmp.clickAction('price_tech-btn2');
                          }}
                        >
                          安全气囊
                        </SwiperSlide>
                        <SwiperSlide
                          className={tabswiperIndex === 2 ? 'item on' : 'item'}
                          onClick={() => {
                            swiperslideTo(2);
                            window.Dmp.clickAction('price_tech-btn3');
                          }}
                        >
                          高刚性车身
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                </div>
              );
            }}
          </Scene>
        </Controller>
      </div>
      {showDetail && (
        <>
          {showDetailindex === 1 && (
            <FullModalDetail>
              <div className="technologydetailprop">
                <div
                  className="close"
                  onClick={() => {
                    setShowDetail(false);
                    window.Dmp.clickAction('price_tech-btn1-close');
                  }}
                ></div>
                <div className="tabs">
                  <div className={showDetailindex === 1 ? 'item on' : 'item'}>Honda SENSING 360</div>
                  <div
                    className={showDetailindex === 2 ? 'item on' : 'item'}
                    onClick={() => {
                      changedetailtab(2);
                    }}
                  >
                    Honda SENSING
                  </div>
                </div>
                <div className="con">
                  <div className="left">
                    <div className="img">
                      <img
                        alt="广汽本田"
                        src={
                          isMobile
                            ? createFilePath('/accord/v2/mobile/technology/technologyb2_pic1.jpg')
                            : createFilePath('/accord/v2/pc/technology/technologyb2_pic1.jpg')
                        }
                      />
                    </div>
                    <div className="c">
                      <div className="t">FCTW穿行先知</div>
                      <div className="p">
                        全新搭载FCTW穿行先知功能。在十字或T字路口，车辆起步或低速行驶时，边角雷达智能感应从左右前方盲区靠近的车辆，通过抬头显示系统和彩色智能屏互联系统上显示附带箭头方向的警报、声音示警，同时仪表盘也会呈现警报标识，以此来通知驾驶者，避免碰撞的发生。
                      </div>
                    </div>
                    <div className="img">
                      <img
                        alt="广汽本田"
                        src={
                          isMobile
                            ? createFilePath('/accord/v2/mobile/technology/technologyb2_pic2.jpg')
                            : createFilePath('/accord/v2/pc/technology/technologyb2_pic2.jpg')
                        }
                      />
                    </div>
                    <div className="c">
                      <div className="t">ACC Cornering Speed Assist 弯道智驾</div>
                      <div className="p">
                        全新搭载CSA弯道车速调整功能，在ACC主动巡航控制系统的基础上进一步优化。转弯时，通过车头摄像头拍摄路况画面提取前方的车道曲率，自动调整车速(可切换3个档位)，减少驾驶者在拐进弯道过程中刹车操作频率，从而降低驾驶员驾驶负荷。
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="c">
                      <div className="t">LCCM变道后卫</div>
                      <div className="p">
                        全新搭载LCCM变道后卫安全辅助功能，在变换车道时，如果雷达监测到与后视镜盲区接近车辆存在碰撞风险，系统将会发出警报音、亮起盲点信号警告灯和仪表盘提示，并主动辅助方向盘操作，避免碰撞。
                      </div>
                    </div>
                    <div className="c">
                      <div className="t">ALCA变道智驾</div>
                      <div className="p">
                        全新搭载ALCA变道智驾安全辅助功能，高速路或汽车专用道路上，当车速大于70km/h并且ACC和LKAS同时运作时，一旦车辆开启转向灯，车身雷达和摄像头将监测周围安全状况，系统辅助方向盘进行变道操作，大幅减轻长途行驶所带来的驾驶负担。
                      </div>
                    </div>
                    <div className="img">
                      <img
                        alt="广汽本田"
                        src={
                          isMobile
                            ? createFilePath('/accord/v2/mobile/technology/technologyb2_pic3.jpg')
                            : createFilePath('/accord/v2/pc/technology/technologyb2_pic3.jpg')
                        }
                      />
                    </div>
                    <div className="c">
                      <div className="t">CMBS防撞智护</div>
                      <img
                        alt="广汽本田"
                        className="img1"
                        src={isMobile ? createFilePath('/accord/v2/mobile/technology/CMBS.png') : createFilePath('/accord/v2/pc/technology/CMBS.png')}
                      />
                      <div className="p">具有左转直行避免碰撞功能、检测横向穿行物体、检测黑暗中行人等功能。</div>
                    </div>
                  </div>
                </div>
              </div>
            </FullModalDetail>
          )}
          {showDetailindex === 2 && (
            <FullModalDetail>
              <div className="technologydetailprop">
                <div
                  className="close"
                  onClick={() => {
                    setShowDetail(false);
                    window.Dmp.clickAction('price_tech-btn1-close');
                  }}
                ></div>
                <div className="tabs">
                  <div
                    className={showDetailindex === 1 ? 'item on' : 'item'}
                    onClick={() => {
                      changedetailtab(1);
                    }}
                  >
                    Honda SENSING 360
                  </div>
                  <div className={showDetailindex === 2 ? 'item on' : 'item'}>Honda SENSING</div>
                </div>
                <div className="con">
                  <div className="left">
                    <div className="img">
                      <img
                        alt="广汽本田"
                        src={
                          isMobile
                            ? createFilePath('/accord/v2/mobile/technology/technologyb2_pic4.jpg')
                            : createFilePath('/accord/v2/pc/technology/technologyb2_pic4.jpg')
                        }
                      />
                    </div>
                    <div className="c">
                      <div className="t">LKAS车道保持辅助系统</div>
                      <div className="p">
                        在原有LKAS车道保持辅助系统的基础上进一步升级，即使在难以检测到行车线的场景中，系统也能够通过摄像头和雷达，检测前车的轨迹，持续辅助方向盘操作，使车辆维持在行车道路中央。
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="img">
                      <img
                        alt="广汽本田"
                        src={
                          isMobile
                            ? createFilePath('/accord/v2/mobile/technology/technologyb2_pic5.jpg')
                            : createFilePath('/accord/v2/pc/technology/technologyb2_pic5.jpg')
                        }
                      />
                    </div>
                    <div className="c">
                      <div className="t">BSI盲区监测系统</div>
                      <div className="p">
                        变换车道时，后侧方出现其他车辆，BSI盲区监测系统通过警报音和后视镜指示灯提醒驾驶者注意后方来车，进一步提高驾驶安全性。
                      </div>
                    </div>
                  </div>
                  <div className="Detailpageswiper">
                    <Swiper
                      slidesPerView={!isMobile ? 3 : 1}
                      className="modules-swiper"
                      spaceBetween={0}
                      initialSlide={0}
                      observer={true}
                      resizeObserver={true}
                      updateOnWindowResize={true}
                      modules={[Navigation]}
                      navigation={{
                        prevEl: '.Detailpageswiper .prev',
                        nextEl: '.Detailpageswiper .next',
                        disabledClass: 'disabled'
                      }}
                    >
                      <SwiperSlide className={'item'}>
                        <div className="in">
                          <div className="nums">
                            <span>1</span>
                          </div>
                          <div className="n">
                            CMBS <br />
                            碰撞缓解制动系统
                          </div>
                          <div className="p">具有左转直行避免碰撞功能、检测横向穿行物体、检测黑暗中行人等功能。</div>
                          <img
                            alt="广汽本田"
                            className="img1"
                            src={
                              isMobile ? createFilePath('/accord/v2/mobile/technology/CMBS.png') : createFilePath('/accord/v2/pc/technology/CMBS.png')
                            }
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'item'}>
                        <div className="in">
                          <div className="nums">
                            <span>2</span>
                          </div>
                          <div className="n">
                            RDM <br />
                            车道偏移抑制系统
                          </div>
                          <div className="p">
                            当车辆较大幅度偏移车道时， 系统将施加制动，以防止车辆偏离出车道。具有普通道路车道偏移抑制功能，作用范围约30km/h-180km/h。
                          </div>
                          <img
                            alt="广汽本田"
                            className="img1"
                            src={
                              isMobile ? createFilePath('/accord/v2/mobile/technology/RDM.png') : createFilePath('/accord/v2/pc/technology/RDM.png')
                            }
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'item'}>
                        <div className="in">
                          <div className="nums">
                            <span>3</span>
                          </div>
                          <div className="n">
                            TSR
                            <br />
                            交通标识 智能识别系统
                          </div>
                          <div className="p">通过单眼摄像头检测道路标示 （限速、禁止超车），显示标示内容，提醒驾驶者注意。</div>
                          <img
                            alt="广汽本田"
                            className="img1"
                            src={
                              isMobile ? createFilePath('/accord/v2/mobile/technology/TSR.png') : createFilePath('/accord/v2/pc/technology/TSR.png')
                            }
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'item'}>
                        <div className="in">
                          <div className="nums">
                            <span>4</span>
                          </div>
                          <div className="n">
                            TJA <br />
                            交通拥堵辅助系统
                          </div>
                          <div className="p">
                            通过摄像头和雷达，感应前方车辆和行车线，辅助方向盘转向操作以及车线维持，有效缓解在低速领域(0km/h-70km/h)的驾驶负荷。在拥堵路段也能轻松驾驭，为驾驶带来更多乐趣。
                          </div>
                          <img
                            alt="广汽本田"
                            className="img1"
                            src={
                              isMobile ? createFilePath('/accord/v2/mobile/technology/TJA.png') : createFilePath('/accord/v2/pc/technology/TJA.png')
                            }
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'item'}>
                        <div className="in">
                          <div className="nums">
                            <span>5</span>
                          </div>
                          <div className="n">
                            LWC <br />
                            盲点显示系统
                          </div>
                          <div className="p">
                            在右后视镜安装摄像头，便可以在显示屏上看到位于视野死角范围内的车辆。通过该技术，使右后视镜由20度提高到80度，让驾驶安全更有保障。
                          </div>
                          <img
                            alt="广汽本田"
                            className="img1"
                            src={
                              isMobile ? createFilePath('/accord/v2/mobile/technology/LWC.png') : createFilePath('/accord/v2/pc/technology/LWC.png')
                            }
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'item'}>
                        <div className="in">
                          <div className="nums">
                            <span>6</span>
                          </div>
                          <div className="n">
                            MVC <br />
                            360°全景 影像系统
                          </div>
                          <div className="p">
                            4个高感光摄像头，分布前后左右，全方位无盲区监控；
                            <br />
                            6大行车视野界面，直观、有效避免多种行车突发情况；
                            <br />
                            360°无缝显示，高清质感屏幕，确保周围环境一览无遗；
                            <br />
                            智能辅助线提示功能，有助准确判断距离，泊车更加轻松。
                          </div>
                          <img
                            alt="广汽本田"
                            className="img2"
                            src={
                              isMobile ? createFilePath('/accord/v2/mobile/technology/360.png') : createFilePath('/accord/v2/pc/technology/360.png')
                            }
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'item'}>
                        <div className="in">
                          <div className="nums">
                            <span>7</span>
                          </div>
                          <div className="n">
                            带LSF(低速跟随系统的) <br />
                            ACC主动巡航控制系统
                          </div>
                          <div className="p">
                            基于雷达和摄像头的共同感应识别，自动跟随前车至停止，并重新启动，大大缓解在拥堵路段以及高速公路上行驶时的驾驶疲惫。同时，通过进一步抑制刹车次数，实现更流畅的减速和停止，提升驾驶的舒心体验。
                          </div>
                          <img
                            alt="广汽本田"
                            className="img1"
                            src={
                              isMobile ? createFilePath('/accord/v2/mobile/technology/ACC.png') : createFilePath('/accord/v2/pc/technology/ACC.png')
                            }
                          />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <div className="prev" data-html-item="price_tech-btn1-btnl"></div>
                    <div className="next" data-html-item="price_tech-btn1-btnr"></div>
                  </div>
                </div>
              </div>
            </FullModalDetail>
          )}
        </>
      )}
      {flag && <FullVideo fields={loadingvideo} close={() => setFlag(false)} />}
    </>
  );
}
