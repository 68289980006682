import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import temp from '../../temp/config';
import { getPrefixField, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { fields, eventRef } = props;
  const containerRef = useRef(null);
  const text1_left1Ref = useRef(null);
  const text1_left2Ref = useRef(null);
  const text1_rightRef = useRef(null);
  const img2Ref = useRef(null);
  const img3Ref = useRef(null);
  const scene2TextRef = useRef(null);
  const scene3TextRef = useRef(null);
  const scene3Ref = useRef(null);
  const text2LeftText1Ref = useRef(null);
  const text2LeftText2Ref = useRef(null);
  const text2RightRef = useRef(null);
  const text2RightText1Ref = useRef(null);
  const text2RightText2Ref = useRef(null);
  const scene2Ref = useRef(null);
  let once = true;
  // const deployUrlS3 = temp.app.deployUrlS3;
  const isMobile = isMobileFun();
  const img1 = `${createFilePath(fields[getPrefixField('img1', isMobile)]?.value)}`;
  const img2 = `${createFilePath(fields[getPrefixField('img2', isMobile)]?.value)}`;
  const img3 = `${createFilePath(fields[getPrefixField('img3', isMobile)]?.value)}`;
  const img4 = `${createFilePath(fields[getPrefixField('img4', isMobile)]?.value)}`;
  const scene1Title1 = fields[getPrefixField('scene1_title1', isMobile)]?.value;
  const scene1Title2 = fields[getPrefixField('scene1_title2', isMobile)]?.value;
  const scene1Describe = fields[getPrefixField('scene1_describe', isMobile)]?.value;
  const scene2Title1 = fields[getPrefixField('scene2_title1', isMobile)]?.value;
  const scene2Describe1 = fields[getPrefixField('scene2_describe1', isMobile)]?.value;
  const scene2Title2 = fields[getPrefixField('scene2_title2', isMobile)]?.value;
  const scene2Describe2 = fields[getPrefixField('scene2_describe2', isMobile)]?.value;
  const scene3Title = fields[getPrefixField('scene3_title', isMobile)]?.value;
  const scene3Describe = fields[getPrefixField('scene3_describe', isMobile)]?.value;
  const [data, _] = useState({
    img1,
    img2,
    img3,
    img4,
    isMobile,
    scene1Title1,
    scene1Title2,
    scene1Describe,
    scene2Title1,
    scene2Title2,
    scene2Describe1,
    scene2Describe2,
    scene3Title,
    scene3Describe
  });
  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields?.sectionDmp);
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : '';
  };
  useEffect(() => {
    if (!data) {
      return;
    }
    const exteriorTimeline = gsap.timeline({ pause: true });
    const scene1Timeline = gsap.timeline();
    if (!data.isMobile) {
      exteriorTimeline
        .fromTo(img3Ref.current, { y: '-61.5vh' }, { y: '0', delay: 0.1 })
        .fromTo(img2Ref.current, { y: '100vh' }, { y: '0' }, '<')
        .fromTo(scene2TextRef.current, { y: '38.5vh' }, { y: '0' }, '<')
        .fromTo(text2LeftText1Ref.current, { x: '-100vw' }, { x: '0' }, '<')
        .fromTo(text2LeftText2Ref.current, { x: '100vw' }, { x: '0' }, '<')
        .fromTo(text2RightRef.current, { x: '100vw' }, { x: '0' }, '<')
        .fromTo(scene3Ref.current, { y: '100vh' }, { y: '0', delay: 0.1 })
        .fromTo(scene3TextRef.current, { opacity: '0' }, { opacity: '1' });
    } else {
      exteriorTimeline
        .fromTo(scene2Ref.current, { y: '100vh' }, { y: '0', delay: 0.1 })
        .fromTo(img2Ref.current, { x: '-100vw' }, { x: '0', duration: 0.25 }, '<')
        .fromTo(text2LeftText1Ref.current, { x: '-100vw' }, { x: '0' }, '<')
        .fromTo(text2LeftText2Ref.current, { x: '100vw' }, { x: '0' }, '<')
        .fromTo(img3Ref.current, { x: '100vw' }, { x: '0', duration: 0.5 }, '<')
        .fromTo(text2RightText1Ref.current, { x: '100vw' }, { x: '0' }, '<')
        .fromTo(text2RightText2Ref.current, { x: '-100vw' }, { x: '0' }, '<')
        .fromTo(scene3Ref.current, { y: '100vh' }, { y: '0', delay: 0.1 })
        .fromTo(scene3TextRef.current, { opacity: '0' }, { opacity: '1', delay: 0.2 }, '<');
    }
    const controller = new ScrollMagic.Controller();
    const exteriorScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onLeave',
      duration: data.isMobile ? document.documentElement.clientHeight * 3 : '300%'
    })
      .on('progress', function (event) {
        if (once) {
          once = false;
          if (!isMobile) {
            scene1Timeline
              .fromTo(text1_left1Ref.current, { x: '10vw' }, { x: '0', duration: 1 })
              .fromTo(text1_left2Ref.current, { x: '-10vw' }, { x: '0', duration: 1 }, '<')
              .fromTo(text1_rightRef.current, { y: '10vh', opacity: 0 }, { y: '0', opacity: 0.8, duration: 1 }, '<');
          } else {
            scene1Timeline
              .fromTo(text1_left1Ref.current, { x: '30vw' }, { x: '0', duration: 1 })
              .fromTo(text1_left2Ref.current, { x: '-30vw' }, { x: '0', duration: 1 }, '<')
              .fromTo(text1_rightRef.current, { y: '20vh', opacity: 0 }, { y: '0', opacity: 0.8, duration: 1 }, '<');
          }
        }
        exteriorTimeline.progress(event.progress);
      })
      .setPin(containerRef.current)
      .setTween(exteriorTimeline)
      .addTo(controller);

    return () => {
      scene1Timeline.kill();
      exteriorTimeline.kill();
      exteriorScene.remove();
      exteriorScene.destroy();

      controller.destroy();
    };
  }, []);

  return (
    <section>
      <div className="exterior_v6" ref={containerRef} id={props?.params?.pagename}>
        <img alt="广汽本田" src={data.img1} />
        <div className="scene1">
          <div className="text1_left">
            <div className="text1_left_title1" ref={text1_left1Ref} dangerouslySetInnerHTML={{ __html: data.scene1Title1?.replaceHtml() }} />
            <div className="text1_left_title2" ref={text1_left2Ref} dangerouslySetInnerHTML={{ __html: data.scene1Title2?.replaceHtml() }} />
          </div>
          <div className="text1_right" ref={text1_rightRef} dangerouslySetInnerHTML={{ __html: data.scene1Describe?.replaceHtml() }} />
        </div>
        <div className="scene2" ref={scene2Ref}>
          <img alt="广汽本田" className="scene2_img1" src={data.img2} ref={img2Ref} />
          <img alt="广汽本田" className="scene2_img2" src={data.img3} ref={img3Ref} />
          {data.isMobile ? (
            <>
              <div className="scene2_m_text1">
                <div
                  className="scene2_m_text1_title"
                  ref={text2LeftText1Ref}
                  dangerouslySetInnerHTML={{ __html: data.scene2Title1?.replaceHtml() }}
                />
                <div
                  className="scene2_m_text1_describe"
                  ref={text2LeftText2Ref}
                  dangerouslySetInnerHTML={{ __html: data.scene2Describe1?.replaceHtml() }}
                />
              </div>
              <div className="scene2_m_text2">
                <div
                  className="scene2_m_text2_title"
                  ref={text2RightText1Ref}
                  dangerouslySetInnerHTML={{ __html: data.scene2Title2?.replaceHtml() }}
                />
                <div
                  className="scene2_m_text2_describe"
                  ref={text2RightText2Ref}
                  dangerouslySetInnerHTML={{ __html: data.scene2Describe2?.replaceHtml() }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="scene2_text" ref={scene2TextRef}>
                <div className="text2_left">
                  <div className="text2_left_text1" ref={text2LeftText1Ref} dangerouslySetInnerHTML={{ __html: data.scene2Title1?.replaceHtml() }} />
                  <div
                    className="text2_left_text2"
                    ref={text2LeftText2Ref}
                    dangerouslySetInnerHTML={{ __html: data.scene2Describe1?.replaceHtml() }}
                  />
                </div>
                <div className="text2_right" ref={text2RightRef}>
                  <div className="text2_right_text1" dangerouslySetInnerHTML={{ __html: data.scene2Describe2?.replaceHtml() }} />
                  <div className="text2_right_text2" dangerouslySetInnerHTML={{ __html: data.scene2Title2?.replaceHtml() }} />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="scene3" ref={scene3Ref}>
          <img alt="广汽本田" src={data.img4} />
          <div className="scene3_text" ref={scene3TextRef}>
            <div className="text3_top" dangerouslySetInnerHTML={{ __html: data.scene3Title?.replaceHtml() }} />
            <div className="text3_bottom" dangerouslySetInnerHTML={{ __html: data.scene3Describe?.replaceHtml() }} />
          </div>
        </div>
      </div>
    </section>
  );
}
