/* eslint-disable quotes */
import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { EffectFade, Pagination, Navigation, Autoplay } from 'swiper';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import './style.scss';
import HeroLanding from '../../elements/HeroLanding';
import { convertStylesStringToObject, sendDmp, getPrefixField, createFilePath, isMobileFun } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';

export default function Banner(props) {
  const { fields, eventRef, params } = props;
  let autoplay = false;
  const rootEl = useRef(null);
  const isMobile = isMobileFun();
  const [tabNum, setTabNum] = useState(0);
  const tabs = fields[getPrefixField('tabs', isMobile)];
  const bannerItem = tabs[tabNum].fields?.bannerItem;
  const className = tabs[tabNum].fields?.className?.value;
  const countDown = tabs[tabNum].fields?.countDown?.value;
  const styleHeight = convertStylesStringToObject(isMobile ? tabs[tabNum].fields?.mStyleHeight?.value : tabs[tabNum].fields?.styleHeight?.value);
  const navigation = tabs[tabNum].fields?.navigation?.fields?.k?.value === 'true';
  const maskImage = createFilePath(tabs[getPrefixField('maskImage', isMobile)]?.value);
  const showMask = tabs[getPrefixField('showMask', isMobile)]?.value;
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const secondPageRef = useRef(null);
  const sectionDmp = fields?.sectionDmp[0]?.name;
  const placeHolderRoute = fields.placeHolderRoute.value;
  const defaultTab = fields.default_tab_name.value;
  const dmp = tabs[tabNum].fields?.dmp?.value;
  const isBlackBtn = tabs[tabNum].fields?.blackBtn?.value;
  const pagename = props?.page?.placeholders['jss-main'][0]?.params?.pagename;
  const [data, _] = useState({
    isMobile,
    tabs,
    sectionDmp,
    placeHolderRoute,
    defaultTab
  });
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      data.tabs.map((item, index) => {
        let tabArr = item.fields?.tab_name?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(data.defaultTab.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabNum(tabInd < 0 ? (defaultTabInd < 0 ? tabNum : defaultTabInd) : tabInd);
      } else {
        setTabNum(defaultTabInd < 0 ? tabNum : defaultTabInd);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const sendEvent = () => {
    window.Dmp.sectionAction(sectionDmp || 'home');
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : 'headerNav';
    maskAnimation();
  };

  const maskAnimation = () => {
    const classList = rootEl?.current?.getElementsByClassName('mask-cover')[0]?.classList;
    if (showMask && Array(classList)?.indexOf('mask-cover-animation') === -1) {
      classList?.add('mask-cover-animation');
      rootEl?.current?.getElementsByClassName('mask-image')[0]?.classList?.add('mask-image-animation');
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', function () {
      if (countDown && document.visibilityState === 'visible') {
        let circleBoxList = document.querySelectorAll('.swiper-circle-box-active');
        for (let i = 0; i < circleBoxList.length; i++) {
          circleBoxList[i].classList.remove('swiper-circle-box-active');
          void circleBoxList[i].offsetWidth;
          circleBoxList[i].classList.add('swiper-circle-box-active');
        }
      }
    });
  }, []);
  return (
    <React.Fragment>
      <Placeholder name={data?.placeHolderRoute} rendering={route} page={route} />
      <section className="exteriorBanner" id={props?.params?.pagename || 'headerNav'}>
        <div className="tab_list">
          {data.tabs &&
            data.tabs.length > 0 &&
            data.tabs.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {index !== 0 && <div className="split_line" dangerouslySetInnerHTML={{ __html: fields?.split_line.value?.replaceHtml() }} />}
                  <div
                    className={`tab_item ${index === tabNum ? 'tab_disabled' : ''}`}
                    onClick={() => {
                      setTabNum(index);
                      sendDmp(item?.fields?.tabDmp);
                    }}
                  >
                    <div
                      className={index === tabNum ? 'tabContainer_active' : 'tabContainer'}
                      dangerouslySetInnerHTML={{ __html: item.fields?.tab?.value?.replaceHtml() }}
                    />
                  </div>
                </React.Fragment>
              );
            })}
        </div>
        {tabNum > -1 ? (
          <div className={`HeaderBanner ${className}`} ref={rootEl} style={styleHeight}>
            <Swiper
              // className={!isMobile ? 'bannerList_pc' : 'bannerList_mobile' isBlackBtn ? 'blackBtn' : '' }
              className={[!isMobile ? 'bannerList_pc' : 'bannerList_mobile', isBlackBtn ? 'blackBtn' : ''].join(' ')}
              effect={'fade'}
              fadeEffect={{ crossFade: true }}
              autoplay={{ delay: 3000, autoPlay: true, disableOnInteraction: false }}
              navigation={navigation}
              slidesPerView={1}
              slidesPerGroup={1}
              modules={[EffectFade, Pagination, Navigation, Autoplay]}
              onSlideChangeTransitionStart={() => {
                if (autoplay) {
                  autoplay = false;
                }
              }}
              onInit={(e) => {
                let circleBoxList = document.querySelectorAll('.swiper-circle-box-active');
                if (circleBoxList && circleBoxList.length > 0) {
                  circleBoxList[0].classList.remove('swiper-circle-box-active');
                  e.autoplay.stop();
                  setTimeout(() => {
                    void circleBoxList[0].offsetWidth;
                    circleBoxList[0].classList.add('swiper-circle-box-active');
                    e.autoplay.start();
                  }, 3000);
                }
              }}
              onSlidePrevTransitionEnd={(e) => {
                if (autoplay) {
                  return;
                }
              }}
              onSlideNextTransitionEnd={(e) => {
                if (autoplay) {
                  autoplay = false;
                  return;
                }
              }}
              onAutoplay={(e) => {
                autoplay = true;
              }}
              pagination={
                countDown
                  ? {
                      bulletClass: 'swiper-circle-box',
                      bulletActiveClass: 'swiper-circle-box-active',
                      clickable: true,
                      renderBullet: function (index, className) {
                        let radius = 12;
                        let currentProgress = 100;
                        let dash1 = (((radius - 2) * 2 * Math.PI) / 100) * currentProgress;
                        let dash2 = (radius - 2) * 2 * Math.PI + 'px';
                        return (
                          '<span class=' +
                          className +
                          '><svg width=' +
                          radius * 2 +
                          ' height=' +
                          radius * 2 +
                          '><circle cx=' +
                          radius +
                          ' cy=' +
                          radius +
                          ' r=' +
                          (radius - 2) +
                          " stroke='white' stroke-dasharray=" +
                          dash1 +
                          ',' +
                          dash2 +
                          " fill='rgba(0,0,0,0.5)'/>" +
                          '<circle cx=' +
                          radius +
                          ' cy=' +
                          radius +
                          ' r=' +
                          (radius - 9) +
                          " fill='white' " +
                          " class='swiper-circle-text'" +
                          ' /> ' +
                          '</svg></span>'
                        );
                      }
                    }
                  : {
                      bulletClass: 'swiper-pagination-bullet',
                      bulletActiveClass: 'swiper-pagination-bullet-active',
                      clickable: true
                    }
              }
              onNavigationPrev={(e) => {
                if (tabs[tabNum].fields?.isNextPrevDmp?.value) {
                  global.Dmp.clickAction(dmp ? `${dmp}-btnl` : `home-btnl`, '');
                } else {
                  global.Dmp.clickAction(dmp ? `${dmp}-btn${e.realIndex + 1}` : `home-btn${e.realIndex + 1}`, '');
                }
              }}
              onNavigationNext={(e) => {
                if (tabs[tabNum].fields?.isNextPrevDmp?.value) {
                  global.Dmp.clickAction(dmp ? `${dmp}-btnr` : `home-btnr`, '');
                } else {
                  global.Dmp.clickAction(dmp ? `${dmp}-btn${e.realIndex + 1}` : `home-btn${e.realIndex + 1}`, '');
                }
              }}
              onPaginationUpdate={(e) => {
                e?.pagination?.bullets?.forEach((item, index) => {
                  item.onclick = function () {
                    if (e.realIndex !== index) {
                      global.Dmp.clickAction(dmp ? `${dmp}-btn${index + 1}` : `home-btn${index + 1}`, '');
                    }
                  };
                });
              }}
            >
              {bannerItem?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <HeroLanding data={item} isMobile={isMobile} className="HeaderBanner-hero" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            {showMask && <div className={`mask-cover ${pagename === 'home' && 'mask-cover-animation'}`} />}
            {showMask && <img alt="广汽本田" className={`mask-image ${pagename === 'home' && 'mask-image-animation'}`} src={maskImage} />}
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </section>
    </React.Fragment>
  );
}
