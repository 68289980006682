import React, { useState, useEffect } from 'react';
import temp from '../../temp/config';
import { getPrefixField, sendDmpAction, createFilePath } from '../../utils/obj-utils';
import './style.scss';
import { type } from 'jquery';

export default function index(props) {
  const { fields } = props;
  const [init, setInit] = useState(true);
  let isAutoPause = false;
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    return () => {
      if (Array.from(document.getElementsByClassName('fullVideoContainerV2')).length === 0) {
        document.documentElement.style.overflow = 'auto';
        document.documentElement.style.overflowX = 'hidden';
      }
    };
  }, []);

  return (
    <div className="fullVideoContainerV2">
      <video
        x5-playsinline="true"
        webkit-playsinline="true"
        // playsinline="true"
        playsInline={true}
        className="fullVideo"
        src={`${fields.detail_video}`}
        autoPlay="autoplay"
        muted={false}
        controls
        onPause={() => {
          setTimeout(() => {
            setInit(false);
            if (!isAutoPause) {
              if (fields.stop_video_dmp) {
                sendDmpAction(fields.stop_video_dmp.type, fields.stop_video_dmp.point, fields.stop_video_dmp.value1);
              }
            } else {
              isAutoPause = false;
            }
          }, 0);
        }}
        onPlay={() => {
          if (fields.play_video_dmp) {
            !init && sendDmpAction(fields.play_video_dmp.type, fields.play_video_dmp.point, fields.play_video_dmp.value1);
          }
        }}
        onEnded={() => {
          isAutoPause = true;
        }}
      />
      <img
        alt="广汽本田"
        className="closeVideo"
        src={createFilePath('/common/icon/close.png')}
        onClick={() => {
          props.close();
          if (fields.close_icon_dmp) {
            sendDmpAction(fields.close_icon_dmp.type, fields.close_icon_dmp.point, fields.close_icon_dmp.value1);
          }
        }}
      />
    </div>
  );
}
