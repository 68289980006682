import React, { useState, useRef, useEffect } from 'react';
import './style.scss';
import temp from '../../temp/config';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import Details from './details/index';
import { isMobileFun, createFilePath } from '../../utils/obj-utils';
export default function index(props) {
  const isMobile = isMobileFun();
  const {
    list,
    list_t,
    textIcon,
    searchIcon,
    closeImg,
    play_img,
    m_play_img,
    titleHTML,
    m_titleHTML,
    grey,
    m_footerText,
    listTitleFlag,
    m_footerText_v2
  } = props.fields;
  const [windowWidth, setWindowWidth] = useState(375);
  useEffect(() => {
    setWindowWidth(document.documentElement.clientWidth);
  }, []);
  // 数据区分
  const list_t_numArr = [];
  const list_t_arr = [...list];
  list_t.forEach((item, index) => {
    list_t_numArr.push(list_t_arr.splice(0, Number(item.fields.num.value)));
  });
  // 设置ref
  const arr1 = [];
  const arr2 = [];
  const arr5 = [];
  const arr6 = [];
  list_t.forEach((item, index) => {
    arr1.push([]);
    arr2.push([]);
    arr5.push([]);
    arr6.push([]);
  });
  // list.forEach((element, index) => {
  //   arr1.push(useRef(null));
  //   arr2.push('swiperRef' + index);
  // });
  list_t.forEach((item, index) => {
    for (let i = 0; i < Number(item.fields.num.value); i++) {
      arr1[index].push(useRef(null));
      arr2[index].push('swiperRef' + index + i);
      arr2[index][i] = arr1[index][i];
      arr5[index].push(useRef(null));
      arr6[index].push('swiperRef' + index + i);
      arr6[index][i] = arr5[index][i];
    }
  });
  useEffect(() => {
    // 设置初始高亮
    // eslint-disable-next-line no-unused-expressions
    !isMobile
      ? list_t.forEach((item, index) => {
          for (let i = 0; i < Number(item.fields.num.value); i++) {
            document.querySelector(`.listOnly${index}${i}`).childNodes[0].className = 'activeText';
          }
        })
      : '';
  }, []);
  const [num, setNum] = useState([]);
  useEffect(() => {
    // 显示swiper下标
    var arr = [];
    list.forEach((item, index) => {
      arr.push(index);
    });
    setNum(arr);
  }, []);
  // M端所需数据
  const [m_list, setMlist] = useState([]);
  const changeMlist = function (val) {
    if (val === -1) {
      var arr = [];
      list.forEach((item, index) => {
        item.fields.childrenList.forEach((ite, idx) => {
          arr.push(ite);
        });
      });
      var arr1 = [];
      while (arr.length >= 6) {
        arr1.push(arr.splice(0, 6));
      }
      if (arr.length !== 0) {
        arr1.push(arr);
      }
      setMlist(arr1);
    } else {
      var arr = [];
      if (list[val]?.fields.title.value === '') {
        list[val - 1].fields.childrenList.forEach((ite, idx) => {
          arr.push(ite);
        });
        list[val].fields.childrenList.forEach((ite, idx) => {
          arr.push(ite);
        });
      } else {
        list[val - 1].fields.childrenList.forEach((ite, idx) => {
          arr.push(ite);
        });
      }
      var arr1 = [];
      while (arr.length >= 6) {
        arr1.push(arr.splice(0, 6));
      }
      if (arr.length !== 0) {
        arr1.push(arr);
      }
      setMlist(arr1);
      console.log(arr1);
    }
  };
  useEffect(() => {
    var num = 0;
    list.forEach((item, index) => {
      item.fields.childrenList.forEach((ite, idx) => {
        num++;
        ite.fields.videoIndex = num;
      });
    });
    changeMlist(-1);
  }, []);
  // 视频关闭埋点
  const [closeVideoIndex, setCloseVideoIndex] = useState(0);
  // M端高亮
  const [m_num, setMnum] = useState(0);
  const swiperMobileOne = useRef(null);
  const swiperMobileTwo = useRef(null);
  // 视频播放
  const [flag, setFlag] = useState(false);
  const [video, setVideo] = useState('');

  return !isMobile ? (
    <div className={`operation_footer_v2 vehicle_${props.fields.class.value}`}>
      {flag ? <Details closeImg={closeImg.value} setFlag={setFlag} videoSrc={video} videoIndex={closeVideoIndex}></Details> : ''}
      {titleHTML.vlaue !== '' ? <div dangerouslySetInnerHTML={{ __html: titleHTML.value?.replaceHtml() }}></div> : ''}
      <div className="operation_footer_main">
        {list_t_numArr.map((children, childrenIndex) => {
          return (
            <div
              key={childrenIndex}
              className="newBlock"
              style={{
                backgroundColor: list_t[childrenIndex].fields.backColor.value,
                paddingBottom: `calc(${list_t[childrenIndex].fields.top.value} - 10vh)`
              }}
            >
              <div style={{ top: list_t[childrenIndex].fields.top.value }}>
                <div>
                  <div
                    className="newBlockTitle"
                    style={{
                      border: list_t[childrenIndex].fields.L_borderColor.value,
                      borderBottom: 'none'
                    }}
                  >
                    <div style={{ background: list_t[childrenIndex].fields.backColor.value }}>
                      <div
                        style={{
                          background: `url(${createFilePath('/crider-guide/img/titleBg.png')}) repeat center center`,
                          backgroundSize: '100% 100%',
                          textAlign: list_t[childrenIndex].fields.titleImg.value === '' ? 'center' : ''
                        }}
                      >
                        {list_t[childrenIndex].fields.titleImg.value !== '' ? (
                          <img alt="广汽本田" src={createFilePath(list_t[childrenIndex].fields.titleImg.value)} />
                        ) : (
                          ''
                        )}
                        <span style={{ fontSize: '2.4375rem' }}>{list_t[childrenIndex].fields.title.value}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="lititle"
                    style={{
                      borderLeft: list_t[childrenIndex].fields.L_borderColor.value,
                      borderRight: list_t[childrenIndex].fields.L_borderColor.value
                    }}
                    dangerouslySetInnerHTML={{ __html: list_t[childrenIndex].fields.littleTitle.value?.replaceHtml() }}
                  ></div>
                </div>
                <div style={{ backgroundColor: list_t[childrenIndex].fields.L_backColor.value }}>
                  <div style={{ border: list_t[childrenIndex].fields.borderColor.value, borderTop: 'none' }}>
                    {children.map((item, index) => {
                      return item.fields.reserve.value ? (
                        <div key={index} style={{ display: num.indexOf(index) !== -1 ? 'grid' : 'none' }}>
                          <div className="operation_footer_main_one">
                            <div dangerouslySetInnerHTML={{ __html: item.fields.titleHTML.value?.replaceHtml() }}></div>
                            <div className={`listOnly${childrenIndex}${index}`}>
                              {item.fields.childrenList.map((ite, idx) => {
                                return (
                                  <p key={idx}>
                                    <span
                                      onClick={(e) => {
                                        window.Dmp.msgAction('manual-second', ite.fields.text.value);
                                        arr1[childrenIndex][index].current.swiper.slideTo(idx);
                                        arr5[childrenIndex][index].current.swiper.slideTo(idx);
                                        if (ite?.fields?.remarks?.value) {
                                          document.querySelector(`.listOnlyRemarks${childrenIndex}${index}`).innerHTML =
                                            ite?.fields?.remarks?.value?.replaceHtml();
                                        } else {
                                          document.querySelector(`.listOnlyRemarks${childrenIndex}${index}`).innerHTML = '';
                                        }
                                        document.querySelector(`.listOnly${childrenIndex}${index}`).childNodes.forEach((i, x) => {
                                          document.querySelector(`.listOnly${childrenIndex}${index}`).childNodes[x].className = '';
                                        });
                                        document.querySelector(`.listOnly${childrenIndex}${index}`).childNodes[idx].className += ' activeText';
                                      }}
                                    >
                                      {ite.fields.text.value}
                                      <em
                                        style={{
                                          background: `url('${createFilePath(grey.value)}') no-repeat center center`
                                        }}
                                      ></em>
                                    </span>
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                          <div className="operation_footer_main_two">
                            <Swiper slidesPerView={1} ref={arr1[childrenIndex][index]} className={'name'}>
                              {item.fields.childrenList.map((ite, idx) => {
                                return (
                                  <SwiperSlide key={idx}>
                                    <img
                                      alt="广汽本田"
                                      src={createFilePath(ite.fields.pc_img.value)}
                                      onClick={() => {
                                        setFlag(true);
                                        setCloseVideoIndex(ite.fields.closeDmp[0].displayName);
                                        setVideo(ite.fields.video.value);
                                        window.Dmp.clickAction(ite.fields.openDmp[0].displayName, '', '');
                                      }}
                                    />

                                    <img alt="广汽本田" src={createFilePath(play_img.value)} className="play_icon" />
                                    <div className="Mask"></div>
                                  </SwiperSlide>
                                );
                              })}
                            </Swiper>
                            <div>
                              <Swiper
                                slidesPerView={4}
                                modules={[Navigation]}
                                ref={arr5[childrenIndex][index]}
                                navigation={{ prevEl: `.prevEl${index}`, nextEl: `.nextEl${index}` }}
                              >
                                {item.fields.childrenList.map((ite, idx) => {
                                  return (
                                    <SwiperSlide key={idx}>
                                      <img
                                        alt="广汽本田"
                                        onClick={() => {
                                          setFlag(true);
                                          setCloseVideoIndex(ite.fields.closeDmp[0].displayName);
                                          setVideo(ite.fields.video.value);
                                          window.Dmp.clickAction(ite.fields.openDmp[0].displayName, '', '');
                                        }}
                                        src={createFilePath(ite.fields.pc_little_img.value)}
                                        onMouseMove={(e) => {
                                          e.target.parentNode.parentNode.childNodes.forEach((itee, idxx) => {
                                            if (itee.className.indexOf('activeImage') !== -1) {
                                              itee.classList.remove('activeImage');
                                            }
                                          });
                                          e.target.parentNode.classList.add('activeImage');
                                          arr2[childrenIndex][index].current.swiper.slideTo(idx);
                                          if (ite?.fields?.remarks?.value) {
                                            document.querySelector(`.listOnlyRemarks${childrenIndex}${index}`).innerHTML =
                                              ite?.fields?.remarks?.value?.replaceHtml();
                                          } else {
                                            document.querySelector(`.listOnlyRemarks${childrenIndex}${index}`).innerHTML = '';
                                          }
                                          document.querySelector(`.listOnly${childrenIndex}${index}`).childNodes.forEach((i, x) => {
                                            document.querySelector(`.listOnly${childrenIndex}${index}`).childNodes[x].className = '';
                                          });
                                          document.querySelector(`.listOnly${childrenIndex}${index}`).childNodes[idx].className += ' activeText';
                                        }}
                                      />
                                      {listTitleFlag.value === true ? (
                                        <img alt="广汽本田" src={createFilePath(play_img.value)} className="play_icon" />
                                      ) : (
                                        ''
                                      )}
                                    </SwiperSlide>
                                  );
                                })}
                              </Swiper>
                              <div
                                className={`prveEl prevEl${index}`}
                                onClick={() => {
                                  window.Dmp.clickAction(`manual-list-${item.fields.changeIndex.value}-btnl`, '', '');
                                }}
                              >
                                <img alt="广汽本田" src={createFilePath(props.fields.leftImg.value)} />
                              </div>
                              <div
                                className={`nextEl nextEl${index}`}
                                onClick={() => {
                                  window.Dmp.clickAction(`manual-list-${item.fields.changeIndex.value}-btnr`, '', '');
                                }}
                              >
                                <img alt="广汽本田" src={createFilePath(props.fields.rightImg.value)} />
                              </div>
                              <div dangerouslySetInnerHTML={{ __html: item?.fields?.remarks?.value?.replaceHtml() }}></div>
                              <div
                                className={`listOnlyRemarks${childrenIndex}${index}`}
                                dangerouslySetInnerHTML={{ __html: item?.fields?.childrenList[0]?.fields?.remarks?.value?.replaceHtml() }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div key={index} style={{ display: num.indexOf(index) !== -1 ? 'grid' : 'none' }}>
                          <div className="operation_footer_main_two">
                            <Swiper slidesPerView={1} ref={arr1[childrenIndex][index]} className={'name'}>
                              {item.fields.childrenList.map((ite, idx) => {
                                return (
                                  <SwiperSlide key={idx}>
                                    <img
                                      alt="广汽本田"
                                      src={createFilePath(ite.fields.pc_img.value)}
                                      onClick={() => {
                                        setFlag(true);
                                        setCloseVideoIndex(ite.fields.closeDmp[0].displayName);
                                        setVideo(ite.fields.video.value);
                                        window.Dmp.clickAction(ite.fields.openDmp[0].displayName, '', '');
                                      }}
                                    />

                                    <img alt="广汽本田" src={createFilePath(play_img.value)} className="play_icon" />
                                    <div className="Mask"></div>
                                  </SwiperSlide>
                                );
                              })}
                            </Swiper>
                            <div>
                              <Swiper
                                slidesPerView={4}
                                ref={arr5[childrenIndex][index]}
                                modules={[Navigation]}
                                navigation={{ prevEl: `.prevEl${index}`, nextEl: `.nextEl${index}` }}
                              >
                                {item.fields.childrenList.map((ite, idx) => {
                                  return (
                                    <SwiperSlide key={idx}>
                                      <img
                                        alt="广汽本田"
                                        onClick={() => {
                                          setFlag(true);
                                          setCloseVideoIndex(ite.fields.closeDmp[0].displayName);
                                          setVideo(ite.fields.video.value);
                                          window.Dmp.clickAction(ite.fields.openDmp[0].displayName, '', '');
                                        }}
                                        src={createFilePath(ite.fields.pc_little_img.value)}
                                        onMouseMove={(e) => {
                                          e.target.parentNode.parentNode.childNodes.forEach((itee, idxx) => {
                                            if (itee.className.indexOf('activeImage') !== -1) {
                                              itee.classList.remove('activeImage');
                                            }
                                          });
                                          e.target.parentNode.classList.add('activeImage');
                                          arr2[childrenIndex][index].current.swiper.slideTo(idx);
                                          if (ite?.fields?.remarks?.value) {
                                            document.querySelector(`.listOnlyRemarks${childrenIndex}${index}`).innerHTML =
                                              ite?.fields?.remarks?.value?.replaceHtml();
                                          } else {
                                            document.querySelector(`.listOnlyRemarks${childrenIndex}${index}`).innerHTML = '';
                                          }
                                          document.querySelector(`.listOnly${childrenIndex}${index}`).childNodes.forEach((i, x) => {
                                            document.querySelector(`.listOnly${childrenIndex}${index}`).childNodes[x].className = '';
                                          });
                                          document.querySelector(`.listOnly${childrenIndex}${index}`).childNodes[idx].className += ' activeText';
                                        }}
                                      />
                                      {listTitleFlag.value === true ? (
                                        <img alt="广汽本田" src={createFilePath(play_img.value)} className="play_icon" />
                                      ) : (
                                        ''
                                      )}
                                    </SwiperSlide>
                                  );
                                })}
                              </Swiper>
                              <div
                                className={`prveEl prevEl${index}`}
                                onClick={() => {
                                  window.Dmp.clickAction(`manual-list-${item.fields.changeIndex.value}-btnl`, '', '');
                                }}
                              >
                                <img alt="广汽本田" src={createFilePath(props.fields.leftImg.value)} />
                              </div>
                              <div
                                className={`nextEl nextEl${index}`}
                                onClick={() => {
                                  window.Dmp.clickAction(`manual-list-${item.fields.changeIndex.value}-btnr`, '', '');
                                }}
                              >
                                <img alt="广汽本田" src={createFilePath(props.fields.rightImg.value)} />
                              </div>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: item?.fields?.remarks?.value?.replaceHtml() }}></div>
                            <div
                              className={`listOnlyRemarks${childrenIndex}${index}`}
                              dangerouslySetInnerHTML={{ __html: item?.fields?.childrenList[0]?.fields?.remarks?.value?.replaceHtml() }}
                            ></div>
                          </div>
                          <div className="operation_footer_main_one">
                            <div dangerouslySetInnerHTML={{ __html: item.fields.titleHTML.value?.replaceHtml() }}></div>
                            <div className={`listOnly${childrenIndex}${index}`}>
                              {item.fields.childrenList.map((ite, idx) => {
                                return (
                                  <p key={idx}>
                                    <span
                                      onClick={() => {
                                        window.Dmp.msgAction('manual-second', ite.fields.text.value);
                                        arr1[childrenIndex][index].current.swiper.slideTo(idx);
                                        arr5[childrenIndex][index].current.swiper.slideTo(idx);
                                        if (ite?.fields?.remarks?.value) {
                                          document.querySelector(`.listOnlyRemarks${childrenIndex}${index}`).innerHTML =
                                            ite?.fields?.remarks?.value?.replaceHtml();
                                        } else {
                                          document.querySelector(`.listOnlyRemarks${childrenIndex}${index}`).innerHTML = '';
                                        }
                                        document.querySelector(`.listOnly${childrenIndex}${index}`).childNodes.forEach((i, x) => {
                                          document.querySelector(`.listOnly${childrenIndex}${index}`).childNodes[x].className = '';
                                        });
                                        document.querySelector(`.listOnly${childrenIndex}${index}`).childNodes[idx].className += ' activeText';
                                      }}
                                    >
                                      {ite.fields.text.value}
                                      <em
                                        style={{
                                          background: `url('${createFilePath(grey.value)}') no-repeat center center`
                                        }}
                                      ></em>
                                    </span>
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={`remarks_${props.fields.remarksPosition.value}`} dangerouslySetInnerHTML={{ __html: m_footerText.value?.replaceHtml() }}></div>
    </div>
  ) : (
    <div className={`operation_footer_m_v2 vehicle_${props.fields.class.value}_m`}>
      {m_titleHTML.vlaue !== '' ? <div dangerouslySetInnerHTML={{ __html: m_titleHTML.value?.replaceHtml() }}></div> : ''}
      {flag ? <Details closeImg={closeImg.value} setFlag={setFlag} videoSrc={video} videoIndex={closeVideoIndex}></Details> : ''}
      <div className="operation_footer_head_m">
        <div>
          <select
            placeholder="请选择"
            onChange={(e) => {
              window.Dmp.msgAction('manual-type', e.target.value, '');
              if (e.target.selectedIndex !== 0) {
                var num = Number(e.target[e.target.selectedIndex].getAttribute('data_index')) + 1;
                changeMlist(num);
                document.querySelector('.m_title_text').innerText = list[num - 1].fields.title.value;
                document.querySelector('.m_title_text2').innerText = list[num - 1].fields.title2.value;
              } else {
                changeMlist(-1);
                document.querySelector('.m_title_text').innerText = '车辆操作指南';
                document.querySelector('.m_title_text2').innerText = '';
              }
              swiperMobileOne.current.swiper.slideTo(0);
              swiperMobileTwo.current.swiper.slideTo(0);
            }}
            style={{ border: `1px solid ${props.fields.lightColor.value}` }}
          >
            <option>全部</option>
            {list.map((item, index) => {
              return item.fields.title.value !== '' ? (
                <option key={index} data_index={index}>
                  {item.fields.title.value}
                </option>
              ) : (
                ''
              );
            })}
          </select>
          <div style={{ background: props.fields.lightColor.value }}>
            <div></div>
          </div>
        </div>
        <div style={{ border: `1px solid ${props.fields.lightColor.value}` }}>
          <input
            className="input"
            placeholder="输入关键词…"
            onBlur={(e) => {
              window.Dmp.msgAction('manual-key', e.target.value);
            }}
            style={{ color: props.fields.lightColor.value }}
          />
          <div
            style={{ background: props.fields.lightColor.value }}
            onClick={() => {
              var value = document.querySelector('.input').value;
              window.Dmp.clickAction('manual-search', '', '');
              if (value !== '') {
                document.querySelector('.m_title_text').innerText = '搜索结果';
                document.querySelector('.m_title_text2').innerText = '';
                var arr = [];
                list.forEach((item, index) => {
                  item.fields.childrenList.forEach((ite, idx) => {
                    if (ite.fields.text.value.indexOf(value) !== -1) {
                      arr.push(ite);
                    }
                  });
                });
                var arr1 = [];
                while (arr.length >= 6) {
                  arr1.push(arr.splice(0, 6));
                }
                if (arr.length !== 0) {
                  arr1.push(arr);
                }
                setMlist(arr1);
              } else {
                document.querySelector('select').childNodes[0].selected = true;
                changeMlist(-1);
                document.querySelector('.m_title_text').innerText = '搜索结果';
                document.querySelector('.m_title_text2').innerText = '';
              }
              swiperMobileOne.current.swiper.slideTo(0);
              swiperMobileTwo.current.swiper.slideTo(0);
            }}
          >
            <img alt="广汽本田" src={createFilePath(searchIcon.value)} />
            搜索
          </div>
        </div>
      </div>
      <div className="m_title" style={{ borderLeft: `4px solid ${props.fields.lightColor.value}` }}>
        <p className="m_title_text" style={{ color: props.fields.lightColor.value }}>
          车辆操作指南
        </p>
        <p className="m_title_text2"></p>
      </div>
      <Swiper
        className="swiperOne"
        ref={swiperMobileOne}
        onSlideChange={(e) => {
          if (m_num > e.activeIndex) {
            swiperMobileTwo.current.swiper.slideTo(e.activeIndex - 1);
          } else if (m_num < e.activeIndex) {
            swiperMobileTwo.current.swiper.slideTo(e.activeIndex);
          }
          setMnum(e.activeIndex);
        }}
      >
        {m_list?.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div>
                {item.map((ite, idx) => {
                  return (
                    <div
                      key={idx}
                      onClick={() => {
                        window.Dmp.clickAction(ite.fields.openDmp[0].displayName, '', '');
                        setCloseVideoIndex(ite.fields.closeDmp[0].displayName);
                        setFlag(true);
                        setVideo(ite.fields.video.value);
                      }}
                    >
                      <div className="m_img">
                        <img alt="广汽本田" src={createFilePath(ite.fields.m_img.value)} />
                        <img alt="广汽本田" src={createFilePath(m_play_img.value)} className="play_icon" />
                        <div className="Mask"></div>
                      </div>
                      <div className="hui">
                        {props.fields.m_textBackImg.value !== '' ? (
                          <img alt="广汽本田" src={createFilePath(props.fields.m_textBackImg?.value)} />
                        ) : (
                          ''
                        )}
                        <p>{ite.fields.text.value}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div
        className={`m_remarks_${props.fields.remarksPosition.value}`}
        dangerouslySetInnerHTML={{ __html: (m_footerText_v2.value !== '' ? m_footerText_v2.value : m_footerText.value)?.replaceHtml() }}
      ></div>
      <Swiper
        className="swiperTwo"
        slidesPerView={windowWidth > 750 ? 'auto' : 5}
        ref={swiperMobileTwo}
        initialSlide={0}
        style={{ width: windowWidth > 750 ? '100%' : (m_list.length >= 5 ? 5 : m_list.length) * 7.4 + '%' }}
      >
        {m_list.map((item, index) => {
          return (
            <SwiperSlide
              className={index === m_num ? 'm_active_light' : ''}
              key={index}
              onClick={() => {
                window.Dmp.msgAction('manual-page', index + 1);
                swiperMobileOne.current.swiper.slideTo(index);
                setMnum(index);
                if (m_num > index) {
                  swiperMobileTwo.current.swiper.slideTo(index - 1);
                } else if (m_num < index) {
                  swiperMobileTwo.current.swiper.slideTo(index);
                }
              }}
            >
              <p>{index + 1}</p>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
