import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import temp from '../../temp/config';
import { getPrefixField, sendDmp, convertStylesStringToObject, isMobileFun, createFilePath } from '../../utils/obj-utils';
import LazyLoad from 'react-lazyload';
export default function index({ fields, eventRef }) {
  const holderImg = <img alt="广汽本田" src={createFilePath('/common/placeholder.jpg')} />;
  const mobileAppRef = useRef(null);
  const imgRef = useRef(null);
  const isMobile = isMobileFun();
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const description = fields[getPrefixField('description', isMobile)]?.value;
  const qr_code = createFilePath(fields[getPrefixField('qr_code_url', isMobile)]?.value);
  const qr_code2 = createFilePath(fields[getPrefixField('qr_code2_url', isMobile)]?.value);
  const qr_code_html = fields[getPrefixField('qr_code_html', isMobile)]?.value || '';
  const img_style = fields[getPrefixField('img_style', isMobile)]?.value;
  const content_style = fields[getPrefixField('content_style', isMobile)]?.value;
  const text_style = fields[getPrefixField('text_style', isMobile)]?.value;
  const mobileApp = createFilePath(fields[getPrefixField('app_url', isMobile)]?.value);
  const container_style = fields[getPrefixField('container_style', isMobile)]?.value;
  const [data, _] = useState({
    title,
    description,
    qr_code,
    qr_code2,
    img_style,
    content_style,
    text_style,
    mobileApp,
    isMobile,
    container_style,
    qr_code_html
  });

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields?.sectionDmp);
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const mobileAppTimeline = gsap.timeline({ pause: true });
    if (!data.isMobile) {
      mobileAppTimeline.fromTo(imgRef.current, { x: '30%', opacity: 0 }, { x: '0', opacity: 1 });
    } else {
      mobileAppTimeline.fromTo(imgRef.current, { x: '100%', opacity: 0 }, { x: '0', opacity: 1 });
    }
    const controller = new ScrollMagic.Controller();
    const mobileAppScene = new ScrollMagic.Scene({
      triggerElement: mobileAppRef.current,
      triggerHook: 'onEnter',
      duration: data.isMobile ? document.documentElement.clientHeight : '70%'
    })
      .on('progress', function (event) {
        mobileAppTimeline.progress(event.progress);
      })
      .setTween(mobileAppTimeline)
      .addTo(controller);
    return () => {
      mobileAppTimeline.kill();
      mobileAppScene.remove();
      mobileAppScene.destroy();
      controller.destroy();
    };
  }, [data]);

  return (
    <LazyLoad placeholder={holderImg}>
      <section className="container_mobileApp" ref={mobileAppRef}>
        {data && (
          <div className="mobileApp" style={convertStylesStringToObject(data.container_style)}>
            <div className="container" style={convertStylesStringToObject(data.content_style)}>
              {data.isMobile && <div className="appTitle" dangerouslySetInnerHTML={{ __html: data.title?.replaceHtml() }} />}
              <div className="text" style={convertStylesStringToObject(data.text_style)}>
                {!data.isMobile && <div className="title" dangerouslySetInnerHTML={{ __html: data.title?.replaceHtml() }} />}
                {!data.isMobile && <hr className="hr" />}
                <div className="description" dangerouslySetInnerHTML={{ __html: data.description?.replaceHtml() }} />
                {qr_code_html ? (
                  <div className="qrCodeBox" dangerouslySetInnerHTML={{ __html: data.qr_code_html?.replaceHtml() }}></div>
                ) : (
                  <>
                    <img alt="广汽本田" className="qrCodeImg" src={data.qr_code} />
                    {fields[getPrefixField('qr_code2_url', isMobile)]?.value && (
                      <img alt="广汽本田" className="qrCodeImg" style={{ marginLeft: '2vw' }} src={data.qr_code2} />
                    )}
                  </>
                )}
              </div>
              <div className="img">
                <img className="appImg" src={data.mobileApp} ref={imgRef} style={convertStylesStringToObject(data.img_style)} alt={data.title} />
              </div>
            </div>
          </div>
        )}
      </section>
    </LazyLoad>
  );
}
