import React, { useEffect, useRef, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import { getPrefixField, convertStylesStringToObject, createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields, scrollElement }) {
  const containerTopRef = useRef(null);
  const containerBottomRef = useRef(null);
  const imageTopRef = useRef(null);
  const imageBottomRef = useRef(null);
  const textTopRef = useRef(null);
  const textBottomRef = useRef(null);
  const isMobile = isMobileFun();
  const topTitle = fields[getPrefixField('title1', isMobile)]?.value;
  const topDescription = fields[getPrefixField('description1', isMobile)]?.value;
  const topImage = createFilePath(fields[getPrefixField('image1', isMobile)]?.value);
  const bottomTitle = fields[getPrefixField('title2', isMobile)]?.value;
  const bottomDescription = fields[getPrefixField('description2', isMobile)]?.value;
  const bottomImage = createFilePath(fields[getPrefixField('image2', isMobile)]?.value);
  const reverse = fields[getPrefixField('reverse', isMobile)]?.value;
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const disableAnimate = fields[getPrefixField('disableAnimate', isMobile)]?.value;
  const [data, _] = useState({
    topTitle,
    topDescription,
    topImage,
    bottomTitle,
    bottomDescription,
    bottomImage,
    reverse,
    style,
    disableAnimate,
    isMobile
  });

  useEffect(() => {
    if (data?.disableAnimate) {
      return;
    }

    const topTimeline = gsap
      .timeline({ paused: true })
      .fromTo(imageTopRef.current, { opacity: 0 }, { opacity: 1 })
      .addLabel('p1')
      .fromTo(imageTopRef.current, { scale: 1.3 }, { scale: 1 }, data?.isMobile ? '<' : 'p1-=40%')
      .fromTo(textTopRef.current, { opacity: 0 }, { opacity: 1 }, data?.isMobile ? '>' : 'p1-=40%');
    const controller = new ScrollMagic.Controller({ container: scrollElement || window });
    const topScene = new ScrollMagic.Scene({
      triggerElement: containerTopRef.current,
      triggerHook: 'onCenter',
      duration: '100%'
    })
      .on('progress', function (event) {
        topTimeline.progress(event.progress);
      })
      .setTween(topTimeline)
      .addTo(controller);

    const bottomTimeline = gsap
      .timeline({ paused: true })
      .fromTo(imageBottomRef.current, { opacity: 0 }, { opacity: 1 })
      .addLabel('p2')
      .fromTo(imageBottomRef.current, { scale: 1.3 }, { scale: 1 }, data?.isMobile ? '<' : 'p2-=40%')
      .fromTo(textBottomRef.current, { opacity: 0 }, { opacity: 1 }, data?.isMobile ? '>' : 'p2-=40%');
    const bottomScene = new ScrollMagic.Scene({
      triggerElement: containerBottomRef.current,
      triggerHook: 'onEnter',
      duration: '100%'
    })
      .on('progress', function (event) {
        bottomTimeline.progress(event.progress);
      })
      .setTween(bottomTimeline)
      .addTo(controller);

    return () => {
      topTimeline.kill();
      topScene.remove();
      topScene.destroy();

      bottomTimeline.kill();
      bottomScene.remove();
      bottomScene.destroy();

      controller.destroy();
    };
  }, []);

  const getTopContainer = () => {
    return (
      <div className={`content-container content-container-top ${data?.reverse ? 'content-container-top-reverse' : ''}`} ref={containerTopRef}>
        {!data?.isMobile && data?.reverse ? (
          <React.Fragment>
            {getTopTextContainer()}
            {getTopImageContainer()}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getTopImageContainer()}
            {getTopTextContainer()}
          </React.Fragment>
        )}
      </div>
    );
  };

  const getTopTextContainer = () => {
    return (
      <div className="text-container" ref={textTopRef}>
        <div className="title" dangerouslySetInnerHTML={{ __html: data?.topTitle?.replaceHtml() }} />
        <div className="description" dangerouslySetInnerHTML={{ __html: data?.topDescription?.replaceHtml() }} />
      </div>
    );
  };

  const getTopImageContainer = () => {
    return (
      <div className="image-container">
        <img alt="广汽本田" className="image" ref={imageTopRef} src={createFilePath(data?.topImage)} />
      </div>
    );
  };

  const getBottomContainer = () => {
    return (
      <div
        className={`content-container content-container-bottom ${data?.reverse ? 'content-container-bottom-reverse' : ''}`}
        ref={containerBottomRef}
      >
        {data?.isMobile || (!data?.isMobile && data?.reverse) ? (
          <React.Fragment>
            {getBottomImageContainer()}
            {getBottomTextContainer()}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getBottomTextContainer()}
            {getBottomImageContainer()}
          </React.Fragment>
        )}
      </div>
    );
  };

  const getBottomTextContainer = () => {
    return (
      <div className="text-container" ref={textBottomRef}>
        <div className="title" dangerouslySetInnerHTML={{ __html: data?.bottomTitle?.replaceHtml() }} />
        <div className="description" dangerouslySetInnerHTML={{ __html: data?.bottomDescription?.replaceHtml() }} />
      </div>
    );
  };

  const getBottomImageContainer = () => {
    return (
      <div className="image-container">
        <img alt="广汽本田" className="image" ref={imageBottomRef} src={createFilePath(data?.bottomImage)} />
      </div>
    );
  };

  return (
    <section>
      {data ? (
        <div className={`image-group ${data?.reverse ? 'image-group-reverse' : ''}`} style={data?.style}>
          {getTopContainer()}
          {getBottomContainer()}
        </div>
      ) : null}
    </section>
  );
}
