import React, { useEffect, useRef, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import { getPrefixField, isEmpty, convertStylesStringToObject, createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields, scrollElement }) {
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const backgroundRef = useRef(null);
  const isMobile = isMobileFun();
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const description = fields[getPrefixField('description', isMobile)]?.value;
  const background = createFilePath(fields[getPrefixField('background', isMobile)]?.value);
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const disableAnimate = fields[getPrefixField('disableAnimate', isMobile)]?.value;
  const [data, _] = useState({ title, description, background, style, disableAnimate, isMobile });
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    if (data?.disableAnimate) {
      titleRef.current.style.zIndex = 1;
      descriptionRef.current.style.zIndex = 1;
      return;
    }
    if (backgroundRef.current) {
      backgroundRef.current.style.opacity = 0;
    }
    if (titleRef?.current) {
      titleRef.current.style.opacity = 0;
      titleRef.current.style.animation = 'fadeInUp 0.65s';
      titleRef.current.style.animationDelay = '0.6s';
      titleRef.current.style.animationFillMode = 'forwards';
    }
    if (descriptionRef?.current) {
      descriptionRef.current.style.opacity = 0;
      descriptionRef.current.style.animation = 'fadeInUp 0.65s';
      descriptionRef.current.style.animationDelay = '0.6s';
      descriptionRef.current.style.animationFillMode = 'forwards';
    }
  }, []);

  useEffect(() => {
    if (!backgroundLoaded) {
      return;
    }

    const backgroundNaturalWidth = backgroundRef.current.naturalWidth;
    const backgroundNaturalHeight = backgroundRef.current.naturalHeight;
    const windowWidth = document.documentElement.clientWidth;
    const windowHeight = document.documentElement.clientHeight;
    const backgroundHeight = (windowWidth * backgroundNaturalHeight) / backgroundNaturalWidth;
    let offsetBottom = 0;
    if (backgroundHeight > windowHeight) {
      offsetBottom = ((backgroundHeight - windowHeight) / windowHeight) * 100;
      containerRef.current.style.height = `${backgroundHeight}px`;
    } else if (backgroundHeight < windowHeight) {
      backgroundRef.current.style.objectFit = 'contain';
    }

    if (data?.disableAnimate) {
      return;
    }

    const controller = new ScrollMagic.Controller({ container: scrollElement || window });
    const backgroudScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onLeave',
      duration: '100%'
    })
      .on('progress', function (event) {
        const progress = event.progress;
        backgroundRef.current.style.opacity = progress;
        const top = (data?.isMobile ? 33 : 5) * (1 - Math.min(1, data?.isMobile ? progress : progress * 2));
        const right = (data?.isMobile ? 20 : 20) * (1 - progress);
        const bottom = (data?.isMobile ? 33 : 10 + offsetBottom) * (1 - Math.min(1, data?.isMobile ? progress : progress * 2));
        const left = right;
        backgroundRef.current.style.clipPath = `inset(${top}% ${right}% ${bottom}% ${left}%)`;
        if (!data?.isMobile) {
          const scale = Math.min(1.0, 0.8 + 0.2 * progress * 3);
          backgroundRef.current.style.transform = `scale(${scale})`;
        }
      })
      .setPin(containerRef.current)
      .addTo(controller);

    return () => {
      backgroudScene.remove();
      backgroudScene.destroy();

      controller.destroy();
    };
  }, [backgroundLoaded]);

  return (
    <section style={data?.style}>
      {data ? (
        <div className="title-image-scroll-telling" ref={containerRef}>
          <div className="title-image-scroll-telling-content">
            <div className="title" dangerouslySetInnerHTML={{ __html: data?.title?.replaceHtml() }} ref={titleRef} />
            {!isEmpty(data?.description) && (
              <div className="description" dangerouslySetInnerHTML={{ __html: data?.description?.replaceHtml() }} ref={descriptionRef} />
            )}
          </div>
          {/* <img alt="广汽本田" className="background" src={data?.background} ref={backgroundRef} onLoad={() => setBackgroundLoaded(true)} /> */}
          {data?.background && (
            <img alt="广汽本田" className="background" src={data?.background} ref={backgroundRef} onLoad={() => setBackgroundLoaded(true)} />
          )}
        </div>
      ) : null}
    </section>
  );
}
