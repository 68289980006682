import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import { Swiper } from 'swiper';
import { Pagination, Navigation } from 'swiper';
import temp from '../../temp/config';
import './style.scss';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import Icon from '../../elements/icon';
import { getQueryVariable } from '../../utils/site-utils';

export default function index({ fields, eventRef }) {
  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  // const deployUrlS3 = temp.app.deployUrlS3;
  const isMobile = isMobileFun();
  const tabs = fields?.tabs;
  const [tabNum, setTabNum] = useState(0);
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const title_style = fields[getPrefixField('title_style', isMobile)]?.value;
  const background = fields[getPrefixField('background', isMobile)]?.value;
  const container_style = fields[getPrefixField('container_style', isMobile)]?.value;
  const anchor = fields.anchor?.value;
  const blackBackground = fields?.blackBackground.value;
  const [data, _] = useState({
    isMobile,
    title,
    title_style,
    container_style,
    background,
    anchor,
    blackBackground
  });
  var swiper;

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields?.sectionDmp);
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = data?.anchor;
  };
  useEffect(() => {
    if (!data) {
      return;
    }
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      tabs.map((item, index) => {
        let tabArr = item.fields?.tab_name?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(fields.default_tab_name?.value?.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabNum(tabInd < 0 ? tabNum : tabInd);
      } else {
        setTabNum(defaultTabInd < 0 ? tabNum : defaultTabInd);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [data]);
  useEffect(() => {
    if (!data) {
      return;
    }
    const swiper = new Swiper(swiperRef.current, {
      grabCursor: true,
      modules: [Pagination, Navigation],
      slidesPerView: data?.isMobile ? 1 : 3,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      grid: {
        fill: 'column',
        rows: 1
      },
      pagination: data?.isMobile
        ? {
            el: containerRef.current.querySelector(blackBackground ? '.swiper-pagination-black' : '.swiper-pagination'),
            clickable: true
          }
        : false,
      navigation: {
        prevEl: containerRef.current.querySelector('.navigation-prev'),
        nextEl: containerRef.current.querySelector('.navigation-next'),
        disabledClass: 'navigation-disabled'
      },
      on: {
        init: () => {
          setTimeout(() => {
            swiperRef.current.swiper.update();
            swiperRef.current.swiper.slideTo(0, 0);
            containerRef.current.querySelector('.navigation-prev').className = 'navigation-prev navigation-disabled';
            containerRef.current.querySelector('.navigation-next').className = 'navigation-next';
            if (data?.isMobile) {
              const allbullet = containerRef.current.querySelectorAll('.swiper-pagination-bullet');
              allbullet.className = 'swiper-pagination-bullet swiper-pagination-bullet-active';
              allbullet[0].className = 'swiper-pagination-bullet swiper-pagination-bullet-active';
            }
          }, 0);
        }
      }
    });
    if (data?.isMobile) {
      for (let i = 0; i < swiper.pagination.bullets.length; i++) {
        swiper.pagination.bullets[i].onclick = function () {
          sendDmp([tabs[tabNum].fields?.dmpPointMore[i]]);
        };
      }
    }

    swiper.navigation.prevEl.onclick = function () {
      sendDmp(tabs[tabNum].fields?.dmpPointLeft);
    };
    swiper.navigation.nextEl.onclick = function () {
      sendDmp(tabs[tabNum].fields?.dmpPointRight);
    };
    return () => {
      swiper.destroy(true, true);
    };
  }, [data, tabNum]);
  useEffect(() => {
    if (!data) {
      return;
    }
    const publicPraiseTimeline = gsap.timeline({ pause: true });
    if (!data.isMobile) {
      publicPraiseTimeline.fromTo(swiperRef.current, { x: '100%', opacity: 0 }, { x: '0', opacity: 1 });
    } else {
      publicPraiseTimeline.fromTo(titleRef.current, { opacity: 0 }, { opacity: 1 }).fromTo(swiperRef.current, { x: '100%' }, { x: '0' });
    }
    const controller = new ScrollMagic.Controller();
    const publicPraiseScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onEnter',
      duration: data.isMobile ? document.documentElement.clientHeight : '80%'
    })
      .on('progress', function (event) {
        publicPraiseTimeline.progress(event.progress);
      })
      .setTween(publicPraiseTimeline)
      .addTo(controller);

    return () => {
      publicPraiseTimeline.kill();
      publicPraiseScene.remove();
      publicPraiseScene.destroy();
      controller.destroy();
    };
  }, [data, tabNum]);

  return (
    <section id={data?.anchor} className="container_publicPraiseV2" ref={containerRef} style={convertStylesStringToObject(data.container_style)}>
      {data ? (
        <div className="publicPraiseV2" style={convertStylesStringToObject(data.background)}>
          <div className="content-container">
            <div className="tab_list">
              {tabs &&
                tabs.length > 1 &&
                tabs.map((item, index) => {
                  return (
                    <>
                      {index !== 0 && <div className="split_line" dangerouslySetInnerHTML={{ __html: fields.split_line?.value?.replaceHtml() }} />}
                      <div
                        key={index}
                        className={`tab_item ${index === tabNum ? 'tab_disabled' : ''}`}
                        onClick={() => {
                          setTabNum(index);
                          sendDmp(item.fields?.tab_dmp);
                        }}
                      >
                        <div
                          className={index === tabNum ? 'tabContainer_active' : 'tabContainer'}
                          dangerouslySetInnerHTML={{ __html: item.fields?.tab?.value?.replaceHtml() }}
                        />
                      </div>
                    </>
                  );
                })}
            </div>

            <div
              className="titleV2"
              ref={titleRef}
              dangerouslySetInnerHTML={{ __html: data.title?.replaceHtml() }}
              style={convertStylesStringToObject(data.title_style)}
            />
            {tabs &&
              tabs.length > 1 &&
              tabs.map((ele, index) => {
                return (
                  index === tabNum && (
                    <div className="swiper-container">
                      <div className="swiper-support" ref={swiperRef}>
                        <div className="swiper-wrapper">
                          <React.Fragment>
                            {ele.fields?.[`${getPrefixField('imgList', isMobile)}`]?.map((item, index) => {
                              return (
                                <div className="swiper-slide" key={index}>
                                  <div className="cardDetail" style={convertStylesStringToObject(item.fields.cardDetail_style?.value)}>
                                    <img
                                      alt="广汽本田"
                                      className="img"
                                      src={`${createFilePath(item.fields.img.value)}`}
                                      style={convertStylesStringToObject(item.fields.img_style?.value)}
                                    />
                                    <div className="title" dangerouslySetInnerHTML={{ __html: item.fields.title.value?.replaceHtml() }} />
                                    {item.fields.subTitle.value && (
                                      <div className="subTitle" dangerouslySetInnerHTML={{ __html: item.fields.subTitle.value?.replaceHtml() }} />
                                    )}
                                    <button
                                      className="btnDetails"
                                      onClick={() => {
                                        sendDmp(
                                          item.fields.btnDmp,
                                          item.fields.pc_btn_href?.value !== '' && !isMobile
                                            ? item.fields.pc_btn_href?.value
                                            : item.fields.btn_href?.value
                                        );
                                        window.open(
                                          item.fields.pc_btn_href?.value !== '' && !isMobile
                                            ? item.fields.pc_btn_href?.value
                                            : item.fields.btn_href?.value,
                                          item.fields.btn_target?.fields.v.value
                                        );
                                      }}
                                      style={convertStylesStringToObject(fields.btnStyle?.value)}
                                    >
                                      了解详情
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </React.Fragment>
                        </div>
                      </div>
                      <div className="bottomIcon">
                        <div className="navigation-prev" style={convertStylesStringToObject(fields.navigationColor.value)}>
                          <Icon name="ArrowLeft" />
                        </div>
                        <div className="navigation-next" style={convertStylesStringToObject(fields.navigationColor.value)}>
                          <Icon name="ArrowRight" />
                        </div>
                        {data?.isMobile && <div className={data?.blackBackground ? 'swiper-pagination-black' : 'swiper-pagination'} />}
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
      ) : null}
    </section>
  );
}
