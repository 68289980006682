/* eslint-disable no-eval */
import React, { useEffect, useRef, useState, useImperativeHandle, createRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import Swiper from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import './style.scss';
import { convertStylesStringToObject, countUp, getPrefixField, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import temp from '../../temp/config';
import { getQueryVariable } from '../../utils/site-utils';
import Details from '../../elements/InnerPageDetail';

export default function index({ fields, eventRef, params }) {
  const containerRef = useRef(null);
  const isMobile = isMobileFun();
  const [tabNum, setTabNum] = useState(null);
  const [flag, setFlag] = useState(null);
  const [num, setNum] = useState(null);
  const [showController, setShowController] = useState(null);
  const tabSwiperEl = useRef(null);
  const tabSwiper = useRef(null);
  const contentSwiperEl = useRef(null);
  const contentSwiper = useRef(null);
  const tabs = fields[getPrefixField('tabs', isMobile)];
  const [showDynamicNumberAnimate, setShowshowDynamicNumberAnimate] = useState(false);
  const sectionDmp = fields?.sectionDmp;
  const [data, _] = useState({
    tabs,
    sectionDmp
  });
  const tabsRefList =
    tabs && tabs.length > 0
      ? tabs.map((item) => {
          return {
            tabRef: createRef(),
            containerRef: createRef(),
            content1Ref: createRef(),
            content1LineRef: createRef(),
            content2Ref: createRef(),
            content2LineRef: createRef(),
            content3Ref: createRef(),
            content3LineRef: createRef(),
            titleRef: createRef(),
            leftRef: createRef(),
            leftSubtitleRef: createRef(),
            detailsRef: item.fields?.details.map(() => createRef())
          };
        })
      : null;

  useEffect(() => {}, []);

  useEffect(() => {
    if (showDynamicNumberAnimate) {
      dynamicNumberAnimate(tabNum);
    }
  }, [showDynamicNumberAnimate]);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(data?.sectionDmp);
    // 二级导航高亮设置
    window.navigationIndex = fields?.navigationIndex?.value;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'price_power';
  };
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = 0;
      tabs.map((item, index) => {
        if (item.fields?.tab_name?.value === value) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    // setTabNum(queryTabDefault(fields?.default_tab_name?.value));
    setTimeout(() => {
      switch (getQueryVariable('tab')) {
        case 'hyrid':
          setTabNum(queryTabDefault('hyrid'));
          break;
        case 'plugin':
          setTabNum(queryTabDefault('plugin'));
          break;
        case 'oil':
          setTabNum(queryTabDefault('oil'));
          break;
        case 'control':
          setTabNum(queryTabDefault('control'));
          break;
        default:
          setTabNum(queryTabDefault(fields?.default_tab_name?.value));
      }
    }, 1000);
    return () => {};
  }, []);

  // tab Swiper 初始化
  useEffect(() => {
    tabSwiper.current = new Swiper(tabSwiperEl.current, {
      observer: true,
      slidesPerView: 'auto',
      freeMode: true
    });
    return () => {
      tabSwiper.current.destroy(true, true);
    };
  }, []);

  // content Swiper 初始化
  useEffect(() => {
    contentSwiper.current = new Swiper(contentSwiperEl.current, {
      observer: true,
      slidesPerView: 1,
      freeMode: true,
      modules: [],
      on: {
        slidePrevTransitionEnd(e) {
          setTabNum(e.activeIndex);
          // sendDmp(fields.comparison_left_dmp);
        },
        slideNextTransitionEnd(e) {
          setTabNum(e.activeIndex);
          // sendDmp(fields.comparison_right_dmp);
        }
      }
    });
    return () => {
      contentSwiper.current.destroy(true, true);
    };
  }, []);

  // Tab 动效
  useEffect(() => {
    const showTabController = new ScrollMagic.Controller();
    setShowController(showTabController);
    const showTabTimeline = gsap.timeline({ paused: true });
    let defaultOpacity = isMobile ? 0.3 : 0;
    showTabTimeline.fromTo(tabSwiperEl.current, { opacity: defaultOpacity }, { opacity: 1 });
    const showTabScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 0.3,
      duration: document.documentElement.clientHeight * 0.4
    })
      .on('progress', function (event) {
        showTabTimeline.progress(event.progress);
      })
      .setTween(showTabTimeline)
      .addTo(showTabController);
    return () => {
      showTabTimeline.kill();
      showTabScene.remove();
      showTabScene.destroy();
      showTabController.destroy();
    };
  }, []);

  // content 动效
  useEffect(() => {
    const showController = new ScrollMagic.Controller();
    const showTimeline = gsap.timeline({ paused: true });
    let showSceneList = [];
    tabsRefList.map((item) => {
      let contentLine = [
        { opacity: 1, width: item.content1LineRef.current.style.width, height: item.content1LineRef.current.style.height },
        { opacity: 1, width: item.content2LineRef.current.style.width, height: item.content2LineRef.current.style.height },
        { opacity: 1, width: item.content3LineRef.current.style.width, height: item.content3LineRef.current.style.height }
      ];
      let defaultOpacity = isMobile ? 0.3 : 0;
      showTimeline.fromTo(item.tabRef.current, { opacity: defaultOpacity }, { opacity: 1 });
      if (isMobile) {
        showTimeline.fromTo(item.leftRef.current, { opacity: defaultOpacity }, { opacity: 1 }, '<');
        showTimeline.fromTo(item.leftSubtitleRef.current, { opacity: defaultOpacity }, { opacity: 1 }, '<');
      }
      showTimeline
        .fromTo(item.content1LineRef.current, { opacity: 0, width: 0, height: 0 }, contentLine[0])
        .fromTo(
          item.content1Ref.current,
          { opacity: 0 },
          {
            opacity: 1,
            onComplete: () => {
              setShowshowDynamicNumberAnimate(true);
            }
          }
        )
        .fromTo(item.content2LineRef.current, { opacity: 0, width: 0, height: 0 }, contentLine[1])
        .fromTo(item.content2Ref.current, { opacity: 0 }, { opacity: 1 })
        .fromTo(item.content3LineRef.current, { opacity: 0, width: 0, height: 0 }, contentLine[2])
        .fromTo(item.content3Ref.current, { opacity: 0 }, { opacity: 1 });
      showSceneList.push(
        new ScrollMagic.Scene({
          triggerElement: containerRef.current,
          triggerHook: 0.4,
          duration: document.documentElement.clientHeight * 0.4
        })
          .on('progress', function (event) {
            showTimeline.progress(event.progress);
          })
          .setTween(showTimeline)
          .addTo(showController)
      );
    });

    return () => {
      showTimeline.kill();
      showSceneList.forEach((item) => {
        item.remove();
        item.destroy();
      });
      showController.destroy();
    };
  }, []);

  // details 动效
  useEffect(() => {
    if (tabNum === null) return;
    if (fields?.tab_bottom?.value) return;
    contentSwiper.current.slideTo(tabNum, 0, false);
    let item = tabsRefList[tabNum];
    let showContainerTimelineList = [];
    const showContainerScene = new ScrollMagic.Scene();
    showContainerScene.triggerElement(containerRef.current);
    if (item.detailsRef && item.detailsRef.length > 0) {
      // showContainerTimelineList.push(gsap.timeline({ paused: true }));
      showContainerTimelineList.push(
        gsap
          .timeline({ paused: true })
          .fromTo(item.containerRef.current, { transform: 'translate3d(0, 0px, 0px)' }, { transform: 'translate3d(-100vw, 0px, 0px)', stagger: 1 })
      );
      item.detailsRef.map((detailsItem, detailsIndex) => {
        if (detailsIndex === 0) return;
        showContainerTimelineList.push(gsap.timeline({ paused: true }).fromTo(detailsItem.current, { opacity: 0 }, { opacity: 1 }));
      });
      showContainerScene.triggerHook('onLeave');
      let offset_length = 1;
      if (fields?.stroke_offset_length?.value !== null && fields?.stroke_offset_length?.value + item.detailsRef.length >= 1) {
        offset_length = fields?.stroke_offset_length?.value;
      }
      showContainerScene.duration((item.detailsRef.length + offset_length) * document.documentElement.clientHeight);
      showContainerScene
        .on('progress', function (event) {
          let index = parseInt(event.progress / (1 / (item.detailsRef.length + 1)));
          if (index !== null) {
            if (event.scrollDirection === 'FORWARD' && index >= 1 && index < showContainerTimelineList.length + 1) {
              showContainerTimelineList[index - 1].play();
            } else if (event.scrollDirection === 'REVERSE' && index >= 0 && index < showContainerTimelineList.length) {
              showContainerTimelineList[index].reverse();
            }
          }
        })
        .setPin(containerRef.current)
        .addTo(showController);
    }
    const element = window;
    const scroll = () => {
      const rect = containerRef?.current?.getBoundingClientRect();
      if (showContainerTimelineList && showContainerTimelineList.length > 0 && rect.bottom < 0 && showContainerTimelineList[0].progress() === 0) {
        showContainerTimelineList.forEach((item) => {
          item.progress(100);
        });
      }
    };
    element.addEventListener('scroll', scroll);
    return () => {
      element.removeEventListener('scroll', scroll);
      showContainerTimelineList.forEach((item) => {
        item.progress(0);
        item.kill();
      });
      showContainerScene.remove();
      showContainerScene.destroy(true);
    };
  }, [tabNum]);

  const dynamicNumberAnimate = (index) => {
    if (!tabsRefList[index]?.content1Ref?.current) return;
    if (!fields?.tab_bottom?.value) return;
    Array.from(tabsRefList[index].content1Ref?.current?.getElementsByClassName('dynamic-number'))
      ?.map((item) => ({
        element: item,
        content: item?.innerText
      }))
      ?.forEach((item) => countUp(item?.element, item?.content));
  };

  return (
    <section>
      <div className="price-power-v3" id={params?.pagename !== undefined ? params?.pagename : 'price_power'} ref={containerRef}>
        <div className={`${fields?.tab_bottom?.value ? 'tab_bottom_list' : 'tab_list'}`}>
          <div className="swiper tab_list_swiper" ref={tabSwiperEl}>
            <div className="swiper-wrapper">
              {data.tabs &&
                data.tabs.length > 0 &&
                data.tabs.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {index !== 0 && !fields?.tab_bottom?.value && <div className="split_line" dangerouslySetInnerHTML={{ __html: '|' }} />}
                      <div
                        key={index}
                        className={`swiper-slide tab_list_swiper_slide ${index === tabNum ? 'tab_disabled' : ''}`}
                        onClick={() => {
                          setTabNum(index);
                          sendDmp(item?.fields?.tabDmp);
                          contentSwiper.current.slideTo(index, fields?.tab_bottom?.value ? 1000 : 0, false);
                          if (!fields?.tab_bottom?.value) {
                            setTimeout(() => {
                              containerRef.current.scrollIntoView();
                            }, 0);
                          }
                          dynamicNumberAnimate(index);
                        }}
                      >
                        <div
                          className={index === tabNum ? 'tabContainer_active' : 'tabContainer'}
                          dangerouslySetInnerHTML={{ __html: item.fields?.tab?.value?.replaceHtml() }}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="content_list">
          <div className="swiper content_list_swiper" ref={contentSwiperEl}>
            <div className="swiper-wrapper">
              {data.tabs &&
                data.tabs.length > 0 &&
                data.tabs.map((item, index) => {
                  return (
                    <div key={index} className={`swiper-slide ${fields?.tab_bottom?.value ? '' : 'swiper-no-swiping'} content_list_swiper_slide`}>
                      <div
                        className={`content_container ${
                          item.fields?.details && item.fields?.details.length > 0 && !fields?.tab_bottom?.value ? 'content_container_add_details' : ''
                        }`}
                        ref={tabsRefList[index].containerRef}
                      >
                        <div
                          className={`content_container_mould ${fields?.tab_bottom?.value ? 'content_container_mould_tab_bottom' : ''}`}
                          style={{ height: fields?.tab_bottom?.value ? (isMobile ? '69vh' : '60vh') : '' }}
                        >
                          <div className="content_container_left">
                            <img
                              style={convertStylesStringToObject(item.fields?.container_img_style?.value)}
                              src={`${createFilePath(item.fields?.container_img?.value)}`}
                              alt="广汽本田"
                              ref={tabsRefList[index].leftRef}
                            />
                            {item.fields?.container_subtitle_img?.value && (
                              <img
                                className="container_subtitle_img"
                                style={convertStylesStringToObject(item.fields?.container_subtitle_img_style?.value)}
                                src={`${createFilePath(item.fields?.container_subtitle_img?.value)}`}
                                alt="广汽本田"
                                ref={tabsRefList[index].leftSubtitleRef}
                              />
                            )}
                          </div>
                          <div className="content_container_right">
                            <div className="content_container_title" ref={tabsRefList[index].tabRef}>
                              <img
                                className={`${fields?.tab_bottom?.value ? 'container_img' : ''}`}
                                style={convertStylesStringToObject(item.fields?.title_img_style?.value)}
                                src={`${createFilePath(item.fields?.title_img?.value)}`}
                                alt="广汽本田"
                              />
                              {item.fields?.subtitle_img?.value && item.fields?.subtitle_img?.value !== '' && (
                                <img
                                  style={convertStylesStringToObject(item.fields?.subtitle_img_style?.value)}
                                  src={`${createFilePath(item.fields?.subtitle_img?.value)}`}
                                  alt="广汽本田"
                                />
                              )}
                            </div>
                            <div className="content_container_content1" style={convertStylesStringToObject(item.fields?.content1_style?.value)}>
                              <div
                                ref={tabsRefList[index].content1Ref}
                                dangerouslySetInnerHTML={{
                                  __html: item.fields?.content1?.value?.replaceHtml()
                                }}
                              ></div>
                              <div
                                className="line"
                                ref={tabsRefList[index].content1LineRef}
                                style={convertStylesStringToObject(item.fields?.content1_line_style?.value)}
                              ></div>
                            </div>
                            <div className="content_container_content2" style={convertStylesStringToObject(item.fields?.content2_style?.value)}>
                              <div
                                ref={tabsRefList[index].content2Ref}
                                dangerouslySetInnerHTML={{
                                  __html: item.fields?.content2?.value?.replaceHtml()
                                }}
                              ></div>
                              <div
                                className="line"
                                ref={tabsRefList[index].content2LineRef}
                                style={convertStylesStringToObject(item.fields?.content2_line_style?.value)}
                              ></div>
                            </div>
                            <div className="content_container_content3" style={convertStylesStringToObject(item.fields?.content3_style?.value)}>
                              <div
                                ref={tabsRefList[index].content3Ref}
                                dangerouslySetInnerHTML={{
                                  __html: item.fields?.content3?.value?.replaceHtml()
                                }}
                              ></div>
                              <div
                                className="line"
                                ref={tabsRefList[index].content3LineRef}
                                style={convertStylesStringToObject(item.fields?.content3_line_style?.value)}
                              ></div>
                            </div>
                          </div>
                        </div>
                        {item.fields?.details && item.fields?.details.length > 0 && !fields?.tab_bottom?.value && (
                          <div className="content_container_details">
                            {item.fields?.details.map((detailsItem, detailsIndex) => {
                              return (
                                <div
                                  key={detailsIndex}
                                  className="content_container_details_l"
                                  ref={tabsRefList[index].detailsRef[detailsIndex]}
                                  style={{ backgroundColor: '#FFF' }}
                                >
                                  <div className="content_container_details_list">
                                    <img src={`${createFilePath(detailsItem.fields?.img?.value)}`} alt="广汽本田" />
                                    <div
                                      className="text"
                                      dangerouslySetInnerHTML={{
                                        __html: detailsItem.fields?.text?.value?.replaceHtml()
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {fields?.tab_bottom?.value && (
            <div
              className="content_container_button"
              dangerouslySetInnerHTML={{
                __html: data.tabs[tabNum]?.fields?.content_button?.value?.replaceHtml()
              }}
              onClick={() => {
                setNum(tabNum);
                setFlag(true);
                sendDmp(data.tabs[tabNum]?.fields?.content_button_dmp);
              }}
            ></div>
          )}
        </div>
        {tabNum === 1 && data.tabs[tabNum]?.fields?.btn?.value && (
          <div className="btn">
            <button
              className="btnDetails"
              onClick={() => {
                sendDmp(data.tabs[tabNum].fields.btnDmp, data.tabs[tabNum].fields.btnHref?.value);
                window.open(data.tabs[tabNum].fields.btnHref?.value);
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: data.tabs[tabNum].fields.btn?.value?.replaceHtml() }} />
            </button>
          </div>
        )}
        {flag && <Details id="activity" routePath={data.tabs[num].fields.detail_href.value} close={() => setFlag(false)} />}
      </div>
    </section>
  );
}
