import React, { useEffect, useRef, useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, SplitWords, Timeline } from 'react-gsap';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import temp from '../../temp/config';
import './style.scss';
import { isImage, convertStylesStringToObject, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const {
    video,
    m_video,
    m_kvhtml,
    kvhtml,
    m_noMask,
    noMask,
    btn_style,
    textScroll,
    m_textScroll,
    dialog_video,
    cursor,
    flag,
    open,
    id,
    disableAnimate
  } = props;
  const thisMobile = isMobileFun();
  const textArr = thisMobile ? m_textScroll?.value.split('-') : textScroll?.value.split('-');
  const kvhtmlValue = thisMobile ? m_kvhtml?.value : kvhtml?.value;
  const isNoMask = thisMobile ? m_noMask?.value : noMask?.value;
  const rootEl = useRef(null);

  return (
    <section>
      <Controller>
        <Scene triggerHook={'onLeave'} duration={'100%'} pin={!disableAnimate?.value}>
          {(progress, event) => {
            return (
              <div>
                <Timeline totalProgress={disableAnimate?.value ? 1 : progress} paused>
                  <Tween from={{ scale: 0.9 }} to={{ scale: 1 }} position={1}>
                    <div
                      className={'text-scroll-y-telling_v2'}
                      style={{ cursor: `url(${createFilePath(cursor.value)}), pointer` }}
                      ref={rootEl}
                      onClick={() => {
                        !thisMobile && open();
                      }}
                    >
                      <Tween from={{ scale: 1 }} to={{ scale: 1 }} position={2} stagger={0.2}>
                        {thisMobile ? (
                          <img alt="广汽本田" className="gifSource" src={`${createFilePath(m_video?.value)}`} />
                        ) : isImage(video?.value) ? (
                          <img alt="广汽本田" className="gifSource" src={`${createFilePath(video?.value)}`} />
                        ) : (
                          <video
                            x5-playsinline="true"
                            webkit-playsinline="true"
                            playsInline={true}
                            src={`${createFilePath(video?.value)}`}
                            autoPlay="autoplay"
                            muted={true}
                            loop
                          />
                        )}
                      </Tween>
                      <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={2} stagger={0.2}>
                        <div className="text-container" style={{ backgroundColor: isNoMask ? 'transparent' : 'rgba(0, 0, 0, 0.5)' }}>
                          <div dangerouslySetInnerHTML={{ __html: kvhtmlValue.replaceHtml() }} />
                          <div
                            className={`split-text ${disableAnimate?.value ? '' : `animate__animated ${progress > 0.4 ? 'animate__fadeInUp' : ''}`}`}
                            style={{ display: disableAnimate?.value ? 'block' : '', fontSize: thisMobile ? '' : props?.fontStyle?.value }}
                          >
                            {textArr.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Tween from={{ opacity: 0.2 }} to={{ opacity: 1 }} position={5 + index} stagger={0.2} ease="elastic.out(0.1, 0.1)">
                                    <SplitWords
                                      wrapper={
                                        <div style={{ display: 'inline-block', marginLeft: '-15px', minWidth: thisMobile ? '0.25rem' : '1.9rem' }} />
                                      }
                                      delimiter={'^'}
                                    >
                                      {item}
                                    </SplitWords>
                                  </Tween>
                                  <p></p>
                                </React.Fragment>
                              );
                            })}
                            {thisMobile && (
                              <button
                                className="MobileInsertBtn_v2"
                                style={convertStylesStringToObject(btn_style?.value)}
                                onClick={() => {
                                  open();
                                }}
                              >
                                完整视频
                              </button>
                            )}
                          </div>
                        </div>
                      </Tween>
                    </div>
                  </Tween>
                </Timeline>
              </div>
            );
          }}
        </Scene>
      </Controller>
      <div id={id.value} />
    </section>
  );
}
