import React from 'react';
import './style.scss';
import temp from '../../temp/config';
import { createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { fields, popOldShow, setPopOldShow } = props;
  // const deployUrlS3 = temp.app.deployUrlS3;
  const oldDealearData = fields?.oldDealerData?.value ? JSON.parse(fields.oldDealerData.value.replaceHtml()) : '';

  return (
    <section>
      {popOldShow ? (
        <div className="pop_old">
          <div className="pop_old_content">
            <div className="pop_content">
              <img
                alt="广汽本田"
                src={createFilePath('/service/recall/pop-close.png')}
                className="pop_old_close"
                onClick={() => setPopOldShow(false)}
              />
              <p className="title">停止合作特约店名单</p>
              <table>
                <tbody>
                  <tr>
                    <th>序号</th>
                    <th>城市</th>
                    <th>店名</th>
                    <th>公司名称</th>
                    <th>通讯地址</th>
                  </tr>
                  {oldDealearData.oldDealears &&
                    oldDealearData.oldDealears.length > 0 &&
                    oldDealearData.oldDealears.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.index}</td>
                          <td>{item.city}</td>
                          <td>{item.name}</td>
                          <td>{item.fullName}</td>
                          <td>{item.address}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
}
