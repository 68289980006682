import React, { useEffect, useRef, useState, useImperativeHandle, createRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import temp from '../../temp/config';
import { getPrefixField, sendDmp, convertStylesStringToObject, isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { fields, eventRef } = props;
  const containerRef = useRef(null);
  const scene1Ref = useRef(null);
  const text1Ref = useRef(null);
  const scene2Ref = useRef(null);
  const scene2FrontRef = useRef(null);
  const scene3Ref = useRef(null);
  const scene3BackRef = useRef(null);
  const scene4Ref = useRef(null);
  // const deployUrlS3 = temp.app.deployUrlS3;
  const isMobile = isMobileFun();
  const slideNum = fields.slideScreen?.value;
  const img1 = fields[getPrefixField('image1', isMobile)]?.value;
  const img1_loop = fields[getPrefixField('image1_loop', isMobile)]?.value;
  const img2 = fields[getPrefixField('image2', isMobile)]?.value;
  const img2_style = fields[getPrefixField('image2_style', isMobile)]?.value;
  const img2_open = fields[getPrefixField('image2_open', isMobile)]?.value;
  const img3_open = fields[getPrefixField('image3_open', isMobile)]?.value;
  const img3 = fields[getPrefixField('image3', isMobile)]?.value;
  const img3_style = fields[getPrefixField('image3_style', isMobile)]?.value;
  const text3_style = fields[getPrefixField('text3_style', isMobile)]?.value;
  const text1 = fields[getPrefixField('text1', isMobile)]?.value;
  const text2_title = fields[getPrefixField('text2_title', isMobile)]?.value;
  const text2_describe = fields[getPrefixField('text2_describe', isMobile)]?.value;
  const text3_title = fields[getPrefixField('text3_title', isMobile)]?.value;
  const text3_describe = fields[getPrefixField('text3_describe', isMobile)]?.value;
  const list = fields[getPrefixField('list', isMobile)];
  const [imgLoaded, setImgLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [timelines, setTimelines] = useState([]);
  const [data, _] = useState({
    slideNum,
    img1,
    img1_loop,
    img2,
    img2_style,
    img2_open,
    img3,
    img3_style,
    img3_open,
    text1,
    text2_title,
    text2_describe,
    text3_title,
    text3_describe,
    list,
    isMobile,
    text3_style
  });
  const scene4RefList = data.list.map(() => createRef());
  const videoRefList = data.list.map(() => createRef());

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields?.sectionDmp);
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : '';
  };

  const isPng = (value) => {
    if (value !== '') {
      return value.indexOf('png') !== -1 || value.indexOf('jpg') !== -1 || value.indexOf('gif') !== -1;
    }
  };

  useEffect(() => {
    require('swiped-events');
  }, []);

  useEffect(() => {
    if (isMobile && timelines?.length > 0) {
      containerRef.current.addEventListener('swiped-left', right);
      containerRef.current.addEventListener('swiped-right', left);
    }
    return () => {
      if (isMobile && timelines?.length > 0) {
        containerRef.current.removeEventListener('swiped-left', right);
        containerRef.current.removeEventListener('swiped-right', left);
      }
    };
  }, [timelines, tabIndex]);

  useEffect(() => {
    if (!data || !videoLoaded) {
      return;
    }

    let scene2TimelineList = isMobile
      ? [
          gsap
            .timeline({ paused: true })
            .fromTo(scene2Ref.current, { clip: 'rect(0 0 100vh 0)' }, { clip: 'rect(0 100vw 100vh 0)', duration: 1 })
            .fromTo(scene2FrontRef.current, { opacity: 0 }, { opacity: 1, duration: 1 }, 1),
          gsap
            .timeline({ paused: true })
            .fromTo(scene3Ref.current, { clip: 'rect(0 100vw 100vh 100vw)' }, { clip: 'rect(0 100vw 100vh 0vw)', duration: 1 })
            .fromTo(scene3BackRef.current, { opacity: 0 }, { opacity: 1, duration: 1 }, 1)
            .fromTo(scene4Ref.current, { opacity: 0 }, { opacity: 1 }, '<'),
          gsap
            .timeline({ paused: true })
            .fromTo(scene3Ref.current, { y: '0' }, { y: '-100vh', duration: 1.5 })
            .fromTo(scene1Ref.current, { opacity: 1 }, { opacity: 0 }, '<')
        ]
      : [
          gsap
            .timeline({ paused: true })
            .fromTo(scene2Ref.current, { clip: 'rect(0 0 100vh 0)' }, { clip: 'rect(0 100vw 100vh 0)', duration: 1 })
            .fromTo(scene2FrontRef.current, { opacity: 0 }, { opacity: 1, duration: 1 }, 1),
          gsap
            .timeline({ paused: true })
            .fromTo(scene3Ref.current, { clip: 'rect(0 100vw 100vh 100vw)' }, { clip: 'rect(0 100vw 100vh 0vw)', duration: 1 })
            .fromTo(scene3BackRef.current, { opacity: 0 }, { opacity: 1, duration: 1 }, 1)
            .fromTo(scene4Ref.current, { opacity: 0 }, { opacity: 1 }, '<'),
          gsap
            .timeline({ paused: true })
            .fromTo(scene3Ref.current, { y: '0' }, { y: '-100vh', duration: 1 })
            .fromTo(scene1Ref.current, { opacity: 1 }, { opacity: 0 }, '<')
        ];

    for (let i = 0; i < data.list.length - 1; i++) {
      if (isMobile) {
        scene2TimelineList.push(gsap.timeline({ paused: true }).fromTo(scene4RefList[i].current, { opacity: 1 }, { opacity: 0, duration: 1.5 }));
      } else {
        scene2TimelineList.push(gsap.timeline({ paused: true }).fromTo(scene4RefList[i].current, { x: '0' }, { x: '100vw', duration: 1 }));
      }
    }

    setTimelines(scene2TimelineList);
  }, [videoLoaded]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const controller = new ScrollMagic.Controller();
    const scene1Timeline = gsap.timeline({ pause: true });
    scene1Timeline.fromTo(text1Ref.current, { opacity: 0 }, { opacity: 1 });
    const scene1 = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onEnter',
      duration: '100%'
    })
      .on('progress', function (event) {
        scene1Timeline.progress(event.progress);
      })
      .setTween(scene1Timeline)
      .addTo(controller);

    return () => {
      scene1Timeline.kill();
      scene1.remove();
      scene1.destroy();

      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (isPng(data.list[0].fields?.img?.value)) {
      setVideoLoaded(true);
    }
    const myVideo = document.getElementsByClassName('scene4Item_video')[0];
    function checkforVideo() {
      var videoLoad = setInterval(() => {
        if (myVideo.readyState >= 3) {
          setVideoLoaded(true);
          clearInterval(videoLoad);
        }
      }, 500);
    }

    if (myVideo) {
      checkforVideo();
    }
  }, [imgLoaded]);

  const left = () => {
    if (tabIndex === 0) {
      return;
    }
    setTabIndex(tabIndex - 1);
    for (let i = 0; i < timelines.length; i++) {
      if (i < tabIndex - 1) {
        timelines[i].progress(100);
      } else if (i === tabIndex - 1) {
        timelines[i].reverse();
        if (videoRefList[i - 2]?.current) {
          videoRefList[i - 2].current.pause();
        }
      }
    }
    sendDmp(fields?.left_dmp);
  };

  const right = () => {
    if (tabIndex === timelines.length) {
      return;
    }
    setTabIndex(tabIndex + 1);
    for (let i = 0; i < timelines.length; i++) {
      if (i < tabIndex) {
        timelines[i].progress(100);
      } else if (i === tabIndex) {
        timelines[i].play();
        if (videoRefList[i - 2]?.current) {
          videoRefList[i - 2].current.autoPlay = true;
          videoRefList[i - 2].current.play();
        }
      }
    }
    sendDmp(fields?.right_dmp);
  };

  return (
    <section>
      {data ? (
        <div className="exterior_v7" ref={containerRef} id={props?.params?.pagename}>
          <div className="scene1" ref={scene1Ref}>
            {isPng(data.img1) ? (
              <img alt="广汽本田" className="scene1_img" src={`${createFilePath(data.img1)}`} />
            ) : (
              <video src={`${createFilePath(data.img1)}`} autoPlay={true} muted={true} loop={data.img1_loop} className="scene1_video" />
            )}
            <div className="scene1_text" ref={text1Ref} dangerouslySetInnerHTML={{ __html: data.text1?.replaceHtml() }} />
          </div>
          <div className="scene2" ref={scene2Ref}>
            <img
              alt="广汽本田"
              className="scene2_img_close"
              src={`${createFilePath(data.img2)}`}
              onLoad={() => setImgLoaded(true)}
              style={convertStylesStringToObject(data.img2_style)}
            />
            <div className="scene2_front" ref={scene2FrontRef}>
              <img
                alt="广汽本田"
                className="scene2_img_open"
                src={`${createFilePath(data.img2_open)}`}
                style={convertStylesStringToObject(data.img2_style)}
              />
              <div className="scene2_text">
                <div className="scene2_title" dangerouslySetInnerHTML={{ __html: data.text2_title?.replaceHtml() }} />
                <div className="scene2_describe" dangerouslySetInnerHTML={{ __html: data.text2_describe?.replaceHtml() }} />
              </div>
            </div>
          </div>
          <div className="scene3" ref={scene3Ref}>
            <img
              alt="广汽本田"
              className="scene3_img_close"
              src={`${createFilePath(data.img3)}`}
              style={convertStylesStringToObject(data.img3_style)}
            />
            <div className="scene3_back" ref={scene3BackRef}>
              <img
                alt="广汽本田"
                className="scene3_img_open"
                src={`${createFilePath(data.img3_open)}`}
                style={convertStylesStringToObject(data.img3_style)}
              />
              <div className="scene3_text" style={convertStylesStringToObject(data.text3_style)}>
                <div className="scene3_title" dangerouslySetInnerHTML={{ __html: data.text3_title?.replaceHtml() }} />
                <div className="scene3_describe" dangerouslySetInnerHTML={{ __html: data.text3_describe?.replaceHtml() }} />
              </div>
            </div>
          </div>
          <div className="scene4" ref={scene4Ref}>
            {data.list &&
              data.list.map((item, index) => (
                <div
                  className="scene4Item"
                  key={index}
                  style={convertStylesStringToObject(item.fields?.scene_style?.value)}
                  ref={scene4RefList[index]}
                >
                  {isPng(item.fields?.img?.value) ? (
                    <img
                      alt="广汽本田"
                      className="scene4Item_img"
                      src={`${createFilePath(item.fields?.img?.value)}`}
                      style={convertStylesStringToObject(item.fields?.img_style?.value)}
                    />
                  ) : (
                    <video
                      src={`${createFilePath(item.fields?.img?.value)}`}
                      autoPlay={false}
                      muted={true}
                      loop={item.fields?.img_loop?.value}
                      className="scene4Item_video"
                      ref={videoRefList[index]}
                      style={convertStylesStringToObject(item.fields?.img_style?.value)}
                    />
                  )}
                  <div className="scene4Item_text" dangerouslySetInnerHTML={{ __html: item.fields?.text?.value.replaceHtml() }} />
                </div>
              ))}
          </div>
          {tabIndex !== 0 && (
            <img
              alt="广汽本田"
              className="left"
              onClick={() => {
                /* left(tabIndex);
                setTabIndex(tabIndex - 1); */
                left();
              }}
              src={createFilePath('/common/icon/VectorLeftWhiteGrey.png')}
            />
          )}
          {tabIndex !== timelines.length && (
            <img
              alt="广汽本田"
              className="right"
              onClick={() => {
                /* setTabIndex(tabIndex + 1);
                right(tabIndex + 1); */
                right();
              }}
              src={createFilePath('/common/icon/VectorRightWhiteGrey.png')}
            />
          )}
        </div>
      ) : null}
    </section>
  );
}
