import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import './style.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { EffectFade, Navigation, Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import '../PriceSpace_v3/style_breezePet.scss';
import Details from '../../elements/InnerPageDetail';
import SwiperDetails from '../PriceSpace_v3/SwiperDetails/index';
import Breath from '../PriceSpace_v3/breath';
import temp from '../../temp/config';
import { getPrefixField, sendDmp, convertStylesStringToObject, createFilePath, isMobileFun } from '../../utils/obj-utils';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getQueryVariable } from '../../utils/site-utils';

export default function index(props) {
  const { fields } = props;
  const [windowHeight, setWindowHeight] = useState(400);
  const [flag, setFlag] = useState(false);
  const isMobile = isMobileFun();
  const [tabNum, setTabNum] = useState(0);
  const tabs = fields?.tabs;
  const [num, setNum] = useState(0);
  const [breathIndex, setBreathIndex] = useState(-1);
  const [num2, setNum2] = useState(1);
  const rootEl = useRef(null);
  const tabswiperel = useRef(null);
  const swiperel = useRef(null);
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const secondPageRef = useRef(null);
  const sectionDmp = fields?.sectionDmp;
  const placeHolderRoute = fields.placeHolderRoute.value;
  const defaultTab = fields.default_tab_name.value;
  const [data, _] = useState({
    isMobile,
    tabs,
    sectionDmp,
    placeHolderRoute,
    defaultTab
  });

  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      data.tabs.map((item, index) => {
        let tabArr = item.fields?.tab_name?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(data.defaultTab.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabNum(tabInd < 0 ? (defaultTabInd < 0 ? tabNum : defaultTabInd) : tabInd);
      } else {
        setTabNum(defaultTabInd < 0 ? tabNum : defaultTabInd);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);

  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields.window_dmp);
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : '';
  };

  return (
    <section className="navigationMachine_v3">
      {/* <div className="tab_list"> */}
      <div className={['tab_list', tabNum === 0 ? 'white' : ''].join(' ')}>
        {data.tabs &&
          data.tabs.length > 0 &&
          data.tabs.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {index !== 0 && <div className="split_line" dangerouslySetInnerHTML={{ __html: fields?.split_line.value?.replaceHtml() }} />}
                <div
                  className={`tab_item ${index === tabNum ? 'tab_disabled' : ''}`}
                  onClick={() => {
                    setTabNum(index);
                    sendDmp(item?.fields?.tabDmp);
                  }}
                >
                  <div
                    className={index === tabNum ? 'tabContainer_active' : 'tabContainer'}
                    dangerouslySetInnerHTML={{ __html: item.fields?.tab?.value?.replaceHtml() }}
                  />
                </div>
              </React.Fragment>
            );
          })}
      </div>
      {tabNum > -1 ? (
        <div>
          <Controller>
            <Scene triggerHook={'onEnter'} duration={windowHeight}>
              {(progress, event) => {
                return (
                  <div>
                    <Timeline totalProgress={progress} paused>
                      <div className="NavigationMachine_v1" id={!tabs[tabNum].fields?.noHeaderId?.value ? props?.params?.pagename : ''} ref={rootEl}>
                        <div
                          className="black"
                          style={{ display: tabs[tabNum].fields[`${getPrefixField('hide_black', isMobile)}`]?.value ? 'none' : '' }}
                        >
                          <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={1} stagger={0.2}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: tabs[tabNum].fields[`${getPrefixField('first_page_content', isMobile)}`]?.value?.replaceHtml()
                              }}
                            />
                          </Tween>
                        </div>
                        <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={2} stagger={0.2}>
                          <Swiper
                            ref={swiperel}
                            id={tabs[tabNum].fields?.noHeaderId?.value ? props?.params?.pagename : ''}
                            className="contentSwiper"
                            initialSlide={0}
                            modules={[Pagination, EffectFade]}
                            effect={tabs[tabNum].fields[`${getPrefixField('swiper_effect', isMobile)}`]?.value || 'slide'}
                            pagination={{
                              clickable: true,
                              bulletClass: 'tabContainer',
                              bulletActiveClass: 'tabContainer_active',
                              renderBullet: (index, className) => {
                                return isMobile
                                  ? '<span />'
                                  : `<span key=${index} class=${className}>${
                                      tabs[tabNum].fields[`${getPrefixField('content_list', isMobile)}`][index].fields.tab.value
                                    }</span>`;
                              }
                            }}
                            onSlideChange={(swiper) => {
                              setNum(swiper.activeIndex);
                              window.Dmp.clickAction(
                                tabs[tabNum].fields[`${getPrefixField('content_list', isMobile)}`][swiper.activeIndex].fields.tab_dmp[0]?.displayName
                              );
                              isMobile && tabswiperel.current && tabswiperel.current.swiper.slideTo(swiper.activeIndex);
                            }}
                          >
                            {tabs[tabNum].fields[`${getPrefixField('content_list', isMobile)}`].length &&
                              tabs[tabNum].fields[`${getPrefixField('content_list', isMobile)}`].map((ele, index) => {
                                return (
                                  <SwiperSlide key={index}>
                                    <div className="sildeContainer">
                                      {ele.fields.back_type?.fields.v.value === 'video' ? (
                                        <video
                                          src={createFilePath(ele.fields.back_source?.value)}
                                          autoPlay="autoplay"
                                          muted={true}
                                          loop={true}
                                          style={convertStylesStringToObject(ele.fields.back_source_style?.value)}
                                        />
                                      ) : (
                                        <img
                                          alt="广汽本田"
                                          src={createFilePath(ele.fields.back_source?.value)}
                                          style={convertStylesStringToObject(ele.fields.back_source_style?.value)}
                                        />
                                      )}
                                      <div className="NavigationMachineText">
                                        <div>
                                          {ele.fields.title_img?.value && (
                                            <div className="titleImg">
                                              <img
                                                alt="广汽本田"
                                                src={createFilePath(ele.fields.title_img?.value)}
                                                style={convertStylesStringToObject(ele.fields.title_img_style?.value)}
                                              />
                                            </div>
                                          )}
                                          {ele.fields.title_text?.value && (
                                            <div dangerouslySetInnerHTML={{ __html: ele.fields.title_text.value?.replaceHtml() }} />
                                          )}
                                          {ele.fields.content?.value && (
                                            <div dangerouslySetInnerHTML={{ __html: ele.fields.content.value?.replaceHtml() }} />
                                          )}
                                          {ele.fields.detail_btn?.value && (
                                            <div
                                              onClick={() => {
                                                sendDmp(ele.fields.btn_dmp);
                                                setFlag(true);
                                              }}
                                              dangerouslySetInnerHTML={{ __html: ele.fields.detail_btn.value?.replaceHtml() }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      {ele.fields.specific &&
                                        ele.fields.specific.length > 0 &&
                                        ele.fields.specific.map((item, index) => {
                                          return (
                                            <div
                                              key={index}
                                              onClick={() => {
                                                localStorage.setItem('kk', index);
                                                setBreathIndex(index);
                                                window.Dmp.clickAction(item.fields.breathDmp[0]?.displayName, '', { val: item.fields.name.value });
                                              }}
                                            >
                                              <Breath className={`breathing  breathingFlag${index + 1}${ele.fields.styleBox.value}`}></Breath>
                                            </div>
                                          );
                                        })}
                                      {breathIndex !== -1 && ele.fields.specific && ele.fields.specific.length > 0 && (
                                        <SwiperDetails
                                          fields={{
                                            num: breathIndex,
                                            setNum: setBreathIndex,
                                            specific: ele.fields.specific,
                                            close: ele.fields.close.value,
                                            closeDmp: ele.fields.specific[breathIndex].fields.closeDmp[0]?.displayName,
                                            val: ele.fields.specific[breathIndex].fields.name.value,
                                            styleBox: ele.fields.styleBox.value,
                                            num2,
                                            dmpSwiperName: ele.fields.dmpSwiperName.value
                                          }}
                                        ></SwiperDetails>
                                      )}
                                    </div>
                                  </SwiperSlide>
                                );
                              })}
                          </Swiper>
                        </Tween>
                        {isMobile && (
                          <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={3} stagger={0.2}>
                            <div>
                              <Swiper
                                ref={tabswiperel}
                                className="tabSwiper"
                                centeredSlides={true}
                                slidesPerView={
                                  !isMobile
                                    ? tabs[tabNum].fields[`${getPrefixField('content_list', isMobile)}`].length >= 3
                                      ? 3
                                      : tabs[tabNum].fields[`${getPrefixField('content_list', isMobile)}`].length
                                    : 1.8
                                }
                                modules={[Navigation]}
                                navigation={false}
                              >
                                {tabs[tabNum].fields[`${getPrefixField('content_list', isMobile)}`].length &&
                                  tabs[tabNum].fields[`${getPrefixField('content_list', isMobile)}`].map((ele, index) => {
                                    return (
                                      <SwiperSlide
                                        key={index}
                                        onClick={() => {
                                          setNum(index);
                                          rootEl.current.querySelector('.contentSwiper').swiper.slideTo(index);
                                        }}
                                      >
                                        <div
                                          className={index === num ? 'tabContainer tabContainer_active' : 'tabContainer'}
                                          dangerouslySetInnerHTML={{ __html: ele.fields.tab.value?.replaceHtml() }}
                                        ></div>
                                      </SwiperSlide>
                                    );
                                  })}
                              </Swiper>
                            </div>
                          </Tween>
                        )}
                      </div>
                    </Timeline>
                  </div>
                );
              }}
            </Scene>
          </Controller>
          {flag && (
            <Details
              routePath={tabs[tabNum].fields[`${getPrefixField('content_list', isMobile)}`][num]?.fields?.detail_href?.value}
              close={() => setFlag(false)}
            />
          )}
        </div>
      ) : (
        <div ref={secondPageRef} key={+new Date()}>
          <Placeholder name={data?.placeHolderRoute} rendering={route} page={route} />
        </div>
      )}
    </section>
  );
}
