import React, { useState, useEffect } from 'react';
import './style.scss';
import { createFilePath, getQueryString, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { dataFetcher } from '../../dataFetcher';
import temp from '../../temp/config';

export default function index(props) {
  const isMobile = isMobileFun();
  const [inputValue, setInputValue] = useState(getQueryString('keyword') ? getQueryString('keyword') : '');
  const [keywords, setKeywords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    dataFetcher('/api/honda/v1/Search/GetSearchKeyWords')
      .then(({ data }) => {
        if (data.Status === true) {
          setKeywords(data.Data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    dataFetcher(`/api/honda/v1/Search/GetSearchData?pageIndex=${pageIndex}&pageSize=10&keyword=${inputValue}`)
      .then(({ data }) => {
        if (data.Status === true) {
          setTotalCount(data.Data.TotalCount);
          setList(data.Data.SearchModels);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [search]);

  const loadMore = (num) => {
    dataFetcher(`/api/honda/v1/Search/GetSearchData?pageIndex=${num}&pageSize=10&keyword=${inputValue}`)
      .then(({ data }) => {
        if (data.Status === true) {
          let dataList = list;
          setTotalCount(data.Data.TotalCount);
          setList([...dataList, ...data.Data.SearchModels]);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <section>
      <div className="search_v1">
        <div className="search_main">
          <div className="searchTit">站内搜索</div>
          <div className="searchBox">
            <input
              name="SearchTxt"
              type="text"
              className="getFocus"
              placeholder="请输入关键字"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              onBlur={(e) => {
                window.Dmp.msgAction('search-text', e.target.value);
              }}
            />
            <input
              value=""
              name="SearchBtn1"
              type="button"
              className="searchBtn"
              style={{
                background: `url(${createFilePath('/common/searchicon.png')}) center center no-repeat`,
                backgroundSize: '100%'
              }}
              onClick={() => {
                setPageIndex(1);
                setSearch((search) => !search);
                window.Dmp.clickAction('search-btn1', `${location.origin}${location.pathname}?keyword=${inputValue}`);
                location.href = `${location.origin}${location.pathname}?keyword=${inputValue}`;
              }}
            />
          </div>
          <div className="searchHot">
            <span className="hotTit">热门搜索：</span>
            {keywords.length > 0 &&
              keywords.map((item, index) => {
                return (
                  <a
                    key={index}
                    onClick={() => {
                      setPageIndex(1);
                      setInputValue(item);
                      setSearch((search) => !search);
                      window.Dmp.msgAction('search-keys', item);
                      location.href = `${location.origin}${location.pathname}?keyword=${item}`;
                    }}
                  >
                    {item}
                  </a>
                );
              })}
          </div>
          <div className="searchResult">
            <p className="resultTit">搜索结果：</p>
            <p className="resultTotal">
              为您找到<i> {totalCount}</i>条记录
            </p>
            <ul className="resultList">
              {list &&
                list.length > 0 &&
                list.map((item, index) => {
                  return (
                    <li className="listItem" key={index}>
                      <a
                        className="itemLink"
                        onClick={() => {
                          if (item.ArticleTitle.indexOf('二十周年') > -1) {
                            window.Dmp.clickAction('search-link', createFilePath('/service/environment/pdf/change_pdf/CSR报告官网版0627.pdf'));
                            window.open(createFilePath('/service/environment/pdf/change_pdf/CSR报告官网版0627.pdf'), '_blank');
                          } else if (item.ArticleTitle.indexOf('广汽本田公益二十年') > -1) {
                            window.Dmp.clickAction('search-link', createFilePath('/service/environment/pdf/change_pdf/公益二十年印刷版.pdf'));
                            window.open(createFilePath('/service/environment/pdf/change_pdf/公益二十年印刷版.pdf'), '_blank');
                          } else {
                            window.Dmp.clickAction('search-link', item.HrefUrl);
                            location.href = item.HrefUrl;
                          }
                        }}
                      >
                        <p className="linkTit">{item.ArticleTitle}</p>
                        <p className="linkTime">{item.PublishDate}</p>
                        <i
                          className="linkArrow"
                          style={{
                            background: `url(${createFilePath('/common/arrow.png')}) center center no-repeat`,
                            backgroundSize: '100%'
                          }}
                        />
                      </a>
                    </li>
                  );
                })}
            </ul>
            {pageIndex * 10 < totalCount && (
              <div
                className="addMoreBtn"
                onClick={() => {
                  loadMore(pageIndex + 1);
                  setPageIndex((pageIndex) => pageIndex + 1);
                  window.Dmp.clickAction('search-more');
                }}
              >
                加载更多
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
