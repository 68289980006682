import React, { useState, useEffect } from 'react';
import './style.scss';
import temp from '../../temp/config';
import { isMobileFun, createFilePath } from '../../utils/obj-utils';
let wx = null;
export const prepareWechatSdk = () => {
  if (!wx) {
    wx = require('weixin-js-sdk');
  }
  return wx;
};
export default function HeaderNav(props) {
  if (!props?.showFooter && !props?.fields?.flag?.value) {
    return null;
  }
  const [opacityFlag, setOpacityFlag] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 60) {
        setOpacityFlag(true);
      } else {
        setOpacityFlag(false);
      }
    });
  }, []);
  const [Refresh, setReFresh] = useState(0);
  return (
    <div className={'HeaderBlack_v2'} style={{ opacity: opacityFlag ? 0 : 1, background: `${props.fields.backColor.value}` }}>
      <div className="headerBoxLeft_v2">
        <div className="headerLogo_v2">
          <img
            data-action="clickAction"
            data-item="catalog-back"
            data-url="/"
            // src={`${temp.app.deployUrlS3}${props.fields.logo.value}`}
            src={createFilePath(props.fields.logo.value)}
            alt="广汽本田"
            onClick={() => {
              window.open(`${props.fields.logoUrl.value}`, '_self');
            }}
          />
        </div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
