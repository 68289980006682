import React, { useState, useRef, useImperativeHandle, useEffect, createRef } from 'react';
import './style.scss';
import './style_odyssey.scss';
import './style_crider.scss';
import './style_avancier.scss';
import './style_integra_hyrid.scss';
import './style_vezel.scss';
import temp from '../../temp/config';
import Details from './details/index';
import Details1 from '../../elements/InnerPageDetail';
import SwiperDetails from './SwiperDetails/index';
import { useSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Breath from './breath';
import VideoIndex from './videoIndex';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import gsap from 'gsap';
import { imageLoaded, sendDmp, isMobileFun, createFilePath, convertStylesStringToObject, getPrefixField } from '../../utils/obj-utils';

export default function index(props) {
  // console.log(props);
  const thisMobile = !isMobileFun();
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const rootEl = useRef(null);
  const secondPageRef = useRef(null);
  const secondPageEventRef = useRef(null);
  const securityIntegraImgRef = useRef(null);
  const securityIntegraInnerTextRef = useRef(null);
  const securityIntegraInnerButton1Ref = useRef(null);
  const securityIntegraInnerButton2Ref = useRef(null);
  const [windowWidth, setWindowWidth] = useState(null);
  const [flag, setFlag] = useState(null);
  const [detailHref, setDetailHref] = useState(null);
  useEffect(() => {
    if (props.fields?.placeHolderRoute?.value) {
      props.updateRefList([sendFirstDmp, sendSecondDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current]);
    } else {
      props.updateRefList([sendFirstDmp]);
      props.updateDmpList([rootEl?.current]);
    }
  }, []);
  useEffect(() => {
    imageLoaded(securityIntegraImgRef, () => {
      setWindowWidth(document.documentElement.clientWidth);
      window.addEventListener('resize', resizeEvent);
    });
    const resizeEvent = () => {
      setWindowWidth(document.documentElement.clientWidth);
    };
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useEffect(() => {
    if (props.fields.isShowVideo.value || !props.fields.isLongImg.value || !windowWidth) return;
    const showLongImgController = new ScrollMagic.Controller();
    const showLongImgTimeline = gsap.timeline({ paused: true });
    let x = (windowWidth - securityIntegraImgRef.current.width) / 2;
    let ratio = securityIntegraImgRef.current.height / securityIntegraImgRef.current.width;
    showLongImgTimeline
      .fromTo(
        securityIntegraImgRef.current,
        { transform: `translate3d(${x}px, calc(-${(securityIntegraImgRef.current.height / 120) * 20}px), 0px)` },
        {
          transform: `translate3d(${x}px, calc(-${(securityIntegraImgRef.current.height / 120) * 100}px + 100vh), 0px)`,
          duration: 4
        }
      )
      .addLabel('img')
      .to(securityIntegraImgRef.current, {
        duration: 1,
        opacity: 1,
        width: '100%',
        transform: `translate3d(0px, calc(-${(securityIntegraImgRef.current.height / 120) * 100}px + 100vh), 0px)`
      })
      .fromTo(
        securityIntegraInnerTextRef.current,
        { bottom: thisMobile ? '50vh' : '-25vh', position: 'absolute', left: 0, right: 0 },
        { duration: 1, bottom: thisMobile ? '84vh' : '9vh' },
        '<'
      )
      .fromTo(securityIntegraInnerTextRef.current, { opacity: 1, zIndex: 1 }, { duration: 0.1, opacity: 0, zIndex: -1 }, '<90%')
      .fromTo(securityIntegraInnerButton1Ref.current, { opacity: 0 }, { duration: 1, opacity: 1 }, '<')
      .fromTo(securityIntegraInnerButton2Ref.current, { opacity: 0 }, { duration: 1, opacity: 1 }, '<');

    if (specificRefList && specificRefList.length > 0) {
      for (let i = 0; i < specificRefList.length; i++) {
        showLongImgTimeline.fromTo(specificRefList[i].current, { opacity: 0 }, { duration: 1, opacity: 1 }, '<');
      }
    }
    showLongImgTimeline.fromTo(securityIntegraInnerButton2Ref.current, {}, { duration: 1 });
    const showLongImgScene = new ScrollMagic.Scene({
      triggerElement: rootEl.current,
      triggerHook: 'onLeave',
      duration: document.documentElement.clientHeight * 2
    })
      .on('progress', function (event) {
        showLongImgTimeline.progress(event.progress);
      })
      .setPin(rootEl.current)
      .setTween(showLongImgTimeline)
      .addTo(showLongImgController);
    return () => {
      showLongImgTimeline.kill();
      showLongImgScene.remove();
      showLongImgScene.destroy();
      showLongImgController.destroy();
    };
  }, [windowWidth]);

  const sendFirstDmp = () => {
    if (props.fields?.navigationIndex?.value) window.navigationIndex = props.fields?.navigationIndex?.value;
    if (props.fields?.pageId?.value) window.navigationPagename = window.navigationPagenameb = props.fields.pageId.value;
    if (props.fields?.windowDmp[0]?.displayName) window.Dmp.sectionAction(props.fields?.windowDmp[0]?.displayName);
  };

  const sendSecondDmp = () => {
    secondPageEventRef?.current?.sendEvent();
  };

  const { specific } = props.fields;
  const [num, setNum] = useState(-1);
  const [num2, setNum2] = useState(1);

  const specificRefList =
    specific && specific.length > 0
      ? specific.map(() => {
          return createRef();
        })
      : null;
  const detailButtom = () => {
    return props.fields.btnFlag.value && props.fields.isLongImg.value && props.fields.btn[1] ? (
      <div
        ref={securityIntegraInnerButton2Ref}
        onClick={() => {
          sendDmp(props.fields.btn[1].fields.btnDmp);
          setDetailHref(props.fields.btn[1].fields.link.value);
          setFlag(true);
        }}
      >
        <p style={{ fontSize: thisMobile ? '0.8rem' : '12.8px' }}>{props.fields.btn[1].fields.inner.value}</p>
      </div>
    ) : (
      ''
    );
  };

  return (
    <div>
      <div className="security_integra" id={props.fields.pageId.value} ref={rootEl}>
        {props.fields.isShowVideo.value && (
          <VideoIndex
            rootEl={rootEl}
            swiperImages={thisMobile ? props.fields.swiperImages.value : props.fields.m_swiperImages.value}
            swiperDuration={thisMobile ? props.fields.swiperDuration.value : props.fields.m_swiperDuration.value}
          />
        )}
        <img
          alt="广汽本田"
          ref={securityIntegraImgRef}
          src={`${createFilePath(thisMobile ? props.fields.img.value : props.fields.m_img.value)}`}
          className={`security_integra_img ${props.fields.isLongImg.value ? 'security_integra_img_long_img' : ''} `}
        />
        <div
          className={`security_integra_inner ${props.fields.isLongImg.value && props.fields.btn[1] ? 'security_integra_inner_long_img' : ''} `}
          style={
            ({ marginTop: `${!thisMobile && props?.fields?.btnFlag?.value ? '1rem' : '0'}` },
            convertStylesStringToObject(props.fields[`${getPrefixField('textboxstyle', !thisMobile)}`]?.value))
          }
        >
          <div
            ref={securityIntegraInnerTextRef}
            dangerouslySetInnerHTML={{ __html: (thisMobile ? props.fields.text.value : props.fields.m_text.value)?.replaceHtml() }}
          ></div>
          {thisMobile && detailButtom()}
          {props.fields.btnFlag.value ? (
            props.fields?.isLongImg?.value ? (
              <div
                ref={securityIntegraInnerButton1Ref}
                onClick={() => {
                  window.Dmp.clickAction(props.fields.btn[0].fields.btnDmp[0]?.displayName, props.fields.btn[0]?.fields.link.value, '');
                  window.open(props.fields.btn[0].fields.link.value);
                }}
              >
                <img
                  alt="广汽本田"
                  src={`${createFilePath(props.fields.btn[0].fields.icon.value.split(',')[thisMobile ? 0 : 1])}`}
                  style={{ height: thisMobile ? '50%' : '' }}
                />
                <p style={{ fontSize: thisMobile ? '0.8rem' : '12.8px' }}>{props.fields.btn[0].fields.inner.value}</p>
              </div>
            ) : (
              <div
                ref={securityIntegraInnerButton1Ref}
                onClick={() => {
                  window.Dmp.clickAction(props.fields.btn[0].fields.btnDmp[0]?.displayName, props.fields.btn[0]?.fields.link.value, '');
                  window.open(props.fields.btn[0].fields.link.value);
                }}
              >
                <img alt="广汽本田" src={`${createFilePath(props.fields.btn[0].fields.icon.value)}`} style={{ height: thisMobile ? '50%' : '' }} />
                <p style={{ fontSize: thisMobile ? '0.8rem' : '12.8px' }}>{props.fields.btn[0].fields.inner.value}</p>
              </div>
            )
          ) : (
            ''
          )}
          {!thisMobile && detailButtom()}
        </div>
        {specific.map((item, index) => {
          return (
            <div
              ref={specificRefList[index]}
              key={index}
              onClick={() => {
                localStorage.setItem('kk', index);
                setNum(index);
                window.Dmp.clickAction(item.fields.breathDmp[0]?.displayName, '', { val: item.fields.name.value });
              }}
            >
              <Breath
                className={`breathing  breathingFlag${index + 1}${props.fields.styleBox.value}`}
                style={convertStylesStringToObject(item.fields[`${getPrefixField('style', !thisMobile)}`]?.value)}
              ></Breath>
            </div>
          );
        })}
        {num !== -1 &&
          (props.fields.isSwiper.value ? (
            <SwiperDetails
              fields={{
                num,
                setNum,
                specific,
                close: props.fields.close.value,
                closeDmp: specific[num].fields.closeDmp[0]?.displayName,
                val: specific[num].fields.name.value,
                styleBox: props.fields.styleBox.value,
                num2
              }}
            ></SwiperDetails>
          ) : (
            <Details
              fields={{
                num,
                setNum,
                specific,
                close: props.fields.close.value,
                closeDmp: specific[num].fields.closeDmp[0]?.displayName,
                val: specific[num].fields.name.value
              }}
            ></Details>
          ))}
      </div>
      {props.fields?.placeHolderRoute?.value && (
        <div ref={secondPageRef}>
          <Placeholder name={props.fields.placeHolderRoute.value} rendering={route} page={route} eventRef={secondPageEventRef} />
        </div>
      )}
      {flag && <Details1 id="activity" routePath={detailHref} close={() => setFlag(false)} />}
    </div>
  );
}
