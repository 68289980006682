import React, { useState } from 'react';
import './style.scss';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, EffectCards, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import { getPrefixField, convertStylesStringToObject, sendDmpAction, sendDmp, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { fields } = props;
  const dmpPointMore = fields?.dmpPointMore;
  const [data, _] = useState({
    dmpPointMore
  });
  return (
    <div className="LargeImageContentAll_v1" style={convertStylesStringToObject(fields.container_style?.value)}>
      <div className="ispc">
        {/* <img alt="广汽本田" src={`${temp.app.deployUrlS3}${fields[`${getPrefixField('back_img')}`]?.value}`} className="back_img" /> */}
        <img alt="广汽本田" src={createFilePath(fields[`${getPrefixField('back_img')}`]?.value)} className="back_img" />
        <div className="pc_container">
          {fields?.top_remark?.value !== null && fields?.top_remark?.value !== '' && (
            <div
              dangerouslySetInnerHTML={{
                __html: fields?.top_remark?.value?.replaceHtml()
              }}
            ></div>
          )}
          <div className={`textList  textList_${fields.num?.value || fields.list.length}`}>
            {fields.list.map((item, index) => {
              return (
                <div key={index} style={convertStylesStringToObject(item.fields.style?.value)}>
                  <div>
                    {/* <img alt="广汽本田" src={`${temp.app.deployUrlS3}${item.fields.icon.value}`} /> */}
                    {item.fields.icon.value && <img alt="广汽本田" src={createFilePath(item.fields.icon.value)} />}
                    <p dangerouslySetInnerHTML={{ __html: item.fields.title?.value?.replaceHtml() }}></p>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: item.fields.specificInfo?.value?.replaceHtml() }} />
                </div>
              );
            })}
          </div>
          <div dangerouslySetInnerHTML={{ __html: fields.remark?.value?.replaceHtml() }} />
        </div>
      </div>
      <div
        className="ismb"
        style={{
          // backgroundImage: `url(${temp.app.deployUrlS3}${fields[`${getPrefixField('back_img')}`]?.value})`,
          backgroundImage: `url(${createFilePath(fields[`${getPrefixField('back_img')}`]?.value)})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPositionY: '100%'
        }}
      >
        <div>
          <div dangerouslySetInnerHTML={{ __html: fields.m_top_remark?.value?.replaceHtml() }} />
          <Swiper
            modules={[EffectCards, Pagination, Navigation]}
            className="buyCarlists"
            slidesPerView={1}
            initialSlide={0}
            pagination={{
              bulletClass: `swiper-pagination-bullet ${fields.m_slide_pagination_class?.value}`.trim(),
              bulletActiveClass: `swiper-pagination-bullet-active ${fields.m_slide_pagination_active_class?.value}`.trim(),
              clickable: true
            }}
            navigation={fields.m_slide_navigation?.value}
            onInit={(e) => {
              if (fields.m_slide_navigation?.value) {
                e.navigation.nextEl.onclick = function () {
                  const dmpPointList = fields[getPrefixField('btnr_dmp_point')];
                  if (dmpPointList?.length > 0) {
                    const dmpAction = dmpPointList[0]?.fields?.func?.value;
                    const dmpPoint = dmpPointList[0].name;
                    sendDmpAction(dmpAction, dmpPoint, '');
                  }
                };
                e.navigation.prevEl.onclick = function () {
                  const dmpPointList = fields[getPrefixField('btnl_dmp_point')];
                  if (dmpPointList?.length > 0) {
                    const dmpAction = dmpPointList[0]?.fields?.func?.value;
                    const dmpPoint = dmpPointList[0].name;
                    sendDmpAction(dmpAction, dmpPoint, '');
                  }
                };
              }
            }}
            onPaginationRender={(e) => {
              for (let i = 0; i < e.pagination.bullets.length; i++) {
                e.pagination.bullets[i].onclick = function () {
                  sendDmp([data?.dmpPointMore[i]]);
                };
              }
            }}
          >
            {fields.m_list.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  {item.fields?.top_remark?.value !== null && item.fields?.top_remark?.value !== '' && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.fields?.top_remark?.value?.replaceHtml()
                      }}
                    ></div>
                  )}
                  <div className="item" style={convertStylesStringToObject(item.fields.style?.value)}>
                    <div className="title">
                      {/* <img alt="广汽本田" src={`${temp.app.deployUrlS3}${item.fields.icon.value}`} /> */}
                      <img alt="广汽本田" src={createFilePath(item.fields.icon.value)} />
                      <p dangerouslySetInnerHTML={{ __html: item.fields.title?.value?.replaceHtml() }}></p>
                    </div>
                    <div className="lists" dangerouslySetInnerHTML={{ __html: item.fields.specificInfo?.value?.replaceHtml() }} />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div dangerouslySetInnerHTML={{ __html: fields.m_remark?.value?.replaceHtml() }} />
        </div>
      </div>
    </div>
  );
}
