import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, getQueryString, getPrefixField, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import './style.scss';
import gsap from 'gsap';

export default function index(props) {
  const { fields } = props;
  const jsonData = JSON.parse(fields?.jsonData?.value);
  // const jsonData = {
  //   "lists": [
  //     {
  //       "html": "<img alt="广汽本田" class=\"bg\" src=\"${dm-media}/avancier/v2/pc/space/space2_pic1.jpg\"><div class=\"text\"><div class=\"t\">豪华空间质感</div><div class=\"sub\">优雅从容间，尽享感官盛宴</div><div class=\"p\">以舒适之姿掌控方向，让华丽格调触手可得。</div></div>",
  //       "class": "item1",
  //       "tabdmp": "price_space1-btn1",
  //       "title": "豪华座舱",
  //       "dot": [
  //         {
  //           "n": "SBW按键式电子换挡",
  //           "class": "dot1"
  //         },
  //         {
  //           "n": "豪华大容量滑道式对开扶手箱",
  //           "class": "dot2"
  //         },
  //         {
  //           "n": "全系真皮座椅<br>渐变式花纹穿孔<br>简约现代的花纹绗缝",
  //           "class": "dot3"
  //         }
  //       ]
  //     },
  //     {
  //       "html": "<img alt="广汽本田" class=\"bg\" src=\"${dm-media}/avancier/v2/pc/space/space2_pic2.jpg\"> <img alt="广汽本田" class=\"log\" src=\"${dm-media}/avancier/v2/pc/space/space2_logo.png\"><div class=\"text\"><div class=\"p\">智能助手随时相伴，车家互联尽览全况。<br>只需随心轻唤，时刻与世界连动。</div><div class=\"ul\"><div class=\"li\"><img alt="广汽本田" class=\"icon\" src=\"${dm-media}/avancier/v2/pc/space/meau_icon1.png\"><div class=\"name\">AI智能助理</div></div><div class=\"li\"><img alt="广汽本田" class=\"icon\" src=\"${dm-media}/avancier/v2/pc/space/meau_icon2.png\"><div class=\"name\">车家互联</div></div><div class=\"li\"><img alt="广汽本田" class=\"icon\" src=\"${dm-media}/avancier/v2/pc/space/meau_icon3.png\"><div class=\"name\">数字钥匙</div></div></div></div><div class=\"text2\"><div class=\"sub\">10.1英寸触摸中控屏</div><div class=\"p\">全新升级，智享清晰视界。</div></div>",
  //       "class": "item2",
  //       "tabdmp": "price_space1-btn2",
  //       "title": "Honda CONNECT 3.0 智导互联"
  //     },
  //     {
  //       "html": "<img alt="广汽本田" class=\"bg\" src=\"${dm-media}/avancier/v2/pc/space/space2_pic3.jpg\"><div class=\"text\"><div class=\"sub\">滑道式超大全景天窗(带遮阳帘)</div><div class=\"p\">两段式开启模式，可随心展开至适合的位置。仰望晴空，让感官纵览无垠。</div></div>",
  //       "class": "item3",
  //       "tabdmp": "price_space1-btn3",
  //       "title": "滑道式超大全景天窗"
  //     }
  //   ],
  //   "m_lists": [
  //     {
  //       "html": "<img alt="广汽本田" class=\"bg\" src=\"${dm-media}/avancier/v2/mobile/space/space2_pic1.jpg\"><div class=\"text\"><div class=\"t\">豪华空间质感</div><div class=\"sub\">优雅从容间，尽享感官盛宴</div><div class=\"p\">以舒适之姿掌控方向，让华丽格调触手可得。</div></div>",
  //       "class": "item1",
  //       "tabdmp": "price_space1-btn1",
  //       "title": "豪华座舱",
  //       "dot": [
  //         {
  //           "n": "SBW按键式电子换挡",
  //           "class": "dot1"
  //         },
  //         {
  //           "n": "豪华大容量滑道式对开扶手箱",
  //           "class": "dot2"
  //         }
  //       ]
  //     },
  //     {
  //       "html": "<img alt="广汽本田" class=\"bg\" src=\"${dm-media}/avancier/v2/mobile/space/space2_pic1b.jpg\"><div class=\"text\"><div class=\"t\">豪华空间质感</div><div class=\"sub\">优雅从容间，尽享感官盛宴</div><div class=\"p\">以舒适之姿掌控方向，让华丽格调触手可得。</div></div>",
  //       "class": "item1",
  //       "tabdmp": "price_space1-btn1-next",
  //       "title": "豪华座舱",
  //       "dot": [
  //         {
  //           "n": "全系真皮座椅<br>渐变式花纹穿孔<br>简约现代的花纹绗缝",
  //           "class": "dot3"
  //         }
  //       ]
  //     },
  //     {
  //       "html": "<img alt="广汽本田" class=\"bg\" src=\"${dm-media}/avancier/v2/mobile/space/space2_pic2.jpg\"> <img alt="广汽本田" class=\"log\" src=\"${dm-media}/avancier/v2/mobile/space/space2_logo.png\"><div class=\"text\"><div class=\"p\">智能助手随时相伴，车家互联尽览全况。<br>只需随心轻唤，时刻与世界连动。</div><div class=\"ul\"><div class=\"li\"><img alt="广汽本田" class=\"icon\" src=\"${dm-media}/avancier/v2/mobile/space/meau_icon1.png\"><div class=\"name\">AI智能助理</div></div><div class=\"li\"><img alt="广汽本田" class=\"icon\" src=\"${dm-media}/avancier/v2/mobile/space/meau_icon2.png\"><div class=\"name\">车家互联</div></div><div class=\"li\"><img alt="广汽本田" class=\"icon\" src=\"${dm-media}/avancier/v2/mobile/space/meau_icon3.png\"><div class=\"name\">数字钥匙</div></div></div></div><div class=\"text2\"><div class=\"sub\">10.1英寸触摸中控屏</div><div class=\"p\">全新升级，智享清晰视界。</div></div>",
  //       "class": "item2",
  //       "tabdmp": "price_space1-btn2",
  //       "title": "<span style=\"font-size:0.24rem\">Honda CONNECT 3.0<br />智导互联</span>"
  //     },
  //     {
  //       "html": "<img alt="广汽本田" class=\"bg\" src=\"${dm-media}/avancier/v2/mobile/space/space2_pic3.jpg\"><div class=\"text\"><div class=\"sub\">滑道式超大全景天窗<br> (带遮阳帘)</div><div class=\"p\">两段式开启模式，可随心展开至适合的位置。<br>仰望晴空，让感官纵览无垠。</div></div>",
  //       "class": "item3",
  //       "tabdmp": "price_space1-btn3",
  //       "title": "<span style=\"font-size:0.23rem\">滑道式超大全景天窗</span>"
  //     }
  //   ]
  // }
  const swiperRef = useRef(null);
  const tabsswiperRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const [breathIndex, setBreathIndex] = useState(-1);
  const isMobile = isMobileFun();
  const [swiperIndex, setSwiperIndex] = useState(0);

  const id = props?.params?.pagename ? props?.params?.pagename : 'price_power2';
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
  };
  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      const anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: 'start', behavior: 'auto' });
      }
    }
  };
  const swiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
    setSwiperIndex(index);
  };
  return (
    <>
      <div className="price-space-v11" id={id}>
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          className="bigswiper"
          spaceBetween={0}
          initialSlide={0}
          observer={true}
          resizeObserver={true}
          updateOnWindowResize={true}
          onSlideChangeTransitionEnd={(e) => {
            if (tabsswiperRef.current) {
              tabsswiperRef.current.swiper.slideTo(e.activeIndex);
              setSwiperIndex(e.activeIndex);
            }
          }}
        >
          {jsonData[getPrefixField('lists', isMobile)].map((item, index) => {
            return (
              <SwiperSlide key={index} className={`item ${item.class}`}>
                <div className="in" dangerouslySetInnerHTML={{ __html: item.html.replaceHtml() }}></div>
                {item.dot &&
                  item.dot.map((item1, index1) => {
                    return (
                      <div key={index1} className={`dot ${item1.class} ${breathIndex === (index + 1) * 10 + index1 ? 'on' : ''}`}>
                        {breathIndex === (index + 1) * 10 + index1 && (
                          <>
                            <div className="n" dangerouslySetInnerHTML={{ __html: item1.n.replaceHtml() }}></div>
                            <div className="line"></div>
                          </>
                        )}

                        <div
                          className="dotbtn"
                          onClick={() => {
                            setBreathIndex((index + 1) * 10 + index1);
                            window.Dmp.clickAction(item1.itemdmp, item1.dmpname);
                          }}
                        ></div>
                      </div>
                    );
                  })}
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Swiper
          ref={tabsswiperRef}
          slidesPerView={jsonData[getPrefixField('lists', isMobile)].length > 3 ? 3 : jsonData[getPrefixField('lists', isMobile)].length}
          className="tabsswiper"
          spaceBetween={0}
          initialSlide={0}
          observer={true}
          resizeObserver={true}
          updateOnWindowResize={true}
          navigation={false}
          centeredSlides={isMobile && swiperIndex !== 0 ? true : false}
          onSlideChangeTransitionEnd={(e) => {}}
        >
          {jsonData[getPrefixField('lists', isMobile)].map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                className={`item ${swiperIndex === index ? 'on' : ''}`}
                onClick={() => {
                  swiperslideTo(index);
                  window.Dmp.clickAction(item.tabdmp);
                }}
              >
                <div className="text" dangerouslySetInnerHTML={{ __html: item.title.replaceHtml() }}></div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}
