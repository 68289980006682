/* eslint-disable no-bitwise */
import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';
import Dialog from '../../elements/Dialog/index';
import temp from '../../temp/config';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import $ from 'jquery';
import { getPrefixField, sendDmpAction, isEmpty, debounce, isMobileFun, createFilePath, GotoMall } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';
import { GetGmallAppSchemeUrl } from '../../utils/api';

// 计算经纬度之间的距离
function getDistance(lat1, lng1, lat2, lng2) {
  var radLat1 = (lat1 * Math.PI) / 180.0;
  var radLat2 = (lat2 * Math.PI) / 180.0;
  var a = radLat1 - radLat2;
  var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
  var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
  s = s * 6378.137;
  s = Math.round(s * 10000) / 10000;
  return s;
}

export default function index(props) {
  const { fields } = props.fields.iconList[1].fields.inputDmp[0];
  const { driveList } = props.fields.iconList[1].fields;
  const isMobile = isMobileFun();
  // 默认类型
  const chooseTab = () => {
    let value = getQueryVariable('tab');
    if (!driveList.find((item) => item.fields.tabValue?.fields.v.value === value)) {
      value = fields.defaultNum?.fields?.v?.value;
    }
    let number = 0;
    let items = driveList;
    for (let i = 0; i < items.length; i++) {
      if (items[i].fields.tabValue?.fields.v.value === value) {
        number = i;
        break;
      }
    }
    return number;
  };
  const [tabIndex, setTabIndex] = useState(chooseTab());
  const [submited, setSubmited] = useState(false);
  const [semImg, setSemImg] = useState('');
  const [dealerProvince, setDealerProvince] = useState(null);
  const [dealerProCitys, setDealerProCitys] = useState(null);
  const [dealerDealers, setDealerDealers] = useState(null);
  const [imgId, setImgId] = useState(''); // 设置短信和留资公用的图形编码
  const [customerPhone, setCustomerPhone] = useState('');
  const rootEl = useRef(null);
  const [specialDi, setSpecialD] = useState('');

  // 预约参数模板
  const [person, setPerson] = useState({
    userName: '',
    mobile: '',
    provinceId: '', // 省份Id Provinces.province_name
    cityId: '', // 城市id Citys.city_id
    dealerId: '', // 经销商id Dealers.dealer_id
    mediafrom: fields.mediafrom.value, // 留资方式
    graphic: {
      code: '', // 用户录入的图形验证码
      id: '', // 图形验证码id
      preMod: false
    },
    smsValidId: '', // 短信验证码id
    smsValidCode: '', // 用户录入的短信验证码
    series_code: driveList[chooseTab()].fields[getPrefixField('series_code', isMobile)].value,
    market_code: driveList[chooseTab()].fields[getPrefixField('market_code', isMobile)].value,
    model_code: driveList[chooseTab()].fields.modelCode.value,
    device_type: isMobile ? 2 : 1,
    areaCode: '',
    adcode: '',
    contactid: useSitecoreContext()?.sitecoreContext?.contactId,
    comment: fields.comment.value
  });
  const [policyVersion, setPolicyVersion] = useState(null);
  const [ownAddress, setOwnAddress] = useState([]);
  const [load, setLoad] = useState(false);

  // 短信参数模板
  const customer = {
    phone: '',
    sendType: 1,
    validId: '',
    validCode: '',
    validWhiteList: false,
    whiteListType: 0
  };

  // 数据
  useEffect(() => {
    let isHide = false;
    const debounceScroll = debounce(() => {
      if (isHide) {
        isHide = false;
        rootEl.current.style.display = 'block';
        gsap.fromTo(rootEl.current, { y: '100%' }, { y: 0, duration: 0.3 });
      }
    }, 500);
    const handleScroll = () => {
      if (!isHide) {
        isHide = true;
        gsap.fromTo(
          rootEl.current,
          { y: 0 },
          {
            y: '100%',
            duration: 0.3,
            onComplete: () => {
              rootEl.current.style.display = 'none';
            }
          }
        );
      }
      debounceScroll();
    };
    var urls = window.location.href;
    const obj = {};
    var arr = urls.split('?').pop().split('#').shift().split('&');
    for (var i = arr.length - 1; i >= 0; i--) {
      var k = arr[i].split('=')[0];
      var val = arr[i].split('=')[1];
      obj[k] = val;
    }
    person.adcode = obj.adcode;
    setPerson(person);
    window.addEventListener('scroll', handleScroll);

    if (!load) {
      const getJsonp = (url) => {
        return new Promise((resolve, reject) => {
          $.ajax({
            url: url,
            type: 'GET',
            dataType: 'JSONP',
            cache: false,
            success: function (res) {
              resolve(res);
            },
            error: function (err, res, res2) {
              reject(res2);
            }
          });
        });
      };

      // 根据IP获取地理位置
      const mapCityApiPromise =
        sessionStorage.getItem('ownAddressProvince') && sessionStorage.getItem('ownAddressCity')
          ? { province: sessionStorage.getItem('ownAddressProvince'), city: sessionStorage.getItem('ownAddressCity') }
          : getJsonp('https://restapi.amap.com/v3/ip?key=d4db88c544bf23a3a363f7ff8bcbf783&output=json')
              .then((res) => {
                if (res.status === '1') {
                  sessionStorage.setItem('ownAddressProvince', res.province);
                  sessionStorage.setItem('ownAddressCity', res.city);
                  return {
                    province: res.province,
                    city: res.city
                  };
                } else {
                  return {
                    province: '广东省',
                    city: '广州市'
                  };
                }
              })
              .catch((err) => {
                return {
                  province: '广东省',
                  city: '广州市'
                };
              });

      const AMapLoader = require('@amap/amap-jsapi-loader');
      window._AMapsecuritvonfig = { securityJsCode: 'df2d1e69d62971d0a7ebb43C882b678a' };
      // 有地址时不需要加载高德
      const mapApiPromise =
        sessionStorage.getItem('ownAddressLat') && sessionStorage.getItem('ownAddressLng')
          ? { lat: sessionStorage.getItem('ownAddressLat'), lng: sessionStorage.getItem('ownAddressLng') }
          : AMapLoader.load({
              key: '975d8e63e9648540ee56a840bbce9884',
              version: '2.0',
              plugins: ['AMap.Geolocation']
            });
      const allDealderInfo = dataFetcher('/api/honda/v1/Leads/GetAllDealerProCitys', null);

      // 三级联动数据初次渲染
      Promise.all([mapApiPromise, allDealderInfo, mapCityApiPromise]).then((res) => {
        // sessionStorage是否含有城市
        if (sessionStorage.getItem('ownAddressProvince') && sessionStorage.getItem('ownAddressCity')) {
          province.current = sessionStorage.getItem('ownAddressProvince');
          city.current = sessionStorage.getItem('ownAddressCity');
        } else {
          province.current = res[2].province;
          city.current = res[2].city;
        }

        // sessionStorage是否含有个人经纬度
        if (sessionStorage.getItem('ownAddressLat') && sessionStorage.getItem('ownAddressLng')) {
          setLoad(true);
        } else {
          if (res[0]) {
            let geolocationLatLng = new res[0].Geolocation();
            geolocationLatLng.getCurrentPosition(function (status, result) {
              if (status === 'complete') {
                sessionStorage.setItem('ownAddressLat', result.position.lat);
                sessionStorage.setItem('ownAddressLng', result.position.lng);
                setOwnAddress([result.position.lat, result.position.lng]);
              } else {
                console.log('errorerrorerrorerror', result);
              }
              setLoad(true);
            });
          }
        }

        if (res[1].status === 200) {
          originalData.current = { ...res[1]?.data?.Data };
          fetchData();
        }
      });

      getPolicyVersion();
    } else {
      if (originalData.current) {
        fetchData();
      }
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [load]);

  const filterOriginalData = () => {
    const data = { Citys: [], Dealers: [...originalData.current?.Dealers], Provinces: [] };
    originalData.current?.Citys?.forEach((item) => {
      const dealers = getDealersData(item.city_id, originalData.current?.Dealers);
      if (dealers.length !== 0) {
        data.Citys.push(item);
      }
    });

    originalData.current?.Provinces?.forEach((item) => {
      const result = data?.Citys?.find((item1) => item1.province_id === item.province_id);
      if (result) {
        data.Provinces.push(item);
      }
    });
    return data;
  };

  const getPolicyVersion = () => {
    dataFetcher('/api/honda/v1/Policy/GetPolicyVersion')
      .then(({ data }) => {
        if (data.Status) {
          setPolicyVersion(data?.Data?.policy_code);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchData = () => {
    const data = filterOriginalData();
    let init_province = person?.provinceId || '';
    let init_zoneId = person?.zone_id || '';
    let result = data.Provinces.find((item) => item.province_id === init_province);
    // if (!result) {
    result = data.Provinces.find((item) => province.current?.indexOf(item.province_name) !== -1);
    if (!result) {
      result = data.Provinces.find((item) => '广东省'.indexOf(item.province_name) !== -1);
      if (!result) {
        result = data.Provinces[0];
      }
    }
    init_province = result.province_id;
    init_zoneId = result.zone_id;
    // }
    const newProvinceList = data.Provinces.map((item) => ({ ...item, default: init_province === item.province_id }));
    setDealerProvince({ ...data, Provinces: newProvinceList });

    let init_city = person?.cityId || '';
    const citys = data.Citys.filter((item) => item.province_id === (init_province || data.Provinces[0].province_id));
    result = citys.find((item) => item.city_id === init_city);
    // if (!result) {
    result = citys.find((item) => city.current?.indexOf(item.city_name) !== -1);
    if (!result) {
      result = citys.find((item) => '广州市'.indexOf(item.city_name) !== -1);
      if (!result) {
        result = citys[0];
      }
    }
    init_city = result.city_id;
    // }
    const newCitys = citys.map((item) => ({ ...item, default: init_city === item.city_id }));
    setDealerProCitys(newCitys);

    let dealers = getDealersData(init_city || citys[0].city_id, data.Dealers);
    dealers[0].default = true;
    let ownLatLng = ownAddress;
    if (sessionStorage.getItem('ownAddressLat') && sessionStorage.getItem('ownAddressLng')) {
      ownLatLng = [Number(sessionStorage.getItem('ownAddressLat')), Number(sessionStorage.getItem('ownAddressLng'))];
    }
    if (ownLatLng[0] && ownLatLng[1]) {
      let distance = Number.MAX_VALUE;
      for (let i = 0; i < dealers.length; i++) {
        if (getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealers[i].latitude), Number(dealers[i].longitude)) < distance) {
          distance = getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealers[i].latitude), Number(dealers[i].longitude));
        }
      }
      dealers = dealers.map((item) => ({
        ...item,
        default: getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(item.latitude), Number(item.longitude)) === distance
      }));
    }
    setDealerDealers(dealers);
    person.provinceId = init_province ? init_province : data.Provinces[0].province_id;
    person.cityId = init_city ? init_city : citys[0]?.city_id;
    for (let i = 0; i < dealers.length; i++) {
      if (dealers[i].default) {
        person.dealerId = dealers[i]?.dealer_id;
        setSpecialD(dealers[i].address);
      }
    }
    person.areaCode = init_zoneId ? init_zoneId : data.Provinces[0].zone_id;
    setPerson(person);
  };

  useEffect(() => {
    if (originalData?.current) {
      fetchData();
    }
  }, [tabIndex]);

  const getDealersData = (cityId, data, defaultIndex = tabIndex) => {
    const vehicleWeightKey = driveList[defaultIndex]?.fields?.vehicleWeight?.fields?.k?.value;
    let vehicleWeightValue = driveList[defaultIndex]?.fields?.vehicleWeight?.fields?.v?.value;
    let dealers = [];
    if (!isEmpty(vehicleWeightValue)) {
      vehicleWeightValue = parseInt(vehicleWeightValue);
      dealers = data.filter((item) => item.city_id === cityId && (vehicleWeightValue & item.dealer_right) > 0);
      // if (vehicleWeightKey === 'enp1') {
      //   if (dealers.length === 0) {
      //     dealers = data.filter((item) => item.city_id === cityId && item.dealer_level === 1);
      //   } else {
      //     const exist = dealers.find((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     if (exist) {
      //       dealers = dealers.filter((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     }
      //   }
      // }
    }
    const newEnergy = dealers.filter((item) => item.dealer_right === 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    const gas = dealers.filter((item) => item.dealer_right !== 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    return [...newEnergy, ...gas];
  };

  // 点击切换渲染数据
  const selectProvince = (e) => {
    let p_value = e.target.childNodes[e.target.selectedIndex].getAttribute('value');
    let z_value = e.target.childNodes[e.target.selectedIndex].getAttribute('zoneid');

    const dmpPointList = fields.province_dmp_point;
    if (dmpPointList?.length > 0) {
      const dmpAction = dmpPointList[0]?.fields?.func?.value;
      const dmpPoint = dmpPointList[0].name;
      sendDmpAction(dmpAction, dmpPoint, p_value);
    }

    const aou = dealerProvince.Citys.filter((item) => {
      return item.province_id === p_value;
    });
    person.areaCode = z_value;
    setPerson(person);
    setDealerProCitys(aou);

    const dealers = getDealersData(aou[0].city_id, dealerProvince.Dealers);
    setDealerDealers(dealers);
    person.provinceId = p_value;
    person.cityId = aou[0].city_id;
    person.dealerId = dealers.length > 0 && dealers[0].dealer_id;
    setPerson(person);
    setSpecialD(dealers.length > 0 && dealers[0].address);
  };
  const selectCity = (e) => {
    let c_value = e.target.childNodes[e.target.selectedIndex].getAttribute('value');

    const dmpPointList = fields.city_dmp_point;
    if (dmpPointList?.length > 0) {
      const dmpAction = dmpPointList[0]?.fields?.func?.value;
      const dmpPoint = dmpPointList[0].name;
      sendDmpAction(dmpAction, dmpPoint, c_value);
    }

    const dealers = getDealersData(c_value, dealerProvince.Dealers);
    setDealerDealers(dealers);
    person.cityId = c_value;
    person.dealerId = dealers.length > 0 && dealers[0].dealer_id;
    setPerson(person);
    setSpecialD(dealers.length > 0 && dealers[0].address);
  };
  const selectDealer = (e) => {
    const dmpPointList = fields.shop_dmp_point;
    if (dmpPointList?.length > 0) {
      const dmpAction = dmpPointList[0]?.fields?.func?.value;
      const dmpPoint = dmpPointList[0].name;
      sendDmpAction(dmpAction, dmpPoint, e.target.childNodes[e.target.selectedIndex].getAttribute('value'));
    }

    person.dealerId = e.target.childNodes[e.target.selectedIndex].getAttribute('provinceid');
    setPerson(person);
    setSpecialD(dealerDealers[e.target.selectedIndex].address);
  };
  const [flagList, setFlagList] = useState(false);
  const originalData = useRef(null);
  const province = useRef(null);
  const city = useRef(null);

  return (
    <>
      <div className="FooterBlack_v2" style={{ background: props.fields.backColor.value, color: props.fields.fontColor.value }} ref={rootEl}>
        {flagList && !submited ? (
          <div className="inputList">
            <div>
              <p dangerouslySetInnerHTML={{ __html: props.fields.iconList[1].fields.inputDmp[0].fields.title.value?.replaceHtml() }}></p>
              <img
                alt="广汽本田"
                // src={`${temp.app.deployUrlS3}/common/footer/v1/blackStyle/VectorClose_white.png`}
                src={createFilePath('/common/footer/v1/blackStyle/VectorClose_white.png')}
                onClick={() => {
                  setFlagList(false);
                }}
              />
            </div>
            {props.fields.iconList[1].fields.driveFlag.value ? (
              <select
                className="CarType"
                onChange={(e) => {
                  setTabIndex(e.target.selectedIndex);
                  setTimeout(() => {
                    const dealers = getDealersData(person.cityId, dealerProvince.Dealers, e.target.selectedIndex);
                    setDealerDealers(dealers);
                  }, 0);
                  person.series_code = driveList[e.target.selectedIndex].fields[getPrefixField('series_code', isMobile)].value;
                  person.market_code = driveList[e.target.selectedIndex].fields[getPrefixField('market_code', isMobile)].value;
                  person.model_code = driveList[e.target.selectedIndex].fields.modelCode.value;
                  setPerson(person);
                }}
              >
                {driveList.map((item, index) => {
                  const dmpPointList = item.fields.dmp_point;
                  const dmpAction = dmpPointList?.length > 0 ? dmpPointList[0]?.fields?.func?.value : '';
                  const dmpPoint = dmpPointList?.length > 0 ? dmpPointList[0].name : '';
                  return (
                    <option
                      data-action={dmpAction}
                      data-item={dmpPoint}
                      key={index}
                      className={tabIndex === index ? 'carTypeActive' : ''}
                      dangerouslySetInnerHTML={{ __html: item.fields[getPrefixField('title', isMobile)].value?.replaceHtml() }}
                      selected={tabIndex === index ? 'selected' : ''}
                    ></option>
                  );
                })}
              </select>
            ) : (
              ''
            )}
            <div className="basisMation">
              <div>
                <input
                  type="text"
                  onInput={(e) => {
                    person.userName = e.target.value;
                  }}
                  onBlur={(e) => {
                    const dmpPointList = fields[getPrefixField('name_dmp_point')];
                    if (dmpPointList?.length > 0) {
                      const dmpAction = dmpPointList[0]?.fields?.func?.value;
                      const dmpPoint = dmpPointList[0].name;
                      sendDmpAction(dmpAction, dmpPoint, e.target.value);
                    }
                  }}
                  placeholder="姓名"
                  style={{ background: fields[getPrefixField('inputBackColor')]?.value, color: fields[getPrefixField('inputFontColor')]?.value }}
                />
              </div>
              <div>
                <input
                  type="text"
                  onInput={(e) => {
                    person.mobile = e.target.value;
                    setPerson(person);
                  }}
                  onBlur={(e) => {
                    const dmpPointList = fields[getPrefixField('tel_dmp_point')];
                    if (dmpPointList?.length > 0) {
                      const dmpAction = dmpPointList[0]?.fields?.func?.value;
                      const dmpPoint = dmpPointList[0].name;
                      sendDmpAction(dmpAction, dmpPoint, e.target.value);
                    }
                  }}
                  placeholder="手机号"
                  style={{ background: fields[getPrefixField('inputBackColor')]?.value, color: fields[getPrefixField('inputFontColor')]?.value }}
                />
              </div>
            </div>
            <div className="basisMation">
              <div>
                <select
                  onChange={(e) => {
                    selectProvince(e);
                  }}
                  style={{ background: fields[getPrefixField('inputBackColor')]?.value, color: fields[getPrefixField('inputFontColor')]?.value }}
                >
                  {dealerProvince &&
                    dealerProvince.Provinces.map((item, index) => {
                      return (
                        <option
                          key={index}
                          selected={item.default ? 'selected' : ''}
                          provinceid={item.province_id}
                          zoneid={item.zone_id}
                          value={item.province_id}
                        >
                          {item.province_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div>
                <select
                  onChange={(e) => {
                    selectCity(e);
                  }}
                  style={{ background: fields[getPrefixField('inputBackColor')]?.value, color: fields[getPrefixField('inputFontColor')]?.value }}
                >
                  {dealerProCitys &&
                    dealerProCitys.map((item, index) => {
                      return (
                        <option key={index} selected={item.default ? 'selected' : ''} provinceid={item.city_id} value={item.city_id}>
                          {item.city_name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="specialYue">
              <select
                onChange={(e) => {
                  selectDealer(e);
                }}
                style={{ background: fields[getPrefixField('inputBackColor')]?.value, color: fields[getPrefixField('inputFontColor')]?.value }}
              >
                {dealerDealers &&
                  dealerDealers.map((item, index) => {
                    return (
                      <option key={index} provinceid={item.dealer_id} value={item.dealer_id} selected={item.default ? 'selected' : ''}>
                        {item.dealer_name}
                      </option>
                    );
                  })}
              </select>
              <div className="specialAddress" style={{ color: fields[getPrefixField('addressColor')]?.value }}>
                地址:{specialDi}
              </div>
            </div>
            <button
              style={{ background: fields[getPrefixField('inputBackColor')]?.value, color: fields[getPrefixField('inputFontColor')]?.value }}
              onClick={() => {
                const data = {
                  n: person.userName,
                  tel: person.mobile,
                  prv: person.provinceId,
                  city: person.cityId,
                  dl: person.dealerId,
                  ver: policyVersion
                };

                if (person.userName !== '' && person.mobile !== '') {
                  if (!/^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/.test(person.mobile)) {
                    alert('手机号码格式不正确');
                  } else {
                    if (localStorage.getItem('beforePerson')) {
                      const beforePerson = JSON.parse(localStorage.getItem('beforePerson'));
                      let personFlag = false;
                      for (const key in beforePerson) {
                        if (beforePerson[key] !== person[key]) {
                          personFlag = true;
                        }
                      }
                      if (personFlag) {
                        dataFetcher('/api/honda/v1/Leads/AddLeads', person).then((res) => {
                          if (res.data.Status === true) {
                            alert('提交成功');
                            sendDmpAction('submit', 'drive', data);
                            localStorage.setItem(
                              'beforePerson',
                              JSON.stringify({
                                userName: person.userName,
                                mobile: person.mobile,
                                provinceId: person.provinceId, // 省份Id Provinces.province_name
                                cityId: person.cityId, // 城市id Citys.city_id
                                dealerId: person.dealerId, // 经销商id Dealers.dealer_id
                                mediafrom: person.mediafrom, // 留资方
                                series_code: person.series_code,
                                market_code: person.market_code,
                                model_code: person.model_code,
                                areaCode: person.areaCode,
                                adcode: person.adcode,
                                contactid: person.contactid,
                                comment: person.comment,
                                device_type: isMobile ? 2 : 1
                              })
                            );
                          } else {
                            if (
                              res.data.ErrorResult.ErrCode === '26' ||
                              res.data.ErrorResult.ErrCode === '27' ||
                              res.data.ErrorResult.ErrCode === '28'
                            ) {
                              setSubmited(true);
                              customer.phone = person.mobile;
                              setCustomerPhone(person.mobile);
                              dataFetcher('/api/honda/v1/Graphic/CreateCode', null).then((res) => {
                                if (res.status) {
                                  person.graphic.id = res.data.Data.GraphicValidId;
                                  setSemImg(res.data.Data.Base64);
                                  setImgId(res.data.Data.GraphicValidId);
                                  setPerson(person);
                                }
                              });
                            } else {
                              alert(res.data.ErrorResult.ErrMsg);
                            }
                          }
                        });
                      } else {
                        alert('重复提交');
                      }
                    } else {
                      dataFetcher('/api/honda/v1/Leads/AddLeads', person).then((res) => {
                        if (res.data.Status === true) {
                          alert('提交成功');
                          sendDmpAction('submit', 'drive', data);
                          localStorage.setItem(
                            'beforePerson',
                            JSON.stringify({
                              userName: person.userName,
                              mobile: person.mobile,
                              provinceId: person.provinceId, // 省份Id Provinces.province_name
                              cityId: person.cityId, // 城市id Citys.city_id
                              dealerId: person.dealerId, // 经销商id Dealers.dealer_id
                              mediafrom: person.mediafrom, // 留资方
                              series_code: person.series_code,
                              market_code: person.market_code,
                              model_code: person.model_code,
                              areaCode: person.areaCode,
                              adcode: person.adcode,
                              contactid: person.contactid,
                              comment: person.comment,
                              device_type: isMobile ? 2 : 1
                            })
                          );
                        } else {
                          if (
                            res.data.ErrorResult.ErrCode === '26' ||
                            res.data.ErrorResult.ErrCode === '27' ||
                            res.data.ErrorResult.ErrCode === '28'
                          ) {
                            setSubmited(true);
                            customer.phone = person.mobile;
                            setCustomerPhone(person.mobile);
                            dataFetcher('/api/honda/v1/Graphic/CreateCode', null).then((res) => {
                              if (res.status) {
                                person.graphic.id = res.data.Data.GraphicValidId;
                                setSemImg(res.data.Data.Base64);
                                setImgId(res.data.Data.GraphicValidId);
                                setPerson(person);
                              }
                            });
                          } else {
                            alert(res.data.ErrorResult.ErrMsg);
                          }
                        }
                      });
                    }
                  }
                } else {
                  alert('名字手机号码不能为空');
                }
              }}
            >
              提交
            </button>
          </div>
        ) : !flagList && !submited ? (
          <div className="iconList" style={{ background: props.fields.backColor.value }}>
            {props.fields.iconList.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (item.fields.iconUrl.value === '') {
                      setFlagList(true);
                    } else {
                      if (item.fields.iconValue.value === '在线下订') {
                        GotoMall(item.fields.iconUrl.value, '', isMobile);
                      } else {
                        window.open(item.fields.iconUrl.value);
                      }
                    }
                  }}
                >
                  <img alt="广汽本田" src={createFilePath(item.fields.icon.value)} />
                  <p style={{ color: props.fields.fontColor.value }}>{item.fields.iconValue.value}</p>
                </div>
              );
            })}
          </div>
        ) : (
          <Dialog key={Math.random()} width="100%">
            <div className="formPhone">
              <p>手机号*</p>
              <input type="text" value={person.mobile} readOnly="readOnly" />
            </div>
            <div className="graphicCode">
              <div>
                <p>图形验证码*</p>
                <input
                  type="text"
                  onChange={(e) => {
                    // 设置留资链接和短信公用的图形编码
                    person.graphic.code = e.target.value;
                    customer.validCode = e.target.value;
                    setPerson(person);
                  }}
                />
              </div>
              <img
                alt="广汽本田"
                src={semImg}
                onClick={() => {
                  dataFetcher('/api/honda/v1/Graphic/CreateCode', null).then((res) => {
                    if (res.status) {
                      const graphicID = res.data.Data.GraphicValidId;
                      person.graphic.id = graphicID;
                      setSemImg(res.data.Data.Base64);
                      setImgId(res.data.Data.GraphicValidId);
                      setPerson(person);
                    }
                  });
                }}
              />
            </div>
            <div className="messageCode">
              <div>
                <p>短信验证码*</p>
                <input
                  type="text"
                  onInput={(e) => {
                    // 设置留资链接的短信code
                    person.smsValidCode = e.target.value;
                    setPerson(person);
                  }}
                />
              </div>
              <button
                onClick={() => {
                  if (customer.validCode !== '') {
                    // 完善customer
                    customer.validId = imgId;
                    customer.phone = customerPhone;
                    dataFetcher('/api/honda/v1/SMS/SendSMS', customer).then((res) => {
                      // 设置留资链接的短信id
                      if (res.data.Status) {
                        alert('已发送短信,请注意查收');
                        person.smsValidId = res.data.Data.smsValidId;
                        setPerson(person);
                      } else {
                        if (res.data.ErrorResult.ErrCode === '-1') {
                          alert('短信发送失败');
                        } else if (res.data.ErrorResult.ErrCode === '1' || '2' || '3' || '4' || '6') {
                          alert(res.data.ErrorResult.ErrMsg);
                        } else {
                          if (res.data.ErrorResult.ErrCode === '5') {
                            alert('图形验证码错误');
                            dataFetcher('/api/honda/v1/Graphic/CreateCode', null).then((res) => {
                              if (res.status) {
                                const graphicID = res.data.Data.GraphicValidId;
                                person.graphic.id = graphicID;
                                setSemImg(res.data.Data.Base64);
                                setImgId(res.data.Data.GraphicValidId);
                                setPerson(person);
                              }
                            });
                          } else if (res.data.ErrorResult.ErrCode === '11') {
                            alert('手机号当天发送次数超限');
                          } else if (res.data.ErrorResult.ErrCode === '12') {
                            alert('手机号发送次数超限，请1小时候再试');
                          } else if (res.data.ErrorResult.ErrCode === '13') {
                            alert('手机号发送频率过快，请稍后再试');
                          } else if (res.data.ErrorResult.ErrCode === '21') {
                            alert('非常抱歉，发送验证码时出现异常，请稍后再试');
                          }
                        }
                      }
                    });
                  } else {
                    alert('请输入图形验证码');
                  }
                }}
              >
                发送
              </button>
            </div>
            <button
              onClick={() => {
                customer.validId = imgId;
                customer.phone = customerPhone;
                const data = {
                  n: person.userName,
                  tel: person.mobile,
                  prv: person.provinceId,
                  city: person.cityId,
                  dl: person.dealerId,
                  ver: policyVersion
                };
                if (person.smsValidCode !== '') {
                  dataFetcher('/api/honda/v1/Leads/AddLeads', person).then((res) => {
                    if (res.data.Status) {
                      setSubmited(false);
                      alert('提交成功');
                      sendDmpAction('submit', 'drive', data);
                      localStorage.setItem(
                        'beforePerson',
                        JSON.stringify({
                          userName: person.userName,
                          mobile: person.mobile,
                          provinceId: person.provinceId, // 省份Id Provinces.province_name
                          cityId: person.cityId, // 城市id Citys.city_id
                          dealerId: person.dealerId, // 经销商id Dealers.dealer_id
                          mediafrom: person.mediafrom, // 留资方
                          series_code: person.series_code,
                          market_code: person.market_code,
                          model_code: person.model_code,
                          areaCode: person.areaCode,
                          adcode: person.adcode,
                          contactid: person.contactid,
                          comment: person.comment,
                          device_type: isMobile ? 2 : 1
                        })
                      );
                    } else {
                      if (res.data.ErrorResult.ErrCode === '28') {
                        alert('短信验证码输入错误');
                      }
                    }
                  });
                } else {
                  alert('短信验证码不能为空');
                }
              }}
            >
              确定
            </button>
            <img
              alt="广汽本田"
              className="closeIcon"
              // src={`${temp.app.deployUrlS3}/breeze/v1/pc/price-control/deployed/close.png`}
              src={createFilePath('/breeze/v1/pc/price-control/deployed/close.png')}
              onClick={() => {
                setSubmited(false);
              }}
            />
          </Dialog>
        )}
      </div>
    </>
  );
}
