import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { isMobileFun, createFilePath, getPrefixField, isInView } from '../../utils/obj-utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import './style.scss';
import gsap from 'gsap';

export default function index(props) {
  const { fields } = props;
  const rootEl = useRef(null);
  const swiperRef = useRef(null);
  const isMobile = isMobileFun();
  const [paused, setPaused] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const id = props?.params?.pagename;
  const data = {
    id
  };
  useEffect(() => {
    const scroll = () => {
      if (rootEl.current) {
        setPaused(!isInView(rootEl));
      }
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  useEffect(() => {
    if (isMobile) {
      return;
    }
    const timeline = gsap.timeline({
      duration: 1.5,
      onComplete: () => {
        timeline.kill();
      }
    });
    timeline.fromTo(swiperRef?.current, { y: 0 }, { y: '10%' }, '<');
    if (paused) {
      return;
    }
    timeline.fromTo(swiperRef?.current, { y: '10%' }, { y: 0 }, '<');
    return () => {
      timeline.kill();
    };
  }, [paused]);
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
  };

  const swiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
    setTabIndex(index);
  };

  return (
    <div className="PricePower_v8" id={id} ref={rootEl}>
      <div className="con">
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          className="modules-swiper"
          spaceBetween={0}
          initialSlide={0}
          observer={true}
          resizeObserver={true}
          updateOnWindowResize={true}
          modules={[Pagination, Navigation]}
          navigation={{
            prevEl: '.PricePower_v8 .prev',
            nextEl: '.PricePower_v8 .next',
            disabledClass: 'disabled'
          }}
          // pagination={{
          //   el: '.PricePower_v8 .pagination',
          //   clickable: true,
          //   bulletActiveClass: 'pagination-bullets-active',
          //   bulletClass: 'pagination-bullets'
          // }}
          onActiveIndexChange={(swiper) => {
            setTabIndex(swiper.activeIndex);
          }}
        >
          {fields?.lists.length > 0 &&
            fields?.lists?.map((item, index) => {
              return (
                <SwiperSlide key={index} className="item">
                  {item?.fields[getPrefixField('name', isMobile)]?.value && (
                    <div
                      className="name"
                      dangerouslySetInnerHTML={{ __html: item?.fields[getPrefixField('name', isMobile)]?.value.replaceHtml() }}
                    ></div>
                  )}
                  {item?.fields[getPrefixField('pic', isMobile)]?.value && (
                    <div className="img">
                      <img alt="广汽本田" src={`${createFilePath(item?.fields[getPrefixField('pic', isMobile)]?.value)}`} />
                    </div>
                  )}
                  {item?.fields[getPrefixField('text', isMobile)]?.value && (
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{ __html: item?.fields[getPrefixField('text', isMobile)]?.value.replaceHtml() }}
                    ></div>
                  )}
                </SwiperSlide>
              );
            })}
        </Swiper>
        <div className="next" data-html-item={fields?.nextdmp?.value}>
          {fields?.nexticon?.value && <img alt="广汽本田" className="img" src={`${createFilePath(fields?.nexticon?.value)}`} />}
        </div>
        <div className="prev" data-html-item={fields?.prevdmp?.value}>
          {fields?.previcon?.value && <img alt="广汽本田" className="img" src={`${createFilePath(fields?.previcon?.value)}`} />}
        </div>
        <div className="pagination">
          {fields?.lists.length > 0 &&
            fields?.lists?.map((item, index) => {
              return (
                <span
                  key={index}
                  className={`pagination-bullets ${index === tabIndex ? 'pagination-bullets-active' : ''}`}
                  onClick={() => {
                    swiperslideTo(index);
                    window.Dmp.clickAction(fields?.paginationdmp?.value + (Number(index) + 1));
                  }}
                ></span>
              );
            })}
        </div>
      </div>
    </div>
  );
}
