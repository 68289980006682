import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import FindDealer from '../../elements/FindDealer';
import { getQueryVariable } from '../../utils/site-utils';

export default function index(props) {
  const [showECenter, setShowECenter] = useState(false);
  const setPersonchild = (e) => {};
  useEffect(() => {
    if (getQueryVariable('ActivePage') === 'eCenter') {
      setShowECenter(true);
    }
  }, []);
  return (
    <section>
      <div className="findDealer_v1">
        <div className="content">
          <FindDealer istabs={true} props={props} setPersonparent={setPersonchild} prantentshowECenter={showECenter} />
        </div>
      </div>
    </section>
  );
}
