import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import './style.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import Details from '../../elements/InnerPageDetail';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import temp from '../../temp/config';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const { fields } = props;
  const [flag, setFlag] = useState(false);
  const [windowHeight, setWindowHeight] = useState(400);
  const isMobile = isMobileFun();
  const rootEl = useRef(null);

  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields.window_dmp);
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : '';
  };

  return (
    <section>
      <Controller>
        <Scene triggerHook={'onLeave'} duration={windowHeight} pin>
          {(progress, event) => {
            return (
              <div style={convertStylesStringToObject(fields[`${getPrefixField('container_style', isMobile)}`]?.value)}>
                <Timeline totalProgress={progress} paused>
                  <Tween from={isMobile ? { opacity: 1 } : { opacity: 1 }} to={isMobile ? { opacity: 1 } : { opacity: 1 }} duration={2} position={1}>
                    <div className="BlackNight" id={props?.params?.pagename} ref={rootEl}>
                      <div className="light">
                        <Tween to={{ height: '0%' }} duration={1.8} position={isMobile ? 2 : 1}>
                          <div className="maskUp" style={convertStylesStringToObject(fields[`${getPrefixField('mask_up_style', isMobile)}`]?.value)}>
                            <div
                              className="show"
                              dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('mask_title', isMobile)}`]?.value?.replaceHtml() }}
                            />
                          </div>
                        </Tween>
                        <Tween to={{ height: '0%', top: '100%' }} duration={1.8} position={isMobile ? 2 : 1}>
                          <div
                            className="maskDown"
                            style={convertStylesStringToObject(fields[`${getPrefixField('mask_down_style', isMobile)}`]?.value)}
                          >
                            <div
                              className="show"
                              dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('mask_title', isMobile)}`]?.value?.replaceHtml() }}
                            />
                          </div>
                        </Tween>
                      </div>
                      <div className="video">
                        {fields[`${getPrefixField('back_source_type')}`]?.value === 'video' ? (
                          <video
                            // src={`${temp.app.deployUrlS3}${fields[`${getPrefixField('back_source', isMobile)}`]?.value}`}
                            src={createFilePath(fields[`${getPrefixField('back_source', isMobile)}`]?.value)}
                            muted={true}
                            autoPlay="autoplay"
                            loop={true}
                          />
                        ) : (
                          <img
                            alt="广汽本田"
                            className="back_img"
                            style={convertStylesStringToObject(fields[`${getPrefixField('back_img_style', isMobile)}`]?.value)}
                            // src={`${temp.app.deployUrlS3}${fields[`${getPrefixField('back_source', isMobile)}`]?.value}`}
                            src={createFilePath(fields[`${getPrefixField('back_source', isMobile)}`]?.value)}
                          />
                        )}
                        <div>
                          {fields[`${getPrefixField('title_img')}`]?.value && (
                            <img
                              alt="广汽本田"
                              className="title_img"
                              // src={`${temp.app.deployUrlS3}${fields[`${getPrefixField('title_img', isMobile)}`]?.value}`}
                              src={createFilePath(fields[`${getPrefixField('title_img', isMobile)}`]?.value)}
                            />
                          )}
                          <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} position={2}>
                            <div className="night">
                              <div dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('content', isMobile)}`].value?.replaceHtml() }} />
                              <div
                                style={{ display: 'inline' }}
                                onClick={() => {
                                  setFlag(true);
                                  sendDmp(fields.detail_btn_dmp);
                                }}
                                dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('detail_btn', isMobile)}`].value?.replaceHtml() }}
                              />
                              {fields?.immd_btn?.value && (
                                <div
                                  style={{ display: 'inline' }}
                                  dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('immd_btn', isMobile)}`].value?.replaceHtml() }}
                                />
                              )}
                            </div>
                          </Tween>
                        </div>
                      </div>
                    </div>
                  </Tween>
                </Timeline>
              </div>
            );
          }}
        </Scene>
      </Controller>
      {flag && <Details routePath={fields[`${getPrefixField('detail_btn_href', isMobile)}`]?.value} close={() => setFlag(false)} />}
    </section>
  );
}
