import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, getQueryString, isInView, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import FullModalDetail from '../../elements/FullModalDetail_v1';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const jsonData = JSON.parse(fields?.jsonData?.value);
  // const jsonData = {
  //   "lists": [
  //     {
  //       "title": "ACC主动巡航控制系统",
  //       "sub": "(带LSF低速跟随功能)",
  //       "fm": "/avancier/v2/pc/tech/tech2_pic1fm.jpg",
  //       "detail": "在定速巡航时自动调节与前方车辆间的距离，适应各种场景如跟随前车、从旁边车道插入、超车等，降低驾驶者驾驶负担。",
  //       "video": "/avancier/v2/pc/tech/teach1.mp4",
  //       "bigfm": "/avancier/v2/pc/tech/tech2_pic1.jpg",
  //       "opendmp": "price_tech1-video-open1",
  //       "closedmp": "price_tech1-video-close1",
  //       "btn": "完整视频"
  //     },
  //     {
  //       "title": "CMBS碰撞缓解制动系统",
  //       "sub": "(FCW前方碰撞预警系统)",
  //       "fm": "/avancier/v2/pc/tech/tech2_pic2fm.jpg",
  //       "detail": "利用毫米波雷达和单眼摄像头探测前方，避免前方车辆及夜间行人的碰撞，并逐步自动制动，以减轻碰撞伤害。",
  //       "video": "/avancier/v2/pc/tech/teach2.mp4",
  //       "bigfm": "/avancier/v2/pc/tech/tech2_pic2.jpg",
  //       "opendmp": "price_tech1-video-open2",
  //       "closedmp": "price_tech1-video-close2",
  //       "btn": "完整视频"
  //     }
  //   ],
  //   "m_lists": [
  //     {
  //       "title": "ACC主动巡航控制系统",
  //       "sub": "(带LSF低速跟随功能)",
  //       "fm": "/avancier/v2/mobile/tech/tech2_pic1fm.jpg",
  //       "detail": "在定速巡航时自动调节与前方车辆间的距离<br>适应各种场景如跟随前车、从旁边车道插入、超车等<br>降低驾驶者驾驶负担。",
  //       "video": "/avancier/v2/pc/tech/teach1.mp4",
  //       "bigfm": "/avancier/v2/mobile/tech/tech2_pic1fm.jpg",
  //       "opendmp": "price_tech1-video-open1",
  //       "closedmp": "price_tech1-video-close1",
  //       "btn": "完整视频"
  //     },
  //     {
  //       "title": "CMBS碰撞缓解制动系统",
  //       "sub": "(FCW前方碰撞预警系统)",
  //       "fm": "/avancier/v2/mobile/tech/tech2_pic2fm.jpg",
  //       "detail": "利用毫米波雷达和单眼摄像头探测前方<br>避免前方车辆及夜间行人的碰撞<br>并逐步自动制动，以减轻碰撞伤害。",
  //       "video": "/avancier/v2/pc/tech/teach2.mp4",
  //       "bigfm": "/avancier/v2/mobile/tech/tech2_pic2fm.jpg",
  //       "opendmp": "price_tech1-video-open2",
  //       "closedmp": "price_tech1-video-close2",
  //       "btn": "完整视频"
  //     }
  //   ],
  //   "prevdmp": "price_tech1-prev",
  //   "nextdmp": "price_tech1-next"
  // }
  const [showDetail, setShowDetail] = useState(false);
  const isMobile = isMobileFun();
  const [propindex, setPropindex] = useState(0);
  const id = props?.params?.pagename ? props?.params?.pagename : 'price_exterior';
  const rootEl = useRef(null);
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id;
  };
  const windowhidden = () => {
    document.documentElement.style.overflow = 'hidden';
  };
  const closewindowhidden = () => {
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.overflowX = 'hidden';
  };

  return (
    <div className="price-tech-v2" id={id} ref={rootEl}>
      <div className="lists">
        {jsonData[getPrefixField('lists', isMobile)].map((item, index) => {
          return (
            <div key={index} className="item">
              {!isMobile && (
                <>
                  <div className="t" dangerouslySetInnerHTML={{ __html: item?.title.replaceHtml() }}></div>
                  <div className="sub" dangerouslySetInnerHTML={{ __html: item?.sub.replaceHtml() }}></div>
                </>
              )}

              <div className="video">
                <div className="pic">
                  <img alt="广汽本田" src={`${createFilePath(item?.fm)}`} />
                </div>
                {!isMobile ? (
                  <div
                    className="play"
                    onClick={() => {
                      setShowDetail(true);
                      setPropindex(index);
                      windowhidden();
                      window.Dmp.clickAction(item?.opendmp);
                    }}
                  >
                    <img alt="广汽本田" src={createFilePath('/avancier/v2/pc/power/play.png')} />
                  </div>
                ) : (
                  <>
                    <div className="text">
                      <div className="t" dangerouslySetInnerHTML={{ __html: item?.title.replaceHtml() }}></div>
                      <div className="sub" dangerouslySetInnerHTML={{ __html: item?.sub.replaceHtml() }}></div>
                      <div
                        className="btn"
                        onClick={() => {
                          setShowDetail(true);
                          setPropindex(index);
                          windowhidden();
                          window.Dmp.clickAction(item?.opendmp);
                        }}
                        dangerouslySetInnerHTML={{ __html: item?.btn.replaceHtml() }}
                      ></div>
                    </div>
                  </>
                )}
              </div>
              <div className="p" dangerouslySetInnerHTML={{ __html: item?.detail.replaceHtml() }}></div>
            </div>
          );
        })}
      </div>

      {showDetail && (
        <FullModalDetail>
          <div className="price-tech-v2-prop">
            <div
              className="close"
              onClick={() => {
                setShowDetail(false);
                closewindowhidden();
                window.Dmp.clickAction(jsonData[getPrefixField('lists', isMobile)][propindex]?.closedmp);
              }}
            >
              {isMobile ? (
                <img alt="广汽本田" src={createFilePath('/avancier/v2/mobile/close_icon.png')} />
              ) : (
                <img alt="广汽本田" src={createFilePath('/avancier/v2/pc/close_icon.png')} />
              )}
            </div>
            <Swiper
              slidesPerView={1}
              className="swiper-prop"
              spaceBetween={0}
              initialSlide={propindex}
              observer={true}
              resizeObserver={true}
              updateOnWindowResize={true}
              modules={[Navigation]}
              navigation={{
                prevEl: '.price-tech-v2-prop .prev',
                nextEl: '.price-tech-v2-prop .next',
                disabledClass: 'disabled'
              }}
              onSlideChangeTransitionEnd={(e) => {
                setPropindex(e.activeIndex);
              }}
            >
              {jsonData[getPrefixField('lists', isMobile)].map((item, index) => {
                return (
                  <SwiperSlide key={index} className="item">
                    <div className="pic">
                      {propindex === index ? (
                        <video
                          x5-playsinline="true"
                          webkit-playsinline="true"
                          playsInline={true}
                          src={`${createFilePath(item.video)}`}
                          controls
                          loop
                          autoPlay
                          preload="true"
                        />
                      ) : (
                        <img alt="广汽本田" src={`${createFilePath(item.fm)}`} />
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="next" data-html-item={jsonData.nextdmp}></div>
            <div className="prev" data-html-item={jsonData.prevdmp}></div>
          </div>
        </FullModalDetail>
      )}
    </div>
  );
}
