import React, { useState, useEffect } from 'react';
import temp from '../../temp/config';
import './style.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const isMobile = isMobileFun();
  const { pc_backImg, pc_backImgAlt, m_backImg, m_backImgAlt, pc_text, m_text, logo, logoPosition, logoUrl, pc_marginFlag, m_marginFlag, textUrl } =
    props.fields;
  useEffect(() => {
    setTimeout(() => {
      window.Dmp.sectionAction('manual');
    }, 1500);
  }, []);
  return (
    <div className={`operation_main_v3${isMobile ? '_m' : ''}`} style={{ marginTop: m_marginFlag.value && isMobile ? '47px' : '' }}>
      <img src={`${createFilePath(isMobile ? m_backImg.value : pc_backImg.value)}`} alt={isMobile ? m_backImgAlt.value : pc_backImgAlt.value} />
      <div
        dangerouslySetInnerHTML={{ __html: (isMobile ? m_text.value : pc_text.value)?.replaceHtml() }}
        onClick={() => {
          window.open(textUrl.value);
        }}
      ></div>
      {isMobile ? (
        ''
      ) : (
        <img
          alt="广汽本田"
          src={`${createFilePath(logo.value)}`}
          style={convertStylesStringToObject(logoPosition.value)}
          className="toplogo"
          // onClick={() => {
          //   window.open(logoUrl.value);
          // }}
        />
      )}
    </div>
  );
}
