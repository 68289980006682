import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';
import {
  convertStylesStringToObject,
  scrollFinish,
  getPathnameByPosition,
  getPrefixField,
  sendDmp,
  isMobileFun,
  createFilePath,
  getQueryString,
  GotoMall
} from '../../utils/obj-utils';
import ReactTooltip from 'react-tooltip';
import { GetGmallAppSchemeUrl } from '../../utils/api';

let jsApiListArr = ['updateAppMessageShareData', 'updateTimelineShareData'];
let wx = null;
export const prepareWechatSdk = () => {
  if (!wx) {
    wx = require('weixin-js-sdk');
  }
  return wx;
};
export default function ViceHeadernav(props) {
  if (!props?.showFooter) {
    return null;
  }
  const { fields, rendering } = props;
  const { params } = rendering;
  const { logoA, logoB, menuList, bottom_arror, mobile_arror, top_arror, appointment, logoBInner, marginTop, clubLogo } = fields;
  const [menuShow, setMenuShow] = useState(true);
  const [flag, setFlag] = useState(false);
  const [thisMobile, setThisMobile] = useState(!isMobileFun());
  const isMobile = isMobileFun();
  const [listChildrenFlag, setListenChildrenFlag] = useState(false);
  const styleData = { background: props.fields.backColor.value };
  const [num, setNum] = useState(0);
  const [pathname, setPathname] = useState(true);
  const div = useRef(null);
  const modalRef = useRef(null);
  useEffect(() => {
    // 微信分享
    const w = prepareWechatSdk();
    setWxShare(jsApiListArr, w, () => {
      // eslint-disable-next-line no-unused-expressions
      w.ready(() => {
        w.updateAppMessageShareData({
          title: props.fields.WXtitle.value,
          desc: props.fields.WXdesc.value,
          link: location.href,
          imgUrl: createFilePath(props.fields.img.value)
        });
        w.updateTimelineShareData({
          title: props.fields.WXtitle.value,
          link: location.href,
          imgUrl: createFilePath(props.fields.img.value)
        });
        let wxVideo = document.querySelectorAll('video');
        document.addEventListener('WeixinJSBridgeReady', function () {
          for (let i = 0; i < wxVideo.length(); i++) {
            wxVideo[i].play();
          }
        });
      });
    });
    function setWxShare(jsApiListArr, wx, Callback) {
      if (window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) !== 'micromessenger') {
        return;
      }
      dataFetcher('/api/honda/v1/DWP/GetDWPWeChatShareData?shareUrl=' + encodeURIComponent(location.href), null)
        .then((res) => {
          const { data } = res;
          wx.config({
            debug: false,
            appId: data.Data.appId,
            timestamp: data.Data.timestamp,
            nonceStr: data.Data.noncestr,
            signature: data.Data.signature,
            jsApiList: jsApiListArr,
            openTagList: ['wx-open-launch-weapp']
          });
          wx.ready(Callback);
        })
        .catch((err) => {});
    }
    return () => {};
  }, []);
  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      try {
        window.top.Dmp.isAnchor = true;
      } catch (e) {
        Dmp.isAnchor = true;
      }
      const anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        if (anchorElement === document.querySelector('.NavigationMachine')) {
          anchorElement.scrollIntoView({ block: 'end', behavior: 'smooth' });
        } else if (anchorName === 'price_power' && getPathnameByPosition()?.toLowerCase() === 'integra') {
          if (anchorElement.getBoundingClientRect().bottom <= 0) {
            anchorElement.scrollIntoView({ block: 'end', behavior: 'smooth' });
          } else {
            anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }
          // } else if (
          //   (anchorName === 'ipfilm' ||
          //     anchorName === 'price_exterior' ||
          //     anchorName === 'price_power' ||
          //     anchorName === 'price_space' ||
          //     anchorName === 'price_tech') &&
          //   getPathnameByPosition()?.toLowerCase() === 'accord'
          // ) {
          //   anchorElement.scrollIntoView({ block: 'end', behavior: 'smooth' });
        } else {
          if (anchorElement.offsetHeight > document.documentElement.clientHeight - 60) {
            anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
          } else {
            anchorElement.scrollIntoView({ block: 'end', behavior: 'smooth' });
          }
        }
      }
      var windowHeight = document.documentElement.clientHeight;
      if (anchorName === 'price_power' && getPathnameByPosition()?.toLowerCase() === 'integra') {
        if (anchorElement.getBoundingClientRect().bottom <= 0) {
          scrollFinish(() => {
            window.scrollTo(0, window.scrollY - 10);
          });
        } else {
          scrollFinish(() => {
            window.scrollTo(0, window.scrollY + 1);
          });
        }
        // } else if (
        //   (anchorName === 'price_exterior' || anchorName === 'price_power') &&
        //   isMobile &&
        //   getPathnameByPosition()?.toLowerCase() === 'accord'
        // ) {
        //   if (anchorElement.getBoundingClientRect().bottom <= 0) {
        //     scrollFinish(() => {
        //       window.scrollTo(0, window.scrollY - windowHeight * 0.5);
        //     });
        //   } else {
        //     scrollFinish(() => {
        //       window.scrollTo(0, window.scrollY + 0);
        //     });
        //   }
        // } else if (anchorName === 'price_exterior' && !isMobile && getPathnameByPosition()?.toLowerCase() === 'accord') {
        //   if (anchorElement.getBoundingClientRect().bottom <= 66) {
        //     scrollFinish(() => {
        //       window.scrollTo(0, window.scrollY - windowHeight * 2);
        //     });
        //   } else {
        //     scrollFinish(() => {
        //       window.scrollTo(0, window.scrollY + 0);
        //     });
        //   }
        // } else if ((anchorName === 'price_space' || (anchorName === 'price_tech' && isMobile)) && getPathnameByPosition()?.toLowerCase() === 'accord') {
        //   if (anchorElement.getBoundingClientRect().bottom <= 0) {
        //     scrollFinish(() => {
        //       window.scrollTo(0, window.scrollY - windowHeight * 2);
        //     });
        //   } else {
        //     scrollFinish(() => {
        //       window.scrollTo(0, window.scrollY + 0);
        //     });
        //   }
        // } else if (anchorName === 'price_tech' && !isMobile && getPathnameByPosition()?.toLowerCase() === 'accord') {
        //   if (anchorElement.getBoundingClientRect().bottom <= 0) {
        //     scrollFinish(() => {
        //       window.scrollTo(0, window.scrollY - windowHeight * 3);
        //     });
        //   } else {
        //     scrollFinish(() => {
        //       window.scrollTo(0, window.scrollY + 0);
        //     });
        //   }
      }
    }
  };
  useEffect(() => {
    const touchstart = (ev) => {
      if (ev.target.className !== 'isClose') {
        if (
          ev.target.parentNode.parentNode.parentNode.className !== 'ViceHeadernavBoxList2' &&
          ev.target.parentNode.parentNode.className !== 'ViceHeadernavBoxList2' &&
          ev.target.parentNode.className !== 'ViceHeadernavBoxList2' &&
          ev.target.parentNode.parentNode.parentNode.parentNode.className !== 'ViceHeadernavBoxList2'
        ) {
          const timer = setTimeout(() => {
            setFlag(false);
            clearTimeout(timer);
          }, 500);
        }
      }
    };
    window.addEventListener('touchstart', touchstart);
    return () => {
      window.removeEventListener('touchstart', touchstart);
    };
  }, []);
  const [mobileNum, setMobileNum] = useState(0);
  useEffect(() => {
    const scroll = () => {
      const t = document.documentElement.scrollTop || document.body.scrollTop;
      if (modalRef.current) {
        if (t >= 10) {
          modalRef.current.style.height = thisMobile ? '93vh' : '93vh';
        } else {
          modalRef.current.style.height = thisMobile ? '86vh' : '93vh';
        }
      }
      setMenuShow(!(t >= 60));
    };
    window.addEventListener('scroll', scroll);
    Object.defineProperty(window, 'navigationIndex', {
      set(navigationIndex) {
        setNum(navigationIndex);
      },
      configurable: true
    });
    if (window.navigationIndex === undefined) {
      window.navigationIndex = 0;
    }
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  const modalClose = () => {
    let modalBackground = document.querySelector('.modal');
    if (modalBackground) {
      void modalBackground.offsetWidth;
      modalBackground.classList.add('modalBackground-close');
      const timer = setTimeout(() => {
        modalRef.current.style.display = 'none';
        clearTimeout(timer);
      }, 700);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      modalClose();
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (getPathnameByPosition()?.toLowerCase() !== 'accord') {
      setPathname(false);
    } else {
      setPathname(true);
    }
  }, []);
  return (
    <div
      className={`${props.fields.className.value}  ViceHeadernavBox ` + (!menuShow ? 'active' : '')}
      style={{ background: props.fields.backColor.value, boxShadow: !menuShow ? props.fields.boxShadow.value : '' }}
    >
      <div className="ViceHeadernavBoxLeft">
        <div className="haoLogo">
          <div style={{ marginTop: thisMobile && marginTop.value === '' ? '-1vh' : thisMobile && marginTop.value !== '' ? marginTop.value : '' }}>
            <img alt="广汽本田" src={createFilePath(logoA.value)} />
            <div className="logoBInner" style={{ marginLeft: props.fields.logoInnerLeft.value, width: '102%' }}>
              {logoBInner.value}
            </div>
          </div>
          <div
            className="isClose"
            onClick={() => {
              setListenChildrenFlag(false);
              setFlag(!flag);
              window.Dmp.decideAction(props.fields.MobileShowNavigation[0].displayName, !flag);
            }}
            style={{ backgroundImage: `url(${flag ? createFilePath(top_arror.value) : createFilePath(bottom_arror.value)})` }}
          ></div>
        </div>
        <div className="ViceHeadernavBoxList">
          {menuList.map((item, index) => {
            if (item.fields.name.value === '操作指南' || /\.pdf$/.test(item?.fields?.url?.value)) {
              return (
                <span
                  key={index}
                  // data-action={item.fields.dataItem[0].fields.func.value}
                  // data-item={item.fields.dataItem[0].displayName}
                  // data-url={item.fields.url.value}
                  // onClick={() => {
                  //   window.Dmp.clickAction(item.fields.dataItem[0].displayName, item.fields.url.value);
                  // }}
                  onClick={() => {
                    window.Dmp.clickAction(item.fields.dataItem[0].displayName, item.fields.url.value);
                    window.open(item.fields.url.value);
                  }}
                  style={{ borderBottom: num === index ? '2px solid #ccc' : '', paddingBottom: '8px', marginTop: '8px' }}
                >
                  <a style={{ color: props.fields.fontColor.value }}>{item.fields.name.value}</a>
                </span>
              );
            } else {
              return (
                <span key={index}>
                  <div
                    className="specialNav"
                    href={item.fields.anchorId.value}
                    id={item.id}
                    field={item.fields.name}
                    onClick={() => {
                      window.navigationIndex = index;
                      scrollToAnchor(`${item.fields.anchorId.value}`);
                      {
                        window.Dmp.clickAction(item.fields.dataItem[0].displayName, item.fields.anchorId.value);
                      }
                    }}
                    style={{
                      color: props.fields.fontColor.value
                    }}
                  >
                    <p
                      onMouseOver={() => {
                        if (item.fields.name.value === '车型亮点') {
                          window.Dmp.hoverAction(item.fields.dataItem[0].displayName);
                        }
                      }}
                      style={{
                        paddingBottom: '8px',
                        marginTop: '8px',
                        borderBottom: num === index ? `2px solid ${props.fields.HeightLightColor.value}` : '2px solid rgba(0,0,0,0)'
                      }}
                    >
                      {item.fields.name.value}
                    </p>
                    {item.fields.expand?.length > 0 ? (
                      <img alt="广汽本田" src={createFilePath(bottom_arror.value)} width="10" style={{ marginLeft: '2px' }} />
                    ) : (
                      ''
                    )}
                  </div>
                  {item.fields.expand?.length > 0 ? (
                    <div className="ListChildren" style={{ background: props.fields.opacity.value, color: props.fields.fontColor.value }}>
                      <ul>
                        {item.fields.expand?.map((item, id) => {
                          return (
                            <li
                              data-action={item.fields.dataItem[0].fields.func.value}
                              data-item={item.fields.dataItem[0].displayName}
                              key={item.id}
                              onClick={() => {
                                scrollToAnchor(`${item.fields.anchorId.value}`);
                              }}
                            >
                              {item.fields.title.value}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                </span>
              );
            }
          })}
        </div>
        {flag ? (
          <div className="ViceHeadernavBoxList2" style={{ background: props.fields.backColor.value, color: props.fields.fontColor.value }} ref={div}>
            {menuList.map((item, index) => {
              return (
                <div
                  key={item.id}
                  // className={mobileNum === index ? 'clickActionActive' : ''}
                  onClick={() => {
                    if (item.fields.name.value === '操作指南') {
                      setMobileNum(index);
                      window.open(item.fields.anchorId.value);
                    } else if (/\.pdf$/.test(item?.fields?.url?.value)) {
                      setMobileNum(index);
                      window.open(item?.fields?.url?.value);
                    } else {
                      setMobileNum(index);
                      setNum(index);
                    }
                  }}
                >
                  <span
                    data-action={item.fields.name.value !== '车型亮点' ? item?.fields?.dataItem[0]?.fields?.func?.value : ''}
                    data-item={item.fields.name.value !== '车型亮点' ? item?.fields?.dataItem[0]?.displayName : ''}
                    data-url={item.fields.name.value !== '车型亮点' ? item?.fields?.anchorId?.value : ''}
                    id={item.id}
                    field={item.fields.name}
                    onClick={() => {
                      if (item.fields.name.value !== '车型亮点') {
                        scrollToAnchor(`${item.fields.anchorId.value}`);
                        setListenChildrenFlag(false);
                        setFlag(false);
                      } else {
                        window.Dmp.decideAction(item.fields.dataItem[0].displayName, !listChildrenFlag);
                        setListenChildrenFlag(!listChildrenFlag);
                      }
                    }}
                    className={mobileNum === index ? 'clickActionActive' : ''}
                  >
                    {item.fields.name.value}
                    {item.fields.expand?.length > 0 ? (
                      <img
                        alt="广汽本田"
                        src={createFilePath(listChildrenFlag ? top_arror.value : bottom_arror.value)}
                        // onClick={() => {
                        //   window.Dmp.decideAction(item.fields.dataItem[0].displayName, !listChildrenFlag);
                        // }}
                        width="10"
                        style={{ marginLeft: '2px', width: '4vw' }}
                      />
                    ) : (
                      ''
                    )}
                  </span>
                  {item.fields.expand?.length > 0 && listChildrenFlag ? (
                    <div className="ListChildren">
                      <ul>
                        {item.fields.expand?.map((ite, idx) => {
                          return (
                            <li
                              // data-action={ite.fields.dataItem[0].fields.func.value}
                              // data-item={`${ite.fields.dataItem[0].displayName}`}
                              key={idx}
                              onClick={() => {
                                window.Dmp.clickAction(ite.fields.dataItem[0].displayName, '', '');
                                scrollToAnchor(ite.fields.anchorId.value);
                                setListenChildrenFlag(false);
                                setFlag(false);
                              }}
                            >
                              {ite.fields.title.value}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="ViceHeadernavBoxRight">
        {clubLogo &&
          clubLogo.map((item, index) => {
            return (
              <button
                key={index}
                data-tip={item.fields[getPrefixField('text', !thisMobile)]?.value}
                data-place={'bottom'}
                data-html={true}
                data-type={'light'}
                className="clubLogoButton"
                style={{ backgroundImage: `url(${createFilePath(item.fields[getPrefixField('logo', !thisMobile)]?.value)})` }}
                onMouseEnter={() => {
                  thisMobile && sendDmp(item.fields.hoverDmp, '', '', 'hover');
                }}
                onClick={() => {
                  sendDmp(item.fields.btnDmp, item.fields.btn_href?.value, '', 'click');
                  window.open(item.fields.btn_href?.value);
                }}
              >
                <ReactTooltip
                  overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
                    const rect1 = currentTarget.getBoundingClientRect();
                    const rect2 = node.getBoundingClientRect();
                    return { top: rect1.top + rect1.height, left: rect1.left + (rect1.width - rect2.width) / 2 };
                  }}
                />
              </button>
            );
          })}
        {appointment.length > 0 &&
          appointment.map((item, index) => {
            return (
              index < 3 && (
                <div
                  key={index}
                  className={
                    (item?.fields?.title?.value === '立即下订' && !pathname) ||
                    item?.fields?.title?.value === '奥德赛俱乐部' ||
                    item?.fields?.ismb?.value
                      ? 'mobileBtn'
                      : ''
                  }
                >
                  <button
                    data-action={item?.fields?.dataItem[0]?.fields?.func?.value}
                    data-item={item?.fields?.dataItem[0]?.displayName}
                    onClick={() => {
                      if (item?.fields?.url?.value === '') {
                        scrollToAnchor(item?.fields?.scrollTitle?.value);
                      } else {
                        if (item?.fields?.title?.value === '立即下订') {
                          GotoMall(item?.fields?.url?.value, '', isMobile);
                        } else {
                          window.open(item?.fields?.url?.value);
                        }
                      }
                    }}
                    style={convertStylesStringToObject(thisMobile ? item?.fields?.btnStyle?.value : item?.fields?.m_btnStyle?.value)}
                    dangerouslySetInnerHTML={{ __html: item?.fields?.btnDom?.value?.replaceHtml() }}
                  ></button>
                </div>
              )
            );
          })}
      </div>
      {clubLogo[0] && (
        <div className="modalBackground" ref={modalRef}>
          <div className="modal">
            <img alt="广汽本田" className="modalImg" src={createFilePath(clubLogo[0].fields[getPrefixField('img', !thisMobile)]?.value)} />
            <img
              alt="广汽本田"
              className="modalClose"
              src={createFilePath(clubLogo[0].fields[getPrefixField('close', !thisMobile)]?.value)}
              onClick={() => {
                sendDmp(clubLogo[0].fields.closeDmp);
                modalClose();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
