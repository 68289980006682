import React from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import temp from '../../temp/config';
import { createFilePath, isMobileFun, GotoMall } from '../../utils/obj-utils';
export default function index(props) {
  if (!props?.showFooter) {
    return null;
  }
  const isMobile = isMobileFun();
  const { fields } = props;
  const { top_left_data, top_right_data, bottom_company_title, bottom_other_link_title } = fields;

  return (
    <div className={props.fields.className.value} style={{ color: props.fields.fontColor.value, backgroundColor: props.fields.backColor.value }}>
      <div className="FotterAll">
        <div className="FotterTop">
          <div className="FotterTopLeft">
            <div className="FotterTopLeftHead">
              {top_left_data.map((item, index) => {
                return item.fields.LinkUrl.value ? (
                  <Link
                    data-action={item.fields.dataItem[0].fields.func.value}
                    data-item={item.fields.dataItem[0].displayName}
                    data-url={item.fields.LinkUrl.value}
                    href={item.fields.LinkUrl.value}
                    id={item.id}
                    name={item.name}
                    field={item.fields.title}
                    key={item.id}
                    style={{ color: props.fields.fontColor.value }}
                    target={item.target ? '_blank ' : ''}
                    rel={item.nofollow ? 'nofollow' : ''}
                  >
                    {item.fields.title.value}
                  </Link>
                ) : (
                  <span
                    data-action={item.fields.dataItem[0].fields.func.value}
                    data-item={item.fields.dataItem[0].displayName}
                    data-url={item.fields.LinkUrl.value}
                    id={item.id}
                    name={item.name}
                    field={item.fields.title}
                    key={item.id}
                  >
                    {item.fields.title.value}
                  </span>
                );
              })}
            </div>
            <div className="FotterTopLeftBottom">
              {top_left_data.map((item, index) => {
                return index === 0 ? (
                  <div key={item.id}>
                    {item.fields.listItem.map((ite, id) => {
                      return (
                        <Link
                          // data-action={ite.fields.dataItem[0].fields.func.value}
                          // data-item={ite.fields.dataItem[0].displayName}
                          // data-url={ite.fields.LinkUrl.value}
                          // href={ite.fields.LinkUrl.value}
                          id={ite.id}
                          name={ite.name}
                          field={ite.fields.title}
                          key={ite.id}
                          // target={ite.target ? '_blank ' : ''}
                          rel={ite.nofollow ? 'nofollow' : ''}
                          onClick={(e) => {
                            var url = ite.fields.LinkUrl.value;
                            if (ite.title === '广汽本田商城') {
                              GotoMall(ite.fields.LinkUrl.value, ite.fields.dataItem[0].displayName, isMobile);
                            } else {
                              if (ite.target) {
                                window.open(ite.fields.LinkUrl.value);
                              } else {
                                location.href = ite.fields.LinkUrl.value;
                              }
                              window.Dmp.clickAction(ite.fields.dataItem[0].displayName, url);
                            }
                          }}
                        >
                          {ite.fields.title.value}
                        </Link>
                      );
                    })}
                  </div>
                ) : (
                  <div key={item.id}>
                    {item.fields.listItem.map((ite, id) => {
                      return (
                        <Link
                          // data-action={ite.fields.dataItem[0].fields.func.value}
                          // data-item={ite.fields.dataItem[0].displayName}
                          // data-url={ite.fields.LinkUrl.value}
                          // href={ite.fields.LinkUrl.value}
                          id={ite.id}
                          name={ite.name}
                          field={ite.fields.title}
                          key={ite.id}
                          // target={ite.target ? '_blank ' : ''}
                          rel={ite.nofollow ? 'nofollow' : ''}
                          onClick={(e) => {
                            var url = ite.fields.LinkUrl.value;
                            if (ite.title === '广汽本田商城') {
                              GotoMall(ite.fields.LinkUrl.value, ite.fields.dataItem[0].displayName, isMobile);
                            } else {
                              if (ite.target) {
                                window.open(ite.fields.LinkUrl.value);
                              } else {
                                location.href = ite.fields.LinkUrl.value;
                              }
                              window.Dmp.clickAction(ite.fields.dataItem[0].displayName, url);
                            }
                          }}
                        >
                          {ite.fields.title.value}
                        </Link>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="FotterTopRight">
            {top_right_data.map((item, index) => {
              return (
                <div key={index}>
                  <img
                    alt="广汽本田"
                    // src={`${temp.app.deployUrlS3}${item.fields.icon_src.value}`}
                    src={createFilePath(item.fields.icon_src.value)}
                    className={top_right_data[index].fields.title.value !== '' ? '' : 'weChat'}
                  />
                  {index === 2 || index === 4 ? (
                    <Link
                      data-action="clickAction"
                      data-item={`${top_right_data[index].fields.dmp ? top_right_data[index].fields.dmp : 'bottom-button-btn' + (index + 1)}`}
                      data-url={top_right_data[index].fields.LinkUrl.value}
                      field={top_right_data[index].fields.title}
                      style={{ color: props.fields.fontColor.value }}
                    >
                      {top_right_data[index].fields.title.value !== '' ? (
                        <p
                          data-action="clickAction"
                          data-item={`${top_right_data[index].fields.dmp ? top_right_data[index].fields.dmp : 'bottom-button-btn' + (index + 1)}`}
                          dangerouslySetInnerHTML={{ __html: top_right_data[index].fields.title.value?.replaceHtml() }}
                          data-url={top_right_data[index].fields.LinkUrl.value}
                        >
                          {/* {top_right_data[index].fields.title.value} */}
                        </p>
                      ) : (
                        ''
                      )}
                    </Link>
                  ) : (
                    <Link
                      data-action="clickAction"
                      data-item={`${top_right_data[index].fields.dmp ? top_right_data[index].fields.dmp : 'bottom-button-btn' + (index + 1)}`}
                      data-url={top_right_data[index].fields.LinkUrl.value}
                      field={top_right_data[index].fields.title}
                      href={top_right_data[index].fields.LinkUrl.value}
                      style={{ color: props.fields.fontColor.value }}
                      target={top_right_data[index].target ? '_blank ' : ''}
                      rel={top_right_data[index].nofollow ? 'nofollow' : ''}
                    >
                      {top_right_data[index].fields.title.value !== '' ? (
                        <p
                          data-action="clickAction"
                          dangerouslySetInnerHTML={{ __html: top_right_data[index].fields.title.value?.replaceHtml() }}
                          data-item={`${top_right_data[index].fields.dmp ? top_right_data[index].fields.dmp : 'bottom-button-btn' + (index + 1)}`}
                          data-url={top_right_data[index].fields.LinkUrl.value}
                        >
                          {/* {top_right_data[index].fields.title.value} */}
                        </p>
                      ) : (
                        ''
                      )}
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Fotter">
          <div className="FotterBtm">
            {bottom_other_link_title.map((item, index) => {
              return (
                <Link
                  data-action={item.fields.dataItem[0].fields.func.value}
                  data-item={item.fields.dataItem[0].displayName}
                  data-url={item.fields.LinkUrl.value}
                  href={item.fields.LinkUrl.value}
                  id={item.id}
                  name={item.name}
                  field={item.fields.title}
                  key={item.id}
                  target={item.target ? '_blank ' : ''}
                  rel={item.nofollow ? 'nofollow' : ''}
                >
                  {item.fields.title.value}
                </Link>
              );
            })}
            <div className="FotterBottom" dangerouslySetInnerHTML={{ __html: bottom_company_title.value?.replaceHtml() }}>
              {/* <Link field={bottom_company_title}>{bottom_company_title.value.slice(0, 35)}</Link>
              <Link field={bottom_company_title}>{bottom_company_title.value.slice(35)}</Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
