/* eslint-disable no-bitwise */
import React, { useEffect, useState, useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import Script from 'react-load-script';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';
import { isEmpty, createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function index() {
  const pageSize = 6;
  const isMobile = isMobileFun();
  const [pageIndex, setPageIndex] = useState(1);
  const [data, setData] = useState([]);
  const [scriptLoad, setScriptLoad] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    getData();
  }, [pageIndex]);

  useEffect(() => {
    if (scriptLoad && data.length > 0) {
      loadShare();
    }
  }, [scriptLoad, data]);

  const getData = () => {
    setIsLoading(true);
    dataFetcher(`/api/honda/v1/Article/GetArticleList?articleType=2&pageIndex=${pageIndex}&pageSize=${pageSize}&client=${isMobile ? 2 : 1}`)
      .then(({ data: response }) => {
        setIsLoading(false);
        if (response.Status) {
          if (response?.Data[0]?.Activities?.articles?.length === 0) {
            setShowLoadMore(false);
          } else {
            setData([...data, ...response?.Data[0]?.Activities?.articles]);
          }
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const loadShare = () => {
    let shareId = '';
    let shareText = '';
    $(document).on('mouseover', '.bdsharebuttonbox', function () {
      shareId = $(this).parents('li').find('.detail').find('.list-title').attr('href') || '';
      if (shareId.indexOf('http') === -1) {
        shareId = window.location.protocol + '//' + window.location.host + shareId;
      }
      shareText = $(this).parents('li').find('.detail').find('.list-title').html();
      if ($(window).width() - $(this).offset().left < 230) {
        setTimeout(function () {
          var w = $('.bdshare_popup_box').css('width');
          var l = $('.bdshare_popup_box').css('left');
          if ($('.bdshare_popup_box').length > 0) {
            if ($('.bdshare_popup_box').offset().left + $('.bdshare_popup_box').width() > $(window).width()) {
              $('.bdshare_popup_box').css('left', parseInt(l) - parseInt(w) + 'px');
            }
          }
        }, 1);
      }
    });
    $(document).on('mousemove', '.bdsharebuttonbox', function () {
      if ($(window).width() - $(this).offset().left < 230) {
        setTimeout(function () {
          var w = $('.bdshare_popup_box').css('width');
          var l = $('.bdshare_popup_box').css('left');
          if ($('.bdshare_popup_box').length > 0) {
            if ($('.bdshare_popup_box').offset().left + $('.bdshare_popup_box').width() > $(window).width()) {
              $('.bdshare_popup_box').css('left', parseInt(l) - parseInt(w) + 'px');
            }
          }
        }, 1);
      }
    });
    function setShareUrl(cmd, config) {
      if (shareId) {
        config.bdUrl = shareId;
        config.bdText = shareText;
      }
      return config;
    }
    window._bd_share_config = {
      common: {
        onBeforeClick: setShareUrl,
        bdSnsKey: {},
        bdText: '',
        bdMini: '2',
        bdMiniList: false,
        bdPic: '',
        bdStyle: '0',
        bdSize: '24',
        bdPopupOffsetTop: isMobile ? -1500 : 0
      },
      share: {}
    };
    $('.bdshare_popup_bg').remove();
    $('.bdshare_popup_box').remove();
    if (window._bd_share_main) delete window._bd_share_main;
    (document.getElementsByTagName('head')[0] || body).appendChild(document.createElement('script')).src = createFilePath(
      `/common/lib/share/static/api/js/share.js?cdnversion=${~(-new Date() / 36e5)}`
    );
  };

  const likeClick = (item, index) => {
    dataFetcher(`/api/honda/v1/Article/AddArticleLike?itemId=${item.itemid}&articleType=${item.a_type}`, {})
      .then(({ data: response }) => {
        if (!response.Status) {
          if (parseInt(response.ErrorResult.ErrCode) === 3) {
            alert(response.ErrorResult.ErrMsg);
          }
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        } else {
          data[index].like = true;
          data[index].article_like_total = ++data[index].article_like_total;
          setData([...data]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loadMore = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <section className="activity-v1" ref={containerRef}>
      <Script url={createFilePath('/common/lib/common/js/jquery.min.js')} onLoad={() => setScriptLoad(true)} />
      <div className="title">精彩活动</div>
      <ul className="list">
        {data.map((item, index) => {
          const dom = (isMobile ? (isEmpty(item.event_m_val) ? item.event_pc_val : item.event_m_val) : item.event_pc_val).replace(/link/g, 'a');
          const link = new DOMParser().parseFromString(dom, 'text/html').body.childNodes[0];
          const isEnd = moment().diff(moment(item.a_end_date)) >= 0;
          const isStart = moment().diff(moment(item.a_end_date)) < 0 && moment().diff(moment(item.a_start_date)) >= 0;
          return (
            <li className="item" key={index}>
              <div className="item-container">
                {/* {isEnd && (
                  <span className="act-end" style={{ background: `url(${createFilePath('/common/icon/act_end.png')}) no-repeat center` }}>
                    活动结束
                  </span>
                )} */}
                <div className="picture-container">
                  <img className="picture" src={createFilePath(item.a_thumb.replaceHtml())} alt={item.a_title} />
                  <a className="mask" href={link.getAttribute('url')} target={link.getAttribute('target')}>
                    <div className="subtitle" dangerouslySetInnerHTML={{ __html: item.a_subtitle }}></div>
                  </a>
                </div>
                <div className="detail">
                  <div className="list-title-container">
                    <a
                      className="list-title"
                      href={link.getAttribute('url')}
                      target={link.getAttribute('target')}
                      dangerouslySetInnerHTML={{ __html: item.a_title }}
                    ></a>
                    {isEnd && (
                      <span
                        className="act-end"
                        style={{ background: `url(${createFilePath('/common/icon/act_end2.png')}) no-repeat center`, backgroundSize: '100%' }}
                      ></span>
                    )}
                    {isStart && (
                      <span
                        className="act-start"
                        style={{ background: `url(${createFilePath('/common/icon/act_start.png')}) no-repeat center`, backgroundSize: '100%' }}
                      ></span>
                    )}
                  </div>
                  <div className="bottom-container">
                    <div className="pubilsh-date">{moment(item.publish_date).format('YYYY-MM-DD')}</div>
                    <div className="share-container">
                      <div className="like-container" onClick={() => likeClick(item, index)}>
                        <div
                          className={`icon ${item.like ? 'icon-active' : ''}`}
                          style={{ background: `url(${createFilePath('/common/icon/heart.png')}) no-repeat` }}
                        />
                        <div className="count">({item.article_like_total || 0})</div>
                      </div>
                      <span className="bdsharebuttonbox share-button-box">
                        <a
                          className="bds_more share-more"
                          data-cmd="more"
                          style={{ background: `url(${createFilePath('/common/icon/share.png')}) no-repeat` }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      {data.length > 0 && showLoadMore && !isLoading && (
        <div className="load-more-container">
          <div className="load-more" onClick={loadMore}>
            <img alt="广汽本田" className="load-more-image" src={createFilePath('/common/icon/loadMore.png')} />
            <div className="load-more-text">查看更多</div>
          </div>
        </div>
      )}
    </section>
  );
}
