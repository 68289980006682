/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';
import { createFilePath, isMobileFun } from '../../utils/obj-utils';
import ReactPaginate from 'react-paginate';

export default function index() {
  const isMobile = isMobileFun();
  const pageSize = isMobile ? 1 : 10;
  const [pageIndex, setPageIndex] = useState(1);
  const [data, setData] = useState();
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    getData(pageIndex);
  }, [pageIndex]);

  const getData = (pageIndex = 1) => {
    dataFetcher(`/api/honda/v1/Article/GetEnvList?pageIndex=${pageIndex}&pageSize=${pageSize}&key=${keyword}`)
      .then(({ data: response }) => {
        if (response?.Status) {
          setData(response);
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const keywordInput = (e) => {
    setKeyword(e.target.value);
  };

  const search = () => {
    reloadData();
  };

  const pageChange = (page) => {
    setPageIndex(page.selected + 1);
  };

  const reloadData = () => {
    if (pageIndex === 1) {
      getData();
    } else {
      setPageIndex(1);
    }
  };

  const pageCount = Math.ceil(data?.PageModel?.TotalCount / pageSize);

  return (
    <section className="environment-information-v1">
      <div className="title">环保信息公开</div>
      <div className="search-container">
        <label>请输入查询条件：</label>
        <input
          className="search-input"
          type="text"
          placeholder="支持信息公开编号、车型型号、发动机型号的模糊查询"
          onInput={keywordInput}
          onKeyDown={(e) => e.key === 'Enter' && search()}
        />
        <button className="search-button" onClick={search}>
          立即查询
        </button>
      </div>
      <div className="title-container">
        <span className="left-title">全部车型</span>
        <span className="right-title">
          共<span className="count">{data?.PageModel?.TotalCount}</span>条结果
        </span>
      </div>
      <table className="environment-table">
        {!isMobile && (
          <thead>
            <tr>
              <th width="26.45%">信息公开编号</th>
              <th width="12.91%">车型型号</th>
              <th width="12.91%">发动机型号</th>
              <th width="18.54%">公开时间</th>
              <th width="19.58%">制造商</th>
              <th width="">详情</th>
            </tr>
          </thead>
        )}
        <tbody>
          {isMobile ? (
            <>
              {data?.PageModel?.TotalCount > 0 && (
                <>
                  <tr>
                    <td width="28.4%" className="header">
                      信息公开编号
                    </td>
                    <td>{data?.Data[0]?.id}</td>
                  </tr>
                  <tr>
                    <td className="header">车型型号</td>
                    <td>{data?.Data[0]?.vehicle_model}</td>
                  </tr>
                  <tr>
                    <td className="header">发动机型号</td>
                    <td>{data?.Data[0]?.engine_model}</td>
                  </tr>
                  <tr>
                    <td className="header">公开时间</td>
                    <td>{moment(data?.Data[0]?.opentime).format('YYYY-MM-DD HH:mm:ss')}</td>
                  </tr>
                  <tr>
                    <td className="header">制造商</td>
                    <td>{data?.Data[0]?.company}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <a
                        target="_blank"
                        href={createFilePath(`/environment/attachment/${data?.Data[0]?.id}.pdf`)}
                        className="detail-button"
                        rel="noreferrer"
                      >
                        查看详情
                      </a>
                    </td>
                  </tr>
                </>
              )}
            </>
          ) : (
            <>
              {data?.Data?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.id}</td>
                  <td>{item?.vehicle_model}</td>
                  <td>{item?.engine_model}</td>
                  <td>{moment(item?.opentime).format('YYYY-MM-DD HH:mm:ss')}</td>
                  <td>{item?.company}</td>
                  <td>
                    <a target="_blank" href={createFilePath(`/environment/attachment/${item?.id}.pdf`)} className="detail-button" rel="noreferrer">
                      查看详情
                    </a>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
        {!data?.PageModel?.TotalCount && '没有找到相关数据'}
      </table>
      {pageCount > 1 && (
        <ReactPaginate
          previousLabel="上一页"
          nextLabel="下一页"
          pageCount={pageCount}
          pageRangeDisplayed={isMobile ? 1 : 2}
          marginPagesDisplayed={isMobile ? 1 : 2}
          containerClassName="pagination"
          breakClassName="break"
          activeClassName="active"
          renderOnZeroPageCount={null}
          onPageChange={pageChange}
        />
      )}
    </section>
  );
}
