import React, { Fragment, useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import { getPrefixField, sendDmpAction, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { fields } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [windowHeight, setWindowHeight] = useState(400);
  // const thisMobile = !useSitecoreContext()?.sitecoreContext?.isMobile;
  const isMobile = isMobileFun();
  const thisMobile = !isMobileFun();
  const rootEl = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields.load_dmp_point);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = 'media';
  };
  const swiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
  };

  return (
    <div>
      <Controller>
        {/* indicators={true} */}
        <Scene triggerHook={1} duration={windowHeight}>
          {(progress, event) => {
            var gridTemplateColumns;
            var tabLen = fields[getPrefixField('everyData')].length;
            var maxPercent = 100;
            var gridTempColStyle = [],
              tempArr = [];
            var ratio = 10;
            while (tabLen-- > 0) {
              tempArr.push(`${ratio}%`);
              maxPercent -= ratio;
            }
            gridTempColStyle.push(`${maxPercent}%`);
            gridTempColStyle.push(...tempArr);
            gridTemplateColumns = gridTempColStyle.join(' ');
            return (
              <div>
                {/* <Timeline totalProgress={progress} paused>
              <Tween from={{ scale: 0.9 }} to={{ scale: 1 }} stagger={0} ease="elastic.out(0.1, 0.1)"> */}
                <div
                  className="ListContent"
                  id="media"
                  ref={rootEl}
                  style={{ backgroundColor: fields[`${getPrefixField('background_color')}`]?.value }}
                >
                  <div className="ListContentCenter">
                    <div className="ListContentHeader" style={thisMobile ? { gridTemplateColumns: gridTemplateColumns } : {}}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: fields[`${getPrefixField('everyData')}`][tabIndex].fields[`${getPrefixField('title')}`].value?.replaceHtml()
                        }}
                      ></p>
                      {fields[`${getPrefixField('everyData')}`].length > 1 ? ( // 复数的时候显示标签
                        thisMobile ? (
                          fields.everyData.map((data, index) => {
                            return (
                              <p
                                className={`tab ${index === tabIndex ? 'tabActive' : ''}`}
                                key={index}
                                onClick={(event) => {
                                  setTabIndex(index);
                                }}
                                dangerouslySetInnerHTML={{ __html: data.fields.title?.value?.replaceHtml() }}
                              ></p>
                            );
                          })
                        ) : (
                          <select
                            value={tabIndex}
                            onChange={(event) => {
                              setTabIndex(event.target.value);
                            }}
                          >
                            {fields.m_everyData.map((data, index) => {
                              return (
                                <option key={index} value={index}>
                                  {data.fields.m_title.value}
                                </option>
                              );
                            })}
                          </select>
                        )
                      ) : null}
                    </div>
                    {!thisMobile
                      ? [fields.m_everyData[tabIndex]].map((data, dataIndex) => {
                          const dataLen = fields.m_everyData[tabIndex].length;
                          return (
                            <Fragment key={`${index}-m`}>
                              <div
                                key={`${index}_prev`}
                                data-html-item={data?.fields?.btnl_dmp_point[0]?.name}
                                className="swiper-button-prev-listcontent"
                                style={{ opacity: activeIndex === 0 ? 0.35 : 1 }}
                              >
                                <img alt="广汽本田" src={`${createFilePath(fields.leftArrow.value)}`}></img>
                              </div>
                              <div
                                key={`${index}_next`}
                                className="swiper-button-next-listcontent"
                                data-html-item={data?.fields?.btnr_dmp_point[0]?.name}
                                style={{ opacity: activeIndex === data.fields.m_ConcreteData.length - data.fields.m_slideCount.value ? 0.35 : 1 }}
                              >
                                <img alt="广汽本田" src={`${createFilePath(fields.rightArrow.value)}`}></img>
                              </div>
                              {/* <div className={'swiper-pagination-listContent-m'}></div> */}
                              <div className="swiper-pagination-listContent-m swiper-pagination-bullets swiper-pagination-horizontal">
                                {data.fields.m_ConcreteData.length > 0 &&
                                  data.fields.m_ConcreteData.map((item, index) => {
                                    return (
                                      <span
                                        key={index}
                                        className={`swiper-pagination-bullet ${index === activeIndex ? 'swiper-pagination-bullet-active' : ''}`}
                                        onClick={() => {
                                          swiperslideTo(index);
                                          if (fields?.paginationdmp) {
                                            window.Dmp.clickAction(fields?.paginationdmp?.value + (Number(index) + 1));
                                          } else {
                                            window.Dmp.clickAction('media-3-btn' + (Number(index) + 1));
                                          }
                                        }}
                                      ></span>
                                    );
                                  })}
                              </div>
                              <Swiper
                                updateOnWindowResize={true}
                                slidesPerView={data.fields.m_slideCount.value || 1}
                                key={dataIndex}
                                modules={[Navigation, Pagination]}
                                ref={swiperRef}
                                onActiveIndexChange={(swiper) => {
                                  setActiveIndex(swiper.activeIndex);
                                }}
                                // onSlidePrevTransitionStart={() => {
                                //   const dmpPointList = data.fields.btnl_dmp_point;
                                //   if (dmpPointList?.length > 0) {
                                //     const dmpAction = dmpPointList[0]?.fields?.func?.value;
                                //     const dmpPoint = dmpPointList[0].name;
                                //     sendDmpAction(dmpAction, dmpPoint, '');
                                //   }
                                // }}
                                // onSlideNextTransitionStart={() => {
                                //   const dmpPointList = data.fields.btnr_dmp_point;
                                //   if (dmpPointList?.length > 0) {
                                //     const dmpAction = dmpPointList[0]?.fields?.func?.value;
                                //     const dmpPoint = dmpPointList[0].name;
                                //     sendDmpAction(dmpAction, dmpPoint, '');
                                //   }
                                // }}
                                // pagination={{
                                //   el: '.swiper-pagination-listContent-m',
                                //   clickable: true,
                                //   bulletActiveClass: 'swiper-pagination-bullet-active',
                                //   bulletClass: 'swiper-pagination-bullet'
                                // }}
                                navigation={{
                                  nextEl: '.swiper-button-next-listcontent',
                                  prevEl: '.swiper-button-prev-listcontent'
                                }}
                              >
                                {data.fields.m_ConcreteData.map((item, index) => {
                                  return (
                                    <SwiperSlide key={index} className="slideOnly">
                                      <div className="sliderin">
                                        <img alt="广汽本田" src={`${createFilePath(item.fields.m_image.value)}`} />
                                        <div className="slidertext">
                                          <p dangerouslySetInnerHTML={{ __html: item.fields.context?.value?.replaceHtml() }}></p>
                                          <p dangerouslySetInnerHTML={{ __html: item.fields.sourceText?.value?.replaceHtml() }}></p>
                                          <button
                                            data-action={
                                              item.fields.detail_btn_dmp_point?.length > 0
                                                ? item.fields.detail_btn_dmp_point[0]?.fields?.func?.value
                                                : ''
                                            }
                                            data-item={item.fields.detail_btn_dmp_point?.length > 0 ? item.fields.detail_btn_dmp_point[0].name : ''}
                                            data-url={item.fields.m_sourceUrl.value}
                                            style={convertStylesStringToObject(item.fields.m_detialBtnStyle.value)}
                                            onClick={() => {
                                              window.open(item.fields.m_sourceUrl.value);
                                            }}
                                          >
                                            <span dangerouslySetInnerHTML={{ __html: item.fields.detialBtn?.value?.replaceHtml() }}></span>
                                          </button>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  );
                                })}
                              </Swiper>
                            </Fragment>
                          );
                        })
                      : [fields.everyData[tabIndex]].map((data, dataIndex) => {
                          return (
                            <Fragment key={`${index}-pc`}>
                              <div
                                data-html-item={data?.fields?.btnl_dmp_point[0]?.name}
                                style={{ opacity: activeIndex === 0 ? 0.35 : 1 }}
                                key={`${index}_prev`}
                                className="swiper-button-prev-listcontent"
                              >
                                <img alt="广汽本田" src={`${createFilePath(fields.leftArrow.value)}`}></img>
                              </div>
                              <div
                                data-html-item={data?.fields?.btnr_dmp_point[0]?.name}
                                style={{ opacity: activeIndex === data.fields.ConcreteData.length - data.fields.slideCount.value ? 0.35 : 1 }}
                                key={`${index}_next`}
                                className="swiper-button-next-listcontent"
                              >
                                <img alt="广汽本田" src={`${createFilePath(fields.rightArrow.value)}`}></img>
                              </div>
                              <Swiper
                                key={dataIndex}
                                slidesPerView={data.fields.slideCount.value}
                                breakpoints={{ 1440: { spaceBetween: 60 }, 1024: { spaceBetween: 40 } }}
                                modules={[Navigation]}
                                updateOnWindowResize={true}
                                className={`swiper ${progress > 0 ? 'positionLeft' : ''}`}
                                onActiveIndexChange={(swiper) => {
                                  setActiveIndex(swiper.activeIndex);
                                }}
                                // onSlidePrevTransitionStart={() => {
                                //   const dmpPointList = data.fields.btnl_dmp_point;
                                //   if (dmpPointList?.length > 0) {
                                //     const dmpAction = dmpPointList[0]?.fields?.func?.value;
                                //     const dmpPoint = dmpPointList[0].name;
                                //     sendDmpAction(dmpAction, dmpPoint, '');
                                //   }
                                // }}
                                // onSlideNextTransitionStart={() => {
                                //   const dmpPointList = data.fields.btnr_dmp_point;
                                //   if (dmpPointList?.length > 0) {
                                //     const dmpAction = dmpPointList[0]?.fields?.func?.value;
                                //     const dmpPoint = dmpPointList[0].name;
                                //     sendDmpAction(dmpAction, dmpPoint, '');
                                //   }
                                // }}
                                navigation={{
                                  nextEl: '.swiper-button-next-listcontent',
                                  prevEl: '.swiper-button-prev-listcontent'
                                }}
                              >
                                {data.fields.ConcreteData.map((item, index) => {
                                  return (
                                    <SwiperSlide key={index} className="slideOnly">
                                      <div className="sliderin">
                                        <img alt="广汽本田" src={`${createFilePath(item.fields.image.value)}`} />
                                        <div className="slidertext">
                                          <p dangerouslySetInnerHTML={{ __html: item.fields.context?.value?.replaceHtml() }}></p>
                                          <p dangerouslySetInnerHTML={{ __html: item.fields.sourceText?.value?.replaceHtml() }}></p>
                                          <button
                                            data-action={
                                              item.fields.detail_btn_dmp_point?.length > 0
                                                ? item.fields.detail_btn_dmp_point[0]?.fields?.func?.value
                                                : ''
                                            }
                                            data-item={item.fields.detail_btn_dmp_point?.length > 0 ? item.fields.detail_btn_dmp_point[0].name : ''}
                                            data-url={item.fields.sourceUrl.value}
                                            style={convertStylesStringToObject(item.fields.detialBtnStyle.value)}
                                            onClick={() => {
                                              window.open(item.fields.sourceUrl.value);
                                            }}
                                          >
                                            <span dangerouslySetInnerHTML={{ __html: item.fields.detialBtn?.value?.replaceHtml() }}></span>
                                          </button>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  );
                                })}
                              </Swiper>
                            </Fragment>
                          );
                        })}
                  </div>
                </div>
                {/* </Tween>
            </Timeline> */}
              </div>
            );
          }}
        </Scene>
      </Controller>
      <div
        className="htmlhidden"
        dangerouslySetInnerHTML={{
          __html: fields[`${getPrefixField('sethtml', !thisMobile)}`].value?.replaceHtml()
        }}
      ></div>
    </div>
  );
}
