import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import $ from 'jquery';
import './style.scss';
import { isEmpty, createFilePath, sendDmp, getPrefixField, checkMobile, getQueryString, isMobileFun } from '../../utils/obj-utils';
import EnlargeHeroScrollytelling from '../../elements/EnlargeHeroScrollytelling';
import Details from '../../elements/InnerPageDetail';
import useIsomorphicLayoutEffect from '../../utils/useIsomorphicLayoutEffect';
import { dataFetcher, getJsonp } from '../../dataFetcher';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// 计算经纬度之间的距离
function getDistance(lat1, lng1, lat2, lng2) {
  var radLat1 = (lat1 * Math.PI) / 180.0;
  var radLat2 = (lat2 * Math.PI) / 180.0;
  var a = radLat1 - radLat2;
  var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
  var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
  s = s * 6378.137;
  s = Math.round(s * 10000) / 10000;
  return s;
}
function unique(arr) {
  return Array.from(new Set(arr));
}
export default function index(props) {
  const [num, setNum] = useState(0);
  const [windowHeight, setWindowHeight] = useState(400);
  const isMobile = isMobileFun();
  const [flag, setFlag] = useState(false);
  const rootEl = useRef(null);
  const containerRef = useRef(null);
  const mapRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [location, setLocation] = useState({ province: '广东省', city: '广州市' });
  const [originalDealerData, setOriginalDealerData] = useState();
  const [provinceData, setProvinceData] = useState();
  const [selectProvince, setSelectProvince] = useState();
  const [selectCity, setSelectCity] = useState();
  const [selectDealer, setSelectDealer] = useState();
  const [policyVersion, setPolicyVersion] = useState(null);
  const [ownAddress, setOwnAddress] = useState([]);
  const mapContainerRef = useRef(null);
  const [selectVehicleType, setSelectVehicleType] = useState();
  const globalist = JSON.parse(props.fields.globaListData.value.replaceHtml());
  /* [
    { id: 'DZJ002', province: '浙江', city: '宁波', dealers: '宁波骏达店', adress: '', day: '4月25日-5月2日' },
    { id: 'DZJ009', province: '浙江', city: '宁波', dealers: '宁波中兴店', adress: '', day: '5月3日-5月6日' },
    { id: 'DZJ025', province: '浙江', city: '宁波', dealers: '宁波中升广达店', adress: '', day: '5月7日-5月13日' },
    { id: 'DZJ046', province: '浙江', city: '宁波', dealers: '宁海国宏店', adress: '', day: '5月14日-5月18日' },
    { id: 'DZJ048', province: '浙江', city: '宁波', dealers: '宁波北仑店', adress: '', day: '5月19日-5月22日' },
    { id: 'DZJ017', province: '浙江', city: '宁波', dealers: '宁波慈溪店', adress: '', day: '5月23日-5月25日' },
    { id: 'DZJ026', province: '浙江', city: '宁波', dealers: '余姚信捷店', adress: '', day: '5月26日-5月30日' },
    { id: 'DZJ054', province: '浙江', city: '温州', dealers: '乐清乐广', adress: '', day: '4月25日-4月29日' },
    { id: 'DZJ041', province: '浙江', city: '温州', dealers: '温州红翔', adress: '', day: '5月5日-5月8日' },
    { id: 'DZJ052', province: '浙江', city: '温州', dealers: '温州正大', adress: '', day: '5月13日-5月15日' },
    { id: 'DZJ049', province: '浙江', city: '温州', dealers: '瑞安冠豪', adress: '', day: '5月16日-5月19日' },
    { id: 'DZJ011', province: '浙江', city: '温州', dealers: '瑞安红旭', adress: '', day: '5月20日-5月23日' },
    { id: 'DZJ064', province: '浙江', city: '温州', dealers: '平阳祥龙', adress: '', day: '5月24日-5月27日' },
    { id: 'DZJ040', province: '浙江', city: '温州', dealers: '苍南五田', adress: '', day: '5月28日-5月31日' },
    { id: 'DFJ008', province: '福建', city: '泉州', dealers: '晋江润通', adress: '', day: '4月29日-5月5日' },
    { id: 'DFJ012', province: '福建', city: '泉州', dealers: '石狮远通', adress: '', day: '5月6日-5月11日' },
    { id: 'DFJ032', province: '福建', city: '泉州', dealers: '晋江福埔', adress: '', day: '5月12日-5月15日' },
    { id: 'DFJ026', province: '福建', city: '泉州', dealers: '泉州景顺', adress: '', day: '5月16日-5月18日' },
    { id: 'DFJ023', province: '福建', city: '泉州', dealers: '泉州顺源', adress: '', day: '5月19日-5月22日' },
    { id: 'DFJ016', province: '福建', city: '泉州', dealers: '泉州新鑫', adress: '', day: '5月23日-5月26日' },
    { id: 'DFJ008', province: '福建', city: '泉州', dealers: '晋江润通', adress: '', day: '5月27日-5月30日' },
    { id: 'DZJ018', province: '浙江', city: '杭州', dealers: '杭州古田店', adress: '', day: '4月25日-5月9日' },
    { id: 'DZJ001', province: '浙江', city: '杭州', dealers: '杭州新世纪', adress: '', day: '5月10日-5月19日' },
    { id: 'DZJ035', province: '浙江', city: '杭州', dealers: '富阳富田', adress: '', day: '5月20日-5月30日' },
    { id: 'DSC001', province: '四川', city: '成都', dealers: '成都三和', adress: '', day: '4月28日-5月8日' },
    { id: 'DSC011', province: '四川', city: '成都', dealers: '成都百翔', adress: '', day: '5月8日-5月18日' },
    { id: 'DSC027', province: '四川', city: '成都', dealers: '成都贵诚', adress: '', day: '5月18日-5月28日' },
    { id: 'DSC001', province: '四川', city: '成都', dealers: '成都三和', adress: '', day: '5月28日-6月8日' },
    { id: 'DSC011', province: '四川', city: '成都', dealers: '成都百翔', adress: '', day: '6月8日-6月18日' },
    { id: 'DSC027', province: '四川', city: '成都', dealers: '成都贵诚', adress: '', day: '6月18日-6月28日' },
    { id: 'DSC024', province: '四川', city: '成都', dealers: '成都长缘', adress: '', day: '4月28日-5月8日' },
    { id: 'DSC033', province: '四川', city: '成都', dealers: '成都长昱', adress: '', day: '5月8日-5月18日' },
    { id: 'DSC024', province: '四川', city: '成都', dealers: '成都长缘', adress: '', day: '5月18日-5月28日' },
    { id: 'DSC033', province: '四川', city: '成都', dealers: '成都长昱', adress: '', day: '5月28日-6月8日' },
    { id: 'DCQ004', province: '重庆', city: '重庆', dealers: '重庆长捷', adress: '', day: '4月28日-5月8日' },
    { id: 'DCQ009', province: '重庆', city: '重庆', dealers: '重庆长嘉卓越', adress: '', day: '5月8日-5月18日' },
    { id: 'DCQ010', province: '重庆', city: '重庆', dealers: '重庆铭进', adress: '', day: '5月18日-5月28日' },
    { id: 'DSXW01', province: '陕西', city: '西安', dealers: '西安银光', adress: '', day: '4月15日-4月25日' },
    { id: 'DSXW04', province: '陕西', city: '西安', dealers: '西安白云', adress: '', day: '4月26日-4月30日' },
    { id: 'DSXW04', province: '陕西', city: '西安', dealers: '西安白云', adress: '', day: '5月6日-5月10日' },
    { id: 'DSXW06', province: '陕西', city: '西安', dealers: '西安博盛', adress: '', day: '5月11日-5月20日' },
    { id: 'DSXW15', province: '陕西', city: '西安', dealers: '西安恒得利', adress: '', day: '5月21日-5月30日' },
    { id: 'DSXW01', province: '陕西', city: '西安', dealers: '西安银光', adress: '', day: '5月31日' },
    { id: 'DJS001', province: '江苏', city: '南京', dealers: '南京雨田', adress: '', day: '4月25日-5月8日' },
    { id: 'EJS001', province: '江苏', city: '南京', dealers: '南京东麒路e中心', adress: '', day: '5月9日-5月22日' },
    { id: 'DJS036', province: '江苏', city: '南京', dealers: '南京长昊', adress: '', day: '5月23日-5月31日' },
    { id: 'DJS003', province: '江苏', city: '苏州', dealers: '苏州苏新', adress: '', day: '4月25日-5月12日' },
    { id: 'DJS010', province: '江苏', city: '苏州', dealers: '苏州本旺', adress: '', day: '5月13日-5月21日' },
    { id: 'DJS045', province: '江苏', city: '苏州', dealers: '苏州宝康', adress: '', day: '5月22日-5月31日' },
    { id: 'DJS018', province: '江苏', city: '苏州', dealers: '张家港信安', adress: '', day: '4月25日-5月10日' },
    { id: 'DJS014', province: '江苏', city: '苏州', dealers: '常熟银理', adress: '', day: '5月11日-5月17日' },
    { id: 'DJS035', province: '江苏', city: '苏州', dealers: '太仓绿田', adress: '', day: '5月18日-5月23日' },
    { id: 'DJS044', province: '江苏', city: '苏州', dealers: '昆山合田行', adress: '', day: '5月24日-5月31日' },
    { id: 'DSD026', province: '山东', city: '潍坊', dealers: '潍坊泰合', adress: '', day: '4月25日-5月5日' },
    { id: 'DSD034', province: '山东', city: '潍坊', dealers: '青州金旺', adress: '', day: '5月6日-5月15日' },
    { id: 'DSD047', province: '山东', city: '潍坊', dealers: '潍坊驰峰', adress: '', day: '5月16日-5月24日' },
    { id: 'DSD042', province: '山东', city: '潍坊', dealers: '高密隆顺', adress: '', day: '5月25日-5月31日' },
    { id: 'DSD008', province: '山东', city: '临沂', dealers: '临沂翔宇', adress: '', day: '4月25日-5月10日' },
    { id: 'DSD021', province: '山东', city: '临沂', dealers: '临沂骏业', adress: '', day: '5月11日-5月20日' },
    { id: 'DSD033', province: '山东', city: '临沂', dealers: '临沂冠成', adress: '', day: '5月21日-5月31日' },
    { id: 'DSH003', province: '上海', city: '上海', dealers: '广汽锦仓', adress: '', day: '4月25日-5月12日' },
    { id: 'DSH014', province: '上海', city: '上海', dealers: '上海五角场', adress: '', day: '5月13日-5月26日' },
    { id: 'DSH008', province: '上海', city: '上海', dealers: '上海松广', adress: '', day: '4月25日-5月12日' },
    { id: 'DSH016', province: '上海', city: '上海', dealers: '上海玮兴青浦', adress: '', day: '5月13日-5月26日' },
    { id: 'DSH013', province: '上海', city: '上海', dealers: '上海奉贤', adress: '', day: '4月25日-5月12日' },
    { id: 'DSH016', province: '上海', city: '上海', dealers: '上海长雅', adress: '', day: '5月13日-5月26日' },
    { id: 'DJS051', province: '江苏', city: '无锡', dealers: '无锡照程', adress: '', day: '4月25日-5月19日' },
    { id: 'DJS013', province: '江苏', city: '无锡', dealers: '无锡东鑫', adress: '', day: '5月20日-5月26日' },
    { id: 'DAH018', province: '安徽', city: '合肥', dealers: '合肥恒信致合店', adress: '', day: '4月17-30日' },
    { id: 'DAH003', province: '安徽', city: '合肥', dealers: '合肥新站', adress: '', day: '4月30日-5月5日' },
    { id: 'DAH001', province: '安徽', city: '合肥', dealers: '合肥安田', adress: '', day: '5月5-15日' },
    { id: 'DAH008', province: '安徽', city: '合肥', dealers: '合肥包河', adress: '', day: '5月15-25日' },
    { id: 'DAH024', province: '安徽', city: '合肥', dealers: '合肥悦兴', adress: '', day: '5月25-30日' },
    { id: 'DHUN11', province: '湖南', city: '长沙', dealers: '长沙华顺', adress: '', day: '4月17-30日' },
    { id: 'DHUN29', province: '湖南', city: '长沙', dealers: '长沙宝盛河东', adress: '', day: '5月5-15日' },
    { id: 'DHUN34', province: '湖南', city: '长沙', dealers: '长沙雨花', adress: '', day: '5月15-25日' },
    { id: 'DHUN11', province: '湖南', city: '长沙', dealers: '长沙华顺', adress: '', day: '5月25-30日' },
    { id: 'DHUN10', province: '湖南', city: '长沙', dealers: '长沙潇湘', adress: '', day: '4月17-30日' },
    { id: 'DHUN25', province: '湖南', city: '长沙', dealers: '长沙九城', adress: '', day: '5月1-10日' },
    { id: 'DHUN27', province: '湖南', city: '长沙', dealers: '长沙中升宏达', adress: '', day: '5月11-21日' },
    { id: 'DHUN10', province: '湖南', city: '长沙', dealers: '长沙潇湘', adress: '', day: '5月21-30日' },
    { id: 'DHEN03', province: '河南', city: '郑州', dealers: '郑州智通', adress: '', day: '4月17-5日' },
    { id: 'DHEN23', province: '河南', city: '郑州', dealers: '郑州神通', adress: '', day: '5月5-20日' },
    { id: 'DHEN38', province: '河南', city: '郑州', dealers: '郑州凯融', adress: '', day: '5月20-30日' },
    { id: 'DHEN04', province: '河南', city: '郑州', dealers: '郑州北环', adress: '', day: '4月17-5日' },
    { id: 'DHEN27', province: '河南', city: '郑州', dealers: '郑州锦上', adress: '', day: '5月5-15日' },
    { id: 'DHEN01', province: '河南', city: '郑州', dealers: '郑州港源', adress: '', day: '5月15-25日' },
    { id: 'DHEN28', province: '河南', city: '郑州', dealers: '郑州骏远', adress: '', day: '5月25-30日' },
    { id: 'DHUB04', province: '湖北', city: '武汉', dealers: '武汉景田', adress: '', day: '4月17-30日' },
    { id: 'DHUB12', province: '湖北', city: '武汉', dealers: '武汉友谊', adress: '', day: '5月1-10日' },
    { id: 'DHUB20', province: '湖北', city: '武汉', dealers: '武汉和嘉', adress: '', day: '5月11日-21日' },
    { id: 'DHUB12', province: '湖北', city: '武汉', dealers: '武汉富鑫', adress: '', day: '5月22日-31日' },
    { id: 'DHUB02', province: '湖北', city: '武汉', dealers: '武汉友芝友', adress: '', day: '4月17-5月3日' },
    { id: 'DHUB01', province: '湖北', city: '武汉', dealers: '武汉龙阳', adress: '', day: '5月4-31日' },
    { id: 'DSXE16', province: '山西', city: '太原', dealers: '太原黄河', adress: '', day: '5月1日-5月10日' },
    { id: 'DSXE11', province: '山西', city: '太原', dealers: '太原兴河', adress: '', day: '5月11日-5月20日' },
    { id: 'DSXE12', province: '山西', city: '太原', dealers: '太原新佳', adress: '', day: '5月21日-5月30日' },
    { id: 'DSXE16', province: '山西', city: '太原', dealers: '太原黄河', adress: '', day: '5月31日-5月31日' },
    { id: 'DHEB02', province: '河北', city: '石家庄', dealers: '石家庄恒达', adress: '', day: '5月1日-5月12日' },
    { id: 'DHEB10', province: '河北', city: '石家庄', dealers: '石家庄日新', adress: '', day: '5月13日-5月19日' },
    { id: 'DHEB15', province: '河北', city: '石家庄', dealers: '石家庄信昌盛远', adress: '', day: '5月20日-5月23日' },
    { id: 'DHEB27', province: '河北', city: '石家庄', dealers: '石家庄中升腾达', adress: '', day: '5月24日-5月29日' },
    { id: 'DHEB02', province: '河北', city: '石家庄', dealers: '石家庄恒达', adress: '', day: '5月30日' },
    { id: 'DTJ001', province: '天津', city: '天津', dealers: '天津凯通', adress: '', day: '5月1日-5月6日' },
    { id: 'DTJ005', province: '天津', city: '天津', dealers: '天津瑞泰', adress: '', day: '5月7日-5月11日' },
    { id: 'DTJ006', province: '天津', city: '天津', dealers: '天津汽研', adress: '', day: '5月12日-5月16日' },
    { id: 'DTJ007', province: '天津', city: '天津', dealers: '天津轩泰', adress: '', day: '5月17日-5月21日' },
    { id: 'DTJ009', province: '天津', city: '天津', dealers: '天津长弘', adress: '', day: '5月22日-5月26日' },
    { id: 'DTJ010', province: '天津', city: '天津', dealers: '天津滨海', adress: '', day: '5月27日-5月31日' },
    { id: 'DTJ001', province: '天津', city: '天津', dealers: '天津凯通', adress: '', day: '5月31日' },
    { id: 'DBJ005', province: '北京', city: '北京', dealers: '北京华通', adress: '', day: '4月25日-5月5日' },
    { id: 'DBJ003', province: '北京', city: '北京', dealers: '北京日银', adress: '', day: '5月6日-5月12日' },
    { id: 'DBJ008', province: '北京', city: '北京', dealers: '北京万泉河', adress: '', day: '5月13日-5月19日' },
    { id: 'DBJ024', province: '北京', city: '北京', dealers: '北京通顺路', adress: '', day: '5月20日-5月26日' },
    { id: 'DBJ014', province: '北京', city: '北京', dealers: '北京通州店', adress: '', day: '5月27日-5月31日' },
    { id: 'DBJ002', province: '北京', city: '北京', dealers: '北京方庄', adress: '', day: '4月25日-5月5日' },
    { id: 'DBJ015', province: '北京', city: '北京', dealers: '北京长怡', adress: '', day: '5月6日-5月12日' },
    { id: 'DBJ004', province: '北京', city: '北京', dealers: '北京中汽', adress: '', day: '5月13日-5月19日' },
    { id: 'DBJ016', province: '北京', city: '北京', dealers: '北京石景山', adress: '', day: '5月20日-5月26日' },
    { id: 'DBJ022', province: '北京', city: '北京', dealers: '北京四季信通', adress: '', day: '5月27日-5月31日' },
    { id: 'DLN002', province: '辽宁', city: '沈阳', dealers: '沈阳宏大', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD006', province: '广东', city: '东莞', dealers: '东莞聚成', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD052', province: '广东', city: '东莞', dealers: '东莞莞樟', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD046', province: '广东', city: '东莞', dealers: '东莞恒信', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD001', province: '广东', city: '广州', dealers: '广本一店', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD022', province: '广东', city: '广州', dealers: '广州东圃', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD045', province: '广东', city: '广州', dealers: '广州汇骏', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD002', province: '广东', city: '佛山', dealers: '广东顺德', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD012', province: '广东', city: '佛山', dealers: '佛山宝兴', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD098', province: '广东', city: '佛山', dealers: '佛山行安', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD064', province: '广东', city: '深圳', dealers: '深圳安信', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD008', province: '广东', city: '江门', dealers: '江门冠华', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD004', province: '广东', city: '深圳', dealers: '深圳深业', adress: '', day: '5月1日-5月31日' },
    { id: 'DGX003', province: '广西壮族自治区', city: '南宁', dealers: '南宁恒安', adress: '', day: '5月1日-5月31日' },
    { id: 'DHAN04', province: '海南', city: '海口', dealers: '海口悦友', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD032', province: '广东', city: '中山', dealers: '中山菊城', adress: '', day: '5月1日-5月31日' },
    { id: 'DGD057', province: '广东', city: '深圳', dealers: '深圳誉成', adress: '', day: '5月1日-5月31日' }
  ] */
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(props.fields.load_dmp_point);
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
  };

  useIsomorphicLayoutEffect(() => {
    getLocation();
    getOriginalDealer();
    getPolicyVersion();
    initMap();
  }, []);

  useEffect(() => {
    if (originalDealerData) {
      setProvinceData(filterDealerData());
    }
  }, [originalDealerData, selectVehicleType]);

  useEffect(() => {
    if (provinceData && location) {
      const province = provinceData?.find((item) => location.province.includes(item.province_name));
      const city = province?.Citys?.find((item) => location.city.includes(item.city_name));
      setSelectProvince(province);
      setSelectCity(city);
    }
  }, [provinceData, location]);

  useEffect(() => {
    if (selectCity) {
      getRecentDealer(selectCity.Dealers);
    }
  }, [selectCity, selectProvince]);

  useEffect(() => {
    addMarker();
  }, [selectCity, mapLoaded]);

  const initMap = () => {
    const AMapLoader = require('@amap/amap-jsapi-loader');
    window._AMapsecuritvonfig = { securityJsCode: 'df2d1e69d62971d0a7ebb43C882b678a' };
    AMapLoader.load({
      key: '975d8e63e9648540ee56a840bbce9884',
      version: '2.0',
      plugins: ['AMap.Geolocation'],
      AMapUI: {
        version: '1.1',
        plugins: ['overlay/SimpleMarker']
      },
      Loca: {
        version: '2.0.0'
      }
    })
      .then((AMap) => {
        if (mapContainerRef.current) {
          mapRef.current = new AMap.Map('mapContainer', { zoom: 10, center: [113.264385, 23.129112] });
          setMapLoaded(true);
        }
        getCurrentCoordinates();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const addMarker = () => {
    if (mapLoaded) {
      mapRef?.current?.clearMap();
      selectCity?.Dealers?.forEach((item, index) => {
        const marker = new AMapUI.SimpleMarker({
          iconStyle: { src: createFilePath(`/sporthybrid-union/icon${index + 1}.png`) },
          position: [item?.longitude, item?.latitude],
          extData: { index }
        });
        mapRef?.current?.add(marker);
        mapRef?.current?.setFitView();
        /* $('#mapContainer')
          .off('click')
          .on('click', marker, (e) => {
            Dmp.clickAction('shop-map', selectCity?.Dealers[e?.target?.opts?.extData?.index]?.dealer_name);
            setSelectDealer(selectCity?.Dealers[e?.target?.opts?.extData?.index]);
          }); */
        // var clickbool = true;
        if (isMobile) {
          marker.on('touchstart', (e) => {
            Dmp.clickAction('shop-map', selectCity?.Dealers[e?.target?.opts?.extData?.index]?.dealer_name);
            setSelectDealer(selectCity?.Dealers[e?.target?.opts?.extData?.index]);
          });
        } else {
          marker.on('click', (e) => {
            Dmp.clickAction('shop-map', selectCity?.Dealers[e?.target?.opts?.extData?.index]?.dealer_name);
            setSelectDealer(selectCity?.Dealers[e?.target?.opts?.extData?.index]);
          });
        }
      });
    }
  };

  const getLocation = () => {
    getJsonp('https://restapi.amap.com/v3/ip?key=d4db88c544bf23a3a363f7ff8bcbf783&output=json')
      .then((response) => {
        if (response.status === '1') {
          if (response?.province?.length > 0 && response?.city?.length > 0) {
            setLocation({ province: response.province, city: response.city });
          }
        } else {
          return Promise.reject();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCurrentCoordinates = () => {
    // sessionStorage是否含有个人经纬度
    if (sessionStorage.getItem('ownAddressLat') && sessionStorage.getItem('ownAddressLng')) {
      setOwnAddress([sessionStorage.getItem('ownAddressLat'), sessionStorage.getItem('ownAddressLng')]);
    } else {
      AMap.plugin('AMap.Geolocation', () => {
        var geolocationLatLng = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认：true
          timeout: 5000 // 设置定位超时时间，默认：无穷大
        });
        geolocationLatLng.getCurrentPosition(function (status, result) {
          if (status === 'complete') {
            sessionStorage.setItem('ownAddressLat', result.position.lat);
            sessionStorage.setItem('ownAddressLng', result.position.lng);
            setOwnAddress([result.position.lat, result.position.lng]);
          } else {
            console.log('errorerrorerrorerror', result);
          }
        });
      });
    }
  };

  const getOriginalDealer = () => {
    dataFetcher('/api/honda/v1/Leads/GetAllDealerProCitys')
      .then(({ data }) => {
        if (data.Status) {
          setOriginalDealerData({ ...data?.Data });
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getPolicyVersion = () => {
    dataFetcher('/api/honda/v1/Policy/GetPolicyVersion')
      .then(({ data }) => {
        if (data.Status) {
          setPolicyVersion(data?.Data?.policy_code);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const filterDealerData = () => {
    const cine_Province = [];
    const cine_City = [];
    const cine_Shop = [];
    $.grep(globalist, function (item, index) {
      cine_Province.push(item.province);
    });
    $.grep(globalist, function (item, index) {
      cine_City.push(item.city);
    });
    $.grep(globalist, function (item, index) {
      cine_Shop.push(item.id);
    });
    const exhibit_province_filter = unique(cine_Province);
    const exhibit_city_filter = unique(cine_City);
    const exhibit_shop_filter = unique(cine_Shop);
    const originalData = JSON.parse(JSON.stringify(originalDealerData));
    const shopProvinces = originalData?.Provinces?.filter((item1) => exhibit_province_filter.indexOf(item1.province_name) !== -1);
    const shopCity = originalData?.Citys?.filter((item1) => exhibit_city_filter.indexOf(item1.city_name) !== -1);
    const shopDealer = originalData?.Dealers?.filter((item1) => exhibit_shop_filter.indexOf(item1.dealer_code) !== -1 && item1.dealer_level !== 2);
    originalData.Citys = shopCity;
    originalData.Provinces = shopProvinces;
    originalData.Dealers = shopDealer;
    if (!selectVehicleType) {
      return reduceDealerData(originalData);
    }
    const data = { Provinces: [], Citys: [], Dealers: [...originalData?.Dealers] };
    originalData?.Citys?.forEach((item) => {
      const dealers = getDealersData(item.city_id, originalData?.Dealers);
      if (dealers.length !== 0) {
        item.Dealers = dealers;
        data.Citys.push(item);
      }
    });
    originalData?.Provinces?.forEach((item) => {
      const citys = data?.Citys?.filter((item1) => item1.province_id === item.province_id);
      if (citys.length !== 0) {
        item.Citys = citys;
        data.Provinces.push(item);
      }
    });
    return data?.Provinces;
  };
  const reduceDealerData = (data) => {
    data?.Provinces?.forEach((item1) => {
      data?.Citys?.forEach((item2) => {
        item2.Dealers = data?.Dealers?.filter((item3) => item3.city_id === item2.city_id);
      });
      item1.Citys = data?.Citys?.filter((item2) => item2.province_id === item1.province_id);
    });
    return [...data?.Provinces];
  };

  const getDealersData = (cityId, data) => {
    const modelName = selectVehicleType?.modelName;
    const weight = selectVehicleType?.weight;
    let dealers = [];
    if (!isEmpty(weight)) {
      dealers = data.filter((item) => item.city_id === cityId && (weight & item.dealer_right) > 0);
      // if (modelName === 'enp1') {
      //   if (dealers.length === 0) {
      //     dealers = data.filter((item) => item.city_id === cityId && item.dealer_level === 1);
      //   } else {
      //     const exist = dealers.find((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     if (exist) {
      //       dealers = dealers.filter((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     }
      //   }
      // }
    }
    const newEnergy = dealers.filter((item) => item.dealer_right === 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    const gas = dealers.filter((item) => item.dealer_right !== 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    return [...newEnergy, ...gas];
  };
  const getRecentDealer = (dealers) => {
    setSelectDealer(dealers[0]);
    let ownLatLng = ownAddress;
    if (sessionStorage.getItem('ownAddressLat') && sessionStorage.getItem('ownAddressLng')) {
      ownLatLng = [Number(sessionStorage.getItem('ownAddressLat')), Number(sessionStorage.getItem('ownAddressLng'))];
    }
    if (ownLatLng[0] && ownLatLng[1]) {
      let distance = Number.MAX_VALUE;
      for (let i = 0; i < dealers.length; i++) {
        if (getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealers[i].latitude), Number(dealers[i].longitude)) < distance) {
          distance = getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealers[i].latitude), Number(dealers[i].longitude));
        }
      }
      for (let i = 0; i < dealers.length; i++) {
        if (getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealers[i].latitude), Number(dealers[i].longitude)) === distance) {
          setSelectDealer(dealers[i]);
        }
      }
    }
  };

  const shopProvinceChange = (e) => {
    Dmp.msgAction('shop-province', e.target.value);
    var city = provinceData?.find((item) => item.province_id === e.target.value);
    setSelectProvince(city);
    setSelectCity(city?.Citys[0]);
  };

  const shopCityChange = (e) => {
    Dmp.msgAction('shop-city', e.target.value);
    setSelectCity(selectProvince?.Citys?.find((item) => item.city_id === e.target.value));
  };
  return (
    <section className="shopModel" id={props?.params?.pagename} ref={containerRef}>
      <div className="exhibitCon">
        <div className="left">
          <div className="exhibitMap">
            <div id="mapContainer" ref={mapContainerRef}></div>
          </div>
        </div>
        <div className="right">
          <div className="title">特约店巡展</div>
          <div className="selectLayer">
            {/* <select className="selectItem fontSize18" id="shop-province" data-item1="shop-province">
              <option value="">请选择省份</option>
            </select>
            <select className="selectItem fontSize18" id="shop-city" data-item1="shop-city">
              <option value="">请选择城市</option>
            </select> */}
            <div className="shopSelect">
              <span className="name">省份*</span>
              <select
                className="drive-info-select"
                style={{ backgroundImage: `url(${createFilePath('/enp2/v1/pc/downIcon.png')})` }}
                value={selectProvince?.province_id || 0}
                onChange={shopProvinceChange}
              >
                <option value={0}>请选择省份</option>
                {provinceData?.map((item, index) => (
                  <option key={index} value={item?.province_id}>
                    {item?.province_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="shopSelect">
              <span className="name">城市*</span>
              <select
                className="drive-info-select"
                style={{ backgroundImage: `url(${createFilePath('/enp2/v1/pc/downIcon.png')})` }}
                value={selectCity?.city_id || 0}
                onChange={shopCityChange}
              >
                <option value={0}>请选择城市</option>
                {selectProvince?.Citys?.map((item, index) => (
                  <option key={index} value={item?.city_id}>
                    {item?.city_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="shoptable">
            <div className="tableLayer">
              <div className="tabledata" id="tableData">
                {selectCity?.Dealers?.map((item, index) => (
                  <div className="shopLine" key={index}>
                    <div className="num">
                      <span>{index + 1}</span>
                    </div>
                    <div className="time">
                      {globalist?.map((item1, index1) =>
                        item1?.id === item?.dealer_code ? (
                          <p className="dayTxt" key={index1}>
                            {item1?.day}
                          </p>
                        ) : (
                          ''
                        )
                      )}
                    </div>
                    <div className="name">{item?.dealer_name}</div>
                    <div className="address">{item?.address}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
