import React, { useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import './style.scss';
import classNames from 'classnames';
import Swiper from 'swiper';
import { gridWidth } from '../../../utils/site-utils';
import { isMobileFun, createFilePath, GotoMall } from '../../../utils/obj-utils';

const DesktopMenu = ({ activeIndex, onClose, submenuData }) => {
  const isMobile = isMobileFun();

  const swiperEl = useRef(null);
  const swiperMirrorEl = useRef(null);
  const swiper = useRef(null);
  const swiperMirror = useRef(null);
  const menus = useMemo(() => submenuData[activeIndex] ?? [], [activeIndex]);
  const menusMirror = useMemo(() => {
    const _mirror = [...menus];
    _mirror.reverse();
    return _mirror;
  }, [menus]);
  const [activeSubIndex, setActiveSubIndex] = useState(0);
  const methods = {
    onMenuEnter(index) {
      setActiveSubIndex(index);
      swiper.current.slideTo(index, swiper.current.params.speed);
      swiperMirror.current.slideTo(menus.length - index - 1, swiper.current.params.speed);
    }
  };
  const list = [
    [],
    [],
    ['catalog-buy-btn6', 'catalog-buy-mall', 'catalog-buy-show', 'catalog-buy-btn7', 'catalog-buy-btn8', 'catalog-buy-btn10', 'catalog-buy-btn9'],
    [
      'catalog-customer-btn1',
      'catalog-customer-btn2',
      'catalog-customer-manual',
      'catalog-customer-btn9',
      'catalog-customer-btn6',
      'catalog-customer-btn7',
      'catalog-customer-btn11',
      'catalog-customer-btn3',
      'catalog-customer-btn10',
      'catalog-customer-btn15',
      'catalog-customer-btn16'
    ],
    [
      'catalog-make-news',
      'catalog-make-activity',
      'catalog-make-live',
      'catalog-make-center',
      'catalog-make-tech',
      'catalog-make-value',
      'catalog-make-child'
    ],
    ['catalog-ghac-btn1', 'catalog-ghac-responsibility', 'catalog-ghac-visitor', 'catalog-ghac-btn7', 'catalog-ghac-club']
  ];
  useEffect(() => {
    setActiveSubIndex(0);
    if (swiper.current) {
      swiper.current.destroy(true, true);
    }
    if (swiperMirror.current) {
      swiperMirror.current.destroy(true, true);
    }
    swiper.current = new Swiper(swiperEl.current, {
      direction: 'vertical',
      slidesPerView: 'auto',
      observer: true,
      observeSlideChildren: true,
      loop: true,
      initialSlide: 0,
      centeredSlides: true,
      spaceBetween: gridWidth(1.3),
      allowTouchMove: false
    });
    swiperMirror.current = new Swiper(swiperMirrorEl.current, {
      direction: 'vertical',
      slidesPerView: 'auto',
      observer: true,
      observeSlideChildren: true,
      loop: true,
      initialSlide: 0,
      centeredSlides: true,
      spaceBetween: gridWidth(1.3),
      allowTouchMove: false
    });
  }, [activeIndex]);
  return (
    <div className="s-desktop-menu">
      <img
        alt="广汽本田"
        className="s-desktop-menu__close"
        // src={`${temp.app.deployUrlS3}/breeze/v1/pc/other/VectorClose.png`}
        src={createFilePath('/breeze/v1/pc/other/VectorClose.png')}
        onClick={(e) => onClose && onClose()}
      />
      <div className="s-desktop-menu__list">
        {menus.map((m, i) => (
          <a
            // data-action="clickAction"
            // data-item={`${list[activeIndex][i]}`}
            // data-url={isMobile ? m.link : m.pc_link}
            className={classNames('s-desktop-menu__item', { active: activeSubIndex === i })}
            key={i}
            onMouseEnter={(e) => methods.onMenuEnter(i)}
            // href={isMobile ? m.link : m.pc_link}
            // target={m.target ? '_blank ' : ''}
            rel={m.nofollow ? 'nofollow' : ''}
            onClick={(e) => {
              if (m.title === '广汽本田商城') {
                GotoMall(isMobile ? m.link : m.pc_link, list[activeIndex][i], isMobile);
              } else {
                if (m.target) {
                  window.open(m.pc_link);
                } else {
                  location.href = m.pc_link;
                }
                window.Dmp.clickAction(list[activeIndex][i], m.pc_link);
              }
            }}
          >
            {m.title}
          </a>
        ))}
      </div>
      <div className="s-desktop-menu__swiper">
        <div className="swiper" ref={swiperEl}>
          <div className="swiper-wrapper">
            {menus.map((m, i) => (
              <div className="swiper-slide" key={i}>
                <div className="s-desktop-menu__image" style={{ backgroundImage: `url(${createFilePath(m.img)})` }} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="s-desktop-menu__swiper-mirror">
        <div className="swiper" ref={swiperMirrorEl}>
          <div className="swiper-wrapper">
            {menusMirror.map((m, i) => (
              <div className="swiper-slide" key={i}>
                <div className="s-desktop-menu__image" style={{ backgroundImage: `url(${createFilePath(m.img)})` }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopMenu;
