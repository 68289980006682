import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import gsap from 'gsap';
import './style.scss';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.min.css';
import Icon from '../../elements/icon';
import { getPrefixField, sendDmp, convertStylesStringToObject, isInView, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { fields, rootEl, isMobile, setNum, num } = props;
  const [paused, setPaused] = useState(true);
  const once = useRef(true);
  useEffect(() => {
    const scroll = () => {
      if (rootEl.current) {
        setPaused(!isInView(rootEl));
      }
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  useEffect(() => {
    if (paused || (isMobile && !once?.current)) {
      return;
    }
    const timeline = gsap.timeline({
      duration: 1.5,
      onComplete: () => {
        timeline.kill();
      }
    });
    if (isMobile) {
      // timeline.fromTo(rootEl?.current, { x: '-100%' }, { x: 0 }, '<');
    } else {
      timeline.fromTo(rootEl?.current, { x: '-100%' }, { x: 0 }, '<');
    }
    return () => {
      timeline.kill();
    };
  }, [paused]);
  return (
    <div style={convertStylesStringToObject(fields[`${getPrefixField('container_style', isMobile)}`]?.value)}>
      <div className="ParameterConfiguration1" ref={rootEl}>
        {fields[`${getPrefixField('version_list', isMobile)}`].length &&
          fields[`${getPrefixField('version_list', isMobile)}`].map((ele, index) => {
            return (
              index === num && (
                <React.Fragment key={index}>
                  <Swiper
                    key={index}
                    className={'swipercon'}
                    slidesPerView={1}
                    modules={[Navigation]}
                    navigation={{
                      prevEl: '.ParameterConfiguration1 .navigation-prev',
                      nextEl: '.ParameterConfiguration1 .navigation-next',
                      disabledClass: 'disabled'
                    }}
                  >
                    {ele.fields.car_list.map((item, i) => {
                      return (
                        <SwiperSlide key={i} style={convertStylesStringToObject(item.fields.silder_container_style?.value)}>
                          <div className="carItem" style={convertStylesStringToObject(item.fields.silder_content_container_style?.value)}>
                            <div className="carleft">
                              <div className="title" dangerouslySetInnerHTML={{ __html: item.fields.title?.value?.replaceHtml() }}></div>
                              <img
                                alt="广汽本田"
                                className={'car'}
                                src={`${createFilePath(item.fields.image.value)}`}
                                style={convertStylesStringToObject(item.fields.back_img_style?.value)}
                              />
                              <div className="con" style={convertStylesStringToObject(item.fields.detail_style?.value)}>
                                <div className="info">
                                  <div className="item">
                                    <div className="name" dangerouslySetInnerHTML={{ __html: item.fields.price_text?.value?.replaceHtml() }}></div>
                                    <div
                                      className="remark"
                                      dangerouslySetInnerHTML={{ __html: item.fields.price_remark?.value?.replaceHtml() }}
                                    ></div>
                                  </div>
                                  <div className="item">
                                    <div className="name" dangerouslySetInnerHTML={{ __html: item.fields.oil_cost_text?.value?.replaceHtml() }}></div>
                                    <div
                                      className="remark"
                                      dangerouslySetInnerHTML={{ __html: item.fields.oil_cost_remark?.value?.replaceHtml() }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="lists">
                                  <div className="in">
                                    {item.fields.detail_list.map((element, ii) => {
                                      return (
                                        <div
                                          className="item"
                                          key={ii}
                                          style={convertStylesStringToObject(item.fields.contrast_bottoms_content_style?.value)}
                                        >
                                          <img
                                            alt="广汽本田"
                                            className="icon"
                                            style={convertStylesStringToObject(item.fields.contrast_bottoms_content_img_style?.value)}
                                            src={`${createFilePath(element.fields.icon.value)}`}
                                          />
                                          <div className="name" dangerouslySetInnerHTML={{ __html: element.fields.text.value?.replaceHtml() }}></div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {item.fields.packet && item.fields.packet.length > 0 && (
                              <div className="packet">
                                {item.fields.packet.map((item2, i2) => {
                                  const [packethide, setPackethide] = useState(true);
                                  return (
                                    <div className="packetitem" key={i2}>
                                      <div
                                        className={`title ${isMobile ? (packethide ? 'titlehide' : '') : ''}`}
                                        dangerouslySetInnerHTML={{ __html: item2.fields.title?.value?.replaceHtml() }}
                                        onClick={() => {
                                          isMobile && setPackethide(!packethide);
                                          isMobile && window.Dmp.decideAction(i2 === 0 ? 'detail-music' : 'detail-tech', packethide);
                                        }}
                                      ></div>
                                      <div className={`con ${isMobile ? (packethide ? 'packethide' : '') : ''}`}>
                                        <div className="img">
                                          <img alt="广汽本田" src={`${createFilePath(item2.fields.img?.value)}`} />
                                        </div>
                                        <div className="list">
                                          <div className="in">
                                            {item2.fields.lists.value &&
                                              JSON.parse(item2.fields.lists.value).length > 0 &&
                                              JSON.parse(item2.fields.lists.value).map((item3, i3) => {
                                                return (
                                                  <div
                                                    className="item"
                                                    key={i3}
                                                    dangerouslySetInnerHTML={{ __html: item3.title.replaceHtml() }}
                                                  ></div>
                                                );
                                              })}
                                          </div>
                                          {item2.fields.lists.value && JSON.parse(item2.fields.lists.value).length > 6 && !isMobile && (
                                            <div className="more"></div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                  <div className="swiper-prev navigation-prev" data-html-item={ele?.fields?.slide_prev_dmp?.value}>
                    <Icon name="ArrowLeft" />
                  </div>
                  <div className="swiper-next navigation-next" data-html-item={ele?.fields?.slide_next_dmp?.value}>
                    <Icon name="ArrowRight" />
                  </div>
                </React.Fragment>
              )
            );
          })}
      </div>
    </div>
  );
}
