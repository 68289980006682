/* eslint-disable no-bitwise */
import React, { useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import './style.scss';
import { createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields }) {
  const isMobile = isMobileFun();
  const menuData = useRef(null);

  if (!menuData?.current) {
    menuData.current = JSON.parse(fields?.menuData?.value?.replaceHtml());
  }

  return (
    <section className="environment-v1">
      <img
        alt="广汽本田"
        className="environment-image"
        src={createFilePath(`/service/environment/${isMobile ? 'all-mobileKv.jpg' : 'all-kv.jpg'}`)}
      />
      <ul className="menu-list">
        {menuData?.current?.map((item, index) => (
          <li
            key={index}
            className={`menu-item${item?.isConstruction ? ' disabled' : ''}`}
            style={{ backgroundImage: `url(${createFilePath('/service/environment/all-listBg.jpg')})` }}
          >
            <Link to={item?.link}>
              <img className="menu-item-image" src={createFilePath(item?.image)} alt={item?.title} />
              <span
                className="menu-item-title"
                dangerouslySetInnerHTML={{ __html: `${item?.title}${item?.isConstruction ? '<span>建设中</span>' : ''}` }}
              />
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
}
