import React, { useEffect, useState, useRef, createRef, useImperativeHandle } from 'react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import temp from '../../temp/config';
import Details from '../../elements/InnerPageDetail';
import { getPrefixField, convertStylesStringToObject, sendDmp, scrollFinish, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getQueryVariable } from '../../utils/site-utils';

export default function index({ fields, eventRef }) {
  const containerRef = useRef(null);
  // const deployUrlS3 = temp.app.deployUrlS3;
  const [flag, setFlag] = useState(false);
  const isMobile = isMobileFun();
  const split_line = fields.split_line?.value;
  const tabs = fields.tabs;
  const container_style = fields[getPrefixField('container_style', isMobile)]?.value;
  const back_element = fields[getPrefixField('back_element', isMobile)]?.value;
  const defaultTab = fields.default_tab_name?.value;
  const [data, _] = useState({ isMobile, split_line, tabs, container_style, back_element, defaultTab });
  const [tabNum, setTabNum] = useState(0);
  const replaceContainerRefList = data.tabs[tabNum].fields[`${getPrefixField('content_list')}`].map(() => createRef());

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields.window_dmp);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = 'price_exterior';
  };

  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      data.tabs.map((item, index) => {
        let tabArr = item.fields?.tab_name?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(data.defaultTab.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabNum(tabInd < 0 ? (defaultTabInd < 0 ? tabNum : defaultTabInd) : tabInd);
      } else {
        setTabNum(defaultTabInd < 0 ? tabNum : defaultTabInd);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const replaceTimeline = gsap.timeline({ paused: true });
    data.tabs[tabNum].fields[`${getPrefixField('content_list')}`].forEach((_, index) => {
      data.tabs[tabNum].fields[`${getPrefixField('content_list')}`].length - 1 - index > 0 &&
        replaceTimeline.fromTo(
          replaceContainerRefList[data.tabs[tabNum].fields[`${getPrefixField('content_list')}`].length - 1 - index].current,
          { height: isMobile ? '40vh' : '58vh' },
          { height: 0 }
        );
    });
    const replaceController = new ScrollMagic.Controller();
    const replaceScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onLeave',
      duration: document.documentElement.clientHeight
    })
      .on('progress', function (event) {
        replaceTimeline.progress(event.progress);
      })
      .setPin(containerRef.current)
      .setTween(replaceTimeline)
      .addTo(replaceController);

    return () => {
      replaceTimeline.kill();
      replaceScene.remove();
      replaceScene.destroy();
      replaceController.destroy();
    };
  }, [tabNum]);

  const screenShow = () => {
    let screen = document.querySelector('.priceExterior-content');
    if (screen) {
      screen.classList.remove('exterior-content-show');
      void screen.offsetWidth;
      screen.classList.add('exterior-content-show');
    }
  };

  return (
    <section>
      <div className="priceExterior-v3" id="price_exterior" ref={containerRef} style={convertStylesStringToObject(data.container_style)}>
        <div className="tab_list">
          {data.tabs &&
            data.tabs.length > 1 &&
            data.tabs.map((item, index) => {
              return (
                <>
                  {index !== 0 && <div className="split_line" dangerouslySetInnerHTML={{ __html: data.split_line?.replaceHtml() }} />}
                  <div
                    key={index}
                    className={`tab_item ${index === tabNum ? 'tab_disabled' : ''}`}
                    onClick={() => {
                      setTabNum(index);
                      sendDmp(item?.fields?.tab_dmp);
                      window.Dmp.trackEnable = false;

                      setTimeout(() => {
                        containerRef.current.scrollIntoView();
                        setTimeout(() => {
                          window.Dmp.trackEnable = true;
                        }, 100);
                      }, 0);
                    }}
                  >
                    <div
                      className={index === tabNum ? 'tabContainer_active' : 'tabContainer'}
                      dangerouslySetInnerHTML={{ __html: item.fields?.tab?.value?.replaceHtml() }}
                    />
                  </div>
                </>
              );
            })}
        </div>
        <div className="priceExterior-content">
          {data.tabs[tabNum].fields[`${getPrefixField('content_list', isMobile)}`] &&
            data.tabs[tabNum].fields[`${getPrefixField('content_list', isMobile)}`].map((ele, index) => {
              return (
                <div className="sourceContainer" key={index} ref={replaceContainerRefList[index]}>
                  <div className="sourceFixContainer">
                    <div className="infoContainer" dangerouslySetInnerHTML={{ __html: ele.fields.back_info?.value?.replaceHtml() }} />
                    <div className="imgContainer">
                      <div style={convertStylesStringToObject(ele.fields.img_container_div_style?.value)}>
                        <img alt="广汽本田" src={`${createFilePath(ele.fields.img?.value)}`}></img>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <div
            className="contentContainer"
            dangerouslySetInnerHTML={{ __html: data.tabs[tabNum].fields[`${getPrefixField('content', isMobile)}`]?.value?.replaceHtml() }}
          />
          <div className="buttonContainer">
            <div
              onClick={() => {
                sendDmp(data.tabs[tabNum].fields.detail_btn_dmp);
                setFlag(true);
              }}
              dangerouslySetInnerHTML={{ __html: data.tabs[tabNum].fields[`${getPrefixField('detail_btn', isMobile)}`]?.value?.replaceHtml() }}
            />
          </div>
          <div className="backContainer" dangerouslySetInnerHTML={{ __html: data.back_element.replaceHtml() }} />
        </div>
        {flag && <Details routePath={data.tabs[tabNum].fields.detail_btn_href?.value} close={() => setFlag(false)} />}
      </div>
    </section>
  );
}
