import React, { useEffect, useState } from 'react';
import './style.scss';
import { getPrefixField } from '../../utils/obj-utils';

export default function index({ fields }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const title = fields[getPrefixField('title')]?.value;
    const subTitle = fields[getPrefixField('subTitle')]?.value;
    setData({ title, subTitle });
  }, []);

  return (
    <section>
      {data ? (
        <div className="title-section">
          {data?.title && <h3 className="title" dangerouslySetInnerHTML={{ __html: data?.title?.replaceHtml() }} />}
          {data?.subTitle && <h2 className="sub-title" dangerouslySetInnerHTML={{ __html: data?.subTitle?.replaceHtml() }} />}
        </div>
      ) : null}
    </section>
  );
}
