/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Script from 'react-load-script';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';
import { getPrefixField, convertStylesStringToObject, getQueryString, createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields }) {
  const history = useHistory();
  const isMobile = isMobileFun();
  const style = fields && convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const titleStyle = fields && convertStylesStringToObject(fields[getPrefixField('titleStyle', isMobile)]?.value);
  const [data, setData] = useState(null);
  const [scriptLoad, setScriptLoad] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data && scriptLoad) {
      loadShare();
    }
  }, [data, scriptLoad]);

  useEffect(() => {
    if (data) {
      document.title = data.a_title;
      document.querySelector('meta[name="keywords"]').setAttribute('content', document.title);
      document.querySelector('meta[name="description"]').setAttribute('content', document.title);
    }
  }, [data]);

  const getData = () => {
    dataFetcher(`/api/honda/v1/Article/GetArticleDetails?itempath=${getQueryString('itempath') || ''}&articleType=${fields?.a_type?.value || ''}`)
      .then(({ data: response }) => {
        if (response.Status && response?.Data) {
          setData({ ...response?.Data });
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
        history.replace('/no-find');
      });
  };

  const loadShare = () => {
    let shareId = '';
    let shareText = '';
    $(document).on('mouseover', '.bdsharebuttonbox', function () {
      shareId = window.location.href;
      shareText = data.a_title;
      if ($(window).width() - $(this).offset().left < 230) {
        setTimeout(function () {
          const w = $('.bdshare_popup_box').css('width');
          const l = $('.bdshare_popup_box').css('left');
          if ($('.bdshare_popup_box').length > 0) {
            if ($('.bdshare_popup_box').offset().left + $('.bdshare_popup_box').width() > $(window).width()) {
              $('.bdshare_popup_box').css('left', parseInt(l) - parseInt(w) + 'px');
            }
          }
        }, 1);
      }
    });
    $(document).on('mousemove', '.bdsharebuttonbox', function () {
      if ($(window).width() - $(this).offset().left < 230) {
        setTimeout(function () {
          const w = $('.bdshare_popup_box').css('width');
          const l = $('.bdshare_popup_box').css('left');
          if ($('.bdshare_popup_box').length > 0) {
            if ($('.bdshare_popup_box').offset().left + $('.bdshare_popup_box').width() > $(window).width()) {
              $('.bdshare_popup_box').css('left', parseInt(l) - parseInt(w) + 'px');
            }
          }
        }, 1);
      }
    });
    function setShareUrl(cmd, config) {
      if (shareId) {
        config.bdUrl = shareId;
        config.bdText = shareText;
      }
      return config;
    }
    window._bd_share_config = {
      common: {
        onBeforeClick: setShareUrl,
        bdSnsKey: {},
        bdText: '',
        bdMini: '2',
        bdMiniList: false,
        bdPic: '',
        bdStyle: '0',
        bdSize: '24',
        bdPopupOffsetTop: -1500
      },
      share: {}
    };
    $('.bdshare_popup_bg').remove();
    $('.bdshare_popup_box').remove();
    if (window._bd_share_main) delete window._bd_share_main;
    (document.getElementsByTagName('head')[0] || body).appendChild(document.createElement('script')).src = createFilePath(
      `/common/lib/share/static/api/js/share.js?cdnversion=${~(-new Date() / 36e5)}`
    );
  };

  const likeClick = () => {
    dataFetcher(`/api/honda/v1/Article/AddArticleLike?itemId=${data?.item_id}&articleType=${data?.a_type}`, {})
      .then(({ data: response }) => {
        if (!response.Status) {
          if (parseInt(response.ErrorResult.ErrCode) === 3) {
            alert(response.ErrorResult.ErrMsg);
          }
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        } else {
          data.article_like_total = ++data.article_like_total;
          setData({ ...data });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <section className="news-detail-v1" style={style}>
      <Script url={createFilePath('/common/lib/common/js/jquery.min.js')} onLoad={() => setScriptLoad(true)} />
      {data && (
        <>
          <h1 className="title" style={titleStyle} dangerouslySetInnerHTML={{ __html: data.a_title }} />
          <div className="toolbar-container">
            <p className="publish-date">发布时间：{moment(data.a_publish_date).format('YYYY-MM-DD')}</p>
            <div className="share-container">
              <div className="like-container" onClick={likeClick}>
                <div className="icon" style={{ background: `url(${createFilePath('/common/icon/spriteStaticIcon.gif')}) no-repeat` }} />
                <div className="count">({data.article_like_total})</div>
              </div>
              |
              <span className="bdsharebuttonbox share-button-box">
                <a
                  className="bds_more share-more"
                  data-cmd="more"
                  style={{ background: `url(${createFilePath('/common/icon/spriteStaticIcon.gif')}) no-repeat` }}
                />
              </span>
            </div>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: (isMobile ? data.a_content_mb || data.a_content : data.a_content)?.replaceHtml() }}
          />
        </>
      )}
    </section>
  );
}
