import React, { useEffect, useRef, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import { getPrefixField, createFilePath, sendDmp, isMobileFun, convertStylesStringToObject } from '../../utils/obj-utils';

export default function index({ fields, scrollElement }) {
  const containerTopRef = useRef(null);
  const containerBottomRef = useRef(null);
  const imageTopRef = useRef(null);
  const imageBottomRef = useRef(null);
  const textTopRef = useRef(null);
  const textBottomRef = useRef(null);
  const isMobile = isMobileFun();
  const topTitle = fields[getPrefixField('title1', isMobile)]?.value;
  const topDescription = fields[getPrefixField('description1', isMobile)]?.value;
  const topImage = createFilePath(fields[getPrefixField('image1', isMobile)]?.value);
  const bottomTitle = fields[getPrefixField('title2', isMobile)]?.value;
  const bottomDescription = fields[getPrefixField('description2', isMobile)]?.value;
  const bottomImage = createFilePath(fields[getPrefixField('image2', isMobile)]?.value);
  const reverse = fields[getPrefixField('reverse', isMobile)]?.value;
  const sameDirection = fields.m_sameDirection?.value;
  const disableAnimate = fields[getPrefixField('disableAnimate', isMobile)]?.value;
  const btn1 = fields[getPrefixField('btn1', isMobile)]?.value;
  const btn1Url = fields.btn1Url?.value;
  const btn2 = fields[getPrefixField('btn2', isMobile)]?.value;
  const btn2Url = fields.btn2Url?.value;
  const style = fields[getPrefixField('style', isMobile)]?.value;
  const [data, _] = useState({
    topTitle,
    topDescription,
    topImage,
    bottomTitle,
    bottomDescription,
    bottomImage,
    reverse,
    sameDirection,
    isMobile,
    disableAnimate,
    btn1,
    btn1Url,
    btn2,
    btn2Url,
    style
  });
  useEffect(() => {
    if (data?.disableAnimate) {
      return;
    }

    const topTimeline = gsap.timeline({ paused: true });
    if (!data?.isMobile) {
      topTimeline
        .fromTo(imageTopRef.current, { x: data?.reverse ? '-50%' : '50%' }, { x: '0' })
        .fromTo(textTopRef.current, { x: data?.reverse ? '50%' : '-50%' }, { x: '0' }, '<');
    } else {
      topTimeline
        .fromTo(imageTopRef.current, { x: '-100vw' }, { x: '0' })
        .fromTo(textTopRef.current, { x: `${data?.sameDirection ? '-' : ''}100vw` }, { x: '0' }, data?.sameDirection ? '<' : '<70%');
    }
    const controller = new ScrollMagic.Controller({ container: scrollElement || window });
    const topScene = new ScrollMagic.Scene({
      triggerElement: containerTopRef.current,
      triggerHook: 'onEnter',
      duration: '100%'
    })
      .setTween(topTimeline)
      .addTo(controller);

    const bottomTimeline = gsap.timeline({ paused: true });
    if (!data?.isMobile) {
      bottomTimeline
        .fromTo(imageBottomRef.current, { x: data?.reverse ? '50%' : '-50%' }, { x: '0' })
        .fromTo(textBottomRef.current, { x: data?.reverse ? '-50%' : '50%' }, { x: '0' }, '<');
    } else {
      bottomTimeline
        .fromTo(imageBottomRef.current, { x: '100vw' }, { x: '0' })
        .fromTo(textBottomRef.current, { x: `${data?.sameDirection ? '' : '-'}100vw` }, { x: '0' }, data?.sameDirection ? '<' : '<70%');
    }
    const bottomScene = new ScrollMagic.Scene({
      triggerElement: containerBottomRef.current,
      triggerHook: 'onEnter',
      duration: '100%'
    })
      .setTween(bottomTimeline)
      .addTo(controller);

    return () => {
      topTimeline.kill();
      topScene.remove();
      topScene.destroy();

      bottomTimeline.kill();
      bottomScene.remove();
      bottomScene.destroy();

      controller.destroy();
    };
  }, []);

  const getTopContainer = () => {
    return (
      <div className={`content-container content-container-top ${data?.reverse ? 'content-container-top-reverse' : ''}`} ref={containerTopRef}>
        {!data?.isMobile && data?.reverse ? (
          <React.Fragment>
            {getTopTextContainer()}
            {getTopImageContainer()}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getTopImageContainer()}
            {getTopTextContainer()}
          </React.Fragment>
        )}
      </div>
    );
  };

  const getTopTextContainer = () => {
    return (
      <div className="text-container" ref={textTopRef}>
        <div className="title" dangerouslySetInnerHTML={{ __html: data?.topTitle?.replaceHtml() }} />
        <div className="description" dangerouslySetInnerHTML={{ __html: data?.topDescription?.replaceHtml() }} />
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: data?.btn1?.replaceHtml() }}
            onClick={() => {
              sendDmp(fields.btn1Dmp, data.btn1Url);
              window.open(data?.btn1Url);
            }}
          ></div>
        </div>
      </div>
    );
  };

  const getTopImageContainer = () => {
    return <img alt="广汽本田" className="image" src={data?.topImage} ref={imageTopRef} />;
  };

  const getBottomContainer = () => {
    return (
      <div
        className={`content-container content-container-bottom ${data?.reverse ? 'content-container-bottom-reverse' : ''}`}
        ref={containerBottomRef}
      >
        {data?.isMobile || (!data?.isMobile && data?.reverse) ? (
          <React.Fragment>
            {getBottomImageContainer()}
            {getBottomTextContainer()}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getBottomTextContainer()}
            {getBottomImageContainer()}
          </React.Fragment>
        )}
      </div>
    );
  };

  const getBottomTextContainer = () => {
    return (
      <div className="text-container" ref={textBottomRef}>
        <div className="title" dangerouslySetInnerHTML={{ __html: data?.bottomTitle?.replaceHtml() }} />
        <div className="description" dangerouslySetInnerHTML={{ __html: data?.bottomDescription?.replaceHtml() }} />
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: btn2?.replaceHtml() }}
            onClick={() => {
              sendDmp(fields.btn2Dmp, data.btn2Url);
              window.open(data?.btn2Url);
            }}
          ></div>
        </div>
      </div>
    );
  };

  const getBottomImageContainer = () => {
    return <img alt="广汽本田" className="image" src={data?.bottomImage} ref={imageBottomRef} />;
  };

  return (
    <section>
      {data ? (
        <div className={`text-multi-image ${data?.reverse ? 'text-multi-image-reverse' : ''}`} style={convertStylesStringToObject(data.style)}>
          {data.topTitle && getTopContainer()}
          {data.bottomTitle && getBottomContainer()}
        </div>
      ) : null}
    </section>
  );
}
