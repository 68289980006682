import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import './style.scss';
import { isVideo, createFilePath } from '../../utils/obj-utils';
import temp from '../../temp/config';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import LazyLoad from 'react-lazyload';
export default function index(props) {
  // const holderImg = <img alt="广汽本田" src={temp.app.deployUrlS3 + '/common/placeholder.jpg'} />;
  const holderImg = <img alt="广汽本田" src={createFilePath('/common/placeholder.jpg')} />;
  const [thisMobile, setThisMobile] = useState(false);
  useEffect(() => {
    setThisMobile(document.documentElement.clientWidth > 1024);
    const resizeEvent = () => {
      setThisMobile(document.documentElement.clientWidth > 1024);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    window.Dmp.sectionAction(props.fields.pageDmp[0]?.displayName);
  };

  const textContainerRef = useRef(null);
  const imageContainerRef = useRef(null);
  useEffect(() => {
    const controller = new ScrollMagic.Controller();
    const imageTimeline = gsap.timeline({ paused: true }).fromTo(imageContainerRef.current, { scale: 0.8 }, { scale: 1 });
    const imageScene = new ScrollMagic.Scene({
      triggerElement: imageContainerRef.current,
      triggerHook: 'onEnter',
      duration: thisMobile ? '0%' : '60%'
    })
      .on('progress', function (event) {
        imageTimeline.progress(event.progress);
      })
      .setTween(imageTimeline)
      .addTo(controller);

    return () => {
      imageTimeline.kill();
      imageScene.remove();
      imageScene.destroy();
    };
  }, [imageContainerRef]);
  return (
    <LazyLoad placeholder={holderImg}>
      <div className="HybridAlliance">
        <div className="HybridAllianceImg" ref={imageContainerRef}>
          {thisMobile ? (
            <>
              {isVideo(props.fields.pc_img.value) ? (
                <video autoPlay="autoPlay" muted="muted" loop="loop" src={createFilePath(props.fields.pc_img.value)}></video>
              ) : (
                <img src={createFilePath(props.fields.pc_img.value)} alt="锐·混动联盟" />
              )}
            </>
          ) : (
            <img alt="锐·混动联盟" src={createFilePath(props.fields.m_img.value)} />
          )}
        </div>
        <div className="HybridAllianceHeader" ref={textContainerRef}>
          <div dangerouslySetInnerHTML={{ __html: (thisMobile ? props.fields.pc_text.value : props.fields.m_text.value)?.replaceHtml() }}></div>
          <div className="control">
            <div
              className="cbtn"
              dangerouslySetInnerHTML={{ __html: (thisMobile ? props.fields.pc_btn2.value : props.fields.m_btn2.value)?.replaceHtml() }}
              onClick={() => {
                window.Dmp.clickAction(
                  props.fields.btnDmp2[0]?.displayName,
                  thisMobile ? props.fields.pc_btnLink2?.value : props.fields.btnLink2?.value
                );
                window.open(thisMobile ? props.fields.pc_btnLink2?.value : props.fields.btnLink2?.value, props.fields.btn2_target.fields.v.value);
              }}
            ></div>
            <div
              className="cbtn"
              dangerouslySetInnerHTML={{ __html: (thisMobile ? props.fields.pc_btn.value : props.fields.m_btn.value)?.replaceHtml() }}
              onClick={() => {
                window.Dmp.clickAction(props.fields.btnDmp[0]?.displayName, props.fields.btnLink?.value);
                window.open(props.fields.btnLink.value, props.fields.btn1_target.fields.v.value);
              }}
            ></div>
          </div>
        </div>
      </div>
    </LazyLoad>
  );
}
