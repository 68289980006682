import React, { useEffect, useRef, useImperativeHandle } from 'react';
import './style.scss';
import temp from '../../temp/config';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { openHome } from '../../utils/api';
import LazyLoad from 'react-lazyload';
export default function index({ fields, eventRef }) {
  const holderImg = <img alt="广汽本田" src={createFilePath('/common/placeholder.jpg')} />;
  const containerRef = useRef(null);
  const isMobile = isMobileFun();

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields.window_dmp);
  };

  return (
    <section>
      <React.Fragment>
        <LazyLoad placeholder={holderImg}>
          <div
            className="one-on-one-v3"
            style={convertStylesStringToObject(fields[`${getPrefixField('container_style')}`]?.value)}
            id="oneservice"
            ref={containerRef}
          >
            <div className="background-container">
              <img src={createFilePath(fields[`${getPrefixField('back_img', isMobile)}`]?.value)} alt="随时随地3D互动" />
            </div>
            <div className="buttonContainer">
              <div
                onClick={() => {
                  sendDmp(fields[`${getPrefixField('check_car_dmp', isMobile)}`], fields[`${getPrefixField('check_car_href', isMobile)}`]?.value);
                  window.open(fields[`${getPrefixField('check_car_href', isMobile)}`]?.value);
                }}
                dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('check_car_btn', isMobile)}`]?.value?.replaceHtml() }}
              />
              {isMobile && (
                <>
                  <div
                    onClick={() => {
                      openHome((link) => {
                        sendDmp(fields[`${getPrefixField('talk_car_dmp', isMobile)}`], link);
                      });
                    }}
                    dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('talk_car_btn', isMobile)}`]?.value?.replaceHtml() }}
                  />
                  <div dangerouslySetInnerHTML={{ __html: fields.m_bottomText?.value?.replaceHtml() }} />
                </>
              )}
            </div>
          </div>
        </LazyLoad>
      </React.Fragment>
    </section>
  );
}
