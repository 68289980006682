import React, { useEffect, useRef, useState, forwardRef } from 'react';
import './style.scss';
import { getPrefixField, createFilePath, isEmpty, sendDmpAction, sendDmp, isMobileFun, getPathnameByPosition } from '../../utils/obj-utils';
import ServiceRecallModal_v1 from '../ServiceRecallModal_v1';
import { dataFetcher } from '../../dataFetcher';
import $, { event } from 'jquery';
import { Swiper } from 'swiper';
// 计算经纬度之间的距离
function getDistance(lat1, lng1, lat2, lng2) {
  var radLat1 = (lat1 * Math.PI) / 180.0;
  var radLat2 = (lat2 * Math.PI) / 180.0;
  var a = radLat1 - radLat2;
  var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
  var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
  s = s * 6378.137;
  s = Math.round(s * 10000) / 10000;
  return s;
}
// 点击元素外的事件
function useClickOutside(callback, refObject) {
  const handleClickOutside = (e) => {
    if (!refObject?.current?.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
}
var map1;
var infoWindow;
var markers = [];
var swiper;
const FindDealer = forwardRef((props, ref) => {
  const { fields } = props.props;
  const car = props.car;
  const selectVehicleType = props.selectVehicleType || '';
  const carnum = props.num || 0;
  const istestdrive = props.istestdrive || false;
  const islive = props.islive || false;
  const istabs = props.istabs || false;
  const prantentshowECenter = props.prantentshowECenter || false;
  const [getshowECenter, setGetshowECenter] = useState(false);
  // const [mapShow, setMapShow] = useState(false);
  const setPersonparent = props.setPersonparent || '';
  const isMobile = isMobileFun();
  const title1 = fields[getPrefixField('title1', isMobile)]?.value;
  const title2 = fields[getPrefixField('title2', isMobile)]?.value;
  const dmpList = fields?.dmp?.value ? JSON.parse(fields[getPrefixField('dmp', isMobile)]?.value.replaceHtml()) : '';
  const [data, _] = useState({ isMobile, title1, title2 });
  const [showECenter, setShowECenter] = useState(false);
  const [dealerNameValue, setDealerNameValue] = useState('');
  const [cityPropShow, setCityPropShow] = useState(false);
  const [popOldShow, setPopOldShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [latLng, setLatLng] = useState([113.264385, 23.129112]);
  const [cityName, setCityName] = useState('广州');
  const [num, setNum] = useState(0);
  const [contactShop, setContactShop] = useState(false);
  const [mapLoad, setMapLoad] = useState(false);
  const [dealerProvince, setDealerProvince] = useState([]);
  const [dealerProCitys, setDealerProCitys] = useState([]);
  const [dealerDealers, setDealerDealers] = useState([]);
  const [person, setPerson] = useState({
    provinceId: '', // 省份Id Provinces.province_name
    cityId: '', // 城市id Citys.city_id
    dealerId: '' // 经销商id Dealers.dealer_id
  });
  const originalData = useRef(null);
  const province = useRef(null);
  const city = useRef(null);
  const swiperRef = useRef(null);
  const shopListRef = useRef(null);
  const contactShopRef = useRef(null);
  const [dealerAddress, setDealerAddress] = useState('');
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [ownAddress, setOwnAddress] = useState([]);
  const [init_dealerId, setInit_dealerId] = useState('');
  const [init_address, setInit_address] = useState('');
  const [initAMap, setInitAMap] = useState(false);
  const mapContainerRef = useRef(null);
  const FindDealerhtml = (
    <div className="find_dealer">
      <div id="mapcontainer" className="map" ref={mapContainerRef} />
      <div className="map-fixed">
        <div className="top">
          <div className="title">
            <span>
              <img alt="广汽本田" className="adress_icon2" src={`${createFilePath('/service/recall/adress_icon2.png')}`} />
              <span>{cityName}</span>
            </span>
            <span
              className="changecity"
              onClick={() => {
                if (!data || !dealerProvince || dealerProvince.length <= 0) {
                  return;
                }
                setCityPropShow(true);
                if (dmpList) {
                  window.Dmp.clickAction(dmpList.dealerChange);
                }
              }}
            >
              切换城市
            </span>
            <span>
              <span
                className="link"
                onClick={() => {
                  currentCityClick();
                  setDealerNameValue('');
                  setCityPropShow(false);
                  if (data.isMobile) {
                    shopListRef.current.style.display = 'block';
                  }
                  if (dmpList) {
                    window.Dmp.clickAction(dmpList.dealerBtn3);
                  }
                }}
              >
                定位到当前城市
              </span>
              <img alt="广汽本田" className="location" src={`${createFilePath('/service/recall/location.png')}`} />
            </span>
          </div>
          <div className="search">
            <div className="btext">
              <input
                type="text"
                placeholder={showECenter ? '新能源店名称查询' : '特约店名称查询'}
                className="input_text"
                id="key"
                value={dealerNameValue}
                onChange={(event) => {
                  setDealerNameValue(event.target.value);
                  if (event.target.value.length === 0) {
                    let dealers = getDealersData(person.cityId, originalData.current?.Dealers);
                    setDealerDealers(dealers);
                  }
                }}
                onBlur={(e) => {
                  if (dmpList) {
                    window.Dmp.msgAction(dmpList.dealerKey, e.target.value);
                  }
                }}
              />
              <img alt="广汽本田" src={`${createFilePath('/service/recall/search2.png')}`} className="btext_search" />
              <span
                className={showECenter ? 'e_search_btn' : 'search_btn'}
                onClick={() => {
                  checkDealerNameValue(dealerNameValue);
                  if (dmpList) {
                    window.Dmp.clickAction(dmpList.dealerSearch);
                  }
                }}
              >
                查询
              </span>
            </div>
            {data.isMobile && (
              <div className="tips">
                为您找到<span style={{ color: showECenter ? 'black' : '#c00' }}>{dealerDealers.length}</span>家{showECenter ? '新能源店' : '特约店'}
              </div>
            )}
          </div>
        </div>
        <div className="mapbox" ref={shopListRef}>
          {!data.isMobile && (
            <div className="tips">
              为您找到<span style={{ color: showECenter ? 'black' : '#c00' }}>{dealerDealers.length}</span>家{showECenter ? '新能源店' : '特约店'}
            </div>
          )}
          <div className="list_dealers">
            <div className="list_dealers_box">
              <ul>
                {dealerDealers &&
                  dealerDealers.length > 0 &&
                  dealerDealers.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={`boxItem ${item.dealer_type === 3 || item.dealer_type === 2 ? 'e-boxItem' : ''}`}
                        onClick={() => {
                          getOneOverlay(index);
                          if (dmpList) {
                            window.Dmp.clickAction(dmpList.dealerList, item.dealer_name);
                          }
                        }}
                      >
                        {!data.isMobile && (
                          <span
                            className="boxItem_index"
                            style={{
                              backgroundImage:
                                showECenter || item.dealer_type === 3 || item.dealer_type === 2
                                  ? `url(${createFilePath('/service/recall/adress_icon3.png')})`
                                  : `url(${createFilePath('/service/recall/adress_icon.png')})`
                            }}
                          >
                            {index + 1}
                          </span>
                        )}
                        <div className="boxItem_title">
                          <span>{item.dealer_name}</span>
                          {!data.isMobile && (
                            <a
                              className={showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? 'e_boxItem_a' : 'boxItem_a'}
                              target="_blank"
                              href={`https://idealer.ghac.cn/PC/Shop/Index?ShopID=${dealerDealers[index].dealer_code}`}
                              rel="noreferrer"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (dmpList) {
                                  window.Dmp.clickAction(
                                    dmpList.dealerDetail,
                                    `https://idealer.ghac.cn/PC/Shop/Index?ShopID=${dealerDealers[index].dealer_code}`
                                  );
                                }
                              }}
                            >
                              {showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? '新能源店网站' : '特约店网站'}
                            </a>
                          )}
                        </div>
                        <div className="boxItem_address">{item.address}</div>
                        {!data.isMobile && (
                          <div
                            className="boxItem_tel"
                            style={{ color: showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? 'black' : '#c00' }}
                          >
                            电话号码：{item.sales_tel}
                          </div>
                        )}
                        {data.isMobile && (
                          <div>
                            <a
                              className={showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? 'e_boxItem_a' : 'boxItem_a'}
                              target="_blank"
                              href={`https://idealer.ghac.cn/PC/Shop/Index?ShopID=${dealerDealers[index].dealer_code}`}
                              rel="noreferrer"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (dmpList) {
                                  window.Dmp.clickAction(
                                    dmpList.dealerDetail,
                                    `https://idealer.ghac.cn/PC/Shop/Index?ShopID=${dealerDealers[index].dealer_code}`
                                  );
                                }
                              }}
                            >
                              {showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? '新能源店网站' : '特约店网站'}
                            </a>
                            <a
                              className={showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? 'e_boxItem_a' : 'boxItem_a'}
                              onClick={(e) => {
                                e.stopPropagation();
                                setNum(index);
                                setContactShop(true);
                              }}
                            >
                              {showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? '联系新能源店' : '联系特约店'}
                            </a>
                            <div className="boxItem_luxian">
                              <img
                                alt="广汽本田"
                                src={`${createFilePath('/service/recall/luxian.png')}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(
                                    `https://ditu.amap.com/search?query=${dealerDealers[index].address}&src=mypage&callnative=0&innersrc=uriapi`
                                  );
                                }}
                              />
                              <div className="boxItem_luxian_text">路线</div>
                            </div>
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        {cityPropShow && (
          <div className="city_prop">
            <img
              alt="广汽本田"
              src={`${createFilePath('/service/recall/close.png')}`}
              className="city_prop_close"
              onClick={() => {
                setCityPropShow(false);
                if (dmpList) {
                  window.Dmp.clickAction(dmpList.dmpPointBtn2);
                }
              }}
            />
            <div className="city_prop_name">选择特约店所在城市</div>
            <div className="city_prop_content">
              <div className="city_prop_item">
                <div className="item_province">请选择省份</div>
                <div className="right">
                  <select
                    onChange={(e) => {
                      selectProvince(e);
                    }}
                    value={person.provinceId || ''}
                  >
                    <option value="">请选择省份</option>
                    {dealerProvince &&
                      dealerProvince.Provinces.map((item, index) => {
                        return (
                          <option key={index} provinceid={item.province_id} value={item.province_id}>
                            {item.province_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="city_prop_item">
                <div className="item_province">请选择城市</div>
                <div className="right">
                  <select
                    onChange={(e) => {
                      selectCity(e);
                    }}
                    value={person.cityId || ''}
                  >
                    <option value="">请选择城市</option>
                    {dealerProCitys &&
                      dealerProCitys.map((item, index) => {
                        return (
                          <option key={index} provinceid={item.city_id} value={item.city_id}>
                            {item.city_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
            <div className="city_prop_bottom">
              <span
                className="btn_cancel"
                onClick={() => {
                  reSelectClick();
                  if (dmpList) {
                    window.Dmp.clickAction(dmpList.dmpPointAgain);
                  }
                }}
              >
                重新选择
              </span>
              <span
                className="btn_confirm"
                onClick={() => {
                  propCitySure();
                  if (dmpList) {
                    window.Dmp.clickAction(dmpList.dmpPointBtn1);
                  }
                }}
              >
                确定
              </span>
            </div>
          </div>
        )}
      </div>
      {/* {!showECenter && !istestdrive && (
        <div className="old_btn" onClick={() => setPopOldShow(true)}>
          停止合作特约店名单
        </div>
      )} */}
      {!istestdrive && <ServiceRecallModal_v1 {...props.props} popOldShow={popOldShow} setPopOldShow={setPopOldShow} />}

      {data.isMobile && contactShop && dealerDealers && dealerDealers.length > 0 && (
        <>
          <div className="contactShop" ref={contactShopRef}>
            <p>
              售前：<a href={`tel:${dealerDealers[num].sales_tel}`}>{dealerDealers[num].sales_tel}</a>
            </p>
            <p>
              售后：<a href={`tel:${dealerDealers[num].sales_tel}`}>{dealerDealers[num].after_sales_tel}</a>
            </p>
            <p>
              24小时救援电话：<a href={`tel:${dealerDealers[num].sales_tel}`}>{dealerDealers[num].rescue_tel}</a>
            </p>
          </div>
          <div className="gray_bg" />
        </>
      )}
      {data.isMobile && dealerDealers && dealerDealers.length > 0 && (
        <div className="swiper-container">
          <div className="swiper-support" ref={swiperRef}>
            <div className="swiper-wrapper">
              {dealerDealers.map((item, index) => {
                return (
                  <div className="swiper-slide" key={index}>
                    <div className={`swiper-item ${item.dealer_type === 3 || item.dealer_type === 2 ? 'e-swiper-item' : ''}`}>
                      <div className="swiper-con">
                        <span
                          className="swiper-index"
                          style={{
                            backgroundImage:
                              showECenter || item.dealer_type === 3 || item.dealer_type === 2
                                ? `url(${createFilePath('/service/recall/adress_icon3.png')})`
                                : `url(${createFilePath('/service/recall/adress_icon.png')})`
                          }}
                        >
                          {index + 1}
                        </span>
                        <span className="swiper-title">{item.dealer_name}</span>
                        {item.after_sales_address ? (
                          <div className="swiper-address">
                            售前：{item.address}
                            <br />
                            <span>售后：{item.after_sales_address} </span>
                          </div>
                        ) : (
                          <div className="swiper-address">{item.address}</div>
                        )}
                        <div className="swiper-tel">
                          售前：
                          <a href={`tel:${item.sales_tel}`} style={{ display: 'inline-block' }}>
                            {item.sales_tel}
                          </a>
                        </div>
                        <div className="swiper-tel">
                          售后：
                          <a href={`tel:${item.after_sales_tel}`} style={{ display: 'inline-block' }}>
                            {item.after_sales_tel}
                          </a>
                        </div>
                        <div className="swiper-tel">
                          24小时救援电话：
                          <a href={`tel:${item.rescue_tel}`} style={{ display: 'inline-block' }}>
                            {item.rescue_tel}
                          </a>
                        </div>
                      </div>
                      <div className="swiper-bottom">
                        {istestdrive ? (
                          <>
                            <a
                              className={showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? 'e_swiper-a' : 'swiper-a'}
                              target="_blank"
                              data-html-item={dmpList.dealerDetail}
                              data-url={`https://idealer.ghac.cn/PC/Shop/Index?ShopID=${item.dealer_code}`}
                              href={`https://idealer.ghac.cn/PC/Shop/Index?ShopID=${item.dealer_code}`}
                              rel="noreferrer"
                            >
                              {showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? '新能源店网站' : '特约店网站'}
                            </a>
                            <a
                              className={showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? 'e_swiper-a' : 'swiper-a'}
                              data-html-item={dmpList.dealerDrive}
                              data-url={`https://ditu.amap.com/search?query=${item.address}&src=mypage&callnative=0&innersrc=uriapi`}
                              href={`https://ditu.amap.com/search?query=${item.address}&src=mypage&callnative=0&innersrc=uriapi`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              路线
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              className={showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? 'e_swiper-a' : 'swiper-a'}
                              target="_blank"
                              href={`https://idealer.ghac.cn/PC/Shop/Index?ShopID=${item.dealer_code}`}
                              rel="noreferrer"
                            >
                              {showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? '新能源店网站' : '特约店网站'}
                            </a>
                            <a
                              className={showECenter || item.dealer_type === 3 || item.dealer_type === 2 ? 'e_swiper-a' : 'swiper-a'}
                              href={`https://ditu.amap.com/search?query=${item.address}&src=mypage&callnative=0&innersrc=uriapi`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              路线
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
  useClickOutside(() => {
    setContactShop(false);
  }, contactShopRef);
  // 定位地图和数据初次渲染
  useEffect(() => {
    if (!load) {
      const getJsonp = (url) => {
        return new Promise((resolve, reject) => {
          $.ajax({
            url: url,
            type: 'GET',
            dataType: 'JSONP',
            cache: false,
            success: function (res) {
              resolve(res);
            },
            error: function (err, res, res2) {
              reject(res2);
            }
          });
        });
      };

      // 根据IP获取地理位置
      const mapCityApiPromise = getJsonp('https://restapi.amap.com/v3/ip?key=d4db88c544bf23a3a363f7ff8bcbf783&output=json')
        .then((res) => {
          if (res.status === '1') {
            if (res.province && res.province.length > 0) {
              return {
                province: res.province,
                city: res.city
              };
            } else {
              return {
                province: '广东',
                city: '广州'
              };
            }
          } else {
            return {
              province: '广东',
              city: '广州'
            };
          }
        })
        .catch((err) => {
          return {
            province: '广东',
            city: '广州'
          };
        });

      let allDealderInfo = '';
      if (getPathnameByPosition().toLocaleLowerCase() === 'test-drive') {
        allDealderInfo = dataFetcher('/api/honda/v1/Leads/GetAllDealerProCitys', null);
      } else {
        allDealderInfo = dataFetcher('/api/honda/v1/Leads/GetAllDealerProCitysExceptOffline', null);
      }

      // 三级联动数据初次渲染
      Promise.all([mapCityApiPromise, allDealderInfo]).then((res) => {
        province.current = res[0].province;
        if (res[0].city.indexOf('市')) {
          city.current = res[0].city.split('市')[0];
        } else {
          city.current = res[0].city;
        }
        setLoad(true);
        setCityName(city.current);

        if (res[1].status === 200) {
          originalData.current = { ...res[1]?.data?.Data };
          loadmap();
        }
      });
    } else {
      if (originalData.current) {
        loadmap();
      }
    }
  }, [load, showECenter, selectVehicleType]);

  const getCurrentCoordinates = () => {
    // sessionStorage是否含有个人经纬度
    if (sessionStorage.getItem('ownAddressLat') && sessionStorage.getItem('ownAddressLng')) {
      setOwnAddress([sessionStorage.getItem('ownAddressLat'), sessionStorage.getItem('ownAddressLng')]);
    } else {
      AMap.plugin('AMap.Geolocation', () => {
        var geolocationLatLng = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认：true
          timeout: 5000 // 设置定位超时时间，默认：无穷大
        });
        geolocationLatLng.getCurrentPosition(function (status, result) {
          if (status === 'complete') {
            sessionStorage.setItem('ownAddressLat', result.position.lat);
            sessionStorage.setItem('ownAddressLng', result.position.lng);
            setOwnAddress([result.position.lat, result.position.lng]);
          } else {
            console.log('errorerrorerrorerror', result);
          }
        });
      });
    }
  };

  // 地图加载
  const loadmap = (callBack) => {
    const AMapLoader = require('@amap/amap-jsapi-loader');
    window._AMapsecuritvonfig = { securityJsCode: 'df2d1e69d62971d0a7ebb43C882b678a' };
    AMapLoader.load({
      key: '975d8e63e9648540ee56a840bbce9884',
      // key: '642086a99b9a0de785d41db1cdab02df',
      version: '2.0',
      plugins: ['AMap.Geolocation'],
      AMapUI: {
        version: '1.1',
        plugins: ['overlay/SimpleMarker']
      },
      Loca: {
        version: '2.0.0'
      }
    })
      .then((AMap) => {
        getCurrentCoordinates();
        if (mapContainerRef.current) {
          setInitAMap(true);
          map1 = new AMap.Map('mapcontainer', {
            zoom: 10,
            center: latLng
          });
        }
        fetchData();
      })
      .catch((e) => {
        setInitAMap(true);
        fetchData();
      });
  };

  const loadmapContainer = (dealerDealers) => {
    if (dealerDealers && dealerDealers.length > 0) {
      let positionArr = [];
      for (let i = 0; i < dealerDealers.length; i++) {
        positionArr.push([dealerDealers[i].longitude, dealerDealers[i].latitude]);
      }
      if (markers && map1) {
        map1.remove(markers);
      }
      markers = [];
      infoWindow = new AMap.InfoWindow({ isCustom: true, offset: new AMap.Pixel(16, -45) });
      positionArr.map((item, index) => {
        let marker = new AMapUI.SimpleMarker({
          iconLabel: `${index + 1}`,
          iconTheme: 'default',
          iconStyle: showECenter ? 'black' : 'red',
          position: [item[0], item[1]],
          zIndex: 100,
          extData: {
            id: index
          }
        });
        var title = dealerDealers[index].dealer_name,
          content = [];
        content.push(
          dealerDealers[index].after_sales_address
            ? `<div class="item"><img alt="售前" class="icon" src='${createFilePath('/service/recall/mapadress.png')}'>售前：${
                dealerDealers[index].address
              }</div><div class="item">售后：${dealerDealers[index].after_sales_address}</div>`
            : `<div class="item"><img alt="售后" class="icon" src='${createFilePath('/service/recall/mapadress.png')}'>${
                dealerDealers[index].address
              }</div>`
        );
        content.push(
          `<div class="item"><img alt="售前" class="icon" src='${createFilePath('/service/recall/maptel.png')}'>售前：${
            dealerDealers[index].sales_tel
          }</div><div class="item">售后：${dealerDealers[index].after_sales_tel}</div><div class="item">24小时救援电话：${
            dealerDealers[index].rescue_tel
          }</div>`
        );
        if (istestdrive) {
          content.push(
            `<a 
              class='${showECenter ? 'e_btn' : ''}' 
              data-html-item='${dmpList.dealerDrive}' 
              data-url='https://ditu.amap.com/search?query=${dealerDealers[index].address}&src=mypage&callnative=0&innersrc=uriapi' 
              href='https://ditu.amap.com/search?query=${dealerDealers[index].address}&src=mypage&callnative=0&innersrc=uriapi'
              target='_blank'
            >
              到这去
            </a>`
          );
        } else {
          content.push(
            `<a class='${showECenter ? 'e_btn' : ''}' href=https://ditu.amap.com/search?query=${
              dealerDealers[index].address
            }&src=mypage&callnative=0&innersrc=uriapi target='_blank'>到这去</a>`
          );
        }
        marker.content = createInfoWindow(title, content.join(''));
        dealerDealers[index].index = index;
        marker.setExtData({ dealer: dealerDealers[index] });
        marker.on('click', markerClickHandler);
        markers.push(marker);
        if (map1) {
          map1.add(marker);
        }
      });
      if (map1) {
        map1.setFitView();
      }
      // map1.setZoom(11);
    }
    setMapLoad(true);
    if (getshowECenter) {
      setShowECenter(getshowECenter);
      setGetshowECenter(false);
    }
  };
  // m端swiper加载
  const loadswiper = () => {
    if (!data || dealerDealers.length <= 0 || !data.isMobile) {
      return;
    }
    swiper = new Swiper(swiperRef.current, {
      grabCursor: true,
      modules: [],
      slidesPerView: 1,
      grid: {
        fill: 'column',
        rows: 1
      },
      initialSlide: 0,
      pagination: false,
      navigation: false,
      on: {
        slideChangeTransitionEnd: function (swiper) {
          getOneOverlay(swiper.activeIndex, true);
          if (dmpList) {
            window.Dmp.clickAction(dmpList.dealerList, dealerDealers[swiper.activeIndex].dealer_name);
          }
        }
      }
    });
  };
  useEffect(() => {
    if (prantentshowECenter) {
      setGetshowECenter(true);
    }
  }, [prantentshowECenter]);
  useEffect(() => {
    if (islive) {
      return;
    }
    if (mapContainerRef.current && initAMap) {
      loadmapContainer(dealerDealers);
    }
  }, [dealerDealers]);
  useEffect(() => {
    if (islive) {
      return;
    }
    loadswiper();
    return () => {
      if (swiper) {
        swiper.destroy(true, true);
      }
    };
  }, [swiperRef.current, dealerDealers]);

  const [getRecen, setSetRecen] = useState(true);
  useEffect(() => {
    if (person.cityId && getRecen && ownAddress && ownAddress.length > 0 && dealerDealers && dealerDealers.length > 0) {
      getRecentDealer();
      setSetRecen(false);
    }
  }, [person.cityId, ownAddress]);

  // 滚动到顶部
  const dealersboxscrolltop = () => {
    if (!data || dealerDealers.length <= 0 || !data.isMobile || islive) {
      return;
    }
    shopListRef.current.querySelector('.list_dealers_box').scrollTop = 0;
  };
  useEffect(() => {
    if (islive) {
      return;
    }
    dealersboxscrolltop();
  }, []);
  // 过滤当前省市数据
  const fetchData = () => {
    const data = filterOriginalData();
    let init_province = person?.provinceId || '';
    let init_zoneId = '';
    let result = data.Provinces.find((item) => item.province_id === init_province);
    result = data.Provinces.find((item) => province.current?.indexOf(item.province_name) !== -1);
    if (!result) {
      result = data.Provinces.find((item) => '广东省'.indexOf(item.province_name) !== -1);
      if (!result) {
        result = data.Provinces[0];
      }
    }
    init_province = result.province_id;
    init_zoneId = result.zone_id;
    const newProvinceList = data.Provinces.map((item) => ({ ...item, default: init_province === item.province_id }));
    setDealerProvince({ ...data, Provinces: newProvinceList });

    let init_city = person?.cityId || '';
    const citys = data.Citys.filter((item) => item.province_id === (init_province || data.Provinces[0].province_id));
    result = citys.find((item) => item.city_id === init_city);
    result = citys.find((item) => city.current?.indexOf(item.city_name) !== -1);
    if (!result) {
      result = citys.find((item) => '广州'.indexOf(item.city_name) !== -1);
      if (!result) {
        result = citys[0];
      }
    }
    init_city = result.city_id;
    const newCitys = citys.map((item) => ({ ...item, default: init_city === item.city_id }));
    setDealerProCitys(newCitys);

    let dealers = getDealersData(init_city || citys[0].city_id, data.Dealers);
    setDealerDealers(dealers);
    person.provinceId = init_province ? init_province : data.Provinces[0].province_id;
    person.cityId = init_city ? init_city : citys[0]?.city_id;
    person.dealerId = dealers[0]?.dealer_id;
    person.areaCode = init_zoneId ? init_zoneId : data.Provinces[0].zone_id;
    setDealerAddress(dealers[0].address);
    setPerson(person);
    setPersonparent(person);
  };

  // 筛选所有数据
  const filterOriginalData = () => {
    if (!originalData?.current) {
      return;
    }

    const data = { Citys: [], Dealers: [...originalData.current?.Dealers], Provinces: [] };
    originalData.current?.Citys?.forEach((item) => {
      const dealers = getDealersData(item.city_id, originalData.current?.Dealers);
      if (dealers.length !== 0) {
        data.Citys.push(item);
      }
    });

    originalData.current?.Provinces?.forEach((item) => {
      const result = data?.Citys?.find((item1) => item1.province_id === item.province_id);
      if (result) {
        data.Provinces.push(item);
      }
    });
    return data;
  };

  // 筛选门店数据
  const getDealersData = (cityId, data, car = '') => {
    const modelName = car ? car.modelName : selectVehicleType?.modelName;
    let weight = car ? car.weight : selectVehicleType?.weight;
    let dealers = [];
    if (!isEmpty(weight)) {
      weight = parseInt(weight);
      dealers = data.filter((item) => item.city_id === cityId && (weight & item.dealer_right) > 0);
      // if (modelName === 'enp1') {
      //   if (dealers.length === 0) {
      //     dealers = data.filter((item) => item.city_id === cityId && item.dealer_level === 1);
      //   } else {
      //     const exist = dealers.find((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     if (exist) {
      //       dealers = dealers.filter((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     }
      //   }
      // }
    } else {
      if (showECenter) {
        dealers = data.filter((item) => item.city_id === cityId && item.dealer_right > 0 && (item.dealer_type === 2 || item.dealer_type === 3));
      } else {
        dealers = data.filter((item) => item.city_id === cityId && item.dealer_right > 0 && item.dealer_type === 1);
        dealers = data.filter((item) => item.city_id === cityId && item.dealer_right > 0 && item.dealer_type === 1);
      }
    }
    const newEnergy = dealers.filter((item) => item.dealer_right === 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    const gas = dealers.filter((item) => item.dealer_right !== 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    return [...newEnergy, ...gas];
  };
  const getRecentDealer = () => {
    let ownLatLng = ownAddress;
    if (sessionStorage.getItem('ownAddressLat') && sessionStorage.getItem('ownAddressLng')) {
      ownLatLng = [Number(sessionStorage.getItem('ownAddressLat')), Number(sessionStorage.getItem('ownAddressLng'))];
    }
    if (ownLatLng[0] && ownLatLng[1]) {
      let distance = Number.MAX_VALUE;
      for (let i = 0; i < dealerDealers.length; i++) {
        if (
          getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealerDealers[i].latitude), Number(dealerDealers[i].longitude)) < distance
        ) {
          distance = getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealerDealers[i].latitude), Number(dealerDealers[i].longitude));
        }
      }
      for (let i = 0; i < dealerDealers.length; i++) {
        if (
          getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealerDealers[i].latitude), Number(dealerDealers[i].longitude)) === distance
        ) {
          setInit_dealerId(dealerDealers[i].dealer_id ? dealerDealers[i].dealer_id : '');
          setInit_address(dealerDealers[i].address ? dealerDealers[i].address : '');
          person.dealerId = dealerDealers[i].dealer_id;
          let newPerson = { ...person };
          setPerson(newPerson);
          setPersonparent(newPerson);
          setDealerAddress(dealerDealers[i].address ? dealerDealers[i].address : '');
          if (istestdrive) {
            let dealer = dealerDealers?.find((item) => item.dealer_id === dealerDealers[i].dealer_id);
            let dealerIndex = dealerDealers.indexOf(dealer);
            if (dealerIndex >= 0) {
              getOneOverlay(dealerIndex);
            }
          }
        }
      }
    }
  };
  // 点击切换渲染省份数据
  const selectProvince = (e, issetdealers = false) => {
    let p_value = e.target.childNodes[e.target.selectedIndex].getAttribute('value');
    if (p_value) {
      if (dmpList) {
        window.Dmp.msgAction(dmpList.province, p_value);
      }
      const aou = dealerProvince.Citys.filter((item) => {
        return item.province_id === p_value;
      });
      setDealerProCitys(aou);
      person.provinceId = p_value;
      person.cityId = aou[0].city_id;
      const data = filterOriginalData();
      let zone_id = data.Provinces?.find((item) => item.province_id === p_value);
      person.areaCode = zone_id.zone_id;
      const dealers = getDealersData(aou[0].city_id, dealerProvince.Dealers);
      person.dealerId = dealers[0].dealer_id;
      let newPerson = { ...person };
      setPerson(newPerson);
      setPersonparent(newPerson);

      if (issetdealers) {
        propCitySure();
      }
    }
  };

  // 点击切换渲染城市数据
  const selectCity = (e, issetdealers = false) => {
    let c_value = e.target.childNodes[e.target.selectedIndex].getAttribute('value');
    if (c_value) {
      if (dmpList) {
        window.Dmp.msgAction(dmpList.city, c_value);
      }
      person.cityId = c_value;
      const dealers = getDealersData(c_value, dealerProvince.Dealers);
      person.dealerId = dealers[0]?.dealer_id;
      let newPerson = { ...person };
      setPerson(newPerson);
      setPersonparent(newPerson);
      if (issetdealers) {
        propCitySure();
      }
    }
  };
  // 点击切换渲染特约店数据
  const selectDealer = (e) => {
    let d_value = e.target.childNodes[e.target.selectedIndex].getAttribute('provinceid');
    if (d_value) {
      if (dmpList) {
        window.Dmp.msgAction(dmpList.dealer, d_value);
      }
      person.dealerId = d_value;
      let newPerson = { ...person };
      setPerson(newPerson);
      setPersonparent(newPerson);
      setDealerAddress(dealerDealers[e.target.selectedIndex].address ? dealerDealers[e.target.selectedIndex].address : '');
      if (istestdrive) {
        let dealer = dealerDealers?.find((item) => item.dealer_id === person.dealerId);
        let dealerIndex = dealerDealers.indexOf(dealer);
        if (dealerIndex >= 0) {
          getOneOverlay(dealerIndex);
        }
      }
    }
  };
  // 点击'重新选择'
  const reSelectClick = () => {
    person.provinceId = '';
    person.cityId = '';
    let newPerson = { ...person };
    setPerson(newPerson);
    setPersonparent(newPerson);
  };

  // 点击'确定'切换城市
  const propCitySure = () => {
    if (person.cityId) {
      let data = filterOriginalData();
      let dealers = getDealersData(person.cityId, data.Dealers);
      setDealerDealers(dealers);

      let city = data.Citys?.find((item) => item.city_id === person.cityId);
      setCityName(city.city_name);
      setDealerAddress(dealers.length > 0 && (init_address ? init_address : dealers[0].address));
    }
    if (data.isMobile && shopListRef.current) {
      shopListRef.current.style.display = 'block';
    }
    setCityPropShow(false);
    setDealerNameValue('');
    dealersboxscrolltop();
  };
  // ``
  // 特约店名称查询
  const checkDealerNameValue = (val) => {
    if (person.cityId) {
      if (val) {
        let data = filterOriginalData();
        let dealers = getDealersData(person.cityId, data.Dealers);
        let dealers1 = dealers.filter((item) => item.dealer_name.indexOf(val) !== -1);
        setDealerDealers(dealers1);
        dealersboxscrolltop();
      } else {
        alert('请输入特约店名称');
      }
    }
  };

  // 点击定位到当前城市
  const currentCityClick = () => {
    dealersboxscrolltop();
    const data = filterOriginalData();
    let currentProvince = data.Provinces.find((item) => province?.current?.indexOf(item.province_name) !== -1);
    let currentCity = data.Citys.find((item) => city?.current?.indexOf(item.city_name) !== -1);
    const aou = dealerProvince.Citys.filter((item) => {
      return item.province_id === currentCity.province_id;
    });
    setDealerProCitys(aou);
    person.provinceId = currentProvince.province_id;
    person.cityId = currentCity.city_id;
    setCityName(currentCity.city_name);
    setPerson(person);
    setPersonparent(person);
    let dealers = getDealersData(currentCity.city_id, data.Dealers);
    setDealerDealers(dealers);
  };

  // 点击tab切换
  const tabClick = () => {
    if (!data) {
      return;
    }
    const data = filterOriginalData();
    let currentCity = data.Citys.find((item) => city?.current?.indexOf(item.city_name) !== -1);
    setCityName(currentCity.city_name);
  };

  // 构建自定义信息窗体
  const createInfoWindow = (title, content) => {
    var info = document.createElement('div');
    info.className = 'custom-info input-card content-window-card';
    info.id = 'map-info';

    // 定义顶部标题
    var top = document.createElement('div');
    var titleD = document.createElement('div');
    var closeX = document.createElement('img');
    top.className = 'info-top';
    titleD.innerHTML = title;
    closeX.src = 'https://webapi.amap.com/images/close2.gif';
    closeX.onclick = closeInfoWindow;

    top.appendChild(titleD);
    top.appendChild(closeX);
    info.appendChild(top);

    // 定义中部内容
    var middle = document.createElement('div');
    middle.className = 'info-middle';
    middle.style.backgroundColor = 'white';
    middle.innerHTML = content;
    info.appendChild(middle);

    // 定义底部内容
    var bottom = document.createElement('div');
    bottom.className = 'info-bottom';
    bottom.style.position = 'relative';
    bottom.style.top = '0px';
    bottom.style.margin = '0 auto';
    var sharp = document.createElement('img');
    sharp.src = 'https://webapi.amap.com/images/sharp.png';
    bottom.appendChild(sharp);
    info.appendChild(bottom);
    return info;
  };

  // 点击标记事件
  const markerClickHandler = (e) => {
    var dealer = e.target.getExtData().dealer;
    if (data.isMobile) {
      swiper.slideTo(dealer.index, 1000, false);
      setSwiperIndex(dealer.index);
    } else {
      if (infoWindow && e.target) {
        infoWindow.setContent(e.target.content);
        infoWindow.open(map1, e.target.getPosition());
      }
    }
    if (dmpList) {
      window.Dmp.clickAction(dmpList.dealerBtn4, dealer.dealer_name);
    }
    if (map1) {
      map1.setZoomAndCenter(11, e.target.getPosition());
    }
    let d_value = dealer.dealer_id;
    if (d_value) {
      person.dealerId = d_value;
      let newPerson = { ...person };
      setPerson(newPerson);
      setPersonparent(newPerson);
      setDealerAddress(dealer.address || '');
    }
  };

  // 标记弹窗关闭按钮
  function closeInfoWindow() {
    map1.clearInfoWindow();
  }

  // 点击列表特约店
  const getOneOverlay = (targetId, bool) => {
    var targetMarker;
    for (let i = 0; i < markers.length; i++) {
      var id = markers[i].getExtData().dealer.index;
      if (id === targetId) {
        targetMarker = markers[i];
        break;
      }
    }
    if (data.isMobile) {
      if (shopListRef.current) {
        shopListRef.current.style.display = 'none';
      }

      if (!bool) {
        swiper.slideTo(targetId, 1000, false);
        setSwiperIndex(targetId);
      }
    } else {
      infoWindow.setContent(targetMarker.content);
      infoWindow.open(map1, targetMarker.getPosition());
    }
    if (map1) {
      map1.setZoomAndCenter(11, targetMarker.getPosition());
    }
    let d_value = dealerDealers[targetId].dealer_id;
    if (d_value) {
      person.dealerId = d_value;
      let newPerson = { ...person };
      setPerson(newPerson);
      setPersonparent(newPerson);
      setDealerAddress(dealerDealers[targetId].address || '');
    }
  };
  // ;;

  const imgOpenClick = () => {
    const bg = document.querySelector('.popupBg');
    const model = document.querySelector('.popupBox');
    if (bg && model) {
      bg.style.display = 'block';
      model.style.display = 'block';
    }
  };

  const imgCloseClick = () => {
    const bg = document.querySelector('.popupBg');
    const model = document.querySelector('.popupBox');
    if (bg && model) {
      bg.style.display = 'none';
      model.style.display = 'none';
    }
  };

  const go = (e) => {
    const index = e.target.name;
    let d_value = dealerDealers[index].dealer_id;
    if (d_value) {
      person.dealerId = d_value;
      let newPerson = { ...person };
      setPerson(newPerson);
      setPersonparent(newPerson);
      setDealerAddress(dealerDealers[index].address || '');
    }
    if (dmpList) {
      window.Dmp.clickAction(dmpList.dealerList, d_value);
    }
  };

  return (
    <>
      {istabs && (
        <div className="dealer_tabs">
          <div
            className={showECenter ? 'dealer_tabs_item' : 'dealer_tabs_item_active'}
            dangerouslySetInnerHTML={{ __html: data.title1.replaceHtml() }}
            onClick={() => {
              setShowECenter(false);
              tabClick();
              setDealerNameValue('');
              setCityPropShow(false);
              dealersboxscrolltop();
              if (showECenter) {
                setCityName(city.current);
              }
            }}
          />
          <div
            className={showECenter ? 'dealer_tabs_item_active' : 'dealer_tabs_item'}
            dangerouslySetInnerHTML={{ __html: data.title2.replaceHtml() }}
            onClick={() => {
              setShowECenter(true);
              tabClick();
              setDealerNameValue('');
              setCityPropShow(false);
              dealersboxscrolltop();
              if (!showECenter) {
                setCityName(city.current);
              }
            }}
          />
        </div>
      )}
      {istestdrive ? (
        <>
          <div className="formitem formitem2">
            <div className="n">
              <span className="red">*</span>附近的销售店：
            </div>
            <div className="val">
              <div className="item">
                <i className="icon province_icon">
                  <img src={createFilePath('/test-drive/province_icon.png')} alt="province" />
                </i>
                <select
                  className="select"
                  onChange={(e) => {
                    selectProvince(e, true);
                  }}
                  value={person.provinceId}
                >
                  {dealerProvince.Provinces &&
                    dealerProvince.Provinces.length > 0 &&
                    dealerProvince.Provinces.map((item, index) => {
                      return (
                        <option key={index} provinceid={item.province_id} value={item.province_id}>
                          {item.province_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="item fr">
                <i className="icon city_icon">
                  <img src={createFilePath('/test-drive/city_icon.png')} alt="city" />
                </i>
                <select
                  className="select"
                  onChange={(e) => {
                    selectCity(e, true);
                  }}
                  value={person.cityId}
                >
                  {dealerProCitys &&
                    dealerProCitys.map((item, index) => {
                      return (
                        <option key={index} provinceid={item.city_id} value={item.city_id}>
                          {item.city_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="item item2">
                <i className="icon dealer_icon">
                  <img src={createFilePath('/test-drive/dealer_icon.png')} alt="dealer" />
                </i>
                <select className="select" onChange={(e) => selectDealer(e)} value={person.dealerId}>
                  {dealerDealers &&
                    dealerDealers.map((item, index) => {
                      return (
                        <option key={index} provinceid={item.dealer_id} value={item.dealer_id}>
                          {item.dealer_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="dealerAddress">{dealerAddress}</div>
            </div>
          </div>
          <div className="formmap">{FindDealerhtml}</div>
        </>
      ) : islive ? (
        <>
          <div className="formitem">
            <div className="item">
              <select
                className="select"
                onChange={(e) => {
                  selectProvince(e, true);
                }}
                value={person.provinceId}
              >
                {dealerProvince.Provinces &&
                  dealerProvince.Provinces.length > 0 &&
                  dealerProvince.Provinces.map((item, index) => {
                    return (
                      <option key={index} provinceid={item.province_id} value={item.province_id}>
                        {item.province_name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="item fr">
              <select
                className="select"
                onChange={(e) => {
                  selectCity(e, true);
                }}
                value={person.cityId}
              >
                {dealerProCitys &&
                  dealerProCitys.map((item, index) => {
                    return (
                      <option key={index} provinceid={item.city_id} value={item.city_id}>
                        {item.city_name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="formitem">
            <select className="select" onChange={(e) => selectDealer(e)} value={person.dealerId}>
              {dealerDealers &&
                dealerDealers.map((item, index) => {
                  return (
                    <option key={index} provinceid={item.dealer_id} value={item.dealer_id}>
                      {item.dealer_name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="dealerAddress">特约店地址：{dealerAddress}</div>
        </>
      ) : (
        <>{FindDealerhtml}</>
      )}
    </>
  );
});
FindDealer.displayName = 'FindDealer';
export default FindDealer;
