/* eslint-disable no-bitwise */
import React, { useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { createFilePath, getPrefixField, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields }) {
  const isMobile = isMobileFun();
  const containerRef = useRef(null);
  const desc = fields[getPrefixField('desc', isMobile)]?.value?.replaceHtml();
  const remarks = fields[getPrefixField('remarks', isMobile)]?.value?.replaceHtml();
  const list = fields[getPrefixField('list', isMobile)]?.map((item) => ({ ...item.fields }));

  return (
    <section className="financial-service-v1" ref={containerRef}>
      <div className="title">金融服务</div>
      <div className="tab">
        <a href="/financial/service" className="active">
          喜悦金融服务
        </a>
        <a href="/financial/calculator">贷款计算器</a>
      </div>
      <p className="desc" dangerouslySetInnerHTML={{ __html: desc }} />
      {list.map((item, index) => (
        <div key={index} className="item">
          <div className="item-title" dangerouslySetInnerHTML={{ __html: item?.title?.value?.replaceHtml() }} />
          <div className="item-content">
            <div className="left">
              <p className="text" dangerouslySetInnerHTML={{ __html: item?.desc?.value?.replaceHtml() }} />
              <p className="text" dangerouslySetInnerHTML={{ __html: item?.method?.value?.replaceHtml() }} />
              <p className="text" dangerouslySetInnerHTML={{ __html: item?.customer?.value?.replaceHtml() }} />
            </div>
            <div className="right">
              <img alt="广汽本田" className="image" src={createFilePath(item?.image?.value)} />
            </div>
          </div>
        </div>
      ))}
      <p className="remarks" dangerouslySetInnerHTML={{ __html: remarks }} />
    </section>
  );
}
