import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import './styles.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import temp from '../../temp/config';
import Details from '../../elements/InnerPageDetail';
import { createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const [flag, setFlag] = useState(false);
  const { fields } = props;
  const { item, urlLink } = fields;
  const [windowHeight, setWindowHeight] = useState(400);
  const [spaceIndex, setSpaceIndex] = useState(0);
  const [thisMobile, setThisMobile] = useState(true);
  const rootEl = useRef(null);

  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
    setThisMobile(document.documentElement.clientWidth > 1024);

    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
      setThisMobile(document.documentElement.clientWidth > 1024);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    window.Dmp.sectionAction('price_space');
  };

  return (
    <React.Fragment>
      {flag && <Details routePath="/vehicles/accord/v1/vehicle-module-space" close={() => setFlag(false)} />}
      <Controller>
        {/* indicators={true} */}
        <Scene triggerHook={1} duration={windowHeight}>
          {(progress, event) => {
            return (
              <div>
                <Timeline totalProgress={progress} paused>
                  <Tween from={{ scale: 0.8 }} to={{ scale: 1 }} position={1} ease="elastic.out(0.1, 0.1)" duration={1}>
                    <div className={`PriceSpace_v1 ${item[spaceIndex].fields.className.value}`} id="price_space" ref={rootEl}>
                      {!thisMobile ? (
                        <img src={`${createFilePath(item[spaceIndex]?.fields?.m_image_video?.value)}`} alt="space" />
                      ) : (
                        <img src={`${createFilePath(item[spaceIndex]?.fields?.pc_image_video?.value)}`} alt="space" />
                      )}
                      <div>
                        <Tween from={{ opacity: 0, y: 200 }} to={{ opacity: 1, y: 0 }} duration={1} position={2}>
                          <div className="space">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: (thisMobile
                                  ? item[spaceIndex]?.fields?.title?.value
                                  : item[spaceIndex]?.fields?.m_title?.value
                                )?.replaceHtml()
                              }}
                            ></div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: (thisMobile
                                  ? item[spaceIndex]?.fields?.subtitle?.value
                                  : item[spaceIndex]?.fields?.m_subtitle?.value
                                )?.replaceHtml()
                              }}
                            ></div>
                            <div>
                              <button
                                data-action={item[spaceIndex]?.fields.btn[0].fields.func.value}
                                data-item={item[spaceIndex]?.fields.btn[0].displayName}
                                data-url=""
                                onClick={() => {
                                  setFlag(true);
                                }}
                                style={{ fontSize: '14px' }}
                              >
                                展开详情
                              </button>
                            </div>
                          </div>
                        </Tween>
                        <Tween from={{ opacity: 0, y: 200 }} to={{ opacity: 1, y: 0 }} duration={1} position={3}>
                          <div className="panorama">
                            <a style={{ display: item[spaceIndex].fields.middleFlag.value === 'true' ? 'block' : 'none' }}>
                              <div
                                data-action="clickAction"
                                data-item="price_space-inside"
                                data-url="https://accord360.ghac.cn/?interior=1"
                                onClick={() => {
                                  window.open(fields.urlLink.value);
                                }}
                              >
                                <img
                                  alt="广汽本田"
                                  data-action="clickAction"
                                  data-item="price_space-inside"
                                  data-url="https://accord360.ghac.cn/?interior=1"
                                  src={createFilePath('/common/black_720.png')}
                                />
                                <p data-action="clickAction" data-item="price_space-inside" data-url="https://accord360.ghac.cn/?interior=1">
                                  全景内饰
                                </p>
                              </div>
                            </a>
                          </div>
                        </Tween>
                      </div>
                    </div>
                  </Tween>
                </Timeline>
              </div>
            );
          }}
        </Scene>
      </Controller>
    </React.Fragment>
  );
}
