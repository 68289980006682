import { canUseDom } from './dom-utils';
import { getWindow } from 'ssr-window';

export const gridSpan = (span) => {
  return Math.floor((span * 10000) / 24) / 100 + 'vw';
};
export const gridSpanNumber = (span) => {
  return Math.floor((span * 10000) / 24) / 100;
};
export const gridWidth = (span) => {
  if (!canUseDom()) return null;
  let docWidth = document.documentElement.clientWidth || document.body.clientWidth;
  return Math.floor((span * docWidth * 100) / 24) / 100;
};
export const throttle = (action, delay, context, iselapsed) => {
  let timeout = null;
  let lastRun = 0;
  return function () {
    if (timeout) {
      if (iselapsed) {
        return;
      } else {
        clearTimeout(timeout);
        timeout = null;
      }
    }
    let elapsed = Date.now() - lastRun;
    let args = arguments;
    if (iselapsed && elapsed >= delay) {
      runCallback();
    } else {
      timeout = setTimeout(runCallback, delay);
    }
    /**
     * 执行回调
     */
    function runCallback() {
      lastRun = Date.now();
      timeout = false;
      action.apply(context, args);
    }
  };
};

export const getQueryVariable = (variable) => {
  let query = getWindow().location.search.substring(1);
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};
export const formatDate = (item_date) => {
  let date = new Date(parseInt(item_date) * 1000);
  let YY = date.getFullYear() + '-';
  let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return YY + MM + DD + ' ' + hh + mm + ss;
};
