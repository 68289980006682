import React, { useState } from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { GetGmallAppSchemeUrl } from '../../utils/api';
import { getPrefixField, isMobileFun, createFilePath, GotoMall } from '../../utils/obj-utils';
export default function index(props) {
  if (!props?.showFooter) {
    return null;
  }

  const isMobile = isMobileFun();
  const data = props.fields[getPrefixField('data', isMobile)]?.value || '';
  const { top_left_data, top_right_data, platform_data, contact_data, bottom_other_link_title, fotterbottom_data } = JSON.parse(data.replaceHtml());
  const fotterBtmList = [7, 6, 5, 9, 4, 3, 8];
  const fotterTopLeftHeadList = [
    'bottom-button-car',
    'bottom-button-buy',
    'bottom-button-service',
    'bottom-button-center',
    'bottom-button-about',
    'bottom-button-link'
  ];
  const [pictrueList] = useState([
    '/breeze/v1/pc/footer/Tmall.png',
    '/breeze/v1/pc/footer/Microblog.png',
    '/breeze/v1/pc/footer/WeChat.png',
    '/breeze/v1/pc/footer/QrCode.png',
    '/breeze/v1/pc/footer/Phone.png'
  ]);
  const [num, setNum] = useState(-1);
  const [codeNum, setCodeNum] = useState(-1);
  return (
    <div style={{ color: 'black', backgroundColor: 'white' }}>
      {!isMobile ? (
        <div className="Fotter-homePage">
          <div className="FotterTop">
            <div className="FotterTopLeft">
              <div className="FotterTopLeftHead">
                {top_left_data.map((item, index) => {
                  return item.linkUrl !== '' ? (
                    <Link
                      data-action="clickAction"
                      data-item={`${item.dmp ? item.dmp : fotterTopLeftHeadList[index]}`}
                      data-url={item.pc_linkUrl}
                      href={item.pc_linkUrl}
                      key={index}
                      field={{ value: item.title }}
                      style={{ color: 'black' }}
                      target={item.target ? '_blank ' : ''}
                      rel={item.nofollow ? 'nofollow' : ''}
                    >
                      {item.title}
                    </Link>
                  ) : (
                    <Link
                      data-action="clickAction"
                      data-item={`${item.dmp ? item.dmp : fotterTopLeftHeadList[index]}`}
                      data-url={item.pc_linkUrl}
                      key={index}
                      field={{ value: item.title }}
                      style={{ color: 'black', pointerEvents: 'none' }}
                    >
                      {item.title}
                    </Link>
                  );
                })}
              </div>
              <div className="FotterTopLeftBottom">
                {top_left_data.map((item, index) => {
                  return index === 0 ? (
                    <div key={index}>
                      {item.listItem.map((ite, id) => {
                        return (
                          <Link
                            field={{ value: ite.title }}
                            key={id}
                            rel={ite.nofollow ? 'nofollow' : ''}
                            onClick={(e) => {
                              var url = ite.pc_linkUrl;
                              if (ite.title === '广汽本田商城') {
                                GotoMall(ite.pc_linkUrl, ite.dmp, isMobile);
                              } else {
                                if (ite.target) {
                                  window.open(ite.pc_linkUrl);
                                } else {
                                  location.href = ite.pc_linkUrl;
                                }
                                window.Dmp.clickAction(ite.dmp, url);
                              }
                            }}
                          >
                            {ite.title}
                          </Link>
                        );
                      })}
                    </div>
                  ) : index === 1 ? (
                    <div key={index}>
                      {item.listItem.map((ite, idx) => {
                        return (
                          <Link
                            key={idx === 2 ? 7 : idx < 2 ? idx + 1 : idx}
                            field={{ value: ite.title }}
                            rel={ite.nofollow ? 'nofollow' : ''}
                            onClick={(e) => {
                              var url = ite.pc_linkUrl;
                              if (ite.title === '广汽本田商城') {
                                GotoMall(
                                  ite.pc_linkUrl,
                                  ite.dmp ? ite.dmp : fotterTopLeftHeadList[index] + (idx === 2 ? 7 : idx < 2 ? idx + 1 : idx),
                                  isMobile
                                );
                              } else {
                                if (ite.target) {
                                  window.open(ite.pc_linkUrl);
                                } else {
                                  location.href = ite.pc_linkUrl;
                                }
                                window.Dmp.clickAction(
                                  ite.dmp ? ite.dmp : fotterTopLeftHeadList[index] + (idx === 2 ? 7 : idx < 2 ? idx + 1 : idx),
                                  url
                                );
                              }
                            }}
                          >
                            {ite.title}
                          </Link>
                        );
                      })}
                    </div>
                  ) : (
                    <div key={index}>
                      {item.listItem.map((ite, idx) => {
                        return (
                          <Link
                            key={idx}
                            field={{ value: ite.title }}
                            rel={ite.nofollow ? 'nofollow' : ''}
                            onClick={(e) => {
                              var url = ite.pc_linkUrl;
                              if (ite.title === '广汽本田商城') {
                                GotoMall(ite.pc_linkUrl, ite.dmp ? ite.dmp : fotterTopLeftHeadList[index] + (idx + 1), isMobile);
                              } else {
                                if (ite.target) {
                                  window.open(ite.pc_linkUrl);
                                } else {
                                  location.href = ite.pc_linkUrl;
                                }
                                window.Dmp.clickAction(ite.dmp ? ite.dmp : fotterTopLeftHeadList[index] + (idx + 1), url);
                              }
                            }}
                          >
                            {ite.title}
                          </Link>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="FotterTopRight">
              <div className="platformList" style={{ pointerEvents: 'all' }}>
                {platform_data.map((item, index) => {
                  return (
                    <div key={index} className="platformItem">
                      {item.herf_url !== '' ? (
                        <Link
                          className="itemLink"
                          href={item.herf_url}
                          field={{ value: item.title }}
                          target={item.target ? '_blank ' : ''}
                          rel={item.nofollow ? 'nofollow' : ''}
                        >
                          <img
                            data-action={item.dmpAction}
                            data-item={item.dmpValue}
                            data-url={item.herf_url}
                            src={createFilePath(item.icon_src)}
                            className="platformIcon"
                            alt={item.title}
                          />
                        </Link>
                      ) : (
                        <div className="codeLayout">
                          <img
                            alt="广汽本田"
                            data-action={item.dmpAction}
                            data-item={item.dmpValue}
                            src={createFilePath(item.icon_src)}
                            className="platformIcon"
                          />
                          {item.codeImg !== '' ? (
                            <div className="codePop">
                              <p className="codeTit" dangerouslySetInnerHTML={{ __html: item.title?.replaceHtml() }}></p>

                              <img src={createFilePath(item.codeImg)} className="codeImg" alt={item.title} />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {contact_data.map((item, index) => {
                return (
                  <div key={index} style={{ pointerEvents: 'none' }}>
                    <img src={createFilePath(item.icon_src)} className={item.title !== '' ? '' : 'weChat'} alt={item.title} />
                    <Link
                      data-action="clickAction"
                      data-item={`${item.dmp ? item.dmp : 'bottom-button-btn' + (index + 1)}`}
                      field={{ value: item.title }}
                      data-url={item.linkUrl}
                    >
                      {item.title !== '' ? (
                        <p
                          data-action="clickAction"
                          data-item={`${item.dmp ? item.dmp : 'bottom-button-btn' + (index + 1)}`}
                          data-url={item.linkUrl}
                          dangerouslySetInnerHTML={{ __html: item.title?.replaceHtml() }}
                          style={{ color: 'black' }}
                        ></p>
                      ) : (
                        ''
                      )}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="Fotter">
            <div className="FotterBtm">
              {bottom_other_link_title.map((item, index) => {
                return (
                  <Link
                    data-action="clickAction"
                    data-item={`${item.dmp ? item.dmp : 'bottom-button-btn' + fotterBtmList[index]}`}
                    data-url={item.pc_linkUrl}
                    href={item.pc_linkUrl}
                    key={index}
                    field={{ value: item.title }}
                    style={{ color: 'black' }}
                    target={item.target ? '_blank ' : ''}
                    rel={item.nofollow ? 'nofollow' : ''}
                  >
                    {item.title}
                  </Link>
                );
              })}
              <div className="FotterBottom" style={{ color: 'black' }}>
                <p style={{ color: 'black' }}>
                  {fotterbottom_data.copy}
                  <a style={{ color: 'black' }} href={fotterbottom_data.icplinkUrl} target="_blank" rel="nofollow noreferrer">
                    {fotterbottom_data.icp}
                  </a>
                </p>
                <a style={{ color: 'black' }} href={fotterbottom_data.weblinkUrl} target="_blank" rel="nofollow noreferrer">
                  {fotterbottom_data.web}
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="Fotter-homePage-mobile">
          <div className="platformList" style={{ pointerEvents: 'all' }}>
            <p className="platformTit" style={{ display: platform_data.length > 0 ? 'block' : 'none' }}>
              官方平台
            </p>
            {platform_data.map((item, index) => {
              return (
                <div key={index} className="platformItem">
                  {item.herf_url !== '' ? (
                    <Link
                      className="itemLink"
                      href={item.herf_url}
                      field={{ value: item.title }}
                      target={item.target ? '_blank ' : ''}
                      rel={item.nofollow ? 'nofollow' : ''}
                    >
                      <img
                        data-action="clickAction"
                        data-item={item.dmpValue}
                        data-url={item.herf_url}
                        src={createFilePath(item.icon_src)}
                        className="platformIcon"
                        alt={item.title}
                      />
                    </Link>
                  ) : (
                    <div className="codeLayout">
                      <img
                        data-action="clickAction"
                        data-item={item.dmpValue}
                        src={createFilePath(item.icon_src)}
                        className="platformIcon"
                        onClick={() => {
                          setCodeNum(index);
                        }}
                        alt={item.title}
                      />
                      {codeNum === index && item.codeImg !== '' ? (
                        <div className="codePop">
                          <div className="mask"></div>
                          <div className="codeBox">
                            <img
                              src={createFilePath('/common/footer/v1/closeBtn.png')}
                              className="colseImg"
                              data-item={`bottom-button-close${index + 1}`}
                              onClick={() => {
                                setCodeNum(-1);
                              }}
                              alt={item.title}
                            />
                            <p className="codeTit" dangerouslySetInnerHTML={{ __html: item.title?.replaceHtml() }}></p>

                            <img src={createFilePath(item.codeImg)} className="codeImg" alt={item.title} />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="mobileList backBg">
            {top_left_data.map((item, index) => {
              return (
                <div key={index} className="mobileFirst">
                  <div
                    onClick={() => {
                      if (num === index) {
                        window.Dmp.clickAction(item.dmp ? item.dmp : fotterTopLeftHeadList[index], '', '');
                        setNum(-1);
                      } else {
                        setNum(index);
                        window.Dmp.clickAction(item.dmp ? item.dmp : fotterTopLeftHeadList[index], '', '');
                      }
                    }}
                  >
                    <p href={item.linkUrl} field={{ value: item.title }} style={{ color: 'black' }}>
                      {item.title}
                    </p>
                    <img
                      alt="广汽本田"
                      src={createFilePath(
                        num === index ? '/common/header/v1/blackStytle/topArror.png' : '/common/header/v1/blackStytle/bottomArror.png'
                      )}
                    />
                  </div>
                  {num === index && index === 0 ? (
                    <div className="mobileItem">
                      {item.listItem.map((ite, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            {ite.title === '广汽本田商城' ? (
                              <a
                                onClick={() => {
                                  GotoMall(ite.linkUrl, ite.dmp, isMobile);
                                }}
                              >
                                {ite.title}
                              </a>
                            ) : (
                              <Link
                                data-action="clickAction"
                                data-item={ite.dmp}
                                data-url={ite.linkUrl}
                                field={{ value: ite.title }}
                                href={ite.linkUrl}
                                target={ite.target ? '_blank' : ''}
                                rel={ite.nofollow ? 'nofollow' : ''}
                              >
                                {ite.title}
                              </Link>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  ) : num === index && index !== 0 ? (
                    index === 1 ? (
                      <div className="mobileItem">
                        {item.listItem.map((ite, idx) => {
                          return (
                            <React.Fragment key={idx === 2 ? 7 : idx < 2 ? idx + 1 : idx}>
                              {ite.title === '广汽本田商城' ? (
                                <a
                                  onClick={() => {
                                    GotoMall(
                                      ite.linkUrl,
                                      ite.dmp ? ite.dmp : fotterTopLeftHeadList[index] + (idx === 2 ? 7 : idx < 2 ? idx + 1 : idx),
                                      isMobile
                                    );
                                  }}
                                >
                                  {ite.title}
                                </a>
                              ) : (
                                <Link
                                  data-action="clickAction"
                                  data-item={`${ite.dmp ? ite.dmp : fotterTopLeftHeadList[index] + (idx === 2 ? 7 : idx < 2 ? idx + 1 : idx)}`}
                                  data-url={ite.linkUrl}
                                  field={{ value: ite.title }}
                                  href={ite.linkUrl}
                                  target={ite.target ? '_blank' : ''}
                                  rel={ite.nofollow ? 'nofollow' : ''}
                                >
                                  {ite.title}
                                </Link>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="mobileItem">
                        {item.listItem.map((ite, idx) => {
                          return (
                            <React.Fragment key={idx}>
                              {ite.title === '广汽本田商城' ? (
                                <a
                                  onClick={() => {
                                    GotoMall(ite.linkUrl, ite.dmp ? ite.dmp : fotterTopLeftHeadList[index] + (idx + 1), isMobile);
                                  }}
                                >
                                  {ite.title}
                                </a>
                              ) : (
                                <Link
                                  data-action="clickAction"
                                  data-item={`${ite.dmp ? ite.dmp : fotterTopLeftHeadList[index] + (idx + 1)}`}
                                  data-url={ite.linkUrl}
                                  field={{ value: ite.title }}
                                  href={ite.linkUrl}
                                  target={ite.target ? '_blank' : ''}
                                  rel={ite.nofollow ? 'nofollow' : ''}
                                >
                                  {ite.title}
                                </Link>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </div>
              );
            })}
          </div>
          <div className="Fotter">
            <div className="FotterBtm">
              {bottom_other_link_title.map((item, index) => {
                return (
                  <Link
                    data-action="clickAction"
                    data-item={`${item.dmp ? item.dmp : 'bottom-button-btn' + fotterBtmList[index]}`}
                    data-url={item.pc_linkUrl}
                    href={item.pc_linkUrl}
                    key={index}
                    field={{ value: item.title }}
                    style={{ color: 'black' }}
                    target={item.target ? '_blank ' : ''}
                    rel={item.nofollow ? 'nofollow' : ''}
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
          </div>
          <a className="mobileText" href={fotterbottom_data.icplinkUrl} target="_blank" rel="nofollow noreferrer">
            <p style={{ color: 'black' }}>{fotterbottom_data.copy}</p>
            <p style={{ color: 'rgb(153,153,153)' }}>{fotterbottom_data.icp}</p>
          </a>
        </div>
      )}
    </div>
  );
}
