import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { isMobileFun } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';
import Home_v1 from '../Home_v1';
import './style.scss';

export default function Banner(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const [tabIndex, setTabIndex] = useState(0);
  const id = props?.params?.pagename;

  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };
  // tabs
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      fields?.tabs.map((item, index) => {
        let tabArr = item.fields?.tabname?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(fields.tabsdefault?.value?.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabIndex(tabInd < 0 ? (defaultTabInd < 0 ? tabIndex : defaultTabInd) : tabInd);
      } else {
        setTabIndex(defaultTabInd < 0 ? tabIndex : defaultTabInd);
      }
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="HomeTabs_v1" id={id}>
      <Home_v1 fields={fields?.tabs[tabIndex]?.fields}></Home_v1>
    </div>
  );
}
