import React, { useEffect, useRef, useState, useImperativeHandle, createRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import { Swiper } from 'swiper';
import { Navigation } from 'swiper';
import './style.scss';
import temp from '../../temp/config';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { fields, eventRef, params } = props;
  const containerRef = useRef(null);
  const swiperRef = useRef(null);
  // const deployUrlS3 = temp.app.deployUrlS3;
  const isMobile = isMobileFun();
  const list = fields[getPrefixField('list', isMobile)];
  const [data, _] = useState({ isMobile, list });
  const swiperSlideRefList = data.list.map(() => createRef());

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields?.sectionDmp);
    // 二级导航高亮设置
    window.navigationIndex = fields?.navigationIndex?.value;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'event_display';
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const swiper = new Swiper(swiperRef.current, {
      grabCursor: true,
      modules: [Navigation],
      slidesPerView: data.isMobile ? 1 : 5,
      navigation: {
        nextEl: '.swiper-button-n',
        prevEl: '.swiper-button-p'
      }
    });

    return () => {
      swiper.destroy(true, true);
    };
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const controller = new ScrollMagic.Controller();
    const timeline = gsap.timeline({ paused: true });

    const sceneTimline = gsap.timeline({
      paused: true,
      onComplete: () => {
        timeline.kill();
      },
      onReverseComplete: () => {
        timeline.kill();
      }
    });

    if (data.isMobile) {
      sceneTimline.fromTo(swiperRef.current, { y: '100vh' }, { y: '0', duration: 1 });
    } else {
      sceneTimline
        .fromTo(swiperSlideRefList[0].current, { x: '80vw' }, { x: '0', duration: 1 })
        .fromTo(swiperSlideRefList[1].current, { x: '90vw' }, { x: '0', duration: 1 }, '<')
        .fromTo(swiperSlideRefList[2].current, { x: '100vw' }, { x: '0', duration: 1 }, '<')
        .fromTo(swiperSlideRefList[3].current, { x: '110vw' }, { x: '0', duration: 1 }, '<')
        .fromTo(swiperSlideRefList[4].current, { x: '120vw' }, { x: '0', duration: 1 }, '<')
        .fromTo(swiperSlideRefList[5].current, { x: '130vw' }, { x: '0', duration: 1 }, '<');

      // for (let i = 0; i < data.list.length - 1; i++) {
      //   sceneTimline.fromTo(swiperSlideRefList[i].current, { x: 80 + 10 * i + 'vw' }, { x: '0', duration: 1 }, '<');
      // }
    }

    const sceneTimlineTemp = gsap.timeline({
      duration: 1,
      onStart: () => {
        sceneTimline.play();
      },
      onReverseComplete: () => {
        sceneTimline.reverse();
      }
    });
    timeline.add(sceneTimlineTemp);

    const scene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: data.isMobile ? 0.5 : 0.1,
      duration: document.documentElement.clientHeight * (data.isMobile ? 0.5 : 0.1)
    })
      .on('progress', function (event) {
        timeline.totalProgress(event.progress);
      })
      .setTween(timeline)
      .addTo(controller);

    return () => {
      sceneTimline.kill();
      scene.remove();
      scene.destroy();
      controller.destroy();
    };
  }, [data]);

  return (
    <section>
      <div className="eventDisplay_v1" ref={containerRef}>
        <div className="swiper-container">
          <div className="swiper-support" ref={swiperRef}>
            <div className="swiper-wrapper">
              <React.Fragment>
                {data.list &&
                  data.list.map((item, index) => {
                    return (
                      <div className="swiper-slide" key={index} ref={swiperSlideRefList[index]}>
                        <img src={createFilePath(item.fields.img.value)} alt="广汽本田" />
                        <div className="content">
                          <div className="text" dangerouslySetInnerHTML={{ __html: item.fields.text?.value?.replaceHtml() }} />
                          <button
                            onClick={() => {
                              sendDmp(item.fields.btnDmp, item.fields.btn_href.value);
                              window.open(item.fields.btn_href.value, '_self');
                            }}
                          >
                            了解更多&nbsp;&nbsp;{'>'}
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </React.Fragment>
            </div>
          </div>
          <div
            className="swiper-button-prev swiper-button-p"
            onClick={() => {
              sendDmp(fields.prev_dmp);
            }}
          />
          <div
            className="swiper-button-next swiper-button-n"
            onClick={() => {
              sendDmp(fields.next_dmp);
            }}
          />
        </div>
      </div>
    </section>
  );
}
