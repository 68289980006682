/* eslint-disable no-eval */
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import './style.scss';
import { getPrefixField, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import { unset } from 'lodash/object';
import temp from '../../temp/config';

export default function index({ fields, eventRef, params }) {
  const isMobile = isMobileFun();
  const sectionDmp = fields?.sectionDmp;
  const navigationIndex = fields?.navigationIndex?.value;
  const containerRef = useRef(null);
  const showRef = useRef(null);
  const extendRef = useRef(null);
  const textRef = useRef(null);
  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(sectionDmp);
    window.navigationIndex = navigationIndex;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'price_space';
  };

  useEffect(() => {
    const controller = new ScrollMagic.Controller();
    const timeline = gsap.timeline({ paused: true }).fromTo(textRef.current, { y: 200 }, { y: 0 });
    const scene = new ScrollMagic.Scene({
      triggerElement: showRef.current,
      triggerHook: isMobile ? '0.1' : '0.2'
    })
      .setTween(timeline)
      .addTo(controller);
    return () => {
      timeline.kill();
      scene.remove();
      scene.destroy();
      controller.destroy();
    };
  }, []);

  const showExtend = (value) => {
    if (value) {
      containerRef.current.style.height = '200vh';
      extendRef.current.style.display = 'block';
      extendRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      setTimeout(() => {
        containerRef.current.style.height = '100vh';
        extendRef.current.style.display = 'none';
      }, 500);
    }
  };

  return (
    <div className="PriceSpace_v8" id={params?.pagename !== undefined ? params?.pagename : 'price_space'} ref={containerRef}>
      <div className="show" ref={showRef}>
        <img className="imgs" src={`${createFilePath(fields[getPrefixField('img', isMobile)]?.value)}`} alt="广汽本田" />
        <div
          className={`text text_${
            fields[getPrefixField('text_position', isMobile)]?.value ? fields[getPrefixField('text_position', isMobile)]?.value : 'rightTop'
          }`}
          ref={textRef}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: fields[getPrefixField('text', isMobile)]?.value?.replaceHtml()
            }}
          ></div>
          <div className={`${fields[getPrefixField('button360', isMobile)]?.value ? 'buttons' : ''}`}>
            <div
              onClick={() => {
                showExtend(true);
                sendDmp(fields?.button_dmp);
              }}
              dangerouslySetInnerHTML={{
                __html: fields[getPrefixField('button', isMobile)]?.value?.replaceHtml()
              }}
            ></div>
            <div
              onClick={() => {
                sendDmp(fields?.button360Dmp, fields?.button360link?.value);
                window.open(fields?.button360link?.value);
              }}
              dangerouslySetInnerHTML={{
                __html: fields[getPrefixField('button360', isMobile)]?.value?.replaceHtml()
              }}
            ></div>
          </div>
        </div>
      </div>

      <div className="extend" ref={extendRef}>
        <div className={`lists list_${fields[getPrefixField('lists', isMobile)]?.length}`}>
          {fields[getPrefixField('lists', isMobile)]?.map((item, index) => {
            return (
              <div key={index} className={`item item_${index}`}>
                <img className="imgs" src={`${createFilePath(item?.fields?.img?.value)}`} alt="广汽本田" />
                <div
                  className="name"
                  dangerouslySetInnerHTML={{
                    __html: item?.fields?.name.value?.replaceHtml()
                  }}
                ></div>
              </div>
            );
          })}
        </div>
        <div
          className="close"
          style={{
            background: `url(${createFilePath(fields[getPrefixField('close_img', isMobile)]?.value)}) no-repeat center`,
            backgroundSize: '100% 100%'
          }}
          onClick={() => {
            sendDmp(fields?.close_dmp);
            showExtend(false);
          }}
        ></div>
      </div>
    </div>
  );
}
