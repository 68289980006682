import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, getQueryString, isInView, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { useSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import temp from '../../temp/config';
import './style.scss';
import gsap from 'gsap';

export default function index(props) {
  const { fields } = props;
  const jsonData = JSON.parse(fields?.jsonData?.value);
  // const jsonData = {
  //   "title":
  //     "<div class=\"t\"><img alt="广汽本田" src=\"${dm-media}/avancier/v2/pc/tech/logo.png\"></div><div class=\"sub\">更广域的感应范围，洞悉潜在威胁，让旅程与危险划清界限。</div>",
  //   "car": "/avancier/v2/pc/tech/car.png",
  //   "light1": "/avancier/v2/pc/tech/light.png",
  //   "light2": "/avancier/v2/pc/tech/light2.png",
  //   "light3": "/avancier/v2/pc/tech/light3.png",
  //   "bg": "/avancier/v2/pc/tech/tech1.jpg",
  //   "text": "<div class=\"li\"><div class=\"en\">ACC</div><div class=\"cn\">主动巡航控制系统<br>(带LSF低速跟随功能)</div></div><div class=\"li\"><div class=\"en\">CMBS</div><div class=\"cn\">碰撞缓解制动系统</div></div><div class=\"li\"><div class=\"en\">LWC</div><div class=\"cn\">盲点显示系统</div></div><div class=\"li\"><div class=\"en\">RDM</div><div class=\"cn\">车道偏移抑制系统</div></div><div class=\"li\"><div class=\"en\">LKAS</div><div class=\"cn\">车道保持辅助系统</div></div><div class=\"li\"><div class=\"en\">PSS</div><div class=\"cn\">泊车雷达系统<br>(前4后4式8探头)</div></div><div class=\"li\"><div class=\"en\">TSR</div><div class=\"cn\">交通标识智能识别系统</div></div><div class=\"li\"><div class=\"en\">MVC</div><div class=\"cn\">360° 全景影像系统</div></div><div class=\"li\"><div class=\"en\">CTM</div><div class=\"cn\">后视动态提醒系统</div></div><div class=\"li\"><div class=\"en\">PA</div><div class=\"cn\">自动泊车辅助系统</div></div><div class=\"li\"><div class=\"en\">FCW</div><div class=\"cn\">前方碰撞预警系统</div></div>",
  //   "m_title":
  //     "<div class=\"t\"><img alt="广汽本田" src=\"${dm-media}/avancier/v2/mobile/tech/logo.png\"></div><div class=\"sub\">更广域的感应范围，洞悉潜在威胁，<br>让旅程与危险划清界限。</div>",
  //   "m_car": "",
  //   "m_light1": "/avancier/v2/pc/tech/light.png",
  //   "m_light2": "/avancier/v2/pc/tech/light2.png",
  //   "m_light3": "/avancier/v2/pc/tech/light3.png",
  //   "m_bg": "/avancier/v2/mobile/tech/tech1.jpg",
  //   "m_text":
  //     "<div class=\"li\"><div class=\"en\">ACC</div><div class=\"cn\">主动巡航控制系统<br>(带LSF低速跟随功能)</div></div><div class=\"li\"><div class=\"en\">CMBS</div><div class=\"cn\">碰撞缓解制动系统</div></div><div class=\"li\"><div class=\"en\">LWC</div><div class=\"cn\">盲点显示系统</div></div><div class=\"li\"><div class=\"en\">RDM</div><div class=\"cn\">车道偏移抑制系统</div></div><div class=\"li\"><div class=\"en\">LKAS</div><div class=\"cn\">车道保持辅助系统</div></div><div class=\"li\"><div class=\"en\">PSS</div><div class=\"cn\">泊车雷达系统<br>(前4后4式8探头)</div></div><div class=\"li\"><div class=\"en\">TSR</div><div class=\"cn\">交通标识智能识别系统</div></div><div class=\"li\"><div class=\"en\">MVC</div><div class=\"cn\">360° 全景影像系统</div></div><div class=\"li\"><div class=\"en\">CTM</div><div class=\"cn\">后视动态提醒系统</div></div><div class=\"li\"><div class=\"en\">PA</div><div class=\"cn\">自动泊车辅助系统</div></div><div class=\"li\"><div class=\"en\">FCW</div><div class=\"cn\">前方碰撞预警系统</div></div>",
  //   "placeHolderRoute": "jss-tech"
  // }
  const isMobile = isMobileFun();
  const [tabswiperIndex, setTabswiperIndex] = useState(0);
  const id = props?.params?.pagename ? props?.params?.pagename : 'price_exterior';
  const [paused, setPaused] = useState(true);
  const rootEl = useRef(null);
  const carEl = useRef(null);
  const light1El = useRef(null);
  const light2El = useRef(null);
  const light3El = useRef(null);
  const titleEl = useRef(null);
  const textEl = useRef(null);
  const btnEl = useRef(null);
  const secondPageRef = useRef(null);
  const secondPageEventRef = useRef(null);
  const route = useSitecoreContext()?.sitecoreContext?.route;
  useEffect(() => {
    const scroll = () => {
      if (rootEl.current) {
        setPaused(!isInView(rootEl));
      }
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  useEffect(() => {
    const timeline = gsap.timeline({
      duration: 1,
      onComplete: () => {
        timeline.kill();
      }
    });
    if (paused) {
      if (isMobile) {
        timeline
          .to(textEl?.current, { opacity: 0 })
          .to(btnEl?.current, { opacity: 0 })
          .to(titleEl?.current, { opacity: 0 })
          .to(light3El?.current, { opacity: 0 });
      } else {
        timeline
          .to(textEl?.current, { opacity: 0 })
          .to(btnEl?.current, { opacity: 0 })
          .to(titleEl?.current, { opacity: 0 })
          .to(light3El?.current, { opacity: 0 });
      }
    } else {
      if (isMobile) {
        timeline
          .fromTo(titleEl?.current, 0.4, { y: '-200%', opacity: 0 }, { y: 0, opacity: 1 }, '<0.1')
          .fromTo(textEl?.current, 0.4, { opacity: 0 }, { opacity: 1 }, '<0.4')
          .fromTo(btnEl?.current, 0.3, { opacity: 0 }, { opacity: 1 }, '<0.45')
          .fromTo(light3El?.current, 0.6, { opacity: 0 }, { opacity: 1 }, '<0.8');
      } else {
        timeline
          .fromTo(titleEl?.current, 0.6, { y: '-200%', opacity: 0 }, { y: 0, opacity: 1 }, '<0.1')
          .fromTo(textEl?.current, 0.6, { opacity: 0 }, { opacity: 1 }, '<0.4')
          .fromTo(btnEl?.current, 0.3, { opacity: 0 }, { opacity: 1 }, '<0.45')
          .fromTo(light3El?.current, 0.8, { opacity: 0 }, { opacity: 1 }, '<0.6');
      }
    }

    return () => {
      timeline.kill();
    };
  }, [paused]);
  useEffect(() => {
    if ('jss-tech') {
      props.updateRefList([sendFirstDmp, sendSecondDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current]);
    } else {
      props.updateRefList([sendFirstDmp]);
      props.updateDmpList([rootEl?.current]);
    }
  }, []);
  const sendFirstDmp = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id;
  };

  const sendSecondDmp = () => {
    secondPageEventRef?.current?.sendEvent();
  };
  return (
    <div>
      <div className="price-teach-v1" id={id} ref={rootEl}>
        <div className="text" ref={titleEl} dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('title', isMobile)].replaceHtml() }}></div>
        {!isMobile && (
          <>
            <div className="car" ref={carEl}>
              <img alt="广汽本田" src={`${createFilePath(jsonData[getPrefixField('car', isMobile)])}`} />
            </div>
          </>
        )}
        <div className="light">
          <div className="wave" ref={light3El}>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>
        </div>

        <div className="ul" ref={textEl} dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('text', isMobile)].replaceHtml() }}></div>
        <div className="btn_out" ref={btnEl} dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('btn', isMobile)].replaceHtml() }}></div>
        <div className="bg">
          <img alt="广汽本田" src={`${createFilePath(jsonData[getPrefixField('bg', isMobile)])}`} />
        </div>
      </div>
      {jsonData?.placeHolderRoute && (
        <div ref={secondPageRef}>
          <Placeholder name={jsonData?.placeHolderRoute} rendering={route} page={route} eventRef={secondPageEventRef} />
        </div>
      )}
    </div>
  );
}
