import React, { useEffect, useRef, createRef, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import { getPrefixField, convertStylesStringToObject, createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields, scrollElement }) {
  const containerRef = useRef(null);
  const imageContainerRef = useRef(null);
  const imageRef = useRef(null);
  const textRef = useRef(null);
  const isMobile = isMobileFun();
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const description = fields[getPrefixField('description', isMobile)]?.value;
  const image = createFilePath(fields[getPrefixField('image', isMobile)]?.value);
  const replaceList = fields[getPrefixField('replace_list', isMobile)];
  const reverse = fields[getPrefixField('reverse', isMobile)]?.value;
  const fullscreen = fields[getPrefixField('fullscreen', isMobile)]?.value;
  const replaceImageRefList = replaceList.map(() => createRef());
  const replaceTextRefList = replaceList.map(() => createRef());
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const disableAnimate = fields[getPrefixField('disableAnimate', isMobile)]?.value;
  const data = {
    title,
    description,
    image,
    replaceList,
    reverse,
    fullscreen,
    replaceImageRefList,
    replaceTextRefList,
    style,
    disableAnimate,
    isMobile
  };

  useEffect(() => {
    if (data?.disableAnimate) {
      return;
    }

    const duration = document.documentElement.clientHeight;
    const timelineList = [];
    const sceneList = [];

    // main
    const timeline = gsap.timeline({ paused: true }).from(imageContainerRef.current, { x: `${data?.reverse ? '' : '-'}100%` });
    if (data?.isMobile) {
      timeline.fromTo(imageRef.current, { scale: 1.3 }, { scale: 1.0 }, '<30%');
      if (!data?.fullscreen) {
        timeline.fromTo(textRef.current, { opacity: 0 }, { opacity: 1 }, '<');
      }
    }
    const controller = new ScrollMagic.Controller({ container: scrollElement || window });
    const scene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: data?.isMobile ? '0.7' : 'onEnter',
      duration: data?.isMobile ? duration * 0.7 : duration
    })
      .setTween(timeline)
      .addTo(controller);
    timelineList.push(timeline);
    sceneList.push(scene);

    // replace
    const replaceTimeline = gsap.timeline({ paused: true });
    if (!data?.isMobile) {
      replaceTimeline.fromTo(imageRef.current, { scale: 1.3 }, { scale: 1.0 });
      if (!data?.fullscreen) {
        replaceTimeline.fromTo(textRef.current, { opacity: 0 }, { opacity: 1 }, 0);
      }
    }
    data?.replaceList.forEach((_, index) => {
      if (!data?.isMobile && !data?.fullscreen) {
        replaceTimeline
          .to(index === 0 ? textRef.current : data?.replaceTextRefList[index - 1].current, { opacity: 0, y: -duration / 2 }, `p${index}`)
          .fromTo(data?.replaceTextRefList[index].current, { opacity: 0, y: (duration / 3) * 2 }, { opacity: 1, y: 0 }, `p${index}`);
      }
      replaceTimeline.from(
        data?.replaceImageRefList[index].current,
        {
          y: '100%',
          onStart: () => {
            data?.replaceImageRefList?.forEach((item) => {
              item.current.style.display = 'block';
            });
          },
          onReverseComplete: () => {
            data?.replaceImageRefList?.forEach((item) => {
              item.current.style.display = 'none';
            });
          }
        },
        `p${index}`
      );
      if (data?.isMobile && !data?.fullscreen) {
        replaceTimeline
          .to(index === 0 ? textRef.current : data?.replaceTextRefList[index - 1].current, { opacity: 0 }, '<')
          .fromTo(data?.replaceTextRefList[index].current, { opacity: 0 }, { opacity: 1 }, '<70%');
      }
    });
    const replaceScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onLeave',
      duration
    })
      .setPin(containerRef.current)
      .setTween(replaceTimeline)
      .addTo(controller);
    timelineList.push(replaceTimeline);
    sceneList.push(replaceScene);

    return () => {
      timelineList.forEach((item) => item.kill());
      sceneList.forEach((item) => {
        item.remove();
        item.destroy();
      });

      controller.destroy();
    };
  }, []);

  const getImageContainer = () => {
    return (
      <div className="image-container" ref={imageContainerRef}>
        <img alt="广汽本田" className="image" src={createFilePath(data?.image)} ref={imageRef} />
        {data?.replaceList?.map((item, index) => (
          <img
            alt="广汽本田"
            key={index}
            className="replace-image"
            src={createFilePath(item?.fields?.image?.value)}
            ref={data?.replaceImageRefList[index]}
          />
        ))}
      </div>
    );
  };

  const getContentContainer = () => {
    return (
      <React.Fragment>
        {!data?.fullscreen && (
          <div className="content-container">
            <div className="text-container" ref={textRef}>
              <div className="title" dangerouslySetInnerHTML={{ __html: data?.title?.replaceHtml() }} />
              <div className="description" dangerouslySetInnerHTML={{ __html: data?.description?.replaceHtml() }} />
            </div>
            {data?.replaceList?.map((item, index) => (
              <div key={index} className="text-container" ref={data?.replaceTextRefList[index]}>
                <div className="title" dangerouslySetInnerHTML={{ __html: item?.fields?.title?.value?.replaceHtml() }} />
                <div className="description" dangerouslySetInnerHTML={{ __html: item?.fields?.description?.value?.replaceHtml() }} />
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <section style={data?.style}>
      {data ? (
        <div
          className={`image-replace ${data?.reverse && !data?.isMobile ? 'image-replace-reverse' : ''} ${
            data?.fullscreen ? 'image-replace-fullscreen' : ''
          }`}
          ref={containerRef}
        >
          {data?.reverse && !data?.isMobile ? (
            <React.Fragment>
              {getContentContainer()}
              {getImageContainer()}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getImageContainer()}
              {getContentContainer()}
            </React.Fragment>
          )}
        </div>
      ) : null}
    </section>
  );
}
