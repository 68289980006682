/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';
import { createFilePath, getQueryString, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields }) {
  const history = useHistory();
  const isMobile = isMobileFun();
  const [data, setData] = useState(null);
  const [isSub, setIsSub] = useState(false);
  const [view, setView] = useState(null);
  var Cookie = {
    set: function (key, val) {
      document.cookie = key + '=' + escape(val) + ';';
    },
    get: function (key) {
      var getCookie = document.cookie.replace(/[ ]/g, '');
      var resArr = getCookie.split(';');
      var res;
      for (var i = 0, len = resArr.length; i < len; i++) {
        var arr = resArr[i].split('=');
        if (arr[0] === key) {
          res = arr[1];
          break;
        }
      }
      return unescape(res);
    }
  };
  useEffect(() => {
    getData();
    setIsSub(location?.pathname?.indexOf('sub-detail') !== -1);
    // if (getQueryString('itempath') !== '2019-0429' || getQueryString('itempath') !== '2018-Q4') {
    //   PageVisitInfo();
    // }
  }, []);

  useEffect(() => {
    if (data) {
      document.title = isSub ? data.title : data.a_title;
      document.querySelector('meta[name="keywords"]').setAttribute('content', document.title);
      document.querySelector('meta[name="description"]').setAttribute('content', document.title);
    }
  }, [data, isSub]);

  const getData = () => {
    dataFetcher(`/api/honda/v1/Article/GetArticleDetails?itempath=${getQueryString('itempath') || ''}&articleType=5`)
      .then(({ data: response }) => {
        if (response.Status && response?.Data) {
          setData({ ...response?.Data });
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
        history.replace('/no-find');
      });
  };
  const PageVisitInfo = () => {
    if (Cookie.get('Visit_COOKIE') && Cookie.get('Visit_COOKIE') !== 'undefined') {
      var lists = Cookie.get('Visit_COOKIE').split(',');
      var bool = false;
      for (var i = 0; i < lists.length; i++) {
        if (lists[i] === encodeURIComponent(location.href)) {
          bool = true;
        }
      }
      if (bool) {
        GetPageVisitInfo();
      } else {
        AddPageVisitInfo();
      }
      GetPageVisitInfo();
    } else {
      AddPageVisitInfo();
    }
  };
  const AddPageVisitInfo = () => {
    const data = {
      pageUrl: location.href,
      pageType: 1,
      userId: '',
      userName: ''
    };
    dataFetcher('/api/honda/v1/Pages/AddPageVisitInfo', data)
      .then(({ data: response }) => {
        if (response.Status && response?.Data) {
          var Visit_COOKIE = Cookie.get('Visit_COOKIE');
          if (Visit_COOKIE && Visit_COOKIE !== 'undefined') {
            var Visit_COOKIE_list = Visit_COOKIE.split(',');
            Visit_COOKIE_list.push(encodeURIComponent(location.href));
            Cookie.set('Visit_COOKIE', Visit_COOKIE_list.join(','));
          } else {
            Cookie.set('Visit_COOKIE', encodeURIComponent(location.href));
          }
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
        GetPageVisitInfo();
      })
      .catch((error) => {
        console.log(error);
        GetPageVisitInfo();
      });
  };
  const GetPageVisitInfo = () => {
    dataFetcher(`/api/honda/v1/Pages/GetPageVisitInfo?pageUrl=${encodeURIComponent(location.href) || ''}`)
      .then(({ data: response }) => {
        if (response.Status && response?.Data && response?.Data.length > 0) {
          setView(response?.Data[0]?.visit_count);
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const backToHome = () => {
    history.push('/');
  };

  const back = () => {
    history.goBack();
  };
  const backIndex = () => {
    history.push('/service/knowledge');
  };

  return (
    <section className="knowledge-detail-v1">
      <div className="header">
        <img alt="广汽本田" src={createFilePath('/knowledge/logo_high.png')} onClick={backToHome} />
        <button onClick={isSub ? back : backIndex}>{isSub ? <img alt="广汽本田" src={createFilePath('/knowledge/ext.png')} /> : '返回'}</button>
      </div>
      <div className="header_blank"></div>

      {/* {getQueryString('itempath') !== '2019-0429' && getQueryString('itempath') !== '2018-Q4' && view && (
        <div className="view">
          <img alt="广汽本田" className="icon" src={createFilePath('/knowledge/view_icon.png')} />
          {view || 0}
        </div>
      )} */}
      {data && (
        <div
          className={`content${isSub ? ' sub' : ''}`}
          dangerouslySetInnerHTML={{
            __html: isSub ? (isMobile ? data.a_content_mb?.replaceHtml() : data.a_content?.replaceHtml()) : data.a_intro?.replaceHtml()
          }}
        />
      )}
    </section>
  );
}
