import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import temp from '../../temp/config';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Details from './details';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import { isMobileFun, createFilePath } from '../../utils/obj-utils';
export default function index(props) {
  const isMobile = isMobileFun();
  const { sort, play_icon, download_icon, backImg, m_text_backColor, m_img, list, closeImg, selectIcon, m_leftIcon, m_rightIcon, m_downloadUrl } =
    props.fields;
  // pc
  const [pc_list, setPcList] = useState([]);
  useEffect(() => {
    if (!isMobile) {
      var pc_list = list;
      var arr = [];
      sort.value.split(',').forEach((item) => {
        arr.push(Number(item));
      });
      var arr2 = [];
      arr.forEach((item, index) => {
        arr2[index] = pc_list.splice(0, item);
      });
      setPcList(arr2);
    }
  }, []);
  const [videoUrl, setVideoUrl] = useState('');
  const [flag, setFlag] = useState(false);
  // mobile
  const [num, setNum] = useState(-1);
  const [m_list, setMlist] = useState([]);
  const changeMlist = function (val) {
    if (val === -1) {
      var arr = [];
      list.forEach((item, index) => {
        item.fields.childrenList.forEach((ite, idx) => {
          arr.push(ite);
        });
      });
      var arr1 = [];
      while (arr.length >= 6) {
        arr1.push(arr.splice(0, 6));
      }
      if (arr.length !== 0) {
        arr1.push(arr);
      }
      setMlist(arr1);
    } else {
      var arr = [];
      list[val - 1].fields.childrenList.forEach((ite, idx) => {
        arr.push(ite);
      });
      list[val].fields.childrenList.forEach((ite, idx) => {
        arr.push(ite);
      });
      var arr1 = [];
      while (arr.length >= 6) {
        arr1.push(arr.splice(0, 6));
      }
      if (arr.length !== 0) {
        arr1.push(arr);
      }
      setMlist(arr1);
    }
  };
  useEffect(() => {
    if (isMobile) {
      changeMlist(-1);
    }
  }, []);
  const [m_num, setMnum] = useState(0);
  const swiperMobileOne = useRef(null);
  const swiperMobileTwo = useRef(null);
  // 搜索数据参数
  const [f_num, setFnum] = useState(-1);
  const [s_num, setSnum] = useState(-1);
  const [t_num, setTnum] = useState('');
  const searchMlist = function (index_f, index_s, value) {
    var arr = [];
    // 第一层没有选择
    if (index_f === -1) {
      // 关键词存在否
      if (value === '') {
        // 关键词不存在
        changeMlist(-1);
      } else {
        // 关键词存在
        list.forEach((item, index) => {
          item.fields.childrenList.forEach((ite, idx) => {
            if (ite.fields.title.value.indexOf(value) !== -1) {
              arr.push(ite);
            }
          });
        });
        var arr1 = [];
        while (arr.length >= 6) {
          arr1.push(arr.splice(0, 6));
        }
        if (arr.length !== 0) {
          arr1.push(arr);
        }
        setMlist(arr1);
      }
      // 第一层选择
    } else {
      // 第二层没有选择
      if (index_s === -1) {
        // 关键词存在
        if (value !== '') {
          // 匹配关键词
          list[index_f].fields.childrenList.forEach((ite, idx) => {
            if (ite.fields.title.value.indexOf(value) !== -1) {
              arr.push(ite);
            }
          });
          // 无需匹配关键词
        } else {
          list[index_f].fields.childrenList.forEach((ite, idx) => {
            arr.push(ite);
          });
        }
        // 第二层选择
      } else {
        // 匹配关键词
        if (value !== '') {
          if (list[index_f].fields.childrenList[index_s].fields.title.value.indexOf(value) !== -1) {
            arr.push(list[index_f].fields.childrenList[index_s]);
          }
        } else {
          // 无需匹配关键词
          arr.push(list[index_f].fields.childrenList[index_s]);
        }
      }
      var arr1 = [];
      while (arr.length >= 6) {
        arr1.push(arr.splice(0, 6));
      }
      if (arr.length !== 0) {
        arr1.push(arr);
      }
      setMlist(arr1);
    }
  };
  return (
    <div
      className={`Operation_footer_v6${isMobile ? '_m' : ''}`}
      style={{ backgroundImage: !isMobile ? `url(${createFilePath(backImg.value)})` : '', backgroundColor: isMobile ? 'black' : '' }}
    >
      {flag ? <Details closeImg={closeImg.value} setFlag={setFlag} videoSrc={videoUrl}></Details> : ''}
      {!isMobile ? (
        <div>
          {pc_list.map((item, index) => {
            return (
              <div key={index}>
                {item.map((ite, idx) => {
                  return (
                    <div key={idx}>
                      <img alt="广汽本田" src={`${createFilePath(ite.fields.pc_img.value)}`} className="pc_img" />
                      <p className="pc_title">{ite.fields.title.value}</p>
                      <img
                        alt="广汽本田"
                        src={`${createFilePath(play_icon.value)}`}
                        className="pc_play_icon"
                        onClick={() => {
                          setVideoUrl(ite.fields.video.value);
                          setFlag(true);
                        }}
                      />
                      <img
                        alt="广汽本田"
                        src={`${createFilePath(download_icon.value)}`}
                        className="pc_download_icon"
                        onClick={() => {
                          window.open(ite.fields.downloadUrl.value, '_blank');
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <div
            onClick={() => {
              window.open(m_downloadUrl.value, '_self');
            }}
          >
            操作要点手册下载
            <img alt="广汽本田" src={`${createFilePath(download_icon.value)}`} />
          </div>
          <div>
            <div>
              <select
                onChange={(e) => {
                  setNum(e.target.selectedIndex - 1);
                  setFnum(e.target.selectedIndex - 1);
                  setSnum(-1);
                  document.querySelector('.select_s').childNodes[0].selected = true;
                }}
              >
                <option>请选择</option>
                {list.map((item, index) => {
                  return (
                    <option key={index} data_index={index}>
                      {item.fields.title.value}
                    </option>
                  );
                })}
              </select>
              <img alt="广汽本田" src={`${createFilePath(selectIcon.value)}`} />
            </div>
            <div>
              <select
                className="select_s"
                onChange={(e) => {
                  setSnum(e.target.selectedIndex - 1);
                }}
              >
                <option>请选择</option>
                {num >= 0
                  ? list[num].fields.childrenList.map((item, index) => {
                      return item.fields.flag.value !== true ? <option key={index}>{item.fields.title.value}</option> : '';
                    })
                  : ''}
              </select>
              <img alt="广汽本田" src={`${createFilePath(selectIcon.value)}`} />
            </div>
          </div>
          <div>
            <input
              type="text"
              placeholder="输入关键词..."
              onInput={(e) => {
                setTnum(e.target.value);
              }}
            />
            <div
              onClick={() => {
                searchMlist(f_num, s_num, t_num);
              }}
            >
              搜索
            </div>
          </div>
          <Swiper
            className="swiperOne"
            ref={swiperMobileOne}
            onSlideChange={(e) => {
              if (m_num > e.activeIndex) {
                swiperMobileTwo.current.swiper.slideTo(e.activeIndex - 1);
              } else if (m_num < e.activeIndex) {
                swiperMobileTwo.current.swiper.slideTo(e.activeIndex);
              }
              setMnum(e.activeIndex);
            }}
          >
            {m_list?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div>
                    {item.map((ite, idx) => {
                      return (
                        <div
                          key={idx}
                          onClick={() => {
                            setFlag(true);
                            setVideoUrl(ite.fields.video.value);
                          }}
                        >
                          <div className="m_img">
                            <img alt="广汽本田" src={`${createFilePath(ite.fields.m_img.value)}`} />
                            <img alt="广汽本田" src={`${createFilePath(play_icon.value)}`} className="play_icon" />
                            <div className="Mask"></div>
                          </div>
                          <div className="hui">
                            <p>{ite.fields.title.value}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {m_list.length !== 0 ? (
            <Swiper
              className="swiperTwo"
              slidesPerView={m_list.length > 5 ? 5 : m_list.length}
              ref={swiperMobileTwo}
              initialSlide={0}
              modules={[Navigation]}
              navigation={{
                prevEl: '.navigation_prev',
                nextEl: '.navigation_next',
                disabledClass: 'navigation_disabled'
              }}
              style={{ width: (m_list.length >= 5 ? 5 : m_list.length) * 12 + '%' }}
            >
              {m_list.map((item, index) => {
                return (
                  <SwiperSlide
                    className={index === m_num ? 'm_active_light' : ''}
                    key={index}
                    onClick={() => {
                      swiperMobileOne.current.swiper.slideTo(index);
                      setMnum(index);
                      if (m_num > index) {
                        swiperMobileTwo.current.swiper.slideTo(index - 1);
                      } else if (m_num < index) {
                        swiperMobileTwo.current.swiper.slideTo(index);
                      }
                    }}
                  >
                    <p>{index + 1}</p>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            ''
          )}
          {m_list.length !== 0 ? (
            <div className="navigationDiv" style={{ width: ((m_list.length >= 5 ? 5 : m_list.length) + 2) * 12 + '%' }}>
              <div className="navigation_prev">
                <img alt="广汽本田" src={`${createFilePath(m_leftIcon.value)}`} />
              </div>
              <div className="navigation_next">
                <img alt="广汽本田" src={`${createFilePath(m_rightIcon.value)}`} />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
}
