import ArrowLeft from './arrow-left';
import ArrowRight from './arrow-right';
import ArrowLeftTriangle from './arrow-left-triangle';
import ArrowRightTriangle from './arrow-right-triangle';
import ArrowLeft1 from './arrow-left-1';
import ArrowRight1 from './arrow-right-1';
import Check from './check';
import ArrowTopThin from './arrow-top-thin';
import ArrowTopDouble from './arrow-top-double';
import Close from './close';

const SvgIcons = {
  ArrowLeft,
  ArrowRight,
  ArrowLeftTriangle,
  ArrowRightTriangle,
  ArrowLeft1,
  ArrowRight1,
  ArrowTopThin,
  ArrowTopDouble,
  Check,
  Close
};
export default SvgIcons;
