import React, { memo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import './style.scss';
// eslint-disable-next-line react/display-name
const index = memo((props) => {
  const node = document.createElement('div');
  node.className = 'dialogBox';
  document.body.appendChild(node);
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.removeChild(node);
      document.body.style.overflow = 'auto';
      document.body.style.overflowX = 'hidden';
    };
  }, []);
  return createPortal(
    <div className="dialog" style={{ width: props.width }}>
      <div className="dialogContent">{props.children}</div>
    </div>,
    node
  );
});
export default index;
