import React, { useEffect, useState } from 'react';
import './style.scss';
import { getPrefixField, sendDmp, scrollToAnchor, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

// 获取num在arr里的某个区间的索引
function getTopIndex(arr, num) {
  var a = -1;
  for (var index in arr) {
    if (arr[index] > num) {
      a = index;
      break;
    }
  }
  return a;
}

export default function index(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const list = fields[getPrefixField('list', isMobile)];
  const [tabNum, setTabNum] = useState(0);
  const [data, _] = useState({ list });

  useEffect(() => {
    const scroll = () => {
      try {
        const element1Top = window.top.document.getElementById(data.list[0].fields.id.value).getBoundingClientRect().top;
        const arrTop = [];
        for (let i = 1; i < data.list.length; i++) {
          // 获取元素距离第一个元素的距离
          const anchorElement = window.top.document.getElementById(data.list[i].fields.id.value);
          const top = Math.abs(anchorElement.getBoundingClientRect().top - element1Top);
          arrTop.push(top);
        }
        if (Math.abs(element1Top) + document.documentElement.clientHeight / 2 >= arrTop[arrTop.length - 1]) {
          setTabNum(data.list.length - 1);
        } else {
          setTabNum(Number(getTopIndex(arrTop, Math.abs(element1Top))));
        }
      } catch (e) {
        const element1Top = document.getElementById(data.list[0].fields.id.value).getBoundingClientRect().top;
        const arrTop = [];
        for (let i = 1; i < data.list.length; i++) {
          // 获取元素距离第一个元素的距离
          const anchorElement = document.getElementById(data.list[i].fields.id.value);
          const top = Math.abs(anchorElement.getBoundingClientRect().top - element1Top);
          arrTop.push(top);
        }
        if (Math.abs(element1Top) + document.documentElement.clientHeight / 2 >= arrTop[arrTop.length - 1]) {
          setTabNum(data.list.length - 1);
        } else {
          setTabNum(Number(getTopIndex(arrTop, Math.abs(element1Top))));
        }
      }
    };
    window.addEventListener('scroll', scroll, true);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  return (
    <div className="navigationHeader">
      <div className="list">
        {data.list &&
          data.list.map((item, index) => {
            return (
              <button
                key={index}
                className="navigationHeaderItem"
                onClick={() => {
                  sendDmp(item.fields.btnDmp);
                  scrollToAnchor(item.fields.id?.value);
                }}
              >
                <div
                  className={index === tabNum ? 'text text_active' : 'text'}
                  dangerouslySetInnerHTML={{ __html: item.fields?.text.value?.replaceHtml() }}
                />
                {index === tabNum && <div className="line" />}
              </button>
            );
          })}
      </div>
    </div>
  );
}
