import React from 'react';
import './style.scss';
import { isEmpty, createFilePath } from '../../../../utils/obj-utils';

export default function index({ data: { fields }, className }) {
  return (
    <div className={`hero-support-item-normal ${fields?.background?.value ? 'hero-support-item-normal-background' : ''} ${className || ''}`}>
      {!isEmpty(fields?.image?.value) && <img alt="广汽本田" className="normal-image" src={createFilePath(fields?.image?.value)} />}
      <div className="normal-title" dangerouslySetInnerHTML={{ __html: fields?.title?.value?.replaceHtml() }} />
      {fields?.more?.value && <a className="normal-more" dangerouslySetInnerHTML={{ __html: fields?.more_title?.value?.replaceHtml() }} />}
    </div>
  );
}
