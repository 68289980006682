import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, pcTorem, mbTorem, getQueryString, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { useSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import { swiperAnimateCache, swiperAnimate, clearSwiperAnimate } from '../../utils/swiper.animate1.0.3.min';
import FullVideo from '../../elements/FullVideo';
import FullModalDetail from '../../elements/FullModalDetail_v1';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const rootEl = useRef(null);
  const secondPageRef = useRef(null);
  const secondPageEventRef = useRef(null);
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const [windowHeight, setWindowHeight] = useState(400);
  const swiperRef = useRef(null);
  const swiperRef2 = useRef(null);
  const [flag, setFlag] = useState(false);
  const isMobile = isMobileFun();
  // const hosturl = temp.app.deployUrlS3;
  const [showInteriorDetail, setShowInteriorDetail] = useState(false);
  const [showInteriorDetailIndex, setShowInteriorDetailIndex] = useState(1);
  const [index, setIndex] = useState(1);
  const [showFooter, setShowFooter] = useState(true);
  // if (isMobile) {
  //   var accordhosturl = hosturl + '/accord/v2/mobile';
  // } else {
  //   var accordhosturl = hosturl + '/accord/v2/pc';
  // }
  const id = props?.params?.pagename;
  const videodata = {
    detail_video: createFilePath('/accord/v2/pc/interior/video.mp4?20240701'),
    close_icon_dmp: {
      type: 'click',
      point: 'price_space-video-close',
      value1: ''
    }
  };
  const data = {
    id
  };
  useEffect(() => {
    if (fields?.placeHolderRoute?.value) {
      props.updateRefList([sendFirstDmp, sendSecondDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current]);
    } else {
      props.updateRefList([sendFirstDmp]);
      props.updateDmpList([rootEl?.current]);
    }
  }, []);
  const sendFirstDmp = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename || '';
  };

  const sendSecondDmp = () => {
    secondPageEventRef?.current?.sendEvent();
  };
  const openVideo = () => {
    window.Dmp.clickAction('price_space-video-open');
    setFlag(true);
  };
  const setShowIntex = (index, val) => {
    window.Dmp.clickAction('price_space-point' + index, val);
    setShowInteriorDetail(true);
    setShowInteriorDetailIndex(index - 1);
  };
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useEffect(() => {
    if (
      window.location.href.indexOf('accord-show') !== -1 ||
      getQueryString('source') === 'app' ||
      window.location.href.indexOf('app/vehicles/accord') !== -1
    ) {
      setShowFooter(false);
    }
  }, []);
  const windowhidden = () => {
    document.documentElement.style.overflow = 'hidden';
  };
  const closewindowhidden = () => {
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.overflowX = 'hidden';
  };
  return (
    <div>
      <div className={!showFooter ? 'modules-grid interior noheader' : 'modules-grid interior'} ref={rootEl}>
        <div className="triggerstart" id="interior"></div>
        <div>
          <Swiper
            ref={swiperRef}
            id={data?.id}
            slidesPerView={1}
            className="modules-swiper"
            spaceBetween={0}
            initialSlide={0}
            observer={true}
            resizeObserver={true}
            updateOnWindowResize={true}
            modules={[Navigation]}
            navigation={{
              prevEl: '.interior .prev',
              nextEl: '.interior .next',
              disabledClass: 'disabled'
            }}
            onInit={(e) => {
              if (!isMobile) {
                clearSwiperAnimate(e);
                swiperAnimateCache(e);
              }
            }}
            onTransitionStart={(e) => {}}
            onSlideChangeTransitionEnd={(e) => {
              if (!isMobile) {
                swiperAnimate(e);
              }
            }}
          >
            <SwiperSlide className={'griditem griditem0'}>
              <div className="bg bg1">
                <div className="control">
                  <img
                    alt="广汽本田"
                    src={
                      isMobile ? createFilePath('/accord/v2/mobile/interior/interior0b.png') : createFilePath('/accord/v2/pc/interior/interior0b.jpg')
                    }
                  />
                  <div
                    className="dot dot1"
                    onClick={() => {
                      setShowIntex(1, '座舱环境控制旋钮');
                      windowhidden();
                    }}
                  ></div>
                  <div
                    className="dot dot2"
                    onClick={() => {
                      setShowIntex(2, '大型抬头显示系统');
                      windowhidden();
                    }}
                  ></div>
                  <div
                    className="dot dot3"
                    onClick={() => {
                      setShowIntex(3, '仪表盘10.2英寸彩色TFT多功能信息显示屏');
                      windowhidden();
                    }}
                  ></div>
                  <div
                    className="dot dot4"
                    onClick={() => {
                      setShowIntex(4, '12.3 英寸彩色智能互联屏系统');
                      windowhidden();
                    }}
                  ></div>
                  <div
                    className="dot dot5"
                    onClick={() => {
                      setShowIntex(5, '多色智能氛围灯');
                      windowhidden();
                    }}
                  ></div>
                  <div
                    className="dot dot6"
                    onClick={() => {
                      setShowIntex(6, 'BOSE豪华音响系统');
                      windowhidden();
                    }}
                  ></div>
                  <div
                    className="dot dot7"
                    onClick={() => {
                      setShowIntex(7, '配备无线充电及TYPE-C快充口');
                      windowhidden();
                    }}
                  ></div>
                  <div
                    className="dot dot8"
                    onClick={() => {
                      setShowIntex(8, '豪华仿麂皮座椅');
                      windowhidden();
                    }}
                  ></div>
                </div>
              </div>

              <div className="text">
                <div className="t ani" swiper-animate-effect="animate__fadeInDownBig" swiper-animate-duration="0.4s" swiper-animate-delay="0s">
                  <span>智座</span>智能座舱科技
                </div>
                <div className="sub ani" swiper-animate-effect="animate__fadeInUpBig" swiper-animate-duration="0.5s" swiper-animate-delay="0s">
                  <div className="en">AN IMAGINATIVE ADVENTURE</div>
                  <div className="cn">一段漫游疯狂想象的心灵体验</div>
                </div>
                {!isMobile ? (
                  <div
                    className="videomin1 ani"
                    swiper-animate-effect="animate__fadeInDownBig"
                    swiper-animate-duration="0.6s"
                    swiper-animate-delay="0s"
                    onClick={() => {
                      openVideo();
                    }}
                  >
                    <img alt="广汽本田" src={createFilePath('/accord/v2/pc/interior/video.gif')} className="video" />
                  </div>
                ) : (
                  <div
                    className="btn"
                    onClick={() => {
                      openVideo(true);
                    }}
                  >
                    查看视频
                  </div>
                )}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {showInteriorDetail && (
        <>
          <div className="Detailpage">
            <Swiper
              ref={swiperRef2}
              slidesPerView={1}
              spaceBetween={0}
              initialSlide={showInteriorDetailIndex}
              observer={true}
              resizeObserver={true}
              updateOnWindowResize={true}
              modules={[Navigation]}
              id={data?.id}
              navigation={{
                prevEl: '.Detailpage .prev',
                nextEl: '.Detailpage .next',
                disabledClass: 'disabled'
              }}
              onInit={(e) => {}}
              onTransitionStart={(e) => {}}
              onSlideChangeTransitionEnd={(e) => {
                setIndex(e.activeIndex);
              }}
            >
              <SwiperSlide className="Ditem1">
                <div
                  className="close"
                  onClick={() => {
                    setShowInteriorDetail(false);
                    closewindowhidden();
                    window.Dmp.clickAction('price_space-pclose1', '座舱环境控制旋钮');
                  }}
                ></div>
                <div className="detail-left">
                  {/* <img alt="广汽本田" className="img" src={accordhosturl + '/interior/interior1.jpg'} /> */}
                  {/* {!isMobile ? <img alt="广汽本田" className="icon" src={accordhosturl + '/interior/interior1_img.gif'} /> : ''} */}
                  <img
                    alt="广汽本田"
                    className="img"
                    src={
                      isMobile ? createFilePath('/accord/v2/mobile/interior/interior1.jpg') : createFilePath('/accord/v2/pc/interior/interior1.jpg')
                    }
                  />
                  {!isMobile ? <img alt="广汽本田" className="icon" src={createFilePath('/accord/v2/pc/interior/interior1_img.gif')} /> : ''}
                </div>
                <div className="detail-right">
                  <div className="t">座舱环境控制旋钮</div>
                  <div className="p">以“One Shot”为设计理念，将车内功能（空调、音响、照明）高度集中，一个旋钮即能满足所有需求。</div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="Ditem2">
                <div
                  className="close"
                  onClick={() => {
                    setShowInteriorDetail(false);
                    closewindowhidden();
                    window.Dmp.clickAction('price_space-pclose2', '大型抬头显示系统');
                  }}
                ></div>
                <div className="detail-left">
                  {/* <img alt="广汽本田" className="img" src={accordhosturl + '/interior/interior2.jpg'} /> */}
                  <img
                    alt="广汽本田"
                    className="img"
                    src={
                      isMobile ? createFilePath('/accord/v2/mobile/interior/interior2.jpg') : createFilePath('/accord/v2/pc/interior/interior2.jpg')
                    }
                  />
                </div>
                <div className="detail-right">
                  <div className="t">大型抬头显示系统</div>
                  <div className="p">11.5英寸显示尺寸，有助于驾驶者轻松读取车辆和驾驶辅助的简洁信息（来电信息/导航操作/辅助驾驶状态）</div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="Ditem3">
                <div
                  className="close"
                  onClick={() => {
                    setShowInteriorDetail(false);
                    closewindowhidden();
                    window.Dmp.clickAction('price_space-pclose3', '仪表盘10.2英寸彩色TFT多功能信息显示屏');
                  }}
                ></div>
                <div className="detail-left">
                  {/* <img alt="广汽本田" className="img" src={accordhosturl + '/interior/interior3.jpg'} /> */}
                  <img
                    alt="广汽本田"
                    className="img"
                    src={
                      isMobile ? createFilePath('/accord/v2/mobile/interior/interior3.jpg') : createFilePath('/accord/v2/pc/interior/interior3.jpg')
                    }
                  />
                </div>
                <div className="detail-right">
                  <div className="t">
                    仪表盘10.2英寸
                    <br />
                    彩色TFT多功能信息显示屏
                  </div>
                  <div className="p">简洁大气全液晶仪表盘，显示车辆信息和驾驶辅助信息，可通过方向盘按键进行信息的随意切换。</div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="Ditem4">
                <div
                  className="close"
                  onClick={() => {
                    setShowInteriorDetail(false);
                    closewindowhidden();
                    window.Dmp.clickAction('price_space-pclose4', '12.3 英寸彩色智能互联屏系统');
                  }}
                ></div>
                <div className="detail-left">
                  {/* <img alt="广汽本田" className="img" src={accordhosturl + '/interior/interior4.jpg'} />
                   */}
                  <img
                    alt="广汽本田"
                    className="img"
                    src={
                      isMobile ? createFilePath('/accord/v2/mobile/interior/interior4.jpg') : createFilePath('/accord/v2/pc/interior/interior4.jpg')
                    }
                  />
                </div>
                <div className="detail-right">
                  <div className="t">
                    12.3 英寸
                    <br />
                    彩色智能互联屏系统
                  </div>
                  <div className="p">全系搭载Honda首发12.3英寸彩色智能互联屏系统，智能手机般的窗口使用部件、3D显示效果，让信息传递更加清晰易懂。</div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="Ditem5">
                <div
                  className="close"
                  onClick={() => {
                    setShowInteriorDetail(false);
                    closewindowhidden();
                    window.Dmp.clickAction('price_space-pclose5', '多色智能氛围灯');
                  }}
                ></div>
                <div className="detail-left">
                  {/* <img alt="广汽本田" className="img" src={accordhosturl + '/interior/interior5.jpg'} /> */}
                  <img
                    alt="广汽本田"
                    className="img"
                    src={
                      isMobile ? createFilePath('/accord/v2/mobile/interior/interior5.jpg') : createFilePath('/accord/v2/pc/interior/interior5.jpg')
                    }
                  />
                  <div className="sub">
                    <div className="en">THE COLORS OF ALL SEASONS ARE ILLUMINATING </div>
                    <div className="cn">让四季的色彩 在这里灵动</div>
                  </div>
                </div>
                <div className="detail-right">
                  <div className="t">多色智能氛围灯</div>
                  <div className="p">
                    通过DMC驾驶员状态感知系统检测驾驶者表情从而演绎不同色彩方案，同时联动语音识别操作、车内温度变化、运动模式切换等控制颜色变化，让出行更出彩。
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="Ditem6">
                <div
                  className="close"
                  onClick={() => {
                    setShowInteriorDetail(false);
                    closewindowhidden();
                    window.Dmp.clickAction('price_space-pclose6', 'BOSE豪华音响系统');
                  }}
                ></div>
                <div className="detail-left">
                  {/* <img alt="广汽本田" className="img" src={accordhosturl + '/interior/interior6.jpg'} />
                  <img alt="广汽本田" className="icon2" src={accordhosturl + '/interior/interior6_icon.gif'} /> */}
                  <img
                    alt="广汽本田"
                    className="img"
                    src={
                      isMobile ? createFilePath('/accord/v2/mobile/interior/interior6.jpg') : createFilePath('/accord/v2/pc/interior/interior6.jpg')
                    }
                  />
                  <img
                    alt="广汽本田"
                    className="icon2"
                    src={
                      isMobile
                        ? createFilePath('/accord/v2/mobile/interior/interior6_icon.gif')
                        : createFilePath('/accord/v2/pc/interior/interior6_icon.gif')
                    }
                  />
                  <div className="sub">
                    <div className="en">IMMERSIVE AUDIO THAT CAPTIVATES YOUR SENSES </div>
                    <div className="cn">不止动听 还要处处动心</div>
                  </div>
                </div>
                <div className="detail-right">
                  <div className="t">
                    BOSE豪华音响系统<span className="s">(12扬声器)</span>
                  </div>
                  <div className="p">车内配置12个BOSE高性能扬声器，实现置身于演唱会现场般的沉浸式环绕立体声音体验。</div>
                  {/* {!isMobile ? <img alt="广汽本田" className="icon3" src={accordhosturl + '/interior/interior6_t.png'} /> : ''} */}
                  {!isMobile ? <img alt="广汽本田" className="icon3" src={createFilePath('/accord/v2/pc/interior/interior6_t.png')} /> : ''}
                </div>
                {/* {isMobile ? <img alt="广汽本田" className="icon3" src={accordhosturl + '/interior/interior6_t.png'} /> : ''} */}
                {isMobile ? <img alt="广汽本田" className="icon3" src={createFilePath('/accord/v2/mobile/interior/interior6_t.png')} /> : ''}
              </SwiperSlide>
              <SwiperSlide className="Ditem7">
                <div
                  className="close"
                  onClick={() => {
                    setShowInteriorDetail(false);
                    closewindowhidden();
                    window.Dmp.clickAction('price_space-pclose7', '配备无线充电及TYPE-C快充口');
                  }}
                ></div>
                <div className="detail-left">
                  {/* <img alt="广汽本田" className="img" src={accordhosturl + '/interior/interior7.jpg'} /> */}
                  <img
                    alt="广汽本田"
                    className="img"
                    src={
                      isMobile ? createFilePath('/accord/v2/mobile/interior/interior7.jpg') : createFilePath('/accord/v2/pc/interior/interior7.jpg')
                    }
                  />
                </div>
                <div className="detail-right">
                  <div className="t">
                    配备无线充电
                    <br />
                    及TYPE-C快充口
                  </div>
                  <div className="p">中央托板配备无线充电，加上前后排多个TYPE-C快充口，哪里都是电量满满。</div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="Ditem8">
                <div
                  className="close"
                  onClick={() => {
                    setShowInteriorDetail(false);
                    closewindowhidden();
                    window.Dmp.clickAction('price_space-pclose8', '豪华仿麂皮座椅');
                  }}
                ></div>
                <div className="detail-left">
                  {/* <img alt="广汽本田" className="img" src={accordhosturl + '/interior/interior8.jpg'} /> */}
                  <img
                    alt="广汽本田"
                    className="img"
                    src={
                      isMobile ? createFilePath('/accord/v2/mobile/interior/interior8.jpg') : createFilePath('/accord/v2/pc/interior/interior8.jpg')
                    }
                  />
                  <div className="sub">
                    <div className="en">COMFORT IS THE PLEASURE TRANSMITTED FROM FINGERTIPS TO BODY</div>
                    {!isMobile ? (
                      <div className="cn">舒适 是身体传递至指尖的愉悦</div>
                    ) : (
                      <div className="cn">
                        舒适
                        <br />
                        是身体传递至指尖的愉悦
                      </div>
                    )}
                  </div>
                </div>
                <div className="detail-right">
                  <div className="t">豪华仿麂皮座椅</div>
                  <div className="p">麂皮材质的细腻短纤维、贯穿式打孔工艺、精致考究的缝线，诠释恰如其分的高级质感，将乘坐的舒适感拉满。</div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="next" data-html-item="price_space-btnr"></div>
            <div className="prev" data-html-item="price_space-btnl"></div>
          </div>
        </>
      )}
      {flag && <FullVideo fields={videodata} close={() => setFlag(false)} />}
      {fields?.placeHolderRoute?.value && (
        <div ref={secondPageRef}>
          <Placeholder name={fields.placeHolderRoute.value} rendering={route} page={route} eventRef={secondPageEventRef} />
        </div>
      )}
    </div>
  );
}
