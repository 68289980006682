import React, { useEffect } from 'react';
import './style.scss';
import FullModal from './FullModal';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.min.css';
import { EffectCards, Navigation } from 'swiper';
import temp from '../../../temp/config';
import { convertStylesStringToObject, sendDmp, createFilePath } from '../../../utils/obj-utils';

export default function index(props) {
  const { close, num, sourceList, checkPrev, checkNext, isMobile, single, propClosedmp, propNextdmp, propPrevdmp, imgGroupNum } = props;
  let sourceNum = num;
  let isInit = num !== 0 && true;
  let sendPlayDmpTimer;
  let sendStopDmpTimer;
  const sendPlayDmp = (dmp, value) => {
    sendPlayDmpTimer = setTimeout(() => sendDmp(dmp, value), 100);
  };

  const clearPlayDmpTimer = () => {
    clearTimeout(sendPlayDmpTimer);
  };

  const sendStopDmp = (dmp, value) => {
    sendStopDmpTimer = setTimeout(() => sendDmp(dmp, value), 100);
  };

  const clearStopDmpTimer = () => {
    clearTimeout(sendStopDmpTimer);
  };

  useEffect(() => playVideoAndStop(num), []);

  const playVideoAndStop = (num) => {
    if (isMobile && sourceList[num].fields.type.fields.v.value === 'video') {
      const videoElements = document.getElementsByClassName('wallpaperInnerIndex');
      const videoElement = videoElements[num];
      if (videoElement.tagName != 'VIDEO') return;
      videoElement.play();
      setTimeout(() => {
        clearPlayDmpTimer();
        videoElement.pause();
        setTimeout(() => {
          clearStopDmpTimer();
        }, 5);
      }, 10);
    }
  };

  const pauseVideo = (num) => {
    const videoElements = document.getElementsByClassName('wallpaperInnerIndex');
    const videoElement = videoElements[num];
    if (videoElement.tagName != 'VIDEO') return;
    videoElement.pause();
  };

  return (
    <FullModal>
      <div className="wallpapervideo_detail_container">
        <img
          alt="广汽本田"
          className="closeIcon"
          src={`${createFilePath(sourceList[sourceNum].fields.close_icon.value)}`}
          onClick={() => {
            if (propClosedmp) {
              const dmp = propClosedmp + (sourceNum + imgGroupNum * 6 + 1);
              window.Dmp.clickAction(
                dmp,
                sourceList[sourceNum].fields.type.fields.v.value === 'video' ? sourceList[sourceNum].fields.source?.value : ''
              );
              close();
            } else {
              const dmp = sourceList[sourceNum].fields.close_dmp_point;
              close(dmp, sourceList[sourceNum].fields.type.fields.v.value === 'video' ? sourceList[sourceNum].fields.source?.value : '');
            }
          }}
        />
        <div className="details_Container">
          <Swiper
            enabled={!single}
            slidesPerView={1}
            initialSlide={num}
            modules={[Navigation, EffectCards]}
            onSlidePrevTransitionStart={(swiper) => {
              if (isInit) {
                isInit = false;
                return;
              }
              sourceList[sourceNum].fields.type.fields.v.value === 'video' && pauseVideo(sourceNum);
              sourceNum = swiper.activeIndex;
              playVideoAndStop(swiper.activeIndex);
              if (propNextdmp) {
                window.Dmp.clickAction(propPrevdmp, '');
              } else {
                const dmp = sourceList[swiper.activeIndex]?.fields?.left_dmp;
                checkPrev(dmp);
              }
            }}
            onSlideNextTransitionStart={(swiper) => {
              if (isInit) {
                isInit = false;
                return;
              }
              sourceList[sourceNum].fields.type.fields.v.value === 'video' && pauseVideo(sourceNum);
              sourceNum = swiper.activeIndex;
              playVideoAndStop(swiper.activeIndex);
              if (propNextdmp) {
                window.Dmp.clickAction(propNextdmp, '');
              } else {
                const dmp = sourceList[swiper.activeIndex]?.fields?.right_dmp;
                checkNext(dmp);
              }
            }}
            navigation={true}
          >
            {sourceList &&
              sourceList.length &&
              sourceList.map((ele, index) => {
                return (
                  <SwiperSlide key={index}>
                    {ele.fields.type.fields.v.value === 'img' && (
                      <div className="srcContainer__img">
                        <img
                          alt="广汽本田"
                          className="wallpaperInnerIndex"
                          style={convertStylesStringToObject(ele.fields?.source_style?.value)}
                          src={`${createFilePath(ele.fields?.source?.value)}`}
                        />
                      </div>
                    )}
                    {ele.fields.type.fields.v.value === 'video' && (
                      <div className="srcContainer__video">
                        <video
                          className="wallpaperInnerVideo wallpaperInnerIndex"
                          style={convertStylesStringToObject(ele.fields?.source_style?.value)}
                          src={`${createFilePath(ele.fields.source.value)}`}
                          controls
                          autoPlay={false}
                          onPlay={() => {
                            sendPlayDmp(ele.fields.play_video_dmp_point, ele.fields.source.value);
                          }}
                          onPause={() => {
                            sendStopDmp(ele.fields.stop_video_dmp_point, ele.fields.source.value);
                          }}
                        ></video>
                      </div>
                    )}
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </FullModal>
  );
}
