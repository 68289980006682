import axios from 'axios';
import { isAbsoluteUri } from '../utils/uri-utils';
import config from '../temp/config';
import { merge } from 'lodash';
import { toLowCamel } from '../utils/obj-utils';

const service = axios.create();

export const r = (path, withCredentials, options) => {
  return handleRequest(path, options);
};
export const handleRequest = (path, withCredentials, _opts) => {
  return (params = null, data = null, opts) => {
    const options = getRequestOptions(path, params, data, withCredentials, merge({}, _opts, opts));
    return processRequest(options);
  };
};
export const getRequestOptions = (path, params, data, withCredentials, opts) => {
  let [method, url] = path.split(' ');
  url.replace(/{.*?}/gi, (match) => {
    const key = match.replace(/\{\}/gi, '');
    let result = match;
    if (params[key]) {
      result = params[key];
      delete params[key];
    } else if (data[key]) {
      result = data[key];
      delete data[key];
    }
    return result;
  });
  if (!isAbsoluteUri(url)) {
    url = config.app.apiHost + url;
  }
  return merge({}, opts, {
    method,
    url,
    params,
    data,
    withCredentials
  });
};
export const processRequest = (options) => {
  return new Promise((resolve) => {
    service
      .request(options)
      .then((res) => {
        res = toLowCamel(res);
        const { data } = res;
        console.log('data', data);
        if (data.status) {
          resolve([data.data, null]);
        } else {
          return [null, data];
        }
        resolve([res, null]);
      })
      .catch((ex) => {
        resolve([null, ex]);
      });
  });
};
