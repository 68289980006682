import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { getPrefixField, createFilePath } from '../../utils/obj-utils';
import { Swiper } from 'swiper';
import { Navigation } from 'swiper';

export default function index(props) {
  const { gatelog_list, isMobile } = props;
  const containerRef = useRef(null);
  const pcswiperRef = useRef(null);
  const mbswiperRef = useRef(null);
  const [proppic, setProppic] = useState(null);
  const [propbool, setPropbool] = useState(false);
  const [num, setNum] = useState(0);
  let swiperpc;
  let swipermb;

  useEffect(() => {
    if (!isMobile) {
      swiperpc = new Swiper(pcswiperRef.current, {
        modules: [Navigation],
        slidesPerView: 4,
        navigation: {
          prevEl: containerRef.current.querySelector('.prev'),
          nextEl: containerRef.current.querySelector('.next'),
          disabledClass: 'disabled'
        }
      });

      return () => {
        if (swiperpc) {
          swiperpc.destroy(true, true);
        }
      };
    } else {
      swipermb = new Swiper(mbswiperRef.current, {
        modules: [Navigation],
        slidesPerView: 'auto',
        navigation: {
          prevEl: containerRef.current.querySelector('.prev'),
          nextEl: containerRef.current.querySelector('.next'),
          disabledClass: 'disabled'
        }
      });

      return () => {
        if (swipermb) {
          swipermb.destroy(true, true);
        }
      };
    }
  }, []);
  return (
    <div className="gatelogdownload-list" ref={containerRef}>
      {!isMobile ? (
        <>
          <div className="swiper-container" ref={pcswiperRef}>
            <div className="swiper-wrapper">
              {gatelog_list &&
                gatelog_list.length > 0 &&
                gatelog_list.map((item, index) => {
                  return (
                    <div className="swiper-slide" key={index}>
                      <div className="name">
                        <span dangerouslySetInnerHTML={{ __html: item.fields?.gcar_name?.value?.replaceHtml() }}></span>
                      </div>
                      <div className="pic">
                        <img src={createFilePath(item.fields?.gcar_img?.value)} alt={item.fields?.gcar_name?.value} />
                      </div>
                      {item.fields?.gcar_download?.value && (
                        <a href={createFilePath(item.fields?.gcar_download?.value)} download className="down">
                          点击下载
                        </a>
                      )}
                      {item.fields?.gcar_download2?.value && (
                        <a href={createFilePath(item.fields?.gcar_download2?.value)} download className="down">
                          {item.fields?.gcar_download2_name?.value ? item.fields?.gcar_download2_name?.value : '点击下载'}
                        </a>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="prev"></div>
          <div className="next"></div>
        </>
      ) : (
        <>
          <div className="tabs">
            <div className="swiper-container" ref={mbswiperRef}>
              <div className="swiper-wrapper">
                {gatelog_list &&
                  gatelog_list.length > 0 &&
                  gatelog_list.map((item, index) => {
                    return (
                      <div
                        className="swiper-slide"
                        key={index}
                        onClick={() => {
                          setNum(index);
                        }}
                      >
                        <div className={`name ${num === index ? ' on' : ''}`}>
                          <span dangerouslySetInnerHTML={{ __html: item.fields?.m_gcar_name?.value?.replaceHtml() }}></span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="prev"></div>
            <div className="next"></div>
          </div>
          <div className="con">
            {gatelog_list && gatelog_list.length > 0 && (
              <>
                {gatelog_list[num].fields?.gcar_downloadpics1?.value && (
                  <div
                    className="pic1"
                    onClick={() => {
                      setProppic(createFilePath(gatelog_list[num].fields?.gcar_downloadpic1?.value));
                      setPropbool(true);
                    }}
                  >
                    <img src={createFilePath(gatelog_list[num].fields?.gcar_downloadpics1?.value)} alt={gatelog_list[num].fields?.gcar_name?.value} />
                  </div>
                )}
                {gatelog_list[num].fields?.gcar_downloadpics2?.value && (
                  <div
                    className="pic1"
                    onClick={() => {
                      setProppic(createFilePath(gatelog_list[num].fields?.gcar_downloadpic2?.value));
                      setPropbool(true);
                    }}
                  >
                    <img src={createFilePath(gatelog_list[num].fields?.gcar_downloadpics2?.value)} alt={gatelog_list[num].fields?.gcar_name?.value} />
                  </div>
                )}
                {gatelog_list[num].fields?.gcar_downloadpics3?.value && (
                  <div
                    className="pic1"
                    onClick={() => {
                      setProppic(createFilePath(gatelog_list[num].fields?.gcar_downloadpic3?.value));
                      setPropbool(true);
                    }}
                  >
                    <img src={createFilePath(gatelog_list[num].fields?.gcar_downloadpics3?.value)} alt={gatelog_list[num].fields?.gcar_name?.value} />
                  </div>
                )}
                {gatelog_list[num].fields?.gcar_downloadpics4?.value && (
                  <div
                    className="pic1"
                    onClick={() => {
                      setProppic(createFilePath(gatelog_list[num].fields?.gcar_downloadpic4?.value));
                      setPropbool(true);
                    }}
                  >
                    <img src={createFilePath(gatelog_list[num].fields?.gcar_downloadpics4?.value)} alt={gatelog_list[num].fields?.gcar_name?.value} />
                  </div>
                )}
              </>
            )}
          </div>
          {propbool && (
            <div className="prop">
              <div className="pic">
                <img alt="广汽本田" src={proppic} />
              </div>
              <div className="down">长按保存</div>
              <div
                className="graybg"
                onClick={() => {
                  setProppic(null);
                  setPropbool(false);
                }}
              ></div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
