import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import { getPrefixField, convertStylesStringToObject, sendDmp, createFilePath, isMobileFun } from '../../utils/obj-utils';
import { openCloudCar, openHome } from '../../utils/api';

export default function index({ fields, eventRef }) {
  const containerRef = useRef(null);
  const backgroundOneRef = useRef(null);
  const backgroundTwoRef = useRef(null);
  const titleRef = useRef(null);
  const descContentRef = useRef(null);
  const mobileRef = useRef(null);
  const qrcodeContainerRef = useRef(null);
  const bottomContainerRef = useRef(null);
  const panoramaButtonRef = useRef(null);
  const isMobile = isMobileFun();
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const desc = fields[getPrefixField('desc', isMobile)]?.value;
  const qrCodeTitle = fields[getPrefixField('qrCodeTitle', isMobile)]?.value;
  const qrCodeImage = fields[getPrefixField('qrCodeImage', isMobile)]?.value
    ? createFilePath(fields[getPrefixField('qrCodeImage', isMobile)]?.value)
    : null;
  const earImage = createFilePath(fields[getPrefixField('earImage', isMobile)]?.value);
  const mobileImage = createFilePath(fields[getPrefixField('mobileImage', isMobile)]?.value);
  const mobileStyle = convertStylesStringToObject(fields[getPrefixField('mobileStyle', isMobile)]?.value);
  const background1 = createFilePath(fields[getPrefixField('background1', isMobile)]?.value);
  const background2 = createFilePath(fields[getPrefixField('background2', isMobile)]?.value);
  const buttonText = fields[getPrefixField('buttonText', isMobile)]?.value;
  const buttonStyle = convertStylesStringToObject(fields[getPrefixField('buttonStyle', isMobile)]?.value);
  const panoramaButtonText = fields[getPrefixField('panoramaButtonText', isMobile)]?.value;
  const panoramaButtonStyle = convertStylesStringToObject(fields[getPrefixField('panoramaButtonStyle', isMobile)]?.value);
  const panoramaButtonLink = fields[getPrefixField('panoramaButtonLink', isMobile)]?.value;
  const showPanorama = fields[getPrefixField('showPanorama', isMobile)]?.value;
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const disableAnimate = fields[getPrefixField('disableAnimate', isMobile)]?.value;
  const bottomText = fields.m_bottomText?.value;
  const vehicleType = fields?.vehicleType?.fields?.v?.value;
  const sectionDmp = fields?.sectionDmp;
  const buttonDmp = fields?.buttonDmp;
  const panoramaButtonDmp = fields?.panoramaButtonDmp;
  const anchor = fields?.anchor?.value;
  const [data, _] = useState({
    title,
    desc,
    qrCodeTitle,
    qrCodeImage,
    earImage,
    mobileImage,
    mobileStyle,
    background1,
    background2,
    buttonText,
    buttonStyle,
    panoramaButtonText,
    panoramaButtonStyle,
    panoramaButtonLink,
    showPanorama,
    style,
    disableAnimate,
    vehicleType,
    sectionDmp,
    buttonDmp,
    panoramaButtonDmp,
    anchor,
    isMobile,
    bottomText
  });

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(data?.sectionDmp);
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = data?.anchor || 'oneservice';
  };

  useEffect(() => {
    if (data?.isMobile) {
      const mobileHeight = mobileRef.current.clientHeight;
      const windowHeight = containerRef.current.clientHeight;
      mobileRef.current.style.top = `${(windowHeight - mobileHeight) / 2.0}px`;
    }

    if (data?.disableAnimate) {
      return;
    }

    const timeline1 = gsap
      .timeline({ paused: true })
      .fromTo(backgroundOneRef.current, { x: '-100%', opacity: 0 }, { x: 0, opacity: 1 })
      .fromTo(backgroundTwoRef.current, { x: '100%', opacity: 0 }, { x: data?.isMobile ? 0 : '50%', opacity: data?.isMobile ? 1 : 0.5 }, '>0.1')
      .fromTo(
        titleRef.current,
        { x: data?.isMobile ? '100%' : '-100%', opacity: 0 },
        { x: data?.isMobile ? 0 : '-50%', opacity: data?.isMobile ? 1 : 0.5 },
        '<'
      );
    const controller = new ScrollMagic.Controller();
    const scene1 = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: '0.6',
      duration: '60%'
    })
      .on('progress', function (event) {
        timeline1.progress(event.progress);
      })
      .setTween(timeline1)
      .addTo(controller);

    const timeline2 = gsap.timeline({ paused: true });
    if (!data?.isMobile) {
      timeline2.to(backgroundTwoRef.current, { x: 0, opacity: 1 }).to(titleRef.current, { x: 0, opacity: 1 }, '<');
    }
    timeline2.fromTo(descContentRef.current, { width: 0 }, { width: '100%' });
    if (data?.isMobile) {
      timeline2.fromTo(mobileRef.current, { x: '100%', opacity: 0 }, { x: 0, opacity: 1 }, '<');
    } else {
      timeline2.fromTo(mobileRef.current, { y: '100%', opacity: 0 }, { y: 0, opacity: 1 }, '<');
      if (panoramaButtonRef.current) {
        timeline2.fromTo(panoramaButtonRef.current, { y: '100%', opacity: 0 }, { y: 0, opacity: 1 }, '<30%');
      }
    }
    if (qrcodeContainerRef?.current) {
      timeline2.fromTo(qrcodeContainerRef.current, { x: '100%', opacity: 0 }, { x: 0, opacity: 1 }, '<');
    }
    if (bottomContainerRef?.current) {
      timeline2.fromTo(bottomContainerRef.current, { opacity: 0 }, { opacity: 1 }, '<');
    }
    const scene2 = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onLeave',
      duration: '100%'
    })
      .on('progress', function (event) {
        timeline2.progress(event.progress);
      })
      .setPin(containerRef.current)
      .setTween(timeline2)
      .addTo(controller);

    return () => {
      timeline1.kill();
      timeline2.kill();

      scene1.remove();
      scene1.destroy();
      scene2.remove();
      scene2.destroy();

      controller.destroy();
    };
  }, []);

  const buttonClick = () => {
    if (data?.vehicleType) {
      openCloudCar(data?.vehicleType, (link) => {
        sendDmp(data?.buttonDmp, link);
      });
    } else {
      openHome((link) => {
        sendDmp(data?.buttonDmp, link);
      });
    }
  };

  const panoramaButtonClick = () => {
    sendDmp(data?.panoramaButtonDmp, data?.panoramaButtonLink);
    window.open(data?.panoramaButtonLink);
  };

  const getPanoramaButton = () => {
    return data?.showPanorama ? (
      <button
        className="panorama-button"
        dangerouslySetInnerHTML={{ __html: data?.panoramaButtonText?.replaceHtml() }}
        style={data?.panoramaButtonStyle}
        ref={panoramaButtonRef}
        onClick={panoramaButtonClick}
      />
    ) : null;
  };

  return (
    <section>
      {data ? (
        <React.Fragment>
          <div className="one-on-one-v2" ref={containerRef} style={data?.style}>
            <div className="background-container">
              {/* <img alt="广汽本田" className="background-one" src={data?.background1} ref={backgroundOneRef} />
            <img alt="广汽本田" className="background-two" src={data?.background2} ref={backgroundTwoRef} /> */}
              <img alt="广汽本田" className="background-one" src={data?.background1} />
              <img alt="广汽本田" className="background-two" src={data?.background2} />
            </div>
            <div className="text-container">
              <div className="title" dangerouslySetInnerHTML={{ __html: data?.title?.replaceHtml() }} ref={titleRef} />
              <div className="desc-container">
                <div className="desc-content" ref={descContentRef}>
                  <img alt="广汽本田" className="desc-image" src={data?.earImage} />
                  <div className="desc" dangerouslySetInnerHTML={{ __html: data?.desc?.replaceHtml() }} />
                </div>
              </div>
            </div>
            {!data?.isMobile ? getPanoramaButton() : null}
            <img alt="广汽本田" className="mobile" src={data?.mobileImage} style={data?.mobileStyle} ref={mobileRef} />
            {data?.qrCodeTitle || data?.qrCodeImage ? (
              <div className="qrcode-container" ref={qrcodeContainerRef}>
                {data?.qrCodeImage ? <img alt="广汽本田" className="qrcode-image" src={data?.qrCodeImage} /> : null}
                {data?.qrCodeTitle ? <div className="qrcode-text" dangerouslySetInnerHTML={{ __html: data?.qrCodeTitle?.replaceHtml() }} /> : null}
              </div>
            ) : null}
            {data?.buttonText || (data?.isMobile && data?.showPanorama) ? (
              <div className="bottom-button-container" ref={bottomContainerRef}>
                {data?.isMobile ? getPanoramaButton() : null}
                {data?.buttonText ? (
                  <button
                    className="bottom-button"
                    dangerouslySetInnerHTML={{ __html: data?.buttonText?.replaceHtml() }}
                    style={data?.buttonStyle}
                    onClick={buttonClick}
                  />
                ) : null}
                {data?.isMobile && <div className="bottom-text" dangerouslySetInnerHTML={{ __html: data?.bottomText?.replaceHtml() }} />}
              </div>
            ) : null}
          </div>
          <div id={data?.anchor || 'oneservice'} />
        </React.Fragment>
      ) : null}
    </section>
  );
}
