import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Navigation } from 'swiper';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import './style.scss';
import { createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function Banner(props) {
  const isMobile = isMobileFun();
  const vehiclesData = JSON.parse(props.fields.vehiclesData.value.replaceHtml());
  const [num, setNum] = useState(0);
  const [numArr, setNumArr] = useState([0, 0, 0, 0, 0]);
  const [timeout, setTimeOut] = useState(false);
  var changeFlag = true;
  useEffect(() => {
    setTimeout(() => {
      setTimeOut(true);
    }, 3000);
  }, []);
  const swiperRef = useRef(null);
  const [secondActive, setSecondActive] = useState(0);
  return (
    <div className="allCar-homePage">
      <div className="allCar-homePage-header">
        {vehiclesData.map((item, index) => {
          return (
            <p
              key={index}
              onClick={() => {
                changeFlag = false;
                if (index !== num) {
                  setNum(index);
                }
                window.Dmp.clickAction(props.fields.typeDmp[0]?.displayName, '', { val: index !== 1 ? item.title : '纯电' });
              }}
              className={num === index ? 'activeP' : ''}
            >
              {item.title}
            </p>
          );
        })}
      </div>

      <div style={{ visibility: num === 0 ? 'visible' : 'hidden' }} className="littleTitleAllCar">
        <p
          onClick={(e) => {
            changeFlag = false;
            for (let index = 0; index < e.target.parentNode.childNodes.length; index++) {
              e.target.parentNode.childNodes[index].style.color = 'rgb(172,172,172)';
            }
            e.target.style.color = 'black';
            window.Dmp.clickAction('car-second', '', { val: '烨P' });
            swiperRef.current.swiper.slideTo(0, 1000, true);
            changeFlag = true;
          }}
          className={secondActive === 0 ? 'secondActive' : ''}
        >
          烨P
        </p>
        <p
          onClick={(e) => {
            changeFlag = false;
            for (let index = 0; index < e.target.parentNode.childNodes.length; index++) {
              e.target.parentNode.childNodes[index].style.color = 'rgb(172,172,172)';
            }
            e.target.style.color = 'black';
            window.Dmp.clickAction('car-second', '', { val: 'e:NP纯电' });
            swiperRef.current.swiper.slideTo(1, 1000, true);
            changeFlag = true;
          }}
          className={secondActive === 1 ? 'secondActive' : ''}
        >
          e:NP纯电
        </p>
        <p
          onClick={(e) => {
            changeFlag = false;
            for (let index = 0; index < e.target.parentNode.childNodes.length; index++) {
              e.target.parentNode.childNodes[index].style.color = 'rgb(172,172,172)';
            }
            e.target.style.color = 'black';
            window.Dmp.clickAction('car-second', '', { val: '汽油版' });
            swiperRef.current.swiper.slideTo(3, 1000, true);
            changeFlag = true;
          }}
          className={secondActive === 2 ? 'secondActive' : ''}
        >
          汽油版
        </p>
        <p
          onClick={(e) => {
            changeFlag = false;
            for (let index = 0; index < e.target.parentNode.childNodes.length; index++) {
              e.target.parentNode.childNodes[index].style.color = 'rgb(172,172,172)';
            }
            e.target.style.color = 'black';
            window.Dmp.clickAction('car-second', '', { val: '混动版' });
            swiperRef.current.swiper.slideTo(12, 1000, true);
            changeFlag = true;
          }}
          className={secondActive === 3 ? 'secondActive' : ''}
        >
          混动版
        </p>
        <p
          onClick={(e) => {
            changeFlag = false;
            for (let index = 0; index < e.target.parentNode.childNodes.length; index++) {
              e.target.parentNode.childNodes[index].style.color = 'rgb(172,172,172)';
            }
            e.target.style.color = 'black';
            window.Dmp.clickAction('car-second', '', { val: '新能源' });
            swiperRef.current.swiper.slideTo(17, 1000, true);
            changeFlag = true;
          }}
          className={secondActive === 4 ? 'secondActive' : ''}
        >
          新能源
        </p>
      </div>

      <div className="allSwiper">
        {vehiclesData.map((it, idx) => {
          return (
            <Swiper
              slidesPerView={!isMobile && it.children.length >= 4 ? 4 : !isMobile && it.children.length < 4 ? 4 : 1}
              observeParents={true}
              initialSlide={0}
              ref={idx === 0 ? swiperRef : null}
              modules={[Navigation]}
              navigation={true}
              onSlideChange={(e) => {
                if (timeout) {
                  if (changeFlag) {
                    if (e.realIndex > numArr[idx] && e.realIndex - numArr[idx] <= 2 && e.realIndex - numArr[idx] >= 0) {
                      window.Dmp.clickAction('car-btnr');
                    } else {
                      window.Dmp.clickAction('car-btnl');
                    }
                  }
                }
                numArr[idx] = e.activeIndex;
                setNumArr(numArr);
                if (numArr[0] >= 0 && numArr[0] < 1) {
                  setSecondActive(0);
                } else if (numArr[0] >= 1 && numArr[0] < 10) {
                  setSecondActive(1);
                } else if (numArr[0] >= 10 && numArr[0] < 16) {
                  setSecondActive(2);
                } else if (numArr[0] >= 16) {
                  setSecondActive(3);
                }
              }}
              className="allCar-homePage-main"
              key={idx}
              style={{ display: num === idx ? 'block' : 'none' }}
            >
              {it.children.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div data-idx={index}>
                      <div>
                        {!isMobile ? (
                          <div className="backImage">
                            <div className="allCar-homePage-title">
                              <p>{item.name}</p>
                              <p>{item.littleT}</p>
                              {/* <p>{item.price}万元起</p> */}
                              <p>{item.price ? item.price + (item.priceonly ? '万元' : '万元起') : ''}</p>
                            </div>
                            <img
                              src={createFilePath(item.img)}
                              alt={item.name}
                              onMouseOver={(e) => {
                                window.Dmp.hoverAction(item.imgID);
                                e.target.src = createFilePath(item.img2);
                              }}
                              onMouseLeave={(e) => {
                                e.target.src = createFilePath(item.img);
                              }}
                              onClick={(e) => {
                                location.href = !isMobile ? item.pc_lookLink : item.lookLink;
                                window.Dmp.clickAction(item.imgID, !isMobile ? item.pc_lookLink : item.lookLink);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="backImage">
                            <img
                              src={createFilePath(item.img2)}
                              alt={item.name}
                              onClick={(e) => {
                                location.href = item.lookLink;
                                window.Dmp.clickAction(item.imgID, !isMobile ? item.pc_lookLink : item.lookLink);
                              }}
                              style={{ zIndex: 0 }}
                            />
                            <div className="allCar-homePage-title">
                              <p>{item.name}</p>
                              <p>{item.littleT}</p>
                              {/* <p>{item.price}万元起</p> */}
                              <p>{item.price ? item.price + (item.priceonly ? '万元' : '万元起') : ''}</p>
                            </div>
                          </div>
                        )}
                        <div className="allCar-homePage-btn">
                          <a
                            href={!isMobile ? item.pc_lookLink : item.lookLink}
                            dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.btn1.value : props.fields.m_btn1.value)?.replaceHtml() }}
                            onClick={() => {
                              window.Dmp.clickAction(item.lookID, !isMobile ? item.pc_lookLink : item.lookLink);
                            }}
                          ></a>
                          {/* {(!isMobile ? item.pc_car_id : item.car_id) && ( */}
                          <a
                            href={!isMobile ? item.pc_car_id : item.car_id}
                            dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.btn2.value : props.fields.m_btn2.value)?.replaceHtml() }}
                            style={{
                              opacity: (!isMobile ? item.pc_car_id : item.car_id) ? 1 : 0,
                              width: (!isMobile ? item.pc_car_id : item.car_id) ? 'auto' : '1px',
                              overflow: (!isMobile ? item.pc_car_id : item.car_id) ? 'auto' : 'hidden'
                            }}
                            onClick={() => {
                              window.Dmp.clickAction(item.carID, !isMobile ? item.pc_car_id : item.car_id);
                            }}
                          ></a>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
              {!isMobile ? <SwiperSlide style={{ display: num === 0 ? 'block' : 'none' }}></SwiperSlide> : ''}
            </Swiper>
          );
        })}
      </div>
    </div>
  );
}
