import React, { useState, useEffect, useRef } from 'react';
import { createFilePath, checkMobile, getPrefixField, isEmpty, getQueryString, sendDmpAction, sendDmp, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { dataFetcher } from '../../dataFetcher';
import { getQueryVariable } from '../../utils/site-utils';
import { useHistory } from 'react-router-dom';
import FindDealer from '../../elements/FindDealer';
import FullModalDetail from '../../elements/FullModalDetail_v1';
import temp from '../../temp/config';
import './style.scss';
import ReactPlayer from 'react-player';
// import VideoJS from './VideoJS';

export default function index(props) {
  const { fields } = props;
  const history = useHistory();
  const isMobile = isMobileFun();
  const method = fields.method.value || 2;
  const marketcode = fields.marketcode.value || '';
  const comment = fields.comment.value || '';
  const carList = fields.carData.value ? JSON.parse(fields.carData.value.replaceHtml()) : '';
  const typeList = fields.typeData.value ? JSON.parse(fields.typeData.value.replaceHtml()) : '';
  const startime = fields.time.value ? Number(fields.time.value.split('-')[0]) : 0;
  const endtime = fields.time.value ? Number(fields.time.value.split('-')[1]) : 18;
  const dmpList = fields.dmp.value ? JSON.parse(fields[getPrefixField('dmp', isMobile)]?.value.replaceHtml()) : '';
  const car = getQueryVariable('car_id') ? carList.find((item) => item.modelName.toLowerCase() === getQueryVariable('car_id').toLowerCase()) : '';
  const carIndex = carList.indexOf(car);
  const [selectVehicleType, setSelectVehicleType] = useState(carList[carIndex]);
  const [agree, setAgree] = useState(false);
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [verificationImage, setVerificationImage] = useState();
  const [messageInfo, setMessageInfo] = useState({ sendType: 1, validWhiteList: false, whiteListType: 0 });
  const [policyVersion, setPolicyVersion] = useState(null);
  const [mapShow, setMapShow] = useState(false);
  // 预约参数模板
  const [person, setPerson] = useState({
    userName: '',
    mobile: '',
    provinceId: '',
    cityId: '',
    dealerId: '',
    modelName: car ? car.modelName : '',
    mediafrom: method,
    graphic: {
      code: '', // 用户录入的图形验证码
      id: '', // 图形验证码id
      preMod: false
    },
    smsValidId: '', // 短信验证码id
    smsValidCode: '', // 用户录入的短信验证码
    series_code: '',
    device_type: isMobile ? 2 : 1,
    areaCode: '',
    adcode: '',
    contactid: useSitecoreContext()?.sitecoreContext?.contactId,
    market_code: marketcode,
    comment: comment
  });
  const verificationCodeRef = useRef(null);
  const messageCodeRef = useRef(null);
  const lastPerson = useRef(null);
  const userName = useRef(null);
  const mobile = useRef(null);
  const [isend, setIsend] = useState(null);
  const [iswechat, setIswechat] = useState(false);
  useEffect(() => {
    setInterval(() => {
      let date = new Date();
      let houer = date.getHours();
      // let minute = date.getMinutes();
      // let second = date.getSeconds();
      if (houer >= startime && houer < endtime) {
        setIsend(1);
      } else {
        setIsend(2);
      }
    }, 2000);
  }, []);
  // 版本号
  const getPolicyVersion = () => {
    dataFetcher('/api/honda/v1/Policy/GetPolicyVersion')
      .then(({ data }) => {
        if (data.Status) {
          setPolicyVersion(data?.Data?.policy_code);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getPolicyVersion();
  }, []);

  // 请选择车型
  const selectcar = (e) => {
    let val = e.target.childNodes[e.target.selectedIndex].getAttribute('value');
    if (val) {
      person.modelName = val;
      let car = carList.find((item) => item.modelName === val);
      let newPerson = { ...person };
      setPerson(newPerson);
      setSelectVehicleType(car);
      if (dmpList) {
        window.Dmp.msgAction(dmpList.car, val);
      }
    }
  };

  const verificationCodeChange = (e) => {
    messageInfo.validCode = e.target.value;
  };

  const messageCodeChange = (e) => {
    person.smsValidCode = e.target.value;
  };

  const addLeads = () => {
    dataFetcher('/api/honda/v1/Leads/AddLeads', person)
      .then(({ data }) => {
        if (data?.Status) {
          const personData = {
            n: person.userName,
            tel: person.mobile,
            prv: person.provinceId,
            city: person.cityId,
            dl: person.dealerId,
            ver: policyVersion
          };
          alert('提交成功！');
          lastPerson.current = { ...person };
          close();
          userName.current.value = '';
          mobile.current.value = '';
        } else {
          const errorCode = parseInt(data?.ErrorResult?.ErrCode);
          if (errorCode === 26 || errorCode === 27 || errorCode === 28) {
            if (showVerificationCode) {
              // return Promise.reject(new Error('短信验证码输入错误！'));
              alert('短信验证码输入错误！');
            } else {
              setShowVerificationCode(true);
              getVerificationImage();
            }
          } else {
            // return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
            alert(data?.ErrorResult?.ErrMsg);
          }
        }
      })
      .catch((error) => {
        alert(error?.message);
        console.log(error);
      });
  };

  const close = () => {
    setPerson({
      ...person,
      graphic: {
        code: '',
        id: '',
        preMod: false
      },
      smsValidId: '',
      smsValidCode: ''
    });
    setMessageInfo({ ...messageInfo, validId: '', validCode: '', phone: '' });
    verificationCodeRef?.current && (verificationCodeRef.current.value = '');
    messageCodeRef?.current && (messageCodeRef.current.value = '');
    setShowVerificationCode(false);
  };

  const getVerificationImage = () => {
    dataFetcher('/api/honda/v1/Graphic/CreateCode')
      .then(({ data }) => {
        if (data?.Status) {
          person.graphic.id = data?.Data?.GraphicValidId;
          messageInfo.validId = data?.Data?.GraphicValidId;
          messageInfo.validCode = '';
          verificationCodeRef.current.value = '';
          setVerificationImage(data?.Data?.Base64);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const personSubmit = () => {
    if (!selectVehicleType) {
      alert('请选择车型');
      return;
    }
    if (isEmpty(person.userName)) {
      alert('请填写您的真实姓名');
      return;
    }
    if (!checkMobile(person.mobile)) {
      alert('请填写正确的联系方式');
      return;
    }
    if (!person.provinceId) {
      alert('请选择省份');
      return;
    }
    if (!person.cityId) {
      alert('请选择城市');
      return;
    }
    if (!person.dealerId) {
      alert('请选择特约店');
      return;
    }
    if (!agree) {
      alert('请确认并阅读声明');
      return;
    }
    if (showVerificationCode && isEmpty(person.smsValidCode)) {
      alert('短信验证码不能为空');
      return;
    }
    person.series_code = selectVehicleType?.seriesId;
    person.adcode = getQueryString('adcode');
    person.graphic.code = messageInfo?.validCode;
    if (lastPerson?.current) {
      let exist = false;
      for (const key in lastPerson?.current) {
        if (!['graphic', 'smsValidId', 'smsValidCode'].includes(key) && lastPerson?.current[key] !== person[key]) {
          exist = true;
          break;
        }
      }
      if (!exist) {
        alert('重复提交！');
        return;
      }
    }
    addLeads();
  };

  const sendMessage = () => {
    if (isEmpty(messageInfo?.validCode)) {
      alert('请输入图形验证码！');
      return;
    }
    messageInfo.phone = person.mobile;
    dataFetcher('/api/honda/v1/SMS/SendSMS', messageInfo)
      .then(({ data }) => {
        if (data.Status) {
          alert('已发送短信,请注意查收');
          person.smsValidId = data?.Data?.smsValidId;
        } else {
          getVerificationImage();
          const errorCode = parseInt(data?.ErrorResult?.ErrCode);
          let errorMessage;
          switch (errorCode) {
            case -1:
              errorMessage = '短信发送失败！';
              break;
            case 5:
              errorMessage = '图形验证码错误！';
              break;
            case 11:
              errorMessage = '手机号当天发送次数超限！';
              break;
            case 12:
              errorMessage = '手机号发送次数超限，请1小时候再试！';
              break;
            case 13:
              errorMessage = '手机号发送频率过快，请稍后再试！';
              break;
            case 21:
              errorMessage = '非常抱歉，发送验证码时出现异常，请稍后再试！';
              break;
            default:
              errorMessage = data?.ErrorResult?.ErrMsg;
              break;
          }
          return Promise.reject(new Error(errorMessage));
        }
      })
      .catch((error) => {
        alert(error?.message);
        console.log(error);
      });
  };
  const setPersonchild = (e) => {
    let newPerson = { ...person };
    newPerson.provinceId = e?.provinceId;
    newPerson.cityId = e?.cityId;
    newPerson.dealerId = e?.dealerId;
    newPerson.areaCode = e?.areaCode;
    setPerson(newPerson);
  };

  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(true);
  const [zoom, setZoom] = useState(false);

  useEffect(() => {
    if (navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 || typeof navigator.wxuserAgent !== 'undefined') {
      setIswechat(true);
      setPlaying(false);
    } else {
      setIswechat(false);
      setPlaying(true);
    }
  }, []);

  // const videoJsOptions = {
  //   // 自动播放：为true时，加载完毕自动播放
  //   autoplay: true,
  //   // 播放器子控件是否显示：为true时显示暂停、播放速率等按钮
  //   controls: false,
  //   // 响应性：为true时，播放视频进度条会自动移动
  //   responsive: true,
  //   // 流式布局：为true时尽可能大得填满父标签的剩余空间
  //   fluid: true,
  //   muted: true,
  //   // 视频源
  //   sources: [
  //     {
  //       // 视频文件的路径，可以是一个前端相对路径、后台视频文件URL、直播源等
  //       src: temp.app.deployUrlS3 + fields[getPrefixField('video', isMobile)]?.value,
  //       // 视频源类型
  //       type: 'video/mp4'
  //     }
  //   ]
  // };

  // // 播放器实例化完成后的事件动作，注意！不是视频加载成功
  // const handlePlayerReady = (player) => {
  //   playerRef.current = player;
  //   // 播放器的子事件在这里定义

  //   player.on('canplaythrough', () => {
  //     console.log('视频加载完成！');
  //   });

  //   player.on('error', () => {
  //     console.log('视频文件加载失败，请稍后重试！');
  //   });

  //   player.on('stalled', () => {
  //     console.log('网络异常，请稍后重试！');
  //   });
  // };

  return (
    <section>
      <div className="live_v1">
        <div className="main">
          <div className="title-section">
            <h3 className="title">
              <p>直播专区</p>
            </h3>
          </div>
          <div className="content">
            <div className="title">
              {isend && isend === 2 ? (
                <span className="icon live_icon2">
                  <img alt="广汽本田" src={createFilePath('/live/live_icon.png')} />
                  未开始
                </span>
              ) : isend === 1 ? (
                <span className="icon live_icon">
                  <img alt="广汽本田" src={createFilePath('/live/live_icon.png')} />
                  直播中
                </span>
              ) : (
                <span className="icon live_icon2">
                  <img alt="广汽本田" src={createFilePath('/live/live_icon.png')} />
                  Loading
                </span>
              )}

              <span dangerouslySetInnerHTML={{ __html: fields[getPrefixField('title', isMobile)]?.value.replaceHtml() }}></span>
            </div>

            <div
              className="video"
              onClick={() => {
                setPlaying(true);
              }}
            >
              {isend && isend === 2 ? (
                <div className="unStart">
                  <img alt="广汽本田" src={createFilePath(fields[getPrefixField('banner', isMobile)]?.value)} />
                </div>
              ) : isend === 1 ? (
                <div className={`video_out ${zoom ? 'video_out_fixed' : ''}`} id="livevideo">
                  {/* <VideoJS options={videoJsOptions} onReady={handlePlayerReady} /> */}
                  <ReactPlayer
                    ref={playerRef}
                    url={createFilePath(fields[getPrefixField('video', isMobile)]?.value)}
                    playing={playing}
                    width="100%"
                    muted={muted}
                    height="100%"
                    playsinline={true}
                    loop={true}
                    controls={false}
                    onReady={() => {}}
                    onPause={() => {}}
                  />
                  {iswechat && !playing && (
                    <div
                      className="play"
                      onClick={() => {
                        setPlaying(true);
                      }}
                    >
                      <img alt="广汽本田" src={createFilePath('/live/play_icon.png')} />
                    </div>
                  )}
                  {playing && (
                    <div className="controls">
                      <div
                        className="setmuted"
                        onClick={() => {
                          setMuted(muted ? false : true);
                        }}
                      >
                        {muted ? (
                          <img alt="广汽本田" src={createFilePath('/live/muted_icon2.png')} />
                        ) : (
                          <img alt="广汽本田" src={createFilePath('/live/muted_icon.png')} />
                        )}
                      </div>
                      <div
                        className="setzoom"
                        onClick={() => {
                          setZoom(zoom ? false : true);
                        }}
                      >
                        {zoom ? (
                          <img alt="广汽本田" src={createFilePath('/live/zoom_icon2.png')} />
                        ) : (
                          <img alt="广汽本田" src={createFilePath('/live/zoom_icon.png')} />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="unStart">Loading...</div>
              )}
            </div>

            <div className="content_in">
              <div className="formBox">
                <div className="t">预约试驾</div>
                <div className="formitem">
                  <select
                    className="select"
                    onChange={(e) => {
                      selectcar(e);
                    }}
                    value={person.modelName ? person.modelName : 0}
                  >
                    <option value={0}>请选择意向车型*</option>
                    {carList &&
                      carList.map((item, index) => {
                        return (
                          <option key={index} value={item.modelName}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="formitem">
                  <div className="item">
                    <input
                      type="text"
                      className="text"
                      ref={userName}
                      maxLength="10"
                      placeholder="您的姓名*"
                      onInput={(e) => {
                        person.userName = e.target.value;
                        setPerson(person);
                      }}
                      onBlur={(e) => {
                        if (dmpList) {
                          window.Dmp.msgAction(dmpList.name, null);
                        }
                      }}
                    />
                  </div>
                  <div className="item fr">
                    <input
                      type="text"
                      className="text"
                      maxLength="12"
                      placeholder="您的手机号码*"
                      ref={mobile}
                      onInput={(e) => {
                        if (checkMobile(e.target.value)) {
                          person.mobile = e.target.value;
                          setPerson(person);
                        }
                      }}
                      onBlur={(e) => {
                        const dmpPointList = fields.tel_dmp_point;
                        if (dmpList) {
                          window.Dmp.msgAction(dmpList.tel, null);
                        }
                      }}
                    />
                  </div>
                </div>

                <FindDealer
                  props={props}
                  islive={true}
                  car={car}
                  selectVehicleType={selectVehicleType}
                  mapShow={true}
                  isnomap={true}
                  setPersonparent={setPersonchild}
                />

                <div className="formagree">
                  <label>
                    <input
                      type="checkbox"
                      checked={agree}
                      onChange={() => {
                        setAgree((agree) => !agree);
                        if (dmpList) {
                          window.Dmp.decideAction(dmpList.btn1, !agree);
                        }
                      }}
                    />
                    本人同意
                    <a
                      href="/policy/privacy-policy"
                      target="_blank"
                      onClick={(event) => {
                        event.stopPropagation();
                        if (dmpList) {
                          window.Dmp.clickAction(dmpList.btn2, '/policy/privacy-policy');
                        }
                      }}
                    >
                      《广汽本田隐私政策》
                    </a>
                    ；广汽本田将对您提交的个人信息保密，不对外公开。
                  </label>
                </div>

                <div className="formsubmit">
                  <button
                    type="button"
                    onClick={() => {
                      personSubmit();
                      if (dmpList) {
                        window.Dmp.clickAction(dmpList.btn4);
                      }
                    }}
                  >
                    确认提交
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showVerificationCode && (
          <FullModalDetail style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="financial-calculator-v1-verification-code">
              <div className="verification-code-form">
                <img
                  alt="广汽本田"
                  className="verification-code-close"
                  src={createFilePath('/financial/calculator/close.png')}
                  onClick={() => close()}
                />
                <div className="verification-code-item">
                  <div className="verification-code-item-name">手机号：</div>
                  <div className="verification-code-item-right">{person?.mobile}</div>
                </div>
                <div className="verification-code-item">
                  <div className="verification-code-item-name">图形验证码：</div>
                  <img alt="广汽本田" className="verification-code-item-image" src={verificationImage} onClick={getVerificationImage} />
                  <div className="verification-code-item-right">
                    <input
                      className="verification-code-item-input"
                      ref={verificationCodeRef}
                      type="text"
                      placeholder="请输入图形验证码"
                      onChange={verificationCodeChange}
                    />
                  </div>
                </div>
                <div className="verification-code-item">
                  <div className="verification-code-item-name">短信验证码：</div>
                  <button className="verification-code-item-button" onClick={sendMessage}>
                    发送
                  </button>
                  <div className="verification-code-item-right">
                    <input
                      className="verification-code-item-input"
                      ref={messageCodeRef}
                      type="text"
                      placeholder="请输入短信验证码"
                      onChange={messageCodeChange}
                    />
                  </div>
                </div>
                <button className="verification-code-button" onClick={() => personSubmit()}>
                  确定
                </button>
              </div>
            </div>
          </FullModalDetail>
        )}
      </div>
    </section>
  );
}
