import React, { useState, useEffect } from 'react';
import temp from '../../temp/config';
import { getPrefixField, sendDmpAction, createFilePath } from '../../utils/obj-utils';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const [init, setInit] = useState(true);
  let isAutoPause = false;
  return (
    <div className="fullVideoContainer">
      <video
        x5-playsinline="true"
        webkit-playsinline="true"
        playsInline={true}
        className="fullVideo"
        src={createFilePath(fields[getPrefixField('detail_video')].value)}
        autoPlay="autoplay"
        muted={false}
        controls
        onPause={() => {
          setTimeout(() => {
            setInit(false);
            if (!isAutoPause) {
              sendDmpAction(
                fields[getPrefixField('stop_video_dmp')]?.length > 0 ? fields[getPrefixField('stop_video_dmp')][0].fields.func.value : '',
                fields[getPrefixField('stop_video_dmp')]?.length > 0 ? fields[getPrefixField('stop_video_dmp')][0].name : '',
                ''
              );
            } else {
              isAutoPause = false;
            }
          }, 0);
        }}
        onPlay={() => {
          !init &&
            sendDmpAction(
              fields[getPrefixField('play_video_dmp')]?.length > 0 ? fields[getPrefixField('play_video_dmp')][0].fields.func.value : '',
              fields[getPrefixField('play_video_dmp')]?.length > 0 ? fields[getPrefixField('play_video_dmp')][0].name : '',
              ''
            );
        }}
        onEnded={() => {
          isAutoPause = true;
        }}
      />
      <img
        alt="广汽本田"
        className="closeVideo"
        src={createFilePath(fields[getPrefixField('close_icon')].value)}
        onClick={() => {
          props.close();
          sendDmpAction(
            fields[getPrefixField('close_icon_dmp')]?.length > 0 ? fields[getPrefixField('close_icon_dmp')][0]?.fields?.func?.value : '',
            fields[getPrefixField('close_icon_dmp')]?.length > 0 ? fields[getPrefixField('close_icon_dmp')][0].name : '',
            fields[getPrefixField('close_dmp_value')].value ? createFilePath(fields[getPrefixField('close_dmp_value')].value) : ''
          );
        }}
      />
    </div>
  );
}
