import React, { useEffect, useState, useRef, createRef, useImperativeHandle } from 'react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import { Swiper } from 'swiper';
import { Pagination, Navigation } from 'swiper';
import './style.scss';
import temp from '../../temp/config';
import { getPrefixField, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Icon from '../../elements/icon';

export default function idnex(props) {
  const { fields, eventRef, params } = props;
  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const backgroundRef = useRef(null);
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const carBackgroundRef = useRef(null);
  const carRef = useRef(null);
  const isMobile = isMobileFun();
  // const deployUrlS3 = temp.app.deployUrlS3;
  const sectionDmp = fields.sectionDmp;
  const dmpPointLeft = fields.dmpPointLeft;
  const dmpPointRight = fields.dmpPointRight;
  const dmpPointMore = fields?.dmpPointMore;
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const image1 = fields[getPrefixField('image1', isMobile)]?.value;
  const image2 = fields[getPrefixField('image2', isMobile)]?.value;
  const image3 = fields[getPrefixField('image3', isMobile)]?.value;
  const list = fields[getPrefixField('list', isMobile)];
  const [data, _] = useState({ sectionDmp, dmpPointLeft, dmpPointRight, dmpPointMore, title, image1, image2, image3, list, isMobile });
  const [num, setNum] = useState(0);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(data.sectionDmp);
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'media';
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const swiper = new Swiper(swiperRef.current, {
      grabCursor: true,
      modules: [Pagination, Navigation],
      // spaceBetween: 2,
      slidesPerView: data.isMobile ? 1 : 3,
      grid: {
        fill: 'column',
        rows: 1
      },
      pagination: data.isMobile
        ? {
            el: containerRef.current.querySelector('.swiper-pagination'),
            clickable: true
          }
        : false,
      navigation: {
        prevEl: containerRef.current.querySelector('.navigation-prev'),
        nextEl: containerRef.current.querySelector('.navigation-next'),
        disabledClass: 'navigation-disabled'
      },
      on: {
        slideNextTransitionEnd: function (swiper) {
          setNum((num) => {
            if (0 <= num + swiper.activeIndex - swiper.previousIndex <= data.list.length - 1) {
              return num + swiper.activeIndex - swiper.previousIndex;
            } else {
              return num;
            }
          });
        },
        slidePrevTransitionEnd: function (swiper) {
          setNum((num) => {
            if (0 <= num + swiper.activeIndex - swiper.previousIndex <= data.list.length - 1) {
              return num + swiper.activeIndex - swiper.previousIndex;
            } else {
              return num;
            }
          });
        }
      }
    });
    for (let i = 0; i < swiper.pagination.bullets.length; i++) {
      swiper.pagination.bullets[i].onclick = function () {
        sendDmp([data?.dmpPointMore[i]]);
      };
    }
    swiper.navigation.prevEl.onclick = function () {
      sendDmp(data.dmpPointLeft);
    };
    swiper.navigation.nextEl.onclick = function () {
      sendDmp(data.dmpPointRight);
    };

    return () => {
      swiper.destroy(true, true);
    };
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const controller = new ScrollMagic.Controller();
    const timeline = gsap.timeline({ paused: true });

    const scene1Timeline = gsap.timeline({
      paused: true,
      onComplete: () => {
        timeline.kill();
      },
      onReverseComplete: () => {
        timeline.kill();
      }
    });

    data.isMobile
      ? scene1Timeline
          .fromTo(carRef.current, { x: '100vw' }, { x: '0', duration: 1 })
          // .fromTo(carBackgroundRef.current, { opacity: 0 }, { opacity: 1, duration: 0.1 })
          .fromTo(rightRef.current, { opacity: 1 }, { opacity: 0, duration: 0.1 }, 1.5)
          .fromTo(backgroundRef.current, { display: 'block' }, { display: 'none', duration: 0.1 }, '<')
          .fromTo(leftRef.current, { opacity: 0 }, { opacity: 1, duration: 0.1 }, '<')
      : scene1Timeline
          .fromTo(carRef.current, { x: '100vw' }, { x: '0', duration: 1 })
          // .fromTo(carBackgroundRef.current, { opacity: 0 }, { opacity: 1, duration: 0.1 })
          .fromTo(leftRef.current, { opacity: 0 }, { opacity: 1, duration: 0.1 });

    const scene1TimelineTemp = gsap.timeline({
      duration: 1,
      onStart: () => {
        scene1Timeline.play();
      },
      onReverseComplete: () => {
        scene1Timeline.reverse();
      }
    });
    timeline.add(scene1TimelineTemp);

    const scene1 = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: isMobile ? 0.2 : 0.5,
      duration: document.documentElement.clientHeight / 5
    })
      .on('progress', function (event) {
        timeline.totalProgress(event.progress);
      })
      .setTween(timeline)
      .addTo(controller);

    return () => {
      scene1Timeline.kill();
      scene1TimelineTemp.kill();
      scene1.remove();
      scene1.destroy();

      controller.destroy();
    };
  }, [data]);

  return (
    <section>
      <div className="publicPraise_v3" ref={containerRef} id={params?.pagename !== undefined ? params?.pagename : 'media'}>
        {data.isMobile && <div className="headline" dangerouslySetInnerHTML={{ __html: data.title?.replaceHtml() }} />}
        <img alt="广汽本田" className="background" src={`${createFilePath(data.image1)}`} ref={backgroundRef} />
        <div className="praise_left" ref={leftRef}>
          <div className="praise_left_content">
            {!data.isMobile && <div dangerouslySetInnerHTML={{ __html: data.title?.replaceHtml() }} />}
            <div className="swiper-container">
              <div className="swiper-support" ref={swiperRef}>
                <div className="swiper-wrapper">
                  <React.Fragment>
                    {data.list?.map((item, index) => {
                      return (
                        <div className="swiper-slide" key={index}>
                          <img alt="广汽本田" className="image" src={`${createFilePath(item.fields.image.value)}`} />
                          {isMobile && (
                            <div className="swiper-text">
                              <div className="title" dangerouslySetInnerHTML={{ __html: data.list[num]?.fields?.title?.value?.replaceHtml() }} />
                              {!data.isMobile && <div className="line" />}
                              {!data.isMobile && (
                                <div
                                  className="describe"
                                  dangerouslySetInnerHTML={{ __html: data.list[num]?.fields?.describe?.value?.replaceHtml() }}
                                />
                              )}
                              <div dangerouslySetInnerHTML={{ __html: data.list[num]?.fields?.subTitle?.value?.replaceHtml() }} />
                              <button
                                onClick={() => {
                                  sendDmp(data.list[num]?.fields?.btn_dmp, data.list[num]?.fields?.btn_href?.value);
                                  window.open(data.list[num]?.fields?.btn_href?.value, data.list[num]?.fields?.btn_target?.fields.v.value);
                                }}
                              >
                                {data.list[num]?.fields?.btn?.value}
                              </button>
                            </div>
                          )}
                          {index !== num && !data.isMobile && (
                            <div
                              className="image_background"
                              onClick={() => {
                                sendDmp(item.fields?.img_dmp);
                                setNum(index);
                              }}
                            >
                              <div className="editText" dangerouslySetInnerHTML={{ __html: item.fields?.editText?.value?.replaceHtml() }} />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </React.Fragment>
                </div>
              </div>
              <div className="bottomIcon">
                <div className="navigation-prev">
                  <Icon name="ArrowLeft" />
                </div>
                <div className="navigation-next">
                  <Icon name="ArrowRight" />
                </div>
                {data.isMobile && <div className="swiper-pagination" />}
              </div>
            </div>
            {!isMobile && (
              <div className="swiper-text">
                <div className="title" dangerouslySetInnerHTML={{ __html: data.list[num]?.fields?.title?.value?.replaceHtml() }} />
                {!data.isMobile && <div className="line" />}
                {!data.isMobile && (
                  <div className="describe" dangerouslySetInnerHTML={{ __html: data.list[num]?.fields?.describe?.value?.replaceHtml() }} />
                )}
                <div dangerouslySetInnerHTML={{ __html: data.list[num]?.fields?.subTitle?.value?.replaceHtml() }} />
                <button
                  onClick={() => {
                    sendDmp(data.list[num]?.fields?.btn_dmp, data.list[num]?.fields?.btn_href?.value);
                    window.open(data.list[num]?.fields?.btn_href?.value, data.list[num]?.fields?.btn_target?.fields.v.value);
                  }}
                >
                  {data.list[num]?.fields?.btn?.value}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="praise_right" ref={rightRef}>
          <div className="praise_right_content">
            {!isMobile && <img alt="广汽本田" className="right_background" src={`${createFilePath(data.image3)}`} ref={carBackgroundRef} />}
            <img alt="广汽本田" className="right_car" src={`${createFilePath(data.image2)}`} ref={carRef} />
          </div>
        </div>
      </div>
    </section>
  );
}
