import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import { Swiper } from 'swiper';
import { Pagination, Navigation } from 'swiper';
import temp from '../../temp/config';
import './style.scss';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import Icon from '../../elements/icon';
import LazyLoad from 'react-lazyload';
export default function index({ fields, eventRef }) {
  const holderImg = <img alt="广汽本田" src={createFilePath('/common/placeholder.jpg')} />;
  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  // const deployUrlS3 = temp.app.deployUrlS3;
  const isMobile = isMobileFun();
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const title_style = fields[getPrefixField('title_style', isMobile)]?.value;
  const background = fields[getPrefixField('background', isMobile)]?.value;
  const container_style = fields[getPrefixField('container_style', isMobile)]?.value;
  const anchor = fields.anchor?.value;
  const blackBackground = fields?.blackBackground.value;
  const imgList = fields[getPrefixField('imgList', isMobile)];
  const dmpPointLeft = fields?.dmpPointLeft;
  const dmpPointRight = fields?.dmpPointRight;
  const dmpPointMore = fields?.dmpPointMore;
  const [data, _] = useState({
    imgList,
    isMobile,
    title,
    title_style,
    container_style,
    background,
    anchor,
    blackBackground,
    dmpPointLeft,
    dmpPointRight,
    dmpPointMore
  });

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields?.sectionDmp);
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = data?.anchor;
  };

  useEffect(() => {
    if (!data || !swiperRef.current) {
      return;
    }

    const swiper = new Swiper(swiperRef.current, {
      grabCursor: true,
      modules: [Pagination, Navigation],
      slidesPerView: data?.isMobile ? 1 : 3,
      grid: {
        fill: 'column',
        rows: 1
      },
      pagination: data?.isMobile
        ? {
            el: containerRef.current.querySelector(blackBackground ? '.swiper-pagination-black' : '.swiper-pagination'),
            clickable: true
          }
        : false,
      navigation: {
        prevEl: containerRef.current.querySelector('.navigation-prev'),
        nextEl: containerRef.current.querySelector('.navigation-next'),
        disabledClass: 'navigation-disabled'
      }
    });
    for (let i = 0; i < swiper.pagination.bullets.length; i++) {
      swiper.pagination.bullets[i].onclick = function () {
        sendDmp([data?.dmpPointMore[i]]);
      };
    }
    swiper.navigation.prevEl.onclick = function () {
      sendDmp(data?.dmpPointLeft);
    };
    swiper.navigation.nextEl.onclick = function () {
      sendDmp(data?.dmpPointRight);
    };

    return () => {
      swiper.destroy(true, true);
    };
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const publicPraiseTimeline = gsap.timeline({ pause: true });
    if (!data.isMobile) {
      publicPraiseTimeline.fromTo(swiperRef.current, { x: '100%', opacity: 0 }, { x: '0', opacity: 1 });
    } else {
      publicPraiseTimeline.fromTo(titleRef.current, { opacity: 0 }, { opacity: 1 }).fromTo(swiperRef.current, { x: '100%' }, { x: '0' });
    }
    const controller = new ScrollMagic.Controller();
    const publicPraiseScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onEnter',
      duration: data.isMobile ? document.documentElement.clientHeight : '80%'
    })
      .on('progress', function (event) {
        publicPraiseTimeline.progress(event.progress);
      })
      .setTween(publicPraiseTimeline)
      .addTo(controller);

    return () => {
      publicPraiseTimeline.kill();
      publicPraiseScene.remove();
      publicPraiseScene.destroy();
      controller.destroy();
    };
  }, [data]);

  return (
    <section id={data?.anchor} className="container_publicPraiseV2" ref={containerRef} style={convertStylesStringToObject(data.container_style)}>
      {data ? (
        <div className="publicPraiseV2" style={convertStylesStringToObject(data.background)}>
          <div className="content-container">
            <div
              className="titleV2"
              ref={titleRef}
              dangerouslySetInnerHTML={{ __html: data.title?.replaceHtml() }}
              style={convertStylesStringToObject(data.title_style)}
            />
            <div className="swiper-container">
              <div className="swiper-support" ref={swiperRef}>
                <div className="swiper-wrapper">
                  <React.Fragment>
                    {data?.imgList?.map((item, index) => {
                      return (
                        <div className="swiper-slide" key={index}>
                          <div className="cardDetail" style={convertStylesStringToObject(item.fields.cardDetail_style?.value)}>
                            <LazyLoad placeholder={holderImg}>
                              <img
                                className="img"
                                src={`${createFilePath(item.fields.img.value)}`}
                                style={convertStylesStringToObject(item.fields.img_style?.value)}
                                alt={item.fields.title.value}
                              />
                            </LazyLoad>
                            <div className="title" dangerouslySetInnerHTML={{ __html: item.fields.title.value?.replaceHtml() }} />
                            {item.fields.subTitle.value && (
                              <div className="subTitle" dangerouslySetInnerHTML={{ __html: item.fields.subTitle.value?.replaceHtml() }} />
                            )}
                            <button
                              className="btnDetails"
                              onClick={() => {
                                sendDmp(
                                  item.fields.btnDmp,
                                  item.fields.pc_btn_href?.value !== '' && !isMobile ? item.fields.pc_btn_href?.value : item.fields.btn_href?.value
                                );
                                window.open(
                                  item.fields.pc_btn_href?.value !== '' && !isMobile ? item.fields.pc_btn_href?.value : item.fields.btn_href?.value,
                                  item.fields.btn_target?.fields.v.value
                                );
                              }}
                              style={convertStylesStringToObject(fields.btnStyle?.value)}
                            >
                              了解详情
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </React.Fragment>
                </div>
              </div>
              <div className="bottomIcon">
                <div className="navigation-prev" style={convertStylesStringToObject(fields.navigationColor.value)}>
                  <Icon name="ArrowLeft" />
                </div>
                <div className="navigation-next" style={convertStylesStringToObject(fields.navigationColor.value)}>
                  <Icon name="ArrowRight" />
                </div>
                {data?.isMobile && <div className={data?.blackBackground ? 'swiper-pagination-black' : 'swiper-pagination'} />}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
}
