import React, { useState, useRef, useImperativeHandle, useEffect, createRef } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { sendDmp, isMobileFun } from '../../utils/obj-utils';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import gsap from 'gsap';
export default function index(props) {
  const { fields, eventRef, params } = props;
  const isMobile = isMobileFun();
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const rootEl = useRef(null);
  const secondPageRef = useRef(null);
  const sectionDmp = fields?.sectionDmp;
  const placeHolderRoute = fields?.placeHolderRoute.value;
  const [data, _] = useState({
    sectionDmp,
    placeHolderRoute
  });

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(data?.sectionDmp);
    // 二级导航高亮设置
    window.navigationIndex = fields?.navigationIndex?.value;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'price_space';
  };

  useEffect(() => {
    const timelineList = [];
    const timelineListItem = [];
    const controller = new ScrollMagic.Controller();
    const timeline = gsap.timeline({ paused: true });

    let c = secondPageRef.current.children;
    secondPageRef.current.style.width = '100vw';
    let length = c.length;
    for (let i = 0; i < c.length; i++) {
      c[i].style.display = 'inline-block';
      c[i].style.position = 'absolute';
      const timelineItem = gsap.timeline({
        paused: true,
        onComplete: () => {
          timeline.kill();
        },
        onReverseComplete: () => {
          timeline.kill();
        }
      });
      if (i > 0) {
        timelineItem.fromTo(c[i], { transform: 'translate3d(100vw, 0px, 0px)' }, { transform: 'translate3d(0vw, 0px, 0px)', duration: 1 });
        let cc = c[i]?.children[0]?.children;
        if (cc) {
          for (let j = 0; j < cc.length; j++) {
            if (cc[j].className.indexOf('security_integra_inner') !== -1) {
              timelineItem.fromTo(cc[j], { opacity: 0, zIndex: -1 }, { duration: 0.2, opacity: 1, zIndex: 1 });
            }
          }
        }
      }
      timelineListItem.push(timelineItem);
      const timelineTemp = gsap.timeline({
        duration: 1,
        onStart: () => {
          timelineListItem[i]?.play();
        },
        onReverseComplete: () => {
          timelineListItem[i]?.reverse();
        }
      });
      timeline.add(timelineTemp);
      timelineList.push(timelineTemp);
    }
    const scene = new ScrollMagic.Scene({
      triggerElement: rootEl?.current,
      triggerHook: 'onLeave',
      duration: length * document.documentElement.clientHeight
    })
      .on('progress', function (event) {
        timeline.totalProgress(event.progress);
      })
      .setPin(rootEl?.current)
      .addTo(controller);
    return () => {
      timelineList.forEach((item) => item.kill());
      timelineListItem.forEach((item) => item.kill());

      scene.remove();
      scene.destroy();

      controller.destroy();
    };
  }, []);

  return (
    <section>
      <div className="PriceSpace_v6" id={params?.pagename !== undefined ? params?.pagename : 'price_space'} ref={rootEl}>
        <div ref={secondPageRef}>
          <Placeholder
            name={data?.placeHolderRoute}
            updateDmpList={props.updateDmpList}
            updateRefList={props.updateRefList}
            rendering={route}
            page={route}
          />
        </div>
      </div>
    </section>
  );
}
