/* eslint-disable no-eval */
import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap, { Linear } from 'gsap';
import './style.scss';
import {
  convertStylesStringToObject,
  getPrefixField,
  sendDmp,
  debounce,
  countUp,
  createFilePath,
  imageLoaded as imageLoadedFunc,
  isMobileFun
} from '../../utils/obj-utils';

export default function index({ fields, eventRef }) {
  const containerRef = useRef(null);
  const content0Ref = useRef(null);
  const content1Ref = useRef(null);
  const content2Ref = useRef(null);
  const content3Ref = useRef(null);
  const dotImage1Ref = useRef(null);
  const lineContainer1Ref = useRef(null);
  const line1Ref = useRef(null);
  const dotImage2Ref = useRef(null);
  const lineContainer2Ref = useRef(null);
  const line2Ref = useRef(null);
  const imageContainerRef = useRef(null);
  const carImageRef = useRef(null);
  const wheelImage1Ref = useRef(null);
  const wheelImage2Ref = useRef(null);
  const debounceCallAnimateRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const isMobile = isMobileFun();
  const sectionDmp = fields?.sectionDmp;
  const leftArrowDmp = fields?.leftArrowDmp;
  const rightArrowDmp = fields?.rightArrowDmp;
  const anchor = fields?.anchor?.value;
  const content0 = fields[getPrefixField('content0', isMobile)]?.value;
  const content1 = fields[getPrefixField('content1', isMobile)]?.value;
  const content2 = fields[getPrefixField('content2', isMobile)]?.value;
  const content3 = fields[getPrefixField('content3', isMobile)]?.value;
  const leftArrowImage = createFilePath(fields[getPrefixField('leftArrowImage', isMobile)]?.value);
  const rightArrowImage = createFilePath(fields[getPrefixField('rightArrowImage', isMobile)]?.value);
  const carImage = createFilePath(fields[getPrefixField('carImage', isMobile)]?.value);
  const carImageStyle = convertStylesStringToObject(fields[getPrefixField('carImageStyle', isMobile)]?.value);
  const wheelImage = createFilePath(fields[getPrefixField('wheelImage', isMobile)]?.value);
  const wheelImageStyle = convertStylesStringToObject(fields[getPrefixField('wheelImageStyle', isMobile)]?.value);
  const dotImage = createFilePath(fields[getPrefixField('dotImage', isMobile)]?.value);
  const dotImageStyle = convertStylesStringToObject(fields[getPrefixField('dotImageStyle', isMobile)]?.value);
  const imageContainerStyle = convertStylesStringToObject(fields[getPrefixField('imageContainerStyle', isMobile)]?.value);
  const lineStyle = convertStylesStringToObject(fields[getPrefixField('lineStyle', isMobile)]?.value);
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const [animateIndex, setAnimateIndex] = useState(0);
  const [data, _] = useState({
    sectionDmp,
    leftArrowDmp,
    rightArrowDmp,
    anchor,
    content0,
    content1,
    content2,
    content3,
    leftArrowImage,
    rightArrowImage,
    carImage,
    carImageStyle,
    wheelImage,
    wheelImageStyle,
    dotImage,
    dotImageStyle,
    imageContainerStyle,
    lineStyle,
    style
  });
  const imageNaturalWidth = isMobile ? '217.3%' : '109.7%';
  const imageMiddleWidth = isMobile ? '206.4%' : '104.2%';
  const imageMiniWidth = isMobile ? '106%' : '84%';
  const imageXPosition1 = isMobile ? '60%' : '37%';
  const imageXPosition2 = isMobile ? '68%' : '42%';
  const imageXPosition3 = isMobile ? '9%' : '-4%';
  const imageXPosition4 = isMobile ? '6%' : '61%';
  const length = 3;
  const animateList = [];
  const dynamicNumberList = Array(length);
  let wheelAnimate;

  useEffect(() => {
    require('swiped-events');
    imageLoadedFunc(carImageRef, () => {
      setImageLoaded(true);
    });
  }, []);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(data?.sectionDmp);
    // 二级导航高亮设置
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = data?.anchor;
  };

  useEffect(() => {
    if (!imageLoaded) {
      return;
    }

    initDynamicNumberList();
    initLine();

    wheelAnimate = animate4();
    resetWheel(false);

    debounceCallAnimateRef.current = debounce(callAnimate, 50);
    debounceCallAnimateRef?.current(animateIndex);

    return () => {
      if (!imageLoaded) {
        return;
      }

      wheelAnimate.kill();

      debounceCallAnimateRef?.current?.cancel();
    };
  }, [imageLoaded]);

  useEffect(() => {
    if (!imageLoaded || !isMobile) {
      return;
    }

    containerRef?.current?.addEventListener('swiped-left', rightArrowClick);
    containerRef?.current?.addEventListener('swiped-right', leftArrowClick);

    return () => {
      if (!imageLoaded || !isMobile) {
        return;
      }

      containerRef?.current?.removeEventListener('swiped-left', rightArrowClick);
      containerRef?.current?.removeEventListener('swiped-right', leftArrowClick);
    };
  }, [imageLoaded, animateIndex]);

  const initDynamicNumberList = () => {
    for (let index = 0; index < length; index++) {
      dynamicNumberList[index] = Array.from(eval(`content${index + 1}Ref`)?.current?.getElementsByClassName('dynamic-number'))?.map((item) => ({
        element: item,
        content: item?.innerText
      }));
    }
  };

  const initLine = () => {
    const content1Element = content1Ref?.current?.firstChild;
    const containerWidth = containerRef?.current?.offsetWidth;
    const containerHeight = containerRef?.current?.offsetHeight;
    const topMargin = Math.min((isMobile ? 0.025 : 0.033) * containerHeight, isMobile ? 20 : 30);
    const imageRect = getImageRect();
    const imageWidth = imageRect.imageWidth;
    const imageHeight = imageRect.imageHeight;
    const imageContainerHeight = imageRect.imageContainerHeight;
    const bottomMargin = containerHeight - imageContainerRef?.current?.offsetTop - imageContainerHeight;
    let topOffset, top, left, bottom, right;
    if (content1Element) {
      topOffset = dotImage1Ref?.current?.offsetHeight + topMargin;
      top = content1Element?.offsetTop + content1Element?.offsetHeight + topOffset;
      bottom = imageHeight * 0.5 + bottomMargin;
      if (!isMobile) {
        left = content1Element?.offsetLeft + (content1Element?.offsetWidth / 5) * 2;
        right = containerWidth - (containerWidth - imageWidth * (1 - parseFloat(imageXPosition1) / 100) + imageWidth * 0.12);
      } else {
        left = containerWidth - imageWidth * (1 - parseFloat(imageXPosition1) / 100) + imageWidth * 0.12;
        right = content1Element?.offsetLeft + (content1Element?.offsetWidth / 5) * 2;
      }
      lineContainer1Ref.current.style.top = `${top}px`;
      lineContainer1Ref.current.style.left = `${left}px`;
      lineContainer1Ref.current.style.bottom = `${bottom}px`;
      lineContainer1Ref.current.style.right = `${right}px`;

      const polyline = line1Ref?.current?.getElementsByClassName('polyline')[0];
      const strokeWidth = parseFloat(getComputedStyle(polyline)['stroke-width']?.replace('px', ''));
      const x1 = strokeWidth / 2;
      const y1 = 0;
      const x2 = containerWidth - (left + right) - strokeWidth / 2;
      const y2 = containerHeight - (top + bottom) - strokeWidth / 2;
      polyline.setAttribute('points', isMobile ? `${x2},${y1} ${x2},${y2 / 2} ${x1},${y2 / 2} ${x1},${y2}` : `${x1},${y1} ${x1},${y2} ${x2},${y2}`);
      polyline.style.strokeDashoffset = polyline.getTotalLength();
      polyline.style.strokeDasharray = polyline.getTotalLength();
    }
    const content3Element = content3Ref?.current?.firstChild;
    if (content3Element) {
      topOffset = dotImage2Ref?.current?.offsetHeight + topMargin;
      top = content3Element?.offsetTop + content3Element?.offsetHeight + topOffset;
      bottom = imageHeight * 0.15 + bottomMargin;
      if (!isMobile) {
        left = containerWidth - (containerWidth - imageWidth * (parseFloat(imageXPosition4) / 100) + imageWidth * 0.24);
        right = containerWidth - content3Element?.offsetLeft - content3Element?.offsetWidth + content3Element?.offsetWidth / 2;
      } else {
        left = containerWidth * 0.55;
        right = containerWidth * 0.11;
      }

      lineContainer2Ref.current.style.top = `${top}px`;
      lineContainer2Ref.current.style.left = `${left}px`;
      lineContainer2Ref.current.style.bottom = `${bottom}px`;
      lineContainer2Ref.current.style.right = `${right}px`;

      const polyline = line2Ref?.current?.getElementsByClassName('polyline')[0];
      const strokeWidth = parseFloat(getComputedStyle(polyline)['stroke-width']?.replace('px', ''));
      const x1 = strokeWidth / 2;
      const y1 = 0;
      const x2 = containerWidth - (left + right) - strokeWidth / 2;
      const y2 = containerHeight - (top + bottom) - strokeWidth / 2;
      polyline.setAttribute(
        'points',
        isMobile ? `${x1},${y1} ${x1},${y2 / 4} ${x2},${y2 / 4} ${x2},${y2} ${x1},${y2}` : `${x2},${y1} ${x2},${y2} ${x1},${y2}`
      );
      polyline.style.strokeDashoffset = polyline.getTotalLength();
      polyline.style.strokeDasharray = polyline.getTotalLength();
    }
  };

  const getImageRect = () => {
    const containerWidth = containerRef?.current?.offsetWidth;
    const imageContainerWidth = imageContainerRef?.current?.offsetWidth;
    const imageContainerHeight = imageContainerRef?.current?.offsetHeight;
    const imageContainerRatio = imageContainerWidth / imageContainerHeight;
    const imageWidth = (containerWidth * parseFloat(imageNaturalWidth)) / 100;
    const imageHeight = imageWidth / imageContainerRatio;

    return { imageWidth, imageHeight, imageContainerWidth, imageContainerHeight };
  };

  const getAnimate3XPosition = () => {
    const containerWidth = containerRef?.current?.offsetWidth;
    const imageRect = getImageRect();
    const imageWidth = imageRect.imageWidth;
    const scale = parseFloat(imageXPosition4) / 100;
    const x = isMobile ? scale * imageWidth : -(containerWidth - imageWidth * scale);

    return x;
  };

  const callAnimate = (index, reverse = false) => {
    resetAnimate(index, reverse);
    animateList.push(eval(`animate${index}`)(reverse, index - 1));
  };

  const animate0 = (reverse = false, index) => {
    const timeline = gsap
      .timeline({
        onComplete: () => {
          timeline.kill();
        },
        onReverseComplete: () => {
          timeline.kill();
        }
      })
      .fromTo(content0Ref?.current, { opacity: 0 }, { opacity: 1, duration: 1.5 });
    reverse && timeline.reverse(0);

    return timeline;
  };

  const animate1 = (reverse = false, index) => {
    const timeline = gsap
      .timeline({
        onComplete: () => {
          timeline.kill();
        },
        onReverseComplete: () => {
          timeline.kill();
        }
      })
      .fromTo(content0Ref?.current, { opacity: 1 }, { opacity: 0, duration: 0.5 })
      .fromTo(imageContainerRef?.current, { x: '100%' }, { x: imageXPosition1, duration: 1.5 }, '<')
      .fromTo(
        content1Ref?.current,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.5,
          onStart: () => {
            dynamicNumberAnimate(index);
          }
        }
      )
      .fromTo(dotImage1Ref?.current, { opacity: 0 }, { opacity: 1, duration: 0.5 });
    const polyline = line1Ref?.current?.getElementsByClassName('polyline')[0];
    lineAnimate(polyline, timeline);
    reverse && timeline.reverse(0);

    return timeline;
  };

  const animate2 = (reverse = false, index) => {
    const polyline = line1Ref?.current?.getElementsByClassName('polyline')[0];
    const timeline = gsap.timeline({
      onUpdate: () => {
        if (timeline.currentLabel() === 'p1') {
          resetWheel(!reverse);
        }
      },
      onComplete: () => {
        timeline.kill();
      },
      onReverseComplete: () => {
        timeline.kill();
      }
    });
    if (!reverse) {
      timeline
        .to(imageContainerRef?.current, { x: imageXPosition2, width: imageMiddleWidth, duration: 0.5 })
        .to(content1Ref?.current, { opacity: 0, duration: 0.5 }, '<')
        .to(dotImage1Ref?.current, { opacity: 0, duration: 0.5 }, '<');
      lineAnimate(polyline, timeline, '<', true);
      timeline
        .addLabel('p1')
        .to(imageContainerRef?.current, { x: imageXPosition3, width: imageMiniWidth, duration: 1.0 }, '<')
        .to(
          content2Ref?.current,
          {
            opacity: 1,
            duration: 1.5,
            onStart: () => {
              dynamicNumberAnimate(index);
            }
          },
          '<'
        );
    } else {
      timeline
        .to(imageContainerRef?.current, { x: imageXPosition2, width: imageMiddleWidth, duration: 1.0 })
        .to(content2Ref?.current, { opacity: 0, duration: 1.0 }, '<')
        .addLabel('p1')
        .to(imageContainerRef?.current, { x: imageXPosition1, width: imageNaturalWidth, duration: 0.5 })
        .to(content1Ref?.current, { opacity: 1, duration: 0.5 }, '<')
        .to(dotImage1Ref?.current, { opacity: 1, duration: 0.5 }, '<');
      lineAnimate(polyline, timeline, '<');
    }

    return timeline;
  };

  const animate3 = (reverse = false, index) => {
    const x = getAnimate3XPosition();
    const timeline = gsap
      .timeline({
        onComplete: () => {
          timeline.kill();
        },
        onReverseComplete: () => {
          resetWheel();
          timeline.kill();
        }
      })
      .fromTo(imageContainerRef?.current, { width: imageMiniWidth, x: imageXPosition3 }, { width: imageNaturalWidth, x, duration: 1 })
      .fromTo(
        content2Ref?.current,
        { opacity: 1 },
        {
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            resetWheel(false);
          }
        },
        '<'
      )
      .fromTo(
        content3Ref?.current,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.5,
          onStart: () => {
            dynamicNumberAnimate(index);
          },
          onReverseComplete: () => {
            dynamicNumberAnimate(index - 1);
          }
        },
        '<80%'
      )
      .fromTo(dotImage2Ref?.current, { opacity: 0 }, { opacity: 1, duration: 0.5 });
    const polyline = line2Ref?.current?.getElementsByClassName('polyline')[0];
    lineAnimate(polyline, timeline);
    reverse && timeline.reverse(0);

    return timeline;
  };

  const animate4 = () => {
    const timeline = gsap
      .timeline()
      .fromTo(wheelImage1Ref?.current, { rotate: 0 }, { rotate: -360, duration: 0.7, repeat: -1, ease: Linear.easeNone })
      .fromTo(wheelImage2Ref?.current, { rotate: 0 }, { rotate: -360, duration: 0.7, repeat: -1, ease: Linear.easeNone }, '<');

    return timeline;
  };

  const lineAnimate = (line, timeline, position = '>', reverse = false, duration = 0.25) => {
    const pathLength = line.getTotalLength();
    const pathObj = { offset: reverse ? 0 : pathLength };
    timeline.fromTo(
      pathObj,
      { ...pathObj },
      { offset: reverse ? pathLength : 0, onUpdate: drawLine, onUpdateParams: [line, pathObj], duration, ease: Linear.easeNone },
      position
    );
  };

  const dynamicNumberAnimate = (index) => {
    dynamicNumberList[index].forEach((item) => countUp(item?.element, item?.content));
  };

  const resetAnimate = (index = 0, reverse) => {
    animateList.forEach((item) => item.kill());
    animateList.length = 0;
    switch (index) {
      case 1:
        content2Ref.current.style.opacity = 0;
        content3Ref.current.style.opacity = 0;
        dotImage2Ref.current.style.opacity = 0;
        line1Ref.current.style.display = 'block';
        line2Ref.current.style.display = 'none';
        imageContainerRef.current.style.width = imageNaturalWidth;
        resetWheel(false);
        break;
      case 2:
        content3Ref.current.style.opacity = 0;
        dotImage2Ref.current.style.opacity = 0;
        if (reverse) {
          dotImage1Ref.current.style.opacity = 0;
        } else {
          dotImage1Ref.current.style.opacity = 1;
        }
        line1Ref.current.style.display = 'block';
        line2Ref.current.style.display = 'none';
        break;
      case 3:
        content1Ref.current.style.opacity = 0;
        dotImage1Ref.current.style.opacity = 0;
        line1Ref.current.style.display = 'none';
        line2Ref.current.style.display = 'block';
        break;
      default:
        break;
    }
  };

  const resetWheel = (play = true) => {
    const display = play ? 'block' : 'none';
    if (wheelImage1Ref.current.style.display !== display) {
      wheelImage1Ref.current.style.display = display;
      wheelImage2Ref.current.style.display = display;
      if (play) {
        wheelAnimate.play();
      } else {
        wheelAnimate.pause();
      }
    }
  };

  const drawLine = (line, obj) => {
    line.style.strokeDashoffset = obj.offset;
  };

  const leftArrowClick = () => {
    if (animateIndex > 0) {
      sendDmp(data?.leftArrowDmp);
      debounceCallAnimateRef?.current(animateIndex, true);
      setAnimateIndex(animateIndex - 1);
    }
  };

  const rightArrowClick = () => {
    if (animateIndex < length) {
      sendDmp(data?.rightArrowDmp);
      debounceCallAnimateRef?.current(animateIndex + 1);
      setAnimateIndex(animateIndex + 1);
    }
  };

  return (
    <div className="price-power-v2" id={data?.anchor} ref={containerRef} style={data?.style}>
      <div className="content0" dangerouslySetInnerHTML={{ __html: data?.content0?.replaceHtml() }} ref={content0Ref} />
      <div className="content1" dangerouslySetInnerHTML={{ __html: data?.content1?.replaceHtml() }} ref={content1Ref} />
      <div className="content2" dangerouslySetInnerHTML={{ __html: data?.content2?.replaceHtml() }} ref={content2Ref} />
      <div className="content3" dangerouslySetInnerHTML={{ __html: data?.content3?.replaceHtml() }} ref={content3Ref} />
      <div className="line-container1" ref={lineContainer1Ref}>
        <svg className="line" style={data?.lineStyle} ref={line1Ref}>
          <polyline className="polyline" />
        </svg>
        <img alt="广汽本田" className="dot-image" src={data?.dotImage} style={data?.dotImageStyle} ref={dotImage1Ref} />
      </div>
      <div className="line-container2" ref={lineContainer2Ref}>
        <svg className="line" style={data?.lineStyle} ref={line2Ref}>
          <polyline className="polyline" />
        </svg>
        <img alt="广汽本田" className="dot-image" src={data?.dotImage} style={data?.dotImageStyle} ref={dotImage2Ref} />
      </div>
      <div className="image-container" ref={imageContainerRef} style={data?.imageContainerStyle}>
        <img alt="广汽本田" className="car-image" src={data?.carImage} style={data?.carImageStyle} ref={carImageRef} />
        <img alt="广汽本田" className="wheel-image1" src={data?.wheelImage} style={data?.wheelImageStyle} ref={wheelImage1Ref} />
        <img alt="广汽本田" className="wheel-image2" src={data?.wheelImage} style={data?.wheelImageStyle} ref={wheelImage2Ref} />
      </div>
      {animateIndex > 0 && <img alt="广汽本田" className="left-arrow-image" src={createFilePath(data?.leftArrowImage)} onClick={leftArrowClick} />}
      {animateIndex < length && (
        <img alt="广汽本田" className="right-arrow-image" src={createFilePath(data?.rightArrowImage)} onClick={rightArrowClick} />
      )}
    </div>
  );
}
