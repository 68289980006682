import React from 'react';
import classNames from 'classnames';
import Icon from '../icon';
import './style.scss';

const Checkbox = ({ label, checked, onChange = () => {} }) => {
  const rootClasses = classNames('e-checkbox', { checked });
  const toggle = () => {
    console.log('toggle');
    onChange && onChange(!checked);
  };
  return (
    <div className={rootClasses}>
      <Icon name="Check" className="e-checkbox__icon" onClick={(e) => toggle()} />
      <div className="e-checkbox__label" onClick={toggle} dangerouslySetInnerHTML={{ __html: label?.replaceHtml() }} />
    </div>
  );
};

export default Checkbox;
