import React from 'react';
import './style.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import temp from '../../temp/config';
import { getPrefixField, createFilePath } from '../../utils/obj-utils';

export default function index(props) {
  const { fields, windowHeight, thisMobile, rootEl, openDetail, checkCar } = props;
  return (
    <Controller>
      {/* indicators={true} */}
      <Scene triggerHook={0} duration={windowHeight - 0} offset={0} pin={'#oneservice'}>
        {(progress, event) => {
          return (
            <div>
              <div
                className={`Campaign ${fields?.text_bg_class?.value}`}
                id="oneservice"
                ref={rootEl}
                onClick={() => {
                  thisMobile && openDetail();
                }}
                style={{ cursor: `url(${createFilePath(fields?.look_video_icon?.value)}), pointer` }}
              >
                <Timeline totalProgress={progress} paused>
                  {thisMobile ? (
                    <Tween from={{ width: '400px' }} to={{ width: '0px' }} duration={4} position={4}>
                      <div className={`leftBg ${fields?.text_bg_class?.value}`}></div>
                    </Tween>
                  ) : null}
                  <Tween
                    from={thisMobile ? { width: '150px', height: '250px', y: '0' } : { width: '100px', height: '200px' }}
                    to={thisMobile ? { width: '100%', height: '100%', y: 0 } : { width: '100%' }}
                    duration={4}
                    position={0}
                  >
                    <div className="OneVideo">
                      {/* TASK GIF */}
                      {fields[`${getPrefixField('face_source_type')}`].value === 'video' && (
                        <video
                          src={`${createFilePath(fields[`${getPrefixField('face_source')}`].value)}`}
                          muted={true}
                          loop
                          autoPlay="autoplay"
                        ></video>
                      )}
                      {fields[`${getPrefixField('face_source_type')}`].value === 'img' && (
                        <img alt="广汽本田" src={`${createFilePath(fields[`${getPrefixField('face_source')}`].value)}`}></img>
                      )}
                    </div>
                  </Tween>
                  {thisMobile && (
                    <Tween from={{ width: '400px' }} to={{ width: '0px' }} duration={4} position={4}>
                      <div className={`rightBg ${fields?.text_bg_class?.value}`}></div>
                    </Tween>
                  )}
                  <div className={`OneText ${fields.text_class.value}`} style={{ display: progress > 0.9 ? 'none' : '' }}>
                    <Tween from={{ x: '-1000px' }} to={thisMobile ? { x: '72px' } : { x: '32px' }} duration={4} position={0}>
                      <div className="OneLeft">
                        <div>
                          {fields[`${getPrefixField('context_title')}`].value.split(fields.split_str.value).map((item, index) => {
                            return <p key={index}>{item}</p>;
                          })}
                        </div>
                      </div>
                    </Tween>
                    <Tween
                      from={thisMobile ? { x: '1500px' } : { x: '1000px' }}
                      to={thisMobile ? { x: '40px' } : { x: '32px' }}
                      duration={4}
                      position={1}
                    >
                      <div className="OneRight">
                        <div>
                          {fields[`${getPrefixField('title')}`].value.split(fields.split_str.value).map((ele, index) => {
                            return <p key={index}>{ele}</p>;
                          })}
                        </div>
                        <div className="RightBottom">
                          {fields[`${getPrefixField('context')}`].value.split(fields.split_str.value).map((ele, index) => {
                            return <p key={index}>{ele}</p>;
                          })}
                        </div>
                      </div>
                    </Tween>
                  </div>
                  <div className="OneQR">
                    {thisMobile ? (
                      <Tween from={{ y: '-200%' }} to={{ y: 0 }} position={4} duration={1}>
                        <img alt="广汽本田" src={`${createFilePath(fields.code_img.value)}`} className="QRcode" />
                      </Tween>
                    ) : (
                      <button className={`lookAllVideo ${fields.look_video_btn_class.value}`} onClick={() => openDetail()}>
                        {fields.video_btn_text.value}
                      </button>
                    )}
                    <button
                      data-action={fields.car_btn_dmp?.length > 0 ? fields.car_btn_dmp[0]?.fields?.func?.value : ''}
                      data-item={fields.car_btn_dmp?.length > 0 ? fields.car_btn_dmp[0].name : ''}
                      data-url="weixin://dl/business/?t=qbF4ZexiHkt"
                      className="mobileLookCar"
                      onClick={() => checkCar()}
                    >
                      <a
                        data-action={fields.car_btn_dmp?.length > 0 ? fields.car_btn_dmp[0]?.fields?.func?.value : ''}
                        data-item={fields.car_btn_dmp?.length > 0 ? fields.car_btn_dmp[0].name : ''}
                        data-url="weixin://dl/business/?t=qbF4ZexiHkt"
                      >
                        {fields.car_btn_text.value}
                      </a>
                    </button>
                  </div>
                </Timeline>
              </div>
            </div>
          );
        }}
      </Scene>
    </Controller>
  );
}
