import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import temp from '../../temp/config';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import LazyLoad from 'react-lazyload';

export default function index({ fields, eventRef }) {
  const holderImg = <img alt="广汽本田" src={createFilePath('/common/placeholder.jpg')} />;
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const imgRef = useRef(null);
  const isMobile = isMobileFun();
  const [tabsindex, setTabsndex] = useState(0);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields?.sectionDmp);
  };

  useEffect(() => {
    if (!fields || fields?.disableAnimate?.value) {
      return;
    }

    const textImageTimeline = gsap.timeline({ pause: true });
    if (!isMobile) {
      textImageTimeline.fromTo(containerRef.current, { x: fields?.reverse?.value ? '100vw' : '-100vw', opacity: 0 }, { x: '0', opacity: 1 });
    } else {
      textImageTimeline
        .fromTo(textRef.current, { x: '100vw' }, { x: '0' })
        .fromTo(imgRef.current, { x: '-100vw', opacity: 0 }, { x: '0', opacity: 1 });
    }
    const controller = new ScrollMagic.Controller();
    const textImageScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onEnter',
      duration: isMobile ? document.documentElement.clientHeight : '100%'
    })
      .on('progress', function (event) {
        textImageTimeline.progress(event.progress);
      })
      .setTween(textImageTimeline)
      .addTo(controller);

    return () => {
      textImageTimeline.kill();
      textImageScene.remove();
      textImageScene.destroy();

      controller.destroy();
    };
  }, [fields]);

  return (
    <LazyLoad placeholder={holderImg}>
      <section className="container_textImage" ref={containerRef}>
        <div className="textImage" style={convertStylesStringToObject(fields[getPrefixField('container_style', isMobile)]?.value)}>
          <div className="tabs">
            {fields &&
              fields?.lists?.map((item, index) => {
                const title = item?.fields[getPrefixField('title', isMobile)]?.value;
                const btn_href = item?.fields.btn_href?.value;
                const tabDmp = item?.fields.tabDmp;
                const IsNoContent = item?.fields.IsNoContent?.value;
                const isdefault = item?.fields.default?.value;
                const [tabsdata, _] = useState({
                  title,
                  btn_href,
                  tabDmp,
                  IsNoContent,
                  isdefault
                });
                return (
                  <React.Fragment key={index}>
                    <div
                      className={`item ${tabsindex ? (tabsindex === Number(index) + 1 ? 'on' : '') : tabsdata.isdefault ? 'on' : ''}`}
                      dangerouslySetInnerHTML={{ __html: title?.replaceHtml() }}
                      onClick={() => {
                        if (IsNoContent) {
                          window.open(tabsdata.btn_href, '_self');
                          sendDmp(tabsdata.tabDmp, tabsdata.btn_href);
                        } else {
                          setTabsndex(Number(index) + 1);
                          sendDmp(tabsdata.tabDmp);
                        }
                      }}
                    ></div>
                  </React.Fragment>
                );
              })}
          </div>
          {fields &&
            fields?.lists?.map((item, index) => {
              const img = `${createFilePath(item?.fields[getPrefixField('image', isMobile)]?.value)}`;
              const description = item?.fields[getPrefixField('description', isMobile)]?.value;
              const img_style = item?.fields[getPrefixField('img_style', isMobile)]?.value;
              const text_style = item?.fields[getPrefixField('text_style', isMobile)]?.value;
              const btn_href = item?.fields.btn_href?.value;
              const btnDmp = item?.fields.btnDmp;
              const IsNoContent = item?.fields.IsNoContent?.value;
              const isdefault = item?.fields.default?.value;
              const btn = item?.fields[getPrefixField('btn', isMobile)]?.value;
              const [data, _] = useState({
                img,
                description,
                img_style,
                text_style,
                btn_href,
                btnDmp,
                btn,
                IsNoContent,
                isdefault
              });
              return (
                !IsNoContent && (
                  <React.Fragment key={index}>
                    <div className={`in ${tabsindex ? (tabsindex === Number(index) + 1 ? 'on' : '') : data.isdefault ? 'on' : ''}`}>
                      <div className="text" ref={textRef} style={convertStylesStringToObject(data.text_style)}>
                        <div className="textDetail">
                          <div className="description" dangerouslySetInnerHTML={{ __html: data.description?.replaceHtml() }} />
                          <button
                            className="btnDetails"
                            onClick={() => {
                              sendDmp(data.btnDmp, data.btn_href);
                              window.open(data.btn_href, '_self');
                            }}
                            dangerouslySetInnerHTML={{ __html: data.btn?.replaceHtml() }}
                          ></button>
                        </div>
                      </div>
                      <div className="image" ref={imgRef}>
                        <img className="img" src={data.img} style={convertStylesStringToObject(data.img_style)} alt={data.title} />
                      </div>
                    </div>
                  </React.Fragment>
                )
              );
            })}
        </div>
      </section>
    </LazyLoad>
  );
}
