export default {
  top_left_data: [
    {
      title: '车型总览',
      listItem: [
        {
          title: '雅阁',
          linkUrl: 'https://www.ghac.cn/vehicles/accord',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/accord'
        },
        {
          title: '凌派',
          linkUrl: 'https://www.ghac.cn/vehicles/crider',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/crider'
        },
        {
          title: '绎乐',
          linkUrl: 'https://www.ghac.cn/vehicles/EA6',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/EA6/p'
        },
        {
          title: '飞度',
          linkUrl: 'https://www.ghac.cn/vehicles/fit',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/fit'
        },
        {
          title: '奥德赛 锐·混动',
          linkUrl: 'https://www.ghac.cn/vehicles/odyssey',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/odyssey'
        },
        {
          title: '冠道',
          linkUrl: 'https://www.ghac.cn/vehicles/avancier',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/avancier'
        },
        {
          title: '皓影',
          linkUrl: 'https://www.ghac.cn/vehicles/breeze?tab=oil',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/breeze?tab=oil'
        },
        {
          title: '皓影 锐·混动e+',
          linkUrl: 'https://www.ghac.cn/vehicles/breeze?tab=plugin',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/breeze?tab=plugin'
        },
        {
          title: '致在',
          linkUrl: 'https://www.ghac.cn/vehicles/Z',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/Z'
        },
        {
          title: '缤智',
          linkUrl: 'https://www.ghac.cn/vehicles/vezel',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/vezel'
        },
        {
          title: '极湃1',
          linkUrl: 'https://www.ghac.cn/vehicles/enp1',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/enp1'
        },
        {
          title: '型格',
          linkUrl: 'https://www.ghac.cn/vehicles/integra',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/integra'
        },
        {
          title: 'VE-1',
          linkUrl: 'https://www.ghac.cn/vehicles/VE-1',
          pc_linkUrl: 'https://www.ghac.cn/vehicles/VE-1/p'
        }
      ],
      linkUrl: 'https://www.ghac.cn/vehicles',
      pc_linkUrl: 'https://www.ghac.cn/vehicles'
    },
    {
      title: '购车支持',
      listItem: [
        {
          title: '购车流程',
          linkUrl: 'https://www.ghac.cn/buy/tools/process',
          pc_linkUrl: 'https://www.ghac.cn/buy/tools/process'
        },
        {
          title: '广汽本田商城',
          linkUrl: 'https://mall.ghac.cn/?channel=GW',
          pc_linkUrl: 'https://mall.ghac.cn/?channel=GW'
        },
        {
          title: '广汽本田云展厅',
          linkUrl: 'https://vehicles.ghac.cn',
          pc_linkUrl: 'https://vehicles.ghac.cn'
        },
        {
          title: '车型对比',
          linkUrl: 'https://www.ghac.cn/ModelContrast',
          pc_linkUrl: 'https://www.ghac.cn/ModelContrast/p'
        },
        {
          title: '贷款计算器',
          linkUrl: 'https://www.ghac.cn/buy/tools/financial/components/financeCalculator',
          pc_linkUrl: 'https://www.ghac.cn/buy/tools/financial/components/financeCalculator'
        },
        {
          title: '特约店查询',
          linkUrl: '/buy/tools/find-dealer',
          pc_linkUrl: '/buy/tools/find-dealer'
        },
        {
          title: '喜悦二手车官网',
          linkUrl: 'https://usedcar.ghac.cn',
          pc_linkUrl: 'https://usedcar.ghac.cn'
        }
      ],
      linkUrl: 'https://www.ghac.cn/buy/tools/process',
      pc_linkUrl: 'https://www.ghac.cn/buy/tools/process'
    },
    {
      title: '客户服务',
      listItem: [
        {
          title: '服务品牌',
          linkUrl: 'https://www.ghac.cn/service/funlink',
          pc_linkUrl: 'https://www.ghac.cn/service/funlink/p'
        },
        {
          title: '服务动态',
          linkUrl: 'https://www.ghac.cn/service/service-activities',
          pc_linkUrl: 'https://www.ghac.cn/service/service-activities'
        },
        {
          title: '车辆操作指南',
          linkUrl: 'https://www.ghac.cn/Layouts/Activity/2016/0317/manual',
          pc_linkUrl: 'https://www.ghac.cn/Layouts/Activity/2016/0317/manual'
        },
        {
          title: '用车知识',
          linkUrl: 'https://www.ghac.cn/service/knowledge',
          pc_linkUrl: 'https://www.ghac.cn/service/knowledge'
        },
        {
          title: '零部件价格',
          linkUrl: 'https://www.ghac.cn/service/part-price',
          pc_linkUrl: 'https://www.ghac.cn/service/part-price'
        },
        {
          title: '纯正用品',
          linkUrl: 'https://www.ghac.cn/service/appliance',
          pc_linkUrl: 'https://www.ghac.cn/service/appliance'
        },
        {
          title: '三包及保养信息',
          linkUrl: 'https://www.ghac.cn/service/repair-guide',
          pc_linkUrl: 'https://www.ghac.cn/service/repair-guide'
        },
        {
          title: '喜悦延保',
          linkUrl: 'https://www.ghac.cn/service/service-insurance',
          pc_linkUrl: 'https://www.ghac.cn/service/service-insurance'
        },
        {
          title: '环保信息公开',
          linkUrl: 'https://www.ghac.cn/service/environment',
          pc_linkUrl: 'https://www.ghac.cn/service/environment'
        },
        {
          title: '新能源车售后服务承诺',
          linkUrl: 'https://www.ghac.cn/service/NEV',
          pc_linkUrl: 'https://www.ghac.cn/service/NEV'
        },
        {
          title: '维修技术信息查询',
          linkUrl: 'https://service.gagc.com.cn/mtds-web/login',
          pc_linkUrl: 'https://service.gagc.com.cn/mtds-web/login'
        }
      ],
      linkUrl: 'https://www.ghac.cn/service/funlink',
      pc_linkUrl: 'https://www.ghac.cn/service/funlink/p'
    },
    {
      title: '品牌天地',
      listItem: [
        {
          title: '新闻资讯',
          linkUrl: 'https://www.ghac.cn/news',
          pc_linkUrl: 'https://www.ghac.cn/news'
        },
        {
          title: '精彩活动',
          linkUrl: 'https://www.ghac.cn/activity',
          pc_linkUrl: 'https://www.ghac.cn/activity'
        },
        {
          title: '互动中心',
          linkUrl: 'https://www.ghac.cn/interaction',
          pc_linkUrl: 'https://www.ghac.cn/interaction'
        },
        {
          title: '先进技术',
          linkUrl: 'https://www.ghac.cn/technology',
          pc_linkUrl: 'https://www.ghac.cn/technology'
        },
        {
          title: '儿童道路安全教育',
          linkUrl: 'https://www.ghac.cn/Layouts/Activity/2019/0808/child_safety',
          pc_linkUrl: 'https://www.ghac.cn/Layouts/Activity/2019/0808/child_safety/p'
        }
      ],
      linkUrl: 'https://www.ghac.cn/about/brandling/avancier',
      pc_linkUrl: 'https://www.ghac.cn/about/brandling/avancier'
    },
    {
      title: '关于我们',
      listItem: [
        {
          title: '公司介绍',
          linkUrl: 'https://www.ghac.cn/about/introducing',
          pc_linkUrl: 'https://www.ghac.cn/about/introducing'
        },
        {
          title: '企业社会责任',
          linkUrl: 'https://www.ghac.cn/about/Social-Responsibility',
          pc_linkUrl: 'https://www.ghac.cn/about/Social-Responsibility'
        },
        {
          title: '企业参观预约',
          linkUrl: 'https://reception.ghac.cn',
          pc_linkUrl: 'https://reception.ghac.cn'
        },
        {
          title: '诚聘英才',
          linkUrl: 'https://job.ghac.cn',
          pc_linkUrl: 'https://job.ghac.cn'
        },
        {
          title: '梦想同行Club',
          linkUrl: 'https://www.ghac.cn/mcar-owners-club',
          pc_linkUrl: 'https://www.ghac.cn/car-owners-club'
        }
      ],
      linkUrl: 'https://www.ghac.cn/about/introducing',
      pc_linkUrl: 'https://www.ghac.cn/about/introducing'
    },
    {
      title: '友情链接',
      listItem: [
        {
          title: '广汽集团',
          linkUrl: 'https://www.gac.com.cn/cn',
          pc_linkUrl: 'https://www.gac.com.cn/cn'
        },
        {
          title: '本田中国',
          linkUrl: 'https://www.honda.com.cn',
          pc_linkUrl: 'https://www.honda.com.cn'
        }
      ],
      linkUrl: ''
    }
  ],
  bottom_other_link_title: [
    {
      title: '建店申请',
      linkUrl: 'https://www.ghac.cn/build-apply',
      pc_linkUrl: 'https://www.ghac.cn/build-apply'
    },
    {
      title: '企业邮箱',
      linkUrl: 'https://mail.ghac.cn/owa/auth/logon.aspx?replaceCurrent=1&url=https%3a%2f%2fmail.ghac.cn%2fowa',
      pc_linkUrl: 'https://mail.ghac.cn/owa/auth/logon.aspx?replaceCurrent=1&url=https%3a%2f%2fmail.ghac.cn%2fowa'
    },
    {
      title: '网站地图',
      linkUrl: 'https://www.ghac.cn/sitemap',
      pc_linkUrl: 'https://www.ghac.cn/sitemap'
    },
    {
      title: 'Cookie政策',
      linkUrl: 'https://www.ghac.cn/information-cookie',
      pc_linkUrl: 'https://www.ghac.cn/information-cookie'
    },
    {
      title: '诚聘英才',
      linkUrl: 'https://job.ghac.cn',
      pc_linkUrl: 'https://job.ghac.cn'
    },
    {
      title: '联系我们',
      linkUrl: 'https://www.ghac.cn/contact-us',
      pc_linkUrl: 'https://www.ghac.cn/contact-us'
    },
    {
      title: '网站声明',
      linkUrl: 'https://www.ghac.cn/declaration',
      pc_linkUrl: 'https://www.ghac.cn/declaration'
    }
  ],
  top_right_data: [
    {
      title: '广汽本田天猫官方旗舰店',
      icon_src: '/breeze/v1/pc/footer/Tmall.png',
      linkUrl: 'https://ghac.tmall.com'
    },
    {
      title: '广汽本田官方微博',
      icon_src: '/breeze/v1/pc/footer/Microblog.png',
      linkUrl: 'https://weibo.com/u/2270998083'
    },
    {
      title: '广汽本田官方微信',
      icon_src: '/breeze/v1/pc/footer/WeChat.png',
      linkUrl: ''
    },
    {
      title: '',
      icon_src: '/breeze/v1/pc/footer/QrCode.png',
      linkUrl: ''
    },
    {
      title: '800热线：800-830-8999 <br/>400热线：400-830-8999',
      icon_src: '/breeze/v1/pc/footer/Phone.png',
      linkUrl: ''
    }
  ]
};
