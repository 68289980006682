import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, pcTorem, mbTorem, getQueryString, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import { swiperAnimateCache, swiperAnimate, clearSwiperAnimate } from '../../utils/swiper.animate1.0.3.min';
import FullVideo from '../../elements/FullVideo';
import CountUp from 'react-countup';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const [windowHeight, setWindowHeight] = useState(400);
  const [swipertypeflag, setSwipertypeflag] = useState(true);
  const swiperRef = useRef(null);
  const [showcountnum, setShowcountnum] = useState(false);
  const [flag, setFlag] = useState(false);
  const [swiperflag, setSwiperflag] = useState(false);
  const isMobile = isMobileFun();
  // const hosturl = temp.app.deployUrlS3;
  const [showFooter, setShowFooter] = useState(true);
  if (isMobile) {
    // var accordhosturl = hosturl + '/accord/v2/mobile';
    var accordhosturl = createFilePath('/accord/v2/mobile');
  } else {
    // var accordhosturl = hosturl + '/accord/v2/pc';
    var accordhosturl = createFilePath('/accord/v2/pc');
  }
  const id = props?.params?.pagename;
  const videodata = {
    detail_video: createFilePath('/accord/v2/pc/exterior/video.mp4?20240701'),
    close_icon_dmp: {
      type: 'click',
      point: 'price_exterior-video-close',
      value1: ''
    }
  };
  const data = {
    id
  };
  const openVideo = () => {
    window.Dmp.clickAction('price_exterior-video-open');
    setFlag(true);
  };
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);
  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
  };
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span  data-html-data="price_exterior-btn' + (index + 1) + '" class="' + className + '"></span>';
    }
  };
  useEffect(() => {
    if (
      window.location.href.indexOf('accord-show') !== -1 ||
      getQueryString('source') === 'app' ||
      window.location.href.indexOf('app/vehicles/accord') !== -1
    ) {
      setShowFooter(false);
    }
  }, []);
  const windowhidden = () => {
    document.documentElement.style.overflow = 'hidden';
  };
  const closewindowhidden = () => {
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.overflowX = 'hidden';
  };
  return (
    <>
      <div className={!showFooter ? 'modules-grid exterior noheader' : 'modules-grid exterior'}>
        <div className="triggerstart" id="exterior"></div>
        {!isMobile ? (
          <Controller>
            <Scene triggerHook={'onLeave'} duration={windowHeight * 2} pin={true} reverse={true} triggerElement={'#exterior'}>
              {(progress, event) => {
                if (progress > 0.5 && progress <= 1) {
                  if (swiperRef?.current?.swiper) {
                    swiperAnimate(swiperRef.current.swiper);
                  }
                }
                return (
                  <div id={data?.id}>
                    <Timeline
                      totalProgress={progress}
                      paused
                      target={
                        <div className={'showvideo'}>
                          <video
                            src={createFilePath('/accord/v2/pc/exterior/video.mp4?20240701')}
                            autoPlay="autoplay"
                            muted={true}
                            className="video"
                            loop
                          />
                        </div>
                      }
                      duration={2}
                    >
                      <Tween to={{ opacity: 0 }} position={0.3} duration={0.6} />
                      <Tween to={{ scale: 0.7 }} position={0.5} duration={0.55} />
                      <Tween to={{ zIndex: 0 }} position={0.5} duration={0.4} />
                      {/* <Tween to={{ right: '-80%' }} position={0.6} duration={0.7} />
                      <Tween to={{ top: '-80%' }} position={0.6} duration={0.7} /> */}
                    </Timeline>
                    <Swiper
                      ref={swiperRef}
                      slidesPerView={1}
                      className="modules-swiper"
                      spaceBetween={0}
                      initialSlide={0}
                      observer={true}
                      resizeObserver={true}
                      updateOnWindowResize={true}
                      modules={[Navigation]}
                      navigation={{
                        prevEl: '.exterior .prev',
                        nextEl: '.exterior .next',
                        disabledClass: 'disabled'
                      }}
                      onInit={(e) => {
                        clearSwiperAnimate(e);
                        swiperAnimateCache(e);
                      }}
                      onTransitionStart={(e) => {
                        setShowcountnum(false);
                      }}
                      onSlideChangeTransitionEnd={(e) => {
                        setTimeout(function () {
                          swiperAnimate(e);
                          setShowcountnum(true);
                        }, 0);
                      }}
                    >
                      <SwiperSlide className={'griditem griditem0'}>
                        <img
                          className="bg"
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior0.jpg')
                              : createFilePath('/accord/v2/pc/exterior/exterior0.jpg')
                          }
                          alt="广汽本田"
                        />
                        <img
                          className="car ani"
                          swiper-animate-effect="animate__fadeInTopLight20deg"
                          swiper-animate-duration="1s"
                          swiper-animate-delay="0s"
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior0_car.png')
                              : createFilePath('/accord/v2/pc/exterior/exterior0_car.png')
                          }
                          alt="广汽本田"
                        />
                        <div
                          className="t ani"
                          swiper-animate-effect="animate__fadeInDownBig"
                          swiper-animate-duration="1.5s"
                          swiper-animate-delay="0s"
                        >
                          优雅光韵造型
                        </div>
                        <div
                          className="sub ani"
                          swiper-animate-effect="animate__fadeInUpBig"
                          swiper-animate-duration="1.5s"
                          swiper-animate-delay="0s"
                        >
                          <div className="en">AESTHETIC VISUAL EXCITEMENT</div>
                          <div className="cn">一场刺激视觉神经的美学狂欢</div>
                        </div>
                        <div
                          className="videomin1 ani"
                          swiper-animate-effect="animate__fadeInDownBig"
                          swiper-animate-duration="1.5s"
                          swiper-animate-delay="0s"
                          onClick={() => {
                            openVideo();
                          }}
                        >
                          <img src={createFilePath('/accord/v2/pc/exterior/video.gif')} className="video" alt="广汽本田" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'griditem griditem1'}>
                        <img
                          className="bg"
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior1.jpg')
                              : createFilePath('/accord/v2/pc/exterior/exterior1.jpg')
                          }
                          alt="广汽本田"
                        />
                        <img
                          className="car "
                          // swiper-animate-effect="animate__fadeInRightBig"
                          // swiper-animate-duration="1s"
                          // swiper-animate-delay="0s"
                          // src={accordhosturl + '/exterior/exterior1_car.png'}
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior1_car.png')
                              : createFilePath('/accord/v2/pc/exterior/exterior1_car.png')
                          }
                          alt="广汽本田"
                        />
                        <div
                          className="sub ani"
                          swiper-animate-effect="animate__fadeInDownBig"
                          swiper-animate-duration="0.5s"
                          swiper-animate-delay="0s"
                        >
                          <div className="en">PUSHING BEYOND THE BOUNDARIES OF THE STANDARD</div>
                          <div className="cn">超出规格 不止一点点</div>
                        </div>
                        <div
                          className="bottom ani"
                          swiper-animate-effect="animate__fadeInUpBig"
                          swiper-animate-duration="0.5s"
                          swiper-animate-delay="0s"
                        >
                          <div className="lista">
                            <div className="li">
                              <div className="n">轴距</div>
                              <div className="val">
                                <b className="num1">{showcountnum ? <CountUp duration={1.6} useGrouping={false} start={0} end={2830} /> : '2830'}</b>
                                mm
                              </div>
                            </div>
                            <div className="li">
                              <div className="n">车长+70mm</div>
                              <div className="val">
                                <b className="num2">{showcountnum ? <CountUp duration={1.6} useGrouping={false} start={0} end={4980} /> : '4980'}</b>
                                mm
                              </div>
                            </div>
                            <div className="li">
                              <div className="n">车宽</div>
                              <div className="val">
                                <b className="num3">{showcountnum ? <CountUp duration={1.6} useGrouping={false} start={0} end={1862} /> : '1862'}</b>
                                mm
                              </div>
                            </div>
                          </div>
                          <div className="tip">全新雅阁升级车身尺寸，给你越级空间体验。</div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'griditem griditem2'}>
                        {/* <img className="bg" src={accordhosturl + '/exterior/exterior2.jpg'} /> */}
                        <img
                          className="bg"
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior2.jpg')
                              : createFilePath('/accord/v2/pc/exterior/exterior2.jpg')
                          }
                          alt="广汽本田"
                        />
                        <img
                          className="car "
                          // swiper-animate-effect="animate__fadeInTopRight"
                          // swiper-animate-duration="1s"
                          // swiper-animate-delay="0s"
                          // src={accordhosturl + '/exterior/exterior2_car.png'}
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior2_car.png')
                              : createFilePath('/accord/v2/pc/exterior/exterior2_car.png')
                          }
                          alt="广汽本田"
                        />
                        <div
                          className="sub ani"
                          swiper-animate-effect="animate__fadeInLeftBig"
                          swiper-animate-duration=".5s"
                          swiper-animate-delay="0s"
                        >
                          <div className="en">ADVANCED VISUAL APPEAL HARVESTS ALL ATTENTIONS</div>
                          <div className="cn">赏心悦目 收割一切回头率</div>
                        </div>
                        <div
                          className="list ani"
                          swiper-animate-effect="animate__fadeInRightBig"
                          swiper-animate-duration=".5s"
                          swiper-animate-delay="0s"
                        >
                          <div className="li">灵动前脸机能造型</div>
                          <div className="li">天蓝色车标</div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'griditem griditem3'}>
                        {/* <img className="bg" src={accordhosturl + '/exterior/exterior3.jpg'} /> */}
                        <img
                          className="bg"
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior3.jpg')
                              : createFilePath('/accord/v2/pc/exterior/exterior3.jpg')
                          }
                          alt="广汽本田"
                        />
                        <img
                          className="car "
                          // swiper-animate-effect="animate__fadeInUpBig"
                          // swiper-animate-duration="1s"
                          // swiper-animate-delay="0s"
                          // src={accordhosturl + '/exterior/exterior3_car.png'}
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior3_car.png')
                              : createFilePath('/accord/v2/pc/exterior/exterior3_car.png')
                          }
                          alt="广汽本田"
                        />
                        <div
                          className="sub ani"
                          swiper-animate-effect="animate__fadeInRightBig"
                          swiper-animate-duration=".5s"
                          swiper-animate-delay="0s"
                        >
                          <div className="en">THE EXTERIOR CATCHES EVERY GLANCE</div>
                          <div className="cn">目光所至 掠夺所有的焦点</div>
                        </div>
                        <div
                          className="list ani"
                          swiper-animate-effect="animate__fadeInLeftBig"
                          swiper-animate-duration=".5s"
                          swiper-animate-delay="0s"
                        >
                          <div className="li">薄型化LED远近光灯</div>
                          <div className="li">高反差结构日间行车灯</div>
                          <div className="li">流光转向灯</div>
                          <div className="li">ADB自适应远光灯</div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'griditem griditem4'}>
                        {/* <img className="bg" src={accordhosturl + '/exterior/exterior4.jpg'} style={{ objectPosition: 'center bottom' }} /> */}
                        <img
                          className="bg"
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior4.jpg')
                              : createFilePath('/accord/v2/pc/exterior/exterior4.jpg')
                          }
                          style={{ objectPosition: 'center bottom' }}
                          alt="广汽本田"
                        />
                        <img
                          className="car "
                          // swiper-animate-effect="animate__fadeInUpBig"
                          // swiper-animate-duration="1s"
                          // swiper-animate-delay="0s"
                          // src={accordhosturl + '/exterior/exterior4_car.png'}
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior4_car.png')
                              : createFilePath('/accord/v2/pc/exterior/exterior4_car.png')
                          }
                          alt="广汽本田"
                        />
                        <div
                          className="sub ani"
                          swiper-animate-effect="animate__fadeInDownBig"
                          swiper-animate-duration=".5s"
                          swiper-animate-delay="0s"
                        >
                          <div className="en">THE RED LIGHT QUIETS THE NOISY STREETS</div>
                          <div className="cn">这一抹红 平息街道的喧嚣</div>
                        </div>
                        <div
                          className="list ani"
                          swiper-animate-effect="animate__fadeInDownBig"
                          swiper-animate-duration=".5s"
                          swiper-animate-delay="0s"
                        >
                          <div className="li">穿越型LED组合尾灯</div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'griditem griditem5'}>
                        {/* <img className="bg" src={accordhosturl + '/exterior/exterior5.jpg'} /> */}
                        <img
                          className="bg"
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior5.jpg')
                              : createFilePath('/accord/v2/pc/exterior/exterior5.jpg')
                          }
                          alt="广汽本田"
                        />
                        <img
                          className="car "
                          // swiper-animate-effect="animate__fadeInBottomLeft"
                          // swiper-animate-duration="1s"
                          // swiper-animate-delay="0s"
                          // src={accordhosturl + '/exterior/exterior5_car.png'}
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior5_car.png')
                              : createFilePath('/accord/v2/pc/exterior/exterior5_car.png')
                          }
                          alt="广汽本田"
                        />
                        <div
                          className="list ani"
                          swiper-animate-effect="animate__fadeInUpBig"
                          swiper-animate-duration=".5s"
                          swiper-animate-delay="0s"
                        >
                          <div className="li">流畅洗练后包围</div>
                          <div className="li">动感扰流板饰条</div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'griditem griditem6'}>
                        {/* <img className="bg" src={accordhosturl + '/exterior/exterior6.jpg'} /> */}
                        <img
                          className="bg"
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior6.jpg')
                              : createFilePath('/accord/v2/pc/exterior/exterior6.jpg')
                          }
                          alt="广汽本田"
                        />
                        <img
                          className="car "
                          // swiper-animate-effect="animate__fadeInBottomRight45deg"
                          // swiper-animate-duration="1s"
                          // swiper-animate-delay="0s"
                          // src={accordhosturl + '/exterior/exterior6_car.png'}
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior6_car.png')
                              : createFilePath('/accord/v2/pc/exterior/exterior6_car.png')
                          }
                          alt="广汽本田"
                        />
                        <div
                          className="t-img "
                          // swiper-animate-effect="animate__fadeInUpBig"
                          // swiper-animate-duration="1.5s"
                          // swiper-animate-delay="0s"
                        >
                          {/* <img src={accordhosturl + '/exterior/exterior6_t.png'} /> */}
                          <img
                            src={
                              isMobile
                                ? createFilePath('/accord/v2/mobile/exterior/exterior6_t.png')
                                : createFilePath('/accord/v2/pc/exterior/exterior6_t.png')
                            }
                            alt="广汽本田"
                          />
                        </div>
                        <div
                          className="sub ani"
                          swiper-animate-effect="animate__fadeInUpBig"
                          swiper-animate-duration="0.5s"
                          swiper-animate-delay="0s"
                        >
                          <div className="en">AN ORNATE DRIVINGEXPERIENCE </div>
                          <div className="cn">以豪华优雅驱动酣畅驾趣体验</div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className={'griditem griditem7'}>
                        {/* <img className="bg" src={accordhosturl + '/exterior/exterior7.jpg'} /> */}
                        <img
                          className="bg"
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior7.jpg')
                              : createFilePath('/accord/v2/pc/exterior/exterior7.jpg')
                          }
                          alt="广汽本田"
                        />
                        <div
                          className="sub ani"
                          swiper-animate-effect="animate__fadeInRightBig"
                          swiper-animate-duration="1s"
                          swiper-animate-delay="0s"
                        >
                          <div className="en">DAZZLING WHEELS LEAD THE PATH</div>
                          <div className="cn">转动间 引领风的轨迹</div>
                        </div>
                        <div
                          className="list ani"
                          swiper-animate-effect="animate__fadeInLeftBig"
                          swiper-animate-duration="1s"
                          swiper-animate-delay="0s"
                        >
                          <div className="li">19英寸磨砂钢琴黑切削轮辋</div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <div className="next" data-html-item="price_exterior-btnr"></div>
                    <div className="prev" data-html-item="price_exterior-btnl"></div>
                  </div>
                );
              }}
            </Scene>
          </Controller>
        ) : (
          <>
            <div className={'griditem griditem0'} id={data?.id}>
              <img
                className="bg"
                src={isMobile ? createFilePath('/accord/v2/mobile/exterior/exterior0.jpg') : createFilePath('/accord/v2/pc/exterior/exterior0.jpg')}
                alt="广汽本田"
              />
              <div className="text">
                <div className="t ani" swiper-animate-effect="animate__fadeInDownBig" swiper-animate-duration="1.5s" swiper-animate-delay="0s">
                  优雅光韵造型
                </div>
                <div className="sub ani" swiper-animate-effect="animate__fadeInUpBig" swiper-animate-duration="1.5s" swiper-animate-delay="0s">
                  <div className="en">AESTHETIC VISUAL EXCITEMENT</div>
                  <div className="cn">一场刺激视觉神经的美学狂欢</div>
                </div>
                <div
                  className="btn"
                  data-html-item="price_exterior-more"
                  onClick={() => {
                    setSwiperflag(true);
                    windowhidden();
                  }}
                >
                  展开详情
                </div>
                <div
                  className="btn btn1"
                  onClick={() => {
                    openVideo();
                  }}
                >
                  查看视频
                </div>
              </div>
            </div>

            {swiperflag ? (
              <div className="modules-swiper-prop">
                <div
                  className="close"
                  onClick={() => {
                    setSwiperflag(false);
                    closewindowhidden();
                    window.Dmp.clickAction('price_exterior-mclose');
                  }}
                ></div>
                <div className="next" data-html-item="price_exterior-btnr"></div>
                <div className="prev" data-html-item="price_exterior-btnl"></div>
                <Swiper
                  ref={swiperRef}
                  slidesPerView={1}
                  className="modules-swiper"
                  spaceBetween={0}
                  initialSlide={0}
                  observer={true}
                  resizeObserver={true}
                  updateOnWindowResize={true}
                  pagination={pagination}
                  modules={[Pagination, Navigation]}
                  navigation={{
                    prevEl: '.modules-swiper-prop .prev',
                    nextEl: '.modules-swiper-prop .next',
                    disabledClass: 'disabled'
                  }}
                  onInit={(e) => {
                    if (swiperRef?.current?.swiper?.pagination?.bullets) {
                      for (var i = 0; i < swiperRef.current.swiper.pagination.bullets.length; i++) {
                        swiperRef.current.swiper.pagination.bullets[i].onclick = function (item) {
                          if (item.currentTarget.dataset.htmlData) {
                            window.Dmp.clickAction(item.currentTarget.dataset.htmlData);
                          }
                        };
                      }
                    }
                  }}
                  onTransitionStart={(e) => {
                    setShowcountnum(false);
                  }}
                  onSlideChangeTransitionEnd={(e) => {
                    setShowcountnum(true);
                  }}
                >
                  <SwiperSlide className={'griditem griditem1'}>
                    <div className="text">
                      <div className="sub ani" swiper-animate-effect="animate__fadeInLeftBig" swiper-animate-duration="1s" swiper-animate-delay="0s">
                        <div className="en">PUSHING BEYOND THE BOUNDARIES OF THE STANDARD</div>
                        <div className="cn">超出规格 不止一点点</div>
                      </div>
                      <div
                        className="bottom ani"
                        swiper-animate-effect="animate__fadeInLeftBig"
                        swiper-animate-duration="1.2s"
                        swiper-animate-delay="0s"
                      >
                        <div className="lista">
                          <div className="li">
                            <div className="n">轴距</div>
                            <div className="val">
                              <b className="num1">{showcountnum ? <CountUp duration={1} useGrouping={false} start={0} end={2830} /> : '2830'}</b>
                              mm
                            </div>
                          </div>
                          <div className="li">
                            <div className="n">车长+70mm</div>
                            <div className="val">
                              <b className="num2">{showcountnum ? <CountUp duration={1} useGrouping={false} start={0} end={4980} /> : '4980'}</b>
                              mm
                            </div>
                          </div>
                          <div className="li">
                            <div className="n">车宽</div>
                            <div className="val">
                              <b className="num3">{showcountnum ? <CountUp duration={1} useGrouping={false} start={0} end={1862} /> : '1862'}</b>
                              mm
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <img className="bg" src={accordhosturl + '/exterior/exterior1.jpg'} /> */}
                    <img
                      className="bg"
                      src={
                        isMobile ? createFilePath('/accord/v2/mobile/exterior/exterior1.jpg') : createFilePath('/accord/v2/pc/exterior/exterior1.jpg')
                      }
                      alt="广汽本田"
                    />
                  </SwiperSlide>
                  <SwiperSlide className={'griditem griditem2'}>
                    <div className="text">
                      <div
                        className="sub ani"
                        swiper-animate-effect="animate__fadeInLeftBig"
                        swiper-animate-duration="1.5s"
                        swiper-animate-delay="0s"
                      >
                        <div className="en">ADVANCED VISUAL APPEAL HARVESTS ALL ATTENTIONS</div>
                        <div className="cn">赏心悦目 收割一切回头率</div>
                      </div>
                      <div
                        className="list ani"
                        swiper-animate-effect="animate__fadeInLeftBig"
                        swiper-animate-duration="1.5s"
                        swiper-animate-delay="0s"
                      >
                        <div className="li">灵动前脸机能造型</div>
                        <div className="li">天蓝色车标</div>
                      </div>
                    </div>
                    {/* <img className="bg" src={accordhosturl + '/exterior/exterior2.jpg'} /> */}
                    <img
                      className="bg"
                      src={
                        isMobile ? createFilePath('/accord/v2/mobile/exterior/exterior2.jpg') : createFilePath('/accord/v2/pc/exterior/exterior2.jpg')
                      }
                      alt="广汽本田"
                    />
                  </SwiperSlide>
                  <SwiperSlide className={'griditem griditem3'}>
                    <div className="text">
                      <div
                        className="sub ani"
                        swiper-animate-effect="animate__fadeInLeftBig"
                        swiper-animate-duration="1.5s"
                        swiper-animate-delay="0s"
                      >
                        <div className="en">THE EXTERIOR CATCHES EVERY GLANCE</div>
                        <div className="cn">目光所至 掠夺所有的焦点</div>
                      </div>
                      <div
                        className="list ani"
                        swiper-animate-effect="animate__fadeInLeftBig"
                        swiper-animate-duration="1.5s"
                        swiper-animate-delay="0s"
                      >
                        <div className="li">薄型化LED远近光灯</div>
                        <div className="li">高反差结构日间行车灯</div>
                        <div className="li">流光转向灯</div>
                        <div className="li">ADB自适应远光灯</div>
                      </div>
                    </div>
                    {/* <img className="bg" src={accordhosturl + '/exterior/exterior3.jpg'} /> */}
                    <img
                      className="bg"
                      src={
                        isMobile ? createFilePath('/accord/v2/mobile/exterior/exterior3.jpg') : createFilePath('/accord/v2/pc/exterior/exterior3.jpg')
                      }
                      alt="广汽本田"
                    />
                  </SwiperSlide>
                  <SwiperSlide className={'griditem griditem4'}>
                    <div className="text">
                      <div
                        className="sub ani"
                        swiper-animate-effect="animate__fadeInLeftBig"
                        swiper-animate-duration="1.5s"
                        swiper-animate-delay="0s"
                      >
                        <div className="en">THE RED LIGHT QUIETS THE NOISY STREETS</div>
                        <div className="cn">这一抹红 平息街道的喧嚣</div>
                      </div>
                      <div
                        className="list ani"
                        swiper-animate-effect="animate__fadeInLeftBig"
                        swiper-animate-duration="1.5s"
                        swiper-animate-delay="0s"
                      >
                        <div className="li">穿越型LED组合尾灯</div>
                      </div>
                    </div>
                    {/* <img className="bg" src={accordhosturl + '/exterior/exterior4.jpg'} style={{ objectPosition: 'center bottom' }} /> */}
                    <img
                      className="bg"
                      src={
                        isMobile ? createFilePath('/accord/v2/mobile/exterior/exterior4.jpg') : createFilePath('/accord/v2/pc/exterior/exterior4.jpg')
                      }
                      style={{ objectPosition: 'center bottom' }}
                      alt="广汽本田"
                    />
                  </SwiperSlide>
                  <SwiperSlide className={'griditem griditem5'}>
                    <div className="text">
                      <div
                        className="list ani"
                        swiper-animate-effect="animate__fadeInLeftBig"
                        swiper-animate-duration="1.5s"
                        swiper-animate-delay="0s"
                      >
                        <div className="li">流畅洗练后包围</div>
                        <div className="li">动感扰流板饰条</div>
                      </div>
                    </div>
                    {/* <img className="bg" src={accordhosturl + '/exterior/exterior5.jpg'} /> */}
                    <img
                      className="bg"
                      src={
                        isMobile ? createFilePath('/accord/v2/mobile/exterior/exterior5.jpg') : createFilePath('/accord/v2/pc/exterior/exterior5.jpg')
                      }
                      alt="广汽本田"
                    />
                  </SwiperSlide>
                  <SwiperSlide className={'griditem griditem6'}>
                    <div className="text">
                      <div
                        className="t-img ani"
                        swiper-animate-effect="animate__fadeInLeftBig"
                        swiper-animate-duration="1.5s"
                        swiper-animate-delay="0s"
                      >
                        {/* <img src={accordhosturl + '/exterior/exterior6_t.png'} /> */}
                        <img
                          src={
                            isMobile
                              ? createFilePath('/accord/v2/mobile/exterior/exterior6_t.png')
                              : createFilePath('/accord/v2/pc/exterior/exterior6_t.png')
                          }
                          alt="广汽本田"
                        />
                      </div>
                      <div
                        className="sub ani"
                        swiper-animate-effect="animate__fadeInLeftBig"
                        swiper-animate-duration="1.5s"
                        swiper-animate-delay="0s"
                      >
                        <div className="en">AN ORNATE DRIVING EXPERIENCE </div>
                        <div className="cn">以豪华优雅驱动酣畅驾趣体验</div>
                      </div>
                    </div>
                    {/* <img className="bg" src={accordhosturl + '/exterior/exterior6.jpg'} /> */}
                    <img
                      className="bg"
                      src={
                        isMobile ? createFilePath('/accord/v2/mobile/exterior/exterior6.jpg') : createFilePath('/accord/v2/pc/exterior/exterior6.jpg')
                      }
                      alt="广汽本田"
                    />
                  </SwiperSlide>
                  <SwiperSlide className={'griditem griditem7'}>
                    <div className="text">
                      <div className="sub ani" swiper-animate-effect="animate__fadeInLeftBig" swiper-animate-duration="1s" swiper-animate-delay="0s">
                        <div className="en">DAZZLING WHEELS LEAD THE PATH</div>
                        <div className="cn">转动间 引领风的轨迹</div>
                      </div>
                      <div className="list ani" swiper-animate-effect="animate__fadeInLeftBig" swiper-animate-duration="1s" swiper-animate-delay="0s">
                        <div className="li">19英寸磨砂钢琴黑切削轮辋</div>
                      </div>
                    </div>
                    {/* <img className="bg" src={accordhosturl + '/exterior/exterior7.jpg'} /> */}
                    <img
                      className="bg"
                      src={
                        isMobile ? createFilePath('/accord/v2/mobile/exterior/exterior7.jpg') : createFilePath('/accord/v2/pc/exterior/exterior7.jpg')
                      }
                      alt="广汽本田"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
      {flag && <FullVideo fields={videodata} close={() => setFlag(false)} />}
      {/* <div className="anchor" id={data?.id}></div> */}
    </>
  );
}
