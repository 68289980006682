import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import temp from '../../temp/config';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import LazyLoad from 'react-lazyload';

export default function index({ fields, eventRef }) {
  const holderImg = <img alt="广汽本田" src={createFilePath('/common/placeholder.jpg')} />;
  // const deployUrlS3 = temp.app.deployUrlS3;
  const isMobile = isMobileFun();
  const container_style = fields[getPrefixField('container_style', isMobile)]?.value;
  const imgList = fields[getPrefixField('list', isMobile)];
  const [data, _] = useState({ container_style, imgList, isMobile });
  const rootRef = useRef(null);
  const [windowHeight, setWindowHeight] = useState(400);

  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(fields?.sectionDmp);
  };

  const renderSingleFaceSource = (sourceObj, index) => {
    const {
      fields: { bg_source, title, subTitle, detail_btn_dmp, btn_href }
    } = sourceObj;
    return (
      <div key={index} className={`item item${index}`}>
        <img src={`${createFilePath(bg_source.value)}`} alt={title.value} />
        <div className="card">
          <div className="title" dangerouslySetInnerHTML={{ __html: title.value?.replaceHtml() }} />
          {subTitle.value && <div className="subTitle" dangerouslySetInnerHTML={{ __html: subTitle.value?.replaceHtml() }} />}
          <div className="btnPosition">
            <button
              className="btnDetail"
              onClick={() => {
                sendDmp(detail_btn_dmp, btn_href.value);
                window.open(btn_href.value, '_self');
              }}
            >
              了解详情
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderSinglePage = (progress, list) => {
    return (
      <Timeline totalProgress={progress} paused>
        <div className={`imgListContainer ${'imgList_'}${list.length}`}>
          {list &&
            list.map((item, index) => {
              return (
                <Tween key={index} from={{ opacity: 0 }} to={{ opacity: 1 }} stagger={0.2} duration={10}>
                  {renderSingleFaceSource(item, index)}
                </Tween>
              );
            })}
        </div>
      </Timeline>
    );
  };

  return (
    <LazyLoad placeholder={holderImg}>
      <section className="container_wallPaper">
        {data && (
          <div ref={rootRef}>
            <Controller>
              <Scene triggerHook={data.isMobile ? 0.5 : 0.7} duration={data.isMobile ? windowHeight * 0.9 : windowHeight * 0.7} pin={false}>
                {(progress, event) => {
                  return (
                    <div
                      className="WallpaperVideo_homePage"
                      style={convertStylesStringToObject(fields[`${getPrefixField('container_style')}`]?.value)}
                    >
                      <div className="sourceListContainer">
                        <div>{renderSinglePage(progress, data.imgList)}</div>
                      </div>
                    </div>
                  );
                }}
              </Scene>
            </Controller>
          </div>
        )}
      </section>
    </LazyLoad>
  );
}
