import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import temp from '../../temp/config';
import './style.scss';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getPrefixField, imageLoaded, convertStylesStringToObject, isMobileFun, createFilePath } from '../../utils/obj-utils';
import LazyLoad from 'react-lazyload';
export default function Banner(props) {
  const holderImg = <img alt="广汽本田" src={createFilePath('/common/placeholder.jpg')} />;
  const containerRef = useRef(null);
  const textContainerRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const btnRef = useRef(null);
  const imageContainerRef = useRef(null);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const isMobile = isMobileFun();
  const reverse = props?.fields[getPrefixField('reserve', isMobile)]?.value;
  const textContainer_style = props?.fields[getPrefixField('textContainer_style', isMobile)]?.value;

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    window.Dmp.sectionAction(props.fields.pageDmp[0]?.displayName);
  };

  useEffect(() => {
    imageLoaded(imageContainerRef, () => {
      setBackgroundLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (!backgroundLoaded) {
      return;
    }

    const controller = new ScrollMagic.Controller({ container: props?.scrollElement || window });
    const timeline = gsap.timeline({ paused: true });
    if (reverse) {
      timeline
        .fromTo(imageContainerRef.current, { scale: 0.8 }, { scale: 1 })
        .fromTo(titleRef.current, { opacity: 0 }, { opacity: 1 })
        .fromTo(descriptionRef.current, { opacity: 0 }, { opacity: 1 }, '<30%')
        .fromTo(btnRef.current, { opacity: 0 }, { opacity: 1 }, '<30%');
    } else {
      timeline
        .fromTo(titleRef.current, { opacity: 0 }, { opacity: 1 })
        .fromTo(descriptionRef.current, { opacity: 0 }, { opacity: 1 }, '<30%')
        .fromTo(btnRef.current, { opacity: 0 }, { opacity: 1 }, '<30%')
        .fromTo(imageContainerRef.current, { scale: 0.8 }, { scale: 1 });
    }
    const scene = new ScrollMagic.Scene({
      triggerElement: containerRef?.current,
      triggerHook: 'onCenter',
      duration: containerRef?.current?.getBoundingClientRect()?.height / 2
    })
      .setTween(timeline)
      .addTo(controller);

    return () => {
      timeline.kill();
      scene.remove();
      scene.destroy();

      controller.destroy();
    };
  }, [backgroundLoaded]);

  return !isMobile ? (
    !props.fields.reserve.value ? (
      <LazyLoad placeholder={holderImg}>
        <div className="LifeValue-homePage" ref={containerRef}>
          <div className="LifeValue-homePage-header" ref={textContainerRef} style={convertStylesStringToObject(textContainer_style)}>
            <div
              dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.text1.value : props.fields.m_text1.value)?.replaceHtml() }}
              ref={titleRef}
            ></div>
            <div
              dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.text2.value : props.fields.m_text2.value)?.replaceHtml() }}
              ref={descriptionRef}
            ></div>
            <div>
              <div
                dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.btn.value : props.fields.m_btn.value)?.replaceHtml() }}
                ref={btnRef}
              ></div>
              <a
                data-action={props.fields.btnDmp[0]?.fields?.func?.value}
                data-item={props.fields.btnDmp[0]?.displayName}
                data-url={props.fields.pc_btnUrl?.value}
                href={props.fields.pc_btnUrl.value}
                target={`${props.fields.btn_target.fields.v.value}`}
              ></a>
            </div>
          </div>
          {props?.fields[getPrefixField('img', isMobile)]?.value && (
            // <img alt="广汽本田" src={`${temp.app.deployUrlS3}${props?.fields[getPrefixField('img', isMobile)]?.value}`} ref={imageContainerRef} alt="车生活全价值" />
            <img src={createFilePath(props?.fields[getPrefixField('img', isMobile)]?.value)} ref={imageContainerRef} alt="车生活全价值" />
          )}
          {/* <img alt="广汽本田" src={`${temp.app.deployUrlS3}${!isMobile ? props.fields.img.value : props.fields.m_img.value}`} ref={imageContainerRef} /> */}
        </div>
      </LazyLoad>
    ) : (
      <LazyLoad placeholder={holderImg}>
        <div className="LifeValue-homePage" ref={containerRef}>
          {/* <img alt="广汽本田" src={`${temp.app.deployUrlS3}${!isMobile ? props.fields.img.value : props.fields.m_img.value}`} ref={imageContainerRef} /> */}
          {props?.fields[getPrefixField('img', isMobile)]?.value && (
            // <img alt="广汽本田" src={`${temp.app.deployUrlS3}${props?.fields[getPrefixField('img', isMobile)]?.value}`} ref={imageContainerRef} />
            <img alt="广汽本田" src={createFilePath(props?.fields[getPrefixField('img', isMobile)]?.value)} ref={imageContainerRef} />
          )}
          <div className="LifeValue-homePage-header" ref={textContainerRef}>
            <div
              dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.text1.value : props.fields.m_text1.value)?.replaceHtml() }}
              ref={titleRef}
            ></div>
            <div
              dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.text2.value : props.fields.m_text2.value)?.replaceHtml() }}
              ref={descriptionRef}
            ></div>
            <div>
              <div
                dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.btn.value : props.fields.m_btn.value)?.replaceHtml() }}
                ref={btnRef}
              ></div>
              <a
                data-action={props.fields.btnDmp[0]?.fields?.func?.value}
                data-item={props.fields.btnDmp[0]?.displayName}
                data-url={props.fields.pc_btnUrl?.value}
                href={props.fields.pc_btnUrl.value}
                target={`${props.fields.btn_target.fields.v.value}`}
              ></a>
            </div>
          </div>
        </div>
      </LazyLoad>
    )
  ) : !props.fields.m_reserve.value ? (
    <LazyLoad placeholder={holderImg}>
      <div className="LifeValue-homePage" ref={containerRef}>
        <div className="LifeValue-homePage-header" ref={textContainerRef}>
          <div
            dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.text1.value : props.fields.m_text1.value)?.replaceHtml() }}
            ref={titleRef}
          ></div>
          <div
            dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.text2.value : props.fields.m_text2.value)?.replaceHtml() }}
            ref={descriptionRef}
          ></div>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.btn.value : props.fields.m_btn.value)?.replaceHtml() }}
              ref={btnRef}
            ></div>
            <a
              data-action={props.fields.btnDmp[0]?.fields?.func?.value}
              data-item={props.fields.btnDmp[0]?.displayName}
              data-url={props.fields.btnUrl?.value}
              href={props.fields.btnUrl.value}
              target={`${props.fields.btn_target.fields.v.value}`}
            ></a>
          </div>
        </div>
        {/* <img alt="广汽本田" src={`${temp.app.deployUrlS3}${!isMobile ? props.fields.img.value : props.fields.m_img.value}`} ref={imageContainerRef} /> */}
        {props?.fields[getPrefixField('img', isMobile)]?.value && (
          // <img alt="广汽本田" src={`${temp.app.deployUrlS3}${props?.fields[getPrefixField('img', isMobile)]?.value}`} ref={imageContainerRef} alt="车生活全价值" />
          <img src={createFilePath(props?.fields[getPrefixField('img', isMobile)]?.value)} ref={imageContainerRef} alt="车生活全价值" />
        )}
      </div>
    </LazyLoad>
  ) : (
    <LazyLoad placeholder={holderImg}>
      <div className="LifeValue-homePage lifeReserve" ref={containerRef}>
        {/* <img alt="广汽本田" src={`${temp.app.deployUrlS3}${!isMobile ? props.fields.img.value : props.fields.m_img.value}`} ref={imageContainerRef} /> */}
        {props?.fields[getPrefixField('img', isMobile)]?.value && (
          // <img alt="广汽本田" src={`${temp.app.deployUrlS3}${props?.fields[getPrefixField('img', isMobile)]?.value}`} ref={imageContainerRef} />
          <img alt="广汽本田" src={createFilePath(props?.fields[getPrefixField('img', isMobile)]?.value)} ref={imageContainerRef} />
        )}
        <div className="LifeValue-homePage-header" ref={textContainerRef}>
          <div
            dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.text1.value : props.fields.m_text1.value)?.replaceHtml() }}
            ref={titleRef}
          ></div>
          <div
            dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.text2.value : props.fields.m_text2.value)?.replaceHtml() }}
            ref={descriptionRef}
          ></div>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: (!isMobile ? props.fields.btn.value : props.fields.m_btn.value)?.replaceHtml() }}
              ref={btnRef}
            ></div>
            <a
              data-action={props.fields.btnDmp[0]?.fields?.func?.value}
              data-item={props.fields.btnDmp[0]?.displayName}
              data-url={props.fields.btnUrl?.value}
              href={props.fields.btnUrl.value}
              target={`${props.fields.btn_target.fields.v.value}`}
            ></a>
          </div>
        </div>
      </div>
    </LazyLoad>
  );
}
