/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';
import { createFilePath, isMobileFun } from '../../utils/obj-utils';
import ReactPaginate from 'react-paginate';

export default function index() {
  const isMobile = isMobileFun();
  const pageSize = 10;
  const [pageIndex, setPageIndex] = useState(1);
  const [data, setData] = useState();

  useEffect(() => {
    getData(pageIndex);
  }, [pageIndex]);

  const getData = (pageIndex = 1) => {
    dataFetcher(`/api/honda/v1/Article/GetEnvChangeList?pageIndex=${pageIndex}&pageSize=${pageSize}`)
      .then(({ data: response }) => {
        if (response?.Status) {
          setData(response);
        } else {
          return Promise.reject(new Error(response?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const pageChange = (page) => {
    setPageIndex(page.selected + 1);
  };

  const pageCount = Math.ceil(data?.PageModel?.TotalCount / pageSize);

  return (
    <section className="environment-announcement-v1">
      <div className="title">环保信息变更申明</div>
      <table className="environment-table">
        <thead>
          <tr>
            <th width={isMobile ? '70%' : '71.85%'}>文件标题</th>
            <th width={isMobile ? '' : '18.54%'}>上传时间</th>
            {!isMobile && <th>详情</th>}
          </tr>
        </thead>
        <tbody>
          {data?.Data?.map((item, index) => {
            const disabled = moment().diff(moment(item?.upload_time), 'days') > 30;
            return (
              <tr key={index}>
                <td>
                  {isMobile && !disabled ? (
                    <a
                      target="_blank"
                      href={createFilePath(`/environment/attachment/change_pdf/${item?.file_name}.pdf`)}
                      className="file-link"
                      rel="noreferrer"
                    >
                      {item?.file_title}
                    </a>
                  ) : (
                    item?.file_title
                  )}
                </td>
                <td>{moment(item?.upload_time).format(isMobile ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss')}</td>
                {!isMobile && (
                  <td>
                    <a
                      target="_blank"
                      href={disabled ? null : createFilePath(`/environment/attachment/change_pdf/${item?.file_name}.pdf`)}
                      className={disabled ? 'detail-button-disabled' : 'detail-button'}
                      rel="noreferrer"
                    >
                      查看详情
                    </a>
                  </td>
                )}
              </tr>
            );
          })}
          {data?.PageModel?.TotalCount === 0 && (
            <tr>
              <td>暂无最新声明</td>
              <td>2017-02-28 12:00:00</td>
              {!isMobile && <td>无</td>}
            </tr>
          )}
        </tbody>
      </table>
      {pageCount > 1 && (
        <ReactPaginate
          previousLabel="上一页"
          nextLabel="下一页"
          pageCount={pageCount}
          pageRangeDisplayed={isMobile ? 1 : 2}
          marginPagesDisplayed={isMobile ? 1 : 2}
          containerClassName="pagination"
          breakClassName="break"
          activeClassName="active"
          renderOnZeroPageCount={null}
          onPageChange={pageChange}
        />
      )}
    </section>
  );
}
