import React, { useState, useEffect } from 'react';
import './style.scss';
import temp from '../../../temp/config';
import { isMobile as isDeviceMobile } from '../../../utils/dom-utils';
import FulModulDetail from '../../../elements/FullModal';
import { createFilePath } from '../../../utils/obj-utils';
export default function index(props) {
  const [thisMobile, setThisMobile] = useState(false);
  const [reserveFlag, setReserveFlag] = useState(false);
  useEffect(() => {
    setThisMobile(document.documentElement.clientWidth > 1024);
  }, []);
  const { specific, num, setNum, close, closeDmp, val, conHtml } = props.fields;
  return (
    <FulModulDetail>
      {num !== -1 ? (
        thisMobile ? (
          <div className="securityDetail">
            {specific[num].fields.conHtml ? (
              <div dangerouslySetInnerHTML={{ __html: specific[num].fields.conHtml?.replaceHtml() }}></div>
            ) : (
              <React.Fragment>
                <img
                  alt="广汽本田"
                  src={`${createFilePath(specific[num].fields.img.value)}`}
                  className={`securityDetailImg securityDetailImg${num}${reserveFlag ? 'T' : ''}`}
                />
                <div className={`securityDetailInner${reserveFlag ? 'T' : ''}`}>
                  <div dangerouslySetInnerHTML={{ __html: specific[num].fields.inner.value?.replaceHtml() }}></div>
                </div>
              </React.Fragment>
            )}
            <img
              alt="广汽本田"
              src={`${createFilePath(close)}`}
              className={`securityDetailClose securityDetailClose${reserveFlag ? 'T' : ''}`}
              onClick={() => {
                window.Dmp.clickAction(closeDmp, '', { val: val });
                setReserveFlag(true);
                setTimeout(() => {
                  setNum(-1);
                  setReserveFlag(false);
                }, 700);
              }}
            />
          </div>
        ) : (
          <div className="securityDetail">
            {specific[num].fields.conHtml ? (
              <div dangerouslySetInnerHTML={{ __html: specific[num].fields.conHtml?.replaceHtml() }}></div>
            ) : (
              <React.Fragment>
                <img
                  alt="广汽本田"
                  src={`${createFilePath(specific[num].fields.m_img.value)}`}
                  className={`securityDetailImg_m securityDetailImg${num}_m${reserveFlag ? 'T' : ''}`}
                />
                <div className={`securityDetailInner${reserveFlag ? 'T' : ''}`}>
                  <div dangerouslySetInnerHTML={{ __html: specific[num].fields.m_inner.value?.replaceHtml() }}></div>
                </div>
              </React.Fragment>
            )}
            <img
              alt="广汽本田"
              src={`${createFilePath(close)}`}
              className={`securityDetailClose securityDetailClose${reserveFlag ? 'T' : ''}`}
              onClick={() => {
                window.Dmp.clickAction(closeDmp, '', { val: val });
                setReserveFlag(true);
                setTimeout(() => {
                  setNum(-1);
                  setReserveFlag(false);
                }, 700);
              }}
            />
          </div>
        )
      ) : (
        ''
      )}
      ;
    </FulModulDetail>
  );
}
