import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import { getPrefixField, createFilePath, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { dataFetcher } from '../../dataFetcher';

export default function index(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const img_bg = fields[getPrefixField('img_bg', isMobile)]?.value;
  const explain = fields[getPrefixField('explain', isMobile)]?.value;
  const remark = fields[getPrefixField('remark', isMobile)]?.value;
  const icon_list = fields[getPrefixField('banner_list', isMobile)];
  const [data, _] = useState({ isMobile, img_bg, icon_list, explain, remark });
  const [selectCarPropShow, setSelectCarPropShow] = useState(false);
  const [tabNum, setTabNum] = useState(0);
  const [carNum, setCarNum] = useState(3);
  const [carData, setCarData] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [morePropShow, setMorePropShow] = useState(false);
  const [mCatePropShow, setMCatePropShow] = useState(false);
  const componentsName = ['保养件', '定期更换件', '油液件', '碰撞件', '其他零件'];
  const containerRef = useRef(null);

  const moreClick = () => {
    if (data.isMobile) {
      setMorePropShow(true);
    } else {
      let explainDdList = containerRef.current.querySelectorAll('.explain>dl>dd');
      if (explainDdList && explainDdList.length > 0) {
        for (let i = 0; i < explainDdList.length; i++) {
          if (i > 14) {
            explainDdList[i].style.display = 'inline-block';
          }
        }
      }
      containerRef.current.getElementsByClassName('remark')[0].style.display = 'inline-block';
      containerRef.current.getElementsByClassName('more')[0].style.display = 'none';
    }
  };

  const tdHover = (val) => {
    let propList = containerRef.current.getElementsByClassName('assembly_prop');
    let th4List = containerRef.current.getElementsByClassName('th4_val');
    if (propList && propList.length > 0 && th4List && th4List.length > 0) {
      propList[val].style.display = data.isMobile ? 'flex' : 'inline-block';
      data.isMobile && th4List[val].setAttribute('style', 'padding-bottom: 64px !important');
    }
  };

  const tdLeave = (val) => {
    let propList = containerRef.current.getElementsByClassName('assembly_prop');
    let th4List = containerRef.current.getElementsByClassName('th4_val');
    if (propList && propList.length > 0 && th4List && th4List.length > 0) {
      propList[val].style.display = 'none';
      data.isMobile && th4List[val].setAttribute('style', 'padding-bottom: 0px !important');
    }
  };

  useEffect(() => {
    dataFetcher('/api/honda/v1/Article/GetPartPrice')
      .then(({ data }) => {
        if (data.Status) {
          console.log('===', data);
          setCarData(data.Data);
        } else {
          console.log(data.Status);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <section>
      <div className="partPrice_v1" ref={containerRef}>
        <div className="partPrice_main">
          <div className="staticHead">
            <h3>零部件价格</h3>
          </div>
          <div className="banner">
            <img alt="广汽本田" src={`${createFilePath(data.img_bg)}`} />
            <div className="service">
              <ul>
                {data.icon_list &&
                  data.icon_list.length > 0 &&
                  data.icon_list.map((item, index) => {
                    return (
                      <li key={index}>
                        <img alt="广汽本田" src={`${createFilePath(item.fields?.icon?.value)}`} />
                        <div className="name" dangerouslySetInnerHTML={{ __html: item.fields.name?.value?.replaceHtml() }} />
                        <div className="describe" dangerouslySetInnerHTML={{ __html: item.fields.describe?.value?.replaceHtml() }} />
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="filter">
            <div className="top">
              <div className="selectcar" onClick={() => setSelectCarPropShow((selectCarPropShow) => !selectCarPropShow)}>
                <img alt="广汽本田" src={`${createFilePath('/service/partPrice_v1/meau_icon.png')}`} />
                {carData ? carData[carNum].series_name_cn : null}
              </div>
              {selectCarPropShow && (
                <div className="selectcar_prop">
                  {carData.map((item, index) => {
                    return (
                      <div
                        className={carNum === index ? 'prop_item_active' : 'prop_item'}
                        key={index}
                        onClick={() => {
                          setCarNum(index);
                          setTabNum(0);
                          setInputValue('');
                          setSearchValue('');
                          setSelectCarPropShow(false);
                        }}
                      >
                        {item.series_name_cn}
                      </div>
                    );
                  })}
                </div>
              )}
              {data.isMobile ? (
                <>
                  <div className="mCate" onClick={() => setMCatePropShow((mCatePropShow) => !mCatePropShow)}>
                    <input text="text" value={componentsName[tabNum]} placeholder="请选择零部件类型" readOnly />
                    <div
                      className="btn"
                      style={{
                        background: `url(${createFilePath('/service/partPrice_v1/cir2.png')}) center center no-repeat #cc0000`,
                        backgroundSize: '40%'
                      }}
                    />
                  </div>
                  {mCatePropShow && (
                    <div className="mCate_prop">
                      {componentsName.map((item, index) => {
                        return (
                          <div
                            className={tabNum === index ? 'prop_item_active' : 'prop_item'}
                            key={index}
                            onClick={() => {
                              setTabNum(index);
                              setMCatePropShow(false);
                            }}
                          >
                            {item}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              ) : (
                <div className="search">
                  <input text="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder="请输入零部件关键词" />
                  <img alt="广汽本田" src={`${createFilePath('/service/partPrice_v1/search_icon.png')}`} onClick={() => setSearchValue(inputValue)} />
                </div>
              )}
            </div>
            {data.isMobile ? (
              <div className="mSearch">
                <input text="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder="请输入零部件关键词" />
                <div
                  className="btn"
                  style={{
                    background: `url(${createFilePath('/service/partPrice_v1/search_icon.png')}) center center no-repeat`,
                    backgroundSize: '40%'
                  }}
                  onClick={() => setSearchValue(inputValue)}
                />
              </div>
            ) : (
              <div className="cate">
                <div className="title">零部件类型：</div>
                <div className="con">
                  {componentsName.map((item, index) => {
                    return (
                      <div className={index === tabNum ? 'con_item_active' : 'con_item'} key={index} onClick={() => setTabNum(index)}>
                        <i /> {item}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="result">
            {carData &&
            carData[carNum].part_category_list[tabNum].part_name_list.filter((pItem) => pItem.part_name.indexOf(searchValue) !== -1).length === 0 ? (
              <div className="empty">暂无数据</div>
            ) : (
              <>
                <div className="thead">
                  <table cellPadding={0} cellSpacing={0} border={0}>
                    <tbody>
                      <tr>
                        <th className="th1">车型</th>
                        <th className="th2">零部件类型</th>
                        <th className="th3">零部件名称</th>
                        <th className="th4">建议零售价(RMB)</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {carData ? (
                  <>
                    <div className="tcontent">
                      <table cellPadding={0} cellSpacing={0} border={0}>
                        <tbody>
                          <tr>
                            <td className="t1 th1" rowSpan={carData[carNum].part_category_list[tabNum].part_name_list.length + 1}>
                              {carData[carNum].series_name_cn}
                              <br />
                              <span className="en">{carData[carNum].series_dict_code}</span>
                            </td>
                            <td className="t2 th2" rowSpan={carData[carNum].part_category_list[tabNum].part_name_list.length + 1}>
                              {componentsName[tabNum]}
                            </td>
                          </tr>
                          {carData[carNum].part_category_list[tabNum].part_name_list
                            .filter((pItem) => pItem.part_name.indexOf(searchValue) !== -1)
                            .map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="th3">{item.part_name}</td>
                                  <td
                                    className="th4_val"
                                    onMouseEnter={() => tdHover(index)}
                                    onMouseLeave={() => tdLeave(index)}
                                    style={{ textDecoration: isMobile ? (item.part_model_list.length > 1 ? 'underline' : 'none') : 'none' }}
                                  >
                                    {item.part_last_price}
                                    {data.isMobile && item.part_model_list.length > 1 && (
                                      <i
                                        className="cir3"
                                        style={{
                                          background: `url(${createFilePath('/service/partPrice_v1/cir3.png')}) center center no-repeat`,
                                          backgroundSize: '100%'
                                        }}
                                      />
                                    )}
                                    {item.part_model_list && item.part_model_list.length > 1 && (
                                      <div className="assembly_prop">
                                        {!data.isMobile && (
                                          <>
                                            <img alt="广汽本田" src={`${createFilePath('/service/partPrice_v1/cir.png')}`} />
                                            <div className="tr">
                                              <div className="th">车型</div>
                                              <div className="th val">价格(RMB)</div>
                                            </div>
                                          </>
                                        )}
                                        {item.part_model_list.map((modelItem, modelIndex) => {
                                          return (
                                            <div className={data.isMobile ? 'm_tr' : 'tr'} key={modelIndex}>
                                              <div className={data.isMobile ? 'm_td' : 'td'}>{modelItem.model_name}</div>
                                              <div className={data.isMobile ? 'm_td' : 'td value'}>{modelItem.part_price}</div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </div>
          {data.isMobile ? (
            morePropShow ? (
              <div className="more_prop">
                <div className="in">
                  <div className="mExplain" dangerouslySetInnerHTML={{ __html: data.explain.replaceHtml() }} />
                  <div className="mRemark" dangerouslySetInnerHTML={{ __html: data.remark.replaceHtml() }} />
                </div>
                <img
                  alt="广汽本田"
                  className="close"
                  src={`${createFilePath('/service/partPrice_v1/close_icon.png')}`}
                  onClick={() => setMorePropShow(false)}
                />
                <div className="gray-bgg" />
              </div>
            ) : null
          ) : (
            <>
              <div className="explain" dangerouslySetInnerHTML={{ __html: data.explain.replaceHtml() }} />
              <div className="remark" dangerouslySetInnerHTML={{ __html: data.remark.replaceHtml() }} />
            </>
          )}
          <div className="more" onClick={() => moreClick()}>
            更多说明
          </div>
        </div>
      </div>
    </section>
  );
}
