import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { isMobileFun, createFilePath, getPrefixField, isInView } from '../../utils/obj-utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import './style.scss';
import gsap from 'gsap';

export default function index(props) {
  const { fields } = props;
  const rootEl = useRef(null);
  const conRef = useRef(null);
  const swiperRef = useRef(null);
  const once = useRef(true);
  const isMobile = isMobileFun();
  const [tabIndex, setTabIndex] = useState(0);
  const [paused, setPaused] = useState(true);
  const id = props?.params?.pagename;
  const data = {
    id
  };
  useEffect(() => {
    const scroll = () => {
      if (rootEl.current) {
        setPaused(!isInView(rootEl));
      }
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  useEffect(() => {
    if (isMobile) {
      return;
    }
    const timeline = gsap.timeline({
      duration: 1.5,
      onComplete: () => {
        timeline.kill();
      }
    });
    timeline.fromTo(conRef?.current, { y: 0 }, { y: '5%' }, 0);
    if (paused) {
      return;
    }
    timeline.fromTo(conRef?.current, { y: '5%' }, { y: 0 }, 0);
    return () => {
      timeline.kill();
    };
  }, [paused]);
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
  };

  const swiperslideTo = (index) => {
    setTabIndex(index);
  };
  const mbswiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
    setTabIndex(index);
  };

  return (
    <div className="PriceSafety_v1" id={id} ref={rootEl}>
      <div className="con" ref={conRef}>
        {!isMobile ? (
          <>
            {fields?.lists.length > 0 &&
              fields?.lists?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`item ${index === tabIndex ? 'on' : ''}`}
                    onClick={() => {
                      swiperslideTo(index);
                      if (item?.fields?.tabsdmp?.value) {
                        window.Dmp.clickAction(item?.fields?.tabsdmp?.value);
                      }
                    }}
                  >
                    {item?.fields[getPrefixField('name', isMobile)]?.value && (
                      <div
                        className="name"
                        dangerouslySetInnerHTML={{ __html: item?.fields[getPrefixField('name', isMobile)]?.value.replaceHtml() }}
                      ></div>
                    )}
                    <div className="extend">
                      {item?.fields[getPrefixField('pic', isMobile)]?.value && (
                        <div className="img">
                          <img alt="广汽本田" src={`${createFilePath(item?.fields[getPrefixField('pic', isMobile)]?.value)}`} />
                        </div>
                      )}
                      {item?.fields[getPrefixField('text', isMobile)]?.value && (
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{ __html: item?.fields[getPrefixField('text', isMobile)]?.value.replaceHtml() }}
                        ></div>
                      )}
                    </div>
                  </div>
                );
              })}
            <div className="pagination">
              {fields?.lists.length > 0 &&
                fields?.lists?.map((item, index) => {
                  return (
                    <span
                      key={index}
                      className={`pagination-bullets ${index === tabIndex ? 'pagination-bullets-active' : ''}`}
                      onClick={() => {
                        swiperslideTo(index);
                        window.Dmp.clickAction(fields?.paginationdmp?.value + (Number(index) + 1));
                      }}
                    ></span>
                  );
                })}
            </div>
            <div
              className={`next ${tabIndex >= fields?.lists.length - 1 ? 'disabled' : ''}`}
              data-html-item={fields?.nextdmp?.value}
              onClick={() => {
                if (tabIndex <= fields?.lists.length - 1) {
                  var index = tabIndex;
                  setTabIndex(index++);
                  swiperslideTo(index++);
                }
              }}
            >
              {fields?.nexticon?.value && <img alt="广汽本田" className="img" src={`${createFilePath(fields?.nexticon?.value)}`} />}
            </div>
            <div
              className={`prev ${tabIndex <= 0 ? 'disabled' : ''}`}
              data-html-item={fields?.prevdmp?.value}
              onClick={() => {
                if (tabIndex > 0) {
                  var index = tabIndex;
                  setTabIndex(index--);
                  swiperslideTo(index--);
                }
              }}
            >
              {fields?.previcon?.value && <img alt="广汽本田" className="img" src={`${createFilePath(fields?.previcon?.value)}`} />}
            </div>
          </>
        ) : (
          <>
            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              className="modules-swiper"
              spaceBetween={0}
              initialSlide={0}
              observer={true}
              resizeObserver={true}
              updateOnWindowResize={true}
              onSlideChangeTransitionEnd={(e) => {
                setTabIndex(e.activeIndex);
              }}
            >
              {fields?.lists.length > 0 &&
                fields?.lists?.map((item, index) => {
                  return (
                    <SwiperSlide key={index} className="item">
                      {item?.fields[getPrefixField('name', isMobile)]?.value && (
                        <div
                          className="name"
                          dangerouslySetInnerHTML={{ __html: item?.fields[getPrefixField('name', isMobile)]?.value.replaceHtml() }}
                        ></div>
                      )}
                      <div className="extend">
                        {item?.fields[getPrefixField('pic', isMobile)]?.value && (
                          <div className="img">
                            <img alt="广汽本田" src={`${createFilePath(item?.fields[getPrefixField('pic', isMobile)]?.value)}`} />
                          </div>
                        )}
                        {item?.fields[getPrefixField('text', isMobile)]?.value && (
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{ __html: item?.fields[getPrefixField('text', isMobile)]?.value.replaceHtml() }}
                          ></div>
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            <div className="pagination">
              {fields?.lists.length > 0 &&
                fields?.lists?.map((item, index) => {
                  return (
                    <span
                      key={index}
                      className={`pagination-bullets ${index === tabIndex ? 'pagination-bullets-active' : ''}`}
                      onClick={() => {
                        mbswiperslideTo(index);
                        window.Dmp.clickAction(fields?.paginationdmp?.value + (Number(index) + 1));
                      }}
                    ></span>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
