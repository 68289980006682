import React, { useEffect, useRef, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import { getPrefixField, convertStylesStringToObject, createFilePath, isMobileFun } from '../../utils/obj-utils';

export default function index({ fields, scrollElement }) {
  const containerRef = useRef(null);
  const textContainerRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const imageContainerRef = useRef(null);
  const imageRef = useRef(null);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const isMobile = isMobileFun();
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const description = fields[getPrefixField('description', isMobile)]?.value;
  const image = createFilePath(fields[getPrefixField('image', isMobile)]?.value);
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const disableAnimate = fields[getPrefixField('disableAnimate', isMobile)]?.value;
  const reserve = fields[getPrefixField('reserve', isMobile)]?.value;
  const [data, _] = useState({ title, description, image, style, disableAnimate, reserve, isMobile });

  useEffect(() => {
    if (data?.disableAnimate || !backgroundLoaded) {
      return;
    }

    const controller = new ScrollMagic.Controller({ container: scrollElement || window });
    const timeline = gsap.timeline({ paused: true });
    if (data?.reserve) {
      timeline
        .fromTo(imageRef.current, { scale: 0.8 }, { scale: 1 })
        .fromTo(titleRef.current, { opacity: 0 }, { opacity: 1 })
        .fromTo(descriptionRef.current, { opacity: 0 }, { opacity: 1 }, '<30%');
    } else {
      timeline
        .fromTo(titleRef.current, { opacity: 0 }, { opacity: 1 })
        .fromTo(descriptionRef.current, { opacity: 0 }, { opacity: 1 }, '<30%')
        .fromTo(imageRef.current, { scale: 0.8 }, { scale: 1 });
    }
    const scene = new ScrollMagic.Scene({
      triggerElement: containerRef?.current,
      triggerHook: 'onCenter',
      duration: containerRef?.current?.getBoundingClientRect()?.height / 2
    })
      .setTween(timeline)
      .addTo(controller);

    return () => {
      timeline.kill();
      scene.remove();
      scene.destroy();

      controller.destroy();
    };
  }, [backgroundLoaded]);
  return (
    <section>
      {data ? (
        <div className="text-single-image" style={data?.style} ref={containerRef}>
          {data?.reserve ? (
            <>
              <div className="image-container" ref={imageContainerRef}>
                <img alt="广汽本田" className="image" src={data?.image} ref={imageRef} onLoad={() => setBackgroundLoaded(true)} />
              </div>
              {data.title && (
                <div className="text-container" ref={textContainerRef}>
                  <div className="title" dangerouslySetInnerHTML={{ __html: data?.title?.replaceHtml() }} ref={titleRef} />
                  <div className="description" dangerouslySetInnerHTML={{ __html: data?.description?.replaceHtml() }} ref={descriptionRef} />
                </div>
              )}
            </>
          ) : (
            <>
              {data.title && (
                <div className="text-container" ref={textContainerRef}>
                  <div className="title" dangerouslySetInnerHTML={{ __html: data?.title?.replaceHtml() }} ref={titleRef} />
                  <div className="description" dangerouslySetInnerHTML={{ __html: data?.description?.replaceHtml() }} ref={descriptionRef} />
                </div>
              )}
              <div className="image-container" ref={imageContainerRef}>
                <img alt="广汽本田" className="image" src={data?.image} ref={imageRef} onLoad={() => setBackgroundLoaded(true)} />
              </div>
            </>
          )}
        </div>
      ) : null}
    </section>
  );
}
