const handle1p1f = (sitecore) => {
  const personalization = sitecore?.context?.personalization;
  let jssMain = sitecore.route?.placeholders && sitecore.route?.placeholders['jss-main'];
  if (personalization && jssMain) {
    jssMain = jssMain.sort((a, b) => {
      const aIndex = personalization.findIndex((x) => x === a.uid);
      const bIndex = personalization.findIndex((x) => x === b.uid);
      if (aIndex === -1 || bIndex === -1) return 0;
      return aIndex - bIndex;
    });
    console.log('jssMain sorted', jssMain);
  }
};
export const handleSSR1p1f = (ssrState) => {
  handle1p1f(ssrState.sitecore);
  return ssrState.sitecore && ssrState.sitecore.route
    ? {
        route: ssrState.sitecore.route,
        itemId: ssrState.sitecore.route.itemId,
        ...ssrState.sitecore.context
      }
    : ssrState.sitecore.context;
};

export const handleClient1p1f = (routeData) => {
  handle1p1f(routeData.sitecore);
  return {
    route: routeData.sitecore.route,
    itemId: routeData.sitecore.route.itemId,
    ...routeData.sitecore.context
  };
};
