import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { getPrefixField, createFilePath } from '../../utils/obj-utils';
import { Swiper } from 'swiper';
import { Navigation } from 'swiper';
import Icon from '../../elements/icon';

const getImgName = (str) => {
  let index;
  if (str) {
    index = str.lastIndexOf('/');
    return str.slice(index + 1);
  }
};

export default function index(props) {
  const { wallpaper_list, isMobile } = props;
  const containerRef = useRef(null);
  const swiperRef = useRef(null);
  const swiper2Ref = useRef(null);
  const [num, setNum] = useState(0);
  const [wallpaperNum, setWallpaperNum] = useState(0);
  const [list, setList] = useState(wallpaper_list);
  let swiper;
  let swiper2;

  useEffect(() => {
    swiper = new Swiper(swiperRef.current, {
      grabCursor: true,
      modules: [Navigation],
      slidesPerView: isMobile ? 3 : 5,
      grid: {
        fill: 'column',
        rows: 1
      },
      navigation: {
        prevEl: containerRef.current.querySelector('.navigation-prev'),
        nextEl: containerRef.current.querySelector('.navigation-next'),
        disabledClass: 'navigation-disabled'
      }
    });

    return () => {
      if (swiper) {
        swiper.destroy(true, true);
      }
    };
  }, []);

  useEffect(() => {
    swiper2 = new Swiper(swiper2Ref.current, {
      grabCursor: true,
      modules: [Navigation],
      slidesPerView: isMobile ? 1 : 3,
      grid: {
        fill: 'column',
        rows: 1
      },
      navigation: isMobile
        ? false
        : {
            prevEl: containerRef.current.querySelector('.swiper2-navigation-prev'),
            nextEl: containerRef.current.querySelector('.swiper2-navigation-next'),
            disabledClass: 'swiper2-navigation-disabled'
          }
    });
    return () => {
      if (swiper2) {
        swiper2.destroy(true, true);
      }
    };
  }, [num]);

  const videoOption = (val) => {
    const videoOptionList = containerRef.current.querySelectorAll('.video_option');
    if (videoOptionList && videoOptionList.length > 0) {
      for (let i = 0; i < videoOptionList.length; i++) {
        if (i === val && videoOptionList[i]) {
          if (videoOptionList[i].style.display === 'none') {
            videoOptionList[i].style.display = 'block';
          } else {
            videoOptionList[i].style.display = 'none';
          }
        } else if (i !== val && videoOptionList[i]) {
          videoOptionList[i].style.display = 'none';
        }
      }
    }
  };

  const downloadHover = (val) => {
    if (!isMobile) {
      const imgList = containerRef.current.querySelectorAll('.download_btn_icon');
      if (imgList[val]) {
        imgList[val].src = `${createFilePath('/interaction/download_white.png')}`;
      }
    }
  };

  const downloadLeave = (val) => {
    if (!isMobile) {
      const imgList = containerRef.current.querySelectorAll('.download_btn_icon');
      if (imgList[val]) {
        imgList[val].src = `${createFilePath('/interaction/download_red.png')}`;
      }
    }
  };

  function downloadImg() {
    if (list[num].fields?.wcar_list[wallpaperNum]?.href) {
      var image = new Image();
      image.setAttribute('crossOrigin', 'anonymous');
      image.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL('image/png');
        var a = document.createElement('a');
        var event = new MouseEvent('click');
        a.download = getImgName(list[num].fields?.wcar_list[wallpaperNum]?.href);
        a.href = url;
        a.dispatchEvent(event);
      };
      image.src = createFilePath(list[num].fields?.wcar_list[wallpaperNum]?.href);
    }
  }

  return (
    <div ref={containerRef}>
      <div className="interaction_carList">
        <div className="list_swiperWrap">
          <div className="swiper-container">
            <div className="swiper-support" ref={swiperRef}>
              <div className="swiper-wrapper">
                <React.Fragment>
                  {list &&
                    list.length > 0 &&
                    list.map((item, index) => {
                      return (
                        <div
                          className={`swiper-slide ${index === num ? 'active' : ''}`}
                          key={index}
                          onClick={() => {
                            setNum(index);
                            swiper2 && swiper2.slideTo(0, 500, false);
                          }}
                        >
                          <img alt="广汽本田" src={createFilePath(item.fields[getPrefixField('wcar_img', isMobile)]?.value)} />
                          <p dangerouslySetInnerHTML={{ __html: item.fields[getPrefixField('wcar_name', isMobile)]?.value?.replaceHtml() }} />
                        </div>
                      );
                    })}
                </React.Fragment>
              </div>
            </div>
            <div className="bottomIcon">
              <div className="navigation-prev">
                <Icon name="ArrowLeft" />
              </div>
              <div className="navigation-next">
                <Icon name="ArrowRight" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wallpaper_list">
        <div className="swiper-container">
          <div className="swiper-support" ref={swiper2Ref}>
            <div className="swiper-wrapper">
              <React.Fragment>
                {list[num].fields?.wcar_list?.length > 0 &&
                  list[num].fields?.wcar_list.map((item, index) => {
                    return (
                      <div className="swiper-slide" key={index} onClick={() => setWallpaperNum(index)}>
                        <img alt="广汽本田" src={createFilePath(item.fields?.size_590?.value)} className="swiper-slide-img" />
                        <div className="video_k" onClick={() => videoOption(index)}>
                          <span className="video_k_top video_k_top_co">{item.size ? item.size : ' 请选择尺寸'}</span>
                          <i
                            className="video_k_icon"
                            style={{
                              background: `url(${createFilePath('/interaction/video_icon.png')})`,
                              backgroundPositionX: isMobile ? '-118px' : '-74px',
                              backgroundPositionY: '0'
                            }}
                          />
                          <div className="video_option">
                            <p
                              style={{
                                backgroundColor: item.size === '1024×768' ? '#e10825' : 'white',
                                color: item.size === '1024×768' ? 'white' : '#5f5e5e'
                              }}
                              className="video_option_p"
                              onClick={() => {
                                item.href = item.fields?.size_1024?.value;
                                item.size = '1024×768';
                                setList([...list]);
                              }}
                            >
                              1024×768
                            </p>
                            <p
                              style={{
                                backgroundColor: item.size === '1280×800' ? '#e10825' : 'white',
                                color: item.size === '1280×800' ? 'white' : '#5f5e5e'
                              }}
                              className="video_option_p"
                              onClick={() => {
                                item.href = item.fields?.size_1280?.value;
                                item.size = '1280×800';
                                setList([...list]);
                              }}
                            >
                              1280×800
                            </p>
                            <p
                              style={{
                                backgroundColor: item.size === '1440×900' ? '#e10825' : 'white',
                                color: item.size === '1440×900' ? 'white' : '#5f5e5e'
                              }}
                              className="video_option_p"
                              onClick={() => {
                                item.href = item.fields?.size_1440?.value;
                                item.size = '1440×900';
                                setList([...list]);
                              }}
                            >
                              1440x900
                            </p>
                            <p
                              style={{
                                backgroundColor: item.size === '1920×1080' ? '#e10825' : 'white',
                                color: item.size === '1920×1080' ? 'white' : '#5f5e5e'
                              }}
                              className="video_option_p"
                              onClick={() => {
                                item.href = item.fields?.size_1920?.value;
                                item.size = '1920×1080';
                                setList([...list]);
                              }}
                            >
                              1920x1080
                            </p>
                          </div>
                        </div>
                        <a
                          className="paperDownload"
                          onMouseEnter={() => downloadHover(index)}
                          onMouseLeave={() => downloadLeave(index)}
                          onClick={() => downloadImg()}
                        >
                          <label className="download_btn">
                            <img
                              alt="广汽本田"
                              className="download_btn_icon"
                              src={createFilePath(isMobile ? '/interaction/download_white.png' : '/interaction/download_red.png')}
                            />
                            <span className="download_btn_co">壁纸下载 </span>
                          </label>
                        </a>
                      </div>
                    );
                  })}
              </React.Fragment>
            </div>
          </div>
          {!isMobile && (
            <div className="swiper2-bottomIcon">
              <div className="swiper2-navigation-prev">
                <img alt="广汽本田" src={createFilePath('/interaction/arrowLeft03.png')} />
              </div>
              <div className="swiper2-navigation-next">
                <img alt="广汽本田" src={createFilePath('/interaction/arrowRight03.png')} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
