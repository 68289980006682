import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, getQueryString, isInView, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import FullModalDetail from '../../elements/FullModalDetail_v1';
import 'swiper/swiper.min.css';
import temp from '../../temp/config';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const jsonData = JSON.parse(fields?.jsonData?.value);
  // const jsonData = {
  //   "left": {
  //     "title": "Black Edition幻夜系列",
  //     "sub": "冲击视觉的熏黑处理，深沉中暗藏华丽格调",
  //     "fm": "/avancier/v2/pc/exterior/exterior2_pic1fm.jpg",
  //     "video": "/avancier/v2/pc/exterior/exterior.mp4",
  //     "bigfm": "/avancier/v2/pc/exterior/exterior2_pic1.jpg",
  //     "index": 0,
  //     "opendmp": "price_exterior1-video-open",
  //     "closedmp": "price_exterior1-video-close",
  //     "btn": "完整视频"
  //   },
  //   "right": {
  //     "title": "\"千万纪念\"选装套件",
  //     "sub": "硬朗的前脸造型，一体感的尾部设计",
  //     "lists": [
  //       {
  //         "name": "全新前脸造型",
  //         "fm": "/avancier/v2/pc/exterior/exterior2_pic2fm.jpg",
  //         "bigfm": "/avancier/v2/pc/exterior/exterior2_pic2.jpg",
  //         "opendmp": "price_exterior1-pic1",
  //         "closedmp": "price_exterior1-pclose1",
  //         "index": 1,
  //         "text": "<div class=\"name\">全新前脸造型</div><div class=\"p\">高亮黑涂装前格栅搭配黑色电镀饰条，增强硬朗豪华感。<br>银色涂装保险杠加饰件，点缀高级格调。</div>"
  //       },
  //       {
  //         "name": "一体感尾部设计",
  //         "fm": "/avancier/v2/pc/exterior/exterior2_pic3fm.jpg",
  //         "bigfm": "/avancier/v2/pc/exterior/exterior2_pic3.jpg",
  //         "opendmp": "price_exterior1-pic2",
  //         "closedmp": "price_exterior1-pclose2",
  //         "index": 2,
  //         "text": "<div class=\"name\">一体感尾部设计</div><div class=\"p\">黑色电镀后围饰条，搭配局部黑/银色涂装， 让高级感一体成型。</div>"
  //       }
  //     ]
  //   },
  //   "m_left": {
  //     "title": "Black Edition幻夜系列",
  //     "sub": "冲击视觉的熏黑处理，深沉中暗藏华丽格调",
  //     "fm": "/avancier/v2/mobile/exterior/exterior2_pic1fm.jpg",
  //     "video": "/avancier/v2/pc/exterior/exterior.mp4",
  //     "bigfm": "/avancier/v2/mobile/exterior/exterior2_pic1.jpg",
  //     "index": 0,
  //     "opendmp": "price_exterior1-video-open",
  //     "closedmp": "price_exterior1-video-close",
  //     "btn": "完整视频"
  //   },
  //   "m_right": {
  //     "title": "\"千万纪念\"选装套件",
  //     "sub": "硬朗的前脸造型，一体感的尾部设计",
  //     "lists": [
  //       {
  //         "name": "全新前脸造型",
  //         "fm": "/avancier/v2/mobile/exterior/exterior2_pic2fm.jpg",
  //         "bigfm": "/avancier/v2/mobile/exterior/exterior2_pic2.jpg",
  //         "opendmp": "price_exterior1-pic1",
  //         "closedmp": "price_exterior1-pclose1",
  //         "index": 1,
  //         "text": "<div class=\"name\">全新前脸造型</div><div class=\"p\">高亮黑涂装前格栅搭配黑色电镀饰条，增强<br>硬朗豪华感。银色涂装保险杠加饰件，点缀<br>高级格调。</div>"
  //       },
  //       {
  //         "name": "一体感尾部设计",
  //         "fm": "/avancier/v2/mobile/exterior/exterior2_pic3fm.jpg",
  //         "bigfm": "/avancier/v2/mobile/exterior/exterior2_pic3.jpg",
  //         "opendmp": "price_exterior1-pic2",
  //         "closedmp": "price_exterior1-pclose2",
  //         "index": 2,
  //         "text": "<div class=\"name\">一体感尾部设计</div><div class=\"p\">黑色电镀后围饰条，搭配局部黑/银色涂装， 让高级感一体成型。 </div>"
  //       }
  //     ]
  //   },
  //   "prevdmp": "price_exterior1-btnl",
  //   "nextdmp": "price_exterior1-btnr"
  // }
  const [showDetail, setShowDetail] = useState(false);
  const isMobile = isMobileFun();
  const [propindex, setPropindex] = useState(0);
  const id = props?.params?.pagename ? props?.params?.pagename : 'price_exterior';
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id;
  };
  const windowhidden = () => {
    document.documentElement.style.overflow = 'hidden';
  };
  const closewindowhidden = () => {
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.overflowX = 'hidden';
  };
  return (
    <div className="price-exterior-v11" id={id}>
      <div className="con">
        <div className="left">
          <div className="t" dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('left', isMobile)].title.replaceHtml() }}></div>
          <div className="sub" dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('left', isMobile)].sub.replaceHtml() }}></div>
          <div className="item">
            <img alt="广汽本田" className="pic" src={`${createFilePath(jsonData[getPrefixField('left', isMobile)].fm)}`} />
            <div
              className="play"
              onClick={() => {
                setShowDetail(true);
                setPropindex(jsonData[getPrefixField('left', isMobile)].index);
                windowhidden();
                window.Dmp.clickAction(jsonData[getPrefixField('left', isMobile)].opendmp);
              }}
            >
              <img alt="广汽本田" src={createFilePath('/avancier/v2/pc/power/play.png')} />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="t" dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('right', isMobile)]?.title.replaceHtml() }}></div>
          <div className="sub" dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('right', isMobile)]?.sub.replaceHtml() }}></div>
          {jsonData[getPrefixField('right', isMobile)]?.lists.map((item, index) => {
            return (
              <div key={index} className="item">
                <img alt="广汽本田" className="pic" src={`${createFilePath(item?.fm)}`} />
                <div className="name" dangerouslySetInnerHTML={{ __html: item?.name.replaceHtml() }}></div>
                <div
                  className="setbig"
                  onClick={() => {
                    setShowDetail(true);
                    setPropindex(item?.index);
                    windowhidden();
                    window.Dmp.clickAction(item?.opendmp);
                  }}
                >
                  <img alt="广汽本田" src={createFilePath('/avancier/v2/pc/exterior/big_icon.png')} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {showDetail && (
        <FullModalDetail>
          <div className="price-exterior-v11-prop">
            <div
              className="close"
              onClick={() => {
                setShowDetail(false);
                closewindowhidden();
                if (propindex === 0) {
                  window.Dmp.clickAction(jsonData[getPrefixField('left', isMobile)].closedmp);
                } else {
                  console.log(propindex);
                  window.Dmp.clickAction(jsonData[getPrefixField('right', isMobile)]?.lists[propindex - 1]?.closedmp);
                }
              }}
            >
              {isMobile ? (
                <img alt="广汽本田" src={createFilePath('/avancier/v2/mobile/close_icon.png')} />
              ) : (
                <img alt="广汽本田" src={createFilePath('/avancier/v2/pc/close_icon.png')} />
              )}
            </div>
            <Swiper
              slidesPerView={1}
              className="swiper-prop"
              spaceBetween={0}
              initialSlide={propindex}
              observer={true}
              resizeObserver={true}
              updateOnWindowResize={true}
              modules={[Navigation]}
              navigation={{
                prevEl: '.price-exterior-v11-prop .prev',
                nextEl: '.price-exterior-v11-prop .next',
                disabledClass: 'disabled'
              }}
              onSlideChangeTransitionEnd={(e) => {
                setPropindex(e.activeIndex);
              }}
            >
              <SwiperSlide className="item">
                <div className="pic">
                  {propindex === 0 ? (
                    <video
                      x5-playsinline="true"
                      webkit-playsinline="true"
                      playsInline={true}
                      src={`${createFilePath(jsonData.left.video)}`}
                      poster={`${createFilePath(jsonData.left.bigfm)}`}
                      controls
                      loop
                      autoPlay
                      preload="true"
                    />
                  ) : (
                    <img alt="广汽本田" src={`${createFilePath(jsonData.left.bigfm)}`} />
                  )}
                </div>
              </SwiperSlide>
              {jsonData[getPrefixField('right', isMobile)]?.lists.map((item, index) => {
                return (
                  <SwiperSlide key={index} className="item">
                    <div className="pic">
                      <img alt="广汽本田" src={`${createFilePath(item.bigfm)}`} />
                    </div>
                    <div className="text" dangerouslySetInnerHTML={{ __html: item?.text.replaceHtml() }}></div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="next" data-html-item={jsonData.nextdmp}></div>
            <div className="prev" data-html-item={jsonData.prevdmp}></div>
          </div>
        </FullModalDetail>
      )}
    </div>
  );
}
