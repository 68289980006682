/* eslint-disable no-bitwise */
import React, { useEffect, useState, useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { isEmpty, createFilePath, getPrefixField, checkMobile, getQueryString, isMobileFun } from '../../utils/obj-utils';
import useIsomorphicLayoutEffect from '../../utils/useIsomorphicLayoutEffect';
import { dataFetcher, getJsonp } from '../../dataFetcher';
import FullModalDetail from '../../elements/FullModalDetail_v1';

// 计算经纬度之间的距离
function getDistance(lat1, lng1, lat2, lng2) {
  var radLat1 = (lat1 * Math.PI) / 180.0;
  var radLat2 = (lat2 * Math.PI) / 180.0;
  var a = radLat1 - radLat2;
  var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
  var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
  s = s * 6378.137;
  s = Math.round(s * 10000) / 10000;
  return s;
}

export default function index({ fields }) {
  const isMobile = isMobileFun();
  const containerRef = useRef(null);
  const mapRef = useRef(null);
  const usernameRef = useRef(null);
  const mobileRef = useRef(null);
  const verificationCodeRef = useRef(null);
  const messageCodeRef = useRef(null);
  const typeData = useRef(null);
  const timeData = useRef(null);
  const lastDriveInfo = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [agree, setAgree] = useState(false);
  const [location, setLocation] = useState({ province: '广东省', city: '广州市' });
  const [originalDealerData, setOriginalDealerData] = useState();
  const [provinceData, setProvinceData] = useState();
  const [selectProvince, setSelectProvince] = useState();
  const [selectCity, setSelectCity] = useState();
  const [selectDealer, setSelectDealer] = useState();
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [verificationImage, setVerificationImage] = useState();
  const [policyVersion, setPolicyVersion] = useState(null);
  const method = fields[getPrefixField('method', isMobile)]?.value || 2;
  const [driveInfo, setDriveInfo] = useState({
    mediafrom: method,
    device_type: isMobile ? 2 : 1,
    contactid: useSitecoreContext()?.sitecoreContext?.contactId,
    graphic: {
      code: '',
      id: '',
      preMod: false
    }
  });
  const [messageInfo, setMessageInfo] = useState({ sendType: 1, validWhiteList: false, whiteListType: 0 });

  if (!typeData?.current) {
    typeData.current = JSON.parse(fields?.typeData?.value?.replaceHtml());
    timeData.current = [
      { name: '平时', value: '1' },
      { name: '周末', value: '2' }
    ];
  }
  const [selectVehicleType, setSelectVehicleType] = useState(typeData?.current[0]);
  const [time, setTime] = useState(timeData?.current[0].value);
  const [ownAddress, setOwnAddress] = useState([]);

  const mapContainerRef = useRef(null);

  useIsomorphicLayoutEffect(() => {
    getLocation();
    getOriginalDealer();
    getPolicyVersion();
    initMap();
  }, []);

  useEffect(() => {
    if (originalDealerData) {
      setProvinceData(filterDealerData());
    }
  }, [originalDealerData, selectVehicleType]);

  useEffect(() => {
    if (provinceData && location) {
      const province = provinceData?.find((item) => location.province.includes(item.province_name));
      const city = province?.Citys?.find((item) => location.city.includes(item.city_name));
      setSelectProvince(province);
      setSelectCity(city);
    }
  }, [provinceData, location]);

  useEffect(() => {
    if (selectCity) {
      getRecentDealer(selectCity.Dealers);
    }
  }, [selectCity, selectProvince]);

  useEffect(() => {
    addMarker();
  }, [selectCity, mapLoaded]);

  const initMap = () => {
    const AMapLoader = require('@amap/amap-jsapi-loader');
    window._AMapsecuritvonfig = { securityJsCode: 'df2d1e69d62971d0a7ebb43C882b678a' };
    AMapLoader.load({
      key: '975d8e63e9648540ee56a840bbce9884',
      version: '2.0',
      plugins: ['AMap.Geolocation'],
      AMapUI: {
        version: '1.1',
        plugins: ['overlay/SimpleMarker']
      },
      Loca: {
        version: '2.0.0'
      }
    })
      .then((AMap) => {
        if (mapContainerRef.current) {
          mapRef.current = new AMap.Map('mapContainer', { zoom: 10, center: [113.264385, 23.129112] });
          setMapLoaded(true);
        }
        getCurrentCoordinates();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const addMarker = () => {
    if (mapLoaded) {
      mapRef?.current?.clearMap();
      selectCity?.Dealers?.forEach((item, index) => {
        const marker = new AMapUI.SimpleMarker({
          iconStyle: { src: createFilePath(`/sporthybrid-union/icon${index + 1}.png`) },
          position: [item?.longitude, item?.latitude],
          extData: { index }
        });
        mapRef?.current?.add(marker);
        mapRef?.current?.setFitView();
        marker.on('click', (e) => {
          Dmp.clickAction('drive-btn3', selectCity?.Dealers[e?.target?.De?.extData?.index]?.dealer_name);
          setSelectDealer(selectCity?.Dealers[e?.target?.opts?.extData?.index]);
        });
      });
    }
  };

  const getLocation = () => {
    getJsonp('https://restapi.amap.com/v3/ip?key=d4db88c544bf23a3a363f7ff8bcbf783&output=json')
      .then((response) => {
        if (response.status === '1') {
          if (response?.province?.length > 0 && response?.city?.length > 0) {
            setLocation({ province: response.province, city: response.city });
          }
        } else {
          return Promise.reject();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCurrentCoordinates = () => {
    // sessionStorage是否含有个人经纬度
    if (sessionStorage.getItem('ownAddressLat') && sessionStorage.getItem('ownAddressLng')) {
      setOwnAddress([sessionStorage.getItem('ownAddressLat'), sessionStorage.getItem('ownAddressLng')]);
    } else {
      AMap.plugin('AMap.Geolocation', () => {
        var geolocationLatLng = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认：true
          timeout: 5000 // 设置定位超时时间，默认：无穷大
        });
        geolocationLatLng.getCurrentPosition(function (status, result) {
          if (status === 'complete') {
            sessionStorage.setItem('ownAddressLat', result.position.lat);
            sessionStorage.setItem('ownAddressLng', result.position.lng);
            setOwnAddress([result.position.lat, result.position.lng]);
          } else {
            console.log('errorerrorerrorerror', result);
          }
        });
      });
    }
  };

  const getOriginalDealer = () => {
    dataFetcher('/api/honda/v1/Leads/GetAllDealerProCitys')
      .then(({ data }) => {
        if (data.Status) {
          setOriginalDealerData({ ...data?.Data });
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getPolicyVersion = () => {
    dataFetcher('/api/honda/v1/Policy/GetPolicyVersion')
      .then(({ data }) => {
        if (data.Status) {
          setPolicyVersion(data?.Data?.policy_code);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addLeads = () => {
    dataFetcher('/api/honda/v1/Leads/AddLeads', driveInfo)
      .then(({ data }) => {
        if (data?.Status) {
          alert('提交成功！');
          const data = {
            n: driveInfo.userName,
            tel: driveInfo.mobile,
            prv: driveInfo.provinceId,
            city: driveInfo.cityId,
            dl: driveInfo.dealerId,
            itb: driveInfo.series_code,
            extd: { time: driveInfo.drive_date },
            ver: policyVersion
          };
          Dmp.submitAction('drive', data);
          lastDriveInfo.current = { ...driveInfo };
          driveInfo.userName = '';
          driveInfo.mobile = '';
          usernameRef.current.value = '';
          mobileRef.current.value = '';
          close();
        } else {
          const errorCode = parseInt(data?.ErrorResult?.ErrCode);
          if (errorCode === 26 || errorCode === 27 || errorCode === 28) {
            if (errorCode === 28 && showVerificationCode) {
              return Promise.reject(new Error('短信验证码输入错误！'));
            } else {
              setShowVerificationCode(true);
              getVerificationImage();
            }
          } else {
            return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
          }
        }
      })
      .catch((error) => {
        alert(error?.message);
        console.error(error);
      });
  };

  const getVerificationImage = () => {
    dataFetcher('/api/honda/v1/Graphic/CreateCode')
      .then(({ data }) => {
        if (data?.Status) {
          driveInfo.graphic.id = data?.Data?.GraphicValidId;
          messageInfo.validId = data?.Data?.GraphicValidId;
          messageInfo.validCode = '';
          verificationCodeRef.current.value = '';
          setVerificationImage(data?.Data?.Base64);
        } else {
          return Promise.reject(new Error(data?.ErrorResult?.ErrMsg));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filterDealerData = () => {
    const originalData = JSON.parse(JSON.stringify(originalDealerData));
    if (!selectVehicleType) {
      return reduceDealerData(originalData);
    }
    const data = { Provinces: [], Citys: [], Dealers: [...originalData?.Dealers] };
    originalData?.Citys?.forEach((item) => {
      const dealers = getDealersData(item.city_id, originalData?.Dealers);
      if (dealers.length !== 0) {
        item.Dealers = dealers;
        data.Citys.push(item);
      }
    });
    originalData?.Provinces?.forEach((item) => {
      const citys = data?.Citys?.filter((item1) => item1.province_id === item.province_id);
      if (citys.length !== 0) {
        item.Citys = citys;
        data.Provinces.push(item);
      }
    });
    return data?.Provinces;
  };

  const reduceDealerData = (data) => {
    data?.Provinces?.forEach((item1) => {
      data?.Citys?.forEach((item2) => {
        item2.Dealers = data?.Dealers?.filter((item3) => item3.city_id === item2.city_id);
      });
      item1.Citys = data?.Citys?.filter((item2) => item2.province_id === item1.province_id);
    });
    return [...data?.Provinces];
  };

  const getDealersData = (cityId, data) => {
    const modelName = selectVehicleType?.modelName;
    const weight = selectVehicleType?.weight;
    let dealers = [];
    if (!isEmpty(weight)) {
      dealers = data.filter((item) => item.city_id === cityId && (weight & item.dealer_right) > 0);
      // if (modelName === 'enp1') {
      //   if (dealers.length === 0) {
      //     dealers = data.filter((item) => item.city_id === cityId && item.dealer_level === 1);
      //   } else {
      //     const exist = dealers.find((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     if (exist) {
      //       dealers = dealers.filter((item) => item.dealer_type === 2 || item.dealer_type === 3);
      //     }
      //   }
      // }
    }
    const newEnergy = dealers.filter((item) => item.dealer_right === 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    const gas = dealers.filter((item) => item.dealer_right !== 65536).sort((item1, item2) => item1.dealer_type - item2.dealer_type);
    return [...newEnergy, ...gas];
  };

  const usernameChange = (e) => {
    driveInfo.userName = e.target.value;
  };

  const mobileChange = (e) => {
    driveInfo.mobile = e.target.value;
  };

  const vehicleTypeChange = (e) => {
    Dmp.msgAction('drive-car', e.target.value);
    setSelectVehicleType(typeData?.current?.find((item) => item.seriesId === e.target.value));
  };

  const getRecentDealer = (dealers) => {
    setSelectDealer(dealers[0]);
    let ownLatLng = ownAddress;
    if (sessionStorage.getItem('ownAddressLat') && sessionStorage.getItem('ownAddressLng')) {
      ownLatLng = [Number(sessionStorage.getItem('ownAddressLat')), Number(sessionStorage.getItem('ownAddressLng'))];
    }
    if (ownLatLng[0] && ownLatLng[1]) {
      let distance = Number.MAX_VALUE;
      for (let i = 0; i < dealers.length; i++) {
        if (getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealers[i].latitude), Number(dealers[i].longitude)) < distance) {
          distance = getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealers[i].latitude), Number(dealers[i].longitude));
        }
      }
      for (let i = 0; i < dealers.length; i++) {
        if (getDistance(Number(ownLatLng[0]), Number(ownLatLng[1]), Number(dealers[i].latitude), Number(dealers[i].longitude)) === distance) {
          setSelectDealer(dealers[i]);
        }
      }
    }
  };

  const provinceChange = (e) => {
    Dmp.msgAction('drive-province', e.target.value);
    var city = provinceData?.find((item) => item.province_id === e.target.value);
    setSelectProvince(city);
    setSelectCity(city?.Citys[0]);
  };

  const cityChange = (e) => {
    Dmp.msgAction('drive-city', e.target.value);
    setSelectCity(selectProvince?.Citys?.find((item) => item.city_id === e.target.value));
  };

  const dealerChange = (e) => {
    Dmp.msgAction('drive-shop', e.target.value);
    setSelectDealer(selectCity?.Dealers?.find((item) => item.dealer_id === e.target.value));
  };

  const timeChange = (e) => {
    Dmp.msgAction('drive-time', e.target.value);
    setTime(e.target.value);
  };

  const agreeChange = () => {
    Dmp.decideAction('drive-agree', !agree);
    setAgree(!agree);
  };

  const driverSubmit = () => {
    Dmp.clickAction('drive-btn1');
    if (isEmpty(driveInfo?.userName)) {
      alert('请填写真实姓名');
      return;
    }
    if (!checkMobile(driveInfo?.mobile)) {
      alert('请填写正确的联系方式');
      return;
    }
    if (!selectVehicleType) {
      alert('请选择意向车型');
      return;
    }
    if (!selectProvince) {
      alert('请选择省份');
      return;
    }
    if (!selectCity) {
      alert('请选择城市');
      return;
    }
    if (!selectDealer) {
      alert('请选择特约店');
      return;
    }
    if (!agree) {
      alert('请确认并阅读声明');
      return;
    }
    if (showVerificationCode && isEmpty(driveInfo?.smsValidCode)) {
      alert('短信验证码不能为空');
      return;
    }
    driveInfo.provinceId = selectProvince?.province_id;
    driveInfo.cityId = selectCity?.city_id;
    driveInfo.dealerId = selectDealer?.dealer_id;
    driveInfo.series_code = selectVehicleType?.seriesId;
    driveInfo.areaCode = selectProvince?.zone_id;
    driveInfo.adcode = getQueryString('adcode');
    driveInfo.graphic.code = messageInfo?.validCode;
    driveInfo.drive_date = time;
    if (lastDriveInfo?.current) {
      let exist = false;
      for (const key in lastDriveInfo?.current) {
        if (!['graphic', 'smsValidId', 'smsValidCode'].includes(key) && lastDriveInfo?.current[key] !== driveInfo[key]) {
          exist = true;
          break;
        }
      }
      if (!exist) {
        alert('重复提交！');
        return;
      }
    }
    addLeads();
  };

  const verificationCodeChange = (e) => {
    messageInfo.validCode = e.target.value;
  };

  const messageCodeChange = (e) => {
    driveInfo.smsValidCode = e.target.value;
  };

  const sendMessage = () => {
    if (isEmpty(messageInfo?.validCode)) {
      alert('请输入图形验证码！');
      return;
    }
    messageInfo.phone = driveInfo?.mobile;
    dataFetcher('/api/honda/v1/SMS/SendSMS', messageInfo)
      .then(({ data }) => {
        if (data.Status) {
          alert('已发送短信,请注意查收');
          driveInfo.smsValidId = data?.Data?.smsValidId;
        } else {
          getVerificationImage();
          const errorCode = parseInt(data?.ErrorResult?.ErrCode);
          let errorMessage;
          switch (errorCode) {
            case -1:
              errorMessage = '短信发送失败！';
              break;
            case 5:
              errorMessage = '图形验证码错误！';
              break;
            case 11:
              errorMessage = '手机号当天发送次数超限！';
              break;
            case 12:
              errorMessage = '手机号发送次数超限，请1小时候再试！';
              break;
            case 13:
              errorMessage = '手机号发送频率过快，请稍后再试！';
              break;
            case 21:
              errorMessage = '非常抱歉，发送验证码时出现异常，请稍后再试！';
              break;
            default:
              errorMessage = data?.ErrorResult?.ErrMsg;
              break;
          }
          return Promise.reject(new Error(errorMessage));
        }
      })
      .catch((error) => {
        alert(error?.message);
        console.error(error);
      });
  };

  const close = () => {
    setDriveInfo({
      ...driveInfo,
      graphic: {
        code: '',
        id: '',
        preMod: false
      },
      smsValidId: '',
      smsValidCode: ''
    });
    setMessageInfo({ ...messageInfo, validId: '', validCode: '', phone: '' });
    verificationCodeRef?.current && (verificationCodeRef.current.value = '');
    messageCodeRef?.current && (messageCodeRef.current.value = '');
    setShowVerificationCode(false);
  };

  return (
    <section className="test-drive-v2" ref={containerRef}>
      {isMobile && (
        <div className="test-drive-v2-title">
          <p>预约试驾</p>
        </div>
      )}
      <div className="test-drive-v2-container">
        <div className="form-container">
          <div className="input-container">
            <label className="drive-info-name">真实姓名</label>
            <input
              className="drive-info-input"
              ref={usernameRef}
              type="text"
              placeholder="你的真实姓名"
              onChange={usernameChange}
              onBlur={(e) => Dmp.msgAction('drive-name', null)}
            />
          </div>
          <div className="input-container">
            <label className="drive-info-name">联系方式</label>
            <input
              className="drive-info-input"
              ref={mobileRef}
              type="text"
              placeholder="你的手机号码"
              onChange={mobileChange}
              onBlur={(e) => Dmp.msgAction('drive-tel', null)}
            />
          </div>
          <div className="input-container">
            <label className="drive-info-name">意向试驾车型</label>
          </div>
          <div className="drive-info-car-list">
            {typeData?.current?.map((item, index) => (
              <div key={index} className="drive-info-car-item">
                <img alt="广汽本田" className="drive-info-car-image" src={createFilePath(item?.image)} />
                <input
                  className="drive-info-car-input"
                  type="radio"
                  value={item?.seriesId}
                  checked={selectVehicleType?.seriesId === item?.seriesId}
                  onChange={vehicleTypeChange}
                />
                <div className="p">
                  <span
                    className="drive-info-car-radio"
                    style={{
                      backgroundImage: `url(${createFilePath(
                        `/sporthybrid-union/${selectVehicleType?.seriesId === item?.seriesId ? '3a1' : '3a2'}.png`
                      )})`
                    }}
                  />
                  {item?.name}
                </div>
              </div>
            ))}
          </div>
          <div className="input-container drive-time">
            <label className="drive-info-name">希望试驾时间</label>
            {timeData?.current?.map((item, index) => (
              <span key={index} className="drive-info-time-item">
                <input className="drive-info-time-input" type="radio" value={item?.value} checked={time === item?.value} onChange={timeChange} />
                <span
                  className="drive-info-time-radio"
                  style={{
                    backgroundImage: `url(${createFilePath(`/sporthybrid-union/${time === item?.value ? '3a1' : '3a2'}.png`)})`
                  }}
                />
                {item?.name}
              </span>
            ))}
          </div>
          <div className="input-container drive-city">
            <label className="drive-info-name">选择特约店</label>
            <span className="select-container">
              <select className="drive-info-select" value={selectProvince?.province_id || 0} onChange={provinceChange}>
                <option value={0}>请选择省份</option>
                {provinceData?.map((item, index) => (
                  <option key={index} value={item?.province_id}>
                    {item?.province_name}
                  </option>
                ))}
              </select>
              <select className="drive-info-select" value={selectCity?.city_id || 0} onChange={cityChange}>
                <option value={0}>请选择城市</option>
                {selectProvince?.Citys?.map((item, index) => (
                  <option key={index} value={item?.city_id}>
                    {item?.city_name}
                  </option>
                ))}
              </select>
            </span>
          </div>
          <div className="input-container">
            <label className="drive-info-name" />
            <select className="drive-info-select" value={selectDealer?.dealer_id || 0} onChange={dealerChange}>
              <option value={0}>请选择特约店</option>
              {selectCity?.Dealers?.map((item, index) => (
                <option key={index} value={item?.dealer_id}>
                  {item?.dealer_name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-container drive-address">
            <div>&nbsp;</div>
            <p>{selectDealer?.address}</p>
          </div>
          <p className="drive-info-policy">
            <input type="checkbox" checked={agree} onChange={agreeChange} />
            <span
              className="drive-info-policy-check"
              style={{
                backgroundImage: `url(${createFilePath(`/sporthybrid-union/${agree ? '3b' : '3b2'}.png`)})`
              }}
            />
            本人同意
            <a data-action="click" data-item="drive-policy" data-url="/policy/privacy-policy" href="/policy/privacy-policy" target="_blank">
              《广汽本田隐私政策》
            </a>
            ；广汽本田将对您提交的个人信息保密，不对外公开。
          </p>
          <button className="drive-info-button" onClick={driverSubmit}>
            马上预约 &gt;
          </button>
        </div>
        {!isMobile && <div className="map-container" id="mapContainer" ref={mapContainerRef} />}
      </div>
      {showVerificationCode && (
        <FullModalDetail style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="test-drive-v2-verification-code">
            <div className="verification-code-form">
              <img alt="广汽本田" className="verification-code-close" src={createFilePath('/sporthybrid-union/modalClose.png')} onClick={close} />
              <div className="verification-code-item">
                <div className="verification-code-item-name">手机号：</div>
                <div className="verification-code-item-right">{driveInfo?.mobile}</div>
              </div>
              <div className="verification-code-item">
                <div className="verification-code-item-name">图形验证码：</div>
                <img alt="广汽本田" className="verification-code-item-image" src={verificationImage} onClick={getVerificationImage} />
                <div className="verification-code-item-right">
                  <input
                    className="verification-code-item-input"
                    ref={verificationCodeRef}
                    type="text"
                    placeholder="请输入图形验证码"
                    onChange={verificationCodeChange}
                  />
                </div>
              </div>
              <div className="verification-code-item">
                <div className="verification-code-item-name">短信验证码：</div>
                <button className="verification-code-item-button" onClick={sendMessage}>
                  发送
                </button>
                <div className="verification-code-item-right">
                  <input
                    className="verification-code-item-input"
                    ref={messageCodeRef}
                    type="text"
                    placeholder="请输入短信验证码"
                    onChange={messageCodeChange}
                  />
                </div>
              </div>
              <button className="verification-code-button" onClick={driverSubmit}>
                确定
              </button>
            </div>
          </div>
        </FullModalDetail>
      )}
    </section>
  );
}
