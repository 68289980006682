import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import { Swiper } from 'swiper';
import { Pagination, Navigation } from 'swiper';
import temp from '../../../temp/config';
import './style.scss';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../../utils/obj-utils';
import Icon from '../../../elements/icon';
import './style_odyssey.scss';
import './style_crider.scss';
import './style_avancier.scss';
import './style_integra_hyrid.scss';
import './style_vezel.scss';
import './style_breezePet.scss';
import FulModulDetail from '../../../elements/FullModal';
export default function index({ fields, eventRef }) {
  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const { specific, num, setNum, close, closeDmp, styleBox, num2 } = fields;
  const [reserveFlag, setReserveFlag] = useState(false);
  // const deployUrlS3 = temp.app.deployUrlS3;
  const isMobile = isMobileFun();
  const imgList = specific;
  const [data, _] = useState({
    imgList,
    isMobile
  });
  useImperativeHandle(
    eventRef,
    () => {
      return {
        sendEvent
      };
    },
    []
  );

  const sendEvent = () => {
    // sendDmp(fields?.sectionDmp);
    // window.navigationIndex = 8;
  };

  useEffect(() => {
    localStorage.setItem('kk', num);
    if (!data) {
      return;
    }

    const swiper = new Swiper(swiperRef.current, {
      grabCursor: true,
      modules: [Pagination, Navigation],
      slidesPerView: 1,
      grid: {
        fill: 'column',
        rows: 1
      },
      pagination: data?.isMobile
        ? {
            el: containerRef.current.querySelector('.swiper-pagination'),
            clickable: true
          }
        : false,
      navigation: {
        prevEl: containerRef.current.querySelector('.navigation-prev'),
        nextEl: containerRef.current.querySelector('.navigation-next'),
        disabledClass: 'navigation-disabled'
      },
      initialSlide: num,
      on: {
        slideChange: function (e) {
          localStorage.setItem('kk', e.activeIndex);
        }
      }
    });
    return () => {
      swiper.destroy(true, true);
    };
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    const publicPraiseTimeline = gsap.timeline({ pause: true });
    if (!data.isMobile) {
      publicPraiseTimeline.fromTo(swiperRef.current, { x: '100%', opacity: 0 }, { x: '0', opacity: 1 });
    } else {
      publicPraiseTimeline.fromTo(titleRef.current, { opacity: 0 }, { opacity: 1 }).fromTo(swiperRef.current, { x: '100%' }, { x: '0' });
    }
    const controller = new ScrollMagic.Controller();
    const publicPraiseScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onEnter',
      duration: data.isMobile ? document.documentElement.clientHeight : '80%'
    })
      .on('progress', function (event) {
        publicPraiseTimeline.progress(event.progress);
      })
      .setTween(publicPraiseTimeline)
      .addTo(controller);

    return () => {
      publicPraiseTimeline.kill();
      publicPraiseScene.remove();
      publicPraiseScene.destroy();
      controller.destroy();
    };
  }, [data]);
  const [isCloseShow, setIsCloseShow] = useState(false);
  return (
    <FulModulDetail>
      <section className="swiperDetails" ref={containerRef}>
        {data ? (
          <div className="publicPraiseV2">
            <div className="content-container">
              <div className="swiper-container">
                <div className="swiper-support" ref={swiperRef}>
                  <div className="swiper-wrapper">
                    <React.Fragment>
                      {data?.imgList?.map((item, index) => {
                        return (
                          <div
                            className={`swiper-slide ${isCloseShow ? 'isCloseShow' : ''}`}
                            style={{
                              display:
                                isCloseShow && index === Number(localStorage.getItem('kk'))
                                  ? 'block'
                                  : isCloseShow && index !== Number(localStorage.getItem('kk'))
                                  ? 'none'
                                  : 'block'
                            }}
                            key={index}
                          >
                            <div className="cardDetail">
                              {!isMobile ? (
                                <div className="securityDetail">
                                  {item.fields.conHtml.value ? (
                                    <div className="detailDiy" dangerouslySetInnerHTML={{ __html: item.fields.conHtml.value?.replaceHtml() }}></div>
                                  ) : (
                                    <React.Fragment>
                                      <img
                                        alt="广汽本田"
                                        src={`${createFilePath(item.fields.img.value)}`}
                                        className={`securityDetailImg securityDetailImg${styleBox}${Number(localStorage.getItem('kk'))}${
                                          reserveFlag ? 'T' : ''
                                        }`}
                                      />
                                      <div className={`securityDetailInner${reserveFlag ? 'T' : ''}`}>
                                        <div dangerouslySetInnerHTML={{ __html: item.fields.inner.value?.replaceHtml() }}></div>
                                        {item.fields.images.length !== 0 ? (
                                          <div style={{ display: 'grid', gridAutoFlow: 'column', columnGap: '1vw', width: '60%', marginTop: '1vh' }}>
                                            {item.fields.images.map((it, i) => {
                                              return (
                                                <div
                                                  onClick={(e) => {
                                                    window.Dmp.clickAction(it.fields.dmp[0]?.displayName, '', { val: it.fields.dmpText.value });
                                                    for (let idx = 0; idx < e.target.parentNode.childNodes.length; idx++) {
                                                      e.target.parentNode.childNodes[idx].style.border = '2px solid white';
                                                    }
                                                    e.target.style.border = '2px solid rgb(138,138,138)';
                                                    e.target.parentNode.parentNode.parentNode.childNodes[0].src = `${createFilePath(
                                                      it.fields.url.value
                                                    )}`;
                                                  }}
                                                  key={i}
                                                  style={{
                                                    background: it.fields.color.value,
                                                    width: '2.75rem',
                                                    height: '2.75rem',
                                                    borderRadius: '50%',
                                                    border: num2 === i ? '2px solid rgb(138,138,138)' : '2px solid white',
                                                    cursor: 'pointer'
                                                  }}
                                                ></div>
                                              );
                                            })}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                              ) : (
                                <div className="securityDetail">
                                  {item.fields.conHtml.value ? (
                                    <div className="detailDiy" dangerouslySetInnerHTML={{ __html: item.fields.conHtml.value?.replaceHtml() }}></div>
                                  ) : (
                                    <React.Fragment>
                                      <img
                                        alt="广汽本田"
                                        src={`${createFilePath(item.fields.m_img.value)}`}
                                        className={`securityDetailImg securityDetailImg${styleBox}${Number(localStorage.getItem('kk'))}${
                                          reserveFlag ? 'T' : ''
                                        }`}
                                      />
                                      <div className={`securityDetailInner${reserveFlag ? 'T' : ''}`}>
                                        <div dangerouslySetInnerHTML={{ __html: item.fields.m_inner.value?.replaceHtml() }}></div>
                                        {item.fields.images.length !== 0 ? (
                                          <div
                                            style={{
                                              display: 'grid',
                                              gridAutoFlow: 'column',
                                              columnGap: '6vw',
                                              margin: '0 auto',
                                              paddingTop: '1vh'
                                            }}
                                          >
                                            {item.fields.images.map((it, i) => {
                                              return (
                                                <div
                                                  onClick={(e) => {
                                                    window.Dmp.clickAction(it.fields.dmp[0]?.displayName, '', { val: it.fields.dmpText.value });
                                                    for (let idx = 0; idx < e.target.parentNode.childNodes.length; idx++) {
                                                      e.target.parentNode.childNodes[idx].style.border = '2px solid white';
                                                    }
                                                    e.target.style.border = '2px solid rgb(138,138,138)';
                                                    e.target.parentNode.parentNode.parentNode.childNodes[0].src = `${createFilePath(
                                                      it.fields.m_url.value
                                                    )}`;
                                                  }}
                                                  key={i}
                                                  style={{
                                                    background: it.fields.color.value,
                                                    width: '28px',
                                                    height: '28px',
                                                    borderRadius: '50%',
                                                    border: num2 === i ? '2px solid rgb(138,138,138)' : '2px solid white'
                                                  }}
                                                ></div>
                                              );
                                            })}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  </div>
                </div>
                <div className={`bottomIcon bottomIcon${reserveFlag ? 'T' : ''}`}>
                  <div
                    className="navigation-prev"
                    onClick={() => {
                      fields.dmpSwiperName
                        ? fields.dmpSwiperName === 'price_control-p'
                          ? window.Dmp.clickAction(`${fields.dmpSwiperName}btnl`, '', '')
                          : window.Dmp.clickAction(`${fields.dmpSwiperName}-btnl`, '', '')
                        : window.Dmp.clickAction('price_space-btnl', '', '');
                    }}
                  >
                    <Icon name="ArrowLeft" />
                  </div>
                  <div
                    className="navigation-next"
                    onClick={() => {
                      fields.dmpSwiperName
                        ? fields.dmpSwiperName === 'price_control-p'
                          ? window.Dmp.clickAction(`${fields.dmpSwiperName}btnr`, '', '')
                          : window.Dmp.clickAction(`${fields.dmpSwiperName}-btnr`, '', '')
                        : window.Dmp.clickAction('price_space-btnr', '', '');
                    }}
                  >
                    <Icon name="ArrowRight" />
                  </div>
                  {data?.isMobile && <div className="swiper-pagination" style={{ bottom: '-90.5vh' }} />}
                </div>
              </div>
              <img
                alt="广汽本田"
                src={`${createFilePath(close)}`}
                className={`securityDetailClose securityDetailClose${reserveFlag ? 'T' : ''}`}
                onClick={() => {
                  window.Dmp.clickAction(specific[Number(localStorage.getItem('kk'))].fields.closeDmp[0].displayName, '', {
                    val: specific[Number(localStorage.getItem('kk'))].fields.name.value
                  });
                  // setReserveFlag(true);
                  // setIsCloseShow(true);
                  // setTimeout(() => {
                  setNum(-1);
                  setReserveFlag(false);
                  // }, 700);
                }}
              />
            </div>
          </div>
        ) : null}
      </section>
    </FulModulDetail>
  );
}
