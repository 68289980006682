import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMobileFun, getPrefixField, createFilePath, convertStylesStringToObject } from '../../utils/obj-utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import Price_space_v1 from '../Price_space_v1';
import 'swiper/swiper.min.css';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const swiperRef = useRef(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename;
  const rootEl = useRef(null);
  const secondPageRef = useRef(null);
  const secondPageEventRef = useRef(null);
  const thirdPageRef = useRef(null);
  const thirdPageEventRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  // sectionAction
  useEffect(() => {
    if (
      (fields?.placeHolderRoute?.value && !fields?.placeHolderRoute1?.value) ||
      (!fields?.placeHolderRoute?.value && fields?.placeHolderRoute1?.value)
    ) {
      props.updateRefList([sendFirstDmp, sendSecondDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current]);
    } else if (fields?.placeHolderRoute?.value && fields?.placeHolderRoute1?.value) {
      props.updateRefList([sendFirstDmp, sendSecondDmp, sendThirdDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current, thirdPageRef?.current]);
    } else {
      props.updateRefList([sendFirstDmp]);
      props.updateDmpList([rootEl?.current]);
    }
  }, []);
  const sendFirstDmp = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };

  const sendSecondDmp = () => {
    secondPageEventRef?.current?.sendEvent();
  };
  const sendThirdDmp = () => {
    thirdPageEventRef?.current?.sendEvent();
  };
  const swiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
  };
  return (
    <div id={id}>
      <div className="Price_exteriorLists_v2" ref={rootEl}>
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          className="lists_swiper"
          spaceBetween={0}
          initialSlide={0}
          observer={true}
          resizeObserver={true}
          updateOnWindowResize={true}
          modules={[Pagination, Navigation]}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
            disabledClass: 'disabled'
          }}
          onSwiper={(swiper) => {
            setTimeout(() => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
          onSlideChangeTransitionEnd={(e) => {
            setSwiperIndex(e.activeIndex);
          }}
        >
          {fields?.lists.map((item, index) => {
            return (
              <SwiperSlide className="item" key={index}>
                <Price_space_v1 fields={item?.fields}></Price_space_v1>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          className="lists_next"
          ref={navigationNextRef}
          data-html-item={fields?.nextdmp?.value}
          style={convertStylesStringToObject(fields[`${getPrefixField('nexticonstyle', isMobile)}`]?.value)}
        >
          <img className="icon" src={createFilePath(fields[`${getPrefixField('nexticon', isMobile)}`]?.value)} alt="广汽本田" />
        </div>
        <div
          className="lists_prev"
          ref={navigationPrevRef}
          data-html-item={fields?.prevdmp?.value}
          style={convertStylesStringToObject(fields[`${getPrefixField('previconstyle', isMobile)}`]?.value)}
        >
          <img className="icon" src={createFilePath(fields[`${getPrefixField('previcon', isMobile)}`]?.value)} alt="广汽本田" />
        </div>
        {!fields[`${getPrefixField('isnopagination', isMobile)}`]?.value && (
          <div className={`lists_swiper-pagination ${fields?.paginationclass?.value}`}>
            {fields?.lists.map((item, index) => {
              return (
                <span
                  key={index}
                  className={`swiper-pagination-bullet ${swiperIndex === index ? 'swiper-pagination-bullet-active' : ''}`}
                  onClick={() => {
                    swiperslideTo(index);
                    window.Dmp.clickAction(fields?.paginationdmp?.value ? fields?.paginationdmp?.value + (index + 1) : '');
                  }}
                ></span>
              );
            })}
          </div>
        )}
      </div>
      {fields?.placeHolderRoute?.value && (
        <div ref={secondPageRef}>
          <Placeholder name={fields?.placeHolderRoute?.value} rendering={route} page={route} eventRef={secondPageEventRef} />
        </div>
      )}
      {fields?.placeHolderRoute1?.value && (
        <div ref={thirdPageRef}>
          <Placeholder name={fields?.placeHolderRoute1?.value} rendering={route} page={route} eventRef={thirdPageEventRef} />
        </div>
      )}
    </div>
  );
}
