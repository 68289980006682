import React, { useState } from 'react';
import Detailss from '../../elements/InnerPageDetail';
import { getPrefixField, isMobileFun, createFilePath, convertStylesStringToObject } from '../../utils/obj-utils';
import './style.scss';

export default function index({ fields }) {
  const [flag, setFlag] = useState(false);
  const [videoindex, setVideoindex] = useState(0);
  const isMobile = isMobileFun();
  return (
    <>
      <div className="InnerPageVideoLists_v1">
        {fields?.lists.map((item, index) => {
          return (
            <div key={index} className="item">
              {item?.fields[getPrefixField('img', isMobile)]?.value && (
                <div className="video" style={convertStylesStringToObject(item?.fields[`${getPrefixField('videostyle', isMobile)}`]?.value)}>
                  <img
                    alt="广汽本田"
                    className="img"
                    style={convertStylesStringToObject(item?.fields[`${getPrefixField('imgstyle', isMobile)}`]?.value)}
                    src={`${createFilePath(item?.fields[getPrefixField('img', isMobile)]?.value)}`}
                  />
                  <img
                    alt="play"
                    className="icon"
                    src={`${createFilePath(item?.fields[getPrefixField('icon', isMobile)]?.value)}`}
                    style={convertStylesStringToObject(item?.fields[`${getPrefixField('iconstyle', isMobile)}`]?.value)}
                    onClick={() => {
                      setFlag(true);
                      setVideoindex(index);
                      window.Dmp.clickAction(item?.fields?.dmp?.value, createFilePath(item?.fields?.videourl?.value));
                    }}
                  />
                </div>
              )}
              {item?.fields[`${getPrefixField('text', isMobile)}`]?.value && (
                <div
                  className="right"
                  dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}
                ></div>
              )}
            </div>
          );
        })}
        {flag && (
          <Detailss
            routePath={fields?.lists[videoindex]?.fields?.detailurl?.value}
            {...fields?.lists[videoindex]?.fields}
            close={() => setFlag(false)}
          />
        )}
      </div>
    </>
  );
}
