import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { sendDmp, getQueryString, isInView, getPrefixField, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { useSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import FullModalDetail from '../../elements/FullModalDetail_v1';
import temp from '../../temp/config';
import './style.scss';
import gsap from 'gsap';

export default function index(props) {
  const { fields } = props;
  const jsonData = JSON.parse(fields?.jsonData?.value);
  // const jsonData = {
  //   "bg": "<img alt="广汽本田" src=\"${dm-media}/avancier/v2/pc/space/space1.jpg\" />",
  //   "video": {
  //     "src": "/avancier/v2/mobile/space/space.mp4",
  //     "minsrc": "/avancier/v2/mobile/space/space5s.gif",
  //     "closedmp": "price_space-video-close",
  //     "opendmp": "price_space-video-open"
  //   },
  //   "top": "<div class=\"t\">豪华空间质感</div><div class=\"subs\">沉浸豪华座舱，私享宽奢厅堂</div><div class=\"p\">4858mm超长车身，让身心尽情舒展。</div>",
  //   "btn1": "<a class=\"btn\" data-html-item=\"price_space-inside\" data-url=\"https://avancier360.ghac.cn/?interior=1\" href=\"https://avancier360.ghac.cn/?interior=1\" target=\"_blank\" rel=\"noreferrer\"><img alt="广汽本田" class=\"icon\" src=\"${dm-media}/avancier/v2/pc/space/360_icon.png\" />全景内饰</a>",
  //   "btn2": "了解详情",
  //   "bottom":
  //     "<div class=\"ul\"><div class=\"li\">4向调节多功能方向盘 (配换挡拨片)</div><div class=\"li\">HUD抬头显示系统</div><div class=\"li\">简约中控台设计</div><div class=\"li\">一体式设计平坦后地板</div><div class=\"li\">高级环绕立体声影院音响系统</div></div>",
  //   "m_bg": "<img alt="广汽本田" src=\"${dm-media}/avancier/v2/mobile/space/space1.jpg\" />",
  //   "m_video": {
  //     "src": "/avancier/v2/mobile/space/space.mp4",
  //     "closedmp": "price_space-video-close",
  //     "minsrc": "/avancier/v2/mobile/space/space5s.gif",
  //     "opendmp": "price_space-video-open"
  //   },
  //   "m_top": "<div class=\"t\">豪华空间质感</div><div class=\"subs\">沉浸豪华座舱，私享宽奢厅堂</div>",
  //   "m_btn1":
  //     "<a class=\"btn\" data-html-item=\"price_space-inside\" data-url=\"https://avancier360.ghac.cn/?interior=1\" href=\"https://avancier360.ghac.cn/?interior=1\" target=\"_blank\" rel=\"noreferrer\"><img alt="广汽本田" class=\"icon\" src=\"${dm-media}/avancier/v2/mobile/space/360_icon.png\" />全景内饰</a>",
  //   "m_btn2": "了解详情",
  //   "m_bottom":
  //     "<div class=\"p\">4858mm超长车身，<br>让身心尽情舒展。</div><div class=\"ul\"><div class=\"li\">4向调节多功能方向盘 (配换挡拨片)</div><div class=\"li\">HUD抬头显示系统</div><div class=\"li\">简约中控台设计</div><div class=\"li\">一体式设计平坦后地板</div><div class=\"li\">高级环绕立体声影院音响系统</div></div>",
  //   "placeHolderRoute": "jss-space"
  // }
  const [showDetail, setShowDetail] = useState(false);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename ? props?.params?.pagename : 'price_power1';
  const [paused, setPaused] = useState(true);
  const rootEl = useRef(null);
  const topEl = useRef(null);
  const videoEl = useRef(null);
  const bottomEl = useRef(null);
  const secondPageRef = useRef(null);
  const secondPageEventRef = useRef(null);
  const route = useSitecoreContext()?.sitecoreContext?.route;
  useEffect(() => {
    const scroll = () => {
      if (rootEl.current) {
        setPaused(!isInView(rootEl));
      }
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  useEffect(() => {
    const timeline = gsap.timeline({
      duration: 1,
      onComplete: () => {
        timeline.kill();
      }
    });
    if (paused) {
      if (isMobile) {
        timeline.to(bottomEl?.current, { opacity: 0 }, '<').to(topEl?.current, { opacity: 0 });
      } else {
        timeline.to(bottomEl?.current, { opacity: 0 }, '<').to(videoEl?.current, { opacity: 0 }).to(topEl?.current, { opacity: 0 });
      }
    } else {
      if (isMobile) {
        timeline
          .fromTo(topEl?.current, { y: '-50%', opacity: 0 }, { y: 0, opacity: 1 }, '<')
          .fromTo(bottomEl?.current, { y: '50%', opacity: 0 }, { y: 0, opacity: 1 });
      } else {
        timeline
          .fromTo(topEl?.current, { y: '-50%', opacity: 0 }, { y: 0, opacity: 1 }, '<')
          .fromTo(videoEl?.current, { x: '200%', opacity: 0 }, { x: 0, opacity: 1 })
          .fromTo(bottomEl?.current, { y: '50%', opacity: 0 }, { y: 0, opacity: 1 });
      }
    }
    return () => {
      timeline.kill();
    };
  }, [paused]);
  const openVideo = () => {
    setShowDetail(true);
  };
  useEffect(() => {
    if ('jss-space') {
      props.updateRefList([sendFirstDmp, sendSecondDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current]);
    } else {
      props.updateRefList([sendFirstDmp]);
      props.updateDmpList([rootEl?.current]);
    }
  }, []);
  const sendFirstDmp = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id;
  };
  const sendSecondDmp = () => {
    secondPageEventRef?.current?.sendEvent();
  };
  const windowhidden = () => {
    document.documentElement.style.overflow = 'hidden';
  };
  const closewindowhidden = () => {
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.overflowX = 'hidden';
  };
  return (
    <div>
      <div className="price-space-v10" id={id} ref={rootEl}>
        <div className="bg" dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('bg', isMobile)]?.replaceHtml() }}></div>
        {!isMobile && (
          <div
            className="videomin"
            ref={videoEl}
            onClick={() => {
              openVideo();
              windowhidden();
              window.Dmp.clickAction(jsonData[getPrefixField('video', isMobile)]?.opendmp);
            }}
          >
            <img alt="广汽本田" className="video" src={`${createFilePath(jsonData[getPrefixField('video', isMobile)]?.minsrc)}`} />
          </div>
        )}
        <div className="top" ref={topEl} dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('top', isMobile)]?.replaceHtml() }}></div>
        <div className="bottom" ref={bottomEl}>
          {isMobile ? (
            <>
              <div dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('bottom', isMobile)]?.replaceHtml() }}></div>
              <div dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('btn1', isMobile)]?.replaceHtml() }}></div>
              <div
                className="btn"
                onClick={() => {
                  openVideo();
                  windowhidden();
                  window.Dmp.clickAction(jsonData[getPrefixField('video', isMobile)]?.opendmp);
                }}
                dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('btn2', isMobile)]?.replaceHtml() }}
              ></div>
            </>
          ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('btn1', isMobile)]?.replaceHtml() }}></div>
              <div dangerouslySetInnerHTML={{ __html: jsonData[getPrefixField('bottom', isMobile)]?.replaceHtml() }}></div>
            </>
          )}
        </div>
      </div>

      {showDetail && (
        <FullModalDetail>
          <div className="price-space-v10-prop">
            <div
              className="close"
              onClick={() => {
                setShowDetail(false);
                closewindowhidden();
                window.Dmp.clickAction(jsonData[getPrefixField('video', isMobile)]?.closedmp);
              }}
            >
              {isMobile ? (
                <img alt="广汽本田" src={createFilePath('/avancier/v2/mobile/close_icon.png')} />
              ) : (
                <img alt="广汽本田" src={createFilePath('/avancier/v2/pc/close_icon.png')} />
              )}
            </div>
            <div className="item">
              <div className="pic">
                <video
                  x5-playsinline="true"
                  webkit-playsinline="true"
                  playsInline={true}
                  src={`${createFilePath(jsonData[getPrefixField('video', isMobile)]?.src)}`}
                  controls
                  loop
                  poster=""
                  autoPlay
                />
              </div>
            </div>
          </div>
        </FullModalDetail>
      )}

      {jsonData?.placeHolderRoute && (
        <div ref={secondPageRef}>
          <Placeholder name={jsonData?.placeHolderRoute} rendering={route} page={route} eventRef={secondPageEventRef} />
        </div>
      )}
    </div>
  );
}
