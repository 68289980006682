import React, { useEffect, useRef, useState, forwardRef } from 'react';
import './style.scss';
import temp from '../../temp/config';
import { isEmpty, convertStylesStringToObject, sendDmp, scrollFinish, createFilePath } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';

const HeroLanding = forwardRef((data, ref) => {
  const contentRef = useRef(null);
  const containerRef = useRef(null);
  const [tabNum, setTabNum] = useState(0);
  if (isEmpty(data.data.fields?.m_subtitle?.value)) {
    data.data.fields.m_subtitle = data.data.fields.subtitle;
  }
  const { pngLinks, className, titleLogo, tabs, split_line, default_tab_name } = data.data.fields;
  const isMobile = data?.isMobile;
  const isPng = (value) => {
    if (value !== '') {
      return value.indexOf('png') !== -1 || value.indexOf('jpg') !== -1;
    }
  };
  const positionAuto = (value) => {
    if (value === undefined) return;
    if (value.indexOf('left') === 0) {
      return 'left';
    }
    if (value.indexOf('center') === 0) {
      return 'center';
    }
    if (value.indexOf('right') === 0) {
      return 'right';
    }
  };
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = 0;
      tabs.map((item, index) => {
        if (item.fields?.tab_name?.value === value) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      switch (getQueryVariable('tab')) {
        case 'hyrid':
          setTabNum(queryTabDefault('hyrid'));
          data.setBtnUrl(tabs[queryTabDefault('hyrid')].fields?.btn[0]?.fields?.url?.value);
          break;
        case 'plugin':
          setTabNum(queryTabDefault('plugin'));
          data.setBtnUrl(tabs[queryTabDefault('plugin')].fields?.btn[0]?.fields?.url?.value);
          break;
        case 'oil':
          setTabNum(queryTabDefault('oil'));
          data.setBtnUrl(tabs[queryTabDefault('oil')].fields?.btn[0]?.fields?.url?.value);
          break;
        case 'control':
          setTabNum(queryTabDefault('control'));
          data.setBtnUrl(tabs[queryTabDefault('control')].fields?.btn[0]?.fields?.url?.value);
          break;
        default:
          setTabNum(queryTabDefault(default_tab_name.value));
          data.setBtnUrl(tabs[queryTabDefault(default_tab_name.value)].fields?.btn[0]?.fields?.url?.value);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (!ref) {
      console.log(ref);
      contentRef?.current?.classList.add('animate__fadeInDown');
    }
  }, []);

  const firstScreenShow = () => {
    let screen = containerRef.current.querySelector('.firstScreen');
    if (screen) {
      screen.classList.remove('firstScreen-show');
      void screen.offsetWidth;
      screen.classList.add('firstScreen-show');
    }
  };

  return (
    <div
      className={`heroLandingBox heroLandingBox_${positionAuto(
        tabs[tabNum].fields?.position?.fields?.k?.value || tabs[tabNum].fields?.position?.value
      )} ${data.className || ''} ${className?.value || ''}`}
      ref={containerRef}
    >
      {!isMobile ? (
        isPng(tabs[tabNum].fields?.image_video?.value) ? (
          <img className="firstScreen" src={`${createFilePath(tabs[tabNum].fields?.image_video?.value)}`} />
        ) : (
          <video src={`${createFilePath(tabs[tabNum].fields?.image_video?.value)}`} autoPlay={true} muted={true} />
        )
      ) : (
        // <video src={`${createFilePath(tabs[tabNum].fields?.image_video?.value)}`} autoPlay={true} muted={true} />
        <img className="firstScreen" src={`${createFilePath(tabs[tabNum].fields?.m_image_video?.value)}`} alt="广汽本田" />
      )}
      <div
        className={`heroLanding_${
          !isMobile
            ? tabs[tabNum].fields?.position?.fields?.k?.value || tabs[tabNum].fields?.position?.value
            : tabs[tabNum].fields?.m_position?.fields?.k?.value || (isMobile && tabs[tabNum].fields?.m_position?.value !== '')
            ? tabs[tabNum].fields?.m_position?.value
            : tabs[tabNum].fields?.position?.value
        }`}
        style={{
          top: !isMobile ? tabs[tabNum].fields?.text_style?.value : tabs[tabNum].fields?.m_text_style?.value,
          textAlign: !isMobile ? tabs[tabNum].fields?.textalign?.value : tabs[tabNum].fields?.m_textalign?.value,
          width: !isMobile && className?.value === 'breezeBannerWidth' ? '50%' : '',
          opacity: 0
        }}
        ref={ref || contentRef}
      >
        <div
          className="exterior_tab_list"
          style={convertStylesStringToObject(!isMobile ? tabs[tabNum].fields?.tab_style?.value : tabs[tabNum].fields?.m_tab_style?.value)}
        >
          {tabs &&
            tabs.length > 1 &&
            tabs.map((item, index) => {
              return (
                <>
                  {index !== 0 && <div className="split_line" dangerouslySetInnerHTML={{ __html: split_line.value?.replaceHtml() }} />}
                  <div
                    key={index}
                    className={`tab_item ${index === tabNum ? 'tab_disabled' : ''}`}
                    onClick={() => {
                      setTabNum(index);
                      sendDmp(item?.fields?.tab_dmp);
                      data.setBtnUrl(tabs[index].fields?.btn[0]?.fields?.url?.value);
                      firstScreenShow();
                    }}
                  >
                    <div
                      className={index === tabNum ? 'tabContainer_active' : 'tabContainer'}
                      dangerouslySetInnerHTML={{ __html: item.fields?.tab?.value?.replaceHtml() }}
                    />
                  </div>
                </>
              );
            })}
        </div>
        {isPng(tabs[tabNum].fields?.title?.value) ? (
          <img className="title" src={`${createFilePath(tabs[tabNum].fields?.title?.value)}`} alt="title" />
        ) : (
          <div
            className="title"
            dangerouslySetInnerHTML={{ __html: (!isMobile ? tabs[tabNum].fields?.title?.value : tabs[tabNum].fields?.m_title?.value)?.replaceHtml() }}
          />
        )}
        {titleLogo?.value !== '' ? (
          <div dangerouslySetInnerHTML={{ __html: (!isMobile ? titleLogo?.value : titleLogo?.value)?.replaceHtml() }}></div>
        ) : (
          ''
        )}
        {isPng(!isMobile ? tabs[tabNum].fields?.subtitle?.value : tabs[tabNum].fields?.m_subtitle?.value) ? (
          <img src={`${createFilePath(!isMobile ? tabs[tabNum].fields?.subtitle?.value : tabs[tabNum].fields?.m_subtitle?.value)}`} alt="SubTitle" />
        ) : (
          <div
            className="subTitle specialSunTitle"
            dangerouslySetInnerHTML={{
              __html: (!isMobile ? tabs[tabNum].fields?.subtitle?.value : tabs[tabNum].fields?.m_subtitle?.value)?.replaceHtml()
            }}
          />
        )}
        {tabs[tabNum].fields?.describe?.value !== '' || tabs[tabNum].fields?.m_describe?.value !== '' ? (
          <div
            className="describe"
            dangerouslySetInnerHTML={{
              __html: (!isMobile ? tabs[tabNum].fields?.describe?.value : tabs[tabNum].fields?.m_describe?.value)?.replaceHtml()
            }}
          />
        ) : (
          ''
        )}
        {pngLinks?.fields?.k?.value === 'true' ? (
          <div className="pngLinks">
            <img src={createFilePath('/breeze/v1/pc/banner/red_line.png')} alt="img" />
          </div>
        ) : (
          ''
        )}
        {(tabs[tabNum].fields?.btnFlag?.value === 'true' && !isMobile) || (tabs[tabNum].fields?.m_btnFlag?.value === 'true' && isMobile) ? (
          <div className={`btnArr btnArr_${!isMobile ? tabs[tabNum].fields?.btnPosition?.value : tabs[tabNum].fields?.m_btnPosition?.value}`}>
            {tabs[tabNum].fields?.btn &&
              tabs[tabNum].fields?.btn.map((item) => {
                if (data?.checkDetail) {
                  return (
                    <button
                      className={'btn'}
                      key={item.id}
                      onClick={(e) => {
                        if (item.fields.iconUrl.value !== '' || item?.fields?.isOpenUrl?.value) {
                          sendDmp(
                            item?.fields['data-item'],
                            isMobile && item.fields.m_url.value !== '' ? item.fields.m_url.value : item.fields.url.value,
                            ''
                          );
                          window.open(isMobile && item.fields.m_url.value !== '' ? item.fields.m_url.value : item.fields.url.value);
                        } else {
                          sendDmp(item?.fields['data-item'], '', '');
                          data?.checkDetail(true);
                        }
                      }}
                      style={{
                        ...convertStylesStringToObject(!isMobile ? item?.fields.btn_style?.value : item?.fields?.m_btn_style?.value),
                        backgroundColor: item?.fields?.m_backColor?.value
                      }}
                    >
                      {item?.fields.iconUrl.value !== '' ? (
                        <img alt="广汽本田" className="iconLITTLE" src={`${createFilePath(item.fields.iconUrl.value)}`} />
                      ) : (
                        ''
                      )}
                      {item?.fields?.title.value}
                    </button>
                  );
                } else {
                  return (
                    <button
                      className={`btn btn-${item?.fields?.class?.displayName} btn-${item?.fields?.type.displayName}`}
                      key={item.id}
                      data-action={item?.fields['data-item'][0].fields.func.value}
                      data-item={item?.fields['data-item'][0].displayName}
                      data-url={isMobile ? item?.fields?.url.value : item?.fields?.m_url.value}
                      onClick={() => {
                        isMobile
                          ? item?.fields?.url.value && window.open(item?.fields?.url.value)
                          : item?.fields?.m_url.value && window.open(item?.fields?.m_url.value);
                      }}
                      style={{
                        marginTop: !isMobile ? item?.fields?.pc_bottom?.value : item?.fields?.m_bottom?.value,
                        marginLeft: !isMobile ? item?.fields?.pc_left?.value : item?.fields?.m_left?.value,
                        backgroundColor: !isMobile ? item?.fields?.backColor?.value : item?.fields?.m_backColor?.value
                      }}
                    >
                      {item?.fields?.title.value}
                    </button>
                  );
                }
              })}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
});
HeroLanding.displayName = 'HeroLanding';
export default HeroLanding;
