import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import 'swiper/swiper.min.css';
import './style.scss';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getPrefixField, sendDmp, isMobileFun } from '../../utils/obj-utils';
export default function index(props) {
  const { fields } = props;
  const secondPageRef = useRef(null);
  const isMobile = isMobileFun();
  const route = useSitecoreContext()?.sitecoreContext?.route;

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent: secondPageRef?.current?.sendEvent
    };
  });
  //
  // const sendEvent = () => {
  //   sendDmp(fields.window_dmp);
  //   window.navigationIndex = props.fields.navigationIndex.value;
  //   window.navigationPagename = 'detail';
  // };

  const openComparison = () => {
    secondPageRef?.current?.openComparison();
  };

  const openLoanCalc = () => {
    secondPageRef?.current?.openLoanCalc();
  };
  return (
    <div className="Contrast_v2" id="detail">
      <div className="Contrast_in">
        <Placeholder name={fields?.placeHolderRoute.value} rendering={route} page={route} eventRef={secondPageRef} />
        <div className="ContrastBtn">
          {!isMobile && (
            <div
              onClick={() => openLoanCalc()}
              dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('finance_btn_text', isMobile)}`].value?.replaceHtml() }}
            />
          )}
          <div
            onClick={() => openComparison()}
            dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('detail_btn_text', isMobile)}`].value?.replaceHtml() }}
          />
          {isMobile && (
            <div
              onClick={() => openLoanCalc()}
              dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('finance_btn_text', isMobile)}`].value?.replaceHtml() }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
