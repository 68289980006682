import React, { useState, useEffect, useRef } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { fetchLayoutService } from '../../utils/layout-service';
import FullModalDetail from '../FullModalDetail_v1';
import config from '../../temp/config';
import './style.scss';
import { getPrefixField, convertStylesStringToObject } from '../../utils/obj-utils';

export default function index(props) {
  const [route, setRoute] = useState(null);
  const modalScrollRef = useRef(null);

  const fetchRouteData = () => {
    if (!props.routePath) {
      return;
    }

    fetchLayoutService(props.routePath, config.defaultLanguage)
      .then((routeData) => {
        const route = routeData?.sitecore?.route;
        if (route) {
          window.Dmp.personalizedEnable = route?.fields?.personalized_enable?.value;
          setRoute(route);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchRouteData();
  }, []);

  const getSection = () => {
    return (
      <section
        className={`inner-page-detail ${route?.fields?.fade_in?.value ? 'inner-page-detail-fade-in' : ''}`}
        style={{
          ...convertStylesStringToObject(route?.fields?.[getPrefixField('style')]?.value),
          background: route?.fields?.theme?.fields?.v?.value
        }}
      >
        {route && <Placeholder name="jss-main" close={props?.close} rendering={route} scrollElement={modalScrollRef?.current} />}
      </section>
    );
  };
  return (
    <FullModalDetail ref={modalScrollRef} classname={props?.classname?.value}>
      {getSection()}
    </FullModalDetail>
  );
}
