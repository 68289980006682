import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import HeroLanding from '../../elements/HeroLanding_v4';
import Detail from '../../elements/InnerPageDetail';
import { getHeaderHeight, isMobileFun } from '../../utils/obj-utils';

export default function Power(props) {
  const [flag, setFlag] = useState(false);
  const [btnUrl, setBtnUrl] = useState(props.fields.tabs[0].fields.btn[0].fields.url.value);
  const containerRef = useRef(null);
  const textContainerRef = useRef(null);
  let once = true;
  const isMobile = isMobileFun();
  const routePath = isMobile ? props.fields.btn[0].fields.m_url.value || props.fields.btn[0].fields.url.value : props.fields.btn[0].fields.url.value;
  const background = props.fields.backColor.value;
  const id = props.fields.id.value;
  const data = {
    isMobile,
    routePath,
    background,
    id
  };

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    window.Dmp.sectionAction(data?.id);
    // 二级导航高亮所属变量
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename !== undefined ? props?.params?.pagename : '';
  };

  useEffect(() => {
    textContainerRef.current.style.opacity = 0;
    const windowHeight = document.documentElement.clientHeight;
    const headerHeight = getHeaderHeight();
    const timeline1 = gsap
      .timeline({ paused: true })
      .fromTo(containerRef.current.children[0], { opacity: 0 }, { opacity: 1, duration: 0.55, ease: 'power1.out' })
      .fromTo(containerRef.current.children[0], { scale: 0.6 }, { scale: 1, duration: 1 }, '<');
    const controller = new ScrollMagic.Controller();
    const scene1 = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: '0.8',
      duration: windowHeight * 0.8 - headerHeight
    })
      .on('progress', function (event) {
        if (event.progress >= 0.8 && once) {
          once = false;
          gsap.fromTo(textContainerRef?.current, { opacity: 0, y: '-100%' }, { opacity: 1, y: 0, duration: 1.25 });
        }
      })
      .setTween(timeline1)
      .addTo(controller);

    return () => {
      timeline1.kill();
      scene1.remove();
      scene1.destroy();

      controller.destroy();
    };
  }, []);

  return (
    <section>
      {flag && <Detail routePath={btnUrl} close={() => setFlag(false)} />}
      <div className="Exterior_v1" style={{ background: data?.background }} ref={containerRef}>
        <HeroLanding data={props} checkDetail={setFlag} isMobile={isMobile} ref={textContainerRef} setBtnUrl={setBtnUrl} />
      </div>
      <div className="Exterior_v1-anchor" id={data?.id} />
    </section>
  );
}
