import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import Details from './details/index';
import 'swiper/swiper.min.css';
import { getPrefixField, sendDmp, getPathnameByPosition, isMobileFun } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';
import Detailss from '../../elements/InnerPageDetail';
import ParameterConfiguration from '../../elements/ParameterConfiguration';
import ParameterConfiguration_v1 from '../../elements/ParameterConfiguration_v1';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const [num, setNum] = useState(0);
  const [flag, setFlag] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [windowHeight, setWindowHeight] = useState(400);
  const rootEl = useRef(null);
  const isMobile = isMobileFun();
  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
  }, []);

  useEffect(() => {
    const carList = fields?.version_list?.reduce(
      (prev, cur) => [
        ...prev,
        ...cur?.fields?.loan_car_list?.map((item) => ({
          carName: item.fields.title_text.value,
          GuidancePrice: parseFloat(item.fields.price.value)
        }))
      ],
      []
    );
    fields?.version_list?.forEach((ele) => {
      if (ele?.fields?.only?.value) {
        ele.fields.carList = ele?.fields?.loan_car_list?.map((item) => ({
          carName: item.fields.title_text.value,
          GuidancePrice: parseFloat(item.fields.price.value)
        }));
      } else {
        ele.fields.carList = [...carList];
      }
    });
  }, []);

  useEffect(() => {
    const resizeEvent = () => {
      setWindowHeight(document.documentElement.clientHeight);
    };
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent,
      openComparison,
      openLoanCalc,
      num
    };
  });

  const sendEvent = () => {
    sendDmp(fields.window_dmp);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = 'detail';
  };

  const chooseTab = (value) => {
    const { fields } = props;
    let items = fields[`${getPrefixField('version_list')}`];
    for (let i = 0; i < items.length; i++) {
      if (items[i].fields.tab_value.value === value) {
        setNum(i);
        break;
      }
    }
  };

  useEffect(() => {
    const { fields } = props;
    let tab = getQueryVariable('tab');
    if (!fields[`${getPrefixField('version_list')}`].find((item) => item.fields.tab_value.value === tab)) {
      tab = fields[`${getPrefixField('default_tab_value')}`]?.value;
    }
    chooseTab(tab);
  }, []);

  const openComparison = () => {
    sendDmp(fields.detail_btn_dmp);
    setFlag(true);
  };

  const openLoanCalc = () => {
    sendDmp(fields.finance_btn_dmp);
    setFlag2(true);
  };

  return (
    <div className="Contrast_v1">
      {fields?.ParameterConfiguration?.value === 'ParameterConfiguration1' ? (
        <>
          <ParameterConfiguration_v1
            {...props}
            rootEl={rootEl}
            isMobile={isMobile}
            windowHeight={windowHeight}
            flag={flag}
            setNum={setNum}
            num={num}
            openComparison={() => openComparison()}
            openDetail={() => openLoanCalc()}
          />
        </>
      ) : (
        <ParameterConfiguration
          {...props}
          rootEl={rootEl}
          isMobile={isMobile}
          windowHeight={windowHeight}
          flag={flag}
          setNum={setNum}
          num={num}
          openComparison={() => openComparison()}
          openDetail={() => openLoanCalc()}
        />
      )}

      {flag && <Detailss routePath={fields[`${getPrefixField('version_list')}`][num].fields.detail_btn_href.value} close={() => setFlag(false)} />}
      {flag2 && (
        <Details
          key={Math.random()}
          carType={getPathnameByPosition()?.toLowerCase()}
          close={setFlag2}
          carList={fields?.version_list[num]?.fields?.carList}
        />
      )}
      <div
        className={
          props?.page?.name === 'breeze' && num === 1
            ? 'tips twoTips'
            : props?.page?.name === 'enp1' || props?.page?.name === 'zrv' || props?.page?.name === 'zrv-show' || props?.page?.name === 'enp1-show'
            ? 'tips whitetips'
            : 'tips'
        }
      >
        车图仅为示意，请以实车为准
        {props?.page?.name === 'breeze' && num === 1 && <p>*实际行驶油耗及行驶里程根据不同的燃油品质、道路状况、环境温度、驾驶条件而定</p>}
      </div>
    </div>
  );
}
